import * as NotificationActionTypes from '../actions/utilities/Notifications/NotificationActionTypes';

export default function notificationReducer(state = {}, action) {
  let notifKeys;
  switch (action.type) {
    case NotificationActionTypes.showNotificationType:
      return {
        ...state,
        notificationOpen: true,
        notificationData: action.notificationData,
      };
    case NotificationActionTypes.clearNotificationType:
      return {
        ...state,
        notificationOpen: false,
      };
    case NotificationActionTypes.storeNotifKey:
      notifKeys = state?.notificationKeys || [];
      return {
        ...state,
        notificationKeys: [...notifKeys, action.notificationKey],
      };
    default:
      return state;
  }
}
