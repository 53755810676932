import React from 'react';
import { Table } from 'antd';
import './PlantComponents.css';
import '../SummaryComponents/SummaryStyle.css';

const fields = [
  'Plant number',
  'Supply process',
  'Policy status',
  'Status',
  'Consumption group',
  'Consumption sub-grp',
  'Consumption qty in LT',
  'Consumptions in LT',
  'Demand time',
  'Max crit',
  'Tot ABC',
  'Tot QABC',
  'Max QABC',
  'Highest tag status',
  'Valuation class',
  'Available stock',
  'Other stock',
  'Total value',
  'Plant-sp.matl. status',
  'Purchasing group',
  'EOQ',
];

const mrpRowNames = [
  'MRP Type',
  'Lot Size',
  'Reorder Point',
  'Minimum Lot Size',
  'Maximum Stock Level',
  'Maximum Lot Size',
  'Rounding Value',
  'Fixed Lot Size',
  'MRP Group',
  'MRP Controller',
  'Plant Material Status',
  'ABC Indicator',
  // 'Plant Storage Location',
];

function PlantColumnsCard() {
  const plantData = [];
  const mrpRows = [];
  fields.map((value, index) => plantData.push({ key: index, value }));
  mrpRowNames.map((value, index) => mrpRows.push({ key: index, value }));

  const columns = [
    {
      title: <div style={{ height: 20 }} />,
      dataIndex: 'value',
    }];

  return (
    <div>
      <Table
        className="plantTable"
        style={{
          paddingBottom: 0,
          height: 'calc(59vh - 120px)',
        }}
        pagination={false}
        dataSource={plantData}
        columns={columns}
        rowClassName="plantInfo"
        showHeader
      />
      <Table
        bordered
        dataSource={mrpRows}
        columns={[
          {
            title: <div style={{ height: 19 }} />,
            dataIndex: 'value',
          },
        ]}
        pagination={false}
        className="plantTable"
        style={{ paddingTop: '52px' }}
      />
      <Table
        bordered
        dataSource={[
          {
            key: 'type',
            value: 'Type',
          },
          {
            key: 'value',
            value: 'Value',
          },
        ]}
        columns={[
          {
            title: <div style={{ height: 19 }} />,
            dataIndex: 'value',
          },
        ]}
        pagination={false}
        className="plantTable"
      />
    </div>

  );
}

export default PlantColumnsCard;
