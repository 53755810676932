import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { MsalProvider, MsalAuthenticationTemplate } from '@azure/msal-react';
import { EventType, InteractionType } from '@azure/msal-browser';
import './index.css';
import { Provider } from 'react-redux';
import App from './App';
import msalInstance from './auth/msalProvider';
import configureStore from './store/configureStore';
import * as serviceWorker from './serviceWorker';
import history from './history';
import Loading from './components/Common/Loading';
import DisplayError from './components/Common/DisplayError';
import allActions from './actions/index';
import { showNotification } from './actions/utilities/Notifications/NotificationActions';
import './global.css';

const APIScopes = [process.env.REACT_APP_API_ACCESS_SCOPE];
const redirectUri = process.env.REACT_APP_AAD_REDIRECT_URI;

const initialState = window.initialReduxState;
const store = configureStore(history, initialState, msalInstance);
const authRequest = {
  APIScopes,
  redirectUri,
};

function errorNotification(errorMsg) {
  const message = errorMsg.number === 401
    ? 'User is not authorized for this app.'
    : 'Failed to authorize user, please try to refresh the page.';
  return {
    Type: 'error', Title: 'Authorization failure.', Message: message, Placement: 'bottomRight',
  };
}

msalInstance.addEventCallback((message) => {
  if (message.eventType === EventType.HANDLE_REDIRECT_END) {
    let account = store.getState()?.authState?.user?.account;
    if (!account) {
      account = msalInstance.getAllAccounts()?.[0];
      msalInstance.setActiveAccount(account);
      msalInstance.acquireTokenSilent({
        scopes: APIScopes,
        account,
      }).then((response) => {
        if (response) {
          store.dispatch(allActions.AuthActions.storeToken(response));
        } else throw new Error('No response from MSAL.');
      }).catch((e) => {
        store.dispatch(showNotification(errorNotification(e)));
      });
    }
  }
});

const app = (
  <MsalProvider instance={msalInstance}>
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      loadingComponent={Loading}
      errorComponent={DisplayError}
    >
      <Provider store={store}>
        <App msalInstance={msalInstance} />
      </Provider>
    </MsalAuthenticationTemplate>
  </MsalProvider>
);
ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
