import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { ACTIONTYPES } from '../../../Common/GlobalConstants';
import allActions from '../../../../actions';
import CalendarModal from '../../MaterialDetails/components/CalendarModal';
import SelectedItemsContext from '../../../../contexts/selectedItemsContext';
import { showNotification } from '../../../../actions/utilities/Notifications/NotificationActions';
import ConfirmModal from '../../../Common/ConfirmModal';
import InfoModal from '../../../Common/InfoModal';

const ActionMenu = ({
  materiallistCount, hasSelectedAll, materialViewState, updateCallback,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.authState.user);
  const [showPopup, setShowPopup] = useState(false);
  const [showInfoMsg, setShowInfoMsg] = useState(false);
  const [info, setInfo] = useState({
    msg: '',
    title: '',
  });
  const [dataToSend, setDataToSend] = useState({});
  const [actionMenuVisible, setActionMenuVisible] = useState(false);
  const [calenderVisible, setCalenderVisible] = useState(false);
  const [currentCalender, setCalenderObj] = useState({
    text: null,
    visible: null,
    onOK: null,
    onCancel: null,
  });
  const [showInfoModal, setShowInfoModal] = useState({ status: false });

  const { selectedItems } = useContext(SelectedItemsContext);

  useEffect(() => {
    if (selectedItems.length === 0 && actionMenuVisible) { setActionMenuVisible(false); }
  }, [selectedItems]);

  const handleLastChecked = (confirmed) => {
    if (selectedItems.length > 0) {
      const data = { MrpBatch: '183642', to_Mrp: [] };
      setActionMenuVisible(false);
      if (selectedItems.length <= materiallistCount?.[0]?.NoofRecords) {
        data.to_Mrp = selectedItems.map((item) => ({
          Plant: item.plantID,
          MaterialNumber: item.materialID,
          ActivateLastChecked: true,
        }));
      }
      dispatch(
        allActions.MaterialListActions.updateLastChecked(
          data,
          user.account?.username,
          user.uniqueId,
          hasSelectedAll ? 1 : 0,
          materialViewState,
          hasSelectedAll ? materiallistCount?.[0]?.NoofRecords : selectedItems.length,
        ),
      );
      if (confirmed) {
        setDataToSend({});
        setShowPopup(false);
      }
    }
  };

  const handleAction = (actionType, date, confirmed) => {
    if (selectedItems.length > 0) {
      setActionMenuVisible(false);
      dispatch(
        allActions.MaterialListActions.updateActions(
          actionType,
          selectedItems,
          user.account?.username,
          user.uniqueId,
          date,
          hasSelectedAll ? 1 : 0,
          materialViewState,
          hasSelectedAll ? materiallistCount?.[0]?.NoofRecords : selectedItems.length,
          updateCallback,
        ),
      );
      if (confirmed) {
        setDataToSend({});
        setShowPopup(false);
      }
    }
  };

  const checkIfNeedConfirmation = (actionType, date) => {
    setDataToSend({ actionType, date });
    setCalenderVisible(false);
    setActionMenuVisible(false);
    if ((hasSelectedAll && materiallistCount?.[0]?.NoofRecords > 10000)
     || selectedItems.length > 10000) {
      setShowInfoMsg(true);
      setInfo({
        msg: `No. of records selected for ${actionType} are greater than 10000. Please, limit your selection`,
        title: actionType,
      });
    } else if ((hasSelectedAll && materiallistCount?.[0]?.NoofRecords > 1000)
     || selectedItems.length > 1000) {
      setShowPopup(true);
    } else if (!materiallistCount?.[0]?.NoofRecords && hasSelectedAll) {
      setShowInfoMsg(true);
      setInfo({
        msg: `No. of records selected for ${actionType} are greater than 10000. Please, limit your selection`,
        title: actionType,
      });
    } else if (actionType === ACTIONTYPES.LastChecked) handleLastChecked(true);
    else handleAction(actionType, date);
  };

  const calenderObj = {
    [ACTIONTYPES.Postpone]: {
      text: ACTIONTYPES.Postpone,
      onOK: (date) => {
        checkIfNeedConfirmation(ACTIONTYPES.Postpone, date);
        setCalenderVisible(false);
      },
      onCancel: () => setCalenderVisible(false),
    },
    [ACTIONTYPES.Inspect]: {
      text: ACTIONTYPES.Inspect,
      onOK: (date) => {
        checkIfNeedConfirmation(ACTIONTYPES.Inspect, date);
        setCalenderVisible(false);
      },
      onCancel: () => setCalenderVisible(false),
    },
  };

  const setCurrentCalender = (name) => {
    setCalenderVisible(true);
    setActionMenuVisible(false);
    setCalenderObj(calenderObj[name]);
  };

  const copyFunction = (list, sendToMrp = false, plants = []) => {
    const clipboardText = list.join('\r\n');
    navigator.clipboard.writeText(clipboardText);
    setActionMenuVisible(false);
    dispatch(showNotification(
      {
        Type: 'success',
        Title: 'Material Ids copied to clipboard',
      },
    ));
    if (sendToMrp) {
      const allEqual = plants.length > 0 ? plants.every((item) => item === plants[0]) : false;
      history.push({ pathname: '/mass-update', state: { ids: JSON.stringify(list), plants: allEqual ? plants[0] : '' } });
    }
  };

  const tooManyItems = (noMaxItems) => {
    if (noMaxItems) {
      return { status: false, type: '' };
    }
    if (hasSelectedAll && materiallistCount?.[0]?.NoofRecords > 2000) {
      return { status: true, type: 'mrp' };
    } if (selectedItems.length > 2000) return { status: true, type: 'mrp' };

    return { status: false, type: '' };
  };

  const copyToClipboard = (sendToMrp = false, noMaxItems = false) => {
    const validityCheck = tooManyItems(noMaxItems);
    if (validityCheck.status === true) {
      if (validityCheck.type === 'mrp') setShowInfoModal({ status: true, message: 'Your amount of materials to copy is over 2000.' });
      else {
        setShowInfoModal({ status: true, message: 'Your amount of materials to copy is over 2000.' });
      }
      setActionMenuVisible(false);
    } else {
      const materialIds = [];
      if (hasSelectedAll) {
        dispatch(allActions.MaterialListActions.getMaterialIds(materialViewState, materiallistCount?.[0]?.NoofRecords, sendToMrp, copyFunction));
      } else {
        selectedItems.forEach((element) => {
          materialIds.push(element.materialID);
        });
        const plants = selectedItems.map((item) => item.plantID);
        copyFunction(materialIds, sendToMrp, plants);
      }
    }
  };

  const actionMenu = (
    <Menu>
      <Menu.Item key="1" onClick={() => copyToClipboard(false, true)}>
        Copy materials
      </Menu.Item>
      <Menu.Item key="2" onClick={() => copyToClipboard(true)}>
        Copy materials to mass update
      </Menu.Item>
      <Menu.Item key="3" onClick={() => setCurrentCalender(ACTIONTYPES.Inspect)}>
        {ACTIONTYPES.Inspect}
      </Menu.Item>
      <Menu.Item
        key="4"
        onClick={() => checkIfNeedConfirmation(
          ACTIONTYPES.LastChecked,
          new Date().toLocaleDateString(),
        )}
      >
        Last checked
      </Menu.Item>
      <Menu.Item key="5" onClick={() => setCurrentCalender(ACTIONTYPES.Postpone)}>
        {ACTIONTYPES.Postpone}
      </Menu.Item>
      <Menu.Item key="6" onClick={() => checkIfNeedConfirmation(ACTIONTYPES.Review)}>
        {ACTIONTYPES.Review}
      </Menu.Item>
    </Menu>
  );
  return (
    <>
      <Dropdown
        overlay={actionMenu}
        placement="bottomRight"
        visible={actionMenuVisible}
        disabled={selectedItems.length === 0}
      >
        <Button
          className="headerButton"
          size="middle"
          type={selectedItems.length === 0 ? 'default' : 'primary'}
          onClick={() => setActionMenuVisible(!actionMenuVisible)}
        >
          Actions
          <DownOutlined />
        </Button>
      </Dropdown>
      <CalendarModal
        visible={calenderVisible}
        onOK={currentCalender.onOK}
        onCancel={currentCalender.onCancel}
        text={currentCalender.text}
      />
      <ConfirmModal
        title="Too many items selected"
        visible={showInfoModal?.status}
        message={showInfoModal?.message}
        okButtonDisabled
        onCancel={() => setShowInfoModal({ status: false, message: '' })}
      />
      <ConfirmModal
        title="Large amount of data"
        visible={showPopup}
        message="Your amount of materials to update is over 1000. Are you sure you want to continue?"
        okButtonDisabled={false}
        onCancel={() => setShowPopup(false)}
        onOK={() => handleAction(dataToSend.actionType, dataToSend.date, true)}
      />
      <InfoModal
        visible={showInfoMsg}
        title={info.title}
        content={info.msg}
        onCancel={() => setShowInfoMsg(false)}
      />
    </>
  );
};

export default ActionMenu;

ActionMenu.defaultProps = {
  materiallistCount: [],
  hasSelectedAll: false,
};

ActionMenu.propTypes = {
  materiallistCount: PropTypes.arrayOf(PropTypes.any),
  materialViewState: PropTypes.objectOf(PropTypes.any).isRequired,
  hasSelectedAll: PropTypes.bool,
  updateCallback: PropTypes.func.isRequired,
};
