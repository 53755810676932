import React, { useEffect, useRef, useState } from 'react';
import {
  Table,
} from 'antd';
import PropTypes from 'prop-types';
import './NewStockResultTable.css';
import { columns } from './FormData';
import { NS_PAGE_SIZE } from '../../../Common/GlobalConstants';

const NewStockResultTable = ({
  dataSource, visible, loading, changedRows, currPage,
}) => {
  const [tabData, setTabData] = useState([]);

  useEffect(() => {
    if (dataSource?.length) {
      const temp = dataSource.slice((currPage - 1) * NS_PAGE_SIZE, currPage * NS_PAGE_SIZE)
        .map((x) => ({ ...x }));
      if (changedRows?.length) {
        for (let i = 0; i < changedRows.length; i++) {
          if (changedRows[i] >= (currPage - 1) * NS_PAGE_SIZE
          && changedRows[i] < currPage * NS_PAGE_SIZE) {
            if (temp[changedRows[i] - ((currPage - 1) * NS_PAGE_SIZE)]) {
              temp[changedRows[i] - ((currPage - 1) * NS_PAGE_SIZE)].Max = 'Press submit to get new value';
              const x = temp[changedRows[i] - ((currPage - 1) * NS_PAGE_SIZE)]
                .key?.concat(Math.random().toString());
              temp[changedRows[i] - ((currPage - 1) * NS_PAGE_SIZE)].key = x;
            }
          }
        }
      }
      setTabData(temp);
    } else {
      setTabData([]);
    }
  }, [dataSource, currPage, changedRows]);

  useEffect(() => {
  }, [tabData]);

  const cellNoRef = useRef({
    rowNo: null,
    colNo: null,
  });

  const onCell = (_, index, editRowsNos, pageNo) => {
    const i = editRowsNos?.indexOf(((pageNo - 1) * NS_PAGE_SIZE) + index);
    if (cellNoRef.current.rowNo !== index) {
      cellNoRef.current.rowNo = index;
      cellNoRef.current.colNo = 0;
    }
    const obj = {
      colSpan: null,
    };
    if ((i === -1 || (i !== 0 && !i)) || !editRowsNos) {
      obj.colSpan = 1;
    } else if ((i === 0 || i) && cellNoRef.current.colNo === 3) {
      obj.colSpan = 7;
    } else {
      obj.colSpan = 0;
    }
    cellNoRef.current.colNo += 1;

    return obj;
  };

  return (
    <Table
      align="right"
      size="default"
      className="newStockTable"
      style={{ display: visible ? 'block' : 'none' }}
      dataSource={tabData}
      columns={columns.map((col) => ({
        ...col,
        onCell: (_, index) => onCell(_, index, changedRows, currPage),
      }))}
      pagination={false}
      loading={loading}
    />
  );
};
export default NewStockResultTable;
NewStockResultTable.propTypes = {
  dataSource: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  loading: PropTypes.bool,
  visible: PropTypes.bool,
  changedRows: PropTypes.arrayOf(PropTypes.number).isRequired,
  currPage: PropTypes.number.isRequired,
};

NewStockResultTable.defaultProps = {
  dataSource: [],
  loading: false,
  visible: true,
};
