import React, { useState, useEffect } from 'react';
import { ConfigProvider, Empty, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import allActions from '../../../actions/index';
import * as loadingKeys from '../../../actions/common/LoadingKeys';
import './helpers/ConfigTable.css';
import { configViewColumns } from './helpers/columns';

const CostConfigView = () => {
  const configTable = useSelector((state) => state.commonState.configTable);
  const userSettings = useSelector((state) => state.commonState.userSettings);
  const useDefaultSettings = useSelector((state) => state.commonState.useDefaultSettings);
  const dispatch = useDispatch();
  const [displayConfig, setDisplayConfig] = useState([]);
  const loading = useSelector((state) => state.commonState.loading);

  useEffect(() => { setDisplayConfig(configTable); }, [configTable]);

  useEffect(() => {
    dispatch(allActions.CommonActions.setLoading(loadingKeys.configTable));
    if (!(configTable?.length > 0)) {
      dispatch(allActions.CommonActions.getConfigTable());
    }
  }, []);

  const initialDefaults = (useDefaultSettings
    && userSettings?.USE_DEFAULTS_GLOBALLY
    && userSettings?.DEFAULT_PLANTS?.length > 0)
    ? userSettings?.DEFAULT_PLANTS
    : [];

  const uniqueItems = displayConfig?.length > 0 && Array
    .from(new Set([...initialDefaults, ...displayConfig.map((o) => o.PLANT_FACILITY_SAP_ID)]))
    .sort()
    .map((o) => ({ value: o, text: o }));

  return (
    <ConfigProvider renderEmpty={() => <Empty description={loading?.[loadingKeys.configTable] ? 'Loading...' : 'No data for selected plants'} />}>
      <Table
        dataSource={displayConfig}
        rowClassName={(record, index) => (index % 2 === 0 ? 'table-row-light' : 'table-row-dark')}
        columns={configViewColumns(
          uniqueItems,
          initialDefaults,
        )}
        size="small"
        pagination={{ pageSize: 15 }}
      />
    </ConfigProvider>
  );
};

export default CostConfigView;
