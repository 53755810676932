const SettingStrings = {
  DEFAULT_PLANTS: 'Default plants',
  COLOR_SCHEME: 'Color scheme',
  MATERIAL_LIST_COLUMNS: 'Material list column setup',
  DEFAULT_USER_VIEW: 'Default material list view',
  DEFAULT_MM_USER_VIEW: 'Default material master list view',
  DEFAULT_COLUMNS: 'Default columns',
  DEFAULT_COMPANY_CODES: 'Default company codes',
  USE_DEFAULTS_GLOBALLY: 'Global plant/company code settings',
  HIDE_NOTIFICATIONS: 'Notification settings',
  SHOW_WIDGET_LEGENDS: 'Show/hide clickable legends on dashboard widgets',
  DEFAULT_CURRENCY: 'Default Currency',
  DASHBOARD_FILTERS: 'Dashboard filters',
  COMMENT_SECTION_DEFAULT_TAB: 'Default tabs',
  TAB_SECTION_DEFAULT_TAB: 'Default tabs',
  STATIC_WORKLIST_DEFAULT_TAB: 'Default tab - static worklist',
  DYNAMIC_WORKLIST_DEFAULTS: 'Default tab - dynamic worklist',
  DASHBOARD_LAYOUT: 'Dashboard layout',
};
const SettingCodes = {
  plant: 'DEFAULT_PLANTS',
  companyCode: 'DEFAULT_COMPANY_CODES',
  useDefaultsGlobally: 'USE_DEFAULTS_GLOBALLY',
  defaultUserView: 'DEFAULT_USER_VIEW',
  defaultMMView: 'DEFAULT_MM_USER_VIEW',
  hideNotifications: 'HIDE_NOTIFICATIONS',
  showWidgetLegends: 'SHOW_WIDGET_LEGENDS',
  dashboardLayout: 'DASHBOARD_LAYOUT',
  grpsNdPlants: 'DEFAULT_GROUPS_AND_PLANTS',
};
export { SettingStrings, SettingCodes };
