import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import _ from 'lodash';
import { headers } from './MassUpdateMrpTableHelpers';
import { createKey } from './MassUpdateMrpHelpers';

const SAVINGS = 'Savings';
const INVESTMENT = 'Investments';
const NOIMPACT = 'No impact';

const MRPConfirm = ({
  originalValues,
  changes,
  excludeList,
}) => {
  const hasChanged = (key, field, newValue) => originalValues[key]?.[field] !== newValue;
  const columns = [
    { key: 'Plant', title: 'Plant', render: (p) => p.PLANT_FACILITY_SAP_ID },
    { key: 'Material', title: 'Material', render: (m) => m.MATERIAL_TYPE_SAP_ID },
    ...headers.map((h) => (
      {
        title: h.title,
        key: h.type,
        onCell: (record) => {
          const isChanged = hasChanged(record.key, h.type, record[h.type]);
          return { style: { background: isChanged ? '#fae1a9' : undefined } };
        },
        render: ({ [h?.type]: value }) => value,
      }
    )),
    {
      key: 'monImp',
      title: 'Monetary impact',
      onCell: (p) => {
        const impact = changes.monetaryImpacts.find((i) => i.material?.toString() === p.MATERIAL_TYPE_SAP_ID?.toString());
        let isChanged = false;
        if (impact) {
          if (impact?.impact !== 0 && impact?.impact !== '') isChanged = true;
        }
        return { style: { background: isChanged ? '#fae1a9' : undefined } };
      },
      render: (p) => {
        const impact = changes.monetaryImpacts.find((i) => i.material?.toString() === p.MATERIAL_TYPE_SAP_ID?.toString());
        if (impact && impact.impact !== '') return Math.abs(impact.impact.toFixed(2));
        return 0;
      },
    },
    {
      key: 'monImpType',
      title: 'Monetary impact type',
      onCell: (p) => {
        const impact = changes.monetaryImpacts.find((i) => i.material?.toString() === p.MATERIAL_TYPE_SAP_ID?.toString());
        let isChanged = false;
        let hasError = false;
        if (impact) {
          if (impact.impact !== 0 && impact.impact !== '') isChanged = true;
          if (impact.error) hasError = true;
        }
        // eslint-disable-next-line no-nested-ternary
        return { style: { background: hasError ? '#ff6363' : isChanged ? '#fae1a9' : undefined } };
      },
      render: (p) => {
        const impact = changes.monetaryImpacts.find((i) => i.material?.toString() === p.MATERIAL_TYPE_SAP_ID?.toString());

        if (impact) {
          if (impact.monImpType) return impact.monImpType;
          if (impact.impact < 0) return INVESTMENT;
          if (impact.impact > 0) return SAVINGS;
        }
        return NOIMPACT;
      },
    },
  ];

  const buildDataSource = (newChanges, changes) => {
    const dataWithKey = newChanges ? newChanges.map(
      (c) => ({ ...c, key: createKey(c.Plant, c.MaterialNumber) }),
    ) : [];
    const combinedData = _.cloneDeep(originalValues);
    // Remove items without changes
    Object.keys(combinedData).forEach((key) => {
      if (!dataWithKey.some((item) => item.key === key) && !changes.monetaryImpacts.some((i) => i.key === key && i.monImpType)) {
        delete combinedData[key];
      }
    });

    // Add required "_X" key for API
    dataWithKey.forEach((change) => {
      const { key } = change;
      if (combinedData[key]) {
        Object.entries(change).forEach(([prop, value]) => {
          if (change[`${prop}_X`]) {
            combinedData[key][prop] = value;
          }
        });
      }
    });

    // Convert the updated combinedData object into an array
    const combinedArray = Object.values(combinedData)
      .map((c) => ({ ...c, key: createKey(c.PLANT_FACILITY_SAP_ID, c.MATERIAL_TYPE_SAP_ID) }))
      .sort((a, b) => Number.parseInt(a.MATERIAL_TYPE_SAP_ID, 10)
          - Number.parseInt(b.MATERIAL_TYPE_SAP_ID, 10))
      .filter((obj) => !excludeList.includes(obj.MATERIAL_TYPE_SAP_ID));
    return combinedArray;
  };

  return (
    <Table
      dataSource={buildDataSource(changes?.to_Mrp, changes)}
      columns={columns}
      size="small"
      pagination={false}
      style={{ whiteSpace: 'pre-wrap' }}
      scroll={{ y: 465 }}
    />
  );
};

export default MRPConfirm;

MRPConfirm.defaultProps = {
  originalValues: {},
  changes: {},
  excludeList: [],
};

MRPConfirm.propTypes = {
  originalValues: PropTypes.objectOf(PropTypes.any),
  changes: PropTypes.shape({
    to_Mrp: PropTypes.arrayOf(PropTypes.any),
  }),
  excludeList: PropTypes.arrayOf(PropTypes.any),
};
