import { API } from '../api/typeApi';

const apiAction = ({
  type = API,
  url = '',
  method = '',
  params = null,
  data = null,
  onSuccess = () => { },
  onSuccessNotification = null,
  onFailure = () => { },
  onFailureNotification = null,
  label = '',
}) => ({
  type,
  payload: {
    url,
    method,
    params,
    data,
    onSuccess,
    onSuccessNotification,
    onFailure,
    onFailureNotification,
    label,
  },
});

export default apiAction;
