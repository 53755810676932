/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveBar } from '@nivo/bar';
import {
  Button, Empty, Row, Space,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import _ from 'lodash';
import allActions from '../../../../actions';
import {
  colors, defaultColorCodes, defaultColorMapping, defaultTypes, scales,
} from './WidgetConstants';
import { formatLargeNumber } from '../../MaterialDetails/components/TabComponents/TabGraphHelpers';
import { NUMBER_OF_RECORDS_TO_FETCH } from '../../../Common/GlobalConstants';
import {
  getDashboardFilters, getExcessStockMax, getFiltersForMatList, getMax,
  getSampleExcessStock, getSampleGroupOrSPOrCrit, getSampleOptInvStrategyData, hasDataAfterFilter,
} from './WidgetFunctions';
import { getConvertedPrice } from '../../../Common/DateAndNumberFunctions';
import TextWidgetContainer from './TextWidgetContainer';
import { widgetTypeKeys } from './widgetTypes';
import { widgetGroups, widgetViewVals } from '../../Config/WidgetViewConstants';

export default function VerticalGraphWidget({
  inventoryStatusData, showLegendSetting, canViewValues, keysVisible,
  types, label, widgetID, getFormattedData, colorMapping,
  indexBy, keys, textWidgetID,
}) {
  const selectedPlants = useSelector((state) => state.commonState.selectedPlants);
  const selectedCCs = useSelector((state) => state.commonState.selectedCompanyCodes);
  const userSettings = useSelector((state) => state.commonState.userSettings);
  const defaultCurrency = useSelector((state) => state.commonState?.userSettings?.DEFAULT_CURRENCY);
  const dashboardFilter = useSelector((state) => state.commonState.localDashboardFilters);
  const currencyList = useSelector((state) => state.commonState?.currencyList);
  const dragStatus = useSelector((state) => state.dashboardState.dragStatus);
  const widgetViews = useSelector((state) => state.commonState.widgetViews);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [maxValue, setMaxValue] = useState();
  const [scale, setScale] = useState();
  const history = useHistory();
  const [legend, setLegend] = useState(showLegendSetting ?? false);
  const [legendsList, setLegendsList] = useState([]);
  const [graphMargin, setMargin] = useState({});

  const legendProps = {
    dataFrom: 'keys',
    anchor: 'left',
    direction: 'row',
    justify: false,
    itemDirection: 'left-to-right',
    symbolShape: 'square',
    effects: [
      {
        on: 'hover',
        style: {
          itemOpacity: 1,
        },
      },
    ],
  };

  useEffect(() => {
    setLegend(!!showLegendSetting);
  }, [showLegendSetting]);

  const getLabel = (item, color) => {
    switch (color) {
      case colors.GREEN:
        return item?.GREEN_RECORDS > 0 ? formatLargeNumber(item?.GREEN_RECORDS ?? 0) : '';
      case colors.RED:
        return item?.RED_RECORDS > 0 ? formatLargeNumber(item?.RED_RECORDS ?? 0) : '';
      case colors.YELLOW:
        return item?.YELLOW_RECORDS > 0 ? formatLargeNumber(item?.YELLOW_RECORDS ?? 0) : '';
      default: return '?';
    }
  };

  const getColor = (item, color) => {
    switch (color) {
      case colors.GREEN:
        return item?.GREEN_RECORDS > 0 ? color : 'rgb(0,0,0,0)';
      case colors.RED:
        return item?.RED_RECORDS > 0 ? color : 'rgb(0,0,0,0)';
      case colors.YELLOW:
        return item?.YELLOW_RECORDS > 0 ? color : 'rgb(0,0,0,0)';
      default: return '?';
    }
  };

  const getID = (color) => {
    if (widgetID === widgetTypeKeys.excessStockByStatus) {
      switch (color) {
        case colors.GREEN:
          return 'Green';
        case colors.RED:
          return 'Red';
        case colors.YELLOW:
          return 'Yellow';
        default: return undefined;
      }
    } else {
      switch (color) {
        case colors.GREEN:
          return defaultTypes.APPROVED;
        case colors.RED:
          return defaultTypes.NOT_APPROVED;
        case colors.YELLOW:
          return defaultTypes.UNKNOWN;
        default: return undefined;
      }
    }
  };

  const getLegend = () => {
    const colorLegend = [];
    if (data.some((d) => d.GREEN_RECORDS > 0)) {
      colorLegend.push({
        label: defaultTypes.APPROVED,
        color: defaultColorCodes.Green,
      });
    }
    if (data.some((d) => d.YELLOW_RECORDS > 0)) {
      colorLegend.push({
        label: defaultTypes.UNKNOWN,
        color: defaultColorCodes.Yellow,
      });
    }
    if (data.some((d) => d.RED_RECORDS > 0)) {
      colorLegend.push({
        label: defaultTypes.NOT_APPROVED,
        color: defaultColorCodes.Red,
      });
    }
    return colorLegend;
  };

  const getLegends = (key, color) => {
    const legends = [];
    data?.forEach((item) => {
      Object.keys(item).forEach((k) => {
        if (k === key) {
          legends.push({
            id: getID(color),
            label: getLabel(item, color),
            indexValue: indexBy ? item[indexBy] : item.GROUP,
            color: getColor(item, color),
          });
        }
      });
    });
    return legends;
  };

  const handleClick = (e) => {
    if (e?.label === '' || dragStatus) return;
    const viewID = widgetViews?.find((x) => {
      const group = widgetGroups[widgetID]?.find(
        (grp) => grp.key === e.indexValue || grp[grp.key] === e.indexValue,
      );
      return x.WidgetID === widgetID && (x.GroupID === group?.key || x.GroupID === widgetViewVals.all);
    })?.ViewID;
    if (viewID) {
      dispatch(allActions.CommonActions.selectWidgetView(viewID));
    } else {
      dispatch(allActions.CommonActions.selectWidgetView(userSettings?.DEFAULT_USER_VIEW));
    }
    let searchObject = getFiltersForMatList(e, widgetID, colorMapping);
    if (selectedPlants?.length > 0) {
      searchObject = {
        ...searchObject,
        PLANT_FACILITY_SAP_ID: [
          {
            ColumnName: 'PLANT_FACILITY_SAP_ID',
            FilterOperator: 'EqualTo',
            FilterValue:
              selectedPlants,
          },
        ],
      };
    } else if (selectedCCs?.length > 0) {
      searchObject = {
        ...searchObject,
        COMPANY_CODE: [
          {
            ColumnName: 'COMPANY_CODE',
            FilterOperator: 'EqualTo',
            FilterValue:
              selectedCCs,
          },
        ],
      };
    }
    if (searchObject?.CU_DELETED) {
      searchObject.MATERIAL_TYPE_DELETION_IND = [
        {
          ColumnName: 'MATERIAL_TYPE_DELETION_IND',
          FilterOperator: 'EqualTo',
          FilterValue: ['1'],
        },
      ];
    }
    searchObject = {
      ...searchObject,
      ...getDashboardFilters(dashboardFilter),
    };
    const materialViewState = {
      headerCells: [],
      searchFilters: searchObject,
      scrollPage: {
        currentNoOfRecords: 0,
        noOfRecordsToFetch: NUMBER_OF_RECORDS_TO_FETCH,
      },
    };
    if ((selectedPlants?.length > 0
      && !_.isEqual(selectedPlants?.sort(), userSettings?.DEFAULT_PLANTS?.sort()))
      || (selectedCCs?.length > 0
        && !_.isEqual(selectedCCs?.sort(), userSettings?.DEFAULT_COMPANY_CODES?.sort()))) {
      dispatch(allActions.CommonActions.setUseDefaultSettings(false));
    }
    dispatch(allActions.MaterialListActions.setLocalMaterialView(materialViewState));
    dispatch(allActions.MaterialListActions.setSelectedRowKeys([]));
    history.push('/material-list');
  };

  const getOptInvStrategyLegend = (item) => ({
    id: item.id,
    indexValue: item.id,
    label: item?.count > 0 ? formatLargeNumber(item?.count) : '',
    color: item.color,
  });

  useEffect(() => {
    let legends = [];
    if (keysVisible) {
      legends.push({
        ...legendProps,
        anchor: 'bottom',
        translateX: 0,
        data: getLegend(),
        translateY: legend ? 95 : 45,
        itemsSpacing: 60,
        itemWidth: 100,
        itemHeight: 20,
        itemOpacity: 0.85,
        symbolSize: legend ? 12 : 20,
        symbolShape: 'circle',
      });
    }
    if (legend) {
      if (widgetID === widgetTypeKeys.optimalInventoryByStrategy) {
        legends = [...legends, {
          ...legendProps,
          data: data?.map((item) => getOptInvStrategyLegend(item)),
          onClick: handleClick,
          translateX: 0,
          translateY: 105,
          itemsSpacing: 10,
          itemWidth: 400 / (data?.length ?? 8),
          itemHeight: 20,
          itemOpacity: 0.85,
          symbolSize: 20,
          symbolShape: 'circle',
        }];
      } else {
        legends = [...legends, {
          ...legendProps,
          data: getLegends('GREEN_COLOR', colors.GREEN),
          onClick: handleClick,
          translateX: keysVisible ? -35 : 100,
          translateY: keysVisible ? 70 : 80,
          itemsSpacing: keysVisible ? 15 : 10,
          itemWidth: 400 / (data?.length ?? keysVisible ? 8 : 3),
          itemHeight: keysVisible ? 12 : 20,
          itemOpacity: 0.85,
          symbolSize: 12,
        },
        {
          ...legendProps,
          data: getLegends('RED_COLOR', colors.RED),
          onClick: handleClick,
          translateX: keysVisible ? -35 : 100,
          translateY: keysVisible ? 83 : 93,
          itemsSpacing: keysVisible ? 15 : 10,
          itemWidth: 400 / (data?.length ?? keysVisible ? 8 : 3),
          itemHeight: keysVisible ? 12 : 20,
          itemOpacity: 0.85,
          symbolSize: 12,
        },
        {
          ...legendProps,
          data: getLegends('YELLOW_COLOR', colors.YELLOW),
          onClick: handleClick,
          translateX: keysVisible ? -35 : 100,
          translateY: keysVisible ? 96 : 106,
          itemsSpacing: keysVisible ? 15 : 10,
          itemWidth: 400 / (data?.length ?? keysVisible ? 8 : 3),
          itemHeight: 20,
          itemOpacity: 0.85,
          symbolSize: 12,
        }];
      }
    }
    setLegendsList(legends);
    if (!keysVisible) {
      setMargin({
        top: 10, right: 20, bottom: legend ? 100 : 60, left: 60,
      });
    } else {
      setMargin({
        top: 10, right: 20, bottom: legend ? 115 : 70, left: 60,
      });
    }
  }, [legend, keysVisible, data]);

  useEffect(() => {
    if (!inventoryStatusData
      || inventoryStatusData.length === 0
      || _.isEmpty(inventoryStatusData)) return;
    setScale(scales.linear);
    let formattedData = getFormattedData(inventoryStatusData, dashboardFilter ?? null);
    if (widgetID === widgetTypeKeys.optimalInventoryByStrategy) {
      setMaxValue(formattedData[0]?.value * 1.2);
    } else if (widgetID === widgetTypeKeys.excessStockByStatus) setMaxValue(getExcessStockMax(formattedData?.[0]));
    else setMaxValue(getMax(formattedData?.[0]));
    if (!formattedData.length) {
      const ids = [
        widgetTypeKeys.cleanUpWidget,
        widgetTypeKeys.excessStockByStatus,
        widgetTypeKeys.optimalInventoryByCrit,
        widgetTypeKeys.optimalInventoryBySP,
        widgetTypeKeys.optimalInventoryByROP,
        widgetTypeKeys.optimalInventoryByGroup,
      ];
      if (widgetID === widgetTypeKeys.optimalInventoryByStrategy) formattedData = getSampleOptInvStrategyData();
      else if (widgetID === widgetTypeKeys.excessStockByStatus) formattedData = getSampleExcessStock();
      else if (ids.includes(widgetID)) formattedData = getSampleGroupOrSPOrCrit(widgetID, indexBy);
      setMaxValue(1);
    }
    setData(formattedData);
  }, [inventoryStatusData, dashboardFilter]);

  const updateValueScale = () => {
    const newScale = scale.type === scales.symlog.type ? scales.linear : scales.symlog;
    setScale(newScale);
  };

  const toggleLegend = () => {
    setLegend((prev) => !prev);
  };

  const getROPDeviation = (v) => {
    const color = defaultColorMapping[v.id];
    if (!color || !v.data) return 0;
    return v.data[`ROP_DEVIATION_${color}`] ?? 0;
  };

  const getInventoryValue = (v) => {
    let originalVal;
    if (!widgetID) return null;
    if (widgetID === widgetTypeKeys.optimalInventoryByStrategy) {
      originalVal = v.data?.inventory;
    } else {
      originalVal = v.data[`${colorMapping[v.id]?.toUpperCase()}_INVENTORY`] || 0;
    }
    let convertedVal = originalVal;
    let curr = 'NOK';
    if (defaultCurrency && defaultCurrency !== 'NOK') {
      const temp = currencyList?.find((x) => defaultCurrency === x?.FROM_CURRENCY);
      convertedVal = getConvertedPrice(
        originalVal, temp?.FROM_RATIO, temp?.TO_RATIO, temp?.EXCHANGE_RATE,
      );
      curr = defaultCurrency;
    }
    return `${formatLargeNumber(convertedVal)} ${curr}`;
  };

  const assignBarColors = (bar) => {
    if (!widgetID) return null;
    if (widgetID === widgetTypeKeys.optimalInventoryByStrategy) return bar.data?.color;
    return colors[colorMapping[bar.id]?.toUpperCase()];
  };

  const getNoOfRecords = (v) => {
    if (!widgetID) return null;
    if (widgetID === widgetTypeKeys.optimalInventoryByStrategy) return v.data?.count;
    return v.data[`${colorMapping[v.id].toUpperCase()}_RECORDS`];
  };

  const filterKeys = (widgetKeys, widgetData) => widgetKeys.filter(
    (key) => widgetData.find((row) => row && Object.keys(row)?.find((val) => val === key)),
  );

  const getLegendLabel = () => {
    if (widgetID !== widgetTypeKeys.cleanUpWidget) return 'Percentage';
    return 'Number of records';
  };

  const getBarLabel = (v) => {
    if (widgetID === widgetTypeKeys.cleanUpWidget) return v.value;
    return `${v.value.toFixed(1)}%`;
  };

  const getAxisLeftFormat = (v) => {
    if (widgetID === widgetTypeKeys.cleanUpWidget) return v;
    return `${v}%`;
  };

  return (!inventoryStatusData
    || inventoryStatusData.length === 0
    || !data
    || _.isEmpty(inventoryStatusData))
    || (!_.isEmpty(dashboardFilter?.filterVals) && !hasDataAfterFilter(data))
    ? (
      <Space
        direction="vertical"
        style={{
          width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center',
        }}
      >
        <Empty description={_.isEmpty(dashboardFilter?.filterVals) ? 'No data' : 'No data after filtering'} />
      </Space>
    )
    : (
      <>
        <Row justify="space-between">
          <Button onClick={toggleLegend}>
            {legend ? 'Hide legend' : 'Show legend'}
          </Button>
          {
            textWidgetID && (textWidgetID !== 'totalExcessStock' || canViewValues)
              ? (
                <TextWidgetContainer
                  widgetID={textWidgetID}
                />
              )
              : <></>
          }
          <Button
            onClick={updateValueScale}
            className="topRightButton"
          >
            {scale?.type === scales.symlog.type ? 'Show linear scale' : 'Show log. scale'}
          </Button>
        </Row>
        <ResponsiveBar
          data={data}
          keys={keys?.length > 0 ? keys : filterKeys(Object.values(types), data)}
          indexBy={indexBy}
          label={(v) => getBarLabel(v)}
          margin={graphMargin}
          padding={0.3}
          valueScale={scale}
          indexScale={{ type: 'band', round: true }}
          colors={(bar) => assignBarColors(bar)}
          tooltip={(v) => (
            <div
              style={{
                padding: 12,
                background: '#f0f2f5',
                boxShadow: '3px 3px 3px #a1a1a1',
              }}
            >
              <strong>Number of records:</strong>
              {' '}
              {getNoOfRecords(v)}
              <br />
              {
                canViewValues && widgetID !== widgetTypeKeys.cleanUpWidget
                && (
                  <>
                    {
                      widgetID === widgetTypeKeys.optimalInventoryByROP ? (
                        <>
                          <strong>ROP deviation:</strong>
                          {' '}
                          {getROPDeviation(v)}
                          <br />
                          <strong>Sum of monetary impact:</strong>
                          {' '}
                          {getInventoryValue(v)}
                        </>
                      ) : (
                        <>
                          <strong>Value of inventory:</strong>
                          {' '}
                          {getInventoryValue(v)}
                        </>
                      )
                    }
                  </>
                )
              }
            </div>
          )}
          onClick={handleClick}
          borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 10,
            legendPosition: 'middle',
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            tickValues: scale?.type === scales.symlog.type ? 4 : 10,
            legend: getLegendLabel(),
            legendPosition: 'middle',
            legendOffset: -50,
            format: (v) => getAxisLeftFormat(v),
          }}
          maxValue={maxValue}
          legends={legendsList}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{ from: 'color', modifiers: [['darker', 2.7]] }}
          role="application"
          ariaLabel={label}
        />
      </>
    );
}

VerticalGraphWidget.defaultProps = {
  showLegendSetting: false,
  keysVisible: true,
  label: '',
  keys: null,
  textWidgetID: null,
};

VerticalGraphWidget.propTypes = {
  inventoryStatusData: PropTypes.arrayOf(PropTypes.any).isRequired,
  showLegendSetting: PropTypes.bool,
  canViewValues: PropTypes.bool.isRequired,
  types: PropTypes.objectOf(PropTypes.string).isRequired,
  colorMapping: PropTypes.objectOf(PropTypes.string).isRequired,
  keysVisible: PropTypes.bool,
  label: PropTypes.string,
  widgetID: PropTypes.string.isRequired,
  getFormattedData: PropTypes.func.isRequired,
  indexBy: PropTypes.string.isRequired,
  keys: PropTypes.arrayOf(PropTypes.string),
  textWidgetID: PropTypes.string,
};
