import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button, Input, Row, Upload,
} from 'antd';
import {
  DownloadOutlined, SaveOutlined, UploadOutlined,
} from '@ant-design/icons';
import { v4 as uuid } from 'uuid';
import TextArea from 'antd/lib/input/TextArea';
import { downloadNewStockResults, downloadNewStockTemplate, readXLSX } from './ExcelFunctions';
import FormOptions from './FormOptions';
import allActions from '../../../../actions';
import ConfirmModal from '../../../Common/ConfirmModal';
import ReferenceMaterial from './ReferenceMaterial';

const ButtonRow = ({
  fileList, setFileList, rows, setRows, currentKeyNo, setKeyNo,
  dataSource, setDataSource, resetAll, getResultRows, getHighestId, goToPage,
  insertReference,
}) => {
  const allForms = useSelector((state) => state.simState.newStockData?.allNewStockForms);
  const user = useSelector((state) => state.authState?.user);
  const [formName, setFormName] = useState('');
  const formNameRef = useRef('');
  const formDescRef = useRef('');
  const [errText, setErrText] = useState('');

  const [modalOptions, setModalOptions] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [okButtonDisabled, setOkButtonDisabled] = useState(false);

  const dispatch = useDispatch();

  const validateFormName = (input) => {
    const newFormName = input?.trim() || '';
    formNameRef.current = newFormName;
    setFormName(newFormName);
    if (!newFormName || newFormName === '') {
      setErrText('Please enter a name');
    } else if (allForms?.some((x) => x.formName === newFormName)) {
      setErrText('Name is already in use');
    } else {
      setErrText('');
      setOkButtonDisabled(false);
    }
  };

  const updateFormDescription = (desc) => {
    formDescRef.current = desc;
  };

  const saveFormContent = (error) => (
    <>
      <Input placeholder="Form Name" onChange={(e) => validateFormName(e.target?.value)} />
      <div style={{ color: 'red', minHeight: '24px' }}>
        {error}
      </div>
      <TextArea placeholder="Form Description" onChange={(e) => updateFormDescription(e.target?.value)} />
    </>
  );

  const saveFormFunction = () => {
    const metadata = {
      formId: uuid(),
      userId: user?.uniqueId,
      formName: formNameRef.current,
      description: formDescRef.current,
      formData: rows,
      results: getResultRows(dataSource, rows.length),
    };
    dispatch(allActions.SimulationActions.saveNewStockForm(metadata));
    setModalVisible(false);
  };

  const saveModalSettings = {
    modalTitle: 'Save New Stock form',
    modalContent: saveFormContent(errText),
    modalOkText: 'Confirm',
    modalStyle: {
      minWidth: '350px',
    },
    modalOnOk: saveFormFunction,
  };

  const referenceModalSettings = {
    modalTitle: 'Get Reference Material',
    modalContent: <ReferenceMaterial />,
    modalOkText: 'Insert into form',
    modalStyle: {
      minWidth: '350px',
    },
    modalOnOk: insertReference,
  };

  const buttonStyle = {
    width: '200px',
    marginRight: '12px',
    marginBottom: '6px',
  };

  const checkIfNullTable = (tableRows) => {
    let isNullTab = true;
    tableRows.forEach((tabRow) => {
      Object.keys(tabRow)?.forEach((key) => {
        if (key !== 'itemText' && key !== 'key') {
          if (tabRow[key]) {
            isNullTab = false;
          }
        }
      });
    });
    return isNullTab;
  };

  const loadSelectedForm = (formId) => {
    const selectedForm = allForms.find((form) => form.formId === formId);
    if (selectedForm) {
      const temp = JSON.parse(selectedForm.formData);
      const newRows = temp?.form ?? [];
      setRows([...newRows]);
      if (temp?.results?.length) {
        if (checkIfNullTable(temp.results)) {
          setDataSource([]);
        } else {
          setDataSource(temp.results);
        }
      }
      goToPage(1);
      const highestId = getHighestId(newRows, 'newStockFormRow');
      setKeyNo(highestId + newRows.length + 10);
    }
  };

  const addToForm = (form) => {
    const tempRows = JSON.parse(form?.formData ?? null)?.form;
    if (!tempRows) return; // Throw error before returning
    const highestId = getHighestId(tempRows, 'newStockFormRow');
    if (highestId === null) return; // Throw error before returning
    const newRows = rows?.map((formRow, i) => {
      const temp = { ...formRow };
      temp.key = 'newStockFormRow'.concat(highestId + ((i + 1) * 10));
      return temp;
    });
    const formData = JSON.parse(form?.formData);
    let res = [];
    if (dataSource?.length !== rows?.length) {
      res = getResultRows(dataSource, rows.length);
    } else {
      res = [...dataSource];
    }
    let finalResults = [];
    if (formData.results) {
      finalResults = [...formData.results, ...res];
    } else {
      finalResults = [...getResultRows([], tempRows.length), ...res];
    }
    const metadata = {
      formId: form.formId,
      userId: user?.uniqueId,
      formData: [...tempRows, ...newRows],
      results: finalResults,
    };
    dispatch(allActions.SimulationActions.saveNewStockForm(metadata,
      `Successfully added ${newRows.length} rows from current form to ${form.formName} form`,
      `Failed to add current form rows to ${form.formName} form`));
  };

  const overwriteFormFunction = (form) => {
    let res = [];
    if (dataSource?.length !== rows?.length) {
      res = getResultRows(dataSource, rows?.length);
    } else {
      res = [...dataSource];
    }
    const metadata = {
      formId: form.formId,
      userId: user?.uniqueId,
      formData: rows,
      results: res,
    };

    dispatch(allActions.SimulationActions.saveNewStockForm(metadata,
      `Form ${form.formName} is successfully overwritten with the current form`));
    setModalVisible(false);
  };

  const overwriteModalOptions = (form) => ({
    modalTitle: 'Overwrite new stock Addition form',
    modalContent: `Overwrite the form "${form?.formName}" with the current form?`,
    modalOkText: 'Confirm',
    formName: '',
    formDesc: '',
    modalStyle: {
      minWidth: '350px',
    },
    modalOnOk: () => overwriteFormFunction(form),
  });

  const overwriteForm = (form) => {
    setModalOptions(overwriteModalOptions(form));
    setModalVisible(true);
    setOkButtonDisabled(false);
  };

  const deleteFormFunction = (form) => {
    dispatch(allActions.SimulationActions.deleteNewStockForm(
      user?.uniqueId, form?.formId, form?.formName,
    ));
    setModalVisible(false);
  };

  const deleteModalOptions = (form) => ({
    modalTitle: 'Delete New Stock Addition form',
    modalContent: `Overwrite the form "${form?.formName}" with the current form?`,
    modalOkText: 'Confirm',
    formName: '',
    formDesc: '',
    modalStyle: {
      minWidth: '350px',
    },
    modalOnOk: () => deleteFormFunction(form),
  });

  const deleteForm = (form) => {
    setModalOptions(deleteModalOptions(form));
    setModalVisible(true);
  };

  const downloadResults = () => {
    downloadNewStockResults(rows, dataSource);
  };

  const onSaveFormSelect = () => {
    validateFormName(formName);
    setModalOptions(saveModalSettings);
    setModalVisible(true);
  };

  // eslint-disable-next-line no-unused-vars
  const getReference = () => {
    setModalOptions(referenceModalSettings);
    setModalVisible(true);
  };

  useEffect(() => {
    setOkButtonDisabled(!!errText?.length);
    setModalOptions(saveModalSettings);
  }, [errText]);

  return (
    <Row>
      <ConfirmModal
        message={modalOptions?.modalContent}
        title={modalOptions?.modalTitle}
        okText={modalOptions?.modalOkText}
        onOK={modalOptions?.modalOnOk}
        style={modalOptions?.modalStyle}
        okButtonDisabled={okButtonDisabled}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
      />
      <Button style={buttonStyle} type="primary" onClick={downloadNewStockTemplate}>
        <DownloadOutlined />
        Download template
      </Button>
      <Upload
        accept=".xlsx"
        fileList={fileList}
        beforeUpload={(file) => {
          resetAll();
          const reader = new FileReader();
          reader.readAsArrayBuffer(file);
          reader.onload = () => {
            const buffer = reader.result;
            readXLSX(buffer, setRows, currentKeyNo, setKeyNo);
            setFileList([{
              name: file.name,
            }]);
          };
          return false;
        }}
        maxCount={1}
      >
        <Button style={buttonStyle} type="primary">
          <UploadOutlined />
          Upload from template
        </Button>
      </Upload>
      <Button
        style={buttonStyle}
        onClick={onSaveFormSelect}
        type="primary"
      >
        <SaveOutlined />
        Save as New Form
      </Button>
      <FormOptions
        buttonStyle={buttonStyle}
        loadSelectedForm={loadSelectedForm}
        addToForm={addToForm}
        overwriteForm={overwriteForm}
        deleteForm={deleteForm}
      />
      <Button
        type="primary"
        onClick={downloadResults}
        style={buttonStyle}
        disabled={!dataSource || dataSource?.length === 0}
      >
        Download results
      </Button>
      <Button
        type="primary"
        onClick={resetAll}
        style={buttonStyle}
      >
        Reset Form and Results
      </Button>
    </Row>
  );
};

export default ButtonRow;

ButtonRow.defaultProps = {};

ButtonRow.propTypes = {
  fileList: PropTypes.arrayOf(PropTypes.any).isRequired,
  setFileList: PropTypes.func.isRequired,
  rows: PropTypes.arrayOf(PropTypes.any).isRequired,
  setRows: PropTypes.func.isRequired,
  currentKeyNo: PropTypes.number.isRequired,
  setKeyNo: PropTypes.func.isRequired,
  dataSource: PropTypes.arrayOf(PropTypes.any).isRequired,
  setDataSource: PropTypes.func.isRequired,
  resetAll: PropTypes.func.isRequired,
  getResultRows: PropTypes.func.isRequired,
  getHighestId: PropTypes.func.isRequired,
  goToPage: PropTypes.func.isRequired,
  insertReference: PropTypes.func.isRequired,
};
