import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import {
  Button, Card, Table, Upload,
} from 'antd';
import {
  DownloadOutlined, UploadOutlined, LoadingOutlined, CheckCircleOutlined,
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import PlantMaterialSelector from './plantMaterialSelecor/PlantMaterialSelector';
import allActions from '../../../../actions';
import {
  getDataSource, getRowClassName, objectConstructor,
} from './MassUpdateMrpHelpers';
import { configColumns, headers } from './MassUpdateMrpTableHelpers';
import ConfirmModal from '../../../Common/ConfirmModal';
import MRPConfirm from './MRPConfirm';
import {
  getDeliveryTimePayload, getInitialValuesForMassUpdate, downloadNewStockResults,
  generateExcelValues,
  readXLSX,
  lockedColumns,
} from './utils';
import { createIOTAPayloadMassMRP, createIOTAPayloadMassMRPnoChanges } from '../../MaterialDetails/components/SummaryComponents/Recommendations/Generators';
import styles from './styles.module.scss';
import { updateSelectedMaterials } from '../utils';
import { massUpdate } from '../massUpdateGlobals';
import CommonOperations from '../../MaterialDetails/components/SummaryComponents/Recommendations/CommonOperations';
import { tableColumns } from '../../MaterialDetails/components/SummaryComponents/Recommendations/RecommendationsData';
import { showNotification } from '../../../../actions/utilities/Notifications/NotificationActions';
import { getCorrectTokenFromUser } from '../../../../utils/token';

const MassUpdateMrp = ({ matIds, currentTab }) => {
  if (currentTab !== massUpdate.MRP) return null;
  const dispatch = useDispatch();
  const [materialData, setMaterialData] = useState([]);
  const user = useSelector((state) => state.authState.user);
  const [loading, setLoading] = useState(false);
  const groupChangeId = useSelector((state) => state.materialDetailsState.changeGroupIdFromMassUpdateMRP);
  const [relatedPlants, setRelatedPlants] = useState([]);

  const [plant, setPlant] = useState();
  const [selectedMaterials, setSelectedMaterials] = useState([]);
  const [materialsToUse, setMaterialsToUse] = useState([]);
  const [error, setError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [excludeList, setExcludeList] = useState([]);
  const [originalValues, setOriginalValues] = useState({});
  const [resetChanges, setResetChanges] = useState(false);
  const [tableKey, setTableKey] = useState(0);
  const [hasChanges, setHasChanges] = useState(false);
  const [delegateMenuVisible, setDelegateMenuVisible] = useState(false);

  const [ready, setReady] = useState(false);
  const [changes, setChanges] = useState({});
  const [oldValues, setOldValues] = useState({});
  const [showRevertButton, setShowRevertButton] = useState(false);
  const [displayTable, setDisplayTable] = useState(false);
  const [hasChangesToND, setHasChangesToND] = useState(null);
  const [hasNDChangesModal, setHasNDChangesModal] = useState(false);

  const [populateInputs, setPopulateInputs] = useState(false);
  const [calculateMonImp, setCalculateMonImp] = useState(false);

  const [revertNoMrpChanges, setRevertNoMrpChanges] = useState(false);

  const [fetchedPlant, setFetchedPlant] = useState(null);
  const [mrpsForRelatedPlants, setMrpsForRelatedPlants] = useState(null);

  const [revertedChangelog, setRevertedChangelog] = useState([]);

  const [recTableLayout, setRecTableLayout] = useState({});

  const [commonOperations, setCommonOperations] = useState({});

  const [sendingData, setSendingData] = useState(false);

  const [excelValues, setExcelValues] = useState([]);
  const [fileList] = useState([]);

  const [showDescription, setShowDescription] = useState(false);

  const [hasFetchErrors, setHasErrors] = useState(false);

  useEffect(() => {
    if (matIds?.ids?.length > 0) {
      if (matIds.plant) setPlant(matIds.plant?.toString());
      updateSelectedMaterials(matIds.ids, setSelectedMaterials, dispatch);
    }
  }, []);

  const removeNonValidMaterials = (data) => {
    const materialDatas = Object.values(data).map((obj) => obj.MATERIAL_TYPE_SAP_ID);
    const validMaterials = selectedMaterials.filter(
      (material) => materialDatas.some((id) => id?.toString() === material?.toString()),
    );
    return validMaterials;
  };

  const fetchMrpsForRelatedPlants = async (ids) => {
    const mrpData = [];
    if (selectedMaterials.length > 50) {
      const chunkSize = 150;
      const arrayOfArrays = [];

      for (let i = 0; i < selectedMaterials.length; i += chunkSize) {
        const chunk = selectedMaterials.slice(i, i + chunkSize);
        arrayOfArrays.push(chunk);
      }

      await Promise.all(ids.map(async (id) => {
        await Promise.all(arrayOfArrays.map(async (arr) => {
          const data = await allActions.SapAPIActions.getMRPsWithFetch([id], arr, getCorrectTokenFromUser(user));
          mrpData.push(...data);
        }));
      }));
    } else {
      const mrpsForRelatedPlants = await allActions.SapAPIActions.getMRPsWithFetch([ids], selectedMaterials, getCorrectTokenFromUser(user));
      mrpData.push(...mrpsForRelatedPlants);
    }
    setMrpsForRelatedPlants(mrpData);
  };

  const getMrpsForRelatedPlants = async (fetchedRelatedPlants) => {
    const relatedPlantsString = fetchedRelatedPlants.map((relPlant) => relPlant.Plant_ID);
    if (relatedPlantsString && relatedPlantsString?.length > 0) {
      await fetchMrpsForRelatedPlants(relatedPlantsString);
    }
  };

  const getMaterialDataForRelatedPlants = async (fetchedRelatedPlants) => {
    const additionalMaterials = [];
    fetchedRelatedPlants[plant]?.forEach((relatedPlant) => {
      materialsToUse.forEach((material) => {
        additionalMaterials.push({
          plantID: relatedPlant?.Plant_ID?.toString(),
          materialID: material?.toString(),
        });
      });
    });
    if (additionalMaterials?.length > 0) {
      const relatedPlantsMatData = await allActions.MaterialDetailsActions.fetchMaterialsAsync(additionalMaterials);
      setMaterialData((prev) => ([...prev, ...relatedPlantsMatData]));
    }
  };

  const updateOriginalValues = (values, errors) => {
    if (errors?.length > 0) {
      errors.forEach((err) => {
        const key = createKey(plant, err.material);
        values[key].errorMessage = err.message;
      });
    }
    setOriginalValues(values);
  };

  useEffect(() => {
    if (hasFetchErrors) {
      setTableKey((k) => k + 1);
    }
  }, [originalValues, hasFetchErrors]);

  const createCorrectDataStructure = (mrpDataFetched, materialDataFetched) => {
    const formattedData = objectConstructor(mrpDataFetched, materialDataFetched);
    const validMaterials = removeNonValidMaterials(formattedData);
    updateOriginalValues(formattedData);
    setExcelValues(generateExcelValues({}, formattedData));
    setMaterialsToUse(validMaterials);
    const setToExcluded = selectedMaterials.filter((id) => !validMaterials.includes(id));
    setExcludeList(setToExcluded);
    setFetchedPlant(mrpDataFetched);
    const newRecTableData = _.cloneDeep(tableColumns());
    const recTable = getInitialValuesForMassUpdate(materialDataFetched, newRecTableData, mrpDataFetched, plant);
    setRecTableLayout(recTable);
  };

  const handleRelatedPlants = async (relatedPlantsFetched) => {
    await getMaterialDataForRelatedPlants(relatedPlantsFetched);
    await getMrpsForRelatedPlants(relatedPlantsFetched);
  };

  const createChunks = (list) => {
    const chunkSize = 150;
    const arrayOfArrays = [];

    const arrayToUse = list || selectedMaterials;

    for (let i = 0; i < arrayToUse.length; i += chunkSize) {
      const chunk = arrayToUse.slice(i, i + chunkSize);
      arrayOfArrays.push(chunk);
    }
    return arrayOfArrays;
  };

  const getMrpData = async () => {
    const mrpData = [];
    if (selectedMaterials.length > 150) {
      const arrayOfArrays = createChunks();

      await Promise.all(arrayOfArrays.map(async (arr) => {
        const data = await allActions.SapAPIActions.getMRPsWithFetch([plant], arr, getCorrectTokenFromUser(user), dispatch);
        mrpData.push(...data);
      }));
    } else {
      const data = await allActions.SapAPIActions.getMRPsWithFetch([plant], selectedMaterials, getCorrectTokenFromUser(user), dispatch);
      mrpData.push(...data);
    }

    return mrpData;
  };

  const retrieveMaterials = async (isReverting) => {
    if (!isReverting) {
      setRelatedPlants([]);
    }
    setMaterialData([]);
    setLoading(true);
    setHasChanges(false);
    setDisplayTable(true);
    setHasChangesToND(null);
    if (!plant || !(selectedMaterials?.length > 0)) return;
    const plantMaterials = selectedMaterials.map((materialID) => ({
      plantID: plant,
      materialID,
    }));
    if (!isReverting) {
      const relatedPlants = await allActions.MaterialDetailsActions.fetchPlantRelationshipsAsync(plant, getCorrectTokenFromUser(user), dispatch);
      setRelatedPlants(relatedPlants);
      if (relatedPlants.length > 0) {
        await handleRelatedPlants(relatedPlants);
      }
    }
    const matData = await allActions.MaterialDetailsActions.fetchMaterialsAsync(plantMaterials, getCorrectTokenFromUser(user), dispatch);
    const data = await getMrpData();
    setMaterialData((prev) => ([...prev, ...matData]));
    createCorrectDataStructure(data, matData);
    setLoading(false);
  };

  useEffect(() => {
    /* Force rerender of table to refill initial values */
    if (resetChanges) {
      setTableKey((k) => k + 1);
    }
  }, [resetChanges]);

  const setBigDataIfManyItems = () => {
    retrieveMaterials();
  };

  const updateChangelogWithoutMRPChanges = (monetaryImpactChanges, otherChanges) => {
    const iotaPayload = createIOTAPayloadMassMRPnoChanges(monetaryImpactChanges, user);
    dispatch(allActions.MaterialDetailsActions.createMassMrpChangelog(iotaPayload));
    if (!otherChanges) {
      dispatch(allActions.CommonActions.clearMonetaryImpactSummary());
      setShowRevertButton(true);
      setResetChanges(true);
    }
    setLoading(false);
  };

  const updateSuccessful = async (isReverting) => {
    if (isReverting) {
      setShowRevertButton(false);
      if (revertedChangelog?.changelog?.length > 0) {
        const iotaPayload = createIOTAPayloadMassMRP(revertedChangelog, user);
        if (iotaPayload.length > 150) {
          const arrayOfArrays = createChunks(iotaPayload);
          await Promise.all(arrayOfArrays.map(async (arr) => {
            dispatch(allActions.MaterialDetailsActions.createMassMrpChangelog(arr, false));
          }));
        } else {
          dispatch(allActions.MaterialDetailsActions.createMassMrpChangelog(iotaPayload, false));
        }
      }
    } else {
      const iotaPayload = createIOTAPayloadMassMRP(changes, user);
      if (iotaPayload.length > 150) {
        const arrayOfArrays = createChunks(iotaPayload);
        await Promise.all(arrayOfArrays.map(async (arr) => {
          dispatch(allActions.MaterialDetailsActions.createMassMrpChangelog(arr, false));
        }));
      } else {
        dispatch(allActions.MaterialDetailsActions.createMassMrpChangelog(iotaPayload, false));
      }
      const deliveryTimePayload = getDeliveryTimePayload(plant, fetchedPlant, relatedPlants, mrpsForRelatedPlants, changes, materialData);
      if (deliveryTimePayload.to_Mrp.length > 0) {
        await allActions.SapAPIActions.setMrpsAsync(deliveryTimePayload, getCorrectTokenFromUser(user), dispatch);
      }
      setShowRevertButton(true);
    }
    setSendingData(false);
    retrieveMaterials(isReverting);
    setResetChanges(true);
    dispatch(showNotification({
      Type: 'success',
      Title: 'MRP data updated',
    }));
  };

  const resetAll = (includePlantMaterials = true) => {
    if (includePlantMaterials) {
      setPlant();
      setSelectedMaterials([]);
    }
    setHasErrors(false);
    setMaterialData([]);
    setRelatedPlants([]);
    setMaterialsToUse([]);
    setResetChanges(true);
    setError(false);
    setShowModal(false);
    setExcludeList([]);
    setOriginalValues({});
    setTableKey(0);
    setReady(false);
    setChanges({});
    setDisplayTable(false);
  };

  useEffect(() => {
    if (materialsToUse.length > 0) {
      resetAll(false);
    }
  }, [plant, selectedMaterials]);

  const previewChanges = () => {
    setReady(true);
  };

  const createKey = (p, m) => `${p}-${Number.parseInt(m, 10)}`;

  useEffect(() => {
    if (Object.keys(changes).length > 0) {
      const NDChanges = [];
      const { changelog } = changes;
      changelog.forEach((change) => {
        if (change.changeType.includes('MRPType') && change.oldValue !== 'ND' && change.newValue === 'ND' && !excludeList.find((material) => change.MaterialNumber.includes(material))) {
          const material = originalValues?.[createKey(change.Plant, change.MaterialNumber)];
          if (material?.docCount > 0 || material?.TOT_STOCK > 0) {
            NDChanges.push(change);
          }
        }
      });
      if (NDChanges.length > 0) {
        setHasChangesToND(NDChanges);
        setHasNDChangesModal(true);
        setShowModal(false);
      } else setShowModal(true);
    }
  }, [changes, ready]);

  const submitChanges = async () => {
    setShowModal(false);
    setLoading(true);
    setSendingData(true);
    setHasErrors(false);
    // eslint-disable-next-line camelcase
    const { MrpBatch, to_Mrp, monetaryImpacts } = changes;
    const revertedChanges = changes?.changelog?.map(
      (change) => ({ ...change, newValue: change.oldValue, oldValue: change.newValue }),
    );
    if (revertedChanges.length > 0) {
      setRevertedChangelog({ ...changes, changelog: revertedChanges });
    }
    const changeObject = { MrpBatch, to_Mrp };
    const noMrpChanges = [];
    const mrpChanges = [];
    monetaryImpacts.forEach((impact) => {
      const change = to_Mrp.find((i) => i.MaterialNumber.includes(impact.material));
      if (change) {
        mrpChanges.push(impact);
      } else {
        noMrpChanges.push(impact);
      }
    });
    if (mrpChanges.length > 0 || to_Mrp.length > 0) {
      const errors = await allActions.SapAPIActions.setMrpsAsync(changeObject, getCorrectTokenFromUser(user), dispatch);
      if (errors) {
        updateOriginalValues(originalValues, errors);
        setHasErrors(true);
        setLoading(false);
      } else {
        setHasErrors(false);
        updateSuccessful();
      }
    } else if (noMrpChanges.length > 0) setRevertNoMrpChanges(true);
    if (noMrpChanges.length > 0) updateChangelogWithoutMRPChanges(noMrpChanges, mrpChanges.length > 0);
  };

  const submitRevert = async () => {
    setShowRevertButton(false);
    if (!revertNoMrpChanges) {
      setLoading(true);
      const errors = await allActions.SapAPIActions.setMrpsAsync(oldValues, getCorrectTokenFromUser(user), dispatch);
      if (errors) {
        updateOriginalValues(originalValues);
        setHasErrors(true);
        setLoading(false);
      } else {
        setHasErrors(false);
        updateSuccessful(true);
      }
    }
    dispatch(allActions.MaterialDetailsActions.revertChangeLog({ id: groupChangeId }));
  };

  const resetPopulateInputs = () => {
    setPopulateInputs(false);
  };

  const resetCalculateMonImp = () => {
    setCalculateMonImp(false);
  };

  const notValidData = () => {
    const { monetaryImpacts } = changes;
    if (!changes.to_Mrp) return true;
    const hasError = monetaryImpacts.find((i) => i.error === true);
    if (hasError) return true;
    return false;
  };

  const updateSelected = (selectedMats) => {
    updateSelectedMaterials(selectedMats, setSelectedMaterials, dispatch);
  };

  const getCorrectValues = (commonValues, key) => {
    const object = { ...recTableLayout[key].current };
    headers.forEach((header) => {
      if (commonValues[header.type] === 'KEEP') recTableLayout[key][header.type];
      else if (commonValues[header.type]) object[header.type] = commonValues[header.type];
      else {
        object[header.type] = '';
      }
    });
    return object;
  };

  const getRecOrCurValues = (value, type, key) => {
    const object = { ...recTableLayout[key].current };
    headers.forEach((header) => {
      object[header.type] = value[type][header.type];
    });
    return object;
  };

  const updateChanges = (commonObject) => {
    if (commonObject.type) {
      const temp = {};
      const { type } = commonObject;
      materialsToUse.forEach((mat) => {
        if (excludeList.includes(mat)) return null;
        const key = createKey(plant, mat);
        const matData = commonObject.data[key];
        temp[key] = getRecOrCurValues(matData, type, key);
        return null;
      });
      setCommonOperations(temp);
    } else {
      const temp = {};
      materialsToUse.forEach((mat) => {
        if (excludeList.includes(mat)) return null;
        const key = createKey(plant, mat);
        temp[key] = getCorrectValues(commonObject.data, key);
        return null;
      });
      setCommonOperations(temp);
    }
  };

  const [dropdownValues, setDropdownValues] = useState({});
  const [importedValues, setImportedValues] = useState([]);

  const updateExcelValues = (values, monImps) => {
    const copy = JSON.parse(JSON.stringify(originalValues));
    const rows = generateExcelValues(values, copy, monImps);
    setExcelValues(rows);
  };

  const uploadValuesFromImport = (rows) => {
    setExcludeList([]);
    const inputs = {};
    const monImps = [];
    const tempExcludeList = [];

    let wrongList = false;

    rows.forEach((row) => {
      const key = createKey(row.PLANT_FACILITY_SAP_ID, row.MATERIAL_TYPE_SAP_ID);
      if (row.selected !== 'Selected') tempExcludeList.push(row.MATERIAL_TYPE_SAP_ID);
      delete row.selected;
      Object.entries(row).forEach((value) => {
        if (!originalValues[key] || Object.keys(originalValues).length !== rows.length) wrongList = true;
        if (originalValues[key] && row[value[0]] !== originalValues[key][value[0]]) {
          if (!lockedColumns.includes(value[0])) {
            inputs[key] = { ...inputs[key], [value[0]]: typeof value[1] === 'undefined' ? '' : value[1] };
          }
        }
      });
      if (originalValues[key] && (row.monetaryImpact !== '0' || row.monetaryImpactType !== 'No impact')) {
        const newImpact = {
          impact: typeof row.monetaryImpact === 'undefined' ? '0' : row.monetaryImpact,
          monImpType: row.monetaryImpactType,
          plant: row.PLANT_FACILITY_SAP_ID,
          material: row.MATERIAL_TYPE_SAP_ID,
          edited: true,
          key,
          error: (row.monetaryImpact === '0' && row.monetaryImpactType !== 'No impact') || (row.monetaryImpactType !== '0' && row.monetaryImpactType === 'No impact'),
        };
        monImps.push(newImpact);
      }
    });

    if (!wrongList) {
      setImportedValues({ inputs, monImps });
      setExcludeList(tempExcludeList);
      setHasChanges(Object.keys(inputs).length > 0 || monImps.length > 0);
      if (Object.keys(inputs).length > 0 || monImps.length > 0) {
        dispatch(showNotification(
          {
            Type: 'success',
            Title: 'MRP data uploaded from excel',
          },
        ));
      }
    } else {
      dispatch(showNotification(
        {
          Type: 'warning',
          Title: 'There is a difference between mass update data and excel data. Make sure you upload the correct file.',
        },
      ));
    }
    setLoading(false);
  };

  return (
    <Card>
      <ConfirmModal
        style={{
          minWidth: '30vw', minHeight: '80vh', maxWidth: '80vw', maxHeight: '80vh',
        }}
        title="Changes to MRP Type"
        visible={hasNDChangesModal}
        onOK={() => {
          setHasNDChangesModal(false);
          setHasChangesToND(null);
          setShowModal(true);
        }}
        onCancel={() => {
          setHasChangesToND(null);
          setHasNDChangesModal(false);
          setChanges({});
        }}
        message={(
          <div className={styles.NDChanges}>
            <h4>Warning: Materials with open docs or total stock has changed MRP type to ND!</h4>
            {hasChangesToND && hasChangesToND.map((change) => (
              <div>
                Plant:
                {change.Plant}
                {' '}
                - Material:
                {' '}
                {change.MaterialNumber}
              </div>
            ))}
            <div style={{ marginTop: '2rem' }}>Click confirm to continue.</div>
          </div>
        )}
      />
      <ConfirmModal
        style={{
          minWidth: '80vw', minHeight: '80vh', maxWidth: '80vw', maxHeight: '80vh',
        }}
        title="Confirm changes"
        visible={showModal}
        onOK={submitChanges}
        okButtonDisabled={notValidData()}
        onCancel={() => {
          setShowModal(false);
          setChanges({});
        }}
        message={(
          <MRPConfirm
            originalValues={originalValues}
            changes={changes}
            excludeList={excludeList}
          />
        )}
      />
      <div className={styles.massUpdateMrpContainer}>
        <PlantMaterialSelector
          plant={plant}
          updatePlant={(p) => setPlant(p)}
          materials={selectedMaterials}
          setMaterials={updateSelected}
          onOK={setBigDataIfManyItems}
          error={error}
          setError={setError}
          resetAll={resetAll}
          validMaterials={materialsToUse}
        />
        <div className={styles.tableContainer}>
          {selectedMaterials.length > 150 && loading && (
          <div className={styles.loadingView}>
            <div className={styles.loadingContent}>
              <h1 style={{ fontWeight: '700' }} className={styles.loadingText}>
                <LoadingOutlined spin />
                {' '}
                {sendingData ? 'Updating' : 'Fetching'}
                {' '}
                large amounts of data
              </h1>
            </div>
          </div>
          )}

          <Table
            key={tableKey}
            dataSource={displayTable ? getDataSource(selectedMaterials) : []}
            rowClassName={(v, i) => getRowClassName(i, v, materialsToUse)}
            className={styles.table}
            showSorterTooltip={false}
            columns={configColumns(materialData, loading, excludeList, setExcludeList, plant, materialsToUse, selectedMaterials, originalValues, resetChanges, setResetChanges, ready, setReady, setChanges, setOldValues, setHasChanges, populateInputs, resetPopulateInputs, calculateMonImp, resetCalculateMonImp, commonOperations, updateExcelValues,
              setDropdownValues, importedValues, setImportedValues, showDescription, setShowDescription, hasFetchErrors)}
            size="small"
            scroll={{ x: showDescription ? 2100 : 1500, y: 398 }}
            pagination={false}
            footer={() => (

              <div className={styles.buttonRow}>
                <Button
                  style={{ display: showRevertButton ? 'inherit' : 'none' }}
                  className="revertButton"
                  onClick={submitRevert}
                >
                  Undo submitted change
                </Button>
                <div className={styles.extraButtons}>
                  <Button
                    className="secondary"
                    onClick={() => {
                      downloadNewStockResults(excelValues, excludeList, dropdownValues);
                    }}
                    disabled={Object.keys(originalValues).length === 0}
                  >
                    <DownloadOutlined />
                    Download MRP data
                  </Button>
                  <Upload
                    accept=".xlsx"
                    fileList={fileList}
                    beforeUpload={(file) => {
                      const reader = new FileReader();
                      reader.readAsArrayBuffer(file);
                      reader.onload = () => {
                        const buffer = reader.result;
                        readXLSX(buffer, uploadValuesFromImport);
                      };
                      return false;
                    }}
                    maxCount={1}
                  >
                    <Button className="secondary" disabled={Object.keys(originalValues).length === 0}>
                      <UploadOutlined />
                      Upload MRP data
                    </Button>
                  </Upload>
                  <CommonOperations
                    delegateMenuVisible={delegateMenuVisible}
                    setDelegateMenuVisible={setDelegateMenuVisible}
                    recTableData={recTableLayout}
                    setRecTableData={updateChanges}
                    isMassUpdate
                  />
                  <Button
                    className="secondary"
                    onClick={() => setPopulateInputs(true)}
                  >
                    Populate inputs
                  </Button>
                  <Button
                    className="secondary"
                    onClick={() => setCalculateMonImp(true)}
                  >
                    Calculate Mon Imp
                  </Button>
                  <Button
                    type="secondary"
                    onClick={() => {
                      setResetChanges(true);
                      setHasChanges(false);
                    }}
                    disabled={!hasChanges}
                  >
                    Reset all changes
                  </Button>
                  <Button
                    disabled={!hasChanges}
                    type="primary"
                    onClick={previewChanges}
                  >
                    Submit
                  </Button>
                </div>
              </div>

            )}
          />
        </div>
      </div>
    </Card>
  );
};

export default MassUpdateMrp;

MassUpdateMrp.propTypes = {
  currentTab: PropTypes.number.isRequired,
  matIds: PropTypes.shape({
    ids: PropTypes.arrayOf(PropTypes.string),
    plant: PropTypes.string,
  }),
};

MassUpdateMrp.defaultProps = {
  matIds: PropTypes.shape({
    ids: [],
    plant: '',
  }),
};
