import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { useHistory } from 'react-router';
import getColumns from '../CommonResources';

const StaticWorklistTable = ({
  worklists, worklistCount, updateSelectedItems, wlRoute, selectedRowKeys, setSelectedRowKeys,
}) => {
  const intialState = {
    searchText: '',
    searchedColumn: '',
  };
  const history = useHistory();
  const [columnsState, setColumnsState] = useState(intialState);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setColumnsState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setColumnsState({ searchText: '' });
  };

  const handleClick = (path, worklistID) => {
    history.push(`/${path}/${worklistID}`);
  };

  const tableWorklistProps = {
    footer: () => (
      <div className="tableRecordCount">
        {worklistCount && (
          <span>
            Showing
            {` ${worklistCount} `}
            of
            {` ${worklistCount} `}
            items
          </span>
        )}
      </div>
    ),
    scroll: { y: window.innerHeight - 350 },
    pagination: false,
    rowSelection: {
      type: 'checkbox',
      onChange: (_selectedRowKeys, selectedRows) => {
        updateSelectedItems(selectedRows);
        setSelectedRowKeys(_selectedRowKeys);
      },
      selectedRowKeys,
    },
  };

  const columns = getColumns(columnsState, handleSearch, handleReset);
  return (
    <div>
      {worklists && (
        <Table
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...tableWorklistProps}
          rowClassName={(record, index) => (index % 2 === 0 ? 'table-row-light' : 'table-row-dark')}
          columns={columns}
          dataSource={worklists}
          onRow={(record) => ({
            onClick: (e) => {
              if (e?.target?.cellIndex === 0) return;
              handleClick(wlRoute, record.WORKLIST_ID);
            },
          })}
        />
      )}
    </div>
  );
};

export default StaticWorklistTable;

StaticWorklistTable.defaultProps = {
  worklists: [],
  worklistCount: 0,
  selectedRowKeys: [],
};

StaticWorklistTable.propTypes = {
  worklists: PropTypes.arrayOf(PropTypes.any),
  worklistCount: PropTypes.number,
  updateSelectedItems: PropTypes.func.isRequired,
  wlRoute: PropTypes.string.isRequired,
  selectedRowKeys: PropTypes.arrayOf(PropTypes.string),
  setSelectedRowKeys: PropTypes.func.isRequired,
};
