import { useState } from 'react';

const useSelectedItems = () => {
  const [selectedItems, setSelectedItems] = useState([]);

  const resetSelectedItems = () => {
    setSelectedItems([]);
  };

  const updateSelected = (items) => {
    setSelectedItems(items);
  };

  return {
    selectedItems, updateSelected, resetSelectedItems,
  };
};

export default useSelectedItems;
