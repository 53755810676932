import React, { useEffect } from 'react';
import { Tabs } from 'antd';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import Simulation from './Simulation';
import MultiPlant from './MultiPlant';
import allActions from '../../../../../../actions';
import * as loadingKeys from '../../../../../../actions/MaterialDetailsDomain/LoadingKeys';
import { getPlantCluster } from './SimulationFunctions';
import { formatMaterialNumber } from '../../../../../Common/DateAndNumberFunctions';

const { TabPane } = Tabs;

const SimulationContainer = ({ onClose, setSimulatedValues }) => {
  const dispatch = useDispatch();
  const plantRelationships = useSelector((state) => state.materialDetailsState.plantRelationships);
  const materials = useSelector((state) => state.materialDetailsState.material);
  const mrpData = useSelector((state) => state.materialDetailsState.mrpSettings);
  const { plant: focusedPlant, material } = useParams();

  useEffect(() => {
    // get related of related plants, needed for multi-plant sim UI
    if (plantRelationships && plantRelationships[focusedPlant]?.length > 0) {
      plantRelationships[focusedPlant].forEach((plant) => {
        dispatch(allActions.MaterialDetailsActions.fetchPlantRelationships(plant.Plant_ID));
      });
    }
  }, [focusedPlant]);

  useEffect(() => {
    const plantCluster = getPlantCluster(plantRelationships, focusedPlant);

    // load material data and MRP data for related and related of related plants
    const fetchMRP = [];
    plantCluster.forEach((_plant) => {
      if (!materials.some((_material) => (_material.MATERIAL_TYPE_SAP_ID === material
        && _material.PLANT_FACILITY_SAP_ID === _plant))) {
        dispatch(allActions.MaterialDetailsActions.fetchAdditionalMaterial(_plant, material));
      }
      if (!mrpData?.some((m) => m.Plant === _plant
        && formatMaterialNumber(m.MaterialNumber) === material)) {
        fetchMRP.push(_plant);
      }
    });
    if (fetchMRP.length > 0) {
      dispatch(allActions.MaterialDetailsActions.setLoading(loadingKeys.mrp));
      dispatch(allActions.SapAPIActions.getMRPs(fetchMRP, [material]));
    }
  }, [plantRelationships]);

  return (
    <Tabs style={{ minWidth: '100%' }}>
      <TabPane tab="Simulation" key="1">
        <Simulation onClose={onClose} setSimulatedValues={setSimulatedValues} />
      </TabPane>
      <TabPane tab="Multi-plant" key="2" disabled={plantRelationships?.[Number(focusedPlant)]?.length < 1}>
        <MultiPlant />
      </TabPane>
    </Tabs>
  );
};

export default SimulationContainer;

SimulationContainer.propTypes = {
  onClose: PropTypes.func.isRequired,
  setSimulatedValues: PropTypes.func.isRequired,
};
