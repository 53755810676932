import React, { useEffect, useState } from 'react';
import {
  Card, Empty, Button, Divider, Tooltip,
} from 'antd';
import moment from 'moment';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { CheckOutlined } from '@ant-design/icons';
import { NOTIFICATION_TYPES, NUMBER_OF_NOTIFICATIONS_TO_FETCH } from '../Common/GlobalConstants';
import './Notifications.css';
import allActions from '../../actions';
import { formatMaterialNumber } from '../Common/DateAndNumberFunctions';

const UserPanel = ({
  notifications, notificationCount, userID, close, notificationTypes, hideDone, offset, setOffset,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [scrolledToEnd, setScrolledToEnd] = useState(false);

  useEffect(() => {
    if (scrolledToEnd && notificationTypes) {
      if (notifications?.length && notifications.length >= (hideDone
        ? notificationCount.unread
        : notificationCount.total)) return;
      dispatch(allActions.CommonActions.getAdditionalNotifications(
        userID,
        notificationTypes,
        NUMBER_OF_NOTIFICATIONS_TO_FETCH,
        offset + NUMBER_OF_NOTIFICATIONS_TO_FETCH,
        hideDone,
      ));
      setOffset((_offset) => _offset + NUMBER_OF_NOTIFICATIONS_TO_FETCH);
      setScrolledToEnd(false);
    }
  }, [scrolledToEnd]);

  useEffect(() => {
    setScrolledToEnd(false);
  }, [hideDone]);

  useEffect(() => {
    document.getElementById('notificationList').addEventListener('scroll', (e) => {
      const diff = Math.abs((e.target.scrollTop + e.target.clientHeight) - (e.target.scrollHeight));
      if (diff < 5) {
        setScrolledToEnd(true);
      }
    });
  }, []);

  const acceptNotification = (e, notification) => {
    e.stopPropagation();
    if (!notification.accepted) {
      dispatch(allActions.CommonActions.acceptNotification(
        notification.id, userID, notificationTypes,
      ));
    }
  };

  const goToNotification = (notification) => {
    if (notification.type === NOTIFICATION_TYPES.PAST_DUE) {
      let params = null;
      try {
        params = JSON.parse(notification.parameters);
        const path = `/material-details/${params.plant}/${params.material}`;
        history.push(path);
        close();
      } catch { /**/ }
    }
  };

  const title = (notification) => {
    switch (notification.type) {
      case (NOTIFICATION_TYPES.TEST):
        return (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span className={notification.accepted ? '' : 'newNotificationTitle'}>Test</span>
            <span>{moment(notification.generatedTime).fromNow()}</span>
          </div>
        );
      case (NOTIFICATION_TYPES.PAST_DUE):
        return (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span className={notification.accepted ? '' : 'newNotificationTitle'}>
              Decision Past Due
            </span>
            <Divider type="vertical" style={{ height: '1.5em' }} />
            <span>{moment(notification.generatedTime).fromNow()}</span>
            <span>
              <Tooltip title={notification.accepted ? 'Done' : 'Mark as done'} placement="bottom">
                <Button
                  size="small"
                  onClick={(e) => acceptNotification(e, notification)}
                  disabled={notification.accepted}
                >
                  <CheckOutlined />
                </Button>
              </Tooltip>
            </span>
          </div>
        );
      case (NOTIFICATION_TYPES.SIM_FAILED):
      case (NOTIFICATION_TYPES.SIM_COMPLETE):
        return (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span className={notification.accepted ? '' : 'newNotificationTitle'}>
              {`Simulation ${notification.type === NOTIFICATION_TYPES.SIM_FAILED ? 'Failed' : 'Complete'}`}
            </span>
            <Divider type="vertical" style={{ height: '1.5em' }} />
            <span>{moment(notification.generatedTime).fromNow()}</span>
            <span>
              <Tooltip title={notification.accepted ? 'Done' : 'Mark as done'} placement="bottom">
                <Button
                  size="small"
                  onClick={(e) => acceptNotification(e, notification)}
                  disabled={notification.accepted}
                >
                  <CheckOutlined />
                </Button>
              </Tooltip>
            </span>
          </div>
        );
      default:
        return 'Notification';
    }
  };

  const content = (type, parameters, text) => {
    let params = null;
    try {
      params = JSON.parse(parameters);
    } catch { /**/ }
    switch (type) {
      case (NOTIFICATION_TYPES.PAST_DUE):
        if (params) {
          return (
            <>
              {text}
              <br />
              Click to go to material:
              <Link to={`/material-details/${params.plant}/${params.material}`}>{`  ${params.plant}/${params.material}`}</Link>
            </>
          );
        }
        break;
      case (NOTIFICATION_TYPES.SIM_FAILED):
      case (NOTIFICATION_TYPES.SIM_COMPLETE):
        if (params) {
          const simType = params?.type;
          const materials = params?.material.split(',').map((m) => formatMaterialNumber(m));
          return (
            <>
              {`${simType === 'multi' ? 'Scenario ' : 'Single material '} simulation ${type === NOTIFICATION_TYPES.SIM_FAILED ? 'failed' : 'complete'}.`}
              <br />
              {`Plant: ${params.plant} / `}
              {`Material${materials.length > 1 ? 's' : ''}: `}
              {materials.length > 4 ? 'multiple' : ''}
              {materials.length < 5 && materials.length > 1 && materials.join(', ')}
              {materials.length === 1 && materials[0]}
              <br />
              {'Click to go to: '}
              <Link to={`/simulations/${params?.runid}`}>simulation results</Link>
            </>
          );
        }
        break;
      default:
        return null;
    }
    return null;
  };

  const displayNotification = (notification) => (
    <Card
      className={notification.accepted ? 'acceptedNotification' : 'newNotification'}
      key={notification.key}
      title={title(notification)}
      size="small"
      style={{ marginBottom: 10 }}
      hoverable
      onClick={() => goToNotification(notification)}
    >
      {content(notification.type, notification.parameters, notification.text)}
    </Card>
  );

  return (
    <div
      id="notificationList"
      style={{
        maxHeight: '40vh', overflowY: 'auto', width: '24vw',
      }}
    >
      {notifications?.length > 0
        ? notifications.map((notification) => displayNotification(notification))
        : <Empty description="You do not have any notifications." />}
    </div>
  );
};

export default UserPanel;

UserPanel.defaultProps = {
  notifications: null,
  notificationCount: {
    unread: 0,
    total: 0,
  },
  userID: null,
  notificationTypes: null,
  hideDone: false,
  offset: 0,
};

UserPanel.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.object),
  notificationCount: PropTypes.shape({
    unread: PropTypes.number,
    total: PropTypes.number,
  }),
  userID: PropTypes.string,
  close: PropTypes.func.isRequired,
  notificationTypes: PropTypes.arrayOf(PropTypes.string),
  hideDone: PropTypes.bool,
  offset: PropTypes.number,
  setOffset: PropTypes.func.isRequired,
};
