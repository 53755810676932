import React from 'react';
import { Card, PageHeader, Tabs } from 'antd';
import CostConfigView from './CostConfigView';
import OutlierView from './OutlierView';

const ConfigViewContainer = () => {
  const { TabPane } = Tabs;

  return (
    <Card style={{ minHeight: '50vh' }}>
      <PageHeader
        className="configHeader"
        title="Configuration Settings"
        ghost={false}
      />
      <Tabs defaultActiveKey="1">
        <TabPane tab="Cost configuration" key="1">
          <CostConfigView />
        </TabPane>
        <TabPane tab="Outlier configuration" key="2">
          <OutlierView />
        </TabPane>
      </Tabs>
    </Card>
  );
};

export default ConfigViewContainer;
