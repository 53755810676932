import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Dropdown, Menu, Space,
} from 'antd';
import { DownOutlined } from '@ant-design/icons';

const CommonOperations = ({
  delegateMenuVisible, setDelegateMenuVisible, recTableData, setRecTableData, isMassUpdate,
}) => {
  const keepCurrent = 'KEEP';
  const operationType = {
    ND: 'Set ND',
    PD: 'Set PD',
    V1: 'Set V1',
    CURRENT: 'Set to Current',
    RECOMMENDED: 'Set to Recommended',
  };

  const commonOperationValues = {
    [operationType.ND]: {
      MRPType: 'ND',
      LotSize: 'EX',
      MRPController: keepCurrent,
      PlantMaterialStatus: keepCurrent,
    },
    [operationType.PD]: {
      MRPType: 'PD',
      LotSize: 'EX',
      MRPGroup: keepCurrent,
      MRPController: keepCurrent,
      PlantMaterialStatus: keepCurrent,
      ABCIndicator: keepCurrent,
    },
    [operationType.V1]: {
      MRPType: 'V1',
      LotSize: 'ZB',
      ReorderPoint: 1,
      MaximumStockLevel: 1,
      MRPGroup: '0003',
      MRPController: keepCurrent,
      PlantMaterialStatus: keepCurrent,
      ABCIndicator: keepCurrent,
    },
  };

  const setData = (type) => {
    setDelegateMenuVisible(false);
    let updatedData;
    if (isMassUpdate) {
      if (type === operationType.RECOMMENDED) {
        updatedData = { data: recTableData, type: 'recommended' };
      } else if (type === operationType.CURRENT) {
        updatedData = { data: recTableData, type: 'current' };
      } else {
        updatedData = { data: commonOperationValues?.[type] };
      }
    } else if (type === operationType.RECOMMENDED) {
      updatedData = recTableData.map((setting) => ({ ...setting, userDefined: setting.recommended || '' }));
    } else if (type === operationType.CURRENT) {
      updatedData = recTableData.map((setting) => ({ ...setting, userDefined: setting.current || '' }));
    } else {
      updatedData = recTableData.map((setting) => {
        const newValue = commonOperationValues?.[type]?.[setting.key] || '';
        const userDefined = (newValue === keepCurrent) ? setting.current : newValue;
        return { ...setting, userDefined };
      });
    }
    setRecTableData(updatedData);
  };

  const commonOperationsMenu = (
    <Menu>
      {
        Object.keys(operationType).map((type) => (
          <Menu.Item key={type} onClick={() => setData(operationType[type])}>
            {operationType[type]}
          </Menu.Item>
        ))
      }
    </Menu>
  );
  return (
    <div>
      <Dropdown overlay={commonOperationsMenu} placement="bottomRight" visible={delegateMenuVisible}>
        <Button
          className="headerButton"
          style={isMassUpdate && { height: '100%' }}
          size="small"
          onClick={() => setDelegateMenuVisible(!delegateMenuVisible)}
        >
          Common operations
          <DownOutlined />
        </Button>
      </Dropdown>
    </div>
  );
};

export default CommonOperations;

CommonOperations.propTypes = {
  delegateMenuVisible: PropTypes.bool.isRequired,
  setDelegateMenuVisible: PropTypes.func.isRequired,
  recTableData: PropTypes.arrayOf(PropTypes.any),
  setRecTableData: PropTypes.func.isRequired,
};

CommonOperations.defaultProps = {
  recTableData: [],
};
