import { Card, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import allActions from '../../../actions';
import AllSimResults from './AllSimResults';

const AllSimResultsContainer = () => {
  const user = useSelector((state) => state.authState.user);
  const allSimJobs = useSelector((state) => state.simState.allSimJobs);
  const simResults = useSelector((state) => state.simState.simResults);
  const [selectedItems, setSelectedItems] = useState([]);
  const [deleteModal, setDeleteModal] = useState();
  const [deleteSingle, setDeleteSingle] = useState();
  const dispatch = useDispatch();
  const { runID } = useParams();

  useEffect(() => {
    if (user?.account?.username && user?.idToken) {
      dispatch(allActions.SimulationActions.getAllSimJobs(user.account.username));
    }
  }, [user?.account?.username, user?.idToken]);

  useEffect(() => {
    if (runID) dispatch(allActions.SimulationActions.retrieveSimulation(runID));
  }, [runID]);

  const expandSimJob = (expanded, record) => {
    if (!expanded) return;
    dispatch(allActions.SimulationActions.retrieveSimulation(record.RunID));
  };

  const deleteSelected = () => {
    setDeleteSingle(false);
    setDeleteModal(true);
  };

  const deleteSpecific = (e, RunID) => {
    e.stopPropagation();
    e.preventDefault();

    setDeleteSingle(RunID);
    setDeleteModal(true);
  };

  const deleteSingleSim = () => {
    dispatch(allActions.SimulationActions.deleteSim([[deleteSingle]], user?.account?.username));
    setDeleteSingle(false);
    setDeleteModal(false);
    setSelectedItems([]);
  };

  const deleteSelectedSims = () => {
    const runIDs = selectedItems.map((item) => [item.RunID]);
    dispatch(allActions.SimulationActions.deleteSim(runIDs, user?.account?.username));
    setDeleteModal(false);
    setSelectedItems([]);
  };

  return (
    <>
      <AllSimResults
        allSimJobs={allSimJobs}
        simResults={simResults}
        expandSimJob={expandSimJob}
        updateSelectedItems={setSelectedItems}
        deleteSelected={deleteSelected}
        selectedItems={selectedItems}
        deleteSpecific={deleteSpecific}
      />

      <Modal
        title="Delete"
        visible={deleteModal}
        onOk={() => (deleteSingle ? deleteSingleSim() : deleteSelectedSims())}
        onCancel={() => setDeleteModal(false)}
        okText="Confirm"
      >
        Are you sure you want to delete the following simulation(s)?
        <Card>
          {deleteSingle || selectedItems.map((sim) => (
            <div key={sim.RunID}>
              {sim.RunID}
              <br />
            </div>
          ))}
        </Card>
      </Modal>

    </>
  );
};

export default AllSimResultsContainer;
