/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Skeleton, Empty } from 'antd';
import _ from 'lodash';
import { ResponsiveBar } from '@nivo/bar';
import allActions from '../../../../actions';
import { formatLargeNumber } from '../../MaterialDetails/components/TabComponents/TabGraphHelpers';
import * as loadingKeys from '../../../../actions/DashboardDomain/LoadingKeys';
import { MODES, NUMBER_OF_RECORDS_TO_FETCH } from '../../../Common/GlobalConstants';
import './Widgets.css';
import { getDashboardFilters, getMonetaryImpactTot } from './WidgetFunctions';
import { getConvertedPrice, getCurrency } from '../../../Common/DateAndNumberFunctions';
import styles from './styles.module.scss';
import { widgetViewVals } from '../../Config/WidgetViewConstants';

const MonetaryImpact = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const selectedPlants = useSelector((state) => state.commonState.selectedPlants);
  const selectedCCs = useSelector((state) => state.commonState.selectedCompanyCodes);
  const dashboardMode = useSelector((state) => state.commonState.dashboardMode);
  const monetaryImpact = useSelector((state) => state.dashboardState.monetaryImpact);
  const settingsLoaded = useSelector((state) => state.commonState?.loaded?.userSettings);
  const loading = useSelector((state) => state.dashboardState.loading);
  const userSettings = useSelector((state) => state.commonState.userSettings);
  const dashboardFilter = useSelector((state) => state.commonState.localDashboardFilters);
  const currencyList = useSelector((state) => state.commonState?.currencyList);
  const dragStatus = useSelector((state) => state.dashboardState.dragStatus);
  const widgetViews = useSelector((state) => state.commonState.widgetViews);
  const SQLOnly = useSelector((state) => state.commonState?.SQLOnly?.dashboard);
  const [impact, setImpact] = useState({});

  const selectedExists = () => {
    if (selectedCCs?.length === 0 && selectedPlants?.length === 0 && monetaryImpact?.A) {
      return true;
    }
    if (dashboardMode === MODES.PLANT) {
      const key = `P${selectedPlants?.sort().join('')}`;
      return !!monetaryImpact?.[key];
    }
    const key = `CC${selectedCCs?.sort().join('')}`;
    return !!monetaryImpact?.[key];
  };

  const updateCache = () => {
    let tempData;
    const currenctCurrency = userSettings?.DEFAULT_CURRENCY ?? 'NOK';
    let temp = currencyList?.find((x) => currenctCurrency === x?.FROM_CURRENCY);
    if (!temp) temp = [1, 1, 1];
    else temp = [temp.FROM_RATIO, temp.TO_RATIO, temp.EXCHANGE_RATE];
    if (selectedCCs?.length === 0 && selectedPlants?.length === 0) {
      tempData = _.cloneDeep(monetaryImpact.A);
    } else if (dashboardMode === MODES.PLANT) {
      tempData = _.cloneDeep(monetaryImpact[`P${selectedPlants?.sort().join('')}`]);
    } else {
      tempData = _.cloneDeep(monetaryImpact[`CC${selectedCCs?.sort().join('')}`]);
    }
    tempData.data = getMonetaryImpactTot(tempData, dashboardFilter);
    if (!tempData.data?.length) {
      setImpact(tempData);
      return;
    }
    tempData.data[0].Impact = getConvertedPrice(
      tempData.data[0].Impact, ...temp,
    );
    tempData.data[1].Impact = getConvertedPrice(
      tempData.data[1].Impact, ...temp,
    );
    setImpact(tempData);
  };

  useEffect(() => {
    if (selectedPlants && selectedCCs && settingsLoaded && dashboardMode
      && (SQLOnly === true || SQLOnly === false)) {
      if (selectedExists()) {
        updateCache();
      } else {
        dispatch(allActions.DashboardActions.setLoading(loadingKeys.monetaryImpact));
        dispatch(allActions.DashboardActions.getMonetaryImpactVals(
          dashboardMode === MODES.PLANT ? selectedPlants : null,
          dashboardMode === MODES.CC ? selectedCCs : null,
          dashboardMode,
          null,
          SQLOnly,
        ));
      }
    }
  }, [selectedPlants, selectedCCs, settingsLoaded, SQLOnly,
    dashboardMode, monetaryImpact, userSettings, dashboardFilter]);

  const label = (value) => {
    if (value === 0) return '';
    if (Number.isNaN(value)) return value;
    return `${formatLargeNumber(
      Math.abs(value), true,
    )} ${getCurrency(true, userSettings?.DEFAULT_CURRENCY)}`;
  };

  const handleClick = (e) => {
    if (e?.label === '' || dragStatus) return;
    const viewID = widgetViews?.find(
      (x) => x.WidgetID === 'monetaryImpact' && (x.GroupID === e.indexValue || x.GroupID === widgetViewVals.all),
    )?.ViewID;
    if (viewID) {
      dispatch(allActions.CommonActions.selectWidgetView(viewID));
    } else {
      dispatch(allActions.CommonActions.selectWidgetView(userSettings?.DEFAULT_USER_VIEW));
    }
    if (e?.id && !e.indexValue) e.indexValue = e.id;
    let searchObject = {
      DISPLAY_REC_IND: [
        {
          ColumnName: 'DISPLAY_REC_IND',
          FilterOperator: 'EqualTo',
          FilterValue: [
            'True',
          ],
        },
      ],
      MONETARY_IMPACT_TYPE: [
        {
          ColumnName: 'MONETARY_IMPACT_TYPE',
          FilterOperator: 'EqualTo',
          FilterValue: [
            e.indexValue,
          ],
        },
      ],
    };
    if (selectedPlants?.length > 0) {
      searchObject = {
        ...searchObject,
        PLANT_FACILITY_SAP_ID: [
          {
            ColumnName: 'PLANT_FACILITY_SAP_ID',
            FilterOperator: 'EqualTo',
            FilterValue:
              selectedPlants,
          },
        ],
      };
    } else if (selectedCCs?.length > 0) {
      searchObject = {
        ...searchObject,
        COMPANY_CODE: [
          {
            ColumnName: 'COMPANY_CODE',
            FilterOperator: 'EqualTo',
            FilterValue:
              selectedCCs,
          },
        ],
      };
    }
    searchObject = {
      ...searchObject,
      ...getDashboardFilters(dashboardFilter),
    };
    const materialViewState = {
      headerCells: [],
      searchFilters: searchObject,
      scrollPage: {
        currentNoOfRecords: 0,
        noOfRecordsToFetch: NUMBER_OF_RECORDS_TO_FETCH,
      },
    };
    if ((selectedPlants?.length > 0
      && !_.isEqual(selectedPlants?.sort(), userSettings?.DEFAULT_PLANTS?.sort()))
      || (selectedCCs?.length > 0
        && !_.isEqual(selectedCCs?.sort(), userSettings?.DEFAULT_COMPANY_CODES?.sort()))) {
      dispatch(allActions.CommonActions.setUseDefaultSettings(false));
    }
    dispatch(allActions.MaterialListActions.setLocalMaterialView(materialViewState));
    dispatch(allActions.MaterialListActions.setSelectedRowKeys([]));
    history.push('/material-list');
  };

  const getEdge = () => {
    const values = impact?.data.map((x) => Math.abs(x.Impact));
    return Math.round(Math.max(values[0], values[1]));
  };

  const colorArray = ['#D5EAF4', '#B2D4D7'];
  const getLegend = () => [...impact?.data].map((item, index) => ({
    label: item.MONETARY_IMPACT_TYPE,
    color: colorArray[index],
    id: 'Impact',
    indexValue: item.MONETARY_IMPACT_TYPE,
  }));

  return (
    <div className={styles.widgetHeight}>
      {
        impact?.data
          && !loading?.[loadingKeys?.monetaryImpact]
          && ((dashboardMode === MODES.PLANT && _.isEqual(impact?.plants, selectedPlants))
            || (dashboardMode === MODES.CC && _.isEqual(impact?.companyCodes, selectedCCs)))
          ? (
            impact?.data[0]
              ? (
                <ResponsiveBar
                  data={impact?.data}
                  indexBy="MONETARY_IMPACT_TYPE"
                  keys={['Impact']}
                  layout="horizontal"
                  colors={colorArray}
                  colorBy="indexValue"
                  label={(d) => (label(d?.value))}
                  tooltip={() => <></>}
                  onClick={handleClick}
                  enableGridX
                  enableGridY={false}
                  minValue={-getEdge()}
                  maxValue={getEdge()}
                  margin={{
                    bottom: 50,
                  }}
                  axisLeft={{
                    format: () => '',
                    tickSize: 0,
                  }}
                  axisBottom={{
                    legendPosition: 'middle',
                    legendOffset: -5,
                    tickPadding: 0,
                    format: (v) => formatLargeNumber(v),
                  }}
                  legends={[
                    {
                      anchor: 'bottom',
                      direction: 'row',
                      justify: false,
                      dataFrom: 'indexes',
                      data: getLegend(),
                      onClick: handleClick,
                      itemsSpacing: 2,
                      translateX: 20,
                      translateY: 50,
                      itemWidth: 100,
                      itemHeight: 20,
                      itemDirection: 'left-to-right',
                      itemOpacity: 0.85,
                      symbolSize: 20,
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemOpacity: 1,
                          },
                        },
                      ],
                    },
                  ]}
                  theme={{
                    fontSize: 12,
                    axis: {
                      ticks: {
                        text: {
                          fontSize: 12,
                        },
                      },
                    },
                    legends: {
                      text: {
                        fontSize: 12,
                      },
                    },
                  }}
                />
              ) : <Empty description={_.isEmpty(dashboardFilter?.filterVals) ? 'No data' : 'No data after filtering'} />
          )
          : (
            <Skeleton
              active
              paragraph={{ rows: 2 }}
            />
          )
      }
    </div>
  );
};
export default MonetaryImpact;
