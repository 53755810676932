import MaterialListActions from './MaterialListDomain/MaterialListActions';
import CommonActions from './common/CommonActions';
import DashboardActions from './DashboardDomain/dashboardActions';
import WorkListActions from './WorkListDomain/WorkListActions';
import MaterialDetailsActions from './MaterialDetailsDomain/MaterialDetailsAction';
import AuthActions from './auth/AuthActions';
import SimulationActions from './SimulationDomain/SimulationActions';
import SapAPIActions from './SapAPIDomain/SapAPIActions';
import TestActions from './TestDomain/TestActions';
import MMActions from './MaterialMasterDomain/MaterialMasterActions';

const allActions = {
  MaterialListActions,
  MMActions,
  CommonActions,
  DashboardActions,
  WorkListActions,
  MaterialDetailsActions,
  AuthActions,
  SimulationActions,
  SapAPIActions,
  TestActions,
};

export default allActions;
