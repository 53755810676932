import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { PropTypes } from 'prop-types';
import _ from 'lodash';
import MaterialDetailsCard from './components/SummaryComponents/MaterialDetailsCard';
import PlantDetailsCard from './components/SummaryComponents/PlantDetailsCard';
import { formatMaterialNumber } from '../../Common/DateAndNumberFunctions';
import hasAuthenticatedRole from '../../Common/AuthFunctions';

function SummaryElements({ visible, setVisible }) {
  const { plant, material } = useParams();
  const data = useSelector((state) => state.materialDetailsState.material);
  const mrp = useSelector((state) => state.materialDetailsState.mrpSettings);
  const longText = useSelector((state) => state.materialDetailsState.longText);
  const classification = useSelector((state) => state.materialDetailsState.classification);
  const loading = useSelector((state) => state.materialDetailsState.loading);
  const defaultCurrency = useSelector((state) => state.commonState?.userSettings?.DEFAULT_CURRENCY);
  const currencyList = useSelector((state) => state.commonState?.currencyList);
  const userState = useSelector((state) => state.authState.user);
  const [plantData, setPlantData] = useState();
  const [mrpData, setmrpData] = useState();
  const [canViewPrices, setCanViewPrices] = useState(false);
  const [canWriteback, setCanWriteback] = useState(false);

  const addAdditionalData = (initialData) => {
    if (initialData) {
      const updatedPlantData = _.clone(initialData);
      updatedPlantData.POtext = (longText
        && longText?.material === material)
        ? longText[plant]?.PO_text
        : '';
      updatedPlantData.internal_comment = (longText
        && longText?.material === material)
        ? longText[plant]?.internal_comment
        : '';
      updatedPlantData.material_memo = (formatMaterialNumber(mrpData?.MaterialNumber) === material
        && mrpData?.Plant === plant)
        ? mrpData?.to_MrpLongText?.results?.find((e) => e?.TextId === 'LTXT')?.LongText
        : '';
      if (formatMaterialNumber(mrpData?.MaterialNumber) === material && mrpData?.Plant === plant) {
        updatedPlantData.Pl_Ord_Txt = mrpData?.to_MrpLongText?.results?.find((e) => e?.TextId === 'Z901')?.LongText;
        let lastChecked = '-';
        if (mrpData?.LastCheckedDate) {
          const tempDate = new Date(Number.parseInt(mrpData.LastCheckedDate?.replace(/\//g, '')?.replace('Date(', '')?.replace(')', ''), 10));
          if (!Number.isNaN(typeof tempDate?.getFullYear())) {
            const date = tempDate?.getDate() < 10 ? `0${tempDate?.getDate()}` : tempDate?.getDate();
            const month = tempDate?.getMonth() < 9 ? `0${tempDate?.getMonth() + 1}` : tempDate?.getMonth();
            if (tempDate) lastChecked = `${date}.${month}.${tempDate?.getFullYear()}`;
          }
        }
        updatedPlantData.LastCheckedDate = lastChecked;
      }
      setPlantData(updatedPlantData);
    }
  };

  useEffect(() => {
    const newData = data?.find(
      (entry) => entry?.PLANT_FACILITY_SAP_ID === plant && entry?.MATERIAL_TYPE_SAP_ID === material,
    );
    addAdditionalData(newData);
  }, [data, plant, material, mrpData]);

  useEffect(() => {
    setmrpData(mrp.find(
      (m) => formatMaterialNumber(m.MaterialNumber) === material && m?.Plant === plant,
    ));
  }, [plant, material, mrp]);

  useEffect(() => {
    addAdditionalData(plantData);
  }, [longText, mrpData]);

  useEffect(() => {
    if (classification?.material === material && plantData) {
      plantData.classification = classification;
    }
  }, [classification, plantData]);

  useEffect(() => {
    if (userState?.idTokenClaims?.roles) {
      const roles = userState?.idTokenClaims?.roles;
      const userCanWriteBack = hasAuthenticatedRole(
        roles, process.env.REACT_APP_IOTA_COMMENTER,
      );
      setCanWriteback(userCanWriteBack);
      const userCanViewPrices = hasAuthenticatedRole(
        roles, process.env.REACT_APP_VIEW_PRICES,
      );
      setCanViewPrices(userCanViewPrices);
    }
  }, [userState?.idTokenClaims]);

  return (
    <Row>
      <Col span={12}>
        <MaterialDetailsCard
          data={plantData}
          classification={classification}
          loading={loading}
          canViewPrices={canViewPrices}
          canWriteback={canWriteback}
        />
      </Col>
      <Col span={12}>
        <PlantDetailsCard
          data={plantData}
          visible={visible}
          setVisible={setVisible}
          defaultCurrency={defaultCurrency}
          currencyList={currencyList}
          canViewPrices={canViewPrices}
        />
      </Col>
    </Row>
  );
}

SummaryElements.defaultProps = {
  visible: false,
};

SummaryElements.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func.isRequired,
};

export default SummaryElements;
