import {
  Button, PageHeader, Row, Table, Tabs,
} from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { successIcon, columns } from './helpers';
import {
  materialListObjects,
  materialDetailsObjects,
  commonObjects,
  dashboardObjects,
  sapObjects,
  simulationObjects,
  worklistObjects,
} from './endpoints';
import allActions from '../../../actions';

export const TestCenter = () => {
  const { TabPane } = Tabs;
  const dispatch = useDispatch();
  const testState = useSelector((state) => state.testState);
  const collections = [
    materialListObjects,
    materialDetailsObjects,
    commonObjects,
    dashboardObjects,
    sapObjects,
    simulationObjects,
    worklistObjects,
  ];

  const runTest = (endpoint) => {
    if (endpoint) dispatch(endpoint);
  };
  const runAllTests = () => {
    let count = 0;
    collections.forEach((collection) => {
      collection.forEach((testObject) => {
        if (!testState[testObject.action]?.success) {
          setTimeout(() => dispatch(testObject.endpoint), 500 * (count += 1));
        }
      });
    });
  };

  const clearTests = () => {
    dispatch(allActions.TestActions.resetTestResults());
  };

  const typeKeys = {
    materialList: 'Material List',
    materialDetails: 'Material Details',
    common: 'Common',
    dashboard: 'Dashboard',
    sap: 'SAP',
    simulation: 'Simulation',
    worklist: 'Worklist',
  };

  const typeIndexes = {
    0: 'materialList',
    1: 'materialDetails',
    2: 'common',
    3: 'dashboard',
    4: 'sap',
    5: 'simulation',
    6: 'worklist',
  };

  const getArgs = (testObject) => {
    const payload = testObject.endpoint?.payload;
    return payload.params || payload.data
      ? JSON.stringify(payload.params || payload.data)
      : '';
  };

  return (
    <PageHeader
      title={(
        <>
          <Row>
            Test Center
          </Row>
          <Row>
            <Button onClick={runAllTests}>Run remaining tests</Button>
            <Button onClick={clearTests}>Clear test results</Button>
          </Row>
        </>
      )}
      ghost={false}
      style={{ minHeight: '50vh', minWidth: '50vw' }}
    >
      <Tabs>
        {
          collections.map((collection, index) => (
            <TabPane
              tab={(
                <>
                  {typeKeys[typeIndexes[index]]}
                  {successIcon(collection, testState, true)}
                </>
              )}
              key={typeIndexes[index]}
            >
              <Table
                dataSource={collection.map((testObject) => ({
                  key: testObject.label,
                  endpointName: testObject.label,
                  args: getArgs(testObject),
                  button: <Button onClick={() => runTest(testObject.endpoint)}>Test</Button>,
                  action: testObject.action,
                  success: successIcon(testObject, testState),
                  errorMsg: `${testState[testObject.action]?.error || ''}`,
                }))}
                columns={columns}
                pagination={false}
                size="small"
              />
            </TabPane>
          ))
        }
      </Tabs>
    </PageHeader>
  );
};

export default TestCenter;
