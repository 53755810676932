import {
  EditOutlined, DeleteOutlined, SaveOutlined,
} from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import {
  Table, Modal, Button, Skeleton, Select, Input,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import _ from 'lodash';
import { createSapPayload, addSimulatedToRecTable, createIOTAPayload } from './Generators';
import allActions from '../../../../../../actions';
import { tableColumns, dropdownColumns, originalColumns } from './RecommendationsData';
import { getDropdownValues, getInitialValues } from './RecommendationHelpers';
import '../SummaryStyle.css';
import ReviewChanges from './ReviewChanges';
import * as loadingKeys from '../../../../../../actions/MaterialDetailsDomain/LoadingKeys';
import hasAuthenticatedRole from '../../../../../Common/AuthFunctions';
import { convertCost, formatMaterialNumber, getCurrency } from '../../../../../Common/DateAndNumberFunctions';
import calculate from './monetaryImpactHelpers';
import { emptyValue, IMPACT } from '../../../../../Common/GlobalConstants';
import DraggableModal from '../../../../../Common/DraggableModal';
import CommonOperations from './CommonOperations';
import { getDeliveryTimePayloadForSingleMaterial } from './utils';

const SAP_WRITEBACK_ENABLED = process.env.REACT_APP_SAP_WRITEBACK_ENABLED === 'true' || process.env.REACT_APP_SAP_WRITEBACK_ENABLED === true;

const { Option } = Select;

function Recommendations({ materialData, simulatedValues }) {
  const { plant, material } = useParams();
  const dispatch = useDispatch();
  const mrpSettings = useSelector((state) => state.materialDetailsState.mrpSettings);
  const loading = useSelector((state) => state.materialDetailsState.loading);
  const defaultCurrency = useSelector((state) => state.commonState?.userSettings?.DEFAULT_CURRENCY);
  const currencyList = useSelector((state) => state.commonState?.currencyList);
  const relatedPlants = useSelector((state) => state.materialDetailsState.plantRelationships);
  const changelogs = useSelector((state) => state.materialDetailsState.changelogs);
  const [confirmModalIsVisible, setConfirmModalIsVisible] = useState(false);
  const [errorModalIsVisible, setErrorModalIsVisible] = useState(false);
  const [editing, setEditing] = useState(false);
  const userState = useSelector((state) => state.authState.user);
  const mrpUpdated = useSelector((state) => state.materialDetailsState.mrpUpdated);
  const [isSAPWriteBackUser, setIsSAPWriteBackUser] = useState(false);
  const dropdownValues = useSelector((state) => state.materialState?.dropdownValues);
  const [mrpSettingsRetrieved, setMrpSettingsRetrieved] = useState(false);
  const [hasViewerAccess, setHasViewerAccess] = useState(false);
  const [localDropDowns, setLocalDropDowns] = useState([]);
  const [monetaryImpact, setMonetaryImpact] = useState('');
  const [monetaryImpactType, setMonetaryImpactType] = useState('');
  const [recTableData, setRecTableData] = useState(tableColumns());
  const [columns, setColumns] = useState(originalColumns);

  useEffect(() => {
    if (changelogs?.length > 0) {
      dispatch(allActions.MaterialDetailsActions.fetchMaterial(plant, material));
    }
  }, [changelogs]);

  useEffect(() => {
    if (userState?.idTokenClaims?.roles) {
      const userCanWriteback = hasAuthenticatedRole(
        userState.idTokenClaims.roles,
        process.env.REACT_APP_SAPWRITEBACKUSERROLES,
      );
      setIsSAPWriteBackUser(userCanWriteback);

      const userCanViewPrices = hasAuthenticatedRole(
        userState.idTokenClaims.roles,
        process.env.REACT_APP_VIEW_PRICES,
      );
      setHasViewerAccess(userCanViewPrices);
    }
  }, [userState?.idTokenClaims]);

  useEffect(() => {
    if (dropdownColumns && dropdownValues) {
      getDropdownValues(dropdownColumns, dropdownValues, setLocalDropDowns);
    }
  }, [dropdownColumns, dropdownValues]);

  // initial values
  useEffect(() => {
    if (mrpSettings?.length > 0 && plant && material && materialData) {
      const mrpData = mrpSettings.find(
        (item) => formatMaterialNumber(item.MaterialNumber) === material && item?.Plant === plant,
      );
      if (mrpData?.Plant !== materialData?.PLANT_FACILITY_SAP_ID) return;
      setMrpSettingsRetrieved(true);
      const newRecTableData = _.cloneDeep(recTableData);
      if (mrpData) {
        setRecTableData(getInitialValues(materialData, newRecTableData, mrpData));
      } else {
        setMrpSettingsRetrieved(false);
        newRecTableData.forEach((mrpSetting) => {
          const setting = newRecTableData.find((s) => s.key === mrpSetting.key);
          setting.current = '-';
          setting.recommended = '-';
        });
        setRecTableData(newRecTableData);
      }
    }
  }, [mrpSettings, plant, material, materialData]);

  // simulated values
  useEffect(() => {
    if (simulatedValues
      && simulatedValues.PLANT_FACILITY_SAP_ID?.toString() === plant
      && parseInt(simulatedValues.MATERIAL_TYPE_SAP_ID, 10) === parseInt(material, 10)) {
      setRecTableData(addSimulatedToRecTable(recTableData, simulatedValues));
      if (!columns.some((x) => x.dataIndex === 'simulated')) {
        const temporaryColumns = _.cloneDeep(columns);
        temporaryColumns.push({
          title: 'Simulated',
          dataIndex: 'simulated',
          width: '15%',
          align: 'center',
        });
        setColumns(temporaryColumns);
      }
    }
  }, [simulatedValues]);

  useEffect(() => {
    if (mrpUpdated) {
      dispatch(allActions.SapAPIActions.clearMrpUpdate());
      const iotaPayload = createIOTAPayload(plant, material, recTableData, userState, true,
        typeof monetaryImpact === 'string' ? monetaryImpact?.replaceAll(' ', '') : monetaryImpact,
        monetaryImpactType);
      dispatch(allActions.MaterialDetailsActions.setLoading(loadingKeys.mrp));
      dispatch(allActions.SapAPIActions.getMRP(plant, material));
      dispatch(allActions.MaterialDetailsActions.createChangelog(plant, material, iotaPayload));
      dispatch(allActions.CommonActions.clearMonetaryImpactSummary());
      const deliveryTimePayload = getDeliveryTimePayloadForSingleMaterial(plant, relatedPlants, mrpSettings, materialData, material, recTableData);
      if (deliveryTimePayload.to_Mrp.length > 0) {
        dispatch(allActions.SapAPIActions.setMRPs(deliveryTimePayload, true));
      }
    }
  }, [mrpUpdated]);

  const saveChanges = () => {
    const sapPayload = createSapPayload(recTableData, mrpSettings, plant, material);
    if (!_.isEmpty(sapPayload)) {
      dispatch(allActions.SapAPIActions.setMRP(plant, material, sapPayload));
    }
  };

  const handleOk = () => {
    setEditing(false);
    setColumns(originalColumns);
    setConfirmModalIsVisible(false);
    saveChanges();
  };

  const handleCancel = () => {
    setConfirmModalIsVisible(false);
  };

  const checkForChanges = () => {
    if (_.isEmpty(createSapPayload(recTableData, mrpSettings, plant, material))) {
      setErrorModalIsVisible(true);
    } else {
      const ropValues = recTableData.find((x) => x.key === 'ReorderPoint');
      const mlsValues = recTableData.find((x) => x.key === 'MinimumLotSize');

      const newROP = (ropValues?.userDefined === emptyValue || String(ropValues?.userDefined)?.trim() === '')
        ? 0
        : ropValues?.userDefined;

      const newMLS = (mlsValues?.userDefined === emptyValue || String(mlsValues?.userDefined)?.trim() === '')
        ? 0
        : mlsValues?.userDefined;

      const currentROP = (ropValues?.current === emptyValue || String(ropValues?.current)?.trim() === '')
        ? 0
        : ropValues?.current;

      const currentMLS = (mlsValues?.current === emptyValue || String(mlsValues?.current)?.trim() === '')
        ? 0
        : mlsValues.current;

      const impact = calculate(materialData, newROP, newMLS, currentROP, currentMLS);
      const formattedImpact = Math.abs(Number.parseFloat(impact).toFixed(2));
      setMonetaryImpact(Number.isNaN(formattedImpact) ? 0 : formattedImpact);
      if (impact > 0 || impact < 0) {
        setMonetaryImpactType(impact < 0 ? IMPACT.invest : IMPACT.savings);
      } else {
        setMonetaryImpactType('No impact');
      }
      setConfirmModalIsVisible(true);
    }
  };

  const handleChange = (row, text) => {
    const index = recTableData.findIndex((x) => x.key === row.key);
    if (index !== -1) {
      const newData = [...recTableData];
      newData[index].userDefined = text;
      setRecTableData(newData);
    }
  };

  const getSelectOptions = (record, options) => (
    <>
      <Select
        className="mrpTableSelect"
        style={{ margin: '0px 5px !important' }}
        value={record?.userDefined}
        onChange={(val) => handleChange(record, val)}
      >
        {options?.map((option) => {
          if (option === 'No value' || option === ' ') return null;
          return (
            <Option
              key={option}
              value={option}
              label={option}
            >
              {option}
            </Option>
          );
        })}
      </Select>
    </>
  );
  const editColRender = (_text, record) => {
    if (localDropDowns?.[record.key]?.length) {
      return getSelectOptions(record, localDropDowns?.[record.key]);
    }
    return (
      <Input
        onChange={(e) => handleChange(record, e?.target?.value)}
        value={record?.userDefined === emptyValue ? '' : record?.userDefined}
        style={{
          height: '16px', padding: '0px', fontSize: '12px', textAlign: 'center', textIndent: '-17px',
        }}
      />
    );
  };

  useEffect(() => {
    if (!editing) return;
    const newColumns = columns.filter((item) => item.title !== 'Change');
    setColumns(() => [...newColumns, {
      title: 'Change',
      dataIndex: 'userDefined',
      width: '15%',
      align: 'center',
      render: editColRender,
    }]);
  }, [recTableData]);

  const toggleEditColumn = (enableEdit) => {
    if (enableEdit) {
      setEditing(true);
      setColumns((prev) => [...prev, {
        title: 'Change',
        dataIndex: 'userDefined',
        width: '15%',
        align: 'center',
        render: editColRender,
      }]);
    } else {
      setEditing(false);
      const newColumns = columns.filter((item) => item.title !== 'Change');
      setColumns(newColumns);
    }
  };

  const getMonetaryImpactType = () => {
    if (hasViewerAccess && materialData?.DISPLAY_REC_IND && materialData?.MONETARY_IMPACT_TYPE) {
      return materialData?.MONETARY_IMPACT_TYPE;
    }
    return '-';
  };

  const confirmModalContent = (
    <>
      Are you sure you want to submit the following changes?
      <ReviewChanges
        data={recTableData}
        monetaryImpact={monetaryImpact}
        monetaryImpactType={monetaryImpactType}
        setMonetaryImpact={setMonetaryImpact}
        setMonetaryImpactType={setMonetaryImpactType}
      />
    </>
  );

  const getMonetaryImpact = () => {
    if (hasViewerAccess && materialData?.DISPLAY_REC_IND && materialData?.MONETARY_IMPACT) {
      const cost = convertCost(
        Math.abs(Number.parseFloat(materialData?.MONETARY_IMPACT)), defaultCurrency, currencyList,
      );
      const currency = getCurrency(materialData?.MONETARY_IMPACT, defaultCurrency);
      return `${cost} ${currency}`;
    }
    return '-';
  };

  const modifiedColumns = columns.filter((c) => c.dataIndex !== 'recommended');
  const modalTitle = <div className="handle">Confirm changes</div>;

  const [delegateMenuVisible, setDelegateMenuVisible] = useState(false);

  return (
    <>
      <div
        className={isSAPWriteBackUser ? 'headline recommended' : 'headline'}
      >
        {editing
          ? (
            <>
              <DeleteOutlined
                role="button"
                tabIndex={0}
                onClick={() => toggleEditColumn(false)}
                onKeyDown={() => toggleEditColumn(false)}
                className="icon"
              />
              <CommonOperations
                delegateMenuVisible={delegateMenuVisible}
                setDelegateMenuVisible={setDelegateMenuVisible}
                recTableData={recTableData}
                setRecTableData={setRecTableData}
              />

              <SaveOutlined
                role="button"
                tabIndex={0}
                onClick={() => checkForChanges()}
                onKeyDown={() => checkForChanges()}
                className="icon"
              />
            </>
          )
          : (
            <>
              <DeleteOutlined
                className="icon hidden"
              />
              MRP
              {' '}
              {hasViewerAccess ? 'Recommendations' : 'Settings'}
              {isSAPWriteBackUser && mrpSettingsRetrieved && SAP_WRITEBACK_ENABLED ? (
                <EditOutlined
                  role="button"
                  tabIndex={0}
                  onClick={() => toggleEditColumn(true)}
                  onKeyDown={() => toggleEditColumn(true)}
                  className="icon"
                />
              )
                : (
                  <EditOutlined
                    className="hidden"
                  />
                )}
            </>
          )}

      </div>
      {
        loading[loadingKeys.mrp]
          ? <Skeleton active width paragraph={{ rows: 8 }} />
          : (
            <>
              <Table
                bordered
                dataSource={recTableData}
                columns={hasViewerAccess ? columns : modifiedColumns}
                pagination={false}
                className="infoTable"
              />
              <Table
                bordered
                className="monetaryImpact infoTable"
                title={() => <div className="headline">Monetary impact</div>}
                dataSource={
                  [
                    {
                      key: 'type',
                      title: 'Type',
                      value: getMonetaryImpactType(),
                    },
                    {
                      key: 'value',
                      title: 'Value',
                      value: getMonetaryImpact(),
                    },
                  ]
                }
                columns={[
                  {
                    title: '',
                    dataIndex: 'title',
                    width: '40%',
                    align: 'left',
                  },
                  {
                    title: '',
                    dataIndex: 'value',
                    width: '60%',
                    align: 'center',
                  },
                ]}
                pagination={false}
              />
              {
                confirmModalIsVisible && (
                  <DraggableModal
                    handle=".handle"
                    title={modalTitle}
                    visible={confirmModalIsVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    content={confirmModalContent}
                  />
                )
              }
              {
                errorModalIsVisible && (
                  <Modal
                    title="Error"
                    visible={errorModalIsVisible}
                    onOk={() => setErrorModalIsVisible(false)}
                    onCancel={() => setErrorModalIsVisible(false)}
                    footer={[
                      <Button key="submit" type="primary" onClick={() => setErrorModalIsVisible(false)}>
                        OK
                      </Button>,
                    ]}
                  >
                    No changes were registered
                  </Modal>
                )
              }
            </>
          )
      }
    </>
  );
}
export default Recommendations;
Recommendations.defaultProps = {
  simulatedValues: {},
};
Recommendations.propTypes = {
  materialData: PropTypes.shape({
    PLANT_FACILITY_SAP_ID: PropTypes.string,
    MRP_TYPE_SAP_CD: PropTypes.string,
    LOT_SIZE_SAP_CD: PropTypes.string,
    EDAM_REORDER_POINT_QTY: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    RECOMMENDED_REORDER_POINT_BASE_UOM_QTY: PropTypes.number,
    EDAM_MAXIMUM_STOCK_QTY: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    CC_RECOMMENDED_MAX_STOCK_QTY: PropTypes.number,
    EDAM_ROUNDING_VALUE_FOR_PO_QTY: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    MONETARY_IMPACT_TYPE: PropTypes.string,
    MONETARY_IMPACT: PropTypes.number,
    DISPLAY_REC_IND: PropTypes.bool,
    supplier: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  simulatedValues: PropTypes.object,
};
