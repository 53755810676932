import { v4 as uuidv4 } from 'uuid';
import { COMMENT_TYPE, MODES } from '../../Common/GlobalConstants';

const materialViewState = {
  headerCells: [],
  searchFilters: {
    PLANT_FACILITY_SAP_ID: [
      {
        ColumnName: 'PLANT_FACILITY_SAP_ID',
        FilterOperator: 'EqualTo',
        FilterValue: [
          '1000',
        ],
      },
    ],
    MATERIAL_TYPE_DELETION_IND: [
      {
        ColumnName: 'MATERIAL_TYPE_DELETION_IND',
        FilterOperator: 'EqualTo',
        FilterValue: [
          '0',
        ],
      },
    ],
    MATERIAL_TYPE_SAP_ID: [
      {
        ColumnName: 'MATERIAL_TYPE_SAP_ID',
        FilterOperator: 'EqualTo',
        FilterValue: [
          '19',
        ],
      },
    ],
  },
  scrollPage: {
    currentNoOfRecords: 0,
    noOfRecordsToFetch: 50,
  },
};

const displayHeaderCells = [
  {
    name: 'Plant',
    name_long: 'Plant',
    dataIndex: 'PLANT_FACILITY_SAP_ID',
    filterMultiple: true,
    filteredValue: [
      '1775',
      '1776',
      '1130',
    ],
    key: 'PLANT_FACILITY_SAP_ID',
    width: 88,
    viewOrder: 1,
  },
  {
    name: 'Material',
    name_long: 'Material',
    dataIndex: 'MATERIAL_TYPE_SAP_ID',
    filterMultiple: true,
    filteredValue: null,
    key: 'MATERIAL_TYPE_SAP_ID',
    width: 112,
    viewOrder: 2,
  },
  {
    name: 'Material Descr',
    name_long: 'Material Description',
    dataIndex: 'EDAM_MATERIAL_TYPE_NM',
    filterMultiple: true,
    filteredValue: null,
    key: 'EDAM_MATERIAL_TYPE_NM',
    width: 160,
    viewOrder: 3,
  },
  {
    name: 'Cons Grp',
    name_long: 'Consumption Group',
    dataIndex: 'CC_CONSUMPTION_SEGMENT_GROUP',
    filterMultiple: true,
    filteredValue: null,
    key: 'CC_CONSUMPTION_SEGMENT_GROUP',
    width: 112,
    viewOrder: 4,
  },
  {
    name: 'Del Ind',
    name_long: 'Deletion indicator',
    dataIndex: 'MATERIAL_TYPE_DELETION_IND',
    filterMultiple: true,
    filteredValue: [
      '0',
    ],
    key: 'MATERIAL_TYPE_DELETION_IND',
    width: 78,
    viewOrder: null,
  },
  {
    name: 'Postp Past Due',
    name_long: 'Postponement Past Due',
    dataIndex: 'PastDue',
    filterMultiple: true,
    filteredValue: null,
    key: 'PastDue',
    width: 120,
    viewOrder: null,
  },
];

const selectedWorklistItems = [
  {
    key: 'C6C4B867-132B-4A9A-9D48-52F7DB264EFF',
    WorklistID: 'C6C4B867-132B-4A9A-9D48-52F7DB264EFF',
    WorklistName: 'deleteaftertest',
    WorklistDescription: '',
    SearchFilters: '{\'MATERIAL_TYPE_DELETION_IND\':[{\'ColumnName\':\'MATERIAL_TYPE_DELETION_IND\',\'FilterOperator\':\'EqualTo\',\'FilterValue\':[\'0\']}],\'PLANT_FACILITY_SAP_ID\':[{\'ColumnName\':\'PLANT_FACILITY_SAP_ID\',\'FilterOperator\':\'EqualTo\',\'FilterValue\':[\'1775\',\'1776\',\'1130\']}]}',
    SortColumn: null,
    SortDirection: null,
    SelectedColumns: '[{\'name\':\'Plant\',\'name_long\':\'Plant\',\'dataIndex\':\'PLANT_FACILITY_SAP_ID\',\'filterMultiple\':true,\'filteredValue\':[\'1775\',\'1776\',\'1130\'],\'key\':\'PLANT_FACILITY_SAP_ID\',\'width\':88,\'viewOrder\':1},{\'name\':\'Material\',\'name_long\':\'Material\',\'dataIndex\':\'MATERIAL_TYPE_SAP_ID\',\'filterMultiple\':true,\'filteredValue\':null,\'key\':\'MATERIAL_TYPE_SAP_ID\',\'width\':112,\'viewOrder\':2},{\'name\':\'Material Descr\',\'name_long\':\'Material Description\',\'dataIndex\':\'EDAM_MATERIAL_TYPE_NM\',\'filterMultiple\':true,\'filteredValue\':null,\'key\':\'EDAM_MATERIAL_TYPE_NM\',\'width\':160,\'viewOrder\':3},{\'name\':\'Cons Grp\',\'name_long\':\'Consumption Group\',\'dataIndex\':\'CC_CONSUMPTION_SEGMENT_GROUP\',\'filterMultiple\':true,\'filteredValue\':null,\'key\':\'CC_CONSUMPTION_SEGMENT_GROUP\',\'width\':112,\'viewOrder\':4},{\'name\':\'Del Ind\',\'name_long\':\'Deletion indicator\',\'dataIndex\':\'MATERIAL_TYPE_DELETION_IND\',\'filterMultiple\':true,\'filteredValue\':[\'0\'],\'key\':\'MATERIAL_TYPE_DELETION_IND\',\'width\':78,\'viewOrder\':null},{\'name\':\'Postp Past Due\',\'name_long\':\'Postponement Past Due\',\'dataIndex\':\'PastDue\',\'filterMultiple\':true,\'filteredValue\':null,\'key\':\'PastDue\',\'width\':120,\'viewOrder\':null}]',
    CreatedDate: '2022-12-05T08:08:17.453Z',
    ModifiedDate: null,
    CreatedBy: 'Stefan Alexander Ellenberger (Capgemini Norge AS,Oslo)',
    ModifiedBy: null,
    IsWorklistShared: false,
    QueryStatement: "select * from ActiveRecommendationView  WHERE ( ActiveRecommendationView.PLANT_FACILITY_SAP_ID = '1130'  OR  ActiveRecommendationView.PLANT_FACILITY_SAP_ID = '1775'  OR  ActiveRecommendationView.PLANT_FACILITY_SAP_ID = '1776' ) AND ( ActiveRecommendationView.MATERIAL_TYPE_DELETION_IND = '0' )",
    NoOfRecords: null,
    CreatedUserEmail: 'STELL@equinor.com',
    ModifiedByUserEmail: null,
  },
];

const newSearchID = uuidv4();

const plant = 1000;

const material = 19;

const userID = '4bfce439-0c18-47e5-854f-761fed4c23d2';
const userEmail = 'stell@equinor.com';

const settingType = 'DEFAULT_CURRENCY';
const settingValue = 'DZD';

const notificationTypes = ['SimComplete'];
const numberOfRowsnull = 10;
const offsetnull = null;
const unacceptedOnlynull = false;
const notificationID = '6A3A750A-3750-486F-BA51-C08EAA1675F4';

const groupID = 'd826525c-a98d-4a17-b311-3e8ae8bb51eb';

const changeTable = [
  {
    PLANT_FACILITY_SAP_ID: '1000',
    COMPANY_CODE: '1000',
    COMPANY_CURRENCY_SAP_CD: 'NOK',
    MANUAL_PO_COST: 1501,
    AUTO_PO_COST: 750,
    HOLDING_COST_PRCT: 0.07,
    EXCLUDE_PM10: true,
    key: '1000',
  },
];

const plantGroupData = {
  GroupID: 'd826525c-a98d-4a17-b311-3e8ae8bb51eb',
  Name: 'Delete after testing',
  PlantList: '["1000"]',
  CreatedBy: 'Stefan Alexander Ellenberger (Capgemini Norge AS,Oslo)',
};

const plants = [1000, 1007];
const materials = [157, 8539];
const plantMaterials = [{ plantID: 1000, materialID: 19 }, { plantID: 1007, materialID: 8539 }];
const companyCodes = [1000];
const mode = MODES.PLANT;

const viewName = 'bugtest';
const viewSetup = [
  {
    name: 'Plant',
    name_long: 'Plant',
    dataIndex: 'PLANT_FACILITY_SAP_ID',
    filterMultiple: true,
    filteredValue: [
      '1007',
    ],
    key: 'PLANT_FACILITY_SAP_ID',
    width: 66,
    viewOrder: 1,
  },
  {
    name: 'Material',
    name_long: 'Material',
    dataIndex: 'MATERIAL_TYPE_SAP_ID',
    filterMultiple: true,
    filteredValue: null,
    key: 'MATERIAL_TYPE_SAP_ID',
    width: 84,
    viewOrder: 2,
  },
];

const runID = '651689';

const runIDs = [[runID]];

const changes = {
  MaximumStockLevel: '2',
  SchedulingMarginKeyFloats: '001',
};
const memoPlant = '1776';
const memoMaterial = '43389';
const memoInput = 'new long text';

const selectedCols = [
  'PLANT_FACILITY_SAP_ID',
  'MATERIAL_TYPE_SAP_ID',
  'EDAM_MATERIAL_TYPE_NM',
  'CC_CONSUMPTION_SEGMENT_GROUP',
  'md04',
];
const exportType = 'CSV';
const typeOfWorklist = 'dynamic';
const worklistID = '7C46F4B3-306B-4030-A537-38D67CCDDF4B';

const payload = {
  plant: '1000',
  material: '19',
  changedBy: '4bfce439-0c18-47e5-854f-761fed4c23d2',
  changedByEmail: 'STELL@equinor.com',
  changes: [
    {
      changeType: 'Review',
      oldValue: '-',
      newValue: '2022-11-18T07:28:40.744Z',
    },
  ],
  MRP: false,
};

const date = '2022-11-18T07:28:32.252Z';

const commentType = COMMENT_TYPE.IOTA;

const userName = 'testUser';
const content = 'testString';

const delegateArg = 'Automatic';

const commentId = '5F70F665-CB15-4B3B-86C9-B869E5C5C880';

const impact = 100;
const impactType = 'savings';
const impactNotes = 'test impact';

const newStockData = {};

const simulationDescription = 'simulation description text';

const successMsg = 'New Stock test was successful';
const errMsg = 'new stock error';
const newStockSessionId = uuidv4();

const formdata = [{
  key: 'newStockFormRow20',
  itemText: 'stefan 1',
  deliveryTime: 1,
  supplierLocation: 'Domestic',
  criticality: '3',
  maxConsumed: 1,
  consumptionValue: 1,
  consumptionUnit: 'days',
  price: 10,
},
{
  key: 'newStockFormRow420',
  itemText: 'stefan 8',
  deliveryTime: 8,
  supplierLocation: 'Domestic',
  criticality: '3',
  maxConsumed: 8,
  consumptionValue: 8,
  consumptionUnit: 'days',
  price: 17,
}];

const formId = '87d108d1-b68f-4299-adde-5d6ced625021';
const formName = 'test post plz ignore';

const formObj = {
  formId,
  userId: '4bfce439-0c18-47e5-854f-761fed4c23d2',
  formName,
  description: '',
  formData: '{"form":[{"key":"newStockFormRow20","itemText":"stefan 1","deliveryTime":1,"supplierLocation":"Domestic","criticality":"3","maxConsumed":1,"consumptionValue":1,"consumptionUnit":"days","price":10},{"key":"newStockFormRow420","itemText":"stefan 8","deliveryTime":8,"supplierLocation":"Domestic","criticality":"3","maxConsumed":8,"consumptionValue":8,"consumptionUnit":"days","price":17}],"results":[{"itemText":"stefan 1","MLS":"365","consumption_segment":"FM","MRP_TYPE":"V1","Max":"368","ROP":"4","Lot_Size":"ZB","Stock_loc":"Offshore/onshore","key":"newStockResults0"},{"itemText":"stefan 8","MLS":"365","consumption_segment":"FM","MRP_TYPE":"V1","Max":"372","ROP":"8","Lot_Size":"ZB","Stock_loc":"Offshore/onshore","key":"newStockResults1"}]}',
};

const jobID = 670664;

const simulationID = 639961;

const noOfRows = 50;
const offset = 0;

const metadata = {
  userID,
  worklistID: selectedWorklistItems[0].WorklistID,
  worklistName: selectedWorklistItems[0].WorklistName,
};

const metadataAll = {
  ...metadata,
  create: false,
  noOfRecords: 0,
};

const worklistState = {
  worklistId: worklistID,
  worklistName: 'worklistname',
  worklistDescription: 'worklistdesc',
  isWorklistShared: false,
};

const userState = {
  account: {
    username: 'testcenter@equinor.com',
    name: 'Test Center',
  },
};

const worklistItems = [{ plantID: 1000, materialID: 19 }];

const staticWLmetadata = {
  userID: '4bfce439-0c18-47e5-854f-761fed4c23d2',
  worklistID: uuidv4(),
  noOfRecords: 1,
  worklistName: `test center${uuidv4()}`,
  userEmail: 'STELL@equinor.com',
  worklistDescription: 'test center',
};

const worklists = [
  [
    '13361FA6-0AD3-4F21-9177-F2040B602B18',
  ],
];

export {
  materialViewState,
  displayHeaderCells,
  selectedWorklistItems,
  newSearchID,
  plant,
  material,
  plantMaterials,
  userID,
  userEmail,
  settingType,
  settingValue,
  notificationTypes,
  numberOfRowsnull,
  offsetnull,
  unacceptedOnlynull,
  notificationID,
  groupID,
  changeTable,
  plantGroupData,
  plants,
  materials,
  companyCodes,
  mode,
  viewName,
  viewSetup,
  runID,
  runIDs,
  changes,
  memoPlant,
  memoMaterial,
  memoInput,
  selectedCols,
  exportType,
  typeOfWorklist,
  worklistID,
  payload,
  date,
  commentType,
  userName,
  content,
  delegateArg,
  commentId,
  impact,
  impactType,
  impactNotes,
  newStockData,
  simulationDescription,
  successMsg,
  errMsg,
  newStockSessionId,
  formdata,
  formObj,
  formId,
  formName,
  jobID,
  simulationID,
  noOfRows,
  offset,
  metadata,
  metadataAll,
  worklistState,
  userState,
  worklistItems,
  staticWLmetadata,
  worklists,
};
