export const optimalInventoryByStrategy = 'OPTIMAL_INVENTORY_BY_STRATEGY';
export const optimalInventoryByGroup = 'OPTIMAL_INVENTORY_BY_GROUP';
export const optimalInvByTime = 'OPTIMAL_INVENTORY_BY_TIME';
export const inventoryValByTime = 'INVENTORY_VALUE_BY_TIME';
export const optimalInventoryBySP = 'OPTIMAL_INVENTORY_BY_SP';
export const optimalInventoryByCrit = 'OPTIMAL_INVENTORY_BY_CRIT';
export const plannedOrders = 'PLANNED_ORDERS';
export const optimalInventoryByROP = 'OPTIMAL_INVENTORY_BY_ROP';
export const excessStockByStatus = 'SET_EXCESS_STOCK_BY_STATUS';
export const cleanUpWidget = 'CLEAN_UP_WIDGET';
export const inventoryValue = 'INVENTORY_VALUE';
export const monetaryImpact = 'MONETARY_IMPACT';
export const totalExcessStock = 'TOTAL_EXCESS_STOCK_VALUES';
export const storeOptInvTot = 'STORE_OPT_INV_TOT';
export const setLoading = 'SET_DASHBOARD_LOADING';
export const setLineChartLoading = 'SET_LINE_CHART_LOADING';
