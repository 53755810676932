import {
  Card,
  PageHeader, Tabs,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import MassUpdate from './massUpdate';
import { massUpdate } from './massUpdateGlobals';
import MassUpdateMrp from './massUpdateMrp/MassUpdateMrp';
import { titleStrings } from './utils';
import MassUpdateFixedBin from './massUpdateFixedBin';

const MassUpdateContainer = () => {
  const { TabPane } = Tabs;
  const location = useLocation();
  const history = useHistory();
  const userState = useSelector((state) => state.authState.user);

  const getMaterialIdsAndPlantFromState = () => {
    const { state } = location;
    const obj = {};
    if (state && state.ids) {
      const asArr = JSON.parse(state.ids);
      history.replace('/mass-update', {});
      obj.ids = asArr;
    }
    if (state && state.plants) {
      obj.plant = state.plants;
    }
    return obj;
  };

  const [materialIds] = useState(getMaterialIdsAndPlantFromState());

  const validUser = () => {
    if (userState?.account?.idTokenClaims?.roles.includes('SuperUser') || userState?.account?.idTokenClaims?.roles.includes('Analyst')) {
      return true;
    }
    return false;
  };

  const [currentTab, setTab] = useState('');

  useEffect(() => {
    if (userState) {
      if (validUser()) {
        setTab(massUpdate.MRP);
      } else {
        setTab(massUpdate.MEMO);
      }
    }
  }, [userState]);

  return (
    <Card style={{ minHeight: '98%' }}>
      <PageHeader
        title={`Mass Update ${titleStrings[currentTab]}`}
        ghost={false}
      >
        <Tabs activeKey={currentTab} onChange={(key) => setTab(key)}>
          {validUser() && (
          <TabPane tab="MRP Settings" key={massUpdate.MRP}>
            <MassUpdateMrp matIds={materialIds} currentTab={currentTab} />
          </TabPane>
          )}
          <TabPane tab="Material Memo" key={massUpdate.MEMO}>
            <MassUpdate
              currentTab={currentTab}
              matIds={materialIds}
            />
          </TabPane>
          <TabPane tab="Planned Order Text" key={massUpdate.PLANNED_ORDER}>
            <MassUpdate
              currentTab={currentTab}
              matIds={materialIds}
            />
          </TabPane>
          <TabPane tab="IOTA Memo" key={massUpdate.IOTA_MEMO}>
            <MassUpdate
              currentTab={currentTab}
              matIds={materialIds}
            />
          </TabPane>
          <TabPane tab="Warehouse Memo" key={massUpdate.WAREHOUSE_MEMO}>
            <MassUpdate
              currentTab={currentTab}
              matIds={materialIds}
            />
          </TabPane>
          {validUser() && (
          <TabPane tab="Fixed Bin" key={massUpdate.FIXED_BIN}>
            <MassUpdateFixedBin matIds={materialIds} currentTab={currentTab} />
          </TabPane>
          )}
        </Tabs>
      </PageHeader>
    </Card>
  );
};

export default MassUpdateContainer;
