import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from 'antd';
import _ from 'lodash';
import allActions from '../../../../actions/index';
import * as loadingKeys from '../../../../actions/DashboardDomain/LoadingKeys';
import { MODES } from '../../../Common/GlobalConstants';
import { filterWidgetData, getOptInvTot } from './WidgetFunctions';
import { formatLargeNumber } from '../../MaterialDetails/components/TabComponents/TabGraphHelpers';
import { getConvertedPrice, getCurrency } from '../../../Common/DateAndNumberFunctions';
import { widgetTypeKeys } from './widgetTypes';

const TextWidgetContainer = ({ widgetID }) => {
  const dispatch = useDispatch();
  const settingsLoaded = useSelector((state) => state.commonState.loaded?.userSettings);
  const selectedPlants = useSelector((state) => state.commonState.selectedPlants);
  const selectedCCs = useSelector((state) => state.commonState.selectedCompanyCodes);
  const dashboardMode = useSelector((state) => state.commonState.dashboardMode);
  const inventoryValue = useSelector((state) => state.dashboardState[widgetID]);
  const loading = useSelector((state) => state.dashboardState.loading);
  const defaultCurrency = useSelector((state) => state.commonState?.userSettings?.DEFAULT_CURRENCY);
  const dashboardFilter = useSelector((state) => state.commonState.localDashboardFilters);
  const currencyList = useSelector((state) => state.commonState?.currencyList);
  const SQLOnly = useSelector((state) => state.commonState?.SQLOnly?.dashboard);
  const [inventoryStatus, setInventoryStatus] = useState({});
  const [widgetValue, setWidgetValue] = useState('');

  const { Text } = Typography;

  const selectedExists = () => {
    if (selectedCCs?.length === 0 && selectedPlants?.length === 0 && inventoryValue?.A) {
      return true;
    }
    if (dashboardMode === MODES.PLANT) {
      const key = `P${selectedPlants?.sort().join('')}`;
      return !!inventoryValue?.[key];
    }
    const key = `CC${selectedCCs?.sort().join('')}`;
    return !!inventoryValue?.[key];
  };

  const updateCache = () => {
    const tempInventory = JSON.parse(JSON.stringify(inventoryValue));
    if (selectedCCs?.length === 0 && selectedPlants?.length === 0) {
      setInventoryStatus(tempInventory.A);
    } else if (dashboardMode === MODES.PLANT) {
      setInventoryStatus(tempInventory[`P${selectedPlants?.sort().join('')}`]);
    } else {
      setInventoryStatus(tempInventory[`CC${selectedCCs?.sort().join('')}`]);
    }
  };

  useEffect(() => {
    if (selectedPlants && selectedCCs && settingsLoaded && dashboardMode
      && (SQLOnly === true || SQLOnly === false)) {
      if (selectedExists()) {
        updateCache();
      } else {
        if (loading?.[loadingKeys[widgetID]] === true) return;
        dispatch(allActions.DashboardActions.setLoading(loadingKeys[widgetID]));
        if (widgetID === widgetTypeKeys.inventoryValue) {
          dispatch(allActions.DashboardActions.inventoryValue(
            dashboardMode === MODES.PLANT ? selectedPlants : null,
            dashboardMode === MODES.CC ? selectedCCs : null,
            dashboardMode,
            null,
            SQLOnly,
          ));
        }
        if (widgetID === widgetTypeKeys.optInvTot) {
          dispatch(allActions.DashboardActions.getOptInvTot(
            dashboardMode === MODES.PLANT ? selectedPlants : null,
            dashboardMode === MODES.CC ? selectedCCs : null,
            dashboardMode,
            SQLOnly,
          ));
        }
        if (widgetID === widgetTypeKeys.totalExcessStock) {
          dispatch(allActions.DashboardActions.getTotalExcessStock(
            dashboardMode === MODES.PLANT ? selectedPlants : null,
            dashboardMode === MODES.CC ? selectedCCs : null,
            dashboardMode,
            SQLOnly,
          ));
        }
      }
    }
  }, [selectedPlants, selectedCCs, settingsLoaded, SQLOnly,
    dashboardMode, inventoryValue, dashboardFilter]);

  useEffect(() => {
    if (inventoryStatus?.data?.length > 0) {
      const currenctCurrency = defaultCurrency ?? 'NOK';
      const conversionData = currencyList?.find(
        (x) => currenctCurrency === x?.FROM_CURRENCY,
      );
      const conversionValues = conversionData
        ? [conversionData.FROM_RATIO, conversionData.TO_RATIO, conversionData.EXCHANGE_RATE]
        : [1, 1, 1];

      if (widgetID === widgetTypeKeys.optInvTot) {
        const optTot = getOptInvTot(inventoryStatus, dashboardFilter)
          ?.green?.toFixed(2);
        setWidgetValue(`${optTot} %`);
      } else {
        let val;
        if (widgetID === widgetTypeKeys.totalExcessStock
          || widgetID === widgetTypeKeys.inventoryValue) {
          const filteredData = filterWidgetData(
            inventoryStatus?.data, dashboardFilter,
          );
          val = filteredData?.reduce(((prev, curr) => prev + (curr?.INVENTORY_VALUE ?? 0)), 0);
        }
        val = getConvertedPrice(val, ...conversionValues);
        setWidgetValue(
          `${formatLargeNumber(val, true)} ${getCurrency(val, defaultCurrency)}`,
        );
      }
    }
  }, [inventoryStatus, dashboardFilter]);

  return (
    inventoryValue && !loading?.[loadingKeys?.optInvTot]
        && ((dashboardMode === MODES.PLANT && _.isEqual(inventoryStatus?.plants, selectedPlants))
          || (dashboardMode === MODES.CC && _.isEqual(inventoryStatus?.companyCodes, selectedCCs)))
      ? (
        <Text style={{ fontWeight: 'bold', fontSize: 18 }}>
          Today:
          {' '}
          {widgetValue}
        </Text>
      )
      : <></>
  );
};

export default TextWidgetContainer;

TextWidgetContainer.propTypes = {
  widgetID: PropTypes.string.isRequired,
};
