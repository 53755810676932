import React from 'react';
import {
  Row, Col,
} from 'antd';
import {
  ClockCircleTwoTone, InfoCircleTwoTone, PlayCircleTwoTone, WarningTwoTone,
} from '@ant-design/icons';
import { localTimeFormat } from '../DateAndNumberFunctions';

const getStatusDisplay = (simStatus) => (
  <>
    <Row type="flex" gutter={10} style={{ alignItems: 'center' }}>
      {(() => {
        switch (simStatus.status) {
          case 'PENDING':
            return (
              <>
                <Col><ClockCircleTwoTone twoToneColor="#FCE349" style={{ fontSize: 40 }} /></Col>
                <Col>Status: PENDING</Col>
              </>
            );
          case 'RUNNING':
            return (
              <>
                <Col><PlayCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: 40 }} /></Col>
                <Col>Status: RUNNING</Col>
              </>
            );
          case 'FAILED':
          case 'CANCELED':
          case 'SKIPPED':
            return (
              <>
                <Col><InfoCircleTwoTone twoToneColor="#ff4d4f" style={{ fontSize: 40 }} /></Col>
                <Col>
                  {'Status: '}
                  {simStatus.status}
                </Col>
              </>
            );
          default:
            return (
              <>
                <Col><WarningTwoTone twoToneColor="#FCC049" style={{ fontSize: 40 }} /></Col>
                <Col>Status: Unknown</Col>
              </>
            );
        }
      })()}
    </Row>
    <Row gutter={10}>
      {simStatus?.start_time && `Simulation job was started at: ${localTimeFormat(new Date(simStatus.start_time))}`}
    </Row>
  </>
);
export default getStatusDisplay;
