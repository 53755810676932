import { ConfigProvider, Empty, Table } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as loadingKeys from '../../../actions/common/LoadingKeys';
import allActions from '../../../actions';
import { outlierViewColumns } from './helpers/columns';

const OutlierView = () => {
  const dispatch = useDispatch();
  const outliers = useSelector((state) => state.commonState.outliers);
  const userSettings = useSelector((state) => state.commonState.userSettings);
  const useDefaultSettings = useSelector((state) => state.commonState.useDefaultSettings);
  const loading = useSelector((state) => state.commonState.loading);

  useEffect(() => {
    dispatch(allActions.CommonActions.setLoading(loadingKeys.outliers));
    dispatch(allActions.CommonActions.getOutliers());
  }, []);

  const initialDefaults = (useDefaultSettings
    && userSettings?.USE_DEFAULTS_GLOBALLY
    && userSettings?.DEFAULT_PLANTS?.length > 0)
    ? userSettings?.DEFAULT_PLANTS
    : [];

  const uniqueItems = outliers?.length > 0 && Array
    .from(new Set([...initialDefaults, ...outliers.map((o) => o.PLANT_FACILITY_SAP_ID)]))
    .sort()
    .map((o) => ({ value: o, text: o }));

  return (
    <ConfigProvider renderEmpty={() => <Empty description={loading?.[loadingKeys.outliers] ? 'Loading...' : 'No data for selected plants'} />}>
      <Table
        dataSource={outliers}
        rowClassName={(record, index) => (index % 2 === 0 ? 'table-row-light' : 'table-row-dark')}
        columns={outlierViewColumns(
          uniqueItems,
          initialDefaults,
        )}
        size="small"
        pagination={false}
      />
    </ConfigProvider>

  );
};

export default OutlierView;
