const recommendationValues = [
  'CC_MRP_TYPE',
  'CC_ROP',
  'CC_MIN_LOT_SIZE',
  'CC_MAX_STOCK',
  'MONETARY_IMPACT',
  'MONETARY_IMPACT_TYPE',
  'CC_LOT_SIZE',
  'CC_MRP_GROUP',
  'CC_ROP_LT_END_DT',
  'CC_FM_SANITY_CHECK_ADJ',
  'CC_ECONOMIC_ORDER_QTY',
  'MP_ROP_RANGE',
  'MP_MAX_STOCK_RANGE',
  'CC_FIXED_LOT_SIZE',
  'CC_MRP_CONTROLLER',
];

const unUsableCols = [ // Irrespective of user's access
  'CC_CONSUMED_QTY_24M_AVG',
  'CC_MM_LOGIC_IND',
  'CC_CONSUMED_PER_12M_AVG',
  'supplier',
  'CC_STOCK_COST_AS_IS',
];

const unFilterableCols = [
  ...unUsableCols,
  'MATERIAL_TYPE_DELETION_IND',
];

const unSelectableCols = [
  ...unUsableCols,
  'CONTRACT_NUMBER',
  'STORAGE_BIN',
  'FACILITY_SAP_ID',
];

const currencyValues = [
  'MARKET_PRICE',
  'FAIR_PRICE',
  'CC_UNIT_COST_AMT',
  'MONETARY_IMPACT',
  'CC_LAST_PO_COST',
  'CC_CURRENT_STOCK_VALUE_AMT',
  'CC_HOLDING_COST',
  'CC_TRANSACTION_COST',
  'CC_LAST_PO_COST',
  'EDAM_LOCAL_CURRENCY_AMT',
  'TOT_VAL',
  'TOTAL_EXCESS_STOCK_VALUE',
  'Over_Stk_Imp',
  'CC_STOCK_COST_AS_IS',
  'CC_ECONOMIC_ORDER_QTY',
  'CC_FM_SANITY_CHECK_ADJ',
  'CC_STOCK_COST_CHECK_ADJ',
];

const columnsWithAggregation = [
  'AVAIL_STOCK',
  'CC_CONSUMPTION_COUNT_0TO84M',
  'CC_CURRENT_STOCK_VALUE_AMT',
  'CC_HOLDING_COST',
  'CC_ND_STOCK',
  'CC_OPEN_PO_QTY',
  'CC_OPEN_RESERVATION_QTY',
  'CC_OTH_STOCK',
  'CC_PLANNED_ORDER_QTY',
  'CC_TRANSACTION_COST',
  'EXCESS_PD',
  'EXCESS_V1_EX',
  'EXCESS_V1_HBZB',
  'MONETARY_IMPACT',
  'OPEN_PR_QTY',
  'OPEN_RD_QTY',
  'Over_Stk',
  'Over_Stk_Imp',
  'TOT_ABC',
  'TOT_QABC',
  'TOT_STOCK',
  'TOT_VAL',
  'TOTAL_EXCESS_STOCK',
  'TOTAL_EXCESS_STOCK_VALUE',
  'CC_UNIT_COST_AMT',
  'FAIR_PRICE',
  'MARKET_PRICE',
];

const currencyCols = [
  'COMPANY_CURRENCY_SAP_CD',
  'CURRENCY',
];

const sensitiveValues = [
  ...currencyValues,
  ...currencyCols,
  ...recommendationValues,
  'HOLDING_COST',
  'HOLDING_COST_PRCT',
  'MONETARY_IMPACT',
];

export {
  recommendationValues, currencyValues, sensitiveValues,
  currencyCols, unFilterableCols, unSelectableCols, columnsWithAggregation,
};
