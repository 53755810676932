import React, { useState } from 'react';
import {
  Button, Input, Space, Spin,
} from 'antd';
import moment from 'moment';
import { FilterFilled, LoadingOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import allActions from '../../../actions';
import { WORKLIST_VIEW, listModes } from '../../Common/GlobalConstants';

const displayLineBreaks = (string) => {
  const regexStr = /^\s+|\s+$/g; // Remove line breaks and spaces at both ends of the string.
  const tempText = string?.replace(regexStr, '') || '';
  return (
    <>
      {tempText?.split('\n')?.map((x) => (
        <>
          {x}
          <br />
        </>
      ))}
    </>
  );
};

const getColumnSearchProps = (dataIndex, worklistViewState, handleSearch, handleReset) => ({
  filterDropdown: ({
    // eslint-disable-next-line react/prop-types
    setSelectedKeys, selectedKeys, confirm, clearFilters,
  }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={(node) => {
          // eslint-disable-next-line no-param-reassign
          worklistViewState.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ marginBottom: 8, display: 'block' }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered) => <FilterFilled style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) => (record[dataIndex]
    ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
    : ''),
  onFilterDropdownVisibleChange: (visible) => {
    if (visible) {
      setTimeout(() => worklistViewState.searchInput.select(), 100);
    }
  },
});

const getColumns = (worklistViewState, handleSearch, handleReset,
  type, useDefaultSettings, userSettings, listType = listModes.matList,
  displayPersonal = WORKLIST_VIEW.PERSONAL) => {
  const RenderButton = ({ record, index }) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const fetchRecordsCount = (currentListType) => {
      const sfObject = JSON.parse(record.SearchFilters);
      if (currentListType === listModes.matList) {
        if (!sfObject.PLANT_FACILITY_SAP_ID && !sfObject.COMPANY_CODE && useDefaultSettings) {
          if (userSettings?.DEFAULT_PLANTS?.length > 0) {
            sfObject.PLANT_FACILITY_SAP_ID = [
              {
                ColumnName: 'PLANT_FACILITY_SAP_ID',
                FilterOperator: 'EqualTo',
                FilterValue: userSettings?.DEFAULT_PLANTS,
              },
            ];
          } else if (userSettings?.DEFAULT_COMPANY_CODES?.length > 0) {
            sfObject.COMPANY_CODE = [
              {
                ColumnName: 'COMPANY_CODE',
                FilterOperator: 'EqualTo',
                FilterValue: userSettings?.DEFAULT_COMPANY_CODES,
              },
            ];
          }
        }
        dispatch(
          allActions.WorkListActions.fetchDynamicWLCount(
            JSON.stringify(sfObject),
            record?.WorklistID,
            record?.WorklistName,
          ),
        );
      } else {
        dispatch(
          allActions.MMActions.fetchMMDynamicWLCount(
            JSON.stringify(sfObject),
            record?.WorklistID,
            record?.WorklistName,
          ),
        );
      }
      setIsLoading(true);
    };
    if (isLoading) {
      return (
        <Spin
          indicator={<LoadingOutlined style={{ fontSize: 28 }} spin />}
          style={{ textAlign: 'center' }}
        />
      );
    }
    return (
      <Button id={record?.WorklistID?.concat(index)} onClick={() => fetchRecordsCount(listType)}>
        Get records count
      </Button>
    );
  };

  RenderButton.propTypes = {
    record: PropTypes.objectOf(PropTypes.any).isRequired,
    index: PropTypes.number.isRequired,
  };

  const columnHeaders = [
    {
      key: 'WorklistName',
      dataIndex: 'WorklistName',
      title: 'Worklist Name',
      render: displayLineBreaks,
      ...getColumnSearchProps('WorklistName', worklistViewState, handleSearch, handleReset),
      sorter: (a, b) => a.WorklistName.localeCompare(b.WorklistName),
      defaultSortOrder: 'ascend',
      width: 400,
    },
    {
      key: 'WorklistDescription',
      dataIndex: 'WorklistDescription',
      title: 'Description',
      render: displayLineBreaks,
      ...getColumnSearchProps('WorklistDescription', worklistViewState, handleSearch, handleReset),
      sorter: (a, b) => a.WorklistDescription
        && a.WorklistDescription.localeCompare(b.WorklistDescription),
      width: 800,
    },
    {
      key: 'LastChangedDate',
      dataIndex: 'LastChangedDate',
      title: 'Last Changed Date',
      render: (lastChanged) => lastChanged && moment(lastChanged).format('YYYY-MM-DD HH:mm:ss'),
      sorter: (a, b) => moment(a.LastChangedDate) - moment(b.LastChangedDate),
    },
  ];
  if (displayPersonal === WORKLIST_VIEW.PUBLIC) {
    columnHeaders.push(
      {
        key: 'LastUpdatedBy',
        dataIndex: 'LastUpdatedBy',
        title: 'Last Updated By',
        width: 200,
      },
    );
  }

  if (type === 'dynamic') {
    columnHeaders.push(
      {
        key: 'NoOfRecords',
        dataIndex: 'NoOfRecords',
        title: 'No. of records',
        render: (NoOfRecords, rec, index) => {
          if (NoOfRecords || NoOfRecords === 0) {
            return `${NoOfRecords}`;
          }
          return <RenderButton record={rec} index={index} />;
        },
        sorter: (a, b) => (a.NoOfRecords || a.NoOfRecords === 0
          ? a.NoOfRecords - b.NoOfRecords : 1),
        ...getColumnSearchProps('ModifiedDate', worklistViewState, handleSearch, handleReset),
      },

    );
  } else {
    columnHeaders.push(
      {
        key: 'NoOfRecords',
        dataIndex: 'NoOfRecords',
        title: 'Items in list',
        sorter: (a, b) => a.NoOfRecords - b.NoOfRecords,
      },
    );
  }
  return columnHeaders;
};

export default getColumns;
