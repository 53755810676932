import React from 'react';
import PropTypes from 'prop-types';
import { WarningOutlined } from '@ant-design/icons';

export default function DisplayError({ error }) {
  console.log(Object.keys(error));
  return (
    <div style={{
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      padding: '80px 0',
      width: '50%',
    }}
    >
      <div style={{
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '10%',
      }}
      >
        <WarningOutlined style={{ fontSize: 60 }} />
      </div>
      <p>
        {error?.errorMessage
          ? `Error: ${error.errorMessage}`
          : 'Error: Unknown error.'}
      </p>
    </div>
  );
}

DisplayError.defaultProps = {
  error: 'Error',
};

DisplayError.propTypes = {
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};
