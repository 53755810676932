const locale = 'no-NO';

const dateFormat = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
};

const shortDateFormat = {
  day: '2-digit',
  month: '2-digit',
  year: '2-digit',
};

const dateTimeFormat = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  timeZoneName: 'short',
};
const validTimeRegEx = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/;

const isInteger = (value) => /^-?\d+$/.test(value);
const isPositiveInteger = (value) => /^\d+$/.test(value);

const localDateFormat = (date) => {
  if (!date || date === '') return date;
  if (validTimeRegEx.test(date)) return new Date(date).toLocaleDateString(locale, dateFormat);
  try {
    let tempDate = date;
    tempDate = new Date(tempDate);
    if (Number.isNaN(tempDate.getMonth())) {
      if (typeof date === 'string') {
        if (date.includes('-') || date.includes('.') || date.includes('/')) return date;
        tempDate = Number.parseInt(date, 10);
        tempDate = new Date(tempDate).toLocaleDateString(locale, dateFormat);
        if (tempDate === 'Invalid Date') return date;
        return tempDate;
      }
      return date;
    }
    return tempDate.toLocaleDateString(locale, dateFormat);
  } catch {
    return date;
  }
};

const localShortDateFormat = (date) => new Date(date)
  ?.toLocaleDateString(locale, shortDateFormat) || date;

const localTimeFormat = (date) => date.toLocaleTimeString(locale, dateFormat);

const localDateTimeFormat = (date) => date.toLocaleString(locale, dateTimeFormat);

const isoDateToFirstOfMonth = (iso) => {
  const originalDate = new Date(iso);
  return new Date(originalDate.getFullYear(), originalDate.getMonth(), 1);
};

const isoDateToFirstOfNextMonth = (iso) => {
  const originalDate = new Date(iso);
  return new Date(originalDate.getFullYear(), originalDate.getMonth() + 1, 1);
};

const isoDateToFirstOfYear = (iso) => {
  const originalDate = new Date(iso);
  return new Date(originalDate.getFullYear(), 0, 1);
};

const isoDateToLastOfYear = (iso) => {
  const originalDate = new Date(iso);
  return new Date(originalDate.getFullYear(), 11, 1);
};

const timeString = (d) => {
  let tempDate = d;
  try {
    if (typeof d === 'string') {
      tempDate = new Date(d);
    }
    const val = new Date(tempDate.getTime() - (tempDate.getTimezoneOffset() * 60000)).toISOString();
    return val;
  } catch {
    return localDateFormat(d);
  }
};

const formatNumbersLocale = (n) => n.toString().replace(/\./, ',');

const formatNumbersFromLocale = (n) => n.toString().replace(/,/, '.');

const displayRelevantDecimals = (input, currency = false) => {
  const decimal = Number.parseFloat(input);
  if (Number.isNaN(decimal)) {
    return '';
  }
  if (currency) {
    const thousandsSeparator = /\B(?=(\d{3})+(?!\d))/g;
    const currencyFormat = (Math.round(input * 100) / 100).toFixed(2);
    const formatted = currencyFormat.toString().replace(thousandsSeparator, ' ');
    return formatted;
  }
  if (isInteger(decimal)) {
    if (typeof input === 'string') return decimal;
    return input;
  }
  return Math.round(decimal * 1000) / 1000;
};

const formatMaterialNumber = (n) => n?.toString().replace(/^0+/, '');

const convertPriceTo = (cost, fromRatio, toRatio, exchange) => (
  cost / (exchange * (toRatio / fromRatio))
);

const convertPriceFrom = (cost, fromRatio, toRatio, exchange) => (
  cost * (exchange * (toRatio / fromRatio))
);

const convertCost = (cost, userDefault, currencyList = [], fromCurrency = 'NOK', format = true) => {
  // Currency information
  let defaultCurrency = userDefault;
  if (!defaultCurrency) {
    defaultCurrency = 'NOK';
  }
  const fromData = currencyList?.find((c) => c.FROM_CURRENCY === fromCurrency);
  const defaultData = currencyList?.find((c) => c.FROM_CURRENCY === defaultCurrency);
  if (!fromData || !defaultData) return '-';
  const { FROM_RATIO: initialFrom, TO_RATIO: initialTo, EXCHANGE_RATE: initialRate } = fromData;
  const { FROM_RATIO: defaultFrom, TO_RATIO: defaultTo, EXCHANGE_RATE: defaultRate } = defaultData;

  /*
    Currency conversion scenarios:
  */

  // 0) Initial currency and default currency are the same
  if (defaultCurrency === fromCurrency) {
    return format
      ? displayRelevantDecimals(cost, true)
      : cost.toFixed(2);
  }

  // 1) Converting a foreign currency to NOK
  const costInNOK = convertPriceFrom(cost, initialFrom, initialTo, initialRate);
  if (fromCurrency !== 'NOK' && defaultCurrency === 'NOK') {
    return format ? displayRelevantDecimals(costInNOK, true) : costInNOK.toFixed(2);
  }

  // 2) Converting a foreign currency to another foreign currency, via NOK
  if (fromCurrency !== 'NOK' && defaultCurrency !== 'NOK') {
    const convertedCurrency = convertPriceTo(costInNOK, defaultFrom, defaultTo, defaultRate);
    return format ? displayRelevantDecimals(convertedCurrency, true) : convertedCurrency.toFixed(2);
  }
  // 3) Converting NOK to foreign currency
  if (fromCurrency === 'NOK' && defaultCurrency !== 'NOK') {
    const convertedCurrency = convertPriceTo(cost, defaultFrom, defaultTo, defaultRate);
    return format ? displayRelevantDecimals(convertedCurrency, true) : convertedCurrency.toFixed(2);
  }
  return format ? '-' : 0;
};

const getCurrency = (cost, defaultCurr) => {
  if (!defaultCurr || defaultCurr === 'NOK') {
    if (cost) return 'NOK';
    return '';
  }
  if (cost) return defaultCurr;
  return '';
};

export {
  localDateFormat,
  localTimeFormat,
  localDateTimeFormat,
  localShortDateFormat,
  isoDateToFirstOfMonth,
  isoDateToFirstOfNextMonth,
  isoDateToFirstOfYear,
  isoDateToLastOfYear,
  timeString,
  formatNumbersLocale,
  formatNumbersFromLocale,
  isInteger,
  isPositiveInteger,
  displayRelevantDecimals,
  formatMaterialNumber,
  convertPriceTo as getConvertedPrice,
  convertCost,
  getCurrency,
};
