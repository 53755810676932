import React, { useState } from 'react';
import {
  Button, Card, Descriptions, Empty, Tooltip,
} from 'antd';
import './SummaryStyle.css';
import { InfoCircleOutlined, InfoCircleTwoTone } from '@ant-design/icons';
import PropTypes from 'prop-types';
import SimulationContainer from './Simulation/SimulationContainer';

import {
  getDemandTime, getSAPLink, statusIndicator,
} from '../helpers';
import ResizableDrawer from '../../../../Common/ResizableDrawer';
import RecommendationsContainer from './Recommendations/RecommendationsContainer';
import DraggableModal from '../../../../Common/DraggableModal';
import { getPlantStorageCheck } from '../InfoComponents/StorageCheckIdInfo';
import storageCheckID from '../../../../../images/storageCheckID.jpg';
import consumptionGroupID from '../../../../../images/consumptionGroupID.png';
import {
  convertCost, displayRelevantDecimals, formatNumbersLocale, getCurrency,
} from '../../../../Common/DateAndNumberFunctions';

function PlantDetailsCard({
  data, visible, setVisible, defaultCurrency, currencyList, canViewPrices,
}) {
  const [simulatedValues, setSimulatedValues] = useState();
  const [infoTitle, setInfoTitle] = useState('');
  const [infoContent, setInfoContent] = useState(<></>);
  const [infoModalVisible, setInfoModalVisible] = useState(false);

  const onClose = () => {
    setVisible(false);
  };

  const infoModalTypes = {
    storageStatus: 'STORAGE',
    consumptionGroup: 'CONSUMPTION',
  };

  const infoModalData = {
    [infoModalTypes.storageStatus]: {
      title: 'Status',
      image: storageCheckID,
      alt: 'Status Tables',
      height: '80vh',
    },
    [infoModalTypes.consumptionGroup]: {
      title: 'Consumption group',
      image: consumptionGroupID,
      alt: 'Consumption Group Tables',
      height: '40vh',
    },
  };

  const showInfoModal = (input) => {
    const modalData = infoModalData[input];
    setInfoTitle(modalData?.title);
    setInfoContent(
      <div
        style={{ height: modalData?.height }}
        onKeyPress={null}
        onClick={() => window.open(modalData?.image)}
        role="button"
        tabIndex={0}
      >
        <img style={{ cursor: 'pointer' }} title="Click to open image in new window" src={modalData?.image} alt={modalData?.alt} />
      </div>,
    );
    setInfoModalVisible(true);
  };

  const closeInfoModal = () => {
    setInfoTitle('');
    setInfoContent(<></>);
    setInfoModalVisible(false);
  };

  const tableStyle = {
    fontSize: '12px',
    height: '5px',
    padding: '0px 4px',
  };

  const labelStyle = {
    fontSize: '12px',
    height: '5px',
    padding: '0px 4px',
    width: '40%',
  };

  const maskStyle = {
    backgroundColor: 'rgba(0, 0, 0, 0.15)',
  };
  const modalTitle = <div className="handle">{infoTitle}</div>;

  const consumptionGroups = {
    NEW: 'New Materials',
    FM: 'Fast Moving',
    MM: 'Moderate Moving',
    SM: 'Slow Moving',
    'NM-Y': 'Non Moving Young',
    'NM-O': 'Non Moving Old',
    'NM-D': 'Non Moving Dead',
    NU: 'Not In Use',
    NAP: 'Not Applicable',
  };

  return (
    data
      ? (
        <>
          <DraggableModal
            content={infoContent}
            handle=".handle"
            title={modalTitle}
            centered
            visible={infoModalVisible}
            style={{ maxWidth: '80vw' }}
            onCancel={closeInfoModal}
            footer={[
              <Button key="close" type="primary" onClick={closeInfoModal}>
                Close
              </Button>,
            ]}
          />
          <Card // plant data
            className="detailCard"
            style={{
              height: 'calc(60vh - 97px)',
              padding: '0px',
            }}
          >
            <div className="headline">{data?.PLANT_FACILITY_SAP_NM ?? 'No plant details available'}</div>
            <Descriptions
              column={1}
              bordered
              labelStyle={labelStyle}
              contentStyle={tableStyle}
              style={{ marginTop: '0px' }}
            >
              <Descriptions.Item label="Plant number">
                {
                  getSAPLink('Click to navigate to MD04 in SAP', 'MD04', [data?.MATERIAL_TYPE_SAP_ID, data?.PLANT_FACILITY_SAP_ID], data?.PLANT_FACILITY_SAP_ID)
                }
              </Descriptions.Item>
              <Descriptions.Item label="Supply process">
                {data?.SUPPLY_PROCESS}
                <Tooltip title={data?.SUPPLY_PROCESS_DESC}>
                  <InfoCircleOutlined style={{ textAlign: 'center', marginLeft: '3px' }} />
                </Tooltip>

              </Descriptions.Item>
              <Descriptions.Item label="Policy status">{data?.INVENTORY_STRATEGY_STATUS}</Descriptions.Item>
              <Descriptions.Item label={(
                <>
                  {'Status '}
                  <Tooltip title="More info"><InfoCircleTwoTone onClick={() => showInfoModal(infoModalTypes.storageStatus)} /></Tooltip>
                </>
              )}
              >
                <Tooltip title={getPlantStorageCheck(data?.STORAGE_CHECK_ID)}>
                  {statusIndicator(data?.STATUS_LIGHT)}
                  {` ${data?.STORAGE_CHECK_ID}`}
                </Tooltip>
              </Descriptions.Item>
              <Descriptions.Item label={(
                <>
                  {'Consumption group '}
                  <Tooltip title="More info"><InfoCircleTwoTone onClick={() => showInfoModal(infoModalTypes.consumptionGroup)} /></Tooltip>
                </>
              )}
              >
                <Tooltip title={consumptionGroups[data?.CC_CONSUMPTION_SEGMENT_GROUP]}>
                  {data?.CC_CONSUMPTION_SEGMENT_GROUP}
                </Tooltip>
              </Descriptions.Item>
              <Descriptions.Item label="Consumption sub-grp">{data?.CC_SEGMENT_SUBGROUP_NM}</Descriptions.Item>
              <Descriptions.Item label="Consumption qty in LT">{data?.CC_CONS_QTY_IN_LT}</Descriptions.Item>
              <Descriptions.Item label="Consumptions in LT">{data?.CC_CONS_COUNT_IN_LT}</Descriptions.Item>
              <Descriptions.Item label="Demand time">
                {data?.CC_MAX_CRITICALITY && getDemandTime(data.CC_MAX_CRITICALITY)}
              </Descriptions.Item>
              <Descriptions.Item label="Max Crit">{data?.CC_MAX_CRITICALITY}</Descriptions.Item>
              <Descriptions.Item label="Tot ABC">{data?.TOT_ABC}</Descriptions.Item>
              <Descriptions.Item label="Tot QABC">{data?.TOT_QABC}</Descriptions.Item>
              <Descriptions.Item label="Max QABC">{data?.CC_MAX_QABC}</Descriptions.Item>
              <Descriptions.Item label="Highest tag status">{data?.CC_MAX_TAG_STATUS}</Descriptions.Item>
              <Descriptions.Item label="Valuation class">{data?.VALUATION_CLASS}</Descriptions.Item>
              <Descriptions.Item label="Available stock">{data?.AVAIL_STOCK}</Descriptions.Item>
              <Descriptions.Item label="Other stock">{data?.CC_OTH_STOCK}</Descriptions.Item>
              <Descriptions.Item label="Total value">
                {canViewPrices && data?.TOT_VAL
                  ? `${convertCost(data.TOT_VAL, defaultCurrency, currencyList)} ${getCurrency(data.TOT_VAL, defaultCurrency)}`
                  : '-'}
              </Descriptions.Item>
              <Descriptions.Item label="Plant-sp.matl. status">{data?.MATERIAL_STATUS_SAP_CD}</Descriptions.Item>
              <Descriptions.Item label="Purchasing group">{data?.PURCHASING_GROUP}</Descriptions.Item>
              <Descriptions.Item label="EOQ">
                {data?.CC_ECONOMIC_ORDER_QTY ? formatNumbersLocale(displayRelevantDecimals(data?.CC_ECONOMIC_ORDER_QTY, true)) : '-'}
              </Descriptions.Item>
            </Descriptions>
          </Card>
          <RecommendationsContainer data={data} simulatedValues={simulatedValues} height="39vh" />
          <ResizableDrawer
            placement="right"
            closable={false}
            visible={visible}
            onClose={onClose}
            maskStyle={maskStyle}
            size={1070}
          >
            <SimulationContainer
              onClose={onClose}
              setSimulatedValues={setSimulatedValues}
            />
          </ResizableDrawer>
        </>
      )
      : <Empty />
  );
}

export default PlantDetailsCard;

PlantDetailsCard.defaultProps = {
  data: {},
  defaultCurrency: null,
  currencyList: [],
};

PlantDetailsCard.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  defaultCurrency: PropTypes.string,
  currencyList: PropTypes.arrayOf(PropTypes.any),
  canViewPrices: PropTypes.bool.isRequired,
};
