import React from 'react';
import { validate } from 'uuid';
import { CheckOutlined, MinusOutlined } from '@ant-design/icons';
import TableCell from './TableColumn';
import './monetaryImpactSummary.css';
import { displayRelevantDecimals, isoDateToFirstOfMonth, localDateFormat } from '../../Common/DateAndNumberFunctions';

const impactType = {
  savings: 'Savings',
  noImpact: 'No impact',
  investment: 'Investment',
};

const firstOfPreviousMonth = isoDateToFirstOfMonth(new Date().setMonth(new Date().getMonth() - 1));

const topLevelColumns = (defaultCurrency = 'NOK') => [
  { title: 'Impact Type', dataIndex: 'impactType', key: 'impactType' },
  { title: 'Number of registrations', dataIndex: 'noOfRegistrations', key: 'noOfRegistrations' },
  {
    title: 'Total Impact', dataIndex: 'totalImpact', key: 'totalImpact', render: (sum) => displayRelevantDecimals(sum, true),
  },
  {
    title: 'Currency', dataIndex: 'currency', key: 'currency', render: () => defaultCurrency,
  },
  {
    title: 'Last Registration Date', dataIndex: 'lastRegDate', key: 'lastRegDate', render: localDateFormat,
  },
];

const subLevelColumns = (
  data, defaultCurrency, editable, onDataChange,
) => {
  const noteFilter = Array
    .from(new Set(data?.map((i) => i.Notes)))
    .filter((n) => n !== null && !validate(n))
    .map((f) => ({ text: f, value: f }));

  const columns = [
    {
      title: 'Material',
      dataIndex: 'Material',
      key: 'Material',
      sorter: (a, b) => Number.parseInt(b.Material, 10) - Number.parseInt(a.Material, 10),
    },
    {
      title: 'Plant',
      dataIndex: 'Plant',
      key: 'Plant',
      sorter: (a, b) => Number.parseInt(b.Plant, 10) - Number.parseInt(a.Plant, 10),

    },
    {
      title: 'Impact',
      dataIndex: 'Impact',
      key: 'Impact',
      width: 200,
      render: (sum, record) => {
        if (editable && (new Date(record?.FormTimeStamp) > firstOfPreviousMonth)) {
          return (
            <TableCell
              initValue={sum}
              record={record}
              onDataChange={onDataChange}
              colName="Impact"
              addOn={defaultCurrency ?? 'NOK'}
            />
          );
        }
        return `${sum || '-'} ${defaultCurrency ?? 'NOK'}`;
      },
      // sorter: (a, b) => a.Impact.localeCompare(b.Impact),
      sorter: (a, b) => Number.parseFloat(b.Impact) - Number.parseFloat(a.Impact),
    },
    {
      title: 'Date',
      dataIndex: 'FormTimeStamp',
      key: 'FormTimeStamp',
      render: localDateFormat,
      sorter: (a, b) => a.FormTimeStamp?.localeCompare(b.FormTimeStamp),
      defaultSortOrder: 'desc',
    },
    {
      title: 'Impact comment/notes',
      dataIndex: 'Notes',
      key: 'Notes',
      render: (val, record) => {
        if (editable) {
          return (
            <TableCell
              initValue={val}
              record={record}
              onDataChange={onDataChange}
              colName="Notes"
            />
          );
        }
        return ((!validate(val) && val !== null) ? val : '');
      },
      filters: noteFilter,
      onFilter: (filterValue, record) => record?.Notes?.includes(filterValue),
      filterSearch: true,
      sorter: (a, b) => a?.Notes?.localeCompare(b?.Notes),
    },
    {
      title: 'Overstock',
      dataIndex: 'overstock',
      key: 'overstock',
      filters: [{ text: 'Overstock', value: 'Overstock' }, { text: 'Other', value: 'Other' }],
      onFilter: (v, r) => (v === 'Overstock' ? r?.Overstock : !r?.Overstock),
      render: (_val, record) => (record.Overstock ? <CheckOutlined style={{ color: 'green' }} /> : <MinusOutlined />),
    },
    {
      title: 'From Writeback',
      dataIndex: 'writeback',
      key: 'writeback',
      render: (_val, record) => (validate(record.WritebackID) ? <CheckOutlined style={{ color: 'green' }} /> : <MinusOutlined />),
    },
  ];
  if (editable) {
    columns.push({
      title: 'Impact Type',
      dataIndex: 'EditableType',
      key: 'EditableType',
      width: 150,
      render: (val, record) => (
        <TableCell
          initValue={val}
          disabled={!(new Date(record?.FormTimeStamp) > firstOfPreviousMonth)}
          onDataChange={onDataChange}
          colName="ImpactType"
          record={record}
        />
      ),
    });
  }
  return columns;
};

const onCell = (rec, curCol) => {
  const editableCols = JSON.parse(rec?.updatedCols ?? '');
  const hasUpdated = editableCols?.length && editableCols.includes(curCol);
  return hasUpdated ? { style: { background: '#fae1a9' } } : {};
};

const reviewTableCols = [
  {
    title: 'Material',
    dataIndex: 'Material',
    key: 'Material',
  },
  {
    title: 'Plant',
    dataIndex: 'Plant',
    key: 'Plant',
  },
  {
    title: 'Impact',
    dataIndex: 'Impact',
    key: 'Impact',
    onCell: (rec) => onCell(rec, 'Impact'),
  },
  {
    title: 'Impact comment/notes',
    dataIndex: 'Notes',
    key: 'Notes',
    onCell: (rec) => onCell(rec, 'Notes'),
  },
  {
    title: 'Impact Type',
    dataIndex: 'EditableType',
    key: 'EditableType',
    onCell: (rec) => onCell(rec, 'ImpactType'),
  },
];

export {
  impactType,
  topLevelColumns,
  subLevelColumns,
  reviewTableCols,
};
