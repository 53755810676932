import { API } from '../actions/api/typeApi';
import allActions from '../actions';
import {
  showNotification,
} from '../actions/utilities/Notifications/NotificationActions';

const scopes = [process.env.REACT_APP_API_ACCESS_SCOPE];

const TokenMiddleware = (msalInstance) => ({ dispatch, getState }) => {
  let auth = getState().authState;
  const checkToken = () => {
    try {
      auth = getState().authState;
      if (!auth?.user?.accessToken) return true;
      // get current time, convert to seconds because token uses seconds
      const currentTime = Math.floor(new Date().getTime() / 1000);
      const expiryTime = auth?.user?.account?.idTokenClaims?.exp;
      if (!expiryTime) return true;
      return (expiryTime - currentTime < 180);
    } catch {
      // return tokenExpired = true if any operation of the check fails
      return true;
    }
  };

  function errorNotification(errorMsg) {
    const message = errorMsg?.number === 401
      ? 'User is not authorized for this app.'
      : 'Failed to authorize user, please try to refresh the page.';
    return {
      Type: 'error', Title: 'Authorization failure.', Message: message, Placement: 'bottomRight',
    };
  }

  // eslint-disable-next-line consistent-return
  return (next) => (action) => {
    if (action.type === API && checkToken()) {
      const account = auth.user?.account
        ?? msalInstance.getActiveAccount()
        ?? msalInstance.getAllAccounts()?.[0];
      msalInstance.acquireTokenSilent({
        scopes,
        account,
        forceRefresh: true,
      }).then((response) => {
        if (response) {
          dispatch(allActions.AuthActions.storeToken(response));
          next(action);
        } else throw new Error('No response from MSAL.');
      }).catch((e) => {
        console.log(e);
        // dispatch(showNotification(errorNotification(e)));
      });
    } else return next(action);
  };
};

export default TokenMiddleware;
