import Modal from 'antd/lib/modal/Modal';
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import './InfoModal.css';

function InfoModal({
  content, title, visible, onCancel, footer, maxWidth,
}) {
  return (
    <Modal
      style={{ maxWidth: maxWidth ?? '45vw' }}
      title={title}
      visible={visible}
      centered
      onCancel={onCancel}
      footer={[
        ...footer,
        <Button key="close" type="primary" onClick={onCancel}>
          Close
        </Button>,
      ]}
    >
      {content}
    </Modal>
  );
}

export default InfoModal;

InfoModal.defaultProps = {
  footer: [],
  maxWidth: undefined,
};

InfoModal.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  footer: PropTypes.arrayOf(PropTypes.element),
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
