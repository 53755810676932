import * as actionTypes from './ActionTypes';

const storeToken = (data) => ({
  type: actionTypes.storeToken,
  payload: data,
});

const updateAccountData = (accountData) => ({
  type: actionTypes.updateAccountData,
  payload: accountData,
});

const setToLimitedViewer = () => ({
  type: actionTypes.setToLimitedViewer,
});

export default {
  storeToken,
  updateAccountData,
  setToLimitedViewer,
};
