/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import {
  Col, Row, PageHeader, Button, Tooltip, Tabs, Card,
} from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowLeftOutlined, ArrowRightOutlined, UndoOutlined } from '@ant-design/icons';
import PlantElements from './PlantElements';
import SummaryElements from './SummaryElements';
import TabElements from './TabElements';
import allActions from '../../../actions';
import Loading from '../../Common/Loading';
import { getMaterial, getLongText } from '../../../actions/MaterialDetailsDomain/LoadingKeys';
import './MaterialDetails.css';
import HeaderOptions from './HeaderOptions';
import { createIOTAPayload } from './components/SummaryComponents/Recommendations/Generators';
import * as loadingKeys from '../../../actions/MaterialDetailsDomain/LoadingKeys';
import { modes } from '../MaterialListView/MaterialListFunctions';
import hasAuthenticatedRole from '../../Common/AuthFunctions';
import CalendarModal from './components/CalendarModal';
import { formatMaterialNumber } from '../../Common/DateAndNumberFunctions';

const MaterialDetails = () => {
  const { TabPane } = Tabs;
  const screens = useBreakpoint();
  const { plant, material } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const worklistData = useSelector((state) => state.worklistState.staticWorklist);
  const classification = useSelector((state) => state.materialDetailsState?.classification);
  const relatedPlants = useSelector((state) => state.materialDetailsState.plantRelationships);
  const plants = useSelector((state) => state.commonState.plants);
  const configTable = useSelector((state) => state.commonState.configTable);
  const data = useSelector((state) => state.materialDetailsState.material);
  const loading = useSelector((state) => state.materialDetailsState.loading);
  const user = useSelector((state) => state.authState.user);
  const actionComplete = useSelector((state) => state.materialDetailsState.actionComplete);
  const mrpData = useSelector((state) => state.materialDetailsState.mrpSettings);
  const selectedRowKeys = useSelector((state) => state.materialState?.selectedRowKeys || []);
  const matList = useSelector((state) => state.materialState?.materialListData);
  const [simulationVisible, setSimulationVisible] = useState(false);
  const [calenderVisible, setCalenderVisible] = useState(false);
  const [fetchingMaterial, setFetchingMaterial] = useState(false);
  const [dataIsUpdated, setDataIsUpdated] = useState(false);
  const [navList, setNavList] = useState(null);
  const [excludePM10, setExcludePM10] = useState();
  const [canEdit, setCanEdit] = useState(false);
  const [currentCalender, setCalenderObj] = useState({
    text: null,
    visible: null,
    onOK: null,
    onCancel: null,
  });
  const [dateObj, setDateObj] = useState({
    review: null,
    inspect: null,
    postpone: null,
  });

  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    if (!user?.accessToken) return;
    if (material[0] === '0') {
      history.push(`/material-details/${plant}/${Number.parseInt(material, 10)}`);
    } else if (!fetchingMaterial && (!data || !(data.some((_material) => (_material.MATERIAL_TYPE_SAP_ID === material
      && _material.PLANT_FACILITY_SAP_ID === plant))))) {
      setDataIsUpdated(false);
      setFetchingMaterial(true);
      dispatch(allActions.MaterialDetailsActions.setLoading(getMaterial));
      dispatch(allActions.MaterialDetailsActions.fetchMaterial(plant, material));
      dispatch(allActions.MaterialDetailsActions.fetchPlantRelationships(plant));
      dispatch(allActions.MaterialDetailsActions.getClassification(material));
    }
    dispatch(allActions.MaterialDetailsActions.fetchChangelogs(plant, material));
    dispatch(allActions.MaterialDetailsActions.setLoading(getLongText));
    dispatch(allActions.MaterialDetailsActions.getLongText(plant, material));
  }, [plant, material, user?.accessToken]);

  useEffect(() => {
    if (user?.idTokenClaims?.roles) {
      const userCanEdit = hasAuthenticatedRole(
        user.idTokenClaims.roles,
        process.env.REACT_APP_VIEW_PRICES,
      );
      setCanEdit(userCanEdit);
    }
  }, [user?.idTokenClaims]);

  useEffect(() => {
    if (plants && (!classification || !(classification?.MATERIAL_TYPE_SAP_ID === material && material))) {
      dispatch(allActions.MaterialDetailsActions.getClassification(material));
    }
  }, [plants, plant, material]);

  useEffect(() => {
    if (data.length > 0 && configTable && dataIsUpdated) {
      const { supplier } = data.find((x) => x.PLANT_FACILITY_SAP_ID === plant);
      const excludeOnPlant = configTable.find((x) => x.PLANT_FACILITY_SAP_ID === plant)?.EXCLUDE_PM10;
      const excludeOnSupplier = configTable.find((x) => x.PLANT_FACILITY_SAP_ID === supplier)?.EXCLUDE_PM10;
      setExcludePM10(excludeOnPlant || excludeOnSupplier);
    }
  }, [data, configTable, dataIsUpdated]);

  useEffect(() => {
    setFetchingMaterial(false);
    setDataIsUpdated((data && (data.some((_material) => (_material.MATERIAL_TYPE_SAP_ID === material
      && _material.PLANT_FACILITY_SAP_ID === plant)))));
  }, [data]);

  useEffect(() => {
    relatedPlants?.[plant]?.forEach((_plant) => {
      const id = _plant.Plant_ID;
      if (data.some((_material) => (_material.MATERIAL_TYPE_SAP_ID === material
        && _material.PLANT_FACILITY_SAP_ID === id))) return;
      dispatch(allActions.MaterialDetailsActions.fetchAdditionalMaterial(id, material));
    });

    if (relatedPlants?.[plant]?.length > 0) {
      const relPlants = relatedPlants[plant].map((e) => e.Plant_ID);
      // check if current mrp settings from SAP for necessary materials exist in redux
      const fetchPlants = relPlants.filter((p) => !mrpData?.some(
        (m) => m.Plant === p
          && formatMaterialNumber(m.MaterialNumber) === material,
      ));
      if (fetchPlants?.length > 0) {
        dispatch(allActions.MaterialDetailsActions.setLoading(loadingKeys.mrp));
        dispatch(allActions.SapAPIActions.getMRPs(relPlants, [material]));
      }
    }
  }, [relatedPlants]);

  useEffect(() => {
    if (actionComplete?.review || actionComplete?.postpone || actionComplete?.inspect) {
      let key;
      let recKey;
      if (actionComplete.review) {
        key = 'Review';
        recKey = 'ReviewDate';
        dispatch(allActions.MaterialDetailsActions.updateReviewData(plant, material));
      } else if (actionComplete.postpone) {
        key = 'Postpone';
        recKey = 'PostponeToDate';
        dispatch(allActions.MaterialDetailsActions.fetchMaterial(plant, material));
      } else {
        key = 'Inspect';
        recKey = 'InspectToDate';
        dispatch(allActions.MaterialDetailsActions.fetchMaterial(plant, material));
      }
      const materialData = data?.find(
        (entry) => entry?.PLANT_FACILITY_SAP_ID === plant && entry?.MATERIAL_TYPE_SAP_ID === material,
      );
      dispatch(allActions.MaterialDetailsActions.clearActionCompleteStatus(key.toLocaleLowerCase()));
      const payload = createIOTAPayload(plant, material, [{
        current: materialData?.[recKey] ?? '-',
        userDefined: dateObj[key.toLocaleLowerCase()],
        key,
      }], user);
      dispatch(allActions.MaterialDetailsActions.createChangelog(plant, material, payload));
    }
  }, [actionComplete]);

  useEffect(() => {
    if (history.location?.state?.mode) {
      if (history.location?.state?.mode === modes.STATIC && worklistData?.length > 1) {
        setNavList(worklistData);
      } else if (history.location?.state?.mode === modes.DYNAMIC && matList?.length > 1) {
        setNavList(matList);
      }
    } else if (matList?.length > 1) {
      setNavList(matList);
    }
  }, [history.location?.state, worklistData, matList]);

  useEffect(() => () => {
    // cleanup-useEffect to clear tabs data when leaving
    dispatch(allActions.MaterialDetailsActions.clearData());
    // this ensures that when leaving through navigation, we don't
    // apply setPrevPath to incorrect value
    if (history.location?.state !== null) {
      dispatch(allActions.MaterialListActions.setPrevPath());
    }
  }, []);

  useEffect(() => {
    setNotFound(loading && (!loading[loadingKeys?.getMaterial] && !fetchingMaterial));
  }, [loading, fetchingMaterial]);

  const backToMaterialList = () => {
    const state = history.location?.state;
    if (state?.worklistID) {
      const worklistUrl = state?.mode === modes.DYNAMIC ? 'work-list' : 'static-work-list';
      history.push(`/${worklistUrl}/${state?.worklistID}`, { prevPath: 'matDetails' });
    } else {
      history.push('/material-list', { prevPath: 'matDetails' });
    }
  };

  const review = () => {
    setDateObj({ ...dateObj, review: new Date() });
    dispatch(allActions.MaterialDetailsActions.review(plant, material, user?.account?.username));
  };

  const postpone = (date) => {
    if (date) {
      setCalenderVisible(false);
      setDateObj({ ...dateObj, postpone: date });
      dispatch(allActions.MaterialDetailsActions.postpone(
        plant, material, date, user?.account?.username, user?.uniqueId,
      ));
    }
  };

  const inspect = (date) => {
    if (date) {
      setCalenderVisible(false);
      setDateObj({ ...dateObj, inspect: date });
      dispatch(allActions.MaterialDetailsActions.inspect(
        plant, material, date, user?.account?.username, user?.uniqueId,
      ));
    }
  };

  const calenderObj = {
    postpone: {
      text: 'Postpone',
      onOK: (date) => postpone(date),
      onCancel: () => setCalenderVisible(false),
    },
    inspect: {
      text: 'Inspect',
      onOK: (date) => inspect(date),
      onCancel: () => setCalenderVisible(false),
    },
  };

  const setCurrentCalender = (name) => {
    setCalenderVisible(true);
    setCalenderObj(calenderObj[name]);
  };

  const getNextSelectedMaterial = (nextPlant, nextMaterial, to) => {
    const index = selectedRowKeys?.findIndex((rowKey) => rowKey === nextPlant?.concat(nextMaterial));
    if (index >= 0) {
      let nextIndex = index + to;
      if (nextIndex >= selectedRowKeys?.length) nextIndex = 0;
      else if (nextIndex < 0) nextIndex = selectedRowKeys?.length - 1;
      return [selectedRowKeys[nextIndex]?.slice(0, 4), selectedRowKeys[nextIndex]?.slice(4)];
    }
    return [nextPlant, nextMaterial];
  };

  const goToNewMaterial = (to) => {
    const hasSelected = selectedRowKeys?.length > 1;
    let nextIndex = 0;
    let [nextMaterial, nextPlant, ...temp] = history?.location?.pathname.split('/').reverse();
    if (!navList?.length) return;
    const index = navList?.findIndex((matObj) => matObj?.PLANT_FACILITY_SAP_ID === plant
      && matObj?.MATERIAL_TYPE_SAP_ID === material);
    if (index !== -1) {
      temp = [1];
      if (hasSelected) {
        [nextPlant, nextMaterial] = getNextSelectedMaterial(nextPlant, nextMaterial, to);
      } else {
        nextIndex = index + to;
        if (nextIndex < 0) nextIndex = navList?.length - 1;
        else if (nextIndex >= navList?.length) nextIndex = 0;
        else if (nextIndex < 0) nextIndex = navList?.length - 1;
        nextMaterial = navList?.[nextIndex]?.MATERIAL_TYPE_SAP_ID;
        nextPlant = navList?.[nextIndex]?.PLANT_FACILITY_SAP_ID;
      }
    }
    if (nextPlant && nextMaterial && temp?.[0]) {
      const { mode, worklistID } = history?.location?.state;
      history.push(`/material-details/${nextPlant}/${nextMaterial}`,
        { mode: mode || '', worklistID: worklistID || '' });
    }
  };

  const extra = () => {
    let isFinalItem = false;
    if (selectedRowKeys?.length > 0) {
      const finalItem = selectedRowKeys?.slice(-1)?.[0];
      const finalPlant = finalItem.slice(0, 4);
      const finalMaterial = finalItem.slice(4);
      isFinalItem = (finalPlant === plant && finalMaterial === material);
    } else {
      const finalObject = navList?.slice(-1)?.[0];
      const { PLANT_FACILITY_SAP_ID: finalPlant, MATERIAL_TYPE_SAP_ID: finalMaterial } = finalObject || {};
      isFinalItem = (finalPlant === plant && finalMaterial === material);
    }
    return (
      <>
        {
          navList?.length ? (
            <>
              <Tooltip title="Go to previous Material">
                <ArrowLeftOutlined onClick={() => goToNewMaterial(-1)} />
              </Tooltip>
              <Tooltip title={isFinalItem ? 'Go to first material' : 'Go to next material'}>
                {isFinalItem ? <UndoOutlined onClick={() => goToNewMaterial(+1)} /> : <ArrowRightOutlined onClick={() => goToNewMaterial(+1)} />}
              </Tooltip>
            </>
          ) : (<></>)
        }
        <HeaderOptions data={data} style={{ margin: '0px 4px' }} canEdit={canEdit} />
        {
          canEdit
          && (
            <>
              <Button className="headerButton" size="small" type="primary" onClick={() => setSimulationVisible(true)}>Simulation</Button>
              <Tooltip title="Mark material as reviewed">
                <Button className="headerButton" size="small" type="primary" onClick={() => review()}>Review</Button>
              </Tooltip>
              <Button className="headerButton" size="small" type="primary" onClick={() => setCurrentCalender('postpone')}>Postpone</Button>
              <Button className="headerButton" size="small" type="primary" onClick={() => setCurrentCalender('inspect')}>Inspect</Button>
            </>
          )
        }
      </>
    );
  };

  const backIcon = () => {
    const title = `Return to ${history.location?.state?.worklistID ? 'Worklist' : 'Material List'}`;
    return (
      <div>
        <Tooltip title={title} placement="bottomLeft">
          <Button shape="round">
            <ArrowLeftOutlined />
          </Button>
        </Tooltip>
      </div>
    );
  };

  return (
    !dataIsUpdated
      ? <Loading spinning={!notFound} text={notFound ? 'Material not found' : 'Loading'} />
      : (
        <>
          <Row gutter={[4, 24]}>
            <Col span={screens.xxl ? 10 : 24}>
              <PageHeader
                className="leftColumn"
                backIcon={backIcon()}
                onBack={() => backToMaterialList()}
                title="Details"
                extra={extra()}
                style={{ margin: '0px', padding: '0px' }}
              >
                <SummaryElements visible={simulationVisible} setVisible={setSimulationVisible} />
              </PageHeader>
            </Col>
            <Col span={screens.xxl ? 14 : 24}>
              <Card bodyStyle={{
                paddingLeft: '6px', paddingRight: 0, marginLeft: 0, marginRight: 0,
              }}
              >
                <Tabs className="matDetsTabs" defaultActiveKey="1" size="small">
                  <TabPane tab="Additional Data" key="1">
                    <TabElements excludePM10={excludePM10} />
                  </TabPane>
                  <TabPane tab="Related Plants" key="2">
                    <PlantElements hasViewerAccess={canEdit} />
                  </TabPane>
                </Tabs>
              </Card>
            </Col>
          </Row>
          <CalendarModal
            visible={calenderVisible}
            onOK={currentCalender.onOK}
            onCancel={currentCalender.onCancel}
            text={currentCalender.text}
          />
        </>
      )
  );
};
export default MaterialDetails;
