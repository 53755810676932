/* eslint-disable consistent-return */
/* eslint-disable camelcase */
import axios from 'axios';
import apiAction from '../utilities/CommonMethods';
import {
  Base_URL,
  Sap,
  SapMRP,
  SapMRPs,
  SapMaterialMemo,
  SapMaterialMemos,
  SapPlannedOrderText,
  SapPlndOrdMassUpdate,
  MassUpdateChangelog,
  FixedBin,
} from '../APIUrls';
import * as action from './ActionTypes';
import * as matDetailsActions from '../MaterialDetailsDomain/ActionTypes';
import * as loadingKeys from '../MaterialDetailsDomain/LoadingKeys';
import { createKey } from '../../components/Views/MassUpdate/massUpdateMrp/MassUpdateMrpHelpers';
import { showNotification } from '../utilities/Notifications/NotificationActions';
import { formatMaterialNumber } from '../../components/Common/DateAndNumberFunctions';

const sapProxyUrl = Base_URL + Sap;

const getMRP = (plant, material) => {
  const url = sapProxyUrl + SapMRP;
  function receiveMRP(data) {
    if (data?.d) {
      const MRPData = { ...data?.d };
      return {
        type: action.getMRP,
        payload: [MRPData],
      };
    }
  }

  function failedFetch() {
    console.log('Error occured contacting SAP API for MRP lookup');
    return {
      type: matDetailsActions.failedLoading,
      payload: loadingKeys.mrp,
    };
  }

  return apiAction({
    url,
    method: 'GET',
    params: {
      plant,
      matNr: material,
    },
    onSuccess: receiveMRP,
    onFailure: failedFetch,
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'SAP Connection Failure',
      Message: 'Failed to load current MRP data from SAP',
    }),
    label: action.getMRP,
  });
};

const getMRPsWithFetch = async (plants, materials, token, dispatch) => {
  const materialArgs = materials.join(',');
  const plantArgs = plants.join(',');
  const rawResponse = await fetch(`${sapProxyUrl}${SapMRPs}?plant=${plantArgs}&matNrs=${materialArgs}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  const content = await rawResponse.json();

  const { error, responseData, success } = content;
  if (content?.responseData && success) {
    return responseData.d?.results;
  } if (error) {
    dispatch(showNotification({
      Type: 'error',
      Title: 'SAP Connection Failure',
      Message: 'Failed to load current MRP data from SAP',
    }));
    return [];
  }
  return [];
};

const getMRPs = (plants, materials) => {
  const url = sapProxyUrl + SapMRPs;
  const materialArgs = materials.join(',');
  const plantArgs = plants.join(',');
  function receiveMRP(data) {
    const results = data?.d?.results;
    if (results) {
      const MRPData = results.map((item) => ({ ...item, MaterialNumber: item.MaterialNumber }));
      return {
        type: action.getMRPs,
        payload: MRPData,
      };
    }
  }

  function failedFetch() {
    console.log('Error occured contacting SAP API for MRP lookup');
    return {
      type: matDetailsActions.failedLoading,
      payload: loadingKeys.mrp,
    };
  }

  return apiAction({
    url,
    method: 'GET',
    params: {
      plant: plantArgs,
      matNrs: materialArgs,
    },
    onSuccess: receiveMRP,
    onFailure: failedFetch,
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'SAP Connection Failure',
      Message: 'Failed to load array of current MRP data from SAP',
    }),
    label: action.getMRPs,
  });
};

const setMRP = (plant, material, changes, cleanup = false) => {
  const url = sapProxyUrl + SapMRP;

  return apiAction({
    url,
    method: 'PATCH',
    params: {
      plant,
      matNr: material,
    },
    data: changes,
    onSuccess: () => ({ type: cleanup ? null : action.setMRP }),
    onSuccessNotification: {
      Type: 'success',
      Title: 'SAP Update',
      Message: 'Successfully updated MRP settings in SAP',
    },
    onFailure: () => console.log('Error occured submitting changes to SAP'),
    onFailureNotification: (errorMsg) => ({
      Type: 'error',
      Title: 'SAP Update Failure',
      Message: errorMsg,
    }),
    label: action.setMRP,
  });
};

const setMrpsAsync = async (changes, token, dispatch, isFixedBin) => {
  const rawResponse = await fetch(`${sapProxyUrl}${SapMRPs}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(changes),
  });

  const content = await rawResponse.json();

  const { error: errorMsg, responseData, success } = content;

  if (responseData && success) {
    return false;
  } if (errorMsg) {
    try {
      const errorMaterials = [];
      const errorObject = JSON.parse(errorMsg);
      const errorArray = errorObject?.error?.innererror?.errordetails || [];
      const filteredErrors = errorArray.filter((e) => e.message !== 'An exception was raised' && e.severity !== 'success');
      let allErrors = '';
      filteredErrors.forEach((error) => {
        const { message, severity } = error;
        if (message && severity === 'error') {
          const getScrapedNotificationMessage = () => {
            if (message.includes('Material: ')) {
              const messageSplit = message.split('Material: ');
              const newMessage = messageSplit[0];
              const materialSplit = messageSplit[1].split(' ');

              return { material: formatMaterialNumber(materialSplit[0]), message: newMessage };
            }
          };
          const messageWithMaterial = getScrapedNotificationMessage();
          if (messageWithMaterial.material && messageWithMaterial.message) {
            errorMaterials.push(messageWithMaterial);
            if (isFixedBin) {
              allErrors += `${messageWithMaterial.message}\r\n\r\n`;
            }
          } else {
            allErrors += `${message}\r\n\r\n`;
          }
        }
      });
      dispatch(showNotification({
        Type: 'error',
        Title: 'SAP Update Failure - No materials updated',
        Message: allErrors,
      }));
      return errorMaterials;
    } catch { /* */ }
    return true;
  }
};

const setMRPs = (changes, ndCleanup = false) => {
  const url = sapProxyUrl + SapMRPs;
  const changeMappingForErrors = changes.to_Mrp
    .map((m) => ({ materialKey: createKey(m.Plant, m.MaterialNumber) }));
  return apiAction({
    url,
    method: 'POST',
    data: changes,
    onSuccess: () => ({ type: ndCleanup ? null : action.setMRP }),
    onSuccessNotification: {
      Type: 'success',
      Title: 'SAP Update',
      Message: 'Successfully mass updated MRP settings in SAP',
    },
    onFailure: () => ({ type: action.mrpUpdateError }),
    onFailureNotification: (errorMsg) => {
      try {
        const errorObject = JSON.parse(errorMsg);
        const errorArray = errorObject?.error?.innererror?.errordetails || [];
        const filteredErrors = errorArray.filter((e) => e.message !== 'An exception was raised' && e.severity !== 'success');
        const combined = changeMappingForErrors.map((item, index) => ({
          ...item,
          response: filteredErrors[index]?.message,
        }));
        let allErrors = '';
        combined.forEach((error) => {
          const { response, materialKey } = error;
          if (response) {
            if (materialKey) {
              const split = materialKey.split('-');
              const plant = split[0];
              const material = split[1];
              const getScrapedNotificationMessage = () => {
                if (response.includes('Material: ')) {
                  const messageSplit = response.split('Material: ');
                  const newMessage = messageSplit[0];
                  return newMessage;
                }
                return response;
              };

              const messagewithoutMatAndPlant = getScrapedNotificationMessage();

              const customMessage = `Plant: ${plant} - Material: ${material}\r\n ${messagewithoutMatAndPlant}\r\n\r\n`;
              allErrors += customMessage;
            } else {
              allErrors += `${response}\r\n\r\n`;
            }
          }
        });
        return {
          Type: 'error',
          Title: 'SAP Update Failure',
          Message: allErrors,
        };
      } catch { /* */ }
    },
    label: action.setMRPs,
  });
};

const getFixedBin = async (materialNumbers, warehouse, token, dispatch) => {
  const rawResponse = await fetch(`${sapProxyUrl}${FixedBin}?warehouse=${warehouse}&matNrs=${materialNumbers}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  const content = await rawResponse.json();

  const { error, responseData, success } = content;

  if (content?.responseData && success) {
    return responseData.d?.results;
  } if (error) {
    dispatch(showNotification({
      Type: 'error',
      Title: 'SAP Connection Failure',
      Message: 'Failed to load fixed bin data from SAP',
    }));
    return [];
  }
  return [];
};

const setFixedBin = async (materialNumber, warehouse, storageType, storageBin, token, dispatch) => {
  const rawResponse = await fetch(`${sapProxyUrl}${FixedBin}?materialNumber=${materialNumber}&warehouse=${warehouse}&storageType=${storageType}&storageBin=${storageBin}`, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  const content = await rawResponse.json();

  const { error, success } = content;
  if (success) {
    return false;
  } if (error) {
    try {
      const parsed = JSON.parse(error);
      const message = parsed?.error?.message?.value;
      dispatch(showNotification({
        Type: 'error',
        Title: 'SAP Error',
        Message: `Warehouse: ${warehouse}, storage type: ${storageType}: ${message}`,
      }));
    } catch { /* */ }
    return true;
  }
  return true;
};

const activateLastChecked = (plant, material) => {
  const url = sapProxyUrl + SapMRP;

  return apiAction({
    url,
    method: 'PATCH',
    params: {
      plant,
      matNr: material,
    },
    data: { ActivateLastChecked: true },
    onSuccess: () => getMRP(plant, material),
    onSuccessNotification: {
      Type: 'success',
      Title: 'Last Checked Date',
      Message: 'Successfully updated planned order Last checked date in SAP',
    },
    onFailure: () => console.log('Error occured while updating last checked date in SAP'),
    onFailureNotification: (errorMsg) => ({
      Type: 'error',
      Title: 'SAP Update Failure',
      Message: errorMsg,
    }),
    label: action.setMRP,
  });
};

const addMaterialMemo = (plant, material, input) => {
  const url = sapProxyUrl + SapMaterialMemo;
  const data = {
    MaterialNumber: material,
    Plant: plant,
    LongText: input,
    AppendText: '',
    DeleteText: input?.trim().length === 0 ? 'X' : '',
  };
  function failedFetch() {
    console.log('Error occured contacting SAP API for MRP lookup');
    return {
      type: matDetailsActions.failedLoading,
      payload: loadingKeys.mrp,
    };
  }

  return apiAction({
    url,
    method: 'PATCH',
    data,
    onSuccess: () => getMRP(plant, material),
    onSuccessNotification: {
      Type: 'success',
      Title: 'Material Memo Update',
      Message: 'Successfully updated material memo',
    },
    onFailure: failedFetch,
    onFailureNotification: (errorMsg) => ({
      Type: 'error',
      Title: 'Material memo update failed',
      Message: errorMsg,
    }),
    label: action.addMemo,
  });
};

const addPlannedOrderText = (plant, material, input) => {
  const url = sapProxyUrl + SapPlannedOrderText;
  const data = {
    MaterialNumber: material,
    Plant: plant,
    LongText: input,
    AppendText: '',
    DeleteText: input?.trim().length === 0 ? 'X' : '',
  };
  function failedFetch() {
    console.log('Error occured contacting SAP API for MRP lookup');
    return {
      type: matDetailsActions.failedLoading,
      payload: loadingKeys.mrp,
    };
  }

  return apiAction({
    url,
    method: 'PATCH',
    data,
    onSuccess: () => getMRP(plant, material),
    onSuccessNotification: {
      Type: 'success',
      Title: 'Planned order text update',
      Message: 'Successfully updated planned order text ',
    },
    onFailure: failedFetch,
    onFailureNotification: (errorMsg) => ({
      Type: 'error',
      Title: 'Planned order text update failed',
      Message: errorMsg,
    }),
    label: action.addPlOrdTxt,
  });
};

const createMassUpdateChangelog = (
  changes, changedBy, changedByEmail, plant, materials, textID,
) => {
  const url = `${Base_URL}${MassUpdateChangelog}`;
  const textType = textID === 'LTXT' ? 'memo' : 'planned order';
  return apiAction({
    url,
    method: 'POST',
    data: {
      changes, changedBy, changedByEmail, textID,
    },
    onSuccess: () => getMRPs([plant], materials),
    onFailure: () => console.log(`Error occured creating mass ${textType} changelog`),
    label: action.createMassUpdateChangelog,
  });
};

const addMaterialMemos = (
  data, requestJSON, changedBy, changedByEmail, plant, materials, textID,
) => {
  const url = sapProxyUrl + SapMaterialMemos;
  function failedFetch() {
    console.log('Error occured contacting SAP API for MRP lookup');
    return {
      type: matDetailsActions.failedLoading,
      payload: loadingKeys.mrp,
    };
  }
  return apiAction({
    url,
    method: 'PATCH',
    data,
    onSuccess: () => createMassUpdateChangelog(
      requestJSON, changedBy, changedByEmail, plant, materials, textID,
    ),
    onSuccessNotification: {
      Type: 'success',
      Title: 'Material Memos Update',
      Message: 'Successfully updated material memos',
    },
    onFailure: failedFetch,
    onFailureNotification: (errorMsg) => ({
      Type: 'error',
      Title: 'Material memos update failed',
      Message: errorMsg,
    }),
    label: action.addMemos,
  });
};

const addPlannedOrderTexts = (
  data, requestJSON, changedBy, changedByEmail, plant, materials, textID,
) => {
  const url = sapProxyUrl + SapPlndOrdMassUpdate;
  function failedFetch() {
    console.log('Error occured contacting SAP API for MRP lookup');
    return {
      type: matDetailsActions.failedLoading,
      payload: loadingKeys.mrp,
    };
  }
  return apiAction({
    url,
    method: 'PATCH',
    data,
    onSuccess: () => createMassUpdateChangelog(
      requestJSON, changedBy, changedByEmail, plant, materials, textID,
    ),
    onSuccessNotification: {
      Type: 'success',
      Title: 'Material Planned order text Update',
      Message: 'Successfully updated planned order texts of material',
    },
    onFailure: failedFetch,
    onFailureNotification: (errorMsg) => ({
      Type: 'error',
      Title: 'Material planned order texts update failed',
      Message: errorMsg,
    }),
    label: action.addPlOrdTxts,
  });
};

const clearFixedBinUpdated = () => ({
  type: action.clearFixedBinupdate,
});

const clearFixedBin = () => ({
  type: action.clearFixedBin,
  payload: null,
});

const clearMRP = () => ({
  type: action.clearMRP,
  payload: null,
});

const clearMRPs = () => ({
  type: action.clearMRPs,
  payload: null,
});

const clearMrpUpdate = () => ({ type: action.clearMRPupdate });

const clearMrpUpdateError = () => ({ type: action.clearMrpUpdateError });

export default {
  getMRP,
  getMRPs,
  getMRPsWithFetch,
  setMRP,
  addMaterialMemo,
  addMaterialMemos,
  addPlannedOrderText,
  addPlannedOrderTexts,
  clearMRP,
  clearMrpUpdate,
  activateLastChecked,
  setMRPs,
  setMrpsAsync,
  clearMRPs,
  clearMrpUpdateError,
  getFixedBin,
  setFixedBin,
  clearFixedBinUpdated,
  clearFixedBin,
};
