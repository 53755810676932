import { DeleteOutlined } from '@ant-design/icons';
import {
  Button, Divider, Modal, PageHeader, Radio, Tabs,
} from 'antd';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../../actions';
import { listModes, WORKLIST_VIEW } from '../../../Common/GlobalConstants';
import WorklistTable from './WorklistTable';

const ViewWorklists = () => {
  const { TabPane } = Tabs;
  const dispatch = useDispatch();

  const userState = useSelector((state) => state.authState.user);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [displayPersonal, setDisplayPersonal] = useState(WORKLIST_VIEW.PERSONAL);
  const [canDeleteWL, setCanDeleteWL] = useState(false);
  const [wlSettings, setWlSettings] = useState({
    listType: listModes.matList,
    view: WORKLIST_VIEW.PERSONAL,
  });
  const userSettings = useSelector(
    (state) => state.commonState.userSettings,
  );

  const handleDelete = () => {
    dispatch(allActions.WorkListActions.deleteWorklists(selectedItems, userState.account.username));
    setConfirmDeleteModal(false);
  };

  useEffect(() => {
    if (displayPersonal === WORKLIST_VIEW.PERSONAL) setCanDeleteWL(true);
    else if (userState?.idTokenClaims?.roles?.includes('SuperUser')) setCanDeleteWL(true);
    else setCanDeleteWL(false);
  }, [displayPersonal]);

  useEffect(() => {
    if (userSettings?.DYNAMIC_WORKLIST_DEFAULTS) {
      const temp = JSON.parse(userSettings?.DYNAMIC_WORKLIST_DEFAULTS);
      setWlSettings(temp);
      setDisplayPersonal(temp.view);
    }
  }, [userSettings]);

  const tableOperations = (
    <div>
      <div className="divFloatRight">
        <Radio.Group
          value={displayPersonal}
          buttonStyle="solid"
          onChange={(e) => setDisplayPersonal(e.target.value)}
        >
          <Radio.Button value={WORKLIST_VIEW.PERSONAL}>Personal</Radio.Button>
          <Radio.Button value={WORKLIST_VIEW.PUBLIC}>Public</Radio.Button>
        </Radio.Group>
        <Divider type="vertical" />
        <Button
          type="primary"
          disabled={!canDeleteWL || selectedItems?.length === 0}
          onClick={() => { setConfirmDeleteModal(true); }}
        >
          <DeleteOutlined />
          Delete selected items
        </Button>
      </div>
    </div>
  );

  return (
    <>
      <PageHeader
        title="Dynamic Worklists"
        ghost={false}
        style={{ minHeight: '50vh' }}
        extra={tableOperations}
      >
        <Tabs activeKey={wlSettings.listType} onChange={(key) => setWlSettings(key)}>
          <TabPane tab="Material List" key={listModes.matList}>
            <WorklistTable
              listType={listModes.matList}
              setSelectedItems={setSelectedItems}
              displayPersonal={displayPersonal}
              canDeleteWL={canDeleteWL}
              wlRoute="work-list"
            />
          </TabPane>
          <TabPane tab="Material Master List" key={listModes.mmList}>
            <WorklistTable
              listType={listModes.mmList}
              setSelectedItems={setSelectedItems}
              displayPersonal={displayPersonal}
              canDeleteWL={canDeleteWL}
              wlRoute="material-master/work-list"
            />
          </TabPane>
        </Tabs>
      </PageHeader>

      <Modal title="Delete" visible={confirmDeleteModal} onOk={handleDelete} onCancel={() => setConfirmDeleteModal(false)}>
        <p>Are you sure you want to delete the selected work lists?</p>
      </Modal>
    </>
  );
};
export default ViewWorklists;
