import {
  Row, Col, Button, Tooltip, Tag,
} from 'antd';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { SettingCodes } from './SettingTypes';
import MultiItemSelector from '../../Common/MultiItemSelector';
import '../../Menu/AppBar.css';
import { MODES } from '../../Common/GlobalConstants';

const PlantCompanySettings = ({ updateSetting, showPlant }) => {
  const storedPlantIDs = useSelector((state) => state.commonState.userSettings?.DEFAULT_PLANTS);
  const storedGrpsNdPlants = useSelector(
    (state) => state.commonState.userSettings?.DEFAULT_GROUPS_AND_PLANTS,
  );
  const storedCompanyCodes = useSelector(
    (state) => state.commonState.userSettings?.DEFAULT_COMPANY_CODES,
  );
  const allPlants = useSelector((state) => state.commonState.plants);
  const allCompanyCodes = useSelector((state) => state.commonState.companyCodes);
  const plantGroups = useSelector((state) => state.commonState.plantGroups);

  const [selectedPlants, setSelectedPlants] = useState([]);
  const [selectedPlantValues, setSelectedPlantValues] = useState([]);
  const [selectedCompanyCodes, setSelectedCompanyCodes] = useState([]);
  const [plantNames, setPlantNames] = useState({});
  const [ccNames, setCCNames] = useState({});
  const [plantList, setPlantList] = useState([]);
  const [ccList, setCCList] = useState([]);
  const [formattedGrps, formatPlantGrps] = useState([]);
  const [maxTagCount, setTagCount] = useState(0);
  const [grpsNdPlants, setGrpsNdPlants] = useState({
    groups: [],
    plants: [],
  });

  useEffect(() => {
    if (allPlants?.length > 0) {
      const tempPlantNames = {};
      allPlants.forEach((p) => {
        tempPlantNames[p.PLANT_FACILITY_SAP_ID] = p.PLANT_FACILITY_SAP_NM;
      });
      setPlantNames(tempPlantNames);
    }
  }, [allPlants]);

  useEffect(() => {
    if (allCompanyCodes?.length > 0) {
      const tempCCs = {};
      allCompanyCodes.forEach((cc) => {
        tempCCs[cc.Company_Code] = cc.Company_Name;
      });
      setCCNames(tempCCs);
      const tempccList = allCompanyCodes?.sort((cc1, cc2) => cc1.Company_Code - cc2.Company_Code);
      setCCList(tempccList);
    }
  }, [allCompanyCodes]);

  useEffect(() => {
    const sortedList = allPlants?.sort((a, b) => a.PLANT_FACILITY_SAP_ID - b.PLANT_FACILITY_SAP_ID);
    setPlantList(sortedList ? [...sortedList] : sortedList);
  }, [allPlants]);

  useEffect(() => {
    if (storedPlantIDs) {
      if (storedGrpsNdPlants) {
        const tempObj = JSON.parse(storedGrpsNdPlants);
        if (tempObj?.selectedPlants) {
          setTagCount(tempObj.selectedPlants?.filter((x) => {
            try {
              if (typeof JSON.parse(x) === 'object') return true;
              return false;
            } catch (exception) {
              console.log(exception, x);
              return false;
            }
          })?.length || 0);
          setSelectedPlants(tempObj.selectedPlants);
        } else setSelectedPlants(storedPlantIDs);
        if (tempObj?.grpsNdPlants) {
          setGrpsNdPlants(tempObj?.grpsNdPlants);
        }
      } else setSelectedPlants(storedPlantIDs);
      setSelectedPlantValues(storedPlantIDs);
    }
  }, [storedPlantIDs, storedGrpsNdPlants, formattedGrps]);

  useEffect(() => {
    if (storedCompanyCodes) {
      setSelectedCompanyCodes(storedCompanyCodes);
    }
  }, [storedCompanyCodes]);

  useEffect(() => {
    if (plantGroups?.length) {
      formatPlantGrps(plantGroups?.map((group) => ({
        name: group?.Name,
        value: JSON.stringify(group?.PlantList),
      })));
    }
  }, [plantGroups]);

  const clear = (code) => {
    if (code === SettingCodes.plant) {
      setSelectedPlants([]);
      setSelectedPlantValues([]);
    }
    if (code === SettingCodes.companyCode) {
      setSelectedCompanyCodes([]);
    }
  };

  const update = (e, code) => {
    const event = e?.filter((x) => Object.keys(x)?.length > 0);
    if (code === SettingCodes.plant) {
      const tempGroups = [];
      let grpCount = 0;
      let newSelectedPlants = [];
      let unSelectedPlants = [];
      const grpsObj = [];
      selectedPlants.forEach((val) => {
        if (unSelectedPlants.length === 0
        && typeof JSON.parse(val) !== 'object'
        && !event.find((p) => p.value === val)) {
          unSelectedPlants.push(val);
        }
      });
      event?.forEach((group) => {
        if (typeof JSON.parse(group?.value) === 'object') {
          JSON.parse(group?.value)?.forEach(
            (plantID) => (unSelectedPlants?.[0] !== plantID
              ? newSelectedPlants.push(plantID) : null),
          );
          tempGroups.push(group.value);
          grpCount += 1;
          if (!JSON.parse(group?.value)?.includes(unSelectedPlants[0])) {
            grpsObj.push({
              grpName: group.key,
              value: group.value,
            });
          }
        } else {
          newSelectedPlants.push(group.value);
        }
      });
      let groups = [...tempGroups];
      selectedPlants.forEach((val) => {
        // If group is un-selected, all corresponding plants are un-selected.
        if (typeof JSON.parse(val) === 'object' && !(tempGroups.find((x) => x === val))) {
          let tempUnSelectedPlants = JSON.parse(val); // Get all plants of unselected grp
          JSON.parse(val)?.forEach((p) => {
            const plantExistInOtherGrp = tempGroups?.find(
              // Check if plant exist in other SELECTED grps
              (grp) => JSON.parse(grp)?.find((x) => x === p),
            );
            if (plantExistInOtherGrp) {
              // Since, plant exist in other grp, It is not unSelected
              tempUnSelectedPlants = tempUnSelectedPlants.filter((x) => x !== p);
            }
          });
          unSelectedPlants = [...unSelectedPlants, ...tempUnSelectedPlants];
        // if plant is removed, Corresponding grps are un-selected
        } else if (!newSelectedPlants.find((p) => p === val)) {
          groups = groups.filter(
            (grp) => JSON.parse(grp).length && !(JSON.parse(grp).find((p) => p === val)),
          );
        }
      });
      newSelectedPlants = newSelectedPlants.filter(
        (x) => !unSelectedPlants.some((p) => x === p),
      );
      newSelectedPlants = newSelectedPlants?.filter(
        (id, index) => newSelectedPlants?.indexOf(id) === index,
      );
      setTagCount(grpCount);
      setSelectedPlantValues(newSelectedPlants);
      setSelectedPlants([...groups, ...newSelectedPlants]);
      setGrpsNdPlants({
        groups: grpsObj,
        plants: newSelectedPlants.filter(
          (x) => !grpsObj.length || !grpsObj.find((g) => g?.value?.includes(x)),
        ),
      });
    }
    if (code === SettingCodes.companyCode) {
      setSelectedCompanyCodes(event.map((eve) => eve.key));
    }
  };

  const handleChange = (event, code) => {
    if (event.length < 1) {
      clear(code);
    } else {
      update(event, code);
    }
  };

  const save = () => {
    if (showPlant) {
      clear(SettingCodes.companyCode);
      updateSetting(SettingCodes.plant, selectedPlantValues);
      updateSetting(SettingCodes.grpsNdPlants, JSON.stringify({
        selectedPlants,
        grpsNdPlants,
      }));
      updateSetting(SettingCodes.companyCode, []);
    } else {
      clear(SettingCodes.plant);
      updateSetting(SettingCodes.grpsNdPlants, '{}');
      updateSetting(SettingCodes.companyCode, selectedCompanyCodes);
      updateSetting(SettingCodes.plant, []);
    }
  };

  const unSelectOption = (e, value, field) => {
    if (field === MODES.CC) {
      const cList = selectedCompanyCodes.filter((x) => !(x === value));
      setSelectedCompanyCodes([...cList]);
    } else {
      let pList = selectedPlants.filter((x) => x !== value);
      pList = pList.filter((val) => {
        // if plant is removed, Corresponding grps are un-selected
        if (typeof JSON.parse(val) === 'object' && JSON.parse(val)?.find((p) => p === value)) {
          return false;
        }
        return true;
      });
      setSelectedPlants(pList);
      setSelectedPlantValues(pList.filter((x) => typeof JSON.parse(x) !== 'object'));
    }
  };

  const dispSelectedIDs = ({ value }, field) => {
    try {
      if (typeof JSON.parse(value) === 'object') {
        return null;
      }
    } catch (exception) {
      console.log(value);
      console.log(exception);
      return null;
    }
    let title;
    if (field === MODES.PLANT) {
      title = allPlants?.find(
        (p) => value && p?.PLANT_FACILITY_SAP_ID === value,
      )?.PLANT_FACILITY_SAP_NM;
    } else {
      title = allCompanyCodes?.find((cc) => value && cc?.Company_Code === value)?.Company_Name;
    }
    if (!title) title = value;
    else title = `${title}(${value})`;
    return (
      <Tooltip title={title}>
        <Tag
          key={value}
          closable="true"
          onClose={(e) => unSelectOption(e, value, field)}
        >
          <span style={{ fontSize: '16px' }}>{value}</span>
        </Tag>
      </Tooltip>
    );
  };
  const sortCCs = () => {
    if (!ccList) {
      return;
    }
    const sortedList = ccList?.sort((a, b) => {
      const aSearch = selectedCompanyCodes?.find((x) => a.Company_Code === x);
      const bSearch = selectedCompanyCodes?.find((x) => b.Company_Code === x);
      if ((aSearch && bSearch) || (!aSearch && !bSearch)) {
        return a.Company_Code - b.Company_Code;
      }
      if (aSearch) {
        return -1;
      }
      return 1;
    });
    setCCList(sortedList.length ? [...sortedList] : ccList);
  };

  const sortPlants = () => {
    if (!plantList) {
      return;
    }
    const sortedList = plantList?.sort((a, b) => {
      const aSearch = selectedPlants?.find((x) => a.PLANT_FACILITY_SAP_ID === x);
      const bSearch = selectedPlants?.find((x) => b.PLANT_FACILITY_SAP_ID === x);
      if ((aSearch && bSearch) || (!aSearch && !bSearch)) {
        return a.PLANT_FACILITY_SAP_ID - b.PLANT_FACILITY_SAP_ID;
      }
      if (aSearch) {
        return -1;
      }
      return 1;
    });
    setPlantList(sortedList.length ? [...sortedList] : plantList);
  };

  const onPressEnter = (e) => {
    if (e.key === 'Enter') {
      e.stopPropagation();
      e.target.blur();
    }
  };

  const pasteValue = (key, event) => {
    event.preventDefault();
    const pastedText = event.clipboardData.getData('text');
    const splitText = pastedText && pastedText.split('\r\n').filter((s) => s.length > 0);
    if (!splitText || splitText.length < 1) return;
    if (key === SettingCodes.plant) {
      const newPlants = [...new Set([...selectedPlants, ...splitText])];
      const filteredPlants = newPlants.filter(
        (val) => allPlants.some((plant) => plant.PLANT_FACILITY_SAP_ID === val),
      );
      setSelectedPlants(filteredPlants);
      setSelectedPlantValues(filteredPlants.filter((x) => typeof JSON.parse(x) !== 'object'));
    }
    if (key === SettingCodes.companyCode) {
      const newCCs = [...new Set([...selectedCompanyCodes, ...splitText])];
      const filteredCCs = newCCs.filter(
        (val) => allCompanyCodes.some((cc) => cc.Company_Code === val),
      );
      setSelectedCompanyCodes(filteredCCs);
    }
  };

  return (
    <>
      <Row style={{ marginBottom: '5px' }}>
        <Col span={18}>
          <MultiItemSelector
            disabled={!showPlant}
            placeHolder="Set default plant"
            items={plantList
              && plantList.map((x) => (
                { id: x.PLANT_FACILITY_SAP_ID, name: x.PLANT_FACILITY_SAP_NM }
              ))}
            groupedItems={formattedGrps}
            multiSelect
            selectedValues={selectedPlants}
            tagRender={(props) => dispSelectedIDs(props, MODES.PLANT)}
            maxTagCount={maxTagCount + 3}
            maxTagPlaceholder={(
              <Tooltip
                title={selectedPlantValues?.length > 0 && selectedPlantValues.map((p) => `${plantNames[p]} (${p})`).join('\r\n')}
                placement="right"
                overlayClassName="selectorTooltip"
              >
                {`+ ${(selectedPlantValues?.length ?? 0) - 3}`}
              </Tooltip>
            )}
            handleChange={(e) => handleChange(e, SettingCodes.plant)}
            clear={() => clear(SettingCodes.plant)}
            onPasteCapture={(e) => pasteValue(SettingCodes.plant, e)}
            onDropdownVisibleChange={() => sortPlants()}
            onInputKeyDown={(e) => onPressEnter(e)}
          />
        </Col>
        <Col span={6}>
          <Button disabled={!showPlant} type="primary" onClick={save} className="saveButton">Save changes</Button>
        </Col>
      </Row>
      <Row>
        <Col span={18}>
          <MultiItemSelector
            disabled={showPlant}
            placeHolder="Set default company code(s)"
            tagRender={(props) => dispSelectedIDs(props, MODES.CC)}
            items={ccList
              && ccList.map((x) => (
                { id: x.Company_Code, name: x.Company_Name }
              ))}
            multiSelect
            selectedValues={selectedCompanyCodes}
            maxTagCount={maxTagCount + 3}
            maxTagPlaceholder={(
              <Tooltip
                title={selectedCompanyCodes?.length > 0 && selectedCompanyCodes.map((cc) => `${ccNames[cc]} (${cc})`).join('\r\n')}
                overlayClassName="selectorTooltip"
              >
                {`+ ${(selectedCompanyCodes?.length ?? 0) - 3}`}
              </Tooltip>
            )}
            handleChange={(e) => handleChange(e, SettingCodes.companyCode)}
            clear={() => clear(SettingCodes.companyCode)}
            onPasteCapture={(e) => pasteValue(SettingCodes.companyCode, e)}
            onDropdownVisibleChange={() => sortCCs()}
            onInputKeyDown={(e) => onPressEnter(e)}
          />
        </Col>
        <Col span={6}>
          <Button disabled={showPlant} type="primary" onClick={save} className="saveButton">Save changes</Button>
        </Col>
      </Row>
    </>
  );
};

export default PlantCompanySettings;

PlantCompanySettings.propTypes = {
  updateSetting: PropTypes.func.isRequired,
  showPlant: PropTypes.bool.isRequired,
};
