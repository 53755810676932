import { Calendar, Modal } from 'antd';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

const CalendarModal = ({
  visible, onOK, onCancel, text,
}) => {
  const [date, setDate] = useState(new Date());
  return (
    <Modal
      title={`Select date to ${text} to`}
      visible={visible}
      onOk={() => onOK(date)}
      onCancel={onCancel}
      okText={text}
      cancelText="Cancel"
    >
      <div style={{
        width: '300px',
        border: '1px solid #f0f0f0',
        borderRadius: '2px',
      }}
      >
        <Calendar
          fullscreen={false}
          onChange={(selectedDate) => setDate(selectedDate)}
        />
      </div>
    </Modal>
  );
};

export default CalendarModal;

CalendarModal.defaultProps = {
  onOK: () => null,
  onCancel: () => null,
  text: '',
};

CalendarModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onOK: PropTypes.func,
  onCancel: PropTypes.func,
  text: PropTypes.string,
};
