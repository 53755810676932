import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { notification } from 'antd';
import { useLocation } from 'react-router-dom';
import './NotificationMsg.css';
import {
  clearNotification,
} from '../../actions/utilities/Notifications/NotificationActions';

const NotificationMsg = () => {
  const location = useLocation().pathname;
  const dispatch = useDispatch();
  const { notificationOpen, notificationData } = useSelector((state) => state.notificationState);
  const searchID = useSelector((state) => state.materialState.searchID);

  const getType = () => {
    if (notificationData?.Type === 'simSuccess') return 'success';
    if (notificationData?.Type === 'simFailed') return 'error';
    if (!notificationData?.Type) return 'info';
    return notificationData?.Type;
  };

  const closeNotificationClose = () => {
    dispatch(clearNotification());
  };

  return (
    notificationOpen
    && (!notificationData?.Page || notificationData?.Page === location)
    && (!notificationData?.searchID || notificationData?.searchID === searchID) ? (
      <div className="notificationContainer">
        { notification[getType()]({
          key: notificationData?.Key || notificationData.Title,
          className: 'notificationCls',
          message: notificationData.Title,
          description: notificationData.Message,
          placement: notificationData.Placement || 'bottomRight',
          duration: notificationData.Type === 'error' || notificationData.Type === 'simFailed' || notificationData.Type === 'simSuccess' ? 0 : 6,
          onClose: notificationData.onClose || closeNotificationClose,
        })}
      </div>
      ) : null
  );
};

export default NotificationMsg;
