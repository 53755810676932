import Modal from 'antd/lib/modal/Modal';
import React from 'react';
import PropTypes from 'prop-types';

function ConfirmModal({
  message, title, visible, onOK, onCancel, okText, style, okButtonDisabled, hideFooter,
}) {
  return (
    <Modal
      title={title}
      visible={visible}
      onOk={onOK}
      onCancel={onCancel}
      okText={okText}
      cancelText="Cancel"
      style={style}
      okButtonProps={{ disabled: okButtonDisabled }}
      footer={hideFooter ? null : undefined}
    >
      <>{message}</>
    </Modal>
  );
}

export default ConfirmModal;

ConfirmModal.defaultProps = {
  message: '',
  title: '',
  onOK: null,
  okText: 'Confirm',
  style: {},
  okButtonDisabled: false,
  hideFooter: false,
};

ConfirmModal.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.any)]),
  title: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  onOK: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
  okText: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.any),
  okButtonDisabled: PropTypes.bool,
  hideFooter: PropTypes.bool,
};
