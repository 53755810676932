/* eslint-disable no-continue */
import { PlusCircleOutlined } from '@ant-design/icons';
import {
  Button, Card, Col,
} from 'antd';
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import hasAuthenticatedRole from '../../Common/AuthFunctions';
import Widget from './Widget';
import { limitedWidgetTypes, widgetTypeKeys } from './Widgets/widgetTypes';
import styles from './styles.module.scss';

const WidgetContainer = ({ cards, editCards }) => {
  const userSettings = useSelector((state) => state.commonState.userSettings);
  const settingsLoaded = useSelector((state) => state.commonState?.loaded?.userSettings);
  const idTokens = useSelector((state) => state.authState.user?.idTokenClaims);
  const [additionalWidgets, setAdditonalWidgets] = useState([]);
  const [canViewValues, setCanViewValues] = useState(false);
  const [isSuperUser, setIsSuperUser] = useState(false);
  const intervalRef = useRef(null);
  const targetRef = useRef(null);
  const [draggedCard, setDraggedCard] = useState('');
  const [dragTarget, setDragTarget] = useState('');
  // const [newPosID, setNewPosID] = useState(null);

  const createCard = (type) => ({
    type: type || undefined,
    span: 8,
    uuid: uuidv4(),
  });

  const defaultLayout = [
    { key: widgetTypeKeys.optimalInvByTime },
    { key: widgetTypeKeys.inventoryValByTime },
    { key: widgetTypeKeys.monetaryImpact },
    { key: widgetTypeKeys.optimalInventoryByStrategy },
    { key: widgetTypeKeys.optimalInventoryByROP },
    { key: widgetTypeKeys.optimalInventoryByGroup },
    { key: widgetTypeKeys.plannedOrders },
    { key: widgetTypeKeys.cleanUpWidget },
    { key: widgetTypeKeys.excessStockByStatus },
  ];

  useEffect(() => {
    // clarify user accesses
    if (!idTokens?.roles) return;
    const userCanViewPrices = hasAuthenticatedRole(
      idTokens?.roles, process.env.REACT_APP_VIEW_PRICES,
    );
    setCanViewValues(userCanViewPrices);
    const superUser = hasAuthenticatedRole(
      idTokens?.roles, process.env.REACT_APP_CONFIG_ACCESS,
    );
    setIsSuperUser(superUser);

    // initially populate dashboard
    if (settingsLoaded) {
      const initialCards = [];
      const layout = userSettings?.DASHBOARD_LAYOUT?.length
        ? userSettings.DASHBOARD_LAYOUT : defaultLayout;
      layout.forEach((w) => {
        if (userCanViewPrices || !!limitedWidgetTypes[w.key]) {
          initialCards.push(createCard(w.key));
        }
      });
      editCards(initialCards);
    }
  }, [idTokens, settingsLoaded, userSettings]);

  // previous setup:
  // widgetTypeKeys.inventoryValByTime
  // widgetTypeKeys.monetaryImpact
  // widgetTypeKeys.optimalInventoryByStrategy
  // widgetTypeKeys.optimalInventoryByROP
  // widgetTypeKeys.optimalInventoryByGroup
  // widgetTypeKeys.plannedOrders
  // widgetTypeKeys.cleanUpWidget
  // widgetTypeKeys.excessStockByStatus

  useEffect(() => {
    const newWidgets = [];
    for (let i = 9; i > cards.length; i--) newWidgets.push(uuidv4());
    setAdditonalWidgets(newWidgets);
  }, [cards]);

  const addWidget = () => {
    editCards(() => [...cards, createCard()]);
  };

  const removeWidget = useCallback((index) => {
    const remainingCards = [...cards];
    remainingCards.splice(index, 1);
    editCards(remainingCards);
  });

  const setType = useCallback((type, index) => {
    const updatedCards = [...cards];
    updatedCards[index].type = type;
    editCards(updatedCards);
  });

  const getNewCardPostions = (dragCard, newPosCard) => {
    if (!dragCard) return cards;
    const dragCardIndex = cards.findIndex((x, i) => x?.uuid + i === dragCard);
    const newPosIndex = cards.findIndex((x, i) => x?.uuid + i === newPosCard);
    if (newPosIndex === -1 || dragCardIndex === -1 || newPosIndex === dragCardIndex) return cards;

    const newCards = _.cloneDeep(cards);
    newCards[newPosIndex] = _.cloneDeep(cards[dragCardIndex]);
    newCards[dragCardIndex] = _.cloneDeep(cards[newPosIndex]);
    return newCards;
  };

  const onDragFinish = (id) => {
    setDraggedCard('');
    setDragTarget('');
    targetRef.current = null;
    clearInterval(intervalRef.current);
    intervalRef.current = null;
    const newCards = getNewCardPostions(id, dragTarget);
    editCards(newCards);
  };

  const onDragOver = (uuid) => {
    targetRef.current = uuid;
    if (!intervalRef.current) {
      intervalRef.current = setInterval(() => {
        if (draggedCard === targetRef.current) setDragTarget('');
        else setDragTarget(targetRef.current || '');
      }, 300);
    }
  };
  const elementRef = useRef(null);

  return (
    <div ref={elementRef} className={styles.widgetContainer}>
      {cards.map((card, index) => {
        const uuid = card.uuid + index;
        return (
          <div
            id={uuid}
            draggable
            style={{
              transform: dragTarget === uuid && draggedCard !== uuid ? 'scale(0.96)' : '',
              boxShadow: dragTarget === uuid && draggedCard !== uuid
                ? 'rgba(0, 0, 0, 0.35) 0px 5px 15px' : '',
              cursor: 'grab',
            }}
            onDragStart={() => setDraggedCard(uuid)}
            onDragOver={() => onDragOver(uuid)}
            onDragEnd={() => onDragFinish(uuid)}
            className={styles.widgets}
          >
            <Col
              key={card.uuid}
            >
              <Widget
                key={card.uuid}
                index={index}
                settings={card}
                setType={setType}
                removeWidget={removeWidget}
                canViewValues={canViewValues}
                isSuperUser={isSuperUser}
              />
            </Col>
          </div>
        );
      })}
      {
        cards.length < 9
        && additionalWidgets?.map((key) => (
          <Col span={8} key={key} style={{ minWidth: 576 }}>
            <Card key={key} style={{ height: 200, textAlign: 'center' }} title="Add widget">
              <Button style={{ height: '100%' }} type="primary" onClick={() => addWidget()}>
                <PlusCircleOutlined style={{ fontSize: 30 }} />
              </Button>
            </Card>
          </Col>
        ))
      }
    </div>
  );
};
export default WidgetContainer;
WidgetContainer.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.any).isRequired,
  editCards: PropTypes.func.isRequired,
};
