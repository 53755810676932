import _ from 'lodash';

/* eslint-disable prefer-template */
const createKey = (p, m) => `${p}-${Number.parseInt(m, 10)}`;

const getDataSource = (items) => items.map((x) => ({ key: x, material: x }));

const getMaterialItem = (materialData, plant, material) => materialData?.find((x) => x.PLANT_FACILITY_SAP_ID === '' + plant
  && x.MATERIAL_TYPE_SAP_ID === '' + material);

const getRowClassName = (index, v, validMaterials) => {
  const materialFound = validMaterials.length > 0 ? validMaterials.some((id) => id === v.material) : true;
  if (!materialFound) return 'table-row-error';
  return index % 2 === 0 ? 'table-row-light' : 'table-row-dark';
};

const objectConstructor = (mrp, mat) => {
  const formattedObj = mrp.reduce((acc, item) => {
    acc[createKey(item.Plant, item.MaterialNumber)] = item;
    return acc;
  }, {});

  const keepArray = ['PLANT_FACILITY_SAP_ID', 'MATERIAL_TYPE_SAP_ID', 'MRPGroup', 'ABCIndicator',
    'MRPType', 'ReorderPoint', 'CC_OPEN_PO_QTY', 'OPEN_PR_QTY', 'CC_OPEN_RESERVATION_QTY', 'OPEN_RD_QTY', 'ST_OPEN_PR_QTY', 'ST_CC_OPEN_PO_QTY', 'LotSize', 'MinimumLotSize', 'MaximumLotSize',
    'MaximumStockLevel', 'RoundingValue', 'PlantMaterialStatus', 'StorageLocation',
    'FixedLotSize', 'MRPController', 'to_MrpLongText', 'TOT_STOCK', 'supplier', 'CC_MAX_CRITICALITY', 'FAIR_PRICE', 'EDAM_MATERIAL_TYPE_NM', 'CC_UNIT_COST_AMT', 'MARKET_PRICE', 'COMPANY_CURRENCY_SAP_CD'];
  const returnObject = {};
  Object.keys(formattedObj)
    .map((m) => formattedObj[m])
    .forEach((m) => {
      const materialItem = getMaterialItem(mat, m.Plant, parseInt(m.MaterialNumber, 10));
      const newObj = _.pick({ ...m, ...materialItem }, keepArray);
      newObj.docCount = (materialItem?.CC_OPEN_PO_QTY || 0)
          + (materialItem?.OPEN_PR_QTY || 0)
          + (materialItem?.OPEN_RD_QTY || 0)
          + (materialItem?.CC_OPEN_RESERVATION_QTY || 0)
          + (materialItem?.ST_CC_OPEN_PO_QTY || 0)
          + (materialItem?.ST_OPEN_PR_QTY || 0);
      returnObject[createKey(m.Plant, m.MaterialNumber)] = newObj;
    });
  return returnObject;
};

export {
  createKey, getDataSource, getMaterialItem, getRowClassName, objectConstructor,
};
