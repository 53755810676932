import { Table } from 'antd';
import React from 'react';
import { getIndicatorColor } from '../helpers';

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    width: '3%',
  },
  {
    title: 'Condition',
    dataIndex: 'condition',
    width: '8%',
  },
  {
    title: 'Check',
    dataIndex: 'check',
    width: '13%',
  },
  {
    title: 'Status light',
    dataIndex: 'statusLight',
    width: '5%',
    onCell: (record) => ({
      style: { background: getIndicatorColor(record?.statusLight), color: 'transparent', userSelect: 'none' },
    }),
  },
  {
    title: 'Inventory policy check',
    dataIndex: 'invPolicyCheck',
    width: '7%',
  },
  {
    title: 'Inventory strategy status',
    dataIndex: 'invStrategyStatus',
    width: '14%',
  },
  {
    title: 'Plant storage check',
    dataIndex: 'plantStorageCheck',
    width: '50%',
  },
];

const dataSource = [
  {
    id: '0',
    condition: '',
    check: '',
    statusLight: 'Green',
    invPolicyCheck: '',
    invStrategyStatus: 'None',
    plantStorageCheck: 'Not stocked',
  },
  {
    id: '1',
    condition: '',
    check: '',
    statusLight: 'Green',
    invPolicyCheck: 'Yes',
    invStrategyStatus: 'According to strategy',
    plantStorageCheck: 'Based on the highest criticality (ABC=3) storage at this plant is allowed',
  },
  {
    id: '2',
    condition: '',
    check: '',
    statusLight: 'Green',
    invPolicyCheck: 'Yes',
    invStrategyStatus: 'According to strategy',
    plantStorageCheck: 'Based on the highest criticality (ABC=3) storage at this plant is allowed. NB: also stocked onshore',
  },
  {
    id: '3',
    condition: '',
    check: '',
    statusLight: 'Green',
    invPolicyCheck: 'Yes',
    invStrategyStatus: 'According to strategy',
    plantStorageCheck: 'Based on the highest criticality (ABC=2/6) storage at this plant is allowed',
  },
  {
    id: '4',
    condition: '',
    check: '',
    statusLight: 'Red',
    invPolicyCheck: 'No',
    invStrategyStatus: 'Medium criticality',
    plantStorageCheck: 'Based on the highest criticality (ABC=2/6) storage is only allowed onshore',
  },
  {
    id: '5A',
    condition: 'Leadtime',
    check: 'IF =< 15 days',
    statusLight: 'Red',
    invPolicyCheck: 'No',
    invStrategyStatus: 'Low criticality',
    plantStorageCheck: 'Leadtime is shorter or equal to demand time (15 days). Direct supply is sufficient.',
  },
  {
    id: '5B',
    condition: 'Leadtime',
    check: 'ELSE',
    statusLight: 'Green',
    invPolicyCheck: 'Yes',
    invStrategyStatus: 'According to strategy',
    plantStorageCheck: 'Based on the highest criticality (ABC=9) and lead time (>15 days) storage at this plant is allowed',
  },
  {
    id: '6A',
    condition: 'Leadtime',
    check: 'IF =< 15 days',
    statusLight: 'Red',
    invPolicyCheck: 'No',
    invStrategyStatus: 'Low criticality',
    plantStorageCheck: 'Leadtime is shorter or equal to demand time (15 days). Direct supply is sufficient.',
  },
  {
    id: '6B',
    condition: 'Leadtime',
    check: 'ELSE',
    statusLight: 'Red',
    invPolicyCheck: 'No',
    invStrategyStatus: 'Medium criticality',
    plantStorageCheck: 'Based on the highest criticality (ABC=9) and lead time (=<15 days) storage is only allowed onshore',
  },
  {
    id: '7A',
    condition: 'Leadtime',
    check: 'IF =< 60 days',
    statusLight: 'Red',
    invPolicyCheck: 'No',
    invStrategyStatus: 'Low criticality',
    plantStorageCheck: 'Leadtime is shorter or equal to demand time (60 days). Direct supply is sufficient.',
  },
  {
    id: '7B',
    condition: 'Leadtime',
    check: 'ELSE',
    statusLight: 'Green',
    invPolicyCheck: 'Yes',
    invStrategyStatus: 'According to strategy',
    plantStorageCheck: 'Based on the highest criticality (ABC=5) and lead time (>60 days) storage at this plant is allowed',
  },
  {
    id: '8A',
    condition: 'Leadtime',
    check: 'IF =< 60 days',
    statusLight: 'Red',
    invPolicyCheck: 'No',
    invStrategyStatus: 'Low criticality',
    plantStorageCheck: 'Leadtime is shorter or equal to demand time (60 days). Direct supply is sufficient.',
  },
  {
    id: '8B',
    condition: 'Leadtime',
    check: 'ELSE',
    statusLight: 'Red',
    invPolicyCheck: 'No',
    invStrategyStatus: 'Medium criticality',
    plantStorageCheck: 'Based on the highest criticality (ABC=5) and lead time (=<60 days) storage is only allowed onshore',
  },
  {
    id: '9',
    condition: '',
    check: '',
    statusLight: 'Red',
    invPolicyCheck: 'No',
    invStrategyStatus: 'Low criticality',
    plantStorageCheck: 'Low criticality (ABC=1/4/7/8). Storage at this plant is not allowed.',
  },
  {
    id: '10',
    condition: '',
    check: '',
    statusLight: 'Yellow',
    invPolicyCheck: 'TBC',
    invStrategyStatus: 'ABC other',
    plantStorageCheck: 'Criticality unknown (ABC= A/N/Y/Z/blank). Consider "approved deviation" if stocking is required.',
  },
  {
    id: '11',
    condition: '',
    check: '',
    statusLight: 'Red',
    invPolicyCheck: 'No',
    invStrategyStatus: 'Not tied to tag',
    plantStorageCheck: 'Not tied to tag. Storage at this plant is not allowed. Consider "approved deviation" if stocking is required.',
  },
  {
    id: '12A',
    condition: 'Holding cost',
    check: 'IF =< transaction cost',
    statusLight: 'Green',
    invPolicyCheck: 'Yes',
    invStrategyStatus: 'Fast moving',
    plantStorageCheck: 'Fast moving material with holding cost less than transaction cost is allowed in stock',
  },
  {
    id: '12B',
    condition: 'Holding cost',
    check: 'ELSE',
    statusLight: 'Green',
    invPolicyCheck: '',
    invStrategyStatus: '',
    plantStorageCheck: 'Criticality ruleset',
  },
  {
    id: '13A',
    condition: 'Holding cost',
    check: 'IF =< transaction cost',
    statusLight: 'Green',
    invPolicyCheck: 'Yes',
    invStrategyStatus: 'Fast moving',
    plantStorageCheck: 'Fast moving material with holding cost less than transaction cost is allowed in stock. NB: also stocked onshore',
  },
  {
    id: '13B',
    condition: 'Holding cost',
    check: 'ELSE',
    statusLight: 'Green',
    invPolicyCheck: '',
    invStrategyStatus: '',
    plantStorageCheck: 'Criticality ruleset',
  },
  {
    id: '14A',
    condition: 'Holding cost',
    check: 'IF =< transaction cost',
    statusLight: 'Green',
    invPolicyCheck: '',
    invStrategyStatus: 'None',
    plantStorageCheck: 'Consider stocking fast moving material with holding cost less than transaction cost',
  },
  {
    id: '14B',
    condition: 'Holding cost',
    check: 'ELSE',
    statusLight: 'Green',
    invPolicyCheck: '',
    invStrategyStatus: 'None',
    plantStorageCheck: 'Not stocked',
  },
  {
    id: '15',
    condition: '',
    check: '',
    statusLight: 'Green',
    invPolicyCheck: 'Yes',
    invStrategyStatus: 'Approved deviation',
    plantStorageCheck: 'Approved deviation. Stock at this plant is allowed.',
  },
];

const StorageCheckIdInfo = () => {
  dataSource.forEach((entry, index) => {
    dataSource[index] = { ...entry, key: index };
  });
  return (
    <Table
      pagination={false}
      dataSource={dataSource}
      columns={columns}
      size="small"
      className="infoTable"
      scroll={{ x: true }}
    />
  );
};

export const getPlantStorageCheck = (id) => dataSource.find((x) => x.id === id)?.plantStorageCheck;
export default StorageCheckIdInfo;
