import React from 'react';
import {
  Button, Card, Table, Input,
} from 'antd';
import styles from './styles.module.scss';

const FixedBinConfirm = ({
  plant, originalValues, changes, warehouse,
}) => {
  const asList = Object.values(changes);
  const columns = [
    {
      children: [{
        title: 'Plant',
        dataIndex: 'plant',
        render: () => plant,
        width: '5%',
      }],
    },
    {
      children: [{
        title: 'Whs',
        render: () => warehouse,
        width: '5%',
      }],
    },
    {
      children: [{
        title: 'Material',
        dataIndex: 'material',
        render: (material) => material,
        defaultSortOrder: 'descend',
        width: '8%',
      }],
    },
    {
      children: [{

        title: 'Material description',
        render: (v) => {
          const foundMat = originalValues.find((m) => m.material === v.material);
          if (foundMat) {
            return foundMat.materialDescription;
          }
          return '';
        },
        width: '25%',

      }],
    },
    {
      children: [{

        title: 'MRP Type',
        render: (v) => {
          const foundMat = originalValues.find((m) => m.material === v.material);
          if (foundMat) {
            return foundMat.mrpType;
          }
          return '';
        },
        width: '8%',

      }],
    },
    {
      children: [{
        title: 'Stor.Type 001',
        render: (v) => {
          const foundChange = Object.values(changes).find((change) => change.material === v.material && change.storageType === '001');
          if (foundChange) {
            return foundChange.fixedBin;
          }
          return v['001'] === 'NO_VALUE' ? '' : v['001'];
        },
        width: '10%',
        onCell: (v) => {
          const foundChange = Object.values(changes).find((change) => change.material === v.material && change.storageType === '001');
          return { style: { background: foundChange ? '#fae1a9' : undefined } };
        },
      }],
    },
    {
      children: [{
        title: 'Stor.Type 002',
        render: (v) => {
          const foundChange = Object.values(changes).find((change) => change.material === v.material && change.storageType === '002');
          if (foundChange) {
            return foundChange.fixedBin;
          }
          return v['002'] === 'NO_VALUE' ? '' : v['002'];
        },
        width: '10%',
        onCell: (v) => {
          const foundChange = Object.values(changes).find((change) => change.material === v.material && change.storageType === '002');
          return { style: { background: foundChange ? '#fae1a9' : undefined } };
        },
      }],
    },
    {
      children: [{
        title: 'Stor.Type 003',
        render: (v) => {
          const foundChange = Object.values(changes).find((change) => change.material === v.material && change.storageType === '003');
          if (foundChange) {
            return foundChange.fixedBin;
          }
          return v['003'] === 'NO_VALUE' ? '' : v['003'];
        },
        width: '10%',
        onCell: (v) => {
          const foundChange = Object.values(changes).find((change) => change.material === v.material && change.storageType === '003');
          return { style: { background: foundChange ? '#fae1a9' : undefined } };
        },
      }],
    },
    {
      children: [{
        title: 'Stor.Type 020',
        render: (v) => {
          const foundChange = Object.values(changes).find((change) => change.material === v.material && change.storageType === '020');
          if (foundChange) {
            return foundChange.fixedBin;
          }
          return v['020'] === 'NO_VALUE' ? '' : v['020'];
        },
        width: '10%',
        onCell: (v) => {
          const foundChange = Object.values(changes).find((change) => change.material === v.material && change.storageType === '020');
          return { style: { background: foundChange ? '#fae1a9' : undefined } };
        },
      }],
    },

  ];

  return (
    <div>
      <Table
        className={styles.fixedBinConfirm}
        columns={columns}
        dataSource={asList}
        pagination={false}
        style={{ whiteSpace: 'pre-wrap' }}
        size="small"
        scroll={{ x: 1000, y: 398 }}
      />
    </div>
  );
};

export default FixedBinConfirm;
