import { emptyValue } from '../../../../../Common/GlobalConstants';

const filterValues = (key = null, values) => {
  if (key === 'MRPType') {
    return values.filter((x) => x.DROPDOWN_VALUE !== 'VI');
  }
  if (key === 'LotSize') {
    return values.filter((x) => x.DROPDOWN_VALUE !== 'MB'
      && x.DROPDOWN_VALUE !== 'W3'
      && x.DROPDOWN_VALUE !== 'WB');
  }
  return values;
};

const displayNumber = (value, isMassUpdate) => {
  // eslint-disable-next-line eqeqeq
  if (value == 0) {
    return isMassUpdate ? '' : emptyValue;
  }
  if (!Number.isNaN(value) && !value) {
    return isMassUpdate ? '' : emptyValue;
  }
  return value;
};

const getRecommendationForMassUpdate = (mrpSetting, data, mrpData) => {
  switch (mrpSetting) {
    case 'ABCIndicator':
      return mrpData?.ABCIndicator;
    case 'MRPGroup':
      return data?.CC_MRP_GROUP;
    case 'MRPType':
      return data?.CC_MRP_TYPE;
    case 'LotSize':
      return data?.CC_LOT_SIZE;
    case 'ReorderPoint':
      return displayNumber(data?.CC_ROP, true);
    case 'MinimumLotSize':
      return displayNumber(data?.CC_MIN_LOT_SIZE, true);
    case 'MaximumStockLevel':
      return displayNumber(data?.CC_MAX_STOCK, true);
    case 'MaximumLotSize':
      return displayNumber(data?.TO_BE_CONFIRMED, true);
    case 'RoundingValue':
      return displayNumber(data?.EDAM_ROUNDING_VALUE_FOR_PO_QTY, true);
    case 'PlantMaterialStatus':
      return mrpData?.PlantMaterialStatus;
    case 'MRPController':
      return data?.CC_MRP_CONTROLLER;
    case 'FixedLotSize':
      return displayNumber(data?.CC_FIXED_LOT_SIZE, true);
    default:
      return '';
  }
};

const getRecommendation = (mrpSetting, data, mrpData) => {
  if (!data?.DISPLAY_REC_IND) return '';
  switch (mrpSetting) {
    case 'ABCIndicator':
      return mrpData?.ABCIndicator;
    case 'MRPGroup':
      return data?.CC_MRP_GROUP;
    case 'MRPType':
      return data?.CC_MRP_TYPE;
    case 'LotSize':
      return data?.CC_LOT_SIZE;
    case 'ReorderPoint':
      return displayNumber(data?.CC_ROP);
    case 'MinimumLotSize':
      return displayNumber(data?.CC_MIN_LOT_SIZE);
    case 'MaximumStockLevel':
      return displayNumber(data?.CC_MAX_STOCK);
    case 'MaximumLotSize':
      return displayNumber(data?.TO_BE_CONFIRMED);
    case 'RoundingValue':
      return displayNumber(data?.EDAM_ROUNDING_VALUE_FOR_PO_QTY);
    case 'PlantMaterialStatus':
      return mrpData?.PlantMaterialStatus;
    case 'MRPController':
      return data?.CC_MRP_CONTROLLER;
    case 'FixedLotSize':
      return displayNumber(data?.CC_FIXED_LOT_SIZE);
    default:
      return '';
  }
};

const mrpSettingIsNumber = (columnName) => {
  switch (columnName) {
    case 'ReorderPoint':
    case 'MinimumLotSize':
    case 'MaximumStockLevel':
    case 'MaximumLotSize':
    case 'RoundingValue':
    case 'FixedLotSize':
      return true;
    default:
      return false;
  }
};

const plantHasRecommendationsForMassUpdate = (newRecTableData, materialData, mrpData) => newRecTableData.some((mrpSetting) => getRecommendationForMassUpdate(mrpSetting?.key, materialData, mrpData) !== '');

const plantHasRecommendations = (newRecTableData, materialData, mrpData) => newRecTableData.some((mrpSetting) => getRecommendation(mrpSetting?.key, materialData, mrpData) !== '');

const getCurrent = (mrpData, mrpSetting, isMassUpdate) => {
  if (mrpSettingIsNumber(mrpSetting.key)) {
    // eslint-disable-next-line eqeqeq
    if (mrpData[mrpSetting.key] == 0) {
      return isMassUpdate ? '' : emptyValue;
    }
    return Number.parseFloat(mrpData[mrpSetting.key]).toString().replace('.', ',');
  }
  return mrpData[mrpSetting.key];
};

const getInitialValues = (materialData, newRecTableData, mrpData) => {
  const hasRecs = plantHasRecommendations(newRecTableData, materialData, mrpData);
  newRecTableData.forEach((mrpSetting) => {
    if (mrpSetting.key in mrpData) {
      const settingObject = newRecTableData.find((s) => s.key === mrpSetting.key);
      const current = getCurrent(mrpData, mrpSetting);
      const recommended = getRecommendation(mrpSetting.key, materialData, mrpData);

      settingObject.current = current;
      settingObject.recommended = recommended;
      if (!hasRecs) {
        settingObject.userDefined = current;
      } else if (!recommended && (current === '0' || current === 0 || current === emptyValue)) {
        settingObject.userDefined = 0;
      } else {
        settingObject.userDefined = recommended;
      }
      if (settingObject.userDefined === '-') settingObject.userDefined = '';
    }
  });
  return newRecTableData;
};

const getDropdownValues = (dropdownColumns, colDropdownVals, setLocalDropDowns) => {
  const tempDropdowns = {};
  Object.keys(dropdownColumns).forEach((key) => {
    const temp = [];
    filterValues(key, colDropdownVals?.[dropdownColumns[key]]).forEach(
      (ddVal) => ddVal?.DROPDOWN_VALUE && temp.push(ddVal?.DROPDOWN_VALUE),
    );
    if (temp.length) {
      if (key === 'MRPType') {
        tempDropdowns[key] = temp;
      } else {
        tempDropdowns[key] = [...temp, ''];
      }
    }
  });
  setLocalDropDowns(tempDropdowns);
};

export {
  emptyValue,
  getCurrent,
  plantHasRecommendations,
  getRecommendation,
  getInitialValues,
  mrpSettingIsNumber,
  getDropdownValues,
  plantHasRecommendationsForMassUpdate,
  getRecommendationForMassUpdate,
};
