import React, { useState } from 'react';
import {
  Button, Empty, Modal, Table,
} from 'antd';
import { DeleteOutlined, FolderOpenOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const FormOptions = ({
  buttonStyle, overwriteForm, deleteForm, addToForm, loadSelectedForm,
}) => {
  const allForms = useSelector((state) => state.simState.newStockData?.allNewStockForms);

  const [modalVisible, setModalVisible] = useState(false);

  const handleClick = (action, modalVisibility = false) => {
    setModalVisible(modalVisibility);
    action();
  };

  return (
    <>
      <Modal
        title="New Stock Forms"
        visible={modalVisible}
        style={{ minWidth: '800px' }}
        onCancel={() => setModalVisible(false)}
        footer={[
          <Button key="FormOptionsClose" onClick={() => setModalVisible(false)}>
            Close
          </Button>,
        ]}
      >
        <Table
          size="small"
          pagination={false}
          dataSource={allForms?.map(
            (item) => ({ ...item, key: item.formId }),
          )}
          locale={{ emptyText: <Empty description="No forms found" /> }}
          columns={[
            {
              dataIndex: 'formName', title: 'Form Name', defaultSortOrder: 'ascend', sorter: (a, b) => a.formName.localeCompare(b.formName),
            },
            { dataIndex: 'itemCount', title: 'Items', render: (_t, record) => JSON.parse(record?.formData)?.form?.length ?? '-' },
            { dataIndex: 'description', title: 'Description', render: (text) => (text?.length > 0 ? text : '-') },
            {
              dataIndex: 'open',
              title: 'Open form',
              render: (_t, record) => (
                <Button
                  onClick={() => handleClick(() => loadSelectedForm(record.formId), false)}
                  type="primary"
                  size="small"
                  style={{ width: '100%' }}
                >
                  Open form
                </Button>
              ),
            },
            {
              dataIndex: 'add',
              title: 'Add current items',
              render: (t, record) => (
                <Button
                  onClick={() => handleClick(() => addToForm(record), true)}
                  type="primary"
                  size="small"
                  style={{ width: '100%' }}
                >
                  Add to form
                </Button>
              ),
            },
            {
              dataIndex: 'save',
              title: 'Overwrite current',
              render: (t, record) => (
                <Button
                  onClick={() => handleClick(() => overwriteForm(record), true)}
                  type="primary"
                  size="small"
                  style={{ width: '100%' }}
                >
                  Overwrite
                </Button>
              ),
            },
            {
              dataIndex: 'delete',
              title: 'Delete form',
              render: (t, record) => (
                <Button style={{ width: '100%' }} onClick={() => handleClick(() => deleteForm(record), true)}>
                  <DeleteOutlined
                    style={{ color: 'red' }}
                  />
                </Button>
              ),
            },
          ]}
        />
      </Modal>
      <Button onClick={() => setModalVisible(true)} type="primary" style={buttonStyle}>
        <FolderOpenOutlined style={{ fontSize: '20px' }} />
        Form Options
      </Button>
    </>
  );
};

export default FormOptions;

FormOptions.defaultProps = {
  buttonStyle: {},
};

FormOptions.propTypes = {
  buttonStyle: PropTypes.objectOf(PropTypes.any),
  overwriteForm: PropTypes.func.isRequired,
  addToForm: PropTypes.func.isRequired,
  deleteForm: PropTypes.func.isRequired,
  loadSelectedForm: PropTypes.func.isRequired,
};
