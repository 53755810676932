import { PublicClientApplication } from '@azure/msal-browser';

const redirectUri = process.env.REACT_APP_AAD_REDIRECT_URI;
const clientId = process.env.REACT_APP_CLIENT_ID;

// MSAL config
const config = {
  auth: {
    authority: 'https://login.microsoftonline.com/3aa4a235-b6e2-48d5-9195-7fcf05b459b0',
    clientId,
    redirectUri,
    navigateToLoginRequestUrl: true,
  },
  system: {
    tokenRenewalOffsetSeconds: 180,
  },
};

const msalInstance = new PublicClientApplication(config);

export default msalInstance;
