import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { InfoCircleOutlined } from '@ant-design/icons';
import {
  Modal, Select, Input, Tooltip,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { listModes, STATIC_WL_MAX_ITEMS } from '../../../Common/GlobalConstants';
import allActions from '../../../../actions';
import { getMMSelectedItems } from '../MaterialListFunctions';

const { TextArea } = Input;
const { Option } = Select;

const AddToStaticWL = ({
  selectedItems, create, materialMaster, closeModal,
  displayHeaderCells, materialCount, hasSelectedAll, materialViewState,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authState.user);
  const mlWorklists = useSelector((state) => state.worklistState.staticWorklists);
  const mmWorklists = useSelector((state) => state.worklistState.mmStaticWorklists);

  const [visible, setVisible] = useState(true);

  const [worklists, setWorklists] = useState([]);

  const [wlNameExists, setWlNameExists] = useState(false);
  const [newWorklistName, setNewWorklistName] = useState('');
  const [newWorklistDescription, setNewWorklistDescription] = useState();
  const [noRoomInWL, setNoRoomInWL] = useState(false);
  const [selectedWL, setSelectedWL] = useState();

  useEffect(() => {
    const wlHasRoom = mlWorklists.some(
      (wl) => STATIC_WL_MAX_ITEMS - selectedItems?.length - wl.NoOfRecords >= 0,
    );
    setNoRoomInWL(!wlHasRoom);
  }, [materialCount]);

  useEffect(() => {
    const tempWLs = materialMaster ? mmWorklists : mlWorklists;
    setWorklists(tempWLs);
    if (!create) {
      let flag = false;
      tempWLs?.forEach((wl) => {
        if (flag) return;
        if (wl?.NoOfRecords + materialCount <= STATIC_WL_MAX_ITEMS
          || wl?.NoOfRecords + selectedItems.length <= STATIC_WL_MAX_ITEMS) {
          setSelectedWL(wl);
          flag = true;
        }
      });
    }
  }, [mlWorklists, mmWorklists, materialMaster]);

  const handleClose = () => {
    setVisible(false);
    closeModal();
  };

  const handleAdd = () => {
    const metadata = {
      userID: user?.uniqueId,
      worklistID: selectedWL.WORKLIST_ID,
      worklistName: selectedWL.WorklistName,
      create: false,
    };
    const listMode = materialMaster ? listModes.mmList : listModes.matList;
    if (hasSelectedAll) {
      metadata.noOfRecords = Math.min(
        STATIC_WL_MAX_ITEMS, (selectedWL.NoOfRecords + materialCount),
      );
      dispatch(allActions.WorkListActions.allMaterialStaticWL(
        metadata,
        displayHeaderCells,
        materialViewState,
        listMode,
        (metadata.noOfRecords - selectedWL.NoOfRecords),
      ));
    } else if (materialMaster) {
      const wlItems = worklists?.find(
        (wl) => wl?.WORKLIST_ID === selectedWL.WORKLIST_ID,
      )?.MaterialList;
      const tempSelected = getMMSelectedItems(selectedItems, wlItems);
      dispatch(allActions.WorkListActions.updateMMStaticWLContent(metadata, tempSelected, true));
    } else {
      dispatch(allActions.WorkListActions.addToStaticWL(metadata, selectedItems, listMode));
    }
    handleClose();
  };

  const handleCreate = () => {
    const regexStr = /^\s+|\s+$/g; // Remove line breaks and spaces at both ends of the string.
    const tempWLName = newWorklistName?.replace(regexStr, '');
    const tempWLDescr = newWorklistDescription?.replace(regexStr, '');
    const metadata = {
      userID: user?.uniqueId,
      worklistID: uuidv4(),
      worklistName: tempWLName,
      noOfRecords: hasSelectedAll ? materialCount : selectedItems.length,
      userEmail: user?.account?.username ?? null,
      worklistDescription: tempWLDescr,
      create: true,
    };
    const listMode = materialMaster ? listModes.mmList : listModes.matList;

    if (hasSelectedAll) {
      dispatch(allActions.WorkListActions.allMaterialStaticWL(
        metadata, displayHeaderCells, materialViewState, listMode,
      ));
    } else if (materialMaster) {
      dispatch(allActions.WorkListActions.createStaticWL(
        metadata, displayHeaderCells, selectedItems, listMode,
      ));
    } else {
      dispatch(allActions.WorkListActions.createStaticWL(
        metadata, displayHeaderCells, selectedItems, listMode,
      ));
    }
    handleClose();
  };

  const selectWorklist = (_value, opt) => {
    setSelectedWL(worklists?.find((wl) => wl?.WORKLIST_ID === opt.key));
  };

  const updateWorklistName = (name) => {
    const exists = worklists.some((worklist) => worklist.WorklistName === name);
    setWlNameExists(exists);
    setNewWorklistName(name);
  };

  const optionIsDisabled = (wl) => wl?.NoOfRecords
    + (hasSelectedAll ? materialCount : selectedItems?.length)
    >= STATIC_WL_MAX_ITEMS;

  const getTooltip = (wl) => (selectedItems.length + wl.NoOfRecords > STATIC_WL_MAX_ITEMS
    ? `Worklist would exceed max. allowed number of items. Max allowed: ${STATIC_WL_MAX_ITEMS}`
    : null);

  return (
    <Modal
      title={create
        ? `Create ${materialMaster ? 'Material Master' : ''} Worklist`
        : `Add to ${materialMaster ? 'Material Master' : ''} Worklist`}
      visible={visible}
      onOk={create ? handleCreate : handleAdd}
      okButtonProps={create
        ? { disabled: !newWorklistName?.length || wlNameExists }
        : { disabled: !selectedWL || !selectedItems?.length }}
      onCancel={handleClose}
      style={{ minWidth: '450px' }}
    >
      {
        create && (
          <div>
            <TextArea
              autoSize
              placeholder="Worklist Name"
              value={newWorklistName}
              onChange={(e) => updateWorklistName(e.target.value)}
            />
            <span
              style={{
                visibility: (newWorklistName.length > 0 && wlNameExists) ? 'visible' : 'hidden',
                color: '#cf1322',
              }}
            >
              Name is already in use.
            </span>
            <br />
            <TextArea
              autoSize
              placeholder="Worklist Description"
              value={newWorklistDescription}
              maxLength={500}
              showCount
              onChange={(e) => setNewWorklistDescription(e.target.value)}
            />
          </div>
        )
      }
      {
        !create && noRoomInWL && (
          <div>
            None of the worklists can fit all these items
          </div>
        )
      }
      {
        !create && !noRoomInWL && (
          <Select
            value={selectedWL ? `${selectedWL?.WorklistName} - (${selectedWL?.NoOfRecords})` : undefined}
            onChange={(value, option) => selectWorklist(value, option)}
            showSearch
            style={{ width: '100%' }}
            placeholder="Select an existing worklist"
            optionLabelProp="label"
          >
            {
              worklists.map((worklist) => (
                <Option
                  key={worklist.WORKLIST_ID}
                  value={worklist.WorklistName}
                  disabled={optionIsDisabled(worklist)}
                >
                  <Tooltip
                    title={getTooltip(worklist)}
                    mouseEnterDelay={0}
                  >
                    {worklist.WorklistName}
                    {' - '}
                    {worklist.NoOfRecords}
                    {worklist.NoOfRecords === 1 ? ' item' : ' items'}
                  </Tooltip>
                </Option>
              ))
            }
          </Select>
        )
      }
      <p style={{ width: '100%' }}>
        Adding
        {' '}
        {hasSelectedAll ? materialCount : selectedItems.length}
        {' '}
        items
        <br />
        <InfoCircleOutlined style={{
          textAlign: 'center', fontSize: '14px', marginRight: '5px', marginTop: '6px',
        }}
        />
        Static worklists can contain up to 2000 items.

      </p>
    </Modal>
  );
};

export default AddToStaticWL;

AddToStaticWL.defaultProps = {
  selectedItems: [],
  displayHeaderCells: [],
  materialMaster: false,
  materialCount: 0,
  hasSelectedAll: false,
};

AddToStaticWL.propTypes = {
  selectedItems: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        plantID: PropTypes.string,
        materialID: PropTypes.string,
      }),
      PropTypes.string,
    ]),
  ),
  create: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  displayHeaderCells: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  materialMaster: PropTypes.bool,
  materialCount: PropTypes.number,
  hasSelectedAll: PropTypes.bool,
  materialViewState: PropTypes.objectOf(PropTypes.any).isRequired,
};
