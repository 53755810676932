import React from 'react';
import PropTypes from 'prop-types';
import { Empty, Table } from 'antd';
import { confirmColumns, getChangedKeys } from './massUpdateGlobals';

const MemoConfirm = ({
  selectedPlant,
  materials,
  currentTextList,
  newTextUpdates,
  excludeList,
  textType,
}) => {
  const getDataSource = () => {
    if (!selectedPlant || materials?.length === 0) return [];
    const changedKeys = getChangedKeys(
      selectedPlant, materials, excludeList, currentTextList, newTextUpdates,
    );
    const source = changedKeys.map((key) => {
      const [plant, material] = key.split('-');
      return {
        key,
        plant,
        material,
        currentText: currentTextList[key],
        newText: newTextUpdates[key],
      };
    });
    return source;
  };

  return (
    materials?.length > 0 && selectedPlant
      ? (
        <Table
          dataSource={getDataSource()}
          columns={confirmColumns(textType)}
          size="small"
          pagination={false}
          style={{ whiteSpace: 'pre-wrap' }}
          scroll={{
            y: 465,
          }}
        />
      )
      : <Empty />
  );
};

export default MemoConfirm;

MemoConfirm.defaultProps = {
  selectedPlant: null,
  materials: [],
  currentTextList: {},
  newTextUpdates: {},
  excludeList: [],
};

MemoConfirm.propTypes = {
  selectedPlant: PropTypes.string,
  materials: PropTypes.arrayOf(PropTypes.string),
  currentTextList: PropTypes.objectOf(PropTypes.string),
  newTextUpdates: PropTypes.objectOf(PropTypes.string),
  excludeList: PropTypes.arrayOf(PropTypes.string),
  textType: PropTypes.string.isRequired,
};
