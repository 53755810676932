const massUpdate = {
  MEMO: 'material_memo',
  PLANNED_ORDER: 'planned_order',
  IOTA_MEMO: 'iota_memo',
  WAREHOUSE_MEMO: 'warehouse_memo',
  MRP: 'mrp',
  FIXED_BIN: 'fixed_bin',
};
const isCommentType = (type) => [massUpdate.IOTA_MEMO, massUpdate.WAREHOUSE_MEMO].includes(type);

const getChangedKeys = (plant, materials = [], excludeList = [], currentTexts, newTexts) => {
  if (!plant || materials.length === 0) return [];
  const newList = materials?.map(
    (m) => {
      // eslint-disable-next-line prefer-template
      const key = '' + plant + '-' + m; // `${plant}-${m}`;
      return key;
    },
  )
    .filter((m) => !excludeList.includes(m))
    .filter((m) => currentTexts[m] !== newTexts[m]);
  return newList || [];
};
const requestBuilder = (
  selectedPlant, materials, currentTexts, newTexts, excludeList, textType,
) => {
  if (!selectedPlant || materials?.length === 0) return null;
  const textKeys = getChangedKeys(selectedPlant, materials, excludeList, currentTexts, newTexts);
  const longTexts = [];
  const revertTexts = [];
  const previousTexts = [];
  textKeys.forEach((key) => {
    const [plant, material] = key.split('-');
    const commentType = isCommentType(textType);
    const textID = (type) => {
      switch (type) {
        case massUpdate.MEMO:
          return 'LTXT';
        case massUpdate.PLANNED_ORDER:
          return 'Z901';
        default:
          return type;
      }
    };
    const currentMemoObj = {
      MaterialNumber: material,
      Plant: plant,
      LongText: currentTexts[key],
      AppendText: '',
      DeleteText: currentTexts[key]?.trim().length === 0 ? 'X' : '',
      TextObject: commentType ? '' : 'MDTXT',
      TextID: textID(textType),
    };
    revertTexts.push(currentMemoObj);
    const newMemoObj = {
      MaterialNumber: material,
      Plant: plant,
      LongText: newTexts[key],
      AppendText: '',
      DeleteText: newTexts[key]?.trim().length === 0 ? 'X' : '',
      TextObject: commentType ? '' : 'MDTXT',
      TextID: textID(textType),
    };
    longTexts.push(newMemoObj);
    previousTexts.push(newMemoObj);
  });
  const newRequest = {
    LongTextBatch: '',
    to_LongText: longTexts,
    previousTexts: revertTexts,
  };
  const revertRequest = {
    LongTextBatch: '',
    to_LongText: revertTexts,
    previousTexts,
  };
  return [newRequest, revertRequest];
};

const getCurTabText = (curTab) => {
  switch (curTab) {
    case massUpdate.MEMO:
      return 'Material Memo';
    case massUpdate.PLANNED_ORDER:
      return 'Planned Order Text';
    case massUpdate.IOTA_MEMO:
      return 'IOTA Memo';
    case massUpdate.WAREHOUSE_MEMO:
      return 'Warehouse Memo';
    default:
      return '';
  }
};

const confirmColumns = (textType) => {
  const columns = [
    {
      title: 'Plant',
      dataIndex: 'plant',
      width: '8%',
    },
    {
      title: 'Material',
      dataIndex: 'material',
      width: '12%',
      sorter: (a, b) => Number.parseInt(b.material, 10) - Number.parseInt(a.material, 10),
    },
  ];
  if (textType === massUpdate.PLANNED_ORDER || textType === massUpdate.MEMO) {
    columns.push({
      title: `Current ${textType === massUpdate.MEMO ? 'Memo' : 'Planned Order Text'}`,
      dataIndex: 'currentText',
      width: '40%',
    });
    columns.push({
      title: `New ${textType === massUpdate.MEMO ? 'Memo' : 'Planned Order Text'}`,
      dataIndex: 'newText',
      width: '40%',
    });
  } else {
    columns.push(
      {
        title: getCurTabText(textType),
        dataIndex: 'newText',
        width: '40%',
      },
    );
  }
  return columns;
};

export {
  massUpdate, isCommentType, getChangedKeys, requestBuilder, confirmColumns, getCurTabText,
};
