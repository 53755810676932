import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'antd';
import { SettingCodes } from './SettingTypes';

const DashboardSettings = ({ updateSetting, showWidgetLegends, loading }) => {
  const handleToggle = (e) => {
    updateSetting(SettingCodes.showWidgetLegends, e);
  };

  return (
    <Switch
      checked={showWidgetLegends}
      onChange={handleToggle}
      loading={loading?.USER_SETTINGS ?? true}
    />
  );
};

export default DashboardSettings;

DashboardSettings.defaultProps = {
  showWidgetLegends: false,
  loading: {},
};

DashboardSettings.propTypes = {
  updateSetting: PropTypes.func.isRequired,
  showWidgetLegends: PropTypes.bool,
  loading: PropTypes.objectOf(PropTypes.any),
};
