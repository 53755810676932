import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import {
  CaretRightOutlined, CheckOutlined, CloseOutlined, InfoCircleOutlined,
} from '@ant-design/icons';
import './SimInputDisplay.css';

const SimInputDisplay = ({ simID }) => {
  const simResults = useSelector((state) => state.simState.simResults);
  const allSimJobs = useSelector((state) => state.simState.allSimJobs);
  const [simInput, setSimInput] = useState();
  const [previousValues, setPreviousValues] = useState({});
  const keys = {
    mrpType: 'mrp_type',
    leadtime: 'lead_time',
    maxCrit: 'max_criticality',
    approvedDeviation: 'approved_deviation',
  };

  useEffect(() => {
    if (simResults?.[simID]?.metadata?.input?.simulation_parameters) {
      try {
        const input = JSON.parse(simResults?.[simID]?.metadata?.input?.simulation_parameters)[0];
        input.key = input.plant;
        setSimInput([input]);
        if (simResults?.[simID]?.metadata?.input?.current_values) {
          try {
            const previous = JSON.parse(simResults?.[simID]?.metadata?.input?.current_values);
            setPreviousValues(previous);
          } catch { /* */ }
        }
      } catch { /* */ }
    }
  }, [simID, simResults]);

  const getClassName = (type) => (simInput?.[0]?.[type] ? 'selected-column' : '');

  const renderDeviation = (text) => (text ? <CheckOutlined /> : <CloseOutlined />);

  const renderPreviousValue = (text, key) => (
    <>
      {key === keys.approvedDeviation
        ? renderDeviation(previousValues[key])
        : previousValues[key]}
      <CaretRightOutlined style={{ padding: '0px 2px' }} />
      {key === keys.approvedDeviation
        ? renderDeviation(text)
        : text}
    </>
  );

  const addPreviousValue = (text, key) => (previousValues[key] !== undefined
    ? renderPreviousValue(text, key)
    : text || '-');

  const getTitle = () => (
    <div>
      {`Simulation Inputs for run ${simID}`}
      {allSimJobs?.[simID]?.Description
        ? (
          <Tooltip title={`Description: ${allSimJobs[simID].Description}`}>
            <InfoCircleOutlined style={{ marginLeft: '5px' }} />
          </Tooltip>
        )
        : ''}
    </div>
  );

  const columns = [
    {
      key: 'title',
      dataIndex: 'title',
      title: getTitle,
      children: [
        {
          key: keys.mrpType,
          dataIndex: keys.mrpType,
          title: 'MRP Type',
          className: getClassName(keys.mrpType),
          render: (text) => addPreviousValue(text, keys.mrpType),
          onCell: () => ({ style: { textAlign: 'center' } }),
        },
        {
          key: keys.leadtime,
          dataIndex: keys.leadtime,
          title: 'Lead Time',
          className: getClassName(keys.leadtime),
          render: (text) => addPreviousValue(text, keys.leadtime),
          onCell: () => ({ style: { textAlign: 'center' } }),
        },
        {
          key: keys.maxCrit,
          dataIndex: keys.maxCrit,
          title: 'Max criticality',
          className: getClassName(keys.maxCrit),
          render: (text) => addPreviousValue(text, keys.maxCrit),
          onCell: () => ({ style: { textAlign: 'center' } }),
        },
        {
          key: keys.approvedDeviation,
          dataIndex: keys.approvedDeviation,
          title: 'Approved deviation',
          className: getClassName(keys.approvedDeviation),
          render: (text) => addPreviousValue(text, keys.approvedDeviation),
          onCell: () => ({ style: { textAlign: 'center' } }),
        },
      ],
    },

  ];

  return (
    <Table
      columns={columns}
      dataSource={simInput}
      pagination={false}
      bordered
      size="small"
    />
  );
};

export default SimInputDisplay;

SimInputDisplay.defaultProps = {
  simID: null,
};

SimInputDisplay.propTypes = {
  simID: PropTypes.string,
};
