/* eslint-disable camelcase */
import * as actionTypes from './ActionTypes';
import apiAction from '../utilities/CommonMethods';
import {
  GetOptInvTot,
  Base_URL,
  Get_Optimal_Inventory_By_Group_URL,
  Get_Optimal_Inventory_By_SP_URL,
  Get_Optimal_Inventory_By_Crit_URL,
  Get_Planned_Orders_URL,
  Get_Inventory_Value_URL,
  Get_Excess_Stock_By_Status,
  Get_Monetary_Impact,
  Get_Total_Excess_Stock_Value,
  Get_Optimal_Inventory_By_ROP_URL,
  Get_Clean_Up_Widget_URL,
  Get_Optimal_Inventory_By_Time_URL,
  Get_Inventory_Value_By_Time_URL,
  Get_Optimal_Inventory_By_Strategy_URL,
} from '../APIUrls';
import { MODES } from '../../components/Common/GlobalConstants';

const optimalInventoryByStrategy = (plants, companyCodes, mode, SQLOnly) => {
  const isPlant = mode === MODES.PLANT;
  const url = `${Base_URL}widget/${Get_Optimal_Inventory_By_Strategy_URL}`;
  function setOptimalInventoryByStrategy(data) {
    return {
      type: actionTypes.optimalInventoryByStrategy,
      payload: {
        plants: isPlant ? plants : [],
        companyCodes: !isPlant ? companyCodes : [],
        data: JSON.parse(data[0]?.cacheValue ?? '{}'),
      },
    };
  }
  return apiAction({
    url,
    method: 'GET',
    params: {
      plant: plants,
      companyCode: companyCodes,
      mode,
      SQLOnly,
    },
    onSuccess: setOptimalInventoryByStrategy,
    onFailure: () => console.log('Error occured fetching optimal inventory by strategy data'),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Optimal Inventory by Strategy',
      Message: `Failed to load optimal inventory by strategy data for ${isPlant ? 'plant' : 'company code'}(s): ${(isPlant ? plants : companyCodes)?.join()}`,
    }),
    label: actionTypes.optimalInventoryByStrategy,
  });
};

const optimalInventoryByGroup = (plants, companyCodes, mode, SQLOnly) => {
  const url = `${Base_URL}widget/${Get_Optimal_Inventory_By_Group_URL}`;
  const isPlant = mode === MODES.PLANT;
  function setOptimalInventoryByGroup(data) {
    return {
      type: actionTypes.optimalInventoryByGroup,
      payload: {
        plants: isPlant ? plants : [],
        companyCodes: !isPlant ? companyCodes : [],
        data: JSON.parse(data[0]?.cacheValue ?? '{}'),
      },
    };
  }
  return apiAction({
    url,
    method: 'GET',
    params: {
      plant: plants,
      companyCode: companyCodes,
      mode,
      SQLOnly,
    },
    onSuccess: setOptimalInventoryByGroup,
    onFailure: () => console.log('Error occured fetching optimal inventory by group data'),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Optimal Inventory by Group',
      Message: `Failed to load optimal inventory by group data for ${isPlant ? 'plant' : 'company code'}(s): ${(isPlant ? plants : companyCodes)?.join()}`,
    }),
    label: actionTypes.optimalInventoryByGroup,
  });
};

const optimalInvByTime = (plants, companyCodes, mode, filterBy, isGrouped, SQLOnly) => {
  const url = `${Base_URL}widget/${Get_Optimal_Inventory_By_Time_URL}`;
  const isPlant = mode === MODES.PLANT;
  function setOptimalInvByTime(data) {
    return {
      type: actionTypes.optimalInvByTime,
      payload: {
        plants: isPlant ? [...plants, filterBy] : [],
        companyCodes: !isPlant ? [...companyCodes, filterBy] : [],
        data: JSON.parse(data[0]?.cacheValue ?? '[]'),
        isGrouped: isGrouped ? 'GROUPED' : '',
      },
    };
  }
  return apiAction({
    url,
    method: 'GET',
    params: {
      plant: plants,
      companyCode: companyCodes,
      mode,
      filterBy,
      isGrouped,
      SQLOnly,
    },
    onSuccess: setOptimalInvByTime,
    onFailure: () => console.log('Error occured fetching optimal inventory by time data'),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Optimal Inventory by Time',
      Message: `Failed to load optimal inventory by time data for ${isPlant ? 'plant' : 'company code'}(s): ${(isPlant ? plants : companyCodes)?.join()}`,
    }),
    label: actionTypes.optimalInvByTime,
  });
};

const inventoryValByTime = (plants, companyCodes, mode, filterBy, isGrouped, SQLOnly) => {
  const url = `${Base_URL}widget/${Get_Inventory_Value_By_Time_URL}`;
  const isPlant = mode === MODES.PLANT;
  function setInventoryValByTime(data) {
    return {
      type: actionTypes.inventoryValByTime,
      payload: {
        plants: isPlant ? [...plants, filterBy] : [],
        companyCodes: !isPlant ? [...companyCodes, filterBy] : [],
        data: JSON.parse(data[0]?.cacheValue ?? '[]'),
        isGrouped: isGrouped ? 'GROUPED' : '',
      },
    };
  }
  return apiAction({
    url,
    method: 'GET',
    params: {
      plant: plants,
      companyCode: companyCodes,
      mode,
      filterBy,
      isGrouped,
      SQLOnly,
    },
    onSuccess: setInventoryValByTime,
    onFailure: () => console.log('Error occured fetching inventory value by time data'),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Iventory Value by Time',
      Message: `Failed to load inventory value by time data for ${isPlant ? 'plant' : 'company code'}(s): ${(isPlant ? plants : companyCodes)?.join()}`,
    }),
    label: actionTypes.inventoryValByTime,
  });
};

const getExcessStockByStatus = (plants, companyCodes, mode, SQLOnly) => {
  const url = `${Base_URL}widget/${Get_Excess_Stock_By_Status}`;
  const isPlant = mode === MODES.PLANT;
  function setExcessStockByStatus(data) {
    return {
      type: actionTypes.excessStockByStatus,
      payload: {
        plants: isPlant ? plants : [],
        companyCodes: !isPlant ? companyCodes : [],
        data: JSON.parse(data[0]?.cacheValue ?? '{}'),
      },
    };
  }
  return apiAction({
    url,
    method: 'GET',
    params: {
      plant: plants,
      companyCode: companyCodes,
      mode,
      SQLOnly,
    },
    onSuccess: setExcessStockByStatus,
    onFailure: () => console.log('Error occured fetching Excess stock by status data'),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Excess stock by status ',
      Message: `Failed to load Excess stock by status  data for ${isPlant ? 'plant' : 'company code'}(s): ${(isPlant ? plants : companyCodes)?.join()}`,
    }),
    label: actionTypes.excessStockByStatus,
  });
};

const cleanUpWidget = (plants, companyCodes, mode, SQLOnly) => {
  const url = `${Base_URL}widget/${Get_Clean_Up_Widget_URL}`;
  const isPlant = mode === MODES.PLANT;
  function setCleanUpWidget(data) {
    return {
      type: actionTypes.cleanUpWidget,
      payload: {
        plants: isPlant ? plants : [],
        companyCodes: !isPlant ? companyCodes : [],
        data: JSON.parse(data[0]?.cacheValue ?? '{}'),
      },
    };
  }
  return apiAction({
    url,
    method: 'GET',
    params: {
      plant: plants,
      companyCode: companyCodes,
      mode,
      SQLOnly,
    },
    onSuccess: setCleanUpWidget,
    onFailure: () => console.log('Error occured fetching Clean Up widget data'),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Clean Up widget',
      Message: `Failed to load Clean Up widget data for ${isPlant ? 'plant' : 'company code'}(s): ${(isPlant ? plants : companyCodes)?.join()}`,
    }),
    label: actionTypes.cleanUpWidget,
  });
};

const inventoryValue = (plants, companyCodes, mode, materials, SQLOnly) => {
  const url = `${Base_URL}widget/${Get_Inventory_Value_URL}`;
  const isPlant = mode === MODES.PLANT;
  function setInventoryValue(data) {
    return {
      type: actionTypes.inventoryValue,
      payload: {
        plants: isPlant ? plants : [],
        companyCodes: !isPlant ? companyCodes : [],
        materials: materials ?? [],
        data: JSON.parse(data[0]?.cacheValue ?? '{}'),
      },
    };
  }
  return apiAction({
    url,
    method: 'GET',
    params: {
      plant: plants,
      companyCode: companyCodes,
      mode,
      materials,
      SQLOnly,
    },
    onSuccess: setInventoryValue,
    onFailure: () => console.log('Error occured fetching inventory value'),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Inventory Value',
      Message: `Failed to load inventory value for ${isPlant ? 'plant' : 'company code'}(s): ${(isPlant ? plants : companyCodes)?.join()}`,
    }),
    label: actionTypes.inventoryValue,
  });
};

const getTotalExcessStock = (plants, companyCodes, mode, SQLOnly) => {
  const url = `${Base_URL}widget/${Get_Total_Excess_Stock_Value}`;
  const isPlant = mode === MODES.PLANT;
  function setTotoalExcessStockVals(data) {
    return {
      type: actionTypes.totalExcessStock,
      payload: {
        plants: isPlant ? plants : [],
        companyCodes: !isPlant ? companyCodes : [],
        data: JSON.parse(data[0]?.cacheValue ?? '{}'),
      },
    };
  }
  return apiAction({
    url,
    method: 'GET',
    params: {
      plant: plants,
      companyCode: companyCodes,
      mode,
      SQLOnly,
    },
    onSuccess: setTotoalExcessStockVals,
    onFailure: () => console.log('Error occured while fetching Total Excess stock data'),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Excess stock by status ',
      Message: `Failed to load Total Excess stock data for ${isPlant ? 'plant' : 'company code'}(s): ${(isPlant ? plants : companyCodes)?.join()}`,
    }),
    label: actionTypes.totalExcessStock,
  });
};

const getMonetaryImpactVals = (plants, companyCodes, mode, materials, SQLOnly) => {
  const url = `${Base_URL}widget/${Get_Monetary_Impact}`;
  const isPlant = mode === MODES.PLANT;
  function setMonetaryImpactValues(data) {
    return {
      type: actionTypes.monetaryImpact,
      payload: {
        plants: isPlant ? plants : [],
        companyCodes: !isPlant ? companyCodes : [],
        materials: materials ?? [],
        data: JSON.parse(data[0]?.cacheValue ?? '[]'),
      },
    };
  }
  return apiAction({
    url,
    method: 'GET',
    params: {
      plant: plants,
      companyCode: companyCodes,
      mode,
      materials,
      SQLOnly,
    },
    onSuccess: setMonetaryImpactValues,
    onFailure: () => console.log('Error occured fetching Monetary impact values'),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Inventory Value',
      Message: `Failed to load Monetary impact values for ${isPlant ? 'plant' : 'company code'}(s): ${(isPlant ? plants : companyCodes)?.join()}`,
    }),
    label: actionTypes.monetaryImpact,
  });
};

const getOptInvTot = (plants, companyCodes, mode, SQLOnly) => {
  const url = `${Base_URL}widget/${GetOptInvTot}`;
  const isPlant = mode === MODES.PLANT;
  function storeOptInvTotData(data) {
    return {
      type: actionTypes.storeOptInvTot,
      payload: {
        plants: isPlant ? plants : [],
        companyCodes: !isPlant ? companyCodes : [],
        data: JSON.parse(data[0]?.cacheValue ?? '{}'),
      },
    };
  }

  return apiAction({
    url,
    method: 'GET',
    params: {
      plant: plants,
      companyCode: companyCodes,
      mode,
      SQLOnly,
    },
    onSuccess: storeOptInvTotData,
    onFailure: () => console.log('Error occured fetching Optimal inventory statistics'),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Optimal Inventory Statistics',
      Message: `Failed to load analysis of optimal inventory for ${isPlant ? 'plant' : 'company code'}(s): ${(isPlant ? plants : companyCodes)?.join()}`,
    }),
    label: actionTypes.storeOptInvTot,
  });
};

const optimalInventoryBySP = (plants, companyCodes, mode, SQLOnly) => {
  const url = `${Base_URL}widget/${Get_Optimal_Inventory_By_SP_URL}`;
  const isPlant = mode === MODES.PLANT;
  function setOptimalInventoryBySP(data) {
    return {
      type: actionTypes.optimalInventoryBySP,
      payload: {
        plants: isPlant ? plants : [],
        companyCodes: !isPlant ? companyCodes : [],
        data: JSON.parse(data[0]?.cacheValue ?? '{}'),
      },
    };
  }
  return apiAction({
    url,
    method: 'GET',
    params: {
      plant: plants,
      companyCode: companyCodes,
      mode,
      SQLOnly,
    },
    onSuccess: setOptimalInventoryBySP,
    onFailure: () => console.log('Error occured fetching optimal inventory by supply process data'),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Optimal Inventory by Supply Process',
      Message: `Failed to load optimal inventory by supply process data for ${isPlant ? 'plant' : 'company code'}(s): ${(isPlant ? plants : companyCodes)?.join()}`,
    }),
    label: actionTypes.optimalInventoryBySP,
  });
};

const optimalInventoryByCrit = (plants, companyCodes, mode, SQLOnly) => {
  const url = `${Base_URL}widget/${Get_Optimal_Inventory_By_Crit_URL}`;
  const isPlant = mode === MODES.PLANT;
  function setOptimalInventoryByCrit(data) {
    return {
      type: actionTypes.optimalInventoryByCrit,
      payload: {
        plants: isPlant ? plants : [],
        companyCodes: !isPlant ? companyCodes : [],
        data: JSON.parse(data[0]?.cacheValue ?? '{}'),
      },
    };
  }
  return apiAction({
    url,
    method: 'GET',
    params: {
      plant: plants,
      companyCode: companyCodes,
      mode,
      SQLOnly,
    },
    onSuccess: setOptimalInventoryByCrit,
    onFailure: () => console.log('Error occured fetching optimal inventory by criticality data'),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Optimal Inventory by Criticality',
      Message: `Failed to load optimal inventory by criticality data for ${isPlant ? 'plant' : 'company code'}(s): ${(isPlant ? plants : companyCodes)?.join()}`,
    }),
    label: actionTypes.optimalInventoryByCrit,
  });
};

const plannedOrders = (plants, companyCodes, mode, SQLOnly) => {
  const url = `${Base_URL}widget/${Get_Planned_Orders_URL}`;
  const isPlant = mode === MODES.PLANT;
  function setPlannedOrders(data) {
    return {
      type: actionTypes.plannedOrders,
      payload: {
        plants: isPlant ? plants : [],
        companyCodes: !isPlant ? companyCodes : [],
        data: JSON.parse(data[0]?.cacheValue ?? '{}'),
      },
    };
  }
  return apiAction({
    url,
    method: 'GET',
    params: {
      plant: plants,
      companyCode: companyCodes,
      mode,
      SQLOnly,
    },
    onSuccess: setPlannedOrders,
    onFailure: () => console.log('Error occured fetching planned orders'),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Planned orders',
      Message: `Failed to load planned orders for ${isPlant ? 'plant' : 'company code'}(s): ${(isPlant ? plants : companyCodes)?.join()}`,
    }),
    label: actionTypes.plannedOrders,
  });
};

const optimalInventoryByROP = (plants, companyCodes, mode, SQLOnly) => {
  const url = `${Base_URL}widget/${Get_Optimal_Inventory_By_ROP_URL}`;
  const isPlant = mode === MODES.PLANT;
  function setOptimalInventoryByROP(data) {
    return {
      type: actionTypes.optimalInventoryByROP,
      payload: {
        plants: isPlant ? plants : [],
        companyCodes: !isPlant ? companyCodes : [],
        data: JSON.parse(data[0]?.cacheValue ?? '{}'),
      },
    };
  }
  return apiAction({
    url,
    method: 'GET',
    params: {
      plant: plants,
      companyCode: companyCodes,
      mode,
      SQLOnly,
    },
    onSuccess: setOptimalInventoryByROP,
    onFailure: () => console.log('Error occured optimal inventory by ROP'),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Optimal inventory by ROP',
      Message: `Failed to load Optimal inventory by ROP for ${isPlant ? 'plant' : 'company code'}(s): ${(isPlant ? plants : companyCodes)?.join()}`,
    }),
    label: actionTypes.optimalInventoryByROP,
  });
};

const setLoading = (loadingKey) => ({
  type: actionTypes.setLoading,
  payload: loadingKey,
});

const setLineChartLoading = (loadingKey) => ({
  type: actionTypes.setLineChartLoading,
  payload: loadingKey,
});

export default {
  optimalInventoryByStrategy,
  optimalInventoryByGroup,
  getExcessStockByStatus,
  inventoryValue,
  getMonetaryImpactVals,
  getTotalExcessStock,
  getOptInvTot,
  optimalInventoryBySP,
  optimalInventoryByCrit,
  optimalInvByTime,
  inventoryValByTime,
  plannedOrders,
  optimalInventoryByROP,
  setLoading,
  cleanUpWidget,
  setLineChartLoading,
};
