/* eslint-disable no-param-reassign */
import {
  Card, Col, Empty, Row, Table,
  Tooltip,
} from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { generateTabHeaders } from '../helpers';
import { hiddenColumns } from '../../TabOptions';
import allActions from '../../../../../actions';
import Loading from '../../../../Common/Loading';
import './TableTab.css';

function TableTab({
  action,
  loadingKey,
  tabOptions,
  updateFilterData,
  containerID,
  hideSensitiveValues,
  data,
  tableTitle,
}) {
  const { plant, material } = useParams();
  const dispatch = useDispatch();
  const [headerData, setHeaderData] = useState();
  const loading = useSelector((state) => state.materialDetailsState.loading);
  const columnMapper = useSelector((state) => state.commonState.columnMapper);
  const [scrollX, setScrollX] = useState({});

  useEffect(() => {
    if (plant && material) {
      dispatch(allActions.MaterialDetailsActions.setLoading(loadingKey));
      dispatch(action(plant, material));
    }
  }, [plant, material]);

  useEffect(() => {
    setScrollX(containerID === 'tabRealConsTable' ? { x: true } : false);
  }, [containerID]);

  useEffect(() => {
    let allMovements = null;
    if (containerID === 'tabContainer1') {
      allMovements = true;
    }
    if (data?.length >= 1 && columnMapper?.length >= 1) {
      const keys = Object.keys(data[0]);
      setHeaderData(generateTabHeaders(keys, columnMapper, tabOptions, data, allMovements));
      if (updateFilterData) {
        updateFilterData(data);
      }
    } else {
      if (updateFilterData) {
        updateFilterData();
      }
      setHeaderData();
    }
  }, [data, columnMapper, containerID]);

  useEffect(() => {
    document.querySelectorAll('.ant-table-cell .lightred, .ant-table-cell .lightgreen')
      .forEach((ele) => {
        if (ele.className === 'lightred') {
          ele.parentElement.style.backgroundColor = 'rgb(255, 204, 203)';
        } else ele.parentElement.style.backgroundColor = 'lightgreen';
      });
  }, [headerData]);

  const removeSensitiveValues = (source, sensitiveValueArray) => {
    const sensitiveValueObject = {};
    sensitiveValueArray.forEach((value) => {
      sensitiveValueObject[value] = '';
    });
    return source.map((x) => ({ ...x, ...sensitiveValueObject }));
  };

  const getRowClassName = (record, index) => {
    const className = (tabOptions?.rowClassName && tabOptions.rowClassName(record)) || null;
    if (className) return className;
    return (index % 2 === 0 ? 'table-row-light' : 'table-row-dark');
  };

  const columns = useMemo(
    () => {
      if (!headerData || headerData.length === 0) return [];
      return headerData?.filter((h) => (!hiddenColumns?.includes(h.dataIndex)));
    },
    [headerData],
  );

  const getCorrectDate = (date) => {
    const dateAndTime = date.split('T');
    const dayMonthYear = dateAndTime[0].split('-');
    const hourMinute = dateAndTime[1].split(':');
    const string = `${dayMonthYear[2]}.${dayMonthYear[1]}.${dayMonthYear[0]}, ${hourMinute[0]}:${hourMinute[1]} CEST`;
    return string;
  };

  const dataSource = useMemo(
    () => {
      if (!data || !headerData || data.length === 0 || headerData.length === 0) return [];
      if (hideSensitiveValues && tabOptions?.sensitiveValues) {
        return removeSensitiveValues(data, tabOptions?.sensitiveValues);
      }
      if (loadingKey === 'CHANGELOGS') {
        const newData = data.filter((i) => i.CHANGE_TYPE !== 'No MRP changes');
        const correctDate = newData.map((changeObject) => ({ ...changeObject, CHANGED_DATE: getCorrectDate(changeObject.CHANGED_DATE) }));
        return correctDate;
      }
      return data;
    }, [data, headerData],
  );

  return (
    loading && loading[loadingKey]
      ? <Loading />
      : (
        <>
          {tableTitle && <h3>{tableTitle}</h3>}
          <Row gutter={48}>
            <Col span={24}>
              {
              (!data || !headerData)
                ? <Empty description="No data" />
                : (
                  <Card
                    className="tabCard"
                    style={{
                      textAlign: 'center', paddingLeft: '0', paddingRight: '0',
                    }}
                  >
                    <Table
                      className="tabTable"
                      rowClassName={getRowClassName}
                      columns={columns}
                      dataSource={dataSource}
                      pagination={false}
                      size="small"
                      onChange={(_p, _f, _s, extra) => updateFilterData(extra.currentDataSource)}
                      scroll={scrollX}
                    />
                  </Card>
                )
            }
            </Col>
          </Row>
        </>
      )
  );
}

export default TableTab;

TableTab.defaultProps = {
  tabOptions: {},
  updateFilterData: () => { },
  containerID: '',
  hideSensitiveValues: true,
};
TableTab.propTypes = {
  action: PropTypes.func.isRequired,
  loadingKey: PropTypes.string.isRequired,
  tabOptions: PropTypes.objectOf(PropTypes.any),
  updateFilterData: PropTypes.func,
  containerID: PropTypes.string,
  hideSensitiveValues: PropTypes.bool,
};
