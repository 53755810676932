/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveLine } from '@nivo/line';
import {
  Button, Empty, Row, Select, Tooltip,
} from 'antd';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { formatLargeNumber } from '../../MaterialDetails/components/TabComponents/TabGraphHelpers';
import { getOptInvByTimeData } from './WidgetFunctions';
import { getConvertedPrice, localShortDateFormat } from '../../../Common/DateAndNumberFunctions';
import { widgetTypeKeys } from './widgetTypes';
import TextWidgetContainer from './TextWidgetContainer';

export default function LineChartWidget({
  inventoryStatus, showLegendSetting, canViewValues,
  widgetID, timePeriod, setTimePeriod, textWidgetID,
}) {
  const defaultCurrency = useSelector((state) => state.commonState?.userSettings?.DEFAULT_CURRENCY);
  const dashboardFilter = useSelector((state) => state.commonState.localDashboardFilters);
  const currencyList = useSelector((state) => state.commonState?.currencyList);
  const [data, setData] = useState([]);
  const [maxValue, setMaxValue] = useState();
  const [minValue, setMinValue] = useState();
  const [tickValues, setTickValues] = useState([]);
  const [legend, setLegend] = useState(showLegendSetting ?? false);
  const [yAxisText, setYAxisText] = useState('');

  const { Option } = Select;

  const setMinMaxVals = (formattedData) => {
    let maxVal;
    let minVal = Infinity;
    formattedData?.forEach((line) => {
      line?.data?.forEach((point) => {
        if (!maxVal && maxVal !== 0) maxVal = point?.y ?? 0;
        if (point?.y > maxVal) {
          maxVal = point.y;
        }
        if ((point?.y || point?.y === 0) && minVal > point.y) minVal = point.y;
      });
    });
    if (minVal !== Infinity) {
      maxVal += ((maxVal - minVal) * 0.2);
      minVal -= ((maxVal - minVal) * 0.2);
    } else {
      maxVal += maxVal * 0.1;
      minVal = 0;
    }
    let finalMax = Math.ceil(maxVal);
    let finalMin = Math.floor(minVal, 10);
    finalMin -= ((finalMax - finalMin) * 0.3);
    if (finalMax === finalMin) return;
    setMaxValue(finalMax);
    setMinValue(finalMin);

    // set tickValues for graph
    const tickVals = [];
    const tickSize = (finalMax - finalMin) / 6;
    if (widgetID === widgetTypeKeys.optimalInvByTime) {
      if (finalMax === finalMin) finalMin = 0;
      if (finalMax > 100) finalMax = 100;
      if (finalMin < 0) finalMin = 0;
    }
    tickVals.push(finalMin);
    for (let i = finalMin + tickSize; i < finalMax; i += tickSize) {
      tickVals.push(i);
    }
    tickVals[6] = finalMax;
    setTickValues(tickVals);
  };

  const getLeftAxisText = (numText) => {
    if (widgetID !== widgetTypeKeys.inventoryValByTime) return 'Percentage';
    if (numText === '' || !numText) return 'Inventory Value';
    return `Inv. value in ${numText}`;
  };

  const getMinVal = (formattedData) => {
    let minVal;
    formattedData?.forEach((line) => {
      line?.data?.forEach((point) => {
        if (!minVal && minVal !== 0) minVal = point?.y ?? 0;
        if (point?.y < minVal) {
          minVal = point.y;
        }
      });
    });
    return minVal;
  };

  const getConvertedCurrency = (currVal) => {
    let convertedVal = currVal;
    let curr = 'NOK';
    if (defaultCurrency && defaultCurrency !== 'NOK') {
      const temp = currencyList?.find((x) => defaultCurrency === x?.FROM_CURRENCY);
      convertedVal = getConvertedPrice(
        currVal, temp?.FROM_RATIO, temp?.TO_RATIO, temp?.EXCHANGE_RATE,
      );
      curr = defaultCurrency;
    }
    return ({
      convertedVal,
      curr,
    });
  };

  const getInventoryValue = (val = 0) => {
    let curr = 'NOK';
    if (defaultCurrency && defaultCurrency !== 'NOK') {
      curr = defaultCurrency;
    }
    return `${formatLargeNumber(val, null, 2)} ${curr}`;
  };

  const convertInvValToSelectedCurrency = (inv) => {
    const tempInv = _.cloneDeep(inv);
    inv?.forEach((line, i) => line?.data?.forEach((node, j) => {
      const { convertedVal } = getConvertedCurrency(Number.parseInt(node?.y ?? 0, 10));
      tempInv[i].data[j].y = convertedVal;
    }));
    return tempInv;
  };

  const formatInventorydata = (inv) => {
    const formattedData = convertInvValToSelectedCurrency(inv);
    let minVal = getMinVal(formattedData);
    minVal = formatLargeNumber(minVal);
    let divisor = 1;
    if (minVal?.includes('G')) {
      setYAxisText('Billions');
      divisor = 1000000000;
    } else if (minVal?.includes('M')) {
      setYAxisText('Millions');
      divisor = 1000000;
    } else if (minVal?.includes('K')) {
      setYAxisText('Thousands');
      divisor = 1000;
    }
    const tempInv = _.cloneDeep(inv);
    formattedData?.forEach((line, i) => line?.data?.forEach((node, j) => {
      tempInv[i].data[j].yOriginal = node?.y;
      tempInv[i].data[j].y = (node?.y ?? 0) / divisor;
    }));
    return tempInv;
  };

  const dataNotNull = (invData) => {
    let flag = false;
    invData?.forEach((grp) => {
      if (grp && typeof grp === 'object') {
        const grpName = Object.keys(grp)?.[0];
        if (grpName && grp[grpName]?.data?.length > 0) {
          flag = true;
        }
      }
    });
    return flag;
  };

  useEffect(() => {
    if (!inventoryStatus?.data?.length) return;
    if (!dataNotNull(inventoryStatus?.data)) {
      setData(null);
      return;
    }
    let formattedData = getOptInvByTimeData(
      inventoryStatus.data,
      dashboardFilter ?? null,
      widgetID,
      inventoryStatus.mode,
    );
    if (!formattedData.length) {
      setMaxValue(1);
      formattedData = [];
    } else if (widgetID === widgetTypeKeys.inventoryValByTime) {
      formattedData = formatInventorydata(formattedData);
    }
    setMinMaxVals(formattedData);
    setData(formattedData);
  }, [inventoryStatus, dashboardFilter]);

  useEffect(() => {
    setLegend(!!showLegendSetting);
  }, [showLegendSetting]);

  const toggleLegend = () => {
    setLegend((prev) => !prev);
  };

  const getYLabel = (val) => val?.y?.toFixed(2);

  return (
    data?.length > 0 ? (
      <>
        <Row justify="space-between" style={{ marginBottom: '4px' }}>
          {
            (widgetID !== widgetTypeKeys.optimalInvByTime && widgetID !== widgetTypeKeys.inventoryValByTime)
              ? (
                <Button onClick={toggleLegend}>
                  {legend ? 'Hide legend' : 'Show legend'}
                </Button>
              )
              : <Button style={{ visibility: 'hidden' }}>Date selector</Button>
          }
          {
            textWidgetID
              ? (
                <TextWidgetContainer
                  widgetID={textWidgetID}
                />
              )
              : null
          }
          <Select
            style={{ width: '100px', zIndex: 9 }}
            onChange={(e) => setTimePeriod(e)}
            value={timePeriod}
            className="topRightButton"
          >
            <Option key="WEEK" value="WEEK">Weekly</Option>
            <Option key="MONTH" value="MONTH">Monthly</Option>
            <Option key="YEAR" value="YEAR">Yearly</Option>
          </Select>
        </Row>
        <ResponsiveLine
          data={data}
          enableCrosshair={false}
          enableGridX={false}
          margin={{
            top: 10, right: 30, bottom: legend ? 100 : 70, left: 70,
          }}
          xScale={{ type: 'point' }}
          yScale={{
            type: 'linear',
            min: minValue || minValue === 0 ? minValue : 'auto',
            max: maxValue || 'auto',
            stacked: false,
            reverse: false,
          }}
          tooltip={({ point }) => (
            <Tooltip
              placement="topLeft"
              style={{
                padding: 12,
                zIndex: 400,
              }}
            >
              <div style={{
                padding: 12,
                background: '#f0f2f5',
                boxShadow: '3px 3px 3px #a1a1a1',
              }}
              >
                <strong>
                  {
                    inventoryStatus?.mode === 'companyCode'
                      ? 'Company Code:'
                      : 'Plant:'
                  }
                </strong>
                {' '}
                {point.serieId}
                <br />
                <strong>Date:</strong>
                {' '}
                {point.data?.xFormatted}
                <br />
                {
                  widgetID === widgetTypeKeys.optimalInvByTime
                    ? (
                      <>
                        <strong>Optimal inventory: </strong>
                        {' '}
                        {`${point.data?.y?.toFixed(2)} %`}
                      </>
                    )
                    : canViewValues
                    && (
                      <>
                        <strong>Value of inventory:</strong>
                        {' '}
                        {getInventoryValue(point.data?.yOriginal ?? 0)}
                        <br />
                        <strong>Count of materials:</strong>
                        {' '}
                        {point?.data?.count ? point?.data?.count : '-'}
                      </>
                    )
                }
              </div>
            </Tooltip>
          )}
          yFormat=" >-.2f"
          axisTop={null}
          axisRight={null}
          axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 14,
            tickRotation: -30,
            legend: getLeftAxisText(yAxisText),
            legendOffset: -55,
            legendPosition: 'middle',
            tickValues: tickValues?.length && (minValue || minValue === 0) && maxValue ? tickValues : 5,
            format: (val) => {
              const formatted = Number.parseFloat(val).toFixed(1);
              return formatted > 0 ? formatted : '';
            },
          }}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: -20,
            format: (v) => localShortDateFormat(v),
          }}
          pointSize={10}
          colors={[
            '#007079',
            '#66c2a5',
            '#fc8d62',
            '#8da0cb',
            '#e78ac3',
            '#a6d854',
            '#ffd92f',
            '#e5c494',
            '#b3b3b3',
          ]}
          enablePointLabel
          pointLabel={getYLabel}
          pointLabelYOffset={14}
          pointBorderWidth={3}
          useMesh
          legends={[
            {
              anchor: 'bottom',
              direction: 'row',
              justify: false,
              translateX: 0,
              translateY: 45,
              itemsSpacing: 0,
              itemDirection: 'left-to-right',
              itemWidth: 85,
              itemHeight: 15,
              itemOpacity: 0.75,
              symbolSize: 8,
              symbolShape: 'square',
              symbolBorderColor: 'rgba(0, 0, 0, .5)',
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemBackground: 'rgba(0, 0, 0, 0.3)',
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
        />
      </>
    )
      : (
        <div style={{ textAlign: 'center' }}>
          {textWidgetID && (
          <TextWidgetContainer
            widgetID={textWidgetID}
          />
          )}
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No historical data" />
        </div>
      )
  );
}

LineChartWidget.defaultProps = {
  showLegendSetting: false,
  timePeriod: 'MONTH',
  textWidgetID: null,
};

LineChartWidget.propTypes = {
  inventoryStatus: PropTypes.objectOf(PropTypes.any).isRequired,
  showLegendSetting: PropTypes.bool,
  canViewValues: PropTypes.bool.isRequired,
  widgetID: PropTypes.string.isRequired,
  timePeriod: PropTypes.string,
  setTimePeriod: PropTypes.func.isRequired,
  textWidgetID: PropTypes.string,
};
