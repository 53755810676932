import React, { useEffect, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { PropTypes } from 'prop-types';
import { modes } from './MaterialListFunctions';

const MaterialTableFooter = ({
  mode, noOfRecords, data, staticWLCount, listCount,
}) => {
  const [displayNr, setDisplayNr] = useState(0);
  const [totalNr, setTotalNr] = useState();

  useEffect(() => {
    if (mode === modes.STATIC) {
      setTotalNr(noOfRecords ?? null);
    } else if (data?.length < 50) {
      setTotalNr(data?.length);
    } else {
      setTotalNr(listCount?.[0]?.NoofRecords ?? null);
    }
  }, [noOfRecords, listCount, data]);

  useEffect(() => {
    if (mode === modes.STATIC) {
      setDisplayNr(staticWLCount ?? 0);
    } else {
      setDisplayNr(data?.length);
    }
  }, [staticWLCount, data]);

  return (
    <div className="tableRecordCount">
      <span>
        {`Showing ${displayNr ?? 0} of `}
        {totalNr ?? <LoadingOutlined spin />}
        {' items'}
      </span>
    </div>

  );
};
export default MaterialTableFooter;

MaterialTableFooter.defaultProps = {
  mode: '',
  noOfRecords: null,
  data: [],
  staticWLCount: 0,
  listCount: [],
};

MaterialTableFooter.propTypes = {
  mode: PropTypes.oneOf(Object.values(modes)),
  noOfRecords: PropTypes.number,
  data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  staticWLCount: PropTypes.number,
  listCount: PropTypes.arrayOf(PropTypes.any),
};
