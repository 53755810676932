import React, { useEffect, useState } from 'react';
import { Button, Table, Tooltip } from 'antd';
import { useHistory, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Loading from '../../../../Common/Loading';
import { statusIndicator, getDemandTime, getSAPLink } from '../helpers';
import './PlantComponents.css';
import { getPlantStorageCheck } from '../InfoComponents/StorageCheckIdInfo';
import {
  convertCost, displayRelevantDecimals, formatMaterialNumber, formatNumbersLocale, getCurrency,
} from '../../../../Common/DateAndNumberFunctions';

function PlantCard({ plantID, loading, hasViewerAccess }) {
  const { material } = useParams();
  const plants = useSelector((state) => state.commonState.plants);
  const data = useSelector((state) => state.materialDetailsState.material);
  const mrpSettings = useSelector((state) => state.materialDetailsState.mrpSettings);
  const defaultCurrency = useSelector((state) => state.commonState?.userSettings?.DEFAULT_CURRENCY);
  const currencyList = useSelector((state) => state.commonState?.currencyList);
  const plantData = data?.find(
    (_plant) => _plant.PLANT_FACILITY_SAP_ID === plantID
      && _plant.MATERIAL_TYPE_SAP_ID === material,
  );
  const history = useHistory();
  const [mrpData, setMrpData] = useState();
  const [display, setDisplay] = useState(false);

  const plantName = plants?.find(
    (x) => x.PLANT_FACILITY_SAP_ID === plantID,
  )?.PLANT_FACILITY_SAP_NM ?? plantID;
  const handleClick = () => {
    const state = history?.location?.state;
    history.push(`/material-details/${plantID}/${material}`, { mode: state?.mode, worklistID: state?.worklistID });
  };

  const getDisplayValue = (value, suffix = '') => (value && value !== '' ? value + suffix : ' ');
  const displayNumber = (value) => (!Number.isNaN(Number.parseFloat(value)) ? Number.parseFloat(value) : ' ');

  useEffect(() => { setDisplay(!!plantData?.DISPLAY_REC_IND); }, [plantData]);

  useEffect(() => {
    const mrp = mrpSettings.find(
      (e) => e.Plant === plantID && formatMaterialNumber(e.MaterialNumber) === material,
    );

    setMrpData(
      [
        {
          key: 'MRPType',
          name: 'MRP Type',
          current: mrp?.MRPType || ' ',
          recommended: display && (plantData?.CC_MRP_TYPE || ' '),
        },
        {
          key: 'LotSize',
          name: 'Lot Size',
          current: mrp?.LotSize || ' ',
          recommended: display && (plantData?.CC_LOT_SIZE || ' '),
        },
        {
          key: 'ReorderPoint',
          name: 'Reorder Point',
          current: displayNumber(mrp?.ReorderPoint),
          recommended: display && (displayNumber(plantData?.CC_ROP) || ' '),
        },
        {
          key: 'MinimumLotSize',
          name: 'Minimum Lot Size',
          current: displayNumber(mrp?.MinimumLotSize),
          recommended: display && (displayNumber(plantData?.CC_MIN_LOT_SIZE) || ' '),
        },
        {
          key: 'MaximumStockLevel',
          name: 'Maximum Stock Level',
          current: displayNumber(mrp?.MaximumStockLevel),
          recommended: display && (displayNumber(plantData?.CC_MAX_STOCK) || ' '),
        },
        {
          key: 'MaximumLotSize',
          name: 'Maximum Lot Size',
          current: displayNumber(mrp?.MaximumLotSize),
          recommended: display && (displayNumber(plantData?.TO_BE_CONFIRMED) || ' '),
        },
        {
          key: 'RoundingValue',
          name: 'Rounding Value',
          current: displayNumber(mrp?.RoundingValue),
          recommended: display && (displayNumber(plantData?.EDAM_ROUNDING_VALUE_FOR_PO_QTY)),
        },
        {
          key: 'FixedLotSize',
          name: 'Fixed Lot Size',
          current: displayNumber(mrp?.FixedLotSize),
          recommended: display && ' ',
        },
        {
          key: 'MRPGroup',
          name: 'MRP Group',
          current: mrp?.MRPGroup || ' ',
          recommended: display && (plantData?.CC_MRP_GROUP || ' '),
        },
        {
          key: 'MRPController',
          name: 'MRP Controller',
          current: mrp?.MRPController || ' ',
          recommended: display && (plantData?.TO_BE_CONFIRMED || ' '),
        },
        {
          key: 'PlantMaterialStatus',
          name: 'Plant Material Status',
          current: mrp?.PlantMaterialStatus || ' ',
          recommended: display && (plantData?.TO_BE_CONFIRMED || ' '),
        },
        {
          key: 'ABCIndicator',
          name: 'ABC Indicator',
          current: mrp?.ABCIndicator || ' ',
          recommended: display && (plantData?.CONSUMPTION_VALUE_TYPE_CD || ' '),
        },
      ],
    );
  }, [mrpSettings, display, plantData]);

  const columns = [
    {
      title: (
        <Tooltip title="Switch focused plant">
          <Button style={{ fontSize: '12px', height: '20px' }} size="small" type="primary" onClick={handleClick}>{plantName}</Button>
        </Tooltip>
      ),
      dataIndex: 'value',
      width: 150,
      align: 'center',
    }];

  const dataSource = [
    {
      key: 'Plant number',
      value: plantData?.PLANT_FACILITY_SAP_ID ? getSAPLink(
        'Click to navigate to MD04 in SAP',
        'MD04',
        [plantData?.MATERIAL_TYPE_SAP_ID, plantData?.PLANT_FACILITY_SAP_ID],
        plantData?.PLANT_FACILITY_SAP_ID,
      ) : '-',
    },
    { key: 'Supply process', value: getDisplayValue(plantData?.SUPPLY_PROCESS) },
    { key: 'Policy status', value: plantData?.INVENTORY_STRATEGY_STATUS ?? '-' },
    {
      key: 'Status',
      value: (
        <Tooltip title={getPlantStorageCheck(plantData?.STORAGE_CHECK_ID)}>
          {statusIndicator(plantData?.STATUS_LIGHT)}
          {` ${plantData?.STORAGE_CHECK_ID ?? '-'}`}
        </Tooltip>
      ),
    },
    { key: 'Consumption group', value: getDisplayValue(plantData?.CC_CONSUMPTION_SEGMENT_GROUP) },
    { key: 'Consumption sub-grp', value: getDisplayValue(plantData?.CC_SEGMENT_SUBGROUP_NM) },
    { key: 'Consumption qty in LT', value: getDisplayValue(plantData?.CC_CONS_QTY_IN_LT) },
    { key: 'Consumptions in LT', value: getDisplayValue(plantData?.CC_CONS_COUNT_IN_LT) },
    { key: 'Demand time', value: getDemandTime(getDisplayValue(plantData?.CC_MAX_CRITICALITY)) },
    { key: 'Max crit', value: getDisplayValue(plantData?.CC_MAX_CRITICALITY) },
    { key: 'Tot ABC', value: getDisplayValue(plantData?.TOT_ABC) },
    { key: 'Tot QABC', value: getDisplayValue(plantData?.TOT_QABC) },
    { key: 'Max QABC', value: getDisplayValue(plantData?.CC_MAX_QABC) },
    { key: 'Highest tag status', value: getDisplayValue(plantData?.CC_MAX_TAG_STATUS) },
    { key: 'Valuation class', value: getDisplayValue(plantData?.VALUATION_CLASS) },
    { key: 'Available stock', value: getDisplayValue(plantData?.AVAIL_STOCK) },
    { key: 'Other stock', value: getDisplayValue(plantData?.CC_OTH_STOCK) },
    { key: 'Total value', value: getDisplayValue(convertCost(plantData?.TOT_VAL, defaultCurrency, currencyList), ` ${getCurrency(plantData?.TOT_VAL, defaultCurrency)}`) },
    { key: 'Plant-sp.matl. status', value: getDisplayValue(plantData?.MATERIAL_STATUS_SAP_CD) },
    { key: 'Purchasing group', value: getDisplayValue(plantData?.PURCHASING_GROUP) },
    { key: 'EOQ', value: plantData?.CC_ECONOMIC_ORDER_QTY ? formatNumbersLocale(displayRelevantDecimals(plantData?.CC_ECONOMIC_ORDER_QTY, true)) : '-' },
  ];

  const getMonetaryImpact = () => {
    if (hasViewerAccess && plantData?.DISPLAY_REC_IND && plantData?.MONETARY_IMPACT) {
      const cost = convertCost(
        Math.abs(Number.parseFloat(plantData?.MONETARY_IMPACT)), defaultCurrency, currencyList,
      );
      const currency = getCurrency(plantData?.MONETARY_IMPACT, defaultCurrency);
      return `${cost} ${currency}`;
    }
    return '-';
  };

  return (
    loading
      ? <Loading />
      : (
        <>
          <Table
            className="plantTable"
            style={{
              borderLeft: '1px solid #f0f0f0',
              height: 'calc(59vh - 120px)',
            }}
            pagination={false}
            dataSource={dataSource}
            columns={columns}
          />
          <Table
            bordered
            dataSource={mrpData}
            columns={[
              {
                title: 'Current',
                dataIndex: 'current',
                width: '50%',
                align: 'center',
                height: '22px',
                render: (v) => (v === 0 ? ' ' : v),
              },
              {
                title: 'Recommended',
                dataIndex: 'recommended',
                width: '50%',
                align: 'center',
                render: (v) => (v === 0 ? ' ' : v),
              },
            ]}
            pagination={false}
            style={{ paddingTop: '52px' }}
            className="plantTable"
          />
          <Table
            bordered
            dataSource={[
              {
                key: 'type',
                value: (display && hasViewerAccess && plantData?.MONETARY_IMPACT_TYPE) || '-',
              },
              {
                key: 'value',
                value: display ? getMonetaryImpact() : '-',
              },
            ]}
            columns={[
              {
                title: <div style={{ height: 19 }} />,
                dataIndex: 'value',
                width: '100%',
                align: 'center',
              },
            ]}
            pagination={false}
            className="plantTable"
          />
        </>
      )
  );
}

export default PlantCard;

PlantCard.defaultProps = {
  hasViewerAccess: false,
};
PlantCard.propTypes = {
  plantID: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  hasViewerAccess: PropTypes.bool,
};
