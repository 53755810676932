/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useCallback } from 'react';
import { Drawer } from 'antd';
import './ResizableDrawer.css';
import { MinusOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

let isResizing = null;

const ResizableDrawer = ({
  placement, children, size, visible, onClose, maskStyle,
}) => {
  const [drawerSize, setDrawerSize] = useState(undefined);

  useEffect(() => {
    setDrawerSize(size);
  }, [visible]);

  const cbHandleMouseMove = useCallback(handleMouseMove, []);
  const cbHandleMouseUp = useCallback(handleMouseup, []);

  function handleMouseup() {
    if (!isResizing) {
      return;
    }
    isResizing = false;
    document.removeEventListener('mousemove', cbHandleMouseMove);
    document.removeEventListener('mouseup', cbHandleMouseUp);
  }

  function handleMousedown(e) {
    e.stopPropagation();
    e.preventDefault();
    document.addEventListener('mousemove', cbHandleMouseMove);
    document.addEventListener('mouseup', cbHandleMouseUp);
    isResizing = true;
  }

  function handleMouseMove(e) {
    const cursorHeight = e.clientY;
    const cursorWidth = e.clientX;
    const min = 500;
    const max = 1200;
    if (placement === 'right') {
      const offsetRight = document.body.offsetWidth - (cursorWidth - document.body.offsetLeft);
      if (offsetRight > min && offsetRight < max) {
        setDrawerSize(offsetRight);
      }
    } else if (placement === 'top') {
      if (cursorHeight > min && cursorHeight < max) {
        setDrawerSize(cursorHeight);
      }
    }
  }

  return (
    <Drawer
      placement={placement}
      closable={false}
      visible={visible}
      onClose={onClose}
      maskStyle={maskStyle}
      width={drawerSize}
    >

      <div className="sidebar-dragger" onMouseDown={handleMousedown}><MinusOutlined rotate={90} className="drag-icon" /></div>
      {children}
    </Drawer>
  );
};

export default ResizableDrawer;
ResizableDrawer.propTypes = {
  placement: PropTypes.string.isRequired,
  children: PropTypes.objectOf(PropTypes.any).isRequired,
  size: PropTypes.number.isRequired,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  maskStyle: PropTypes.objectOf(PropTypes.any).isRequired,
};
