export const getPlants = 'GET_PLANTS';
export const getESStatus = 'GET_ES_STATUS';
export const getCompanyCodes = 'GET_COMPANY_CODES';
export const setFuncLocationList = 'SET_FUNCTIONAL_LOCATION_LIST';
export const selectPlant = 'SELECT_PLANT';
export const selectCompanyCode = 'SELECT_COMPANY_CODE';
export const setLocalDashboardFilters = 'SET_LOCAL_DASHBOARD_FILTERS';
export const selectMaterials = 'SELECT_MATERIALS';
export const setDashboardMode = 'SET_DASHBOARD_MODE';
export const setSelectedGrpsNdPlants = 'SET_SELECTED_GROUPS_AND_PLANTS';
export const setUserSetting = 'SET_USER_SETTING';
export const getAllUserSettings = 'GET_ALL_USER_SETTINGS';
export const storeUserSettings = 'STORE_USER_SETTINGS';
export const storeCurrencyList = 'STORE_CURRENCY_LIST';
export const setQuickSearchObject = 'STORE_QUICK_SEARCH_OBJECT';
export const setPlantLvlMatView = 'SET_FILTER_FOR_PLANT_LEVEL_MATERIAL_VIEW';
export const getColumnMapper = 'GET_COLUMN_MAPPER';
export const receiveMaterialListColumns = 'RECEIVE_MATERIALLIST_COLUMNS_RESULT';
export const storeNotifications = 'STORE_NOTIFICATIONS';
export const storeAdditionalNotifications = 'STORE_ADDITIONAL_NOTIFICATIONS';
export const setNotificationCount = 'SET_NOTIFICATION_COUNT';
export const changeHideDone = 'CHANGE_HIDE_DONE';
export const setLoading = 'SET_LOADING';
export const clearNotifications = 'CLEAR_NOTIFICATIONS';
export const setUseDefaultSettings = 'SET_USE_DEFAULT_SETTINGS';
export const storeConfigTable = 'STORE_CONFIG_TABLE';
export const storePlantGroups = 'STORE_PLANT_GROUPS';
export const clearConfigTable = 'CLEAR_CONFIG_TABLE';
export const changePlantGroupName = 'CHANGE_PLANT_GROUP_NAME_LOCALLY';
export const updateConfigTable = 'UPDATE_CONFIG_TABLE';
export const acceptNotification = 'ACCEPT_NOTIFICATION';
export const markNotifAsViewed = 'MARK_NOTIF_AS_VIEWED';
export const acceptAllNotifications = 'ACCEPT_ALL_NOTIFICATIONS';
export const deletePlantGroup = 'DELETE_PLANT_GROUP';
export const deleteMultiplePlantGroups = 'DELETE_MULTIPLE_PLANT_GROUPS';
export const createPlantGroup = 'CREATE_PLANT_GROUP';
export const updatePlantGroupName = 'UPDATE_PLANT_GROUP_NAME';
export const getWarnings = 'GET_WARNINGS';
export const getOutdated = 'GET_OUTDATED';
export const getOutliers = 'GET_OUTLIERS';
export const addOutliers = 'ADD_OUTLIER';
export const deleteOutlier = 'DELETE_OUTLIER';
export const storeWidgetViews = 'STORE_WIDGET_VIEWS';
export const saveWidgetViews = 'SAVE_WIDGET_VIEWS';
export const deleteWidgetView = 'DELETE_WIDGET_VIEW_SETTINGS';
export const Track = 'TRACK';
export const generateCSV = 'GENERATE_CSV';
export const getCSV = 'GET_CSV';
export const setCSVID = 'SET_CSV_ID';
export const setCSVError = 'SET_ERROR_OCCURED_FOR_CSV';
export const clearCSV = 'CLEAR_CSV';
export const selectWidgetView = 'SELECT_WIDGET_VIEW';
export const setExportListType = 'SET_LIST_TYPE_OF_EXPORT';
export const getMonetaryImpactSummary = 'GET_MONETARY_IMPACT_SUMMARY';
export const updateMonetaryImpactSummary = 'UPDATE_MONETARY_IMPACT_SUMMARY';
export const clearMonetaryImpactSummary = 'CLEAR_MONETARY_IMPACT_SUMMARY';
export const submitImpactForm = 'SUBMIT_IMPACT_FORM';
