/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Resizable } from 'react-resizable';
import './ResizableColumnHeader.css';

const ResizableColumnHeader = ({
  columnName, updateWidth, originalWidth, ...restProps
}) => {
  const MINWIDTH = 50;

  const [startX, setStartX] = useState(0);
  const [width, setWidth] = useState(originalWidth);

  const onResizeStart = (e) => {
    setStartX(e.pageX);
    e.preventDefault();
  };
  const onResizeStop = (e, { size }) => {
    if (e?.target?.style) {
      e.target.style.cursor = 'default';
    }
    const userDefinedWidth = size.width - (startX - e.pageX);
    const newWidth = userDefinedWidth > MINWIDTH ? userDefinedWidth : MINWIDTH;
    setWidth(newWidth);
    updateWidth(columnName, newWidth);
  };

  const onResize = (e) => {
    if (e?.target?.style) {
      e.target.style.cursor = 'col-resize';
    }
  };

  if (!originalWidth) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      handle={(
        <span
          aria-hidden
          className="react-resizable-handle"
          onClick={(e) => {
            e.stopPropagation();
          }}
        />

      )}
      onResizeStart={onResizeStart}
      onResizeStop={onResizeStop}
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
};
export default ResizableColumnHeader;
ResizableColumnHeader.propTypes = {
  columnName: PropTypes.string,
  updateWidth: PropTypes.func,
  originalWidth: PropTypes.number,
};
