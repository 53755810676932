import React from 'react';
import {
  Button, Select, Space, Tag, Tooltip,
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import MaterialSelector from '../../../../Common/MaterialSelector';
import { isInteger } from '../../../../Common/DateAndNumberFunctions';
import styles from './styles.module.scss';

const { Option } = Select;
const PlantMaterialSelector = ({
  plant, updatePlant, materials, setMaterials, onOK, error, setError, resetAll, validMaterials,
}) => {
  const plantList = useSelector((state) => state.commonState.plants)
    ?.sort((a, b) => a.PLANT_FACILITY_SAP_ID - b.PLANT_FACILITY_SAP_ID);

  const getPlantName = (plantObj) => `${plantObj.PLANT_FACILITY_SAP_ID} - ${plantObj.PLANT_FACILITY_SAP_NM}`;

  const setSearchMMR = (values) => {
    const removeLeadingZeroes = (input) => {
      let inputStr = input.toString();
      inputStr = inputStr.replace(/^0+/, '');
      return inputStr;
    };
    const cleanValues = values
      .map((v) => removeLeadingZeroes(v))
      .map((e) => e.replaceAll('\t', '').replaceAll('\n', ''));
    const hasInvalidInput = cleanValues.some((e) => !(isInteger(e)));
    setError(hasInvalidInput);
    const newMMR = hasInvalidInput
      ? cleanValues.sort((a) => (!isInteger(a) ? -1 : 0))
      : cleanValues.sort((a, b) => a - b);
    setMaterials(newMMR);
  };

  const tagRender = ({
    label, value, closable,
  }) => {
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    const onClose = (e) => {
      e.preventDefault();
      setSearchMMR(materials.filter((x) => x !== value));
    };
    const valid = isInteger(value);
    const materialFound = validMaterials.length > 0 ? validMaterials.some((id) => id === value) : true;

    return (
      <Tooltip title={valid ? '' : 'Only numbers are valid inputs'}>
        <Tag
          key={value}
          color={(valid && materialFound) ? '' : 'red'}
          icon={valid ? '' : <ExclamationCircleOutlined />}
          onMouseDown={onPreventMouseDown}
          closable={closable}
          onClose={onClose}
          style={{ fontSize: '16px' }}
        >
          {label}
        </Tag>
      </Tooltip>
    );
  };

  return (
    <>
      <div className={styles.row}>
        <Select
          showSearch
          placeholder="Select a single plant"
          size="large"
          style={{ width: '100%' }}
          onChange={updatePlant}
          value={plant}
          loading={!(plantList?.length > 0)}
        >
          {plantList?.map((x) => (
            <Option
              key={x.PLANT_FACILITY_SAP_ID}
            >
              {getPlantName(x)}
            </Option>
          ))}
        </Select>
        <div style={{ width: '150px' }} />
      </div>
      <div className={styles.row}>
        <MaterialSelector
          searchMMR={materials}
          setSearchMMR={setSearchMMR}
          style={{ width: '100%' }}
          placeholder="Enter one or more materials"
          handleMMRSearch={(e) => {
            e?.target?.blur();
            e?.target?.focus();
          }}
          tagRender={tagRender}
        />
        <Button type="primary" onClick={onOK} disabled={!plant || materials?.length === 0 || error} style={{ width: '150px', height: '100%' }}>
          Select materials
        </Button>
      </div>
    </>
  );
};

export default PlantMaterialSelector;
PlantMaterialSelector.defaultProps = {
  plant: null,
  materials: [],
};
PlantMaterialSelector.propTypes = {
  plant: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  updatePlant: PropTypes.func.isRequired,
  materials: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  setMaterials: PropTypes.func.isRequired,
  onOK: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
  setError: PropTypes.func.isRequired,
  resetAll: PropTypes.func.isRequired,
};
