import _ from 'lodash';
import { formatMaterialNumber } from '../DateAndNumberFunctions';
import './simulationResults.css';

const mrpVariables = [
  'MRPType',
  'LotSize',
  'ReorderPoint',
  'MaxStockLevel',
  'MinLotSize',
  'MRPGroup',
];
const missingValue = '-';

const removeNull = (value) => (!value || value === 'null' ? missingValue : value);

const pushValues = (obj, curr, rec, sim) => {
  const valueObject = _.clone(obj);
  valueObject['current-MRPType'] = removeNull(curr?.MRPType);
  valueObject['recommended-MRPType'] = removeNull(rec?.CC_MRP_TYPE);
  valueObject['simulated-MRPType'] = removeNull(sim?.CC_MRP_TYPE);
  valueObject['current-LotSize'] = removeNull(curr?.LotSize);
  valueObject['recommended-LotSize'] = removeNull(rec?.CC_LOT_SIZE);
  valueObject['simulated-LotSize'] = removeNull(sim?.CC_LOT_SIZE);
  valueObject['current-ReorderPoint'] = removeNull(curr?.ReorderPoint);
  valueObject['recommended-ReorderPoint'] = removeNull(rec?.CC_ROP);
  valueObject['simulated-ReorderPoint'] = removeNull(sim?.CC_ROP_ADJUSTED);
  valueObject['current-MaxStockLevel'] = removeNull(curr?.MaximumStockLevel);
  valueObject['recommended-MaxStockLevel'] = removeNull(rec?.CC_MAX_STOCK);
  valueObject['simulated-MaxStockLevel'] = removeNull(sim?.CC_MAX_STOCK);
  valueObject['current-MinLotSize'] = removeNull(curr?.MinimumLotSize);
  valueObject['recommended-MinLotSize'] = removeNull(rec?.CC_MIN_LOT_SIZE);
  valueObject['simulated-MinLotSize'] = removeNull(sim?.CC_MIN_LOT_SIZE_ADJ);
  valueObject['current-MRPGroup'] = removeNull(curr?.MRPGroup);
  valueObject['recommended-MRPGroup'] = removeNull(rec?.CC_MRP_GROUP);
  valueObject['simulated-MRPGroup'] = removeNull(sim?.CC_MRP_GROUP);
  return valueObject;
};

export const generateScenarioReferenceArray = (
  currentVals = [], recVals = [], simulatedVals, plant,
) => {
  const referenceArray = [];
  simulatedVals
    .filter((item) => item.PLANT_FACILITY_SAP_ID.toString() === plant)
    .forEach((item) => {
      const plantID = item.PLANT_FACILITY_SAP_ID.toString();
      const materialID = formatMaterialNumber(item.MATERIAL_TYPE_SAP_ID);
      const obj = { key: `${plantID}-${materialID}`, material: materialID };
      const curr = currentVals.find(
        (x) => x.Plant === plantID
          && formatMaterialNumber(x.MaterialNumber) === materialID,
      );
      const rec = recVals.find((v) => v.PLANT_FACILITY_SAP_ID === plantID.toString()
        && v.MATERIAL_TYPE_SAP_ID === materialID.toString());
      const sim = simulatedVals.find(
        (p) => p.PLANT_FACILITY_SAP_ID.toString() === plantID.toString()
          && Number.parseInt(p.MATERIAL_TYPE_SAP_ID, 10)?.toString() === materialID,
      );
      const filledObject = pushValues(obj, curr, rec, sim);
      filledObject.totalstock = rec?.TOT_STOCK ?? missingValue;
      filledObject.leadtime = rec?.CC_LEAD_TIME ?? missingValue;
      filledObject.criticality = rec?.CC_MAX_CRITICALITY ?? missingValue;
      filledObject.sp = rec?.SUPPLY_PROCESS ?? missingValue;
      referenceArray.push(filledObject);
    });
  return referenceArray;
};

export const generateReferenceArray = (
  mrpData, materials, simData, focusedPlant,
) => {
  const referenceArray = [];
  simData.forEach((item) => {
    const plantID = item.PLANT_FACILITY_SAP_ID;
    const material = item.MATERIAL_TYPE_SAP_ID;
    const plantObject = { key: plantID };
    plantObject.material = material;
    const curr = mrpData?.length > 0 && mrpData.find(
      (e) => e.Plant === plantID.toString()
        && formatMaterialNumber(e.MaterialNumber) === material.toString(),
    );
    const rec = materials.find((mat) => mat.PLANT_FACILITY_SAP_ID === plantID.toString()
      && mat.MATERIAL_TYPE_SAP_ID === material.toString());
    const sim = simData.find((p) => p.PLANT_FACILITY_SAP_ID === plantID
      && p.MATERIAL_TYPE_SAP_ID.toString() === material.toString());
    const filledObject = pushValues(plantObject, curr, rec, sim);
    if (plantID === focusedPlant) referenceArray.unshift(filledObject);
    else referenceArray.push(filledObject);
  });
  return referenceArray;
};

export const generateDataSource = (
  referenceArray,
  plantToHide = null,
) => mrpVariables.map((v, index) => {
  const vObj = {};
  vObj.key = index;
  vObj.inputType = v;
  referenceArray.forEach((plantObject) => {
    vObj[`current${plantObject.key}`] = plantObject[`current-${v}`];
    if (plantObject.key !== plantToHide) {
      vObj[`recommended${plantObject.key}`] = plantObject[`recommended-${v}`];
      vObj[`simulated${plantObject.key}`] = plantObject[`simulated-${v}`] !== 'Null'
        ? plantObject[`simulated-${v}`]
        : '-';
    }
  });
  return vObj;
});

export const generateColumns = (simulatedValues) => {
  const tempColumns = [];
  // list of inputs
  tempColumns.push({
    title: '',
    dataIndex: 'inputType',
    key: 'inputType',
  });
  // for each plant
  simulatedValues.forEach((item, i) => {
    const plantID = item.PLANT_FACILITY_SAP_ID;
    tempColumns.push(
      {
        title: plantID ? `Plant ${plantID}` : '',
        children: [
          {
            title: 'Cur.',
            dataIndex: `current${plantID}`,
            key: `current${plantID}`,
            align: 'center',
            className: i % 2 === 0 ? 'plantColumn' : '',
          },
          {
            title: 'Recomm.',
            dataIndex: `recommended${plantID}`,
            key: `recommended${plantID}`,
            align: 'center',
            className: i % 2 === 0 ? 'plantColumn' : '',
          },
          {
            title: 'Sim.',
            dataIndex: `simulated${plantID}`,
            key: `simulated${plantID}`,
            align: 'center',
            className: i % 2 === 0 ? 'plantColumn' : '',
          },
        ],
      },
    );
  });
  return tempColumns;
};

export const getScenarioColumns = (plant) => [
  {
    title: `Basic data for plant ${plant}` ?? 'Basic data',
    children: [
      {
        children: [
          {
            title: 'Material #',
            dataIndex: 'material',
            key: 'material',
          },
          {
            title: 'Total stock',
            dataIndex: 'totalstock',
            key: 'totalstock',
          },
          {
            title: 'Lead time',
            dataIndex: 'leadtime',
            key: 'leadtime',
          },
          {
            title: 'Criticality',
            dataIndex: 'criticality',
            key: 'criticality',
          },
          {
            title: 'Supply Process',
            dataIndex: 'sp',
            key: 'sp',
          },
        ],

      },
    ],
  },
  {
    title: 'MRP Settings',
    children: [
      {
        title: 'MRP Type',
        children: [
          {
            title: 'Cur.',
            dataIndex: 'current-MRPType',
          },
          {
            title: 'Rec.',
            dataIndex: 'recommended-MRPType',
          },
          {
            title: 'Sim.',
            dataIndex: 'simulated-MRPType',
          },

        ],
      },
      {
        title: 'Lot size',
        children: [
          {
            title: 'Cur.',
            dataIndex: 'current-LotSize',
          },
          {
            title: 'Rec.',
            dataIndex: 'recommended-LotSize',
          },
          {
            title: 'Sim.',
            dataIndex: 'simulated-LotSize',
          },

        ],
      },
      {
        title: 'Reorder point',
        children: [
          {
            title: 'Cur.',
            dataIndex: 'current-ReorderPoint',
          },
          {
            title: 'Rec.',
            dataIndex: 'recommended-ReorderPoint',
          },
          {
            title: 'Sim.',
            dataIndex: 'simulated-ReorderPoint',
          },

        ],
      },
      {
        title: 'Max stock level',
        children: [
          {
            title: 'Cur.',
            dataIndex: 'current-MaxStockLevel',
          },
          {
            title: 'Rec.',
            dataIndex: 'recommended-MaxStockLevel',
          },
          {
            title: 'Sim.',
            dataIndex: 'simulated-MaxStockLevel',
          },

        ],
      },
      {
        title: 'Minimum lot size',
        children: [
          {
            title: 'Cur.',
            dataIndex: 'current-MinLotSize',
          },
          {
            title: 'Rec.',
            dataIndex: 'recommended-MinLotSize',
          },
          {
            title: 'Sim.',
            dataIndex: 'simulated-MinLotSize',
          },

        ],
      },
      {
        title: 'MRP Group',
        children: [
          {
            title: 'Cur.',
            dataIndex: 'current-MRPGroup',
          },
          {
            title: 'Rec.',
            dataIndex: 'recommended-MRPGroup',
          },
          {
            title: 'Sim.',
            dataIndex: 'simulated-MRPGroup',
          },

        ],
      },
    ],
  },
];
