import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  Col, Row, Menu, Dropdown, Button, Divider, Tooltip,
} from 'antd';
import { DeleteTwoTone, DownOutlined, UndoOutlined } from '@ant-design/icons';
import allActions from '../../../actions';
import { SettingCodes } from './SettingTypes';
import { listModes } from '../../Common/GlobalConstants';
import hasAuthenticatedRole from '../../Common/AuthFunctions';

const UserViewSettings = ({ updateSetting, materialMaster }) => {
  const userSettings = useSelector((state) => state.commonState.userSettings);
  const userViews = useSelector((state) => state.materialState.userViews);
  const userState = useSelector((state) => state.authState.user);
  const loading = useSelector((state) => state.commonState.loading);
  const [filteredViews, setFilteredViews] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (userViews) {
      let tempViews = [];
      let selectedId;
      if (materialMaster) {
        tempViews = userViews.filter((view) => view.LIST_MODE === listModes.mmList);
        selectedId = userSettings?.DEFAULT_MM_USER_VIEW;
      } else {
        selectedId = userSettings?.DEFAULT_USER_VIEW;
        tempViews = userViews.filter((view) => view.LIST_MODE === listModes.matList);
      }
      setFilteredViews(tempViews?.sort((a, b) => {
        if (selectedId === a.VIEW_ID) return -1;
        if (selectedId === b.VIEW_ID) return 1;
        if ((a.PUBLIC_VIEW && b.PUBLIC_VIEW) || (!a.PUBLIC_VIEW && !b.PUBLIC_VIEW)) {
          return a.VIEW_NAME?.toUpperCase() < b.VIEW_NAME?.toUpperCase() ? -1 : 1;
        }
        if (a.PUBLIC_VIEW) return 1;
        return -1;
      }));
    }
  }, [materialMaster, userViews]);

  useEffect(() => {
    if (!userViews && userState?.uniqueId) {
      dispatch(allActions.MaterialListActions.getUserViews(
        userState.uniqueId,
        userState.account?.username,
      ));
    }
  }, [userViews, userState?.uniqueId]);

  const setDefaultView = (viewID) => {
    // if user clicks current default, set default to null (will then use global default)
    if (materialMaster) {
      if (viewID === userSettings?.DEFAULT_MM_USER_VIEW) {
        updateSetting(SettingCodes.defaultMMView, null);
        return;
      }
      updateSetting(SettingCodes.defaultMMView, viewID);
    } else {
      if (viewID === userSettings?.DEFAULT_USER_VIEW) {
        updateSetting(SettingCodes.defaultUserView, null);
        return;
      }
      updateSetting(SettingCodes.defaultUserView, viewID);
    }
  };

  const deleteView = (view) => {
    dispatch(allActions.MaterialListActions.deleteUserView(
      userState?.uniqueId, userState.account?.username, view.VIEW_ID,
      view.VIEW_NAME, view.VIEW_NAME?.endsWith('_PUBLIC'),
    ));
    if (materialMaster) {
      if (userSettings?.DEFAULT_MM_USER_VIEW === view.VIEW_ID) {
        updateSetting(SettingCodes.defaultMMView, null);
      }
    } else if (userSettings?.DEFAULT_USER_VIEW === view.VIEW_ID) {
      updateSetting(SettingCodes.defaultUserView, null);
    }
  };

  const isSuperUser = () => hasAuthenticatedRole(
    userState?.idTokenClaims?.roles,
    process.env.REACT_APP_SHAREDWORKLISTCREATORROLES,
  );

  const viewMenu = (
    <Menu selectedKeys={[materialMaster
      ? userSettings?.DEFAULT_MM_USER_VIEW
      : userSettings?.DEFAULT_USER_VIEW]}
    >
      {(!filteredViews || filteredViews.length < 1)
        ? (
          <Menu.Item key="noViewsFound">
            <Tooltip title="Create a View in material list" placement="bottom">
              No views found
            </Tooltip>
          </Menu.Item>
        )
        : (
          <>
            <Menu.Item key="resetToDefault" onClick={() => setDefaultView(null)}>
              <UndoOutlined style={{ paddingRight: '3px' }} />
              Reset to system default view
            </Menu.Item>
            <Menu.Divider />
          </>
        )}
      {filteredViews && filteredViews.map((view) => (
        <Menu.Item key={view.VIEW_ID}>
          <Row>
            <Col
              span={19}
              onClick={() => setDefaultView(view.VIEW_ID)}
              style={{ color: view.PUBLIC_VIEW ? '#75ba75' : null }}
            >
              {view.VIEW_NAME}
            </Col>
            {(!view.PUBLIC_VIEW || (isSuperUser() && view.PUBLIC_VIEW))
            && (
            <>
              <Divider type="vertical" />
              <Col span={3} style={{ display: 'flex', justifyContent: 'center' }}>
                <Tooltip title="Delete this view" placement="right">
                  <DeleteTwoTone twoToneColor="IndianRed" onClick={() => deleteView(view)} />
                </Tooltip>
              </Col>
            </>
            )}
          </Row>
        </Menu.Item>
      ))}
    </Menu>
  );

  const getInfoText = () => {
    if (materialMaster && userSettings?.DEFAULT_MM_USER_VIEW) {
      return filteredViews?.find(
        (x) => x?.VIEW_ID === userSettings?.DEFAULT_MM_USER_VIEW,
      )?.VIEW_NAME;
    }
    if (!materialMaster && userSettings?.DEFAULT_USER_VIEW) {
      return filteredViews?.find((x) => x?.VIEW_ID === userSettings?.DEFAULT_USER_VIEW)?.VIEW_NAME;
    }
    return 'No default view is set.';
  };

  return (
    <Row justify="space-between">
      <Dropdown overlay={viewMenu} placement="bottomRight">
        <Button style={{ width: '75%' }}>
          {(loading && loading.USER_SETTINGS === false) && getInfoText()}
          {(loading && loading.USER_SETTINGS) && 'Loading...'}
          <DownOutlined />
        </Button>
      </Dropdown>
    </Row>
  );
};

export default UserViewSettings;
UserViewSettings.defaultProps = {
  materialMaster: false,
};

UserViewSettings.propTypes = {
  updateSetting: PropTypes.func.isRequired,
  materialMaster: PropTypes.bool,
};
