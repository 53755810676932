import React, { useEffect, useState } from 'react';
import { Empty, Row, Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { PlusCircleTwoTone } from '@ant-design/icons';
import './SummaryStyle.css';
import { COMMENT_TYPE } from '../../../../Common/GlobalConstants';
import hasAuthenticatedRole from '../../../../Common/AuthFunctions';

const CommentsContainer = ({ type, data, expandLongText }) => {
  const user = useSelector((state) => state.authState?.user);
  const [canComment, setCanComment] = useState(false);
  const [content, setContent] = useState('');
  const originaltext = type === COMMENT_TYPE.IOTA ? data.iotaComment : data.warehouseComment;

  useEffect(() => {
    if (user?.idTokenClaims?.roles) {
      const userCanComment = hasAuthenticatedRole(
        user.idTokenClaims.roles,
        process.env.REACT_APP_IOTA_COMMENTER,
      );
      setCanComment(userCanComment);
    }
  }, [user?.idTokenClaims]);

  useEffect(() => {
    setContent(originaltext);
  }, [originaltext]);

  return (
    <>
      <Row style={{ width: '100%' }} className="commentContainer commentParagaph">
        {content
          ? (
            <div style={{ width: '98%' }}>{content}</div>
          )
          : <Empty className="commentContainerEmpty" image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ width: '100%', fontSize: '12px' }} description="No comments found" />}
      </Row>
      <Row style={{ float: 'right' }}>
        <Tooltip title="View full text / edit ">
          <PlusCircleTwoTone style={{ height: '2vh' }} onClick={() => expandLongText(type)} />
        </Tooltip>
      </Row>
    </>
  );
};

CommentsContainer.defaultProps = {
  data: {},
};

CommentsContainer.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.objectOf(PropTypes.any),
  expandLongText: PropTypes.func.isRequired,
};

export default CommentsContainer;
