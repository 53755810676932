/* eslint-disable camelcase */
import * as actions from './MaterialListActionTypes';
import apiAction from '../utilities/CommonMethods';
import {
  Base_URL,
  Get_MaterialList_URL,
  Get_MaterialList_SearchCount_URL,
  Get_MaterialColumns_URL,
  Get_Dropdown_Values,
  User_Views,
  Get_MaterialList_Aggr_URL,
  Get_Material_Ids,
  MassUpdateLastChecked,
  ShareView,
  Create_User_View,
  Create_Public_User_View,
  Public_User_Views,
} from '../APIUrls';
import { listModes } from '../../components/Common/GlobalConstants';

const clearMaterialStoreState = () => ({
  type: actions.clearMaterialStoreState,
  payload: null,
});

const setCheckMatlistCount = (data) => ({
  type: actions.checkMatListCount,
  payload: data,
});

const clearMaterialList = () => ({
  type: actions.clearMaterialList,
  payload: null,
});

const setPrevPath = () => ({
  type: actions.setPrevPath,
  payload: 'matDetails',
});

const deletePrevPath = () => ({
  type: actions.setPrevPath,
  payload: null,
});

const setColAggrLoading = (colName) => ({
  type: actions.setAggrLoading,
  payload: colName,
});

const clearColAggregations = () => ({
  type: actions.clearAllAggrValues,
  payload: null,
});

const fetchMaterialList = (materialViewState, searchID, allMaterials, dataTypes, SQLOnly) => {
  const url = Base_URL + Get_MaterialList_URL;

  function setMaterialListData(data, _searchID) {
    return {
      type: actions.receiveMateriallist,
      payload: { data, searchID: _searchID },
    };
  }
  function onFailure() {
    console.log('Error occured fetching more data to materiallist');
    return setCheckMatlistCount(true);
  }
  return apiAction({
    url,
    method: 'POST',
    data: {
      SortColumn: materialViewState.sortColumn?.length > 0
        ? materialViewState.sortColumn : undefined,
      SortDirection: materialViewState.sortDirection?.length > 0
        ? materialViewState.sortDirection : undefined,
      SearchFilters: materialViewState.searchFilters,
      NoOfRecords: materialViewState.scrollPage.currentNoOfRecords
        + materialViewState.scrollPage.noOfRecordsToFetch,
      allMaterials,
      dataTypes,
      SQLOnly,
    },
    onSuccess: (data) => setMaterialListData(data, searchID),
    onFailure,
    onFailureNotification: (error) => ({
      Type: 'error',
      Title: 'Material list',
      Page: '/material-list',
      searchID,
      Message: error?.code === 'ETIMEOUT'
        ? 'Request timed out, please try again'
        : 'Failed to load material list data',
    }),
    label: actions.receiveMateriallist,
  });
};

const fetchMaterialListColAggregate = (materialViewState, searchID, allMaterials, columns) => {
  const url = Base_URL + Get_MaterialList_Aggr_URL;

  function setMaterialListAggr(data, _searchID) {
    return {
      type: actions.receiveMateriallistAggr,
      payload: { data, searchID: _searchID },
    };
  }
  return apiAction({
    url,
    method: 'POST',
    data: {
      SearchFilters: materialViewState.searchFilters,
      allMaterials,
      columns,
    },
    onSuccess: (data) => setMaterialListAggr(data, searchID),
    onFailureNotification: (error) => ({
      Type: 'error',
      Title: 'Material list',
      Page: '/material-list',
      searchID,
      Message: error?.code === 'ETIMEOUT'
        ? 'Request timed out, please try again'
        : 'Failed to load material list data',
    }),
    label: actions.receiveMateriallist,
  });
};

const setMaterialListCountNull = () => ({
  type: actions.clearMaterialListCount,
  payload: null,
});

const fetchMaterialListCount = (materialViewState, searchID, allMaterials) => {
  const url = Base_URL + Get_MaterialList_SearchCount_URL;
  function setMaterialListCount(data, _searchID) {
    return {
      type: actions.setMaterialListCount,
      payload: { data, searchID: _searchID },
    };
  }
  return apiAction({
    url,
    method: 'POST',
    data: {
      SearchFilters: materialViewState.searchFilters,
      allMaterials,
    },
    onSuccess: (data) => setMaterialListCount(data, searchID),
    onFailure: () => console.log('Error occured fetching materiallist count'),
    label: actions.fetchMaterialListCount,
  });
};

const fetchMaterialColumns = (
) => {
  const url = Base_URL + Get_MaterialColumns_URL;
  function setMaterialListColumns(data) {
    return {
      type: actions.receiveMaterialListColumns,
      payload: data,
    };
  } return apiAction({
    url,
    method: 'GET',
    onSuccess: setMaterialListColumns,
    onFailure: () => console.log('Error occured fetching material list columns'),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Could not retrieve columns',
      Message: 'An error occured while fetching column name mappings',
    }),
    label: actions.receiveMaterialListColumns,
  });
};

const getUserViews = (userID, userEmail) => {
  const url = Base_URL + User_Views;

  function storeUserViews(data) {
    return {
      type: actions.storeUserViews,
      payload: data,
    };
  }

  return apiAction({
    url,
    method: 'POST',
    data: {
      userID,
      userEmail,
    },
    onSuccess: storeUserViews,
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'User-defined Views',
      Message: 'Failed to load user\'s custom views.',
    }),
    label: actions.storeUserViews,
  });
};

const setUserView = (userID, viewID, viewName, viewSetup, userEmail = '', materialMaster = false) => {
  const url = Base_URL + Create_User_View;
  return apiAction({
    url,
    method: 'POST',
    data: {
      User_ID: userID,
      User_Email: userEmail,
      View_ID: viewID,
      View_Name: viewName,
      View_Setup: JSON.stringify(viewSetup),
      List_Mode: materialMaster ? listModes.mmList : listModes.matList,
    },
    onSuccess: () => getUserViews(userID, userEmail),
    onSuccessNotification: {
      Type: 'success',
      Title: 'Updated user view',
      Message: `Successfully stored/updated user view named ${viewName}.`,
    },
    onFailure: (msg) => console.log(`Error occured updating a user view. ${msg?.message}`),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'User-defined view',
      Message: `Failed to save user-defined view named ${viewName}.`,
    }),
    label: actions.storeUserViews,
  });
};

const setPublicUserView = (userID, viewID, viewName,
  viewSetup, userEmail = '', materialMaster = false) => {
  const url = Base_URL + Create_Public_User_View;
  return apiAction({
    url,
    method: 'POST',
    data: {
      User_ID: userID,
      User_Email: userEmail,
      View_ID: viewID,
      View_Name: viewName,
      View_Setup: JSON.stringify(viewSetup),
      List_Mode: materialMaster ? listModes.mmList : listModes.matList,
    },
    onSuccess: () => getUserViews(userID, userEmail),
    onSuccessNotification: {
      Type: 'success',
      Title: 'Updated public user view',
      Message: `Successfully stored/updated public user view named ${viewName}.`,
    },
    onFailure: (msg) => console.log(`Error occured updating a user view. ${msg?.message}`),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'User-defined view',
      Message: `Failed to save user-defined view named ${viewName}.`,
    }),
    label: actions.storeUserViews,
  });
};

const shareUserView = (
  userID,
  viewID,
  viewName,
  columnSetup,
  userEmail = '',
  materialMaster = false,
  recieverEmails = [],
) => {
  const url = Base_URL + ShareView;
  return apiAction({
    url,
    method: 'POST',
    data: {
      User_ID: userID,
      View_ID: viewID,
      View_Name: viewName,
      columnSetup,
      User_Email: userEmail,
      List_Mode: materialMaster ? listModes.mmList : listModes.matList,
      recieverEmails,
    },
    onSuccess: () => null,
    // onSuccess: () => (userID),
    onSuccessNotification: {
      Type: 'success',
      Title: 'Updated share view',
      Message: `Successfully stored/updated share view named ${viewName}.`,
    },
    onFailure: (msg) => console.log(`Error occured updating a user view. ${msg?.message}`),
    onFailureNotification: (err) => {
      console.log(err);
      let msg = `Failed to share view named ${viewName}.`;
      if (err?.state === 253) msg = err.message;
      return ({
        Type: 'error',
        Title: 'User-defined view',
        Message: msg,
      });
    },
    label: actions.setShareView,
  });
};

const deleteUserView = (userID, userEmail, viewID, viewName, publicView = false) => {
  const url = Base_URL + (publicView ? Public_User_Views : User_Views);
  const params = {
    View_ID: viewID,
  };
  if (!publicView) {
    params.User_ID = userID;
    params.User_Email = userEmail;
  }
  return apiAction({
    url,
    method: 'DELETE',
    params,
    onSuccess: () => getUserViews(userID, userEmail),
    onSuccessNotification: {
      Type: 'success',
      Title: 'Deleted user view',
      Message: `Successfully deleted user view named ${viewName}.`,
    },
    onFailure: (msg) => console.log(`Error occured deleting a user view. ${msg?.message}`),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'User-defined view',
      Message: `Failed to delete user-defined view named ${viewName}.`,
    }),
    label: actions.deleteUserView,
  });
};

const getDropdownValues = (
) => {
  const url = Base_URL + Get_Dropdown_Values;
  function setDropdownValues(data) {
    return {
      type: actions.getDropdownValues,
      payload: data,
    };
  } return apiAction({
    url,
    method: 'GET',
    onSuccess: setDropdownValues,
    onFailure: () => console.log('Error occured fetching dropdown values'),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Could not retrieve dropdown values',
      Message: 'An error occured while fetching dropdown values',
    }),
    label: actions.getDropdownValues,
  });
};

const setSelectedRowKeys = (keys) => ({
  type: actions.setSelectedRowKeys,
  payload: keys,
});

const setMaterialViewLocalFilter = (data) => ({
  type: actions.setMaterialViewLocalFilter,
  payload: data,
});

const setMaterialViewLocalSort = (data) => ({
  type: actions.setMaterialViewLocalSort,
  payload: data,
});

const setLocalMaterialView = (data) => ({
  type: actions.setLocalMaterialView,
  payload: data,
});

const setDisplayHeaderCells = (data) => ({
  type: actions.setDisplayHeaderCells,
  payload: data,
});

const setLoading = () => ({
  type: actions.setLoading,
});

const resetComplete = () => ({
  type: actions.resetComplete,
});

const selectView = (view) => ({
  type: actions.selectView,
  payload: view,
});

const setSearchID = (searchID) => ({
  type: actions.setSearchID,
  payload: searchID,
});

const setScrollToMaterial = (yAxis) => ({
  type: actions.scrollToMatVals,
  payload: {
    yAxis,
  },
});

const generateCorrectData = (
  actionType,
  plant,
  material,
  userEmail,
  userID,
  date,
) => {
  if (actionType === 'postpone') {
    return {
      PlantID: plant,
      MaterialID: material,
      PostponeToDate: date,
      PostponedBy: userEmail,
      UserID: userID,
    };
  }
  if (actionType === 'inspect') {
    return {
      PlantID: plant,
      MaterialID: material,
      InspectToDate: date,
      InspectedBy: userEmail,
      UserID: userID,
    };
  }
  if (actionType === 'review') {
    return {
      PlantID: plant,
      MaterialID: material,
      ReviewedBy: userEmail,
      UserID: userID,
    };
  }

  return null;
};

const generateCorrectDataForAll = (actionType, userEmail, userID, date) => {
  if (actionType === 'postpone') {
    return {
      PostponeToDate: date,
      PostponedBy: userEmail,
      UserID: userID,
    };
  }
  if (actionType === 'inspect') {
    return {
      InspectToDate: date,
      InspectedBy: userEmail,
      UserID: userID,
    };
  }
  if (actionType === 'review') {
    return {
      ReviewedBy: userEmail,
      UserID: userID,
    };
  }

  return null;
};

const updateLastChecked = (
  items,
  userEmail,
  userID,
  allItems,
  materialViewState,
  recordsToAdd,
) => {
  const url = Base_URL + MassUpdateLastChecked;
  const data = allItems ? {
    SearchFilters: materialViewState.searchFilters,
    NoOfRecords: recordsToAdd,
    allItems,
    userEmail,
    userID,
  } : {
    items,
    userEmail,
    userID,
  };

  if (data) {
    return apiAction({
      url,
      method: 'POST',
      data,
      onSuccessNotification: {
        Type: 'success',
        Title: 'Last Checked date',
        Message: 'Successfully updated last checked date selected material(s)',
      },
      onFailure: () => console.log('Error occured while trying to last checked date'),
      onFailureNotification: () => ({
        Type: 'error',
        Title: 'Last Checked date',
        Message: 'Failed to update last checked date',
      }),
      label: actions.lastChecked,
    });
  }
  return null;
};

const getMaterialIds = (materialViewState, records, sendToMrp, copyFunction) => {
  const url = Base_URL + Get_Material_Ids;
  const requestObject = {
    SearchFilters: materialViewState.searchFilters,
    NoOfRecords: records,
  };

  const stringWithMaterials = (listOfMaterialIds) => {
    const arr = listOfMaterialIds?.map((material) => material.MaterialID);
    const plants = listOfMaterialIds?.map((matPlant) => matPlant.PlantID);
    if (arr && arr.length > 0) {
      copyFunction(arr, sendToMrp, plants);
    } else {
      copyFunction([], sendToMrp, plants);
    }
  };

  if (requestObject) {
    return apiAction({
      url,
      method: 'POST',
      data: requestObject,
      onSuccess: (list) => {
        stringWithMaterials(list);
      },
      onFailure: (err) => console.log(err, 'Error occured while trying to copy materials'),
      onFailureNotification: () => ({
        Type: 'error',
        Title: 'Something went wrong',
        Message: 'IOTA could not copy materials',
      }),
      label: actions.getMaterialIds,
    });
  }
  return [];
};

const updateActions = (
  actionType,
  listOfMaterials,
  userEmail,
  userID,
  date,
  allItems,
  materialViewState,
  recordsToAdd,
  updateCallback,
) => {
  const actionTypeAsUnderscore = actionType.toLowerCase();
  const url = `${Base_URL}${actionTypeAsUnderscore}-mass-update`;
  const data = allItems ? {
    ...generateCorrectDataForAll(actionTypeAsUnderscore, userEmail, userID, date),
    SearchFilters: materialViewState.searchFilters,
    NoOfRecords: recordsToAdd,
    allItems,
  } : listOfMaterials.map((material) => generateCorrectData(
    actionTypeAsUnderscore,
    material.plantID,
    material.materialID,
    userEmail,
    userID,
    date,
  ));

  if (data) {
    let msg = actionType;
    if (actionType?.includes('last')) msg = `set ${actionType.replace('-', ' ')} date`;
    return apiAction({
      url,
      method: 'POST',
      data,
      onSuccess: () => {
        updateCallback();
      },
      onSuccessNotification: {
        Type: 'success',
        Title: `Successful ${actionType}`,
        Message: `Successful ${actionTypeAsUnderscore} of material(s)`,
      },
      onFailure: () => console.log(`Error occured while trying to ${actionTypeAsUnderscore}`),
      onFailureNotification: () => ({
        Type: 'error',
        Title: actionType,
        Message: `Failed to ${msg}`,
      }),
      label: actions[actionType],
    });
  }

  return null;
};

export default {
  fetchMaterialList,
  fetchMaterialListColAggregate,
  setSelectedRowKeys,
  setMaterialViewLocalFilter,
  setMaterialViewLocalSort,
  setLocalMaterialView,
  setDisplayHeaderCells,
  setLoading,
  setCheckMatlistCount,
  fetchMaterialListCount,
  fetchMaterialColumns,
  getDropdownValues,
  setMaterialListCountNull,
  clearMaterialStoreState,
  clearMaterialList,
  resetComplete,
  setUserView,
  getUserViews,
  shareUserView,
  deleteUserView,
  setPublicUserView,
  selectView,
  setSearchID,
  setScrollToMaterial,
  setPrevPath,
  deletePrevPath,
  setColAggrLoading,
  clearColAggregations,
  updateActions,
  getMaterialIds,
  updateLastChecked,
};
