import React from 'react';
import { Card, PageHeader, Tabs } from 'antd';
import CostConfig from './CostConfig';
import PlantGroupConfig from './PlantGroupConfig';
import OutlierConfig from './OutlierConfig';
import WidgetViewSettings from './WidgetViewSettings';

const ConfigContainer = () => {
  const { TabPane } = Tabs;

  return (
    <Card style={{ minHeight: '50vh' }}>
      <PageHeader
        className="configHeader"
        title="Admin Settings"
        ghost={false}
      />
      <Tabs defaultActiveKey="1">
        <TabPane tab="Cost configuration" key="1">
          <CostConfig />
        </TabPane>
        <TabPane tab="Outlier configuration" key="2">
          <OutlierConfig />
        </TabPane>
        <TabPane tab="Plant Groups" key="3">
          <PlantGroupConfig />
        </TabPane>
        <TabPane tab="Widget Views" key="4">
          <WidgetViewSettings />
        </TabPane>
      </Tabs>
    </Card>
  );
};

export default ConfigContainer;
