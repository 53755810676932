import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { ExclamationCircleOutlined, InfoCircleTwoTone } from '@ant-design/icons';
import {
  Button, Col, Input, Row, Select, Tag, Tooltip, Typography,
} from 'antd';
import { useSelector } from 'react-redux';
import MultiItemSelector from '../Common/MultiItemSelector';
import * as searchOptions from '../Views/MaterialListView/HeaderComponents/AdvancedSearch/AdvancedSearchOptions';
import { MODES } from '../Common/GlobalConstants';
import './Menu.css';
import { isInteger } from '../Common/DateAndNumberFunctions';

export default function QuickSearchMenu({
  setQuickSearchText, quickSearchText, searchMMR, setSearchMMR,
  selectedPlants, setSelectedPlants, selectedCCs, setSelectedCCs,
  funcLocations, setFuncLocations, storageBins, setStorageBins,
  error, setError, searchMenuOpened, searchOperators, setSearchOperators,
  resetSearch, setResetSearch, contractNos, setContractNos,
  allMaterials, setAllMaterials,
}) {
  const userSettings = useSelector((state) => state.commonState.userSettings);
  const useDefaultSettings = useSelector((state) => state.commonState.useDefaultSettings);
  const plantGroups = useSelector((state) => state.commonState.plantGroups);

  const defaultPlants = useSelector((state) => state.commonState.userSettings?.DEFAULT_PLANTS);
  const defaultCCs = useSelector((state) => state.commonState.userSettings?.DEFAULT_COMPANY_CODES);
  const plants = useSelector((state) => state.commonState.plants);
  const [formattedGrps, formatPlantGrps] = useState([]);
  const companyCodes = useSelector((state) => state.commonState.companyCodes);
  // const funcLocations = useSelector((state) => state.commonState.funcLocations);
  const [plantList, sortPlantList] = useState([]);
  // const [selectedFuncLocations, setSelectedFuncLocations] = useState([]);
  const [plantNames, setPlantNames] = useState({});
  const [ccNames, setCCNames] = useState({});
  const [maxTagCount, setTagCount] = useState(0);
  const [ccList, sortCCList] = useState([]);

  const funcLocRef = useRef();
  const { Text } = Typography;

  const inputModes = {
    ...MODES,
    MATERIAL: 'material',
  };

  useEffect(() => {
    if (plants?.length > 0) {
      const tempPlantNames = {};
      plants.forEach((p) => {
        tempPlantNames[p.PLANT_FACILITY_SAP_ID] = p.PLANT_FACILITY_SAP_NM;
      });
      setPlantNames(tempPlantNames);
      const pList = plants.sort((p1, p2) => p1.PLANT_FACILITY_SAP_ID - p2.PLANT_FACILITY_SAP_ID);
      sortPlantList(pList);
    }
  }, [plants]);

  useEffect(() => {
    if (companyCodes?.length > 0) {
      const tempCCs = {};
      companyCodes.forEach((cc) => {
        tempCCs[cc.Company_Code] = cc.Company_Name;
      });
      setCCNames(tempCCs);
      const tempccList = companyCodes?.sort((cc1, cc2) => cc1.Company_Code - cc2.Company_Code);
      sortCCList(tempccList);
    }
  }, [companyCodes]);

  useEffect(() => {
    if (searchMenuOpened) {
      if (useDefaultSettings) {
        if (defaultPlants?.length) {
          setSelectedPlants(defaultPlants);
          setSelectedCCs([]);
        } else {
          setSelectedCCs(defaultCCs);
          setSelectedPlants([]);
        }
      } else {
        setSelectedPlants(selectedPlants);
        setSelectedCCs(selectedCCs);
      }
    }
  }, [searchMenuOpened]);

  useEffect(() => {
    if (plantGroups?.length) {
      formatPlantGrps(plantGroups?.map((group) => ({
        name: group?.Name,
        value: JSON.stringify(group?.PlantList),
      })));
    }
  }, [plantGroups]);

  useEffect(() => {
    if (resetSearch) {
      setStorageBins([]);
      setFuncLocations([]);
      setSearchMMR([]);
      setQuickSearchText([]);
      setContractNos([]);
      if (useDefaultSettings) {
        setSelectedPlants(userSettings?.DEFAULT_PLANTS ?? []);
        setSelectedCCs(userSettings?.DEFAULT_COMPANY_CODES ?? []);
      } else {
        setSelectedCCs([]);
        setSelectedPlants([]);
      }
      setResetSearch(false);
    }
  }, [resetSearch]);

  useEffect(() => {
    if (!contractNos?.length) setAllMaterials(false);
  }, [contractNos]);

  const updatePlants = (event) => {
    const tempGroups = [];
    let grpCount = 0;
    let newSelectedPlants = [];
    let unSelectedPlants = [];
    selectedPlants.forEach((val) => {
      if (unSelectedPlants.length === 0
        && typeof JSON.parse(val) !== 'object'
        && !event.find((p) => p.value === val)) {
        unSelectedPlants.push(val);
      }
    });
    event?.forEach((group) => {
      if (typeof JSON.parse(group?.value) === 'object') {
        JSON.parse(group?.value)?.forEach(
          (plantID) => (unSelectedPlants?.[0] !== plantID
            ? newSelectedPlants.push(plantID) : null),
        );
        tempGroups.push(group.value);
        grpCount += 1;
      } else {
        newSelectedPlants.push(group.value);
      }
    });
    let groups = [...tempGroups];
    selectedPlants.forEach((val) => {
      // If group is un-selected, all corresponding plants are un-selected.
      if (typeof JSON.parse(val) === 'object' && !(tempGroups.find((x) => x === val))) {
        let tempUnSelectedPlants = JSON.parse(val); // Get all plants of unselected grp
        JSON.parse(val)?.forEach((p) => {
          const plantExistInOtherGrp = tempGroups?.find(
            // Check if plant exist in other SELECTED grps
            (grp) => JSON.parse(grp)?.find((x) => x === p),
          );
          if (plantExistInOtherGrp) {
            // Since, plant exist in other grp, It is not unSelected
            tempUnSelectedPlants = tempUnSelectedPlants.filter((x) => x !== p);
          }
        });
        unSelectedPlants = [...unSelectedPlants, ...tempUnSelectedPlants];
        // if plant is removed, Corresponding grps are un-selected
      } else if (!newSelectedPlants.find((p) => p === val)) {
        groups = groups.filter(
          (grp) => JSON.parse(grp).length && !(JSON.parse(grp).find((p) => p === val)),
        );
      }
    });
    newSelectedPlants = newSelectedPlants.filter(
      (x) => !unSelectedPlants.some((p) => x === p),
    );
    newSelectedPlants = newSelectedPlants?.filter(
      (id, index) => newSelectedPlants?.indexOf(id) === index,
    );
    setTagCount(grpCount);
    setSelectedPlants([...groups, ...newSelectedPlants]);
  };

  const changeSelectedPlants = (event) => {
    if (event?.length > 0) {
      updatePlants(event);
    } else {
      setSelectedPlants([]);
    }
  };

  const changeSelectedCCs = (event) => {
    if (event?.length > 0) {
      const newCCs = event.map((e) => e.key);
      setSelectedCCs(newCCs);
    } else {
      setSelectedCCs([]);
    }
  };

  const unSelectOption = (e, value, field) => {
    if (field === MODES.CC) {
      const cList = selectedCCs.filter((x) => !(x === value));
      setSelectedCCs([...cList]);
    } else {
      let pList = selectedPlants.filter((x) => x !== value);
      pList = pList.filter((val) => {
        // if plant is removed, Corresponding grps are un-selected
        if (typeof JSON.parse(val) === 'object' && JSON.parse(val)?.find((p) => p === value)) {
          return false;
        }
        return true;
      });
      setSelectedPlants(pList);
    }
  };

  const onMaterialChange = (event) => {
    const cleanEvent = event.map(
      (e) => e.replaceAll('\t', '').replaceAll('\n', ''),
    );
    const hasInvalidInput = cleanEvent.some((e) => !(isInteger(e)));
    setError((prev) => ({
      ...prev,
      material: hasInvalidInput,
    }));
    /* Bring invalid values to the front */
    const newMMR = hasInvalidInput
      ? cleanEvent.sort((a) => (!isInteger(a) ? -1 : 0))
      : cleanEvent;
    setSearchMMR(newMMR);
  };

  const pasteValue = (key, event) => {
    event.preventDefault();
    const pastedText = event.clipboardData.getData('text');
    const splitText = pastedText && pastedText.split('\r\n').filter((s) => s.length > 0);
    if (!splitText || splitText.length < 1) return;
    if (key === inputModes.PLANT) {
      const newPlants = [...new Set([...selectedPlants, ...splitText])];
      const filteredPlants = newPlants.filter(
        (val) => plants.some((plant) => plant.PLANT_FACILITY_SAP_ID === val),
      );
      setSelectedPlants(filteredPlants);
    }
    if (key === inputModes.CC) {
      const newCCs = [...new Set([...selectedCCs, ...splitText])];
      const filteredCCs = newCCs.filter(
        (val) => companyCodes.some((cc) => cc.Company_Code === val),
      );
      setSelectedCCs(filteredCCs);
    }
    if (key === inputModes.MATERIAL) {
      const newMaterials = [...new Set([...searchMMR, ...splitText])];
      onMaterialChange(newMaterials);
    }
  };

  const sortCCs = () => {
    if (!ccList) {
      return;
    }
    const sortedList = ccList?.sort((a, b) => {
      const aSearch = selectedCCs?.find((x) => a.Company_Code === x);
      const bSearch = selectedCCs?.find((x) => b.Company_Code === x);
      if ((aSearch && bSearch) || (!aSearch && !bSearch)) {
        return a.Company_Code - b.Company_Code;
      }
      if (aSearch) {
        return -1;
      }
      return 1;
    });
    sortCCList(sortedList.length ? [...sortedList] : ccList);
  };

  const sortPlants = () => {
    if (!selectedPlants || selectedPlants?.length === 0) {
      const pList = plantList?.sort(
        (p1, p2) => p1.PLANT_FACILITY_SAP_ID - p2.PLANT_FACILITY_SAP_ID,
      );
      sortPlantList(pList);
      return;
    }
    const filteredPlants = plantList?.filter((p) => {
      const x = selectedPlants?.find((plant) => plant === p.PLANT_FACILITY_SAP_ID);
      if (x) {
        return true;
      }
      return false;
    });
    const unselectedPlants = plantList?.filter((p) => {
      const x = selectedPlants?.find((plant) => plant === p.PLANT_FACILITY_SAP_ID);
      if (x) {
        return false;
      }
      return true;
    });
    sortPlantList(filteredPlants.concat(unselectedPlants));
  };

  const dispSelectedIDs = ({ value }, field) => {
    try {
      if (typeof JSON.parse(value) === 'object') {
        return null;
      }
    } catch (exception) {
      console.log(value);
      console.log(exception);
      return null;
    }
    let title;
    if (field === MODES.PLANT) {
      title = plants?.find(
        (p) => value && p?.PLANT_FACILITY_SAP_ID === value,
      )?.PLANT_FACILITY_SAP_NM;
    } else {
      title = companyCodes?.find((cc) => value && cc?.Company_Code === value)?.Company_Name;
    }
    if (!title) title = value;
    else title = `${title}(${value})`;
    return (
      <Tooltip title={title}>
        <Tag
          key={value}
          closable="true"
          onClose={(e) => unSelectOption(e, value, field)}
        >
          <span style={{ fontSize: '16px' }}>{value}</span>
        </Tag>
      </Tooltip>
    );
  };

  const tagRender = ({
    label, value, closable,
  }) => {
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    const onClose = (e) => {
      e.preventDefault();
      onMaterialChange(searchMMR.filter((x) => x !== value));
    };
    const valid = isInteger(value);
    return (
      <Tooltip title={valid ? '' : 'Only numbers are valid inputs'}>
        <Tag
          key={value}
          color={valid ? '' : 'red'}
          icon={valid ? '' : <ExclamationCircleOutlined />}
          onMouseDown={onPreventMouseDown}
          closable={closable}
          onClose={onClose}
          style={{ fontSize: '16px' }}
        >
          {label}
        </Tag>
      </Tooltip>
    );
  };

  const updateOperator = (e, type) => {
    setSearchOperators({
      ...searchOperators,
      [type]: e,
    });
  };

  const getOperatorSelector = (type) => {
    let operatorList = null;
    if (type === 'keywords' || type === 'funcLoc' || type === 'storageBin' || type === 'contractNos') {
      operatorList = searchOptions.stringOptions;
    }
    if (type === 'material') {
      operatorList = searchOptions.numberOptions;
    }
    if (type === 'plant' || type === 'companyCode') {
      operatorList = searchOptions.dropdownOptions;
    }
    return (
      <Select
        placeholder="Select condition"
        value={searchOperators?.[type] ?? null}
        style={{ width: '100%' }}
        size="middle"
        onChange={(e) => updateOperator(e, type)}
      >
        {operatorList.map((o) => searchOptions.getOption(o.value, o.display, o.symbol, o.disabled))}
      </Select>
    );
  };

  const getColumns = (text, type) => (
    <>
      <Col className="quickSearchCol1">
        <Text style={{ verticalAlign: 'middle' }}>{`${text}: `}</Text>
      </Col>
      <Col className="quickSearchCol2">
        {getOperatorSelector(type)}
      </Col>
    </>
  );

  return (
    <>
      <Row className="quickSearchRows" justify="space-between" gutter={5}>
        {getColumns('Keyword', 'keywords')}
        <Col style={{ width: '60%' }}>
          <Select
            style={{ width: '100%' }}
            onChange={(event) => {
              setQuickSearchText(event);
            }}
            value={quickSearchText}
            placeholder="Enter keyword(s)"
            maxTagCount={5}
            maxTagPlaceholder={(
              <Tooltip
                title={quickSearchText?.length > 0 && quickSearchText.map((m) => `${m}`).join('\r\n')}
                overlayClassName="selectorTooltip"
              >
                {`+ ${(quickSearchText?.length ?? 0) - 5}`}
              </Tooltip>
            )}
            allowClear
            mode="tags"
            size="middle"
            notFoundContent=""
            tokenSeparators={['\r\n', '\n']}
          />
        </Col>
        <Col style={{ width: '5%' }}>
          <Tooltip title="Enter keyword(s) and/or text string(s) to search in material desc, manuf p/n, PO text, int comment" placement="right">
            <InfoCircleTwoTone className="quickSearchInfo" />
          </Tooltip>
        </Col>
      </Row>
      <Row className="quickSearchRows" justify="space-between" gutter={5}>
        {getColumns('Material', 'material')}
        <Col style={{ width: '60%' }}>
          <Select
            className={error?.material ? 'selectInputError' : ''}
            style={{ width: '100%' }}
            onChange={(event) => onMaterialChange(event)}
            placeholder="Enter material number(s)"
            maxTagCount={5}
            value={searchMMR}
            maxTagPlaceholder={(
              <Tooltip
                title={searchMMR?.length > 0 && searchMMR.map((m) => `${m}`).join('\r\n')}
                overlayClassName="selectorTooltip"
              >
                {`+ ${(searchMMR?.length ?? 0) - 5}`}
              </Tooltip>
            )}
            maxTagTextLength={7}
            allowClear
            mode="tags"
            size="middle"
            notFoundContent=""
            tokenSeparators={[' ']}
            tagRender={tagRender}
            onPasteCapture={(e) => pasteValue(inputModes.MATERIAL, e)}
          />
        </Col>
        <Col style={{ width: '5%' }}>
          <Tooltip title="Enter/paste material number(s)" placement="right">
            <InfoCircleTwoTone className="quickSearchInfo" />
          </Tooltip>
        </Col>
      </Row>
      <Row className="quickSearchRows" justify="space-between" gutter={5}>
        {getColumns('Plant', 'plant')}
        <Col flex="auto" style={{ width: '60%' }}>
          <MultiItemSelector
            className="quickSearchRows"
            placeHolder="Select plant(s)"
            items={plantList
              && plantList.map((x) => (
                { id: x.PLANT_FACILITY_SAP_ID, name: x.PLANT_FACILITY_SAP_NM }
              ))}
            multiSelect
            groupedItems={formattedGrps}
            selectedValues={selectedPlants}
            handleChange={(event) => changeSelectedPlants(event)}
            clear={() => setSelectedPlants()}
            tagRender={(props) => dispSelectedIDs(props, MODES.PLANT)}
            maxTagCount={maxTagCount + 4}
            maxTagPlaceholder={(
              <Tooltip
                title={selectedPlants?.length > 0 && selectedPlants
                  .filter((x) => typeof JSON.parse(x) !== 'object')
                  .map((p) => `${plantNames[p]} (${p})`)
                  .join('\r\n')}
                overlayClassName="selectorTooltip"
              >
                {`+ ${(selectedPlants?.length ?? 0) - 4 - maxTagCount}`}
              </Tooltip>
            )}
            maxTagTextLength={30}
            onPasteCapture={(e) => pasteValue(inputModes.PLANT, e)}
            onDropdownVisibleChange={(open) => sortPlants(open)}
            size="middle"
          />
        </Col>
        <Col style={{ width: '5%' }}>
          <Tooltip title="Select Plant IDs or enter/paste values" placement="right">
            <InfoCircleTwoTone className="quickSearchInfo" />
          </Tooltip>
        </Col>
      </Row>
      <Row className="quickSearchRows" justify="space-between" gutter={5}>
        {getColumns('Company Code', 'companyCode')}
        <Col flex="auto" style={{ width: '60%' }}>
          <MultiItemSelector
            className="quickSearchRows"
            placeHolder="Select company code(s)"
            items={ccList
              && ccList.map((x) => (
                { id: x.Company_Code, name: x.Company_Name }
              ))}
            multiSelect
            tagRender={(props) => dispSelectedIDs(props, MODES.CC)}
            selectedValues={selectedCCs}
            handleChange={(event) => changeSelectedCCs(event)}
            clear={() => setSelectedCCs()}
            maxTagCount={4}
            maxTagPlaceholder={(
              <Tooltip
                title={selectedCCs?.length > 0 && selectedCCs.map((cc) => `${ccNames[cc]} (${cc})`).join('\r\n')}
                overlayClassName="selectorTooltip"
              >
                {`+ ${(selectedCCs?.length ?? 0) - 4}`}
              </Tooltip>
            )}
            maxTagTextLength={20}
            onPasteCapture={(e) => pasteValue(inputModes.CC, e)}
            onDropdownVisibleChange={(e) => sortCCs(e)}
            size="middle"
          />
        </Col>
        <Col style={{ width: '5%' }}>
          <Tooltip title="Select Company code or enter/paste values" placement="right">
            <InfoCircleTwoTone className="quickSearchInfo" />
          </Tooltip>
        </Col>
      </Row>
      <Row className="quickSearchRows" justify="space-between" gutter={5}>
        {getColumns('Functional Location', 'funcLoc')}
        <Col flex="auto" style={{ width: '60%' }}>
          <Select
            ref={funcLocRef}
            key="func_loc_key"
            style={{ width: '100%' }}
            onChange={(event) => {
              setFuncLocations(event);
            }}
            value={funcLocations}
            placeholder="Enter functional location(s)"
            maxTagCount={funcLocations?.[0]?.length > 4 ? 2 : 4}
            maxTagPlaceholder={(
              <Tooltip
                title={funcLocations?.length > 0 && funcLocations.map((m) => `${m}`).join('\r\n')}
                overlayClassName="selectorTooltip"
              >
                {`+ ${(funcLocations?.length ?? 0) - (funcLocations?.[0]?.length > 4 ? 2 : 4)}`}
              </Tooltip>
            )}
            maxTagTextLength={funcLocations?.length > 1 ? 4 : 10}
            allowClear
            mode="tags"
            size="middle"
            notFoundContent=""
            tokenSeparators={['\r\n', '\n']}
          />
        </Col>
        <Col style={{ width: '5%' }}>
          <Tooltip title="Enter/paste Functional locations" placement="right">
            <InfoCircleTwoTone className="quickSearchInfo" />
          </Tooltip>
        </Col>
      </Row>
      <Row className="quickSearchRows" justify="space-between" gutter={5}>
        {getColumns('Storage Bin', 'storageBin')}
        <Col flex="auto" style={{ width: '60%' }}>
          <Select
            key="storage_bin_key"
            style={{ width: '100%' }}
            onChange={(event) => {
              setStorageBins(event);
            }}
            value={storageBins}
            placeholder="Enter storage bin(s)"
            maxTagCount={storageBins?.[0]?.length > 4 ? 2 : 4}
            maxTagPlaceholder={(
              <Tooltip
                title={storageBins?.length > 0 && storageBins.map((m) => `${m}`).join('\r\n')}
                overlayClassName="selectorTooltip"
              >
                {`+ ${(storageBins?.length ?? 0) - (storageBins?.[0]?.length > 4 ? 2 : 4)}`}
              </Tooltip>
            )}
            maxTagTextLength={storageBins?.length > 1 ? 4 : 10}
            allowClear
            mode="tags"
            size="middle"
            notFoundContent=""
            tokenSeparators={['\r\n', '\n']}
          />
        </Col>
        <Col style={{ width: '5%' }}>
          <Tooltip title="Enter/paste Storage Bin IDs" placement="right">
            <InfoCircleTwoTone className="quickSearchInfo" />
          </Tooltip>
        </Col>
      </Row>
      <Row className="quickSearchRows" justify="space-between" gutter={5}>
        {getColumns('Contract number', 'contractNos')}
        <Col flex="auto" style={{ width: '60%' }}>
          <Input.Group compact>
            <Select
              key="contract_no_key"
              className={error?.contractNo ? 'selectInputError' : ''}
              style={{ width: '100%' }}
              onChange={(event) => {
                setError((prev) => ({
                  ...prev,
                  contractNo: event.some((e) => !(isInteger(e))),
                }));
                setContractNos(event);
              }}
              value={contractNos}
              placeholder="Enter contract number(s)"
              maxTagCount={contractNos?.[0]?.length > 4 ? 2 : 4}
              maxTagPlaceholder={(
                <Tooltip
                  title={contractNos?.length > 0 && contractNos.map((m) => `${m}`).join('\r\n')}
                  overlayClassName="selectorTooltip"
                >
                  {`+ ${(contractNos?.length ?? 0) - (contractNos?.[0]?.length > 4 ? 2 : 4)}`}
                </Tooltip>
              )}
              maxTagTextLength={contractNos?.length > 1 ? 4 : 10}
              allowClear
              mode="tags"
              size="middle"
              notFoundContent=""
              tokenSeparators={['\r\n', '\n']}
              tagRender={tagRender}
            />
          </Input.Group>
        </Col>
        <Col style={{ width: '5%' }}>
          <Tooltip title="Enter/paste Contract numbers" placement="right">
            <InfoCircleTwoTone className="quickSearchInfo" />
          </Tooltip>
        </Col>
      </Row>
      {/* <Row
        className="quickSearchRows"
        gutter={5}
      >
        <Col flex="auto" style={{ width: '60%', marginRight: '10px' }}>
          {!!contractNos?.length && (
            <div style={{ float: 'right' }}>
              <Button
                onClick={() => setAllMaterials(false)}
                type={!allMaterials ? 'primary' : 'default'}
              >
                Materials in contract
              </Button>
              <Button
                onClick={() => setAllMaterials(true)}
                type={allMaterials ? 'primary' : 'default'}
              >
                Materials from all plants
              </Button>
            </div>
          )}
        </Col>
      </Row> */}
    </>
  );
}

QuickSearchMenu.defaultProps = {
  selectedPlants: [],
  selectedCCs: [],
  funcLocations: [],
  storageBins: [],
  contractNos: [],
  resetSearch: false,
  allMaterials: false,
};

QuickSearchMenu.propTypes = {
  setQuickSearchText: PropTypes.func.isRequired,
  quickSearchText: PropTypes.oneOfType(
    [PropTypes.arrayOf(PropTypes.string), PropTypes.string],
  ).isRequired,
  searchMMR: PropTypes.oneOfType(
    [PropTypes.arrayOf(PropTypes.string), PropTypes.string],
  ).isRequired,
  setSearchMMR: PropTypes.func.isRequired,
  selectedPlants: PropTypes.arrayOf(PropTypes.string),
  setSelectedPlants: PropTypes.func.isRequired,
  selectedCCs: PropTypes.arrayOf(PropTypes.string),
  setSelectedCCs: PropTypes.func.isRequired,
  funcLocations: PropTypes.arrayOf(PropTypes.string),
  setFuncLocations: PropTypes.func.isRequired,
  storageBins: PropTypes.arrayOf(PropTypes.string),
  setStorageBins: PropTypes.func.isRequired,
  contractNos: PropTypes.arrayOf(PropTypes.string),
  setContractNos: PropTypes.func.isRequired,
  error: PropTypes.objectOf(PropTypes.bool).isRequired,
  setError: PropTypes.func.isRequired,
  searchMenuOpened: PropTypes.bool.isRequired,
  searchOperators: PropTypes.objectOf(PropTypes.any).isRequired,
  setSearchOperators: PropTypes.func.isRequired,
  resetSearch: PropTypes.bool,
  setResetSearch: PropTypes.func.isRequired,
  allMaterials: PropTypes.bool,
  setAllMaterials: PropTypes.func.isRequired,
};
