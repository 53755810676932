import { Card } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import Recommendations from './Recommendations';
import allActions from '../../../../../../actions';
import { mrp } from '../../../../../../actions/MaterialDetailsDomain/LoadingKeys';
import { formatMaterialNumber } from '../../../../../Common/DateAndNumberFunctions';

export default function RecommendationsContainer({ data, height, simulatedValues }) {
  const currentValues = useSelector((state) => state.materialDetailsState.mrpSettings);
  const dispatch = useDispatch();
  const { plant, material } = useParams();

  useEffect(() => {
    if (!currentValues?.some(
      (m) => m.Plant === plant
        && formatMaterialNumber(m.MaterialNumber) === material,
    )) {
      dispatch(allActions.MaterialDetailsActions.setLoading(mrp));
      dispatch(allActions.SapAPIActions.getMRP(plant, material));
    }
  }, [plant, material]);

  return (
    <Card
      className="detailCard"
      style={{
        height,
        padding: '0px',
      }}
    >
      <Recommendations materialData={data} simulatedValues={simulatedValues} />
    </Card>
  );
}

RecommendationsContainer.defaultProps = {
  simulatedValues: {},
};
RecommendationsContainer.propTypes = {
  height: PropTypes.string.isRequired,
  data: PropTypes.shape({
    MRP_TYPE_SAP_CD: PropTypes.string,
    LOT_SIZE_SAP_CD: PropTypes.string,
    EDAM_REORDER_POINT_QTY: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    RECOMMENDED_REORDER_POINT_BASE_UOM_QTY: PropTypes.number,
    EDAM_MAXIMUM_STOCK_QTY: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    CC_RECOMMENDED_MAX_STOCK_QTY: PropTypes.number,
    EDAM_ROUNDING_VALUE_FOR_PO_QTY: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  simulatedValues: PropTypes.objectOf(PropTypes.any),
};
