import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card, PageHeader, Radio, Switch, Timeline, Row, Col, Select, Button,
} from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import allActions from '../../../actions';
import UserViewSettings from './UserViewSettings';
import PlantCompanySettings from './PlantCompanySettings';
import './Settings.css';
import { SettingCodes } from './SettingTypes';
import NotificationSettings from './NotificationSettings';
import DashboardSettings from './DashboardSettings';
import { textTabs, expandTabs } from './SettingsConstants';
import DashboardFiltersSettings from './DashboardFiltersSettings';
import { listModes, WORKLIST_VIEW } from '../../Common/GlobalConstants';

const SettingsContainer = () => {
  const [showPlant, setShowPlant] = useState(true);
  const [useGlobally, setUseGlobally] = useState(true);
  const [filtersVisible, setFiltersVisible] = useState(false);
  const { Item } = Timeline;
  const dispatch = useDispatch();
  const [selectedExpandTab, setDefaultExpandTab] = useState('');
  const [selectedTextTab, setDefaultTextTab] = useState('');
  const [dynamicWL, setDynamicWL] = useState({
    listType: listModes.matList,
    view: WORKLIST_VIEW.PERSONAL,
  });
  const { Option } = Select;

  const defaultTextTab = useSelector(
    (state) => state.commonState?.userSettings?.COMMENT_SECTION_DEFAULT_TAB,
  );
  const defaultExpandTab = useSelector(
    (state) => state.commonState?.userSettings?.TAB_SECTION_DEFAULT_TAB,
  );
  const defaultCurrency = useSelector(
    (state) => state.commonState?.userSettings?.DEFAULT_CURRENCY,
  );
  const currencyList = useSelector((state) => state.commonState?.currencyList);
  const userState = useSelector(
    (state) => state.authState.user,
  );
  const useGlobalDefault = useSelector(
    (state) => state.commonState?.userSettings?.USE_DEFAULTS_GLOBALLY,
  );
  const storedCompanyCodes = useSelector(
    (state) => state.commonState.userSettings?.DEFAULT_COMPANY_CODES,
  );
  const hideNotifications = useSelector(
    (state) => state.commonState.userSettings?.HIDE_NOTIFICATIONS,
  );
  const useDefaultLocal = useSelector(
    (state) => state.materialState.useDefaultPlants,
  );
  const showWidgetLegends = useSelector(
    (state) => state.commonState.userSettings?.SHOW_WIDGET_LEGENDS,
  );

  const staticWLSettings = useSelector(
    (state) => state.commonState.userSettings?.STATIC_WORKLIST_DEFAULT_TAB,
  );
  const dynamicWLSettings = useSelector(
    (state) => state.commonState.userSettings?.DYNAMIC_WORKLIST_DEFAULTS,
  );
  const loading = useSelector((state) => state.commonState.loading);
  const storedPlantIDs = useSelector((state) => state.commonState.userSettings?.DEFAULT_PLANTS);

  useEffect(() => {
    setDefaultExpandTab(defaultExpandTab);
    setDefaultTextTab(defaultTextTab);
    if (dynamicWLSettings) setDynamicWL(JSON.parse(dynamicWLSettings));
  }, [defaultExpandTab, defaultTextTab, dynamicWLSettings]);

  useEffect(() => {
    if (storedCompanyCodes?.length > 0) {
      setShowPlant(false);
    }
  }, []);

  useEffect(() => {
    setUseGlobally(useGlobalDefault);
  }, [useGlobalDefault]);

  const updateSetting = (settingKey, settingValue) => {
    try {
      const userID = userState?.uniqueId;
      dispatch(allActions.CommonActions.setLoading('USER_SETTINGS'));
      dispatch(allActions.CommonActions.setUserSetting(userID, settingKey, settingValue));
      if (settingKey === SettingCodes.plant
        || settingKey === SettingCodes.companyCode
        || settingKey === SettingCodes.useDefaultsGlobally) {
        dispatch(allActions.WorkListActions.nullRowCountAll());
      }
    } catch { /* */ }
  };

  const updateGlobal = (bool) => {
    setUseGlobally(bool);
    updateSetting(SettingCodes.useDefaultsGlobally, bool);
    if (useDefaultLocal !== bool) {
      dispatch(allActions.CommonActions.setUseDefaultSettings(bool));
    }
  };

  const saveMatDetailsSettings = () => {
    const userID = userState?.uniqueId;
    if (!userID) return;
    dispatch(allActions.CommonActions.setUserSetting(userID, 'COMMENT_SECTION_DEFAULT_TAB', selectedTextTab));
    dispatch(allActions.CommonActions.setUserSetting(userID, 'TAB_SECTION_DEFAULT_TAB', selectedExpandTab));
  };

  const getSelectionValue = (userSelection, defaultSelection) => {
    if (!loading || loading?.USER_SETTINGS) return 'Loading...';
    return userSelection || defaultSelection;
  };

  const saveWLSettings = (isDynamic, val) => {
    const userID = userState?.uniqueId;
    if (!userID) return;
    if (!isDynamic) {
      dispatch(allActions.CommonActions.setUserSetting(userID, 'STATIC_WORKLIST_DEFAULT_TAB', val));
    } else {
      dispatch(allActions.CommonActions.setUserSetting(userID, 'DYNAMIC_WORKLIST_DEFAULTS', JSON.stringify(dynamicWL)));
    }
  };

  return (
    <PageHeader
      title="Settings"
      ghost={false}
      style={{ minHeight: '50vh' }}
    >
      <Card
        bordered={false}
        style={{ minWidth: '800px', width: '50vw' }}
      >
        <Timeline style={{ marginLeft: '-40px' }} mode="left">
          <Item label={<span className="header">Default plant and company code</span>}>
            Select either a plant or a company code
          </Item>
          <Item label="Select default value to filter on">
            <Radio.Group
              defaultValue={showPlant}
              value={showPlant}
              onChange={(e) => setShowPlant(e.target.value)}
            >
              <Radio.Button value>Plant</Radio.Button>
              <Radio.Button value={false}>Company code</Radio.Button>
            </Radio.Group>
          </Item>
          <Item label="Select your default">
            <PlantCompanySettings
              showPlant={showPlant}
              setShowPlant={setShowPlant}
              updateSetting={updateSetting}
            />
          </Item>
          <Item dot={<SettingOutlined />} label="Use defaults globally">
            <Switch
              checked={useGlobally}
              onChange={updateGlobal}
              loading={loading?.USER_SETTINGS ?? true}
              disabled={!storedCompanyCodes && !storedPlantIDs}
            />
          </Item>
          {/* <Item label={<span className="header">Material List / Material Master</span>} /> */}
          <Item dot={<SettingOutlined />} label="Selected default Material List view">
            <UserViewSettings
              updateSetting={updateSetting}
            />
          </Item>
          <Item dot={<SettingOutlined />} label="Selected default Material Master view">
            <UserViewSettings
              updateSetting={updateSetting}
              materialMaster
            />
          </Item>
          <Item dot={<SettingOutlined />} label={<span className="header">Default tabs in Material Details</span>}>
            <Row style={{ marginBottom: '5px' }}>
              <Col span={9}>
                <Select
                  onChange={(value) => setDefaultTextTab(value)}
                  style={{ width: '100%' }}
                  value={getSelectionValue(selectedTextTab, 'material_memo')}
                  loading={loading?.USER_SETTINGS ?? true}
                >
                  {textTabs.map(
                    (tab) => (
                      <Option
                        key={tab.value}
                        value={tab.value}
                      >
                        {tab.description}
                      </Option>
                    ),
                  )}
                </Select>
              </Col>
              <Col span={9}>
                <Select
                  onChange={(value) => setDefaultExpandTab(value)}
                  style={{ width: '100%', paddingLeft: '4px' }}
                  value={getSelectionValue(selectedExpandTab, 'AM')}
                  loading={loading?.USER_SETTINGS ?? true}
                  listHeight={500}
                >
                  {expandTabs.map(
                    (tab) => (
                      <Option
                        key={tab.value}
                        value={tab.value}
                      >
                        {tab.description}
                      </Option>
                    ),
                  )}
                </Select>
              </Col>
              <Col span={6}>
                <Button type="primary" style={{ marginLeft: '5px' }} onClick={saveMatDetailsSettings}>Save changes</Button>
              </Col>
            </Row>
          </Item>

          <Item dot={<SettingOutlined />} label={<span className="header">Static worklist default tab</span>}>
            <Row style={{ marginBottom: '5px' }}>
              <Col span={9}>
                <Select
                  onChange={(value) => saveWLSettings(false, value)}
                  style={{ width: '100%', paddingLeft: '4px' }}
                  value={staticWLSettings ?? listModes.matList}
                  loading={loading?.USER_SETTINGS ?? true}
                  listHeight={500}
                >
                  <Option key={listModes.matList} value={listModes.matList}>
                    Material List
                  </Option>
                  <Option key={listModes.mmList} value={listModes.mmList}>
                    Material Master
                  </Option>
                </Select>
              </Col>

            </Row>
          </Item>
          <Item dot={<SettingOutlined />} label={<span className="header">Dynamic worklist defaults</span>}>
            <Row style={{ marginBottom: '5px' }}>
              <Col span={9}>
                <Select
                  onChange={(value) => setDynamicWL({ ...dynamicWL, listType: value })}
                  style={{ width: '100%', paddingRight: '4px' }}
                  value={dynamicWL.listType}
                  loading={loading?.USER_SETTINGS ?? true}
                  listHeight={500}
                >
                  <Option key={listModes.matList} value={listModes.matList}>
                    Material List
                  </Option>
                  <Option key={listModes.mmList} value={listModes.mmList}>
                    Material Master
                  </Option>
                </Select>
              </Col>
              <Col span={9}>
                <Select
                  onChange={(value) => setDynamicWL({ ...dynamicWL, view: value })}
                  style={{ width: '100%' }}
                  value={dynamicWL.view}
                  loading={loading?.USER_SETTINGS ?? true}
                >
                  <Option key={WORKLIST_VIEW.PERSONAL} value={WORKLIST_VIEW.PERSONAL}>
                    Personal
                  </Option>
                  <Option key={WORKLIST_VIEW.PUBLIC} value={WORKLIST_VIEW.PUBLIC}>
                    Public
                  </Option>
                </Select>
              </Col>
              <Col span={6}>
                <Button
                  type="primary"
                  style={{ marginLeft: '5px' }}
                  onClick={() => saveWLSettings(true)}
                >
                  Save changes
                </Button>
              </Col>
            </Row>
          </Item>
          <Item dot={<SettingOutlined />} label={<span className="header">Default Currency</span>}>
            <Row style={{ marginBottom: '5px' }}>
              <Col span={18}>
                <Select
                  showSearch
                  onChange={(value) => updateSetting('DEFAULT_CURRENCY', value)}
                  filterOption={(input, option) => option.children?.toLowerCase()?.includes(
                    input?.toLocaleLowerCase(),
                  )}
                  style={{ width: '100%' }}
                  value={getSelectionValue(defaultCurrency, 'NOK')}
                  loading={loading?.USER_SETTINGS ?? true}
                >
                  {currencyList?.length
                    ? currencyList.sort((a, b) => a.FROM_CUR_NAME?.localeCompare(b.FROM_CUR_NAME))
                      .map(
                        (curr) => (
                          <Option
                            key={curr.FROM_CURRENCY}
                            value={curr.FROM_CURRENCY}
                          >
                            {`${curr.FROM_CUR_NAME} (${curr.FROM_CURRENCY?.trim()})`}
                          </Option>
                        ),
                      ) : <></>}
                </Select>
              </Col>
            </Row>
          </Item>
          <Item label={<span className="header">Notification settings</span>} />
          <Item dot={<SettingOutlined />} label="Show notifications">
            <NotificationSettings
              updateSetting={updateSetting}
              hideNotifications={hideNotifications}
              loading={loading}
            />
          </Item>
          <Item label={<span className="header">Dashboard filter settings</span>}>
            <Button type="primary" onClick={() => setFiltersVisible(true)}>Open Dashboard filters</Button>
            <DashboardFiltersSettings
              visible={filtersVisible}
              setVisible={setFiltersVisible}
              updateSettings={updateSetting}
              openWithDefault
            />
          </Item>
          <Item label={<span className="header">Dashboard settings</span>} />
          <Item dot={<SettingOutlined />} label="Show clickable widget legends">
            <DashboardSettings
              updateSetting={updateSetting}
              showWidgetLegends={showWidgetLegends}
              loading={loading}
            />
          </Item>
        </Timeline>
      </Card>
    </PageHeader>
  );
};

export default SettingsContainer;
