/* eslint-disable import/prefer-default-export */
export const getMRP = 'GET_MRP';
export const setMRP = 'SET_MRP';
export const getMRPs = 'GET_MRPs';
export const setMRPs = 'SET_MRPs';
export const getFixedBin = 'GET_FIXED_BIN';
export const setFixedBin = 'SET_FIXED_BIN';
export const clearFixedBin = 'CLEAR_FIXED_BIN';
export const clearFixedBinupdate = 'CLEAR_FIXED_BIN';
export const createMassUpdateChangelog = 'CREATE_MASS_UPDATE_CHANGELOG';
export const createPlannedOrderChangelog = 'CREATE_PLANNED_ORDER_TEXT_CHANGELOG';
export const clearMRP = 'CLEAR_MRP';
export const clearMRPs = 'CLEAR_MRPS';
export const clearMRPupdate = 'CLEAR_MRP_UPDATE';
export const addMemo = 'ADD_MEMO';
export const addPlOrdTxt = 'ADD_PLANNED_ORDER_TEXT';
export const addMemos = 'ADD_MEMOS';
export const addPlOrdTxts = 'ADD_PLANNED_ORDER_TEXTS';
export const createMassUpdateComments = 'CREATE_MASS_UPDATE_COMMENTS';
export const clearMrpUpdateError = 'CLEAR_UPDATE_MRP_ERROR';
export const mrpUpdateError = 'UPDATE_MRP_ERROR';
