/* eslint-disable quotes */
/* eslint-disable camelcase */
import * as actionTypes from './ActionTypes';
import apiAction from '../utilities/CommonMethods';
import {
  Base_URL,
  Simulate_URL,
  saveNewStockFormURL,
  newStockSimulation,
  getNewStockFormsURL,
  deleteNewStockFormsURL,
  storeNewStockSessionFormURL,
} from '../APIUrls';

const retrieveSimulation = (id) => {
  const url = `${Base_URL}${Simulate_URL}/${id}`;
  function onResponse(data) {
    if (data.metadata.state.result_state === 'SUCCESS' && data?.notebook_output?.result) {
      const result = JSON.parse(data?.notebook_output?.result.replaceAll(/'/ig, '"'));
      const metadata = {
        start_time: data?.metadata?.start_time,
        input: data?.metadata?.overriding_parameters?.notebook_params,
      };
      return {
        type: actionTypes.retrieveSimulation,
        payload: { results: result, metadata, id },
      };
    }
    const status = data?.metadata?.state?.life_cycle_state;
    return {
      type: actionTypes.simulationStatus,
      payload:
      {
        status: status === 'TERMINATED' ? data?.metadata?.state?.result_state : status,
        start_time: data?.metadata?.start_time,
        id,
      },
    };
  }
  return apiAction({
    url,
    method: 'GET',
    onSuccess: onResponse,
    onFailure: () => console.log('Could not retrieve simulation.'),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Simulation Data Loading Failure',
      Message: 'Failed to retrieve data for current simulation job',
    }),
    label: actionTypes.retrieveSimulation,
  });
};
const clearSimulationResults = (id) => ({
  type: actionTypes.clearSimulationResults,
  payload: id,
});

const saveSimulation = (plant, material, userEmail, run, type, description) => {
  const url = `${Base_URL}${Simulate_URL}/save`;
  function onResponse() {
    return {
      type: actionTypes.simulate,
      payload: run,
    };
  }
  return apiAction({
    url,
    method: 'POST',
    data: {
      plant,
      material,
      userEmail,
      run,
      type,
      description: description?.length > 0 ? description : null,
    },
    onSuccess: onResponse,
    onFailure: () => console.log('Could not save simulation run ID.'),
    label: actionTypes.saveSimulation,
  });
};

const simulate = (plant, materials, values = {}, userEmail, type, userID, currentValues = {}) => {
  const url = `${Base_URL}${Simulate_URL}`;
  const multiple = materials.length > 1;

  const simulation_parameters = materials.map((material) => ({
    plant,
    material_no: material,
    mrp_type: values.mrp_type,
    lead_time: values.lead_time,
    max_criticality: values.max_criticality,
    approved_deviation: values.approved_deviation,
  }));
  const data = { simulation_parameters, userID, currentValues };

  return apiAction({
    url,
    method: 'POST',
    data,
    onSuccess: (d) => saveSimulation(plant, multiple ? 'multiple' : materials[0], userEmail, d.run_id, type, values.description),
    onFailure: () => console.log(data),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Simulation Failure',
      Message: 'Failed to start simulation job.',
    }),
    label: multiple ? actionTypes.simulateMultiple : actionTypes.simulate,
  });
};

const clearSimulationID = () => ({
  type: actionTypes.simulate,
  payload: 0,
});

const storeAllNewStockForms = (res) => ({
  type: actionTypes.saveAllNewStockForms,
  payload: res,
});

const clearNewStockResults = () => ({
  type: actionTypes.clearNewStockResults,
  payload: null,
});

const storeFormRows = (form) => ({
  type: actionTypes.storeFormRowsTemp,
  payload: form.current,
});

const unsetNewStockError = () => ({
  type: actionTypes.unsetNewStockError,
  payload: null,
});

const getAllNewStockForms = (userId) => {
  const url = `${Base_URL}${Simulate_URL}/${getNewStockFormsURL}/${userId}`;

  return apiAction({
    url,
    method: 'GET',
    onSuccess: (res) => storeAllNewStockForms(res),
    onFailure: (data) => console.log(data),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Load New stock addition forms error',
      Message: 'Failed to retrieve all saved new stock addition forms.',
    }),
    label: actionTypes.getAllNewStockForms,
  });
};

const deleteNewStockForm = (userId, formId, formName) => {
  const url = `${Base_URL}${Simulate_URL}/${deleteNewStockFormsURL}`;

  return apiAction({
    url,
    method: 'DELETE',
    data: {
      userId,
      formId,
    },
    onSuccess: (res) => storeAllNewStockForms(res),
    onSuccessNotification: {
      Type: 'success', Title: 'Delete form', Message: `New stock addtion form ${formName ?? ''} successfully deleted`, Placement: 'bottomRight',
    },
    onFailure: (data) => console.log(data),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Delete New stock addition form error',
      Message: 'Failed to delete the new stock addition form.',
    }),
    label: actionTypes.deleteNewStock,
  });
};

const saveNewStockForm = (formObj, successMsg, errMsg) => {
  const url = `${Base_URL}${Simulate_URL}/${saveNewStockFormURL}`;
  const {
    formId, userId, formName, description, formData, results,
  } = formObj;
  const objToString = JSON.stringify({ form: formData, results });
  let sMsg;
  if (!successMsg) {
    sMsg = `New stock addition form ${formName ?? ''} is successfully saved`;
  } else sMsg = successMsg;
  let eMsg;
  if (!errMsg) {
    eMsg = 'Failed to save new stock addition form';
  } else eMsg = errMsg;
  return apiAction({
    url,
    method: 'POST',
    data: {
      formId,
      userId,
      formName,
      description,
      formData: objToString,
    },
    onSuccess: (res) => storeAllNewStockForms(res),
    onSuccessNotification: {
      Type: 'success', Title: 'New stock addition form', Message: sMsg, Placement: 'bottomRight',
    },
    onFailure: (data) => console.log(data),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'New stock addition form error',
      Message: eMsg,
    }),
    label: actionTypes.saveNewStock,
  });
};

const newStock = (data) => {
  const url = `${Base_URL}${Simulate_URL}/${newStockSimulation}`;
  function setNewStockRunID(res, formData) {
    return {
      type: actionTypes.saveNewStockJobData,
      payload: {
        newStockform: formData,
        jobId: res,
      },
    };
  }
  return apiAction({
    url,
    method: 'POST',
    data: {
      newStockForm: data?.[0]?.NEW_STOCK_SIM_ID,
    },
    onSuccess: (res) => setNewStockRunID(res, data?.[0]?.FORM),
    onFailure: () => console.log(data),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'New stock simulation Failure',
      Message: 'Failed to start new stock simulation job.',
    }),
    label: actionTypes.createNewStock,
  });
};

const storeNewStockSessionForm = (formData, newStockSessionId) => {
  const url = `${Base_URL}${Simulate_URL}/${storeNewStockSessionFormURL}`;
  return apiAction({
    url,
    method: 'POST',
    data: {
      newStockSessionId,
      newStockForm: JSON.stringify(formData),
    },
    onSuccess: (res) => newStock(res),
    onFailure: () => console.log(formData),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'New stock simulation Failure',
      Message: 'Failed to create new session for new stock simulation job.',
    }),
    label: actionTypes.storeNewStock,
  });
};

const getNewStockResults = (runID) => {
  const url = `${Base_URL}${Simulate_URL}/new-stock/${runID}`;
  function setNewStockResultData(data) {
    if (data?.error
      && data?.error?.includes('No output is available until the task begins') !== true) {
      return {
        type: actionTypes.setNewStockError,
        payload: null,
      };
    }
    return {
      type: actionTypes.saveNewStockResult,
      payload: data,
    };
  }
  function failedStockReq() {
    console.log('Failed to load new stock simulation results.');
    return {
      type: actionTypes.saveNewStockResult,
      payload: null,
    };
  }
  return apiAction({
    url,
    method: 'GET',
    onSuccess: (res) => setNewStockResultData(res),
    onFailure: () => failedStockReq(),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'New stock simulation Failure',
      Message: 'Failed to load new stock simulation results.',
    }),
    label: actionTypes.getNewStockResults,
  });
};

const getAllSimJobs = (userEmail) => {
  const url = `${Base_URL}${Simulate_URL}`;
  function onResponse(data) {
    return {
      type: actionTypes.getAllSimJobs,
      payload: data,
    };
  }
  return apiAction({
    url,
    method: 'GET',
    params: {
      userEmail,
    },
    onSuccess: onResponse,
    onFailure: () => console.log('Failed to load simulations metadata.'),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Loading failure',
      Message: 'Failed to load user\'s simulations.',
    }),
    label: actionTypes.getAllSimJobs,
  });
};

const deleteSim = (RunIDs, userEmail) => {
  const url = `${Base_URL}${Simulate_URL}`;
  return apiAction({
    url,
    method: 'DELETE',
    data: {
      RunIDs,
    },
    onSuccess: () => getAllSimJobs(userEmail),
    onFailure: () => console.log('Failed to delete simulation job.'),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Deletion Failure',
      Message: 'Failed to delete the simulation job.',
    }),
    label: actionTypes.deleteSimulation,
  });
};

const setLoading = (loadingKey) => ({
  type: actionTypes.setSimLoading,
  payload: loadingKey,
});

export default {
  retrieveSimulation,
  simulate,
  clearSimulationID,
  newStock,
  storeNewStockSessionForm,
  saveNewStockForm,
  storeFormRows,
  getNewStockResults,
  clearNewStockResults,
  getAllNewStockForms,
  clearSimulationResults,
  getAllSimJobs,
  setLoading,
  deleteSim,
  deleteNewStockForm,
  unsetNewStockError,
};
