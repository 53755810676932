/* eslint-disable max-len */
/* eslint-disable arrow-body-style */
/* eslint-disable import/prefer-default-export */
import React, { useEffect, useState } from 'react';
import {
  Checkbox, Input, Select, Tooltip,
} from 'antd';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import {
  LoadingOutlined, CloseCircleOutlined,
  InfoCircleOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import { createKey, getMaterialItem } from './MassUpdateMrpHelpers';
import { convertCost } from '../../../Common/DateAndNumberFunctions';
import ControllerCheckbox from '../ControllerCheckbox';
import calculate from '../../MaterialDetails/components/SummaryComponents/Recommendations/monetaryImpactHelpers';
import { dropdownColumns } from '../../MaterialDetails/components/SummaryComponents/Recommendations/RecommendationsData';
import { getDropdownValues, mrpSettingIsNumber } from '../../MaterialDetails/components/SummaryComponents/Recommendations/RecommendationHelpers';
import styles from './styles.module.scss';
import '../massUpdate.css';

const SAVINGS = 'Savings';
const INVESTMENT = 'Investment';
const NOIMPACT = 'No impact';

const { Option } = Select;

const getNewROP = (newValue, originalValue) => {
  if (newValue) {
    if (newValue.ReorderPoint) {
      return newValue.ReorderPoint;
    }
    if (newValue.ReorderPoint === '') return 0;
  }
  return originalValue.ReorderPoint;
};

const calculateIMForSingleMaterial = (materialData, plant, material,
  originalValues, inputValues) => {
  const key = createKey(plant, material);
  const originalValue = originalValues[key];
  const newValue = inputValues[key];
  const currentROP = originalValue.ReorderPoint;
  const newMLS = newValue ? newValue.MinimumLotSize : originalValue.MinimumLotSize;
  const newROP = getNewROP(newValue, originalValue);
  const currentMLS = originalValue?.MinimumLotSize || originalValue.MinimumLotSize || 0;
  const data = materialData.find((d) => d.PLANT_FACILITY_SAP_ID === `${plant}` && d.MATERIAL_TYPE_SAP_ID === `${material}`);
  if (!data) return null;
  return {
    plant,
    material,
    key,
    impact: calculate(data, newROP, newMLS, currentROP, currentMLS, true),
  };
};

const calculateMI = (materialData, materials, excludeList, plant,
  originalValues, inputValues) => {
  if (materialData.length) {
    const calculatedImpacts = materials
      .filter((m) => !excludeList.includes(m))
      .map((m) => materialData.find((d) => d.PLANT_FACILITY_SAP_ID === `${plant}` && d.MATERIAL_TYPE_SAP_ID === `${m}`))
      .filter((m) => !!m)
      .map((x) => {
        const key = createKey(x.PLANT_FACILITY_SAP_ID, x.MATERIAL_TYPE_SAP_ID);
        const originalValue = originalValues[key];
        const newValue = inputValues[key];
        const currentROP = originalValue.ReorderPoint;
        const newMLS = newValue ? newValue.MinimumLotSize : originalValue.MinimumLotSize;
        const newROP = getNewROP(newValue, originalValue);
        const currentMLS = originalValue?.MinimumLotSize || 0;
        return {
          plant: x.PLANT_FACILITY_SAP_ID,
          material: x.MATERIAL_TYPE_SAP_ID,
          key,
          impact: calculate(x, newROP, newMLS, currentROP, currentMLS, true),
        };
      });
    return calculatedImpacts;
  }
  return [];
};

const updateAll = (value, inputValues, setInputValues, originalValues, excludeKeys, type) => {
  const newValues = _.cloneDeep(inputValues) || {};
  const typeKey = `${type}Populate`;
  Object.keys(originalValues)
    .filter((key) => !excludeKeys.includes(key))
    .forEach((key) => {
      if (newValues?.[key]) { // this has not been tested
        newValues[key] = {
          ...newValues[key],
          [typeKey]: value,
        };
      } else if (value !== originalValues[key][type]) {
        newValues[key] = {
          ...newValues[key],
          [typeKey]: value,
        };
      }
    });
  setInputValues(newValues);
};

const getSelectOptions = (defaultValue, value, type, options, inputValues, setInputValues, key, materialFound) => {
  const onChange = (newInput) => {
    const newInputValues = {
      ...inputValues,
      [key]: {
        ...inputValues?.[key],
        [type]: newInput,
      },
    };
    setInputValues(newInputValues);
  };

  return (
    <Select
      value={value}
      defaultValue={defaultValue}
      style={{ width: '100%' }}
      onChange={onChange}
      className={styles.inputs}
      disabled={!materialFound}
    >
      {
        options?.map((option) => {
          if (option === 'No value' || option === ' ') return null;
          return (
            <Option
              key={option}
              value={option}
              label={option}
            >
              {option}
            </Option>
          );
        })
      }
    </Select>
  );
};
const getSelectOptionsHeader = (options, type, inputValues, setInputValues, originalValues, excludeKeys) => {
  const isDisabled = Object.keys(originalValues).length === 0;
  return (
    <Select
      className={styles.populateInputs}
      placeholder="Input"
      style={{ width: '100%' }}
      onChange={(v) => updateAll(v, inputValues, setInputValues, originalValues, excludeKeys, type)}
      disabled={isDisabled}
    >
      {
        options?.map((option) => {
          if (option === 'No value' || option === ' ') return null;
          return (
            <Option
              key={option}
              value={option}
              label={option}
            >
              {option}
            </Option>
          );
        })
      }
    </Select>
  );
};

const cellRender = (v, type, localDropDowns, plant,
  inputValues, setInputValues, originalValues, excludeKeys) => {
  const shouldRender = !_.isEmpty(originalValues);
  const key = createKey(plant, v.material);
  const defaultValue = originalValues[createKey(plant, v.material)]?.[type];
  const value = inputValues[createKey(plant, v.material)]?.[type];
  const materialFound = !excludeKeys.includes(key);

  if (localDropDowns?.[type]?.length) {
    if (shouldRender) {
      return getSelectOptions(defaultValue, value, type, localDropDowns?.[type], inputValues, setInputValues, key, materialFound);
    }
    return '-';
  }
  const onChange = (newInput) => {
    let inputValue = newInput;
    if (newInput.includes(',')) {
      inputValue = newInput.replaceAll(',', '.');
    }
    const newInputs = {
      ...inputValues,
      [key]: {
        ...inputValues[key],
        [type]: inputValue,
      },
    };
    setInputValues(newInputs);
  };

  const showCorrectValue = (standardValue) => {
    if (!standardValue) return '';
    if (standardValue === '0.000') return '';
    const parseToNumber = Number(standardValue);
    if (typeof parseToNumber === 'number') return Math.round(parseToNumber * 100) / 100;
    return standardValue;
  };

  const input = shouldRender
    ? (
      <Input
        onChange={(e) => onChange(e?.target?.value)}
        value={value}
        defaultValue={showCorrectValue(defaultValue)}
        className={styles.inputs}
        disabled={!materialFound}
        style={{ minWidth: '50px' }}
      />
    )
    : '-';
  return input;
};

const cellHeader = (type, localDropDowns,
  inputValues, setInputValues, originalValues, excludeKeys) => {
  const isDisabled = Object.keys(originalValues).length === 0;
  return (
    localDropDowns?.[type]?.length
      ? getSelectOptionsHeader(localDropDowns?.[type], type, inputValues, setInputValues, originalValues, excludeKeys)
      : (
        <Input
          className={styles.populateInputs}
          placeholder="Input"
          disabled={isDisabled}
          onChange={(e) => updateAll(e?.target?.value, inputValues, setInputValues, originalValues, excludeKeys, type)}
        />
      )
  );
};

const sortOnError = (a, b, plant, originalValues) => {
  const defaultValueA = originalValues[createKey(plant, a.material)]?.errorMessage || '';
  const defaultValueB = originalValues[createKey(plant, b.material)]?.errorMessage || '';
  if (defaultValueA && !defaultValueB) {
    return 1;
  }
  if (defaultValueB && !defaultValueA) return -1;
  return 0;
};

const sortOnColumn = (a, b, plant, originalValues, type) => {
  const defaultValueA = originalValues[createKey(plant, a.material)]?.[type];
  const defaultValueB = originalValues[createKey(plant, b.material)]?.[type];
  return defaultValueA - defaultValueB;
};

const cellWithHeader = (title, type, localDropDowns, inputValues,
  setInputValues, plant, originalValues, excludeKeys,
  mrpDataLoading) => {
  const hasDropdowns = !!localDropDowns[type];
  const sizeToUse = () => {
    if (type === 'MRPGroup' || type === 'MRPController') return 95;
    if (hasDropdowns) return 80;
    if (type === 'RoundingValue') return 90;
    return 'auto';
  };
  return {
    title: cellHeader(type, localDropDowns, inputValues, setInputValues, originalValues, excludeKeys),
    children: [
      {
        title,
        width: sizeToUse(),
        render: (v) => (mrpDataLoading
          ? <LoadingOutlined spin />
          : cellRender(v, type, localDropDowns, plant, inputValues, setInputValues, originalValues, excludeKeys)),
        sorter: (a, b) => {
          return sortOnColumn(a, b, plant, originalValues, type);
        },
      }],
  };
};

const headers = [
  { title: 'MRP Type', type: 'MRPType' },
  { title: 'Lot Size', type: 'LotSize' },
  { title: 'Reorder Point', type: 'ReorderPoint' },
  { title: 'Min Lot Size', type: 'MinimumLotSize' },
  { title: 'Max Stock Level', type: 'MaximumStockLevel' },
  { title: 'Rounding Value', type: 'RoundingValue' },
  { title: 'Fixed Lot Size', type: 'FixedLotSize' },
  { title: 'MRP Group', type: 'MRPGroup' },
  { title: 'MRP Controller', type: 'MRPController' },
  { title: 'Plant Material Status', type: 'PlantMaterialStatus' },
  { title: 'ABC Ind', type: 'ABCIndicator' },
];

const configColumns = (materialData, isLoading, excludeList, setExcludeList, plant,
  materialsToUse, selectedMaterials, originalValues, resetChanges, setResetChanges,
  ready, setReady, setChanges, setRevert, setHasChanges, populateInputs, resetPopulateInputs, calculateMonImp, resetCalculateMonImp, commonOperationsValues, updateExcelValues, updateDropdownValues, importedValues, setImportedValues, showDescription, setShowDescription, hasFetchErrors) => {
  const currencyList = useSelector((state) => state.commonState?.currencyList);
  const dropdownValues = useSelector((state) => state.materialState?.dropdownValues);
  const defaultCurrency = useSelector((state) => state.commonState?.userSettings);

  const [monetaryImpacts, setMonetaryImpacts] = useState([]);
  const [localDropDowns, setLocalDropDowns] = useState([]);

  const [inputValues, setInputValues] = useState({});

  const [overwriteMonetaryImpact, setOverwriteMonetaryImpact] = useState('');
  const [overwriteMonetaryImpactType, setOverwriteMonetaryImpactType] = useState('');

  const updateInputValues = (input) => {
    setInputValues(input);
    setHasChanges(true);
  };

  useEffect(() => {
    if (importedValues.inputs || importedValues.monImps) {
      if (importedValues.monImps) {
        setMonetaryImpacts(importedValues.monImps);
      } if (importedValues.inputs) {
        setInputValues(importedValues.inputs);
      }
      setImportedValues({});
    }
  }, [importedValues]);

  useEffect(() => {
    if (localDropDowns) {
      updateDropdownValues(localDropDowns);
    }
  }, [localDropDowns]);

  const materials = selectedMaterials;

  const getMonetaryImpactsForNonChangedMaterials = () => {
    const temp = [];
    const oldMonImp = JSON.parse(JSON.stringify(monetaryImpacts));
    materials.forEach((material) => {
      if (excludeList.includes(material)) return;
      const index = oldMonImp.findIndex((impacts) => impacts?.material?.toString() === material?.toString());
      if (index >= 0) {
        const impact = oldMonImp[index];
        impact.impact = impact.impact !== '' ? Number.parseFloat(impact.impact) : 0;
        if (impact.monImpType) {
          if (impact.monImpType === INVESTMENT && impact.impact > 0) {
            impact.impact *= -1;
          }
        }
        temp.push(impact);
      } else {
        // const newImpact = calculateIMForSingleMaterial(materialData, plant, material, originalValues, inputValues);
        // temp.push(newImpact);
      }
    });
    return temp;
  };

  const checkIfSchedulingMarginKeyIsNeeded = (toMrp) => {
    toMrp.forEach((mrp) => {
      if (mrp.MRPType && mrp.MRPType === 'PD') {
        mrp.SchedulingMarginKeyFloats = '001';
        mrp.SchedulingMarginKeyFloats_X = true;
      }
    });
    return toMrp;
  };

  useEffect(() => {
    const copy = JSON.parse(JSON.stringify(inputValues));
    const monImps = JSON.parse(JSON.stringify(monetaryImpacts));
    updateExcelValues(copy, monImps, dropdownValues);
  }, [inputValues, monetaryImpacts]);

  useEffect(() => {
    if (ready) {
      setReady(false);
      if (_.isEmpty(inputValues) && _.isEmpty(monetaryImpacts)) {
        console.log('no changes registered');
      } else {
        const changeObject = { MrpBatch: '123' };
        const revertObject = { MrpBatch: '123' };
        const mrpChanges = [];
        const revertChanges = [];
        const changelog = [];
        const revertChangelog = [];

        Object.keys(inputValues).forEach((material) => {
          Object.keys(inputValues[material]).forEach((mrpSetting) => {
            let prev;
            let curr;
            const isDropdown = !mrpSettingIsNumber(mrpSetting);
            if (isDropdown) {
              prev = originalValues?.[material]?.[mrpSetting];
              curr = inputValues?.[material]?.[mrpSetting];
            } else {
              prev = originalValues?.[material]?.[mrpSetting] === '' ? 0 : Number.parseFloat(originalValues?.[material]?.[mrpSetting]);
              curr = inputValues?.[material]?.[mrpSetting] === '' ? 0 : Number.parseFloat(inputValues?.[material]?.[mrpSetting]);
            }
            const same = (prev === curr);
            if (!same) {
              const p = originalValues[material]?.PLANT_FACILITY_SAP_ID;
              const m = originalValues[material]?.MATERIAL_TYPE_SAP_ID?.toString().padStart(8, '0');
              const settingKey = `${mrpSetting}_X`;
              const materialExists = mrpChanges.some(
                (c) => c.Plant === p && c.MaterialNumber === m,
              );
              if (!materialExists) {
                const currentObject = {
                  MaterialNumber: m,
                  Plant: p,
                  [mrpSetting]: curr?.toString() || '',
                  [settingKey]: true,
                };
                const previousObject = {
                  MaterialNumber: m,
                  Plant: p,
                  [mrpSetting]: prev?.toString() || '',
                  [settingKey]: true,
                };
                const revertChangelogObject = {};
                mrpChanges.push(currentObject);
                revertChanges.push(previousObject);
                revertChangelog.push(revertChangelogObject);
              } else {
                const item = mrpChanges.find((c) => c.Plant === p && c.MaterialNumber === m);
                const revertItem = revertChanges.find((c) => c.Plant === p && c.MaterialNumber === m);
                item[mrpSetting] = curr?.toString() || '';
                item[settingKey] = true;
                revertItem[mrpSetting] = prev?.toString() || '';
                revertItem[settingKey] = true;
              }
              changelog.push({
                MaterialNumber: m,
                Plant: p,
                changeType: `${mrpSetting} (MassUpdate)`,
                oldValue: prev,
                newValue: curr,
              });
            }
          });
        });
        const sortedAndFilteredChanges = _.cloneDeep(mrpChanges)
          .sort((a, b) => (parseInt(a.MaterialNumber, 10) - parseInt(b.MaterialNumber, 10)))
          .filter((x) => !excludeList.includes(parseInt(x.MaterialNumber, 10)?.toString()));
        const sortedAndFilteredRevertChanges = _.cloneDeep(revertChanges)
          .sort((a, b) => (parseInt(a.MaterialNumber, 10) - parseInt(b.MaterialNumber, 10)))
          .filter((x) => !excludeList.includes(parseInt(x.MaterialNumber, 10)?.toString()));

        changeObject.to_Mrp = checkIfSchedulingMarginKeyIsNeeded(sortedAndFilteredChanges);
        revertObject.to_Mrp = sortedAndFilteredRevertChanges;
        changeObject.monetaryImpacts = getMonetaryImpactsForNonChangedMaterials();
        changeObject.changelog = changelog;
        setChanges(changeObject);
        setRevert(revertObject);
      }
    }
  }, [ready]);

  useEffect(() => {
    if (commonOperationsValues) {
      setInputValues(commonOperationsValues);
      setHasChanges(true);
    }
  }, [commonOperationsValues]);

  useEffect(() => {
    if (resetChanges) {
      setResetChanges(false);
      setInputValues({});
      setMonetaryImpacts([]);
      setOverwriteMonetaryImpact('');
      setOverwriteMonetaryImpactType('');
      setChanges({});
    }
  }, [resetChanges]);

  useEffect(() => {
    if (dropdownColumns && dropdownValues) {
      getDropdownValues(dropdownColumns, dropdownValues, setLocalDropDowns);
    }
  }, [dropdownColumns, dropdownValues]);

  const excludeKeys = excludeList.map((m) => createKey(plant, m));

  const hasOverwriteError = () => {
    if (overwriteMonetaryImpact !== 0 && overwriteMonetaryImpact !== '0' && overwriteMonetaryImpact !== '' && (overwriteMonetaryImpactType === NOIMPACT || overwriteMonetaryImpactType === '')) {
      return true;
    } if ((overwriteMonetaryImpact === '' || overwriteMonetaryImpact === '0' || overwriteMonetaryImpact === '') && (overwriteMonetaryImpactType !== NOIMPACT && overwriteMonetaryImpactType !== '')) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (populateInputs) {
      if (!hasOverwriteError()) {
        Object.entries(inputValues).forEach((wholeArray) => {
          Object.entries(wholeArray[1]).forEach((entries) => {
            if (entries[0].includes('Populate')) {
              const key = entries[0].replace('Populate', '');
              // eslint-disable-next-line prefer-destructuring
              inputValues[wholeArray[0]][key] = entries[1];
              delete inputValues[wholeArray[0]][entries[0]];
            }
          });
        });
        updateInputValues(inputValues);

        if (overwriteMonetaryImpact || overwriteMonetaryImpactType) {
          const overwrites = [];
          Object.values(originalValues).forEach((material) => {
            const key = createKey(material.PLANT_FACILITY_SAP_ID, material.MATERIAL_TYPE_SAP_ID);
            const impact = {
              impact: overwriteMonetaryImpact || 0,
              plant: material.PLANT_FACILITY_SAP_ID,
              material: material.MATERIAL_TYPE_SAP_ID,
              key,
              edited: true,
            };
            if (overwriteMonetaryImpactType) {
              impact.monImpType = overwriteMonetaryImpactType;
            }
            overwrites.push(impact);
          });
          setMonetaryImpacts(overwrites);
        }
      }
      resetPopulateInputs();
    }
  }, [populateInputs]);

  useEffect(() => {
    if (calculateMonImp) {
      setMonetaryImpacts(calculateMI(materialData, materials, excludeList, plant, originalValues, inputValues));
      resetCalculateMonImp();
    }
  }, [calculateMonImp]);

  return [
    {
      title: '',
      children: [
        {
          title: <ControllerCheckbox
            // eslint-disable-next-line react/jsx-indent-props
            excludeList={excludeList}
            // eslint-disable-next-line react/jsx-indent-props
            setExcludeList={setExcludeList}
            // eslint-disable-next-line react/jsx-indent-props
            materials={materials}
          />,
          dataIndex: 'include',
          width: '40px',
          render: (_v, r) => {
            const material = getMaterialItem(materialData, plant, r.material);
            const handleChange = (val) => {
              if (val.target?.checked) {
                setExcludeList(excludeList.filter((item) => item !== r.material));
              } else {
                setExcludeList([...excludeList, r.material]);
              }
            };
            return (
              <Tooltip title={material ? null : 'Material does not exist'}>
                <Checkbox
                  defaultChecked={!!material}
                  checked={!excludeList?.includes(r.material)}
                  disabled={!material}
                  onChange={handleChange}
                />
              </Tooltip>
            );
          },
        },
      ],
    },
    (hasFetchErrors ? {
      title: '',
      children: [{
        title: 'Status',
        width: 70,
        sorter: (a, b) => {
          return sortOnError(a, b, plant, originalValues);
        },
        defaultSortOrder: 'descend',
        render: (v) => {
          const material = originalValues[createKey(plant, v.material)];
          return <div className={styles.statusField}><Tooltip title={material?.errorMessage ? material?.errorMessage : 'Ready to update'}>{material?.errorMessage ? <CloseCircleOutlined style={{ color: '#BB0000' }} /> : <CheckCircleOutlined style={{ color: '#00BB00' }} />}</Tooltip></div>;
        },
      }],
    } : {}),
    {
      title: '',
      children: [{
        title: 'Plant',
        dataIndex: 'plant',
        render: () => plant,
        width: 55,
      }],
    },
    {
      // eslint-disable-next-line react/jsx-one-expression-per-line
      title: <div className={styles.materialCheckbox}><Checkbox defaultChecked={showDescription} onChange={(val) => setShowDescription(val.target?.checked)} /><Tooltip title="Show material description"><InfoCircleOutlined /></Tooltip></div>,
      children: [{
        title: 'Material',
        width: 85,
        dataIndex: 'material',
        sorter: (a, b) => {
          return a.material - b.material;
        },
        render: (v) => {
          return <Tooltip title={originalValues[createKey(plant, v)]?.EDAM_MATERIAL_TYPE_NM}><div style={{ textAlign: 'right', width: '100%' }}>{originalValues[createKey(plant, v)]?.MATERIAL_TYPE_SAP_ID}</div></Tooltip>;
        },
      }],
    },
    (showDescription ? {
      title: '',
      children: [{
        title: 'Material description',
        width: 400,
        dataIndex: 'material',
        sorter: (a, b) => {
          return a.material - b.material;
        },
        render: (v) => <div className={styles.materialDescription}>{originalValues[createKey(plant, v)]?.EDAM_MATERIAL_TYPE_NM}</div>,
      }],
    } : {}),
    {
      title: '',
      children: [{
        title: 'Max crit',
        width: 50,
        sorter: (a, b) => {
          return a.material - b.material;
        },
        render: (v) => <div style={{ textAlign: 'right', width: '100%' }}>{originalValues[createKey(plant, v.material)]?.CC_MAX_CRITICALITY || '-'}</div>,
      }],
    },
    {
      title: '',
      children: [{
        title: 'Fair price',
        width: 70,
        sorter: (a, b) => {
          return a.material - b.material;
        },
        render: (v) => {
          const material = originalValues[createKey(plant, v.material)];
          if (!material) return '-';
          return (
            <Tooltip
              title={(
                <div className={styles.openDocsTooltip}>
                  <span>
                    Fair price:
                    {' '}
                    {material?.FAIR_PRICE}
                    {' '}
                    {material?.COMPANY_CURRENCY_SAP_CD}
                  </span>
                  <span>
                    Market Price:
                    {' '}
                    {material?.MARKET_PRICE}
                    {' '}
                    {material?.COMPANY_CURRENCY_SAP_CD}
                  </span>
                  <span>
                    Moving average price:
                    {' '}
                    {material?.CC_UNIT_COST_AMT}
                    {' '}
                    {material?.COMPANY_CURRENCY_SAP_CD}
                  </span>
                </div>
              )}
            >
              <div style={{ width: '100%', textAlign: 'right' }}>
                {material?.FAIR_PRICE?.toFixed(0)}
              </div>
            </Tooltip>
          );
        },
      }],
    },
    {
      title: '',
      children: [{
        title: 'Total Stock',
        width: 70,
        render: (v) => <div style={{ textAlign: 'right', width: '100%' }}>{originalValues?.[createKey(plant, v.material)]?.TOT_STOCK || '-'}</div>,
        sorter: (a, b) => {
          const materialA = originalValues?.[createKey(plant, a.material)];
          const materialB = originalValues?.[createKey(plant, b.material)];
          return materialA?.TOT_STOCK - materialB?.TOT_STOCK;
        },
      }],
    },
    {
      title: '',
      children: [{
        title: 'Open Docs',
        width: 70,
        render: (v) => {
          const material = originalValues?.[createKey(plant, v.material)];
          return (
            <Tooltip
              title={material?.docCount ? (
                <div className={styles.openDocsTooltip}>
                  <span>
                    Open RD Qty:
                    {' '}
                    {material?.OPEN_RD_QTY}
                  </span>
                  <span>
                    Open PR Qty:
                    {' '}
                    {material?.OPEN_PR_QTY}
                  </span>
                  <span>
                    Open PO Qty:
                    {' '}
                    {material?.CC_OPEN_PO_QTY}
                  </span>
                  <span>
                    Open Res Qty:
                    {' '}
                    {material?.CC_OPEN_RESERVATION_QTY}
                  </span>
                  <span>
                    Open STO Qty:
                    {' '}
                    {material?.ST_CC_OPEN_PO_QTY}
                  </span>
                  <span>
                    Open STR Qty:
                    {' '}
                    {material?.ST_OPEN_PR_QTY}
                  </span>
                </div>
              ) : <span>No open docs on this material</span>}
            >
              <div style={{ textAlign: 'right', width: '100%' }}>{material?.docCount || '-'}</div>
            </Tooltip>
          );
        },
        sorter: (a, b) => {
          const materialA = originalValues?.[createKey(plant, a.material)];
          const materialB = originalValues?.[createKey(plant, b.material)];
          return materialA?.docCount - materialB?.docCount;
        },
      }],
    },
    ...headers.map((h) => cellWithHeader(h.title, h.type, localDropDowns, inputValues, updateInputValues, plant, originalValues, excludeKeys, isLoading)),
    {
      title: (
        <Input
          onChange={(e) => {
            const { value } = e.target;
            const regex = /^[0-9.,]+$/;
            if (e.target && (regex.test(value) || value === '')) {
              setOverwriteMonetaryImpact(value);
            }
          }}
          defaultValue=""
          className={styles.populateInputs}
          placeholder="Input"
          value={overwriteMonetaryImpact}
          disabled={Object.keys(originalValues).length === 0}
        />
      ),
      children: [
        {
          title: 'Monetary Impact',
          width: 85,
          render: (v) => {
            const impact = monetaryImpacts.find((i) => i.plant?.toString() === plant?.toString() && i.material?.toString() === v.material?.toString());
            const valueToUse = () => {
              if (impact?.impact === '') {
                return '';
              } if (impact?.edited) return impact.impact;
              if (impact?.impact) {
                return convertCost(
                  Math.abs(Number.parseFloat(impact?.impact)),
                  defaultCurrency?.DEFAULT_CURRENCY,
                  currencyList,
                );
              }
              return '0';
            };

            const materialFound = materialsToUse.length > 0 ? materialsToUse.some((id) => id === v.material) : true;
            return (
              <div className={styles.monImpContainer}>
                <Input
                  value={valueToUse(impact)}
                  className={styles.inputs}
                  disabled={!materialFound}
                  pattern="[0-9.,]"
                  onChange={(e) => {
                    const { value } = e.target;
                    const regex = /^[0-9]*\.?[0-9]*$/;
                    if (e.target && (regex.test(value) || value === '')) {
                      const index = monetaryImpacts.findIndex((impacts) => impacts.material === impact?.material);
                      if (index < 0) {
                        const newImpact = {
                          impact: value,
                          key: createKey(plant, v.material),
                          plant,
                          material: v.material,
                          edited: true,
                          monImpType: 'No impact',
                        };
                        if ((value === '0' || value === '' || value === 0) && newImpact.monImpType !== 'No impact') {
                          newImpact.error = true;
                        } else if (newImpact.monImpType === 'No impact' && value !== '0' && value !== '' && value !== 0) {
                          newImpact.error = true;
                        } else {
                          newImpact.error = false;
                        }
                        const allImpacts = [...monetaryImpacts, newImpact];
                        setMonetaryImpacts(allImpacts);
                      } else {
                        const temp = [...monetaryImpacts];
                        const newImpact = temp[index];
                        newImpact.impact = value;
                        newImpact.edited = true;
                        if ((value === '0' || value === '' || value === 0) && newImpact.monImpType !== 'No impact') {
                          newImpact.error = true;
                        } else if (newImpact.monImpType === 'No impact' && value !== '0' && value !== '' && value !== 0) {
                          newImpact.error = true;
                        } else {
                          newImpact.error = false;
                        }
                        temp[index] = newImpact;
                        setMonetaryImpacts(temp);
                      }
                    }
                    setHasChanges(true);
                  }}
                  defaultValue="0"
                />
              </div>
            );
          },
        },
      ],
    },
    {
      title: (
        <Select
          style={{ width: '100%' }}
          onChange={(value) => setOverwriteMonetaryImpactType(value)}
          placeholder="Input"
          status={hasOverwriteError() ? 'error' : ''}
          disabled={Object.keys(originalValues).length === 0}
          className={hasOverwriteError() ? styles.inputError : {}}
        >
          {(!overwriteMonetaryImpact || overwriteMonetaryImpact === 0 || overwriteMonetaryImpact === '0')
            && (
            <Option
              key={NOIMPACT}
              value={NOIMPACT}
              label={NOIMPACT}
            >
              No impact
            </Option>
            )}
          {overwriteMonetaryImpact && overwriteMonetaryImpact !== 0 && overwriteMonetaryImpact !== '0' && (
          <>
            <Option
              key={SAVINGS}
              value={SAVINGS}
              label={SAVINGS}
            >
              Savings
            </Option>
            <Option
              key={INVESTMENT}
              value={INVESTMENT}
              label={INVESTMENT}
            >
              Investment
            </Option>
          </>
          )}
        </Select>
      ),
      children: [
        {
          title: 'Monetary Impact Type',
          width: 100,
          render: (v) => {
            const impact = monetaryImpacts.find((i) => i.plant?.toString() === plant?.toString() && i.material?.toString() === v.material?.toString());

            const getCorrectText = (number) => {
              if (number < 0) return INVESTMENT;
              if (number > 0) return SAVINGS;
              return NOIMPACT;
            };

            const valueToUse = () => {
              if (impact?.monImpType) {
                return impact.monImpType;
              }
              if (impact?.edited) {
                const correctImpact = calculateIMForSingleMaterial(materialData, plant, v.material, originalValues, inputValues);
                return getCorrectText(correctImpact.impact);
              } if (impact?.impact) {
                return getCorrectText(impact?.impact);
              }
              return NOIMPACT;
            };

            const checkIfError = (monImp, monImpType) => {
              if (monImpType === NOIMPACT) {
                if (monImp === '0' || monImp === '') return false;
                return true;
              }
              if (monImp === '0' || monImp === '') return true;
              return false;
            };

            const updateMonetaryImpactsType = (value) => {
              const index = monetaryImpacts.findIndex((impacts) => impacts.material === impact?.material);
              const temp = [...monetaryImpacts];
              if (index >= 0) {
                temp[index].monImpType = value;
                temp[index].error = checkIfError(temp[index].impact, value);
              } else {
                const newImpact = {
                  impact: '0',
                  key: createKey(plant, v.material),
                  plant,
                  material: v.material,
                  monImpType: value,
                };
                temp.push(newImpact);
              }
              setMonetaryImpacts(temp);
            };

            const materialFound = materialsToUse.length > 0 ? materialsToUse.some((id) => id === v.material) : true;

            return (
              <div className={styles.monImpContainer}>
                <Select
                  value={valueToUse()}
                  style={{ width: '100%' }}
                  onChange={(value) => updateMonetaryImpactsType(value)}
                  className={impact && impact?.error ? styles.inputError : {}}
                  status={impact && impact?.error ? 'error' : ''}
                  disabled={!materialFound}
                >
                  {(!impact || (impact.impact === '0' || impact.impact === '')) && (
                  <Option
                    key={NOIMPACT}
                    value={NOIMPACT}
                    label={NOIMPACT}
                  >
                    No impact
                  </Option>
                  )}
                  {(impact && (impact.impact !== '0' && impact.impact !== '')) && (
                    <>
                      <Option
                        key={SAVINGS}
                        value={SAVINGS}
                        label={SAVINGS}
                      >
                        Savings
                      </Option>
                      <Option
                        key={INVESTMENT}
                        value={INVESTMENT}
                        label={INVESTMENT}
                      >
                        Investment
                      </Option>
                    </>
                  )}
                </Select>
              </div>
            );
          },
        },
      ],
    },
    {
      children: [{
        title: 'Material Memo',
        render: (v) => {
          const memo = originalValues[createKey(plant, v.material)]
            ?.to_MrpLongText
            ?.results
            ?.find((r) => r.TextId)
            ?.LongText;
          if (memo) return <Tooltip title={memo}><div className={styles.memo}>{memo}</div></Tooltip>;
          return <Tooltip title="Material does not have a memo"><CloseCircleOutlined /></Tooltip>;
        },
      }],
    },
  ].filter((e) => Object.keys(e).length > 0);
};

export {
  headers, configColumns, calculateMI,
};
