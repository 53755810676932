import React, { useEffect, useState } from 'react';
import {
  Col, Row, Form,
} from 'antd';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import SimulationForm from '../../Common/Simulation/SimulationForm';
import MaterialSelector from '../../Common/MaterialSelector';
import MultiItemSelector from '../../Common/MultiItemSelector';

const ScenarioForm = (
  {
    plant, handleChange, clearPlant, searchMMR, setSearchMMR, handleMMRSearch, onFinish,
  },
) => {
  const plants = useSelector((state) => state.commonState.plants);
  const [error, setError] = useState(false);

  useEffect(() => {
    setError(!(searchMMR?.length > 0));
  }, [searchMMR]);

  return (
    <>
      <Form
        labelCol={{ span: 5 }}
        size="small"
      >
        <Row gutter={[24]}>
          <Col span={12}>
            <Form.Item label="Plant">
              <MultiItemSelector
                size="small"
                placeHolder="Select Plant"
                selectedValues={plant}
                handleChange={handleChange}
                clear={clearPlant}
                items={plants
                  && plants.map((x) => (
                    { id: x.PLANT_FACILITY_SAP_ID, name: x.PLANT_FACILITY_SAP_NM }
                  ))}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Material" style={{ margin: '0px' }}>
              <MaterialSelector
                size="small"
                searchMMR={searchMMR}
                setSearchMMR={setSearchMMR}
                handleMMRSearch={handleMMRSearch}
              />
              {error && <span style={{ color: '#ff4d4f' }}>Please include at least one Material</span>}
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <SimulationForm
        onFinish={onFinish}
        twoColumns
        labelCol={{
          span: 5,
        }}
      />
    </>
  );
};
export default ScenarioForm;
ScenarioForm.defaultProps = {
  plant: null,
};

ScenarioForm.propTypes = {
  plant: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  clearPlant: PropTypes.func.isRequired,
  searchMMR: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSearchMMR: PropTypes.func.isRequired,
  handleMMRSearch: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
};
