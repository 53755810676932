import React from 'react';
import { Spin } from 'antd';

export default function LoadingIndicator() {
  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
    >
      <Spin tip="Loading..." />
    </div>
  );
}
