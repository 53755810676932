import React, { useEffect, useState } from 'react';
import { Button, Menu, Modal } from 'antd';
import _ from 'lodash';
import {
  DesktopOutlined,
  TableOutlined,
  DatabaseOutlined,
  UnorderedListOutlined,
  SettingOutlined,
  LineChartOutlined,
  MenuUnfoldOutlined,
  QuestionOutlined,
  ToolOutlined,
  SearchOutlined,
  ReconciliationOutlined,
  BorderlessTableOutlined,
  FilterOutlined,
  DollarCircleOutlined,
  RocketOutlined,
} from '@ant-design/icons';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../actions';
import './Menu.css';
import QuickSearchMenu from './QuickSearchMenu';
import hasAuthenticatedRole from '../Common/AuthFunctions';
import { listModes } from '../Common/GlobalConstants';

export default function MainMenu({ isProd }) {
  const location = useLocation().pathname;
  const dispatch = useDispatch();
  const defaultPlants = useSelector((state) => state.commonState.userSettings?.DEFAULT_PLANTS);
  const defaultCCs = useSelector((state) => state.commonState.userSettings?.DEFAULT_COMPANY_CODES);
  const userSettings = useSelector((state) => state.commonState.userSettings);
  const user = useSelector((state) => state.authState?.user);
  const [hasConfigAccess, setHasConfigAccess] = useState(false);
  const [hasViewerAccess, setHasViewerAccess] = useState(false);
  const [quicksearchVisible, setQuicksearchVisible] = useState(false);
  const [resetSearch, setResetSearch] = useState(false);

  useEffect(() => {
    if (user?.idTokenClaims?.roles) {
      const userHasAuthenticatedRole = hasAuthenticatedRole(
        user.idTokenClaims.roles,
        process.env.REACT_APP_CONFIG_ACCESS,
      );
      setHasConfigAccess(userHasAuthenticatedRole);
      const userHasViewerAccess = hasAuthenticatedRole(
        user.idTokenClaims.roles,
        process.env.REACT_APP_VIEW_PRICES,
      );
      setHasViewerAccess(userHasViewerAccess);
    }
  }, [user?.idTokenClaims, user?.idTokenClaims?.roles]);

  const clearMaterialState = (mode) => {
    if (mode === listModes.matList) {
      dispatch(allActions.MaterialListActions.clearMaterialStoreState());
      dispatch(allActions.MaterialListActions.selectView(userSettings?.DEFAULT_USER_VIEW));
    } else if (mode === listModes.mmList) {
      dispatch(allActions.MMActions.clearMMStoreState());
      dispatch(allActions.MaterialListActions.selectView(userSettings?.DEFAULT_MM_USER_VIEW));
    }
  };

  const history = useHistory();
  const [quickSearchText, setQuickSearchText] = useState([]);
  const [searchMMR, setSearchMMR] = useState([]);
  const [selectedPlants, setSelectedPlants] = useState([]);
  const [selectedCCs, setSelectedCCs] = useState([]);
  const [funcLocations, setFuncLocations] = useState([]);
  const [storageBins, setStorageBins] = useState([]);
  const [contractNos, setContractNos] = useState([]);
  const [allMaterials, setAllMaterials] = useState(false);
  const [error, setError] = useState({
    material: false,
    contractNo: false,
  });
  const [searchMenuOpened, setSearchMenuOpened] = useState(false);
  const [searchOperators, setSearchOperators] = useState({
    keywords: 'Contains',
    funcLoc: 'EqualTo',
    storageBin: 'EqualTo',
    contractNos: 'EqualTo',
    plant: 'EqualTo',
    companyCode: 'EqualTo',
    material: 'EqualTo',
  });

  const handleOk = () => {
    if ((defaultPlants?.length > 0 && !_.isEqual(selectedPlants?.sort(), defaultPlants?.sort()))
      || (defaultCCs?.length > 0 && !_.isEqual(selectedCCs?.sort(), defaultCCs?.sort()))) {
      dispatch(allActions.CommonActions.setUseDefaultSettings(false));
    }
    setQuicksearchVisible(false);
    const plantList = selectedPlants?.filter((x) => typeof JSON.parse(x) !== 'object');
    dispatch(allActions.CommonActions.setQuickSearchObject({
      quickSearchText,
      searchMMR,
      selectedPlants: plantList,
      selectedCCs,
      funcLocations,
      storageBins,
      contractNos,
      searchOperators,
      allMaterialsInContract: allMaterials,
    }));
    history.push('/material-list');
  };

  const checkForError = () => Object.values(error).some((item) => item);

  useEffect(() => {
    setSearchMenuOpened(quicksearchVisible);
  }, [quicksearchVisible]);

  return (
    <>
      <Modal
        className="quickSearchModal"
        wrapClassName="quickSearchModalPlacement"
        title="Quick Search"
        onOk={handleOk}
        onCancel={() => setQuicksearchVisible(false)}
        visible={quicksearchVisible}
        okText="Search"
        cancelText="Cancel"
        okButtonProps={{ disabled: error }}
        footer={[
          <Button key="ResetFlds" type="primary" onClick={() => setResetSearch(true)}>Reset all fields</Button>,
          <Button key="Search" type="primary" onClick={handleOk} disabled={checkForError()}>Search</Button>,
          <Button key="Cancel" onClick={() => setQuicksearchVisible(false)}>Cancel</Button>,
        ]}
      >
        <QuickSearchMenu
          searchMenuOpened={searchMenuOpened}
          setQuickSearchText={setQuickSearchText}
          quickSearchText={quickSearchText}
          searchMMR={searchMMR}
          setSearchMMR={setSearchMMR}
          selectedPlants={selectedPlants}
          setSelectedPlants={setSelectedPlants}
          selectedCCs={selectedCCs}
          setSelectedCCs={setSelectedCCs}
          funcLocations={funcLocations}
          setFuncLocations={setFuncLocations}
          storageBins={storageBins}
          setStorageBins={setStorageBins}
          contractNos={contractNos}
          setContractNos={setContractNos}
          error={error}
          setError={setError}
          searchOperators={searchOperators}
          setSearchOperators={setSearchOperators}
          resetSearch={resetSearch}
          setResetSearch={setResetSearch}
          allMaterials={allMaterials}
          setAllMaterials={setAllMaterials}
        />
      </Modal>
      <Menu
        selectedKeys={[location]}
        defaultSelectedKeys={['/dashboard']}
        mode="vertical"
        theme="dark"
        style={{ backgroundColor: isProd ? 'var(--prod-color)' : 'var(--non-prod-color)' }}
      >
        <Menu.Item key="quicksearch" icon={<SearchOutlined />} onClick={() => setQuicksearchVisible(true)} className={isProd ? 'prodItem' : 'nonProdItem'}>
          Quick Search
        </Menu.Item>
        <Menu.Item key="/dashboard" icon={<DesktopOutlined />} className={isProd ? 'prodItem' : 'nonProdItem'}>
          <NavLink to="/dashboard">Dashboard</NavLink>
        </Menu.Item>
        <Menu.Item key="/material-list" icon={<TableOutlined />} onClick={() => clearMaterialState(listModes.matList)} className={isProd ? 'prodItem' : 'nonProdItem'}>
          <NavLink to="/material-list">Material List</NavLink>
        </Menu.Item>
        <Menu.Item
          key="/material-master"
          icon={<BorderlessTableOutlined />}
          onClick={() => clearMaterialState(listModes.mmList)}
          className={isProd ? 'prodItem' : 'nonProdItem'}
        >
          <NavLink to="/material-master">Material Master</NavLink>
        </Menu.Item>
        <Menu.Item
          key="/work-lists"
          icon={
            <UnorderedListOutlined />
            }
          className={isProd ? 'prodItem' : 'nonProdItem'}
        >
          <NavLink to="/work-lists">Dynamic Work Lists</NavLink>
        </Menu.Item>
        <Menu.Item key="/static-work-lists" icon={<DatabaseOutlined />} className={isProd ? 'prodItem' : 'nonProdItem'}>
          <NavLink to="/static-work-lists">Static Work Lists</NavLink>
        </Menu.Item>
        <Menu.Item key="/simulation/new-stock" icon={<LineChartOutlined />} className={isProd ? 'prodItem' : 'nonProdItem'}>
          <NavLink to="/simulation/new-stock">New Stock Addition</NavLink>
        </Menu.Item>
        {/* <Menu.Item key="/simulation/scenario" disabled icon={<DotChartOutlined />}>
          <NavLink to="/simulation/scenario">Scenario Simulation</NavLink>
        </Menu.Item> */}
        <Menu.Item key="/simulations" icon={<MenuUnfoldOutlined />} className={isProd ? 'prodItem' : 'nonProdItem'}>
          <NavLink to="/simulations">Simulation Results</NavLink>
        </Menu.Item>
        <Menu.Item key="/mass-update" icon={<RocketOutlined />} className={isProd ? 'prodItem' : 'nonProdItem'}>
          <NavLink to="/mass-update">Mass Update</NavLink>
        </Menu.Item>
        <Menu.Item key="/monetary-impact" icon={<DollarCircleOutlined />} className={isProd ? 'prodItem' : 'nonProdItem'}>
          <NavLink to="/monetary-impact">Monetary Impact</NavLink>
        </Menu.Item>
        <Menu.Item key="/settings" icon={<SettingOutlined />} className={isProd ? 'prodItem' : 'nonProdItem'}>
          <NavLink to="/settings">Settings</NavLink>
        </Menu.Item>
        {/* <Menu.Item key="/feature-timeline" icon={<PullRequestOutlined />}>
          <NavLink to="/feature-timeline">Feature Timeline</NavLink>
        </Menu.Item> */}
        {hasConfigAccess && (
          <Menu.Item key="/config" icon={<ToolOutlined />} className={isProd ? 'prodItem' : 'nonProdItem'}>
            <NavLink to="/config">Admin Settings</NavLink>
          </Menu.Item>
        )}
        {hasViewerAccess
          && (
            <Menu.Item key="/config-view" icon={<FilterOutlined />} className={isProd ? 'prodItem' : 'nonProdItem'}>
              <NavLink to="/config-view">Configuration Settings</NavLink>
            </Menu.Item>
          )}
        <Menu.Item key="IOTA-help-center" icon={<QuestionOutlined />} className={isProd ? 'prodItem' : 'nonProdItem'}>
          <a target="_blank" href="https://statoilsrm.sharepoint.com/sites/IOTAHelpcenter" rel="noreferrer">
            <span>IOTA Help center</span>
          </a>
        </Menu.Item>
        {hasConfigAccess && (
          <Menu.Item key="/test-center" icon={<ReconciliationOutlined />} className={isProd ? 'prodItem' : 'nonProdItem'}>
            <NavLink to="/test-center">Test Center</NavLink>
          </Menu.Item>
        )}
      </Menu>
    </>
  );
}
