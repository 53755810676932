import { Row, Card } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import allActions from '../../../../../../actions';
import SimulationForm from '../../../../../Common/Simulation/SimulationForm';
import { formatMaterialNumber } from '../../../../../Common/DateAndNumberFunctions';
import { SIM_TYPES } from '../../../../../Common/GlobalConstants';
import getInitialValues from '../../../../../Common/Simulation/getInitialValues';
import Loading from '../../../../../Common/Loading';
import SimulationResults from './SimulationResults';
import { retrieveSimulations } from '../../../../../../actions/SimulationDomain/LoadingKeys';

export default function Simulation({ onClose, setSimulatedValues: returnSimulatedValues }) {
  const dispatch = useDispatch();
  const { plant, material } = useParams();
  const user = useSelector((state) => state.authState?.user);
  const defaultCurrency = useSelector((state) => state.commonState?.userSettings?.DEFAULT_CURRENCY);
  const currencyList = useSelector((state) => state.commonState?.currencyList);
  const userEmail = useSelector((state) => state.authState.user?.account?.username);

  // values to build from
  const [initialValues, setInitialValues] = useState();
  const materialData = useSelector((state) => state.materialDetailsState.material);
  const mrpSettings = useSelector((state) => state.materialDetailsState.mrpSettings);

  useEffect(() => {
    if (materialData && currencyList) {
      const materialFocusedPlant = materialData.filter(
        (mat) => mat.PLANT_FACILITY_SAP_ID === plant
          && mat.MATERIAL_TYPE_SAP_ID === material,
      )[0];
      const mrpFocusedPlant = mrpSettings.find(
        (mat) => mat.Plant === plant
          && formatMaterialNumber(mat.MaterialNumber) === material,
      );
      if (materialFocusedPlant) {
        setInitialValues(
          getInitialValues(materialFocusedPlant, mrpFocusedPlant),
        );
      }
    }
  }, [materialData, defaultCurrency, currencyList, mrpSettings]);

  const onFinish = (values, hasChanged) => {
    dispatch(allActions.SimulationActions.setLoading(retrieveSimulations));
    dispatch(allActions.SimulationActions.clearSimulationID());
    const userValues = {};
    const currentValues = {};
    Object.keys(hasChanged).forEach((change) => {
      if (hasChanged[change]) {
        userValues[change] = values[change];
      }
      if (initialValues[change] !== '' && initialValues[change] !== undefined) {
        currentValues[change] = initialValues[change];
      }
    });
    dispatch(allActions.SimulationActions.simulate(
      plant, [material], userValues, userEmail,
      SIM_TYPES.SINGLE_MATERIAL, user.uniqueId, currentValues,
    ));
  };

  return (
    <>
      <Row>
        <Card title="Simulation" style={{ width: '100%' }}>
          {initialValues
            ? (
              <SimulationForm
                onFinish={onFinish}
                labelCol={{ span: 8 }}
                initialValues={initialValues}
              />
            )
            : <Loading />}
        </Card>
      </Row>
      <SimulationResults
        onClose={onClose}
        returnSimulatedValues={returnSimulatedValues}
      />
    </>
  );
}

Simulation.propTypes = {
  onClose: PropTypes.func.isRequired,
  setSimulatedValues: PropTypes.func.isRequired,
};
