import React, { useEffect, useState } from 'react';
import {
  Table, Tag, Button, Tooltip, Input,
} from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import MultiItemSelector from '../../Common/MultiItemSelector';
import allActions from '../../../actions/index';
import './helpers/ConfigTable.css';

const PlantGroupConfig = () => {
  const dispatch = useDispatch();
  const [selectedPlants, setSelectedPlants] = useState({});
  const plantList = useSelector((state) => state.commonState.plants);
  const dataSource = useSelector((state) => state.commonState.plantGroups);
  const user = useSelector((state) => state.authState?.user);
  const [plantGroups, setPlantGroups] = useState({});
  const [visible, setModalVisible] = useState(false);
  const [confirmVisible, setConfirmModalVisible] = useState(false);
  const [title, setTitle] = useState('');
  const [modalContent, setModalContent] = useState(<></>);
  const [onOk, setOnOk] = useState(null);
  const [newGrpPlants, setNewGrpPlants] = useState([]);
  const [newGroup, setNewGroup] = useState({
    name: '',
    plants: [],
  });
  const [okButtonDisabled, setOkButtonDisabled] = useState(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [errText, setErrText] = useState(null);
  const [editName, setEditName] = useState(false);
  const [newName, setNewName] = useState(null);

  useEffect(() => {
    const tempList = {};
    const tempSelectedList = {};
    dataSource?.forEach((group) => {
      tempList[group?.key] = [...plantList];
      tempSelectedList[group?.key] = group?.PlantList;
    });
    setPlantGroups(tempList);
    if (dataSource?.length) setSelectedPlants(tempSelectedList);
    if (plantList?.length) setNewGrpPlants([...plantList]);
  }, [dataSource, plantList]);

  useEffect(() => {
    if (!newGroup
      || !newGroup?.name
      || newGroup.name === ''
      || !newGroup?.plants?.length
      || errText) {
      setOkButtonDisabled(true);
      return;
    }
    setOkButtonDisabled(false);
  }, [newGroup]);

  const getPlantName = (plantID) => {
    const name = plantList?.find((x) => x.PLANT_FACILITY_SAP_ID === plantID)?.PLANT_FACILITY_SAP_NM;
    if (!name) return 'title';
    return name;
  };

  const changeSelectedPlants = (event, groupID) => {
    if (!groupID) return;
    setSelectedPlants((prev) => ({
      ...prev,
      [groupID]: event.map((e) => e?.key),
    }));
  };

  const unSelectOption = (e, value, groupID, isNewGrp) => {
    if (isNewGrp) {
      const pList = newGroup?.plants?.filter((x) => !(x === value));
      setNewGroup((prev) => ({
        ...prev,
        plants: pList,
      }));
      return;
    }
    const pList = selectedPlants?.[groupID]?.filter((x) => !(x === value));
    setSelectedPlants((prev) => ({
      ...prev,
      [groupID]: pList,
    }));
  };

  const pasteValue = (event, groupID, isNewGrp) => {
    if (!groupID && !isNewGrp) return;
    event.preventDefault();
    const pastedText = event.clipboardData.getData('text');
    const splitText = pastedText && pastedText.split('\r\n').filter((s) => s.length > 0);
    if (!splitText || splitText.length < 1) return;
    let newPlants;
    if (isNewGrp) {
      newPlants = [...new Set([...newGroup?.plants, ...splitText])];
    } else {
      newPlants = [...new Set([...selectedPlants?.[groupID], ...splitText])];
    }
    const filteredPlants = newPlants.filter(
      (val) => plantList?.some((plant) => plant.PLANT_FACILITY_SAP_ID === val),
    );
    if (isNewGrp) {
      setNewGroup({
        ...newGroup,
        plants: filteredPlants,
      });
    } else {
      setSelectedPlants((prev) => ({
        ...prev,
        [groupID]: filteredPlants,
      }));
    }
  };

  const sortPlants = (event, groupID) => {
    if (!selectedPlants || !selectedPlants?.[groupID] || selectedPlants?.[groupID]?.length === 0) {
      return;
    }
    const filteredPlants = plantList?.filter(
      (p) => !!selectedPlants?.[groupID]?.find((plant) => plant === p.PLANT_FACILITY_SAP_ID),
    );
    const unselectedPlants = plantList?.filter((p) => {
      const x = selectedPlants?.[groupID]?.find((plant) => plant === p.PLANT_FACILITY_SAP_ID);
      if (x) {
        return false;
      }
      return true;
    });
    setPlantGroups((prev) => ({
      ...prev,
      [groupID]: filteredPlants.concat(unselectedPlants),
    }));
  };

  const sortNewGrpPlants = () => {
    if (!newGroup?.plants?.length) {
      return;
    }
    const filteredPlants = newGrpPlants?.filter(
      (p) => !!newGroup?.plants?.find((plant) => plant === p.PLANT_FACILITY_SAP_ID),
    );
    const unselectedPlants = newGrpPlants?.filter((p) => {
      const x = newGroup?.plants?.find((plant) => plant === p.PLANT_FACILITY_SAP_ID);
      if (x) {
        return false;
      }
      return true;
    });
    setNewGrpPlants(filteredPlants.concat(unselectedPlants));
  };

  const dispSelectedPlantIDs = ({ value }, groupID, isNewGrp) => (
    <Tag
      key={value}
      closable="true"
      onClose={(e) => unSelectOption(e, value, groupID, isNewGrp)}
    >
      <span style={{ fontSize: '16px' }}>{value}</span>
    </Tag>
  );

  const createGroup = () => {
    setModalVisible(false);
    setNewGroup({
      name: '',
      plants: [],
    });
    dispatch(allActions.CommonActions.createPlantGroup({
      GroupID: uuid(),
      Name: newGroup?.name,
      PlantList: JSON.stringify(newGroup?.plants),
      CreatedBy: user?.account?.name,
    }));
  };

  const updatePlantGroup = (groupID) => {
    setConfirmModalVisible(false);
    const name = dataSource?.find((grp) => grp?.GroupID === groupID)?.Name;
    if (!selectedPlants?.[groupID]?.length) {
      setConfirmModalVisible(true);
      setTitle('Error Message');
      setModalContent(`Plant Group "${name}" has no plants selected. Please select atleast 1 plant`);
      setOnOk(() => () => setConfirmModalVisible(false));
      return;
    }
    dispatch(allActions.CommonActions.createPlantGroup({
      GroupID: groupID,
      Name: name,
      PlantList: JSON.stringify(selectedPlants?.[groupID]),
      CreatedBy: user?.account?.name,
    }));
  };

  const deletePlantGroup = (groupID) => {
    setConfirmModalVisible(false);
    const name = dataSource?.find((grp) => grp?.GroupID === groupID)?.Name;
    dispatch(allActions.CommonActions.deletePlantGroup(groupID, name));
  };

  const deleteMultipleGroups = () => {
    setConfirmModalVisible(false);
    if (selectedRowKeys?.length) {
      dispatch(allActions.CommonActions.deleteMultiplePlantGroups(selectedRowKeys));
    }
    setSelectedRowKeys([]);
  };

  const confirmDeleteAllGroups = () => {
    setConfirmModalVisible(true);
    setTitle('Delete Multiple Plant Groups');
    setModalContent((
      <div>
        Are you sure you want to delete selected plant groups?
      </div>
    ));
    setOnOk(() => () => deleteMultipleGroups());
  };

  const conFirmDeleteGroup = (groupID) => {
    const name = dataSource?.find((grp) => grp?.GroupID === groupID)?.Name;
    setConfirmModalVisible(true);
    setTitle('Delete Plant Group');
    setModalContent((
      <div>
        {`Are you sure you want to delete "${name}" plant group?`}
      </div>
    ));
    setOnOk(() => () => deletePlantGroup(groupID));
  };

  const confirmGroupUpdate = (groupID) => {
    const name = dataSource?.find((grp) => grp?.GroupID === groupID)?.Name;
    setConfirmModalVisible(true);
    setTitle('Update Plant Group');
    setModalContent((
      <>
        {`Are you sure you want to update "${name}" plant group?`}
      </>
    ));
    setOnOk(() => () => updatePlantGroup(groupID));
  };

  const clearGroup = (groupID) => {
    setSelectedPlants((prev) => ({
      ...prev,
      [groupID]: [],
    }));
  };

  const updateNameLocally = (val) => {
    if (dataSource?.length) {
      if (dataSource?.find((x) => x.Name === val)) {
        setErrText('Name is already in use');
      } else {
        setErrText(null);
      }
    }
    if (!val || val === '' || val.replace(/\s+/g, '') === '') {
      setNewName('');
      setErrText('Name cannot be empty');
      return;
    }
    setNewName(val);
  };

  const updateOnlyName = (val, groupID) => {
    setEditName(false);
    setNewName(null);
    setErrText(null);
    if (dataSource?.length && dataSource?.find((x) => x.Name === val)) {
      setErrText('Name is already in use');
      return;
    }
    if (!val || val === '' || val.replace(/\s+/g, '') === '') {
      setErrText('Name cannot be empty');
      return;
    }
    dispatch(allActions.CommonActions.updatePlantGroupName(val, groupID));
  };

  const columns = [
    {
      title: 'Group Name',
      dataIndex: 'Name',
      width: '200px',
      className: 'GroupName',
      render: (text, record) => {
        if (editName && editName === record?.key) {
          return (
            <div>
              <Input
                value={(newName === '' || newName) ? newName : text}
                onChange={(e) => updateNameLocally(e.target?.value)}
                onBlur={(e) => updateOnlyName(e.target?.value, record?.key)}
              />
              <div style={{ display: errText ? 'block' : 'none', color: 'red' }}>
                {errText}
              </div>
            </div>
          );
        }
        return text;
      },
    },
    {
      title: 'Plant List',
      dataIndex: 'PlantList',
      className: 'PlantList',
      render: (text, record) => (
        <MultiItemSelector
          className="quickSearchRows"
          placeHolder="Select plant(s)"
          items={plantGroups?.[record?.key]
            ? plantGroups?.[record?.key]?.map((x) => (
              { id: x?.PLANT_FACILITY_SAP_ID, name: x?.PLANT_FACILITY_SAP_NM }
            ))
            : []}
          multiSelect
          tagRender={(props) => dispSelectedPlantIDs(props, record?.key)}
          selectedValues={selectedPlants?.[record?.key]}
          handleChange={(event) => changeSelectedPlants(event, record?.key)}
          clear={() => clearGroup(record?.key)}
          maxTagCount={4}
          maxTagPlaceholder={(
            <Tooltip
              title={
                selectedPlants?.[record?.key]?.length > 0 && selectedPlants[record?.key]
                  .map((plantID) => `${getPlantName(plantID)} (${plantID})`).join('\r\n')
              }
              overlayClassName="selectorTooltip"
            >
              {`+ ${(selectedPlants?.[record?.key]?.length ?? 0) - 4}`}
            </Tooltip>
          )}
          maxTagTextLength={20}
          onPasteCapture={(e) => pasteValue(e, record?.key)}
          onDropdownVisibleChange={(e) => sortPlants(e, record?.key)}
        />

      ),
    },
    {
      title: 'Update group',
      dataIndex: 'UpdateGroup',
      className: 'UpdateGroup',
      width: '10%',
      render: (_text, record) => (
        <Button onClick={() => confirmGroupUpdate(record.key)}>
          Update plants
        </Button>
      ),
    },
    {
      title: 'Created By',
      dataIndex: 'CreatedBy',
      className: 'CreatedBy',
      width: '10%',
    },
    {
      title: 'Created On',
      dataIndex: 'CreatedOn',
      className: 'CreatedOn',
      width: '10%',
      render: (createdDate) => createdDate && moment(createdDate).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: 'Changed On',
      dataIndex: 'ChangedOn',
      className: 'ChangedOn',
      width: '10%',
      render: (changedDate) => changedDate && moment(changedDate).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: 'Delete group',
      dataIndex: 'DeleteGroup',
      className: 'DeleteGroup',
      width: '10%',
      render: (text, record) => (
        <DeleteOutlined
          style={{ color: 'red', fontSize: '18px' }}
          onClick={() => conFirmDeleteGroup(record?.key)}
        />
      ),
    },
  ];

  const handleRowClick = (e, key) => {
    if (!e?.target?.cellIndex && e?.target?.cellIndex !== 0) return;
    const { className } = e.target;
    if (className?.includes('UpdateGroup')
      || className?.includes('DeleteGroup')
      || className?.includes('PlantList')) return;
    if (className?.includes('GroupName') && editName !== key) {
      setEditName(key);
      return;
    }
    if (!selectedRowKeys?.length) {
      setSelectedRowKeys([key]);
      return;
    }
    if (selectedRowKeys?.find((x) => x === key)) {
      setSelectedRowKeys(selectedRowKeys?.filter((x) => x !== key));
    } else setSelectedRowKeys([...selectedRowKeys, key]);
  };

  const updateName = (e) => {
    const name = e?.target?.value;
    if (name !== '' && name.replace(/\s+/g, '') === '') {
      setErrText('Name must contain Alphabets or numbers');
    } else if (dataSource?.some((x) => x.Name === name)) {
      setErrText('This Name is already in use');
    } else {
      setErrText(null);
    }
    setNewGroup((prev) => ({
      ...prev,
      name,
    }));
  };

  const newGrpContent = (
    <>
      <Input
        placeholder="Group name"
        value={newGroup?.name}
        onChange={updateName}
        style={{ height: '40px' }}
        size="large"
      />
      <div style={{ color: 'red', margin: '10px 0px 30px 0px' }}>
        {errText}
      </div>
      <MultiItemSelector
        className="quickSearchRows"
        placeHolder="Select plant(s)"
        items={newGrpPlants?.length
          ? newGrpPlants?.map((x) => (
            { id: x?.PLANT_FACILITY_SAP_ID, name: x?.PLANT_FACILITY_SAP_NM }
          ))
          : []}
        multiSelect
        tagRender={(props) => dispSelectedPlantIDs(props, null, true)}
        selectedValues={newGroup?.plants}
        handleChange={(event) => setNewGroup((prev) => ({
          ...prev,
          plants: event.map((e) => e?.key),
        }))}
        clear={() => setNewGroup((prev) => ({
          ...prev,
          plants: [],
        }))}
        maxTagCount={4}
        maxTagPlaceholder={(
          <Tooltip
            title={
              newGroup?.plants?.length
                ? newGroup?.plants?.map((plantID) => getPlantName(plantID)?.concat(' (', plantID, ')'))?.join('\r\n')
                : 'title'
            }
            overlayClassName="selectorTooltip"
          >
            {`+ ${(newGroup?.plants?.length ?? 0) - 4}`}
          </Tooltip>
        )}
        maxTagTextLength={20}
        onPasteCapture={(e) => pasteValue(e, null, true)}
        onDropdownVisibleChange={() => sortNewGrpPlants()}
      />
    </>
  );

  const openCreateForm = () => {
    setOkButtonDisabled(true);
    setModalVisible(true);
    setModalContent(newGrpContent);
    setOnOk(() => () => createGroup());
  };

  return (
    <>
      <Modal
        title="Create New Plant Group"
        visible={visible}
        onOk={createGroup}
        onCancel={() => setModalVisible(false)}
        okText="Create"
        cancelText="Cancel"
        okButtonProps={{ disabled: okButtonDisabled }}
        style={{ minWidth: '600px' }}
      >
        {newGrpContent}
      </Modal>
      <Modal
        title={title}
        visible={confirmVisible}
        onOk={onOk}
        onCancel={() => setConfirmModalVisible(false)}
        okText="Confirm"
        cancelText="Cancel"
        style={{ minWidth: '600px' }}
      >
        {modalContent}
      </Modal>
      <div style={{ float: 'right', marginBottom: '10px' }}>
        <Button type="primary" onClick={() => openCreateForm()} style={{ marginRight: '20px' }}>
          <PlusOutlined />
          Create new group
        </Button>
        <Button type="primary" onClick={() => confirmDeleteAllGroups()} disabled={!selectedRowKeys?.length || !dataSource?.length}>
          Delete selected groups
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={dataSource}
        rowClassName={(record, index) => (index % 2 === 0 ? 'table-row-light' : 'table-row-dark')}
        rowSelection={{
          type: 'checkbox',
          selectedRowKeys,
          onChange: (selectedKeys) => setSelectedRowKeys(selectedKeys),
        }}
        scroll={{ y: 600 }}
        onRow={(record) => ({
          onClick: (e) => handleRowClick(e, record.key),
        })}
        pagination={false}
      />
    </>
  );
};

export default PlantGroupConfig;
