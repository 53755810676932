const getPrice = (m) => (m.MARKET_PRICE
  ? Number.parseFloat(m.MARKET_PRICE)
  : Number.parseFloat(m.CC_UNIT_COST_AMT));

const otherCalculation = (m, newROP, currentROP) => {
  let impact;
  if (currentROP === newROP) return 0;

  const price = getPrice(m);
  impact = (currentROP - newROP) * price;
  if (impact < 0) {
    const greater = Math.max(m.EDAM_REORDER_POINT_QTY, m.AVAIL_STOCK);
    const newImpact = (greater - newROP) * price;
    if (newImpact >= 0) {
      return 0;
    }
    impact = Math.max(newImpact, impact);
  }
  return impact;
};

const fmCalculation = (m, newMLS) => {
  const stockCostAsIs = m.CC_STOCK_COST_AS_IS;
  const purchaseQty = newMLS > m.CC_CONSUMED_QTY_24M_AVG ? newMLS : m.CC_CONSUMED_QTY_24M_AVG;
  const posPer12M = purchaseQty !== 0
    ? m.CC_CONSUMED_PER_12M_AVG / purchaseQty
    : m.CC_CONSUMPTIONS_12M_AVG;
  const transCostToBe = posPer12M * m.CC_LAST_PO_COST;
  const holdingCostToBe = m.HOLDING_COST_PRCT * m.CC_UNIT_COST_AMT * newMLS;
  const stockCostToBe = holdingCostToBe + transCostToBe;
  const impact = stockCostAsIs - stockCostToBe;
  return impact;
};

const calculate = (material, newROP, newMLS, currentROP, currentMLS) => {
  const parsedNewMls = Number.parseFloat(newMLS);
  const parsedCurrentMls = Number.parseFloat(currentMLS);
  if (material.CC_CONSUMPTION_SEGMENT_GROUP === 'FM' && material.CC_MM_LOGIC_IND !== 1) {
    if (parsedNewMls === parsedCurrentMls) return 0;
    return fmCalculation(material, parsedNewMls);
  }
  const parsedNewRop = Number.parseFloat(newROP);
  const parsedCurrentRop = Number.parseFloat(currentROP);
  if (parsedNewRop === parsedCurrentRop) return 0;
  return otherCalculation(material, parsedNewRop, parsedCurrentRop);
};

export default calculate;
