import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import PropTypes from 'prop-types';
import { sensitiveValues, unFilterableCols } from '../../../../Common/SpecialValues';

const { Option } = Select;

const AdvancedSearchColumns = ({
  updateValue, selectedColumn, canViewPrices, columns, materialMaster,
}) => {
  const [options, setOptions] = useState([]);

  const columnOrderSorter = (a, b) => {
    if (materialMaster) {
      return (a.DISPLAY_NAME_LONG ?? a.DISPLAY_NAME ?? a.ORIGINAL_NAME)?.localeCompare(
        b.DISPLAY_NAME_LONG ?? b.DISPLAY_NAME ?? b.ORIGINAL_NAME,
      );
    }
    if (a === b) {
      return 0;
    }
    if (a.MATERIALLIST_VIEW_ORDER === null) {
      return 1;
    }
    if (b.MATERIALLIST_VIEW_ORDER === null) {
      return -1;
    }
    return a.MATERIALLIST_VIEW_ORDER > b.MATERIALLIST_VIEW_ORDER ? 1 : -1;
  };

  useEffect(() => {
    if (columns?.length > 0) {
      setOptions(columns
        .filter((col) => !unFilterableCols.includes(col.ORIGINAL_NAME))
        .filter((col) => canViewPrices || (!canViewPrices && !sensitiveValues.find(
          (val) => val === col.ORIGINAL_NAME,
        )))
        .sort(columnOrderSorter)
        .map(
          (option) => (
            <Option
              value={option.ORIGINAL_NAME}
              key={option.ORIGINAL_NAME}
              label={option.DISPLAY_NAME_LONG ?? option.DISPLAY_NAME ?? option.ORIGINAL_NAME}
            >
              {option.DISPLAY_NAME_LONG ?? option.DISPLAY_NAME ?? option.ORIGINAL_NAME}
            </Option>
          ),
        ));
    }
  }, [columns, canViewPrices]);

  return (
    <Select
      showSearch
      optionFilterProp="value"
      filterOption={(input, option) => (
        option.label?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
        || option.value?.toLowerCase().indexOf(input?.toLowerCase()) >= 0)}
      defaultValue={selectedColumn || undefined}
      placeholder="Select column"
      onChange={(value) => { updateValue('column', value); }}
    >
      {options}
    </Select>
  );
};
export default AdvancedSearchColumns;

AdvancedSearchColumns.defaultProps = {
  canViewPrices: true,
  columns: [],
  materialMaster: false,
};

AdvancedSearchColumns.propTypes = {
  updateValue: PropTypes.func.isRequired,
  selectedColumn: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string]).isRequired,
  canViewPrices: PropTypes.bool,
  columns: PropTypes.arrayOf(PropTypes.any),
  materialMaster: PropTypes.bool,
};
