import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { InputNumber, Select, Table } from 'antd';
import { useSelector } from 'react-redux';
import { userHasUpdated } from './Generators';
import { emptyValue, IMPACT } from '../../../../../Common/GlobalConstants';
import { convertCost } from '../../../../../Common/DateAndNumberFunctions';

const { Option } = Select;

const renderValue = (text) => (text === emptyValue ? ' ' : text);

const columns = [
  {
    title: '',
    dataIndex: 'name',
    width: '25%',
  },
  {
    title: 'Current',
    dataIndex: 'current',
    width: '25%',
    render: renderValue,
  },
  {
    title: 'Recommended',
    dataIndex: 'recommended',
    width: '25%',
    render: renderValue,
  },
  {
    title: 'New value',
    dataIndex: 'userDefined',
    width: '25%',
    render: (text, record) => (userHasUpdated(record)
      ? <div><b>{renderValue(text)}</b></div>
      : renderValue(text)),
    onCell: (record) => (userHasUpdated(record) ? { style: { background: '#fae1a9' } } : {}),
  },
];

const ReviewChanges = (
  {
    data, monetaryImpact, setMonetaryImpact, monetaryImpactType, setMonetaryImpactType,
  },
) => {
  const defaultCurrency = useSelector((state) => state.commonState?.userSettings?.DEFAULT_CURRENCY);
  const currencyList = useSelector((state) => state.commonState?.currencyList);

  const [impactDisabled, setImpactDisabled] = useState(monetaryImpactType === IMPACT.noImp);
  const [convertedValue, setConvertedValue] = useState(convertCost(
    Math.abs(Number.parseFloat(monetaryImpact)), defaultCurrency, currencyList,
  ));

  const handleChange = (value, field) => {
    if (field === 'type') {
      setMonetaryImpactType(value);
      if (value === IMPACT.noImp) {
        setConvertedValue(0);
        setMonetaryImpact(0);
        setImpactDisabled(true);
      } else {
        setImpactDisabled(false);
      }
    } else {
      let tempVal = value;
      if (typeof value === 'string') tempVal = value?.replaceAll(' ', '');
      setConvertedValue(value);
      if (defaultCurrency && defaultCurrency !== 'NOK') {
        setMonetaryImpact(convertCost(
          Math.abs(Number.parseFloat(tempVal)), 'NOK', currencyList, defaultCurrency,
        ));
      } else {
        setMonetaryImpact(Math.abs(Number.parseFloat(tempVal)));
      }
    }
  };

  const editableColumn = (text, record) => {
    if (record.name === 'Type') {
      return (
        <Select defaultValue={text} style={{ width: '100%' }} onChange={(e) => handleChange(e, 'type')} size="small">
          <Option key={IMPACT.savings} value={IMPACT.savings}>{IMPACT.savings}</Option>
          <Option key={IMPACT.invest} value={IMPACT.invest}>{IMPACT.invest}</Option>
          <Option key={IMPACT.noImp} value={IMPACT.noImp}>{IMPACT.noImp}</Option>
        </Select>
      );
    }
    return (
      <InputNumber
        size="small"
        style={{ width: '100%' }}
        onChange={(value) => handleChange(value, 'sum')}
        decimalSeparator=","
        value={convertedValue}
        disabled={impactDisabled}
      />
    );
  };

  return (
    <div style={{ width: '600px' }}>
      <Table
        bordered
        dataSource={data}
        columns={columns}
        pagination={false}
        className="infoTable"
      />
      <br />
      <Table
        dataSource={[{
          key: '1',
          name: `Sum (${defaultCurrency || 'NOK'})`,
          value: monetaryImpact,
        },
        {
          key: '2',
          name: 'Type',
          value: monetaryImpactType || '-',
        }]}
        columns={[
          {
            title: 'Monetary Impact',
            dataIndex: 'name',
            width: '25%',
          },
          {
            title: 'Value',
            dataIndex: 'value',
            render: editableColumn,
            width: '25%',
          },
        ]}
        pagination={false}
        className="infoTable"
      />
    </div>
  );
};

export default ReviewChanges;

ReviewChanges.defaultProps = {
  monetaryImpact: '-',
  monetaryImpactType: '-',
};

ReviewChanges.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  monetaryImpact: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setMonetaryImpact: PropTypes.func.isRequired,
  monetaryImpactType: PropTypes.string,
  setMonetaryImpactType: PropTypes.func.isRequired,
};
