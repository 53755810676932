/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import React, { useEffect } from 'react';
import {
  Descriptions, Empty,
} from 'antd';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../../../actions';
import Loading from '../../../../Common/Loading';

function LeadTimeTab({ loadingKey, materialLeadTime }) {
  const dispatch = useDispatch();
  const { plant, material } = useParams();
  const loading = useSelector((state) => state.materialDetailsState.loading);
  const { Item } = Descriptions;

  useEffect(() => {
    if (plant && material) {
      dispatch(allActions.MaterialDetailsActions.setLoading(loadingKey));
      dispatch(allActions.MaterialDetailsActions.fetchMaterialLeadTime(plant, material));
    }
  }, [plant, material]);

  const tableStyle = {
    fontSize: '12px',
    height: '5px',
    padding: '4px',
    width: '20ch',
  };

  return (
    <>
      {loading && loading[loadingKey]
        ? <Loading />
        : (!loading[loadingKey] && materialLeadTime && materialLeadTime[0]
          && materialLeadTime?.[0].PLANT_FACILITY_SAP_ID === plant
          && materialLeadTime?.[0].MATERIAL_TYPE_SAP_ID === material ? (
            <>
              <Descriptions
                className="infoTable"
                column={1}
                bordered
                labelStyle={tableStyle}
                contentStyle={tableStyle}
              >
                <Item style={{ fontWeight: 'bold' }} label="Contract">Days</Item>
                <Item label="LeadTime Source">{materialLeadTime[0]?.CONTRACT_LT_SOURCE}</Item>
                <Item label="LeadTime From Supplier">{materialLeadTime[0]?.CONTRACT_PLANNED_DELIVERY_TIME}</Item>
                <Item label="LeadTime From Supplier Incl Weekends">{materialLeadTime[0]?.CONTRACT_PLANNED_DELIVERY_TIME_ADJ}</Item>
                <Item label="Transport">{materialLeadTime[0]?.CONTRACT_VENDOR_LT}</Item>
                <Item label="PO Processing Time">{materialLeadTime[0]?.CONTRACT_PROCESSING_TIME}</Item>
                <Item label="Contract number">{materialLeadTime[0]?.CONTRACT_NUMBER}</Item>
                <Item label="Total Contract LeadTime">{materialLeadTime[0]?.CONTRACT_LEAD_TIME}</Item>
              </Descriptions>
              <Descriptions
                column={1}
                bordered
                labelStyle={tableStyle}
                contentStyle={tableStyle}
              >
                <Item label="Purchase Order" style={{ fontWeight: 'bold' }}>Days</Item>
                <Item label="LeadTime Source">{materialLeadTime[0]?.PO_LT_SOURCE}</Item>
                <Item label="LeadTime From Supplier">{materialLeadTime[0]?.PO_LEAD_TIME_SUPPLIER}</Item>
                <Item label="PO Processing Time">{materialLeadTime[0]?.PO_PROCESSING_TIME}</Item>
                <Item label="Total PO LeadTime">{materialLeadTime[0]?.PO_LEAD_TIME}</Item>
              </Descriptions>
              <Descriptions
                column={1}
                bordered
                labelStyle={tableStyle}
                contentStyle={tableStyle}
              >
                <Item label="Material Master" style={{ fontWeight: 'bold' }}>Days</Item>
                <Item label="MM LeadTime">{materialLeadTime[0]?.MM_LEAD_TIME}</Item>
                <Item label="PO Processing Time">{materialLeadTime[0]?.CC_MM_PROCESS_TIME}</Item>
                <Item label="Total MM LeadTime">{materialLeadTime[0]?.LT6_LEAD_TIME}</Item>
              </Descriptions>
              <Descriptions
                column={1}
                bordered
                labelStyle={tableStyle}
                contentStyle={tableStyle}
              >
                <Item style={{ fontWeight: 'bold' }} label="GR processing time (not part of lead time calculation)">{materialLeadTime[0]?.GR_PROCESS_TIME}</Item>
              </Descriptions>
            </>
          ) : <Empty />)}
    </>
  );
}

export default LeadTimeTab;

LeadTimeTab.propTypes = {
  loadingKey: PropTypes.string.isRequired,
};
