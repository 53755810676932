import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

const uploadCols = [
  { header: 'Item text', key: 'itemText', width: 50 },
  { header: 'Delivery time', key: 'deliveryTime', width: 15 },
  { header: 'Supplier location', key: 'supplierLocation', width: 20 },
  { header: 'Criticality', key: 'criticality', width: 15 },
  { header: 'Max Consumed', key: 'maxConsumed', width: 15 },
  { header: 'Consumption value', key: 'consumptionValue', width: 15 },
  { header: 'Consumption unit', key: 'consumptionUnit', width: 20 },
  { header: 'Price', key: 'price', width: 20 },
];

const downloadFile = (wb, fileName) => {
  wb.xlsx.writeBuffer(fileName).then((x) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const blob = new Blob([x], { type: fileType });
    saveAs(blob, fileName);
  }).catch((err) => {
    console.log(err.message);
  });
};

const downloadNewStockTemplate = () => {
  const wb = new ExcelJS.Workbook();
  const worksheet = wb.addWorksheet('My Sheet');
  const idCol = worksheet.getColumn('A');
  idCol.width = 50;
  const validationObj = {
    type: 'list',
    allowBlank: true,
    showErrorMessage: true,
    errorStyle: 'error',
    errorTitle: 'Invalid',
    error: 'Only values provided in the dropdown are considered valid',
  };
  for (let i = 1; i < 1000; i++) {
    worksheet.getCell('C'.concat(i + 1)).dataValidation = {
      formulae: ['"Domestic,Continental,Global"'],
      ...validationObj,
    };
    worksheet.getCell('D'.concat(i + 1)).dataValidation = {
      formulae: ['"3 (H),2/6 (M),9 (M),5 (M),1/4/7/8 (L),A/Y/N/Z (Other),Blank (None)"'],
      ...validationObj,
    };
    worksheet.getCell('G'.concat(i + 1)).dataValidation = {
      formulae: ['"days,weeks,months,years"'],
      ...validationObj,
    };
    const cols = ['B', 'E', 'F', 'H'];
    for (let j = 0; j < 4; j++) {
      worksheet.getCell(cols[j].concat(i + 1)).dataValidation = {
        type: 'whole',
        operator: 'greaterThan',
        showErrorMessage: true,
        formulae: [0],
        errorStyle: 'error',
        errorTitle: '< 1',
        error: 'The value must be greater than 0',
      };
    }
  }

  worksheet.columns = [...uploadCols];

  for (let i = 0; i < 8; i++) {
    const x = worksheet.getCell(String.fromCharCode('A'.charCodeAt(0) + i).concat(1));
    x.font = {
      bold: true,
      size: 12,
    };
  }

  downloadFile(wb, 'template.xlsx');
};

const downloadNewStockResults = (formRows, resultRows) => {
  const wb = new ExcelJS.Workbook();
  const worksheet = wb.addWorksheet('My Sheet');
  const idCol = worksheet.getColumn('A');
  idCol.width = 50;

  worksheet.columns = [
    ...uploadCols,
    { header: '', key: 'empty', width: 15 },
    { header: 'MRP Type', key: 'MRP_TYPE', width: 15 },
    { header: 'Lot Size', key: 'Lot_Size', width: 15 },
    { header: 'ROP', key: 'ROP', width: 15 },
    { header: 'Max', key: 'MAX', width: 15 },
    { header: 'MLS', key: 'MLS', width: 15 },
    { header: 'Consumption Segment', key: 'consumption_segment', width: 15 },
    { header: 'Stock Location', key: 'Stock_loc', width: 20 },
  ];
  for (let i = 0; i < formRows.length; i++) {
    worksheet.addRow({ ...formRows[i], ...resultRows[i] });
  }

  for (let i = 0; i < 16; i++) {
    if (i !== 8) {
      const x = worksheet.getCell(String.fromCharCode('A'.charCodeAt(0) + i).concat(1));
      x.font = {
        bold: true,
        size: 12,
      };
    }
  }

  downloadFile(wb, 'Results.xlsx');
};

const readXLSX = (data, setRows, currentKeyNo, setKeyNo) => {
  const wb = new ExcelJS.Workbook();
  const uploadRows = [];
  wb.xlsx.load(data).then((workbook) => {
    workbook.eachSheet((sheet) => {
      sheet.eachRow((row, rowIndex) => {
        if (rowIndex < 2) return;
        const vals = [...row.values];
        uploadRows.push({
          key: 'newStockFormRow'.concat((currentKeyNo + rowIndex) * 10),
          itemText: vals[1],
          deliveryTime: vals[2],
          supplierLocation: vals[3],
          criticality: vals[4] === 'Blank (None)' ? vals[4] : vals[4]?.split(' (')?.[0],
          maxConsumed: vals[5],
          consumptionValue: vals[6],
          consumptionUnit: vals[7],
          price: vals[8],
        });
      });
    });
    setRows(uploadRows);
    setKeyNo((count) => count + uploadRows?.length + 10);
  }).catch((error) => console.log(error));
};

export {
  downloadNewStockTemplate, downloadNewStockResults, readXLSX,
};
