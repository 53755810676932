/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import Loading from '../../Common/Loading';
import { modes } from '../MaterialListView/MaterialListFunctions';
import MaterialMasterContainer from './MaterialMasterContainer';

function MMStaticWLContainer() {
  const { worklistID } = useParams();
  const worklists = useSelector((state) => state.worklistState.mmStaticWorklists);
  const [worklistDetails, setWorklistDetails] = useState();

  useEffect(() => {
    if (worklists?.length > 0) {
      const worklist = worklists.find((e) => e.WORKLIST_ID === worklistID);
      if (worklist) {
        setWorklistDetails(worklist);
      }
    }
  }, [worklists, worklistID]);

  return (
    worklistDetails && worklistID
      ? (
        <MaterialMasterContainer
          worklistID={worklistID}
          worklistDetails={{
            WorklistID: worklistDetails?.WORKLIST_ID,
            WorklistName: worklistDetails?.WorklistName,
            WorklistDescription: worklistDetails?.WorklistDescription,
            CreatedDate: worklistDetails?.CreatedDate,
            ModifiedDate: worklistDetails?.ModifiedDate,
            NoOfRecords: worklistDetails?.NoOfRecords,
          }}
          headerCells={worklistDetails?.SelectedColumns ? JSON.parse(worklistDetails.SelectedColumns) : []}
          sortColumn={worklistDetails?.SortColumn}
          sortDirection={worklistDetails?.SortDirection}
          mode={modes.STATIC}
        />
      )
      : <Loading text="Loading Worklist" />
  );
}

export default MMStaticWLContainer;
