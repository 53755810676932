import { showNotification } from '../../../actions/utilities/Notifications/NotificationActions';
import { massUpdate } from './massUpdateGlobals';

export const updateSelectedMaterials = (selectedMaterials, setSelectedMaterials, dispatch) => {
  if (selectedMaterials.length <= 2000) {
    setSelectedMaterials(selectedMaterials);
  } else {
    const max150 = selectedMaterials.slice(0, 2000);
    const removed = selectedMaterials.slice(2000);
    setSelectedMaterials(max150);

    const clipboardText = removed.join('\r\n');
    dispatch(showNotification(
      {
        Type: 'error',
        Title: 'Too many materials selected',
        Message: `Missing materials is copied and can be pasted after submit:\r\n  ${clipboardText}`,
      },
    ));
  }
};

export const titleStrings = {
  [massUpdate.MEMO]: 'Material Memo',
  [massUpdate.PLANNED_ORDER]: 'Planned Order',
  [massUpdate.MRP]: 'MRP Settings',
  [massUpdate.IOTA_MEMO]: 'IOTA Comment',
  [massUpdate.WAREHOUSE_MEMO]: 'Warehouse Memo',
  [massUpdate.FIXED_BIN]: 'Fixed Bin',
};
