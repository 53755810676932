import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Checkbox, Spin } from 'antd';
import { NOTIFICATION_TYPES, NOTIFICATION_TYPE_NAMES } from '../../Common/GlobalConstants';
import { SettingCodes } from './SettingTypes';
import './Settings.css';

const NotificationSettings = ({
  updateSetting, hideNotifications, loading,
}) => {
  const options = [];
  Object.keys(NOTIFICATION_TYPES).forEach((type) => {
    options.push({ label: NOTIFICATION_TYPE_NAMES?.[type], value: NOTIFICATION_TYPES[type] });
  });
  const [checkedList, setCheckedList] = useState(Object.values(NOTIFICATION_TYPES));
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);

  const onChange = (list, fromStorage) => {
    setCheckedList(list);
    setIndeterminate(list?.length && list.length < options.length);
    setCheckAll(list.length === options.length);

    if (!fromStorage) {
      const hidden = Object.values(NOTIFICATION_TYPES).filter(
        (type) => !list.includes(type),
      );
      const hiddenString = JSON.stringify(hidden);
      updateSetting(SettingCodes.hideNotifications, hiddenString);
    }
  };

  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? Object.values(NOTIFICATION_TYPES) : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);

    const hiddenString = JSON.stringify(e.target.checked ? [] : Object.values(NOTIFICATION_TYPES));
    updateSetting(SettingCodes.hideNotifications, hiddenString);
  };

  useEffect(() => {
    let hidden = null;
    try { hidden = JSON.parse(hideNotifications); } catch { /**/ }
    if (hidden?.length > 0) {
      const checked = Object.values(NOTIFICATION_TYPES).filter(
        (type) => !hidden.includes(type),
      );
      onChange(checked, true);
    } else {
      setCheckedList(Object.values(NOTIFICATION_TYPES));
      setCheckAll(true);
      setIndeterminate(false);
    }
  }, [hideNotifications]);

  return (
    <Card
      className="settingsCard"
      style={{ width: '60%' }}
      title={(
        <>
          <Checkbox
            indeterminate={indeterminate}
            onChange={onCheckAllChange}
            checked={checkAll}
            disabled={!!loading?.USER_SETTINGS}
          >
            All notifications
          </Checkbox>
          {!!loading?.USER_SETTINGS && <Spin />}
        </>
      )}
    >
      <Checkbox.Group
        style={{ display: 'block' }}
        options={options}
        value={checkedList}
        onChange={onChange}
        disabled={!!loading?.USER_SETTINGS}
      />
    </Card>
  );
};

export default NotificationSettings;

NotificationSettings.defaultProps = {
  hideNotifications: '',
  loading: {},
};

NotificationSettings.propTypes = {
  updateSetting: PropTypes.func.isRequired,
  hideNotifications: PropTypes.string,
  loading: PropTypes.objectOf(PropTypes.any),
};
