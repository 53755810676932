import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'antd';
import { useParams } from 'react-router';
import InputCell from './InputCell';
import { convertToNumber, getPlantCluster } from './SimulationFunctions';
import './MultiPlantTable.css';
import { formatMaterialNumber } from '../../../../../Common/DateAndNumberFunctions';

const generateColumns = (materials, material, plantCluster, changeValue) => {
  const tempColumns = [];
  // for each plant
  plantCluster.forEach((plantID) => {
    const plant = materials.find(
      (mat) => mat.PLANT_FACILITY_SAP_ID === plantID && mat.MATERIAL_TYPE_SAP_ID === material,
    );
    const col = {
      title: plant?.PLANT_TYPE === 'SUPPLY BASE' ? (`${plantID} (onshore)`) : plantID,
      children: [
        {
          title: 'Cur.',
          dataIndex: `current${plantID}`,
          key: `current${plantID}`,
          align: 'center',
          width: `${100 / (plantCluster.length * 2 + 1)}%`,
          render: (text, _record, index) => (
            <InputCell
              value={text}
              index={index}
              plantID={plantID}
              changeValue={changeValue}
            />
          ),
        },
        {
          title: 'Rec.',
          dataIndex: `recommended${plantID}`,
          key: `recommended${plantID}`,
          align: 'center',
        },
      ],
    };
    if (plant?.PLANT_TYPE === 'SUPPLY BASE') tempColumns.unshift(col);
    else tempColumns.push(col);
  });
  tempColumns.unshift({
    title: '',
    dataIndex: 'rowType',
    key: 'rowType',
    align: 'center',
  });
  return tempColumns;
};

const generateDataSource = (
  materials, mrpSettings, onshoreData, offshoreData, ROPinput, plantCluster, material,
) => {
  const data = [];

  // add ROP row data
  let row = { key: 1 };
  plantCluster.forEach((p) => {
    let recROP;
    if (p === onshoreData.plantID) {
      recROP = `${onshoreData.recROPmin} - ${onshoreData.recROPmax}`;
    } else {
      recROP = offshoreData[p].recROPnorm;
    }
    row.rowType = 'ROP';
    row[`current${p}`] = ROPinput[p];
    row[`recommended${p}`] = recROP;
  });
  data.push(row);

  // add MAX row data
  row = { key: 2 };
  plantCluster.forEach((p) => {
    const mrpData = mrpSettings.find(
      (m) => formatMaterialNumber(m.MaterialNumber) === material && m?.Plant === p,
    );
    let recMAX;
    if (p === onshoreData.plantID) {
      recMAX = `${onshoreData.recMAXmin} - ${onshoreData.recMAXmax}`;
    } else {
      recMAX = offshoreData[p].recMAX;
    }
    row.rowType = 'MAX';
    row[`current${p}`] = convertToNumber(mrpData?.MaximumStockLevel) ?? '-';
    row[`recommended${p}`] = recMAX;
  });
  data.push(row);

  // add MLS row data
  row = { key: 3 };
  plantCluster.forEach((p) => {
    const materialData = materials.find(
      (m) => m.MATERIAL_TYPE_SAP_ID === material
      && m.PLANT_FACILITY_SAP_ID === p,
    );
    const mrpData = mrpSettings.find(
      (m) => formatMaterialNumber(m.MaterialNumber) === material && m?.Plant === p,
    );
    let recMLS;
    if (p === onshoreData.plantID) {
      recMLS = materialData?.CC_MIN_LOT_SIZE;
    } else {
      recMLS = offshoreData[p].recMLS;
    }
    row.rowType = 'MLS';
    row[`current${p}`] = convertToNumber(mrpData?.MinimumLotSize) ?? '-';
    row[`recommended${p}`] = recMLS;
  });
  data.push(row);

  return data;
};

const MultiPlant = () => {
  const materials = useSelector((state) => state.materialDetailsState.material);
  const plantRelationships = useSelector((state) => state.materialDetailsState.plantRelationships);
  const mrpSettings = useSelector((state) => state.materialDetailsState.mrpSettings);
  const { plant: focusedPlant, material } = useParams();
  const [columns, setColumns] = useState();
  const [dataSource, setDataSource] = useState();
  const [onshore, setOnshore] = useState();
  const [offshore, setOffshore] = useState();
  const [ROPvalues, setROPvalues] = useState();
  const ROPref = useRef();

  const changeValue = (input, plantID) => {
    const newROPvalues = {
      ...ROPref.current,
      [plantID]: input,
    };
    setROPvalues(newROPvalues);
    ROPref.current = newROPvalues;
  };

  const calculate = (plantCluster) => {
    const onshoreMaterial = materials.find(
      (m) => m.MATERIAL_TYPE_SAP_ID === material
      && m.PLANT_TYPE === 'SUPPLY BASE'
      && plantCluster.includes(m.PLANT_FACILITY_SAP_ID),
    );
    const onshorePlant = onshoreMaterial?.PLANT_FACILITY_SAP_ID;
    const onshoreMRP = mrpSettings.find(
      (m) => formatMaterialNumber(m.MaterialNumber) === material && m?.Plant === onshorePlant,
    );
    if (!onshoreMaterial || !onshoreMRP) return;
    const offshorePlants = plantCluster.filter((p) => p !== onshorePlant);

    // set onshore plant data needed to calculate offshore data
    let onshoreData = {
      plantID: onshorePlant,
      curROP: convertToNumber(ROPvalues[onshorePlant]),
      curMAX: convertToNumber(onshoreMRP.MaximumStockLevel),
      curMLS: convertToNumber(onshoreMRP.MinimumLotSize),
      curLotSize: convertToNumber(onshoreMRP.FixedLotSize),
      recMLS: convertToNumber(onshoreMaterial.CC_MIN_LOT_SIZE),
    };

    // set offshore data (will be used to calculate remaining onshore values)
    const offshoreData = {};
    offshorePlants.forEach((p) => {
      const offshoreMaterial = materials.find(
        (m) => m.MATERIAL_TYPE_SAP_ID === material
        && m.PLANT_FACILITY_SAP_ID === p,
      );
      const curROP = ROPvalues[p];
      const recROP = convertToNumber(offshoreMaterial?.CC_ROP);
      const recROPraw = convertToNumber(offshoreMaterial?.CC_ROP)
        - ROPvalues[onshorePlant];
      const recROPnorm = recROPraw > 0 ? recROPraw : 0;
      const recMLS = convertToNumber(offshoreMaterial?.CC_MIN_LOT_SIZE);
      const recMAX = recMLS > 0 ? recROPnorm + recMLS - 1 : recROPnorm;
      const deviationRaw = recROP - curROP;
      const offshorePlant = {
        recROPnorm,
        recMAX: recROPnorm === 0 ? 0 : recMAX,
        recMLS: recROPnorm === 0 ? 0 : recMLS,
        deviationRaw,
        deviationNorm: deviationRaw > 0 ? deviationRaw : 1,
      };
      offshoreData[p] = offshorePlant;
    });

    // remaining onshore plant calculations
    const recROPmin = Math.max(...Object.values(offshoreData).map((plant) => plant.deviationNorm));
    const recROPmax = Object.values(offshoreData).map(
      (p) => p.deviationNorm,
    ).reduce((prev, curr) => prev + curr, 0);
    const recMAXmin = recROPmin + onshoreData.recMLS - 1;
    const recMAXmax = recROPmax + onshoreData.recMLS - 1;
    onshoreData = {
      ...onshoreData,
      recROPmin,
      recROPmax,
      recMAXmin,
      recMAXmax,
    };
    setOnshore(onshoreData);
    setOffshore(offshoreData);
  };

  // set initial ROP values, to be changed by user
  useEffect(() => {
    if (mrpSettings && plantRelationships && focusedPlant) {
      const ROP = {};
      const plantCluster = getPlantCluster(plantRelationships, focusedPlant);
      plantCluster.forEach((p) => {
        const mat = mrpSettings.find(
          (m) => formatMaterialNumber(m.MaterialNumber) === material && m?.Plant === p,
        );
        ROP[p] = convertToNumber(mat?.ReorderPoint);
      });
      setROPvalues(ROP);
      ROPref.current = ROP;
    }
  }, [mrpSettings, plantRelationships, focusedPlant]);

  useEffect(() => {
    if (materials && plantRelationships) {
      const plantCluster = getPlantCluster(plantRelationships, focusedPlant);
      setColumns(
        generateColumns(materials, material, plantCluster, changeValue),
      );
    }
  }, [materials, plantRelationships, focusedPlant]);

  // run logic calculation when data is loaded, or user changes ROPvalues
  useEffect(() => {
    if (materials && mrpSettings && plantRelationships && focusedPlant && ROPvalues) {
      const plantCluster = getPlantCluster(plantRelationships, focusedPlant);
      calculate(plantCluster);
    }
  }, [materials, mrpSettings, plantRelationships, focusedPlant, ROPvalues]);

  // update table with values after calculation
  useEffect(() => {
    if (onshore && offshore) {
      const plantCluster = getPlantCluster(plantRelationships, focusedPlant);
      setDataSource(
        generateDataSource(
          materials, mrpSettings, onshore, offshore, ROPvalues, plantCluster, material,
        ),
      );
    }
  }, [onshore, offshore]);

  return (
    <Table
      className="multiPlantTable"
      columns={columns}
      dataSource={dataSource}
      pagination={false}
      bordered
    />
  );
};

export default MultiPlant;
