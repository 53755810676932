import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Tabs } from 'antd';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import TableTab from './components/TabComponents/TableTab';
import allActions from '../../../actions';
import * as loadingKeys from '../../../actions/MaterialDetailsDomain/LoadingKeys';
import './MaterialDetails.css';
import { getTabOptions } from './TabOptions';
import TabGraph from './components/TabComponents/TabGraph';
import { GRAPH_TYPES } from './components/TabComponents/TabGraphHelpers';
import LeadTimeTab from './components/TabComponents/LeadTimeTab';
import hasAuthenticatedRole from '../../Common/AuthFunctions';
import styles from './styles.module.scss';
import FixedBin from './components/TabComponents/FixedBin';

function TabElements({ excludePM10 }) {
  const { TabPane } = Tabs;
  const { plant, material } = useParams();
  const tabOptions = getTabOptions(plant, material, excludePM10);
  const [filteredConsumption, updateFilteredConsumption] = useState([]);
  const [filteredPurchasing, updateFilteredPurchasing] = useState([]);
  const [activeTabKey, setActiveTabKey] = useState('AM');
  const [hasViewerAccess, setHasViewerAccess] = useState(false);
  const defaultTab = useSelector(
    (state) => state.commonState?.userSettings?.TAB_SECTION_DEFAULT_TAB,
  );
  const user = useSelector((state) => state.authState?.user);

  useEffect(() => {
    if (user?.idTokenClaims?.roles) {
      const userHasAuthenticatedRole = hasAuthenticatedRole(
        user.idTokenClaims.roles,
        process.env.REACT_APP_VIEW_PRICES,
      );
      setHasViewerAccess(userHasAuthenticatedRole);
    }
  }, [user?.idTokenClaims]);

  useEffect(() => {
    if (defaultTab) {
      setActiveTabKey(defaultTab ?? 'AM');
    }
  }, [defaultTab]);

  const changeTab = (key) => {
    setActiveTabKey(key);
  };

  const filteredConsumptionData = useMemo(() => (
    tabOptions?.consumptionHistoryOptions?.filter
      ? filteredConsumption?.filter(
        (val) => tabOptions.consumptionHistoryOptions.filter(val),
      )
      : filteredConsumption), [filteredConsumption]);

  const materialMovement = useSelector((state) => state.materialDetailsState.materialMovement);
  const consumptionHistory = useSelector((state) => state.materialDetailsState.consumptionHistory);
  const materialChangelogs = useSelector((state) => state.materialDetailsState.materialChangelogs);
  const functionalLocation = useSelector((state) => state.materialDetailsState.functionalLocation);
  const reservations = useSelector((state) => state.materialDetailsState.reservations);
  const purchasing = useSelector((state) => state.materialDetailsState.purchasing);
  const returnDocument = useSelector((state) => state.materialDetailsState.returnDocument);
  const warehouse = useSelector((state) => state.materialDetailsState.warehouse);
  const changelogs = useSelector((state) => state.materialDetailsState.changelogs);
  const xPlant = useSelector((state) => state.materialDetailsState.xPlant);
  const materialLeadTime = useSelector((state) => state.materialDetailsState.materialLeadTime);

  const fixKeyOnXPlant = xPlant && xPlant.length > 0 ? xPlant.map((x, i) => ({ ...x, key: `${x.key}${i}` })) : [];

  return (
    <Row className="full-tab" style={{ overflowX: 'hidden' }}>
      <Tabs className="matDetsTabs compact" style={{ minWidth: '100%', height: '100%' }} onChange={changeTab} activeKey={activeTabKey}>
        <TabPane forceRender tab={<span className={materialMovement?.length > 0 ? 'hasContent' : 'noContent'}>All movement</span>} key="AM">
          <div id="tabContainer1" style={{ height: '100%', overflowX: 'hidden' }}>
            <TableTab
              loadingKey={loadingKeys.materialMovement}
              selector={(state) => state.materialDetailsState.materialMovement}
              action={allActions.MaterialDetailsActions.fetchMaterialMovement}
              title="Material Movement"
              tabOptions={tabOptions.allMovementOptions}
              containerID="tabContainer1"
              hideSensitiveValues={!hasViewerAccess}
              data={materialMovement}
            />
          </div>
        </TabPane>
        <TabPane forceRender tab={<span className={(filteredConsumption || consumptionHistory?.length > 0) ? 'hasContent' : 'noContent'}>Real Consumption</span>} key="RC">
          <div id="tabRealConsGraph" style={{ height: '40%' }}>
            <TabGraph
              type={GRAPH_TYPES.consumption}
              filteredData={
                filteredConsumptionData
              }
              containerID="tabRealConsGraph"
              activeTabKey={activeTabKey}
            />
          </div>
          <div id="tabRealConsTable" style={{ height: '60%', overflowX: 'hidden' }}>
            <TableTab
              loadingKey={loadingKeys.consumptionHistory}
              selector={(state) => state.materialDetailsState.consumptionHistory}
              action={allActions.MaterialDetailsActions.fetchConsumptionHistory}
              title="Material Consumption Data"
              tabOptions={tabOptions.consumptionHistoryOptions}
              updateFilterData={updateFilteredConsumption}
              containerID="tabRealConsTable"
              data={consumptionHistory}
            />
          </div>
        </TabPane>
        <TabPane
          forceRender
          tab={(
            <span className={materialChangelogs?.length === 0 && 'noContent'}>
              MRP Changelog
            </span>
)}
          key="MC"
        >
          <div id="tabContainer3" style={{ height: '100%', overflowX: 'hidden' }}>
            <TableTab
              loadingKey={loadingKeys.materialChangelogs}
              selector={(state) => state.materialDetailsState.materialChangelogs}
              action={allActions.MaterialDetailsActions.fetchMaterialChangelogs}
              title="MRP Changelog"
              tabOptions={tabOptions.materialChangelogOptions}
              containerID="tabContainer3"
              data={materialChangelogs}
            />
          </div>
        </TabPane>
        <TabPane forceRender tab={<span className={functionalLocation?.length > 0 ? 'hasContent' : 'noContent'}>Functional Location</span>} key="FL">
          <div id="tabContainer4" style={{ height: '100%', overflowX: 'hidden' }}>
            <TableTab
              loadingKey={loadingKeys.functionalLocation}
              selector={(state) => state.materialDetailsState.functionalLocation}
              action={allActions.MaterialDetailsActions.fetchFunctionalLocation}
              title="Functional Location"
              tabOptions={tabOptions.functionalLocationOptions}
              containerID="tabContainer4"
              data={functionalLocation}
            />
          </div>
        </TabPane>
        <TabPane forceRender tab={<span className={reservations?.length > 0 ? 'hasContent' : 'noContent'}>Reservations</span>} key="RES">
          <div id="tabContainer5" style={{ height: '100%', overflowX: 'hidden' }}>
            <TableTab
              loadingKey={loadingKeys.reservations}
              selector={(state) => state.materialDetailsState.reservations}
              action={allActions.MaterialDetailsActions.fetchReservations}
              title="Reservations"
              tabOptions={tabOptions.reservationsOptions}
              containerID="tabContainer5"
              data={reservations}
            />
          </div>
        </TabPane>
        <TabPane forceRender tab={<span className={(filteredPurchasing || purchasing?.length > 0) ? 'hasContent' : 'noContent'}>Purchasing</span>} key="PUR">
          <div id="tabPurchasingGraph" style={{ height: '40%' }}>
            <TabGraph
              type={GRAPH_TYPES.purchasing}
              filteredData={filteredPurchasing}
              containerID="tabPurchasingGraph"
              activeTabKey={activeTabKey}
            />
          </div>
          <div id="tabPurchasingTable" style={{ height: '60%', overflowX: 'hidden' }}>
            <TableTab
              loadingKey={loadingKeys.purchasing}
              selector={(state) => state.materialDetailsState.purchasing}
              action={allActions.MaterialDetailsActions.fetchPurchasing}
              title="Purchasing"
              tabOptions={tabOptions.purchasingOptions}
              updateFilterData={updateFilteredPurchasing}
              containerID="tabPurchasingTable"
              data={purchasing}
            />
          </div>
        </TabPane>
        <TabPane forceRender tab={<span>Lead Time</span>} key="LT">
          <LeadTimeTab loadingKey={loadingKeys.materialLeadTime} materialLeadTime={materialLeadTime} />
        </TabPane>
        <TabPane forceRender tab={<span className={returnDocument?.length > 0 ? 'hasContent' : 'noContent'}>Returns</span>} key="RD">
          <div id="returnsDocument" style={{ height: '100%', overflowX: 'hidden' }}>
            <TableTab
              loadingKey={loadingKeys.returnDocument}
              selector={(state) => state.materialDetailsState.returnDocument}
              action={allActions.MaterialDetailsActions.fetchReturnDocument}
              tabOptions={tabOptions.returnTabOptions}
              title="Returns"
              containerID="returnsDocument"
              data={returnDocument}
            />
          </div>
        </TabPane>
        <TabPane forceRender tab={<span className={warehouse?.length > 0 ? 'hasContent' : 'noContent'}>Warehouse</span>} key="WH">
          <div id="tabContainer8" className={styles.warehouseTab}>
            <div>
              <TableTab
                loadingKey={loadingKeys.warehouse}
                selector={(state) => state.materialDetailsState.warehouse}
                action={allActions.MaterialDetailsActions.fetchWarehouse}
                title="Warehouse"
                containerID="tabContainer8"
                data={warehouse}
                tableTitle="WM Stock"
              />
            </div>
            <div style={{ width: '40%' }}>
              <FixedBin materialNumber={material} plant={plant} />
            </div>
          </div>
        </TabPane>
        {hasViewerAccess
          && (
            <TabPane forceRender tab={<span className={changelogs?.length > 0 ? 'hasContent' : 'noContent'}>IOTA Change log</span>} key="ICL">
              <div id="tabContainer9" style={{ height: '100%', overflowX: 'hidden' }}>
                <TableTab
                  loadingKey={loadingKeys.changelogs}
                  selector={(state) => state.materialDetailsState.changelogs}
                  action={allActions.MaterialDetailsActions.fetchChangelogs}
                  title="IOTA Change log"
                  tabOptions={tabOptions.iotaChangelogOptions}
                  containerID="tabContainer9"
                  data={changelogs}
                />
              </div>
            </TabPane>
          )}
        <TabPane forceRender tab={<span className={xPlant?.length > 0 ? 'hasContent' : 'noContent'}>Other Plants</span>} key="xplant">
          <div id="xPlantContainer" style={{ height: '100%', overflowX: 'hidden' }}>
            <TableTab
              loadingKey={loadingKeys.getXPlant}
              selector={(state) => state.materialDetailsState.xPlant}
              action={allActions.MaterialDetailsActions.fetchXPlant}
              title="Other Plants"
              containerID="xPlantContainer"
              data={fixKeyOnXPlant}
            />
          </div>
        </TabPane>
      </Tabs>
    </Row>
  );
}

export default TabElements;
TabElements.defaultProps = {
  excludePM10: false,
};
TabElements.propTypes = {
  excludePM10: PropTypes.bool,
};
