export const getMaterial = 'GET_MATERIAL';
export const getMaterials = 'GET_MATERIALS';
export const getMaterialDescriptions = 'GET_MATERIAL_DESCRIPTIONS';
export const getAdditionalMaterial = 'GET_ADDITIONAL_MATERIAL';
export const getBomExplosion = 'GET_BOM_EXPLOSION';
export const getConsumptionHistory = 'GET_CONSUMPTION_HISTORY';
export const getReservations = 'GET_RESERVATIONS';
export const createChangelog = 'CREATE_CHANGELOG';
export const getChangelogs = 'GET_CHANGELOGS';
export const getMaterialLeadTime = 'GET_MATERIAL_LEADTIME';
export const getXPlant = 'GET_X_PLANT';
export const returnDocument = 'GET_RETURN_DOCUMENT';
export const getMaterialChangelogs = 'GET_MATERIAL_CHANGELOGS';
export const getMaterialMovement = 'GET_MATERIAL_MOVEMENT';
export const getFunctionalLocation = 'GET_FUNCTIONAL_LOCATION';
export const getPlantRelationships = 'GET_PLANT_RELATIONSHIPS';
export const getWarehouse = 'GET_WAREHOUSE';
export const getPurchasing = 'GET_PURCHASING';
export const setLoading = 'SET_MAT_DETAILS_LOADING';
export const failedLoading = 'FAILED_LOADING';
export const getMrpSetting = 'GET_MRP_SETTING';
export const getMrpSettings = 'GET_MRP_SETTINGS';
export const setMrpSettings = 'SET_MRP_SETTINGS';
export const storeLongText = 'STORE_LONG_TEXT';
export const getClassification = 'GET_CLASSIFICATION';
export const postpone = 'POSTPONE';
export const clearPostponement = 'CLEAR_POSTPONEMENT';
export const clearReview = 'CLEAR_REVIEW';
export const inspect = 'INSPECT';
export const clearInspection = 'CLEAR_INSPECTION';
export const review = 'REVIEW';
export const delegate = 'DELEGATE';
export const setActionComplete = 'SET_ACTION_ON_MATERIAL_COMPLETE';
export const clearActionComplete = 'CLEAR_ACTION_ON_MATERIAL_COMPLETE';
export const clearReviewComplete = 'CLEAR_REVIEW_COMPLETE';
export const clearData = 'CLEAR_DATA';
export const updateReviewData = 'UPDATE_REVIEW_DATA';
export const storeIOTAComments = 'STORE_IOTA_COMMENTS';
export const storeWarehouseComments = 'STORE_WAREHOUSE_COMMENTS';
export const addComment = 'ADD_COMMENT';
export const deleteComment = 'DELETE_COMMENT';
export const adminDeleteComment = 'ADMIN_DELETE_COMMENT';
export const createMassUpdateChangelog = 'CREATE_MASS_UPDATE_CHANGELOG';
export const createMassUpdateComments = 'CREATE_MASS_UPDATE_COMMENTS';
export const changeGroupIdFromMassUpdateMRP = 'CHANGE_GROUP_ID_FROM_MASS_UPDATE';
export const createMassMRPUpdateChangelog = 'CREATE_MASS_MRP_UPDATE_CHANGELOG';
