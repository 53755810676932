import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { isInteger, isString } from 'lodash';
import { formatMaterialNumber } from '../../../Common/DateAndNumberFunctions';
import {
  plantHasRecommendationsForMassUpdate, getCurrent, getRecommendationForMassUpdate, emptyValue,
} from '../../MaterialDetails/components/SummaryComponents/Recommendations/RecommendationHelpers';
import { createKey } from './MassUpdateMrpHelpers';

// eslint-disable-next-line import/prefer-default-export
export const getDeliveryTimePayload = (plant, fetchedPlant, relatedPlants, mrpsForRelatedPlants, changes, materialData) => {
  const supplier = materialData.find((d) => d?.PLANT_FACILITY_SAP_ID === plant)?.supplier;
  const deliveryTimePayload = { MrpBatch: '123', to_Mrp: [] };
  if (plant === supplier) {
    const ndChanges = changes?.changelog?.filter((c) => c.changeType === 'MRPType (MassUpdate)' && c.newValue === 'ND');
    ndChanges?.forEach((change) => {
      const onshoreDeliveryTime = fetchedPlant.find(
        (mrpSetting) => mrpSetting.Plant === plant?.toString()
            && formatMaterialNumber(mrpSetting.MaterialNumber)
            === formatMaterialNumber(change.MaterialNumber),
      )?.PlannedDeliveryTimeInDays;
      if (onshoreDeliveryTime) {
        relatedPlants?.forEach((relatedPlant) => {
          const material = formatMaterialNumber(change.MaterialNumber);
          if (materialData.some((x) => x.PLANT_FACILITY_SAP_ID === relatedPlant.Plant_ID
              && x.MATERIAL_TYPE_SAP_ID === material)) {
            const changeObject = {
              MaterialNumber: material,
              Plant: relatedPlant.Plant_ID,
              PlannedDeliveryTimeInDays: onshoreDeliveryTime,
              PlannedDeliveryTimeInDays_X: true,
            };
            deliveryTimePayload.to_Mrp.push(changeObject);
          }
        });
      }
    });
  }

  const fromNdChanges = changes?.changelog?.filter((change) => change.changeType === 'MRPType (MassUpdate)' && (change.newValue === 'V1' || change.newValue === 'PD') && change.oldValue === 'ND');
  const allPlants = { [plant]: [...relatedPlants, { Plant_ID: plant }] };
  const allMrps = [];
  if (mrpsForRelatedPlants && mrpsForRelatedPlants.length > 0) allMrps.push(...mrpsForRelatedPlants);
  if (fetchedPlant && fetchedPlant.length > 0) allMrps.push(...fetchedPlant);
  fromNdChanges.forEach((change) => {
    allPlants[plant].forEach((relPlant) => {
      if (relPlant.Plant_ID !== supplier) {
        const material = formatMaterialNumber(change.MaterialNumber);
        if (materialData.some((x) => x.PLANT_FACILITY_SAP_ID === relPlant.Plant_ID
          && x.MATERIAL_TYPE_SAP_ID?.toString() === material?.toString())) {
          const mrpDataForRelatedPlant = allMrps.find((mrp) => formatMaterialNumber(mrp.MaterialNumber) === material && mrp.Plant === relPlant.Plant_ID);
          if (mrpDataForRelatedPlant) {
            if (!mrpDataForRelatedPlant.SpecialProcurementType || mrpDataForRelatedPlant.SpecialProcurementType === '') {
              const changeObject = {
                MaterialNumber: material,
                Plant: relPlant.Plant_ID,
                SpecialProcurementType_X: true,
                SpecialProcurementType: '40',
              };
              deliveryTimePayload.to_Mrp.push(changeObject);
            }
          }
        }
      }
    });
  });

  return deliveryTimePayload;
};

export const getInitialValuesForMassUpdate = (materialData, newRecTableData, mrpData, plant) => {
  const temp = {};
  mrpData.forEach((mrp) => {
    const material = formatMaterialNumber(mrp.MaterialNumber);
    const correctMaterialData = materialData.find((m) => m?.MATERIAL_TYPE_SAP_ID?.toString() === material?.toString() && m?.PLANT_FACILITY_SAP_ID?.toString() === plant?.toString());
    const hasRecs = plantHasRecommendationsForMassUpdate(newRecTableData, correctMaterialData, mrp);
    const key = createKey(mrp.Plant, material);
    temp[key] = {};
    newRecTableData.forEach((mrpSetting) => {
      if (mrpSetting.key in mrp) {
        const current = getCurrent(mrp, mrpSetting, true);
        const recommended = getRecommendationForMassUpdate(mrpSetting.key, correctMaterialData, mrp, true);
        temp[key].current = { ...temp[key].current, [mrpSetting.key]: current };
        temp[key].recommended = { ...temp[key].recommended, [mrpSetting.key]: recommended };
        if (!hasRecs) {
          temp[key].userDefined = { ...temp[key].userDefined, [mrpSetting.key]: current };
        } else if (!recommended && (current === '0' || current === 0 || current === emptyValue)) {
          temp[key].userDefined = { ...temp[key].userDefined, [mrpSetting.key]: 0 };
        } else {
          temp[key].userDefined = { ...temp[key].userDefined, [mrpSetting.key]: recommended };
        }
        if (temp[key].userDefined[mrpSetting.key] === '-') temp[key].userDefined[mrpSetting.key] = '';
      }
    });
  });
  return temp;
};

const downloadFile = (wb, fileName) => {
  wb.xlsx.writeBuffer(fileName).then((x) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const blob = new Blob([x], { type: fileType });
    saveAs(blob, fileName);
  }).catch((err) => {
    console.log(err.message);
  });
};

export const downloadNewStockResults = async (formRows, excludeList, dropdownValues) => {
  const selectedRows = formRows.map((row) => ({ ...row, selected: excludeList.includes(row.MATERIAL_TYPE_SAP_ID) ? 'Unselected' : 'Selected', MATERIAL_TYPE_SAP_ID: Number(row.MATERIAL_TYPE_SAP_ID) }));
  const wb = new ExcelJS.Workbook();
  const worksheet = wb.addWorksheet('My Sheet');
  const idCol = worksheet.getColumn('A');
  idCol.width = 50;
  idCol.type = ExcelJS.ValueType.Boolean;
  idCol.style = {
    alignment: {
      horizontal: 'center',
      vertical: 'middle',
    },
  };

  const mrpControllerCopy = JSON.parse(JSON.stringify(dropdownValues.MRPController));
  const mrpGroupCopy = JSON.parse(JSON.stringify(dropdownValues.MRPGroup));
  const plantMaterialCopy = JSON.parse(JSON.stringify(dropdownValues.PlantMaterialStatus));

  const MRPController = mrpControllerCopy.map((val) => `'${val}`);
  const MRPGroup = mrpGroupCopy.map((val) => `'${val}`);
  const PlantMaterialStatus = plantMaterialCopy.map((val) => `'${val}`);

  const protectedColumns = [1, 2, 3, 4, 5, 6, 7];

  const validationObj = {
    type: 'list',
    allowBlank: true,
    showErrorMessage: true,
    errorStyle: 'error',
    errorTitle: 'Invalid',
    error: 'Only values provided in the dropdown are considered valid',
  };

  worksheet.columns = [
    {
      header: 'Selected',
      key: 'selected',
      width: 10,
      style: { numFmt: '@' },
    },
    {
      header: 'Plant', key: 'PLANT_FACILITY_SAP_ID', width: 8,
    },
    {
      header: 'Material', key: 'MATERIAL_TYPE_SAP_ID', width: 12,
    },
    {
      header: 'Material description', key: 'EDAM_MATERIAL_TYPE_NM', width: 25,
    },
    {
      header: 'Max Crit', key: 'CC_MAX_CRITICALITY', width: 12,
    },
    {
      header: 'Fair Price', key: 'FAIR_PRICE', width: 12,
    },
    {
      header: 'Total Stock', key: 'TOT_STOCK', width: 8,
    },
    {
      header: 'Open Docs', key: 'docCount', width: 8,
    },
    {
      header: 'MRP Type', key: 'MRPType', width: 8, style: { numFmt: '@' },
    },
    {
      header: 'Lot Size', key: 'LotSize', width: 6,
    },
    {
      header: 'Reorder Point', key: 'ReorderPoint', width: 10,
    },
    {
      header: 'Minimum Lot Size', key: 'MinimumLotSize', width: 12,
    },
    {
      header: 'Maximum Stock Level', key: 'MaximumStockLevel', width: 12,
    },
    {
      header: 'Rounding value', key: 'RoundingValue', width: 12,
    },
    {
      header: 'Fixed Lot Size', key: 'FixedLotSize', width: 12,
    },
    {
      header: 'MRP Group', key: 'MRPGroup', width: 12, style: { numFmt: '@' },
    },
    {
      header: 'MRP Controller', key: 'MRPController', width: 12, style: { numFmt: '@' },
    },
    {
      header: 'Plant Material Status', key: 'PlantMaterialStatus', width: 12, style: { numFmt: '@' },
    },
    {
      header: 'ABC Indicator', key: 'ABCIndicator', width: 12, style: { numFmt: '@' },
    },
    {
      header: 'Monetary Impact', key: 'monetaryImpact', width: 12,
    },
    {
      header: 'Monetary Impact Type', key: 'monetaryImpactType', width: 12,
    },
  ];
  console.log(selectedRows);

  const sortFunction = (a, b) => {
    if (a.MATERIAL_TYPE_SAP_ID === b.MATERIAL_TYPE_SAP_ID) {
      return 0;
    }

    return (a.MATERIAL_TYPE_SAP_ID < b.MATERIAL_TYPE_SAP_ID) ? -1 : 1;
  };

  const sorted = selectedRows.sort(sortFunction);

  console.log(sorted);

  for (let i = 0; i < selectedRows.length; i++) {
    worksheet.addRow({ ...sorted[i] });
  }

  const MRPTypeDropdown = dropdownValues?.MRPType?.join();
  const ABCDropdown = dropdownValues?.ABCIndicator?.join();
  const MRPGroupDropdown = MRPGroup?.join();
  const StatusDropdown = PlantMaterialStatus?.join();
  const LotSizeDropdown = dropdownValues?.LotSize?.join();
  const MRPControllerDropdown = MRPController?.join();

  for (let i = 1; i < worksheet.actualRowCount; i++) {
    worksheet.getCell('A'.concat(i + 1)).dataValidation = {
      formulae: ['"Selected,Unselected"'],
      ...validationObj,
    };
    worksheet.getCell('U'.concat(i + 1)).dataValidation = {
      formulae: ['"No impact,Savings,Investment"'],
      alignment: {
        horizontal: 'center',
      },
      ...validationObj,
    };
    worksheet.getCell('I'.concat(i + 1)).dataValidation = {
      formulae: [`"${MRPTypeDropdown}"`],
      alignment: {
        horizontal: 'center',
      },
      ...validationObj,
    };
    worksheet.getCell('J'.concat(i + 1)).dataValidation = {
      formulae: [`"${LotSizeDropdown}"`],
      ...validationObj,
    };
    worksheet.getCell('P'.concat(i + 1)).dataValidation = {
      formulae: [`"${MRPGroupDropdown}"`],
      ...validationObj,
    };
    worksheet.getCell('Q'.concat(i + 1)).dataValidation = {
      formulae: [`"${MRPControllerDropdown}"`],
      ...validationObj,
    };
    worksheet.getCell('R'.concat(i + 1)).dataValidation = {
      formulae: [`"${StatusDropdown}"`],
      ...validationObj,
    };
    worksheet.getCell('S'.concat(i + 1)).dataValidation = {
      formulae: [`"${ABCDropdown}"`],
      ...validationObj,
    };
  }

  for (let i = 0; i < 21; i++) {
    const x = worksheet.getCell(String.fromCharCode('A'.charCodeAt(0) + i).concat(1));
    x.font = {
      bold: true,
      size: 12,
    };
    x.alignment = {
      wrapText: true,
    };
  }

  worksheet.eachRow((row, i) => {
    row.protection = { locked: i === 1 };
    row.eachCell((cell) => {
      cell.protection = {
        locked: i === 1,
        lockText: i === 1,
      };
    });
  });

  worksheet.columns.forEach((column, columnIndex) => {
    if (protectedColumns.includes(columnIndex)) {
      column.eachCell((cell) => {
        cell.protection = {
          lockText: true,
          locked: true,
        };
      });
    }
  });

  await worksheet.protect('', { selectLockedCells: true, selectUnlockedCells: true, deleteRows: true });

  downloadFile(wb, 'MRP_data.xlsx');
};

export const readXLSX = (data, updateValuesFromImport) => {
  const wb = new ExcelJS.Workbook();
  const uploadRows = [];
  wb.xlsx.load(data).then((workbook) => {
    workbook.eachSheet((sheet) => {
      sheet.eachRow((row, rowIndex) => {
        if (rowIndex < 2) return;
        const vals = [...row.values];
        uploadRows.push({
          selected: vals[1],
          PLANT_FACILITY_SAP_ID: vals[2],
          MATERIAL_TYPE_SAP_ID: vals[3],
          EDAM_MATERIAL_TYPE_NM: vals[4],
          CC_MAX_CRITICALITY: vals[5],
          FAIR_PRICE: vals[6],
          TOT_STOCK: vals[7],
          docCount: vals[8],
          MRPType: vals[9],
          LotSize: vals[10],
          ReorderPoint: vals[11],
          MinimumLotSize: vals[12],
          MaximumStockLevel: vals[13],
          RoundingValue: vals[14],
          FixedLotSize: vals[15],
          MRPGroup: vals[16],
          MRPController: vals[17],
          PlantMaterialStatus: vals[18],
          ABCIndicator: vals[19],
          monetaryImpact: vals[20],
          monetaryImpactType: vals[21],
        });
      });
    });
    updateValuesFromImport(uploadRows);
  }).catch((error) => console.log(error));
};

const checkIfHasMonImps = (key, monImps) => {
  const impact = monImps?.find((impact) => createKey(impact.plant, impact.material) === key);

  if (impact) {
    return {
      monetaryImpact: impact.impact,
      monetaryImpactType: impact.monImpType,
    };
  }
  return {
    monetaryImpact: '0',
    monetaryImpactType: 'No impact',
  };
};

export const generateExcelValues = (input, original, monImps) => {
  Object.entries(original).map((og) => {
    if (input[og[0]]) {
      Object.entries(input[og[0]]).forEach((inp) => {
        const key = inp[0];
        // eslint-disable-next-line prefer-destructuring
        if (key) og[1][key] = inp[1];
      });
    } else {
      Object.entries(og[1]).forEach((entry) => {
        // eslint-disable-next-line prefer-destructuring
        if (isString(entry[1]) && entry[1].includes('.000')) {
          const noDecimal = entry[1].split('.')[0];
          og[1][entry[0]] = noDecimal;
        }
      });
    }
  });

  Object.entries(original).map((og) => {
    const key = og[0];
    // eslint-disable-next-line prefer-destructuring
    const impactData = checkIfHasMonImps(key, monImps);
    og[1].monetaryImpact = impactData.monetaryImpact;
    og[1].monetaryImpactType = impactData.monetaryImpactType;
  });
  const asRows = Object.values(original);
  return asRows;
};

export const lockedColumns = ['CC_MAX_CRITICALITY', 'PLANT_FACILITY_SAP_ID', 'MATERIAL_TYPE_SAP_ID', 'TOT_STOCK', 'docCount', 'monetaryImpact', 'monetaryImpactType', 'FAIR_PRICE', 'EDAM_MATERIAL_TYPE_NM'];
