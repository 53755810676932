import React from 'react';
import {
  Card, Col, PageHeader, Row, Timeline,
} from 'antd';
import 'antd/dist/antd.css';
import './FeatureTimeline.css';
import { BulbOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { pages, latest } from './FeatureReleaseData';

const FeatureTimeline = () => {
  const latestRelease = latest?.features.map((item) => (
    <p key={item} className="item">{item}</p>
  ));

  const features = pages?.map((page) => (
    <Timeline.Item
      key={page.label}
      position="left"
      label={(
        <div style={{ fontWeight: 'bold' }}>
          {page.label}
        </div>
      )}
    >
      {page.features.map((feature) => (
        <p key={feature} className="item">{feature}</p>
      ))}
    </Timeline.Item>
  ));

  return (

    <Row>
      <Col>
        <PageHeader
          title="Feature Timeline"
          ghost={false}
          className="feature-header"
        >
          <Card>
            <Timeline className="timeline" mode="alternate">
              <Timeline.Item
                label={<div className="timelineHeader">Latest release</div>}
                color="green"
                position="left"
                dot={<ClockCircleOutlined style={{ fontSize: '16px' }} />}
              >
                <div>{latest?.date}</div>
                {latestRelease}
              </Timeline.Item>
              <Timeline.Item
                color="blue"
                label={<div className="timelineHeader">List of features</div>}
                position="left"
                dot={<BulbOutlined style={{ fontSize: '16px' }} />}
              />
              {features}
            </Timeline>
          </Card>
        </PageHeader>

      </Col>
    </Row>
  );
};

export default FeatureTimeline;
