import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Col, Modal, Row, Select, Typography,
} from 'antd';
import _ from 'lodash';
import * as searchOptions from '../MaterialListView/HeaderComponents/AdvancedSearch/AdvancedSearchOptions';

const DashboardFiltersSettings = ({
  visible, setVisible, updateSettings, openWithDefault,
}) => {
  const defaultOperators = {
    STATUS_LIGHT: 'EqualTo',
    CC_CONSUMPTION_SEGMENT_GROUP: 'EqualTo',
    CC_SEGMENT_SUBGROUP_NM: 'EqualTo',
    PLANT_TYPE: 'EqualTo',
    SEGMENT: 'EqualTo',
    MATERIAL_TYPE_IN_CONTRACT_IND: 'EqualTo',
  };
  const [searchOperators, setSearchOperators] = useState(defaultOperators);
  const [filterVals, setFilterValues] = useState({});
  const dropDownVals = useSelector((state) => state.materialState.dropdownValues);
  const localFilters = useSelector((state) => state.commonState.localDashboardFilters);
  const defaultFilters = useSelector(
    (state) => state.commonState?.userSettings?.DASHBOARD_FILTERS,
  );

  const { Text } = Typography;
  const { Option } = Select;

  const filterArray = [
    { name: 'Status light', index: 'STATUS_LIGHT' },
    { name: 'Consumption Group', index: 'CC_CONSUMPTION_SEGMENT_GROUP' },
    { name: 'Consumption subgroup', index: 'CC_SEGMENT_SUBGROUP_NM' },
    { name: 'Plant type', index: 'PLANT_TYPE' },
    { name: 'Material Segment', index: 'SEGMENT' },
    { name: 'In Agreement', index: 'MATERIAL_TYPE_IN_CONTRACT_IND' },
  ];

  const setFilters = (reset = false) => {
    if (visible) {
      if (reset) {
        const filterObj = JSON.parse(defaultFilters ?? '{}');
        setFilterValues(filterObj?.filterVals ?? {});
        setSearchOperators(filterObj?.searchOperators ?? defaultOperators);
      } else if (localFilters?.filterVals) {
        setFilterValues(localFilters?.filterVals ?? {});
        setSearchOperators(localFilters?.searchOperators ?? defaultOperators);
      } else if (defaultFilters) {
        const filterObj = JSON.parse(defaultFilters ?? '{}');
        setFilterValues(filterObj?.filterVals ?? {});
        setSearchOperators(filterObj?.searchOperators ?? defaultOperators);
      }
    }
  };

  useEffect(() => {
    setFilters(openWithDefault);
  }, [defaultFilters, visible]);

  const updateOperator = (e, type) => {
    setSearchOperators({
      ...searchOperators,
      [type]: e,
    });
  };

  const updateFilterVals = (e, type) => {
    if (e.length === 0) {
      setFilterValues(
        _.omit(filterVals, type),
      );
    } else {
      setFilterValues({
        ...filterVals,
        [type]: e,
      });
    }
  };

  const saveFiltersSettings = () => {
    updateSettings('DASHBOARD_FILTERS', JSON.stringify({ searchOperators, filterVals }));
    setVisible(false);
  };

  const getOperatorSelector = (type) => {
    const operatorList = searchOptions.dropdownOptions;
    return (
      <Select
        placeholder="Select condition"
        value={searchOperators?.[type] ?? null}
        style={{ width: '100%' }}
        size="middle"
        onChange={(e) => updateOperator(e, type)}
      >
        {operatorList.map((o) => searchOptions.getOption(o.value, o.display, o.symbol, o.disabled))}
      </Select>
    );
  };

  const getFilterInput = (type) => (
    <Select
      placeholder="Select value"
      value={filterVals?.[type] ?? undefined}
      style={{ width: '100%' }}
      size="middle"
      onChange={(e) => updateFilterVals(e, type)}
      mode="multiple"
      allowClear
    >
      {dropDownVals?.[type]?.map(({ DROPDOWN_VALUE }) => (
        <Option key={DROPDOWN_VALUE} value={DROPDOWN_VALUE}>{DROPDOWN_VALUE}</Option>
      ))}
    </Select>
  );

  const getRow = (text, type) => (
    <Row justify="space-between" className="filters-row" key={type} gutter={[9]}>
      <Col className="filterCol1" span={5}>
        <Text style={{ verticalAlign: 'middle' }}>{`${text}: `}</Text>
      </Col>
      <Col className="filterCol2" span={6}>
        {getOperatorSelector(type)}
      </Col>
      <Col className="filterCol3" span={13}>
        {getFilterInput(type)}
      </Col>
    </Row>
  );

  const clearFilters = () => {
    setFilterValues({});
    setSearchOperators({ ...defaultOperators });
  };

  return (
    <Modal
      className="dashboard-filters-modal"
      title="Dashboard filters settings"
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={[
        <Button key="Reset" type="primary" onClick={() => setFilters(true)}>Reset to default</Button>,
        <Button key="Clear" type="primary" onClick={clearFilters}>Clear All</Button>,
        <Button key="Save" type="primary" onClick={saveFiltersSettings}>Save</Button>,
        <Button key="Cancel" onClick={() => setVisible(false)}>Cancel</Button>,
      ]}
    >
      <>
        {filterArray.map((filter) => getRow(filter.name, filter.index))}
      </>
    </Modal>
  );
};

export default DashboardFiltersSettings;

DashboardFiltersSettings.defaultProps = {
  visible: false,
  openWithDefault: false,
};

DashboardFiltersSettings.propTypes = {
  setVisible: PropTypes.func.isRequired,
  updateSettings: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  openWithDefault: PropTypes.bool,
};
