import materialReducer from './materialReducer';
import commonReducer from './CommonReducer';
import authReducer from './authReducer';
import dashboardReducer from './dashboardReducer';
import worklistReducer from './worklistReducer';
import notificationReducer from './notificationReducer';
import loadingReducer from './loadingReducer';
import materialDetailsReducer from './MaterialDetailsReducer';
import materialMasterReducer from './materialMasterReducer';
import SimulationReducer from './SimulationReducer';
import testReducer from './testReducer';

/* eslint-disable import/prefer-default-export */

export const reducers = {
  materialState: materialReducer,
  commonState: commonReducer,
  authState: authReducer,
  dashboardState: dashboardReducer,
  worklistState: worklistReducer,
  notificationState: notificationReducer,
  loadingState: loadingReducer,
  materialDetailsState: materialDetailsReducer,
  simState: SimulationReducer,
  testState: testReducer,
  materialMasterState: materialMasterReducer,
};
