/* eslint-disable no-else-return */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Skeleton } from 'antd';
import _ from 'lodash';
import allActions from '../../../../actions';
import * as loadingKeys from '../../../../actions/DashboardDomain/LoadingKeys';
import { MODES } from '../../../Common/GlobalConstants';
import HorizontalGraphWidget from './HorizontalGraphWidget';
import styles from './styles.module.scss';

const HorizontalGraphsContainer = ({ widgetID }) => {
  const dispatch = useDispatch();
  const selectedPlants = useSelector((state) => state.commonState.selectedPlants);
  const selectedCCs = useSelector((state) => state.commonState.selectedCompanyCodes);
  const dashboardMode = useSelector((state) => state.commonState.dashboardMode);
  const plannedOrders = useSelector((state) => state.dashboardState.plannedOrders);
  const settingsLoaded = useSelector((state) => state.commonState?.loaded?.userSettings);
  const loading = useSelector((state) => state.dashboardState.loading);
  const SQLOnly = useSelector((state) => state.commonState?.SQLOnly?.dashboard);
  const [inventoryData, setInventoryData] = useState({});

  const selectedExists = () => {
    if (selectedCCs?.length === 0 && selectedPlants?.length === 0 && plannedOrders?.A) {
      return true;
    }
    if (dashboardMode === MODES.PLANT) {
      const key = `P${selectedPlants?.sort().join('')}`;
      return !!plannedOrders?.[key];
    }
    const key = `CC${selectedCCs?.sort().join('')}`;
    return !!plannedOrders?.[key];
  };

  const updatePlannedOrders = () => {
    if (selectedCCs?.length === 0 && selectedPlants?.length === 0) {
      setInventoryData(plannedOrders.A);
    } else if (dashboardMode === MODES.PLANT) {
      setInventoryData(plannedOrders[`P${selectedPlants?.sort().join('')}`]);
    } else { setInventoryData(plannedOrders[`CC${selectedCCs?.sort().join('')}`]); }
  };

  useEffect(() => {
    if (selectedPlants && selectedCCs && settingsLoaded && dashboardMode
      && (SQLOnly === true || SQLOnly === false)) {
      if (selectedExists()) {
        updatePlannedOrders();
      } else {
        if (loading?.[loadingKeys?.[widgetID]] === true) return;
        dispatch(allActions.DashboardActions.setLoading(loadingKeys?.[widgetID]));
        dispatch(
          allActions.DashboardActions?.[widgetID](
            dashboardMode === MODES.PLANT ? selectedPlants : null,
            dashboardMode === MODES.CC ? selectedCCs : null,
            dashboardMode,
            SQLOnly,
          ),
        );
      }
    }
  }, [selectedPlants, selectedCCs, settingsLoaded, dashboardMode, plannedOrders, SQLOnly]);

  return (
    <div className={styles.widgetHeight}>
      {!loading?.[loadingKeys?.plannedOrders]
        && ((dashboardMode === MODES.PLANT && _.isEqual(inventoryData?.plants, selectedPlants))
          || (dashboardMode === MODES.CC && _.isEqual(inventoryData?.companyCodes, selectedCCs)))
        ? (
          <HorizontalGraphWidget
            inventoryData={inventoryData?.data}
            widgetID={widgetID}
          />
        )
        : (
          <Skeleton
            active
            paragraph={{ rows: 5 }}
          />
        )}
    </div>
  );
};
export default HorizontalGraphsContainer;

HorizontalGraphsContainer.defaultProps = {

};
HorizontalGraphsContainer.propTypes = {
  widgetID: PropTypes.string.isRequired,
};
