import React from 'react';
import PropTypes from 'prop-types';
import { InputNumber } from 'antd';
import './MultiPlantTable.css';

const InputCell = ({
  value, index, plantID, changeValue,
}) => (index === 0 ? (
  <InputNumber
    className="inputNumber"
    value={value}
    min={0}
    onChange={(v) => changeValue(v, plantID)}
  />
) : (
  <>{value}</>
));

export default InputCell;

InputCell.defaultProps = {
  value: null,
};

InputCell.propTypes = {
  value: PropTypes.number,
  index: PropTypes.number.isRequired,
  plantID: PropTypes.string.isRequired,
  changeValue: PropTypes.func.isRequired,
};
