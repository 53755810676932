import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Button, Tooltip,
} from 'antd';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import AdvancedSearchItem from './AdvancedSearchItem';
import './AdvancedSearch.css';

const AdvancedSearch = ({
  materialListFilters, submit, onClose, canViewPrices, materialMaster,
}) => {
  const [form] = Form.useForm();
  const [searchForm, setSearchForm] = useState(form);
  const [items, setItems] = useState([]);
  const [deletionFilter, setDeletionFilter] = useState(null);
  const [deletionFilterText, setDeletionFilterText] = useState('Set deletion filter');

  const removeItem = useCallback((item) => {
    setItems((prevItems) => prevItems.filter((x) => x.key !== item));
  }, [setItems]);

  const addItem = () => {
    const id = uuidv4();
    const blankItem = (
      <AdvancedSearchItem
        materialMaster={materialMaster}
        id={id}
        key={id}
        removeItem={removeItem}
        form={searchForm}
        canViewPrices={canViewPrices}
      />
    );
    setItems((prevItems) => [...prevItems, blankItem]);
  };

  const changeDelFilter = (filterVals) => {
    setDeletionFilter(filterVals);
    if (filterVals.includes('0') && filterVals.includes('1')) {
      setDeletionFilterText('Display all materials');
    } else if (filterVals.includes('1')) {
      setDeletionFilterText('Display only materials marked for deletion');
    } else {
      setDeletionFilterText('Hide materials marked for deletion');
    }
  };

  useEffect(() => {
    if (materialListFilters) {
      const filters = [];
      const deletionObj = Object.keys(materialListFilters).find((x) => x === 'MATERIAL_TYPE_DELETION_IND');
      if (deletionObj) {
        const vals = materialListFilters.MATERIAL_TYPE_DELETION_IND[0]?.FilterValue;
        if (vals?.length) {
          changeDelFilter(vals);
        }
      }
      Object.keys(materialListFilters)
        .filter((x) => x !== 'MATERIAL_TYPE_DELETION_IND')
        .forEach((column) => {
          materialListFilters[column].forEach((filter) => {
            const id = uuidv4();
            filters.push(
              <AdvancedSearchItem
                id={id}
                key={id}
                column={filter.ColumnName}
                condition={filter.FilterOperator}
                input={filter.FilterValue}
                removeItem={removeItem}
                form={searchForm}
                canViewPrices={canViewPrices}
                materialMaster={materialMaster}
              />,
            );
          });
        });
      setItems(filters);
    }
  }, [materialListFilters]);

  const onFinish = (formValues) => {
    const filters = {};
    const newMaterialListFilters = {};
    Object.entries(formValues).forEach((formValue) => {
      const filterAndField = formValue[0].split('+');
      const filterID = filterAndField[0];
      const field = filterAndField[1];
      const value = formValue[1];
      if (field === 'column') {
        filters[filterID] = {};
        filters[filterID].ColumnName = value;
      } else if (field === 'condition') {
        filters[filterID].FilterOperator = value;
      } else if (field === 'input') {
        if (Array.isArray(value)) {
          filters[filterID].FilterValue = value;
        } else if (moment.isMoment(value)) {
          filters[filterID].FilterValue = [value.format('YYYY-MM-DD')];
        }
      }
    });
    Object.entries(filters).forEach((filter) => {
      const filterData = filter[1];
      if (!newMaterialListFilters[filterData.ColumnName]) {
        newMaterialListFilters[filterData.ColumnName] = [{
          ColumnName: filterData.ColumnName,
          FilterOperator: filterData.FilterOperator,
          FilterValue: filterData.FilterValue,
        }];
      } else {
        newMaterialListFilters[filterData.ColumnName].push(
          {
            ColumnName: filterData.ColumnName,
            FilterOperator: filterData.FilterOperator,
            FilterValue: filterData.FilterValue,
          },
        );
      }
    });
    const materialTypeDeletion = {
      MATERIAL_TYPE_DELETION_IND: [
        {
          ColumnName: 'MATERIAL_TYPE_DELETION_IND',
          FilterOperator: 'EqualTo',
          FilterValue: deletionFilter,
        },
      ],
    };
    onClose();
    submit(deletionFilter
      ? { ...newMaterialListFilters, ...materialTypeDeletion }
      : newMaterialListFilters);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    console.log(errorInfo.values);
  };

  useEffect(() => {
    setSearchForm(form);
  }, [form]);

  return (
    <Form
      form={searchForm}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      validateMessages={{ required: 'This field is required' }}
      requiredMark
    >
      {items}
      <div className="buttonContainer">
        <div>
          <Tooltip title="Add a search element" mouseEnterDelay={1.5}>
            <Button onClick={() => addItem()}> Add </Button>
          </Tooltip>
        </div>
        <div>
          <Button onClick={() => onClose()}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </div>
      </div>
      <div style={{ margin: '6px' }}>
        <span style={{ marginLeft: '5px' }}>{deletionFilterText}</span>
        <br />
        <Button
          onClick={() => changeDelFilter(['0'])}
          type={deletionFilter?.length === 1 && deletionFilter?.[0] === '0' ? 'primary' : null}
        >
          Hide marked
        </Button>
        <Button
          onClick={() => changeDelFilter(['1'])}
          type={deletionFilter?.length === 1 && deletionFilter?.[0] === '1' ? 'primary' : null}
        >
          Show only marked
        </Button>

        <Button onClick={() => changeDelFilter(['0', '1'])} type={deletionFilter?.length === 2 ? 'primary' : null}>
          Show all materials
        </Button>
      </div>
    </Form>
  );
};

export default AdvancedSearch;

AdvancedSearch.defaultProps = {
  materialMaster: false,
};

AdvancedSearch.propTypes = {
  materialListFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  submit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  canViewPrices: PropTypes.bool.isRequired,
  materialMaster: PropTypes.bool,
};
