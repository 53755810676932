import React, { useState, useEffect } from 'react';
import {
  Table, Row, Col, Button, Input, Tooltip, Select,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../actions/index';
import * as loadingKeys from '../../../actions/common/LoadingKeys';
import './helpers/ConfigTable.css';
import { MODES } from '../../Common/GlobalConstants';
import { stringOptions } from '../MaterialListView/HeaderComponents/AdvancedSearch/AdvancedSearchOptions';
import { outlierColumns } from './helpers/columns';
import ConfigSelector from './helpers/ConfigSelector';

const OutlierConfig = () => {
  const dispatch = useDispatch();
  const allPlants = useSelector((state) => state.commonState.plants);
  const allCompanyCodes = useSelector((state) => state.commonState.companyCodes);
  const outliers = useSelector((state) => state.commonState.outliers);
  const user = useSelector((state) => state.authState.user);

  const [selectedPlants, setSelectedPlants] = useState([]);
  const [selectedCompanyCodes, setSelectedCompanyCodes] = useState([]);
  const [operator, setOperator] = useState();
  const [input, setInput] = useState();
  const [invalid, setInvalid] = useState();

  const [filteredTable, setFilteredTable] = useState([]);

  const [showPlant, setShowPlant] = useState(true);

  const { Option } = Select;

  useEffect(() => {
    dispatch(allActions.CommonActions.setLoading(loadingKeys.outliers));
    dispatch(allActions.CommonActions.getOutliers());
  }, []);

  const submitChanges = () => {
    const changeTable = [];
    let isPlant = true;
    if (selectedPlants.length > 0) {
      selectedPlants.forEach((plant) => changeTable.push({
        plantCC: plant,
        operator,
        input,
      }));
    } else if (selectedCompanyCodes.length > 0) {
      isPlant = false;
      selectedCompanyCodes.forEach((cc) => changeTable.push({
        plantCC: cc,
        operator,
        input,
      }));
    }
    dispatch(allActions.CommonActions.addOutliers(changeTable, isPlant, user?.account?.username));
  };

  const deleteOutlier = (rec) => {
    dispatch(allActions.CommonActions.deleteOutlier(rec.key));
  };

  const updatePlantCC = (event, type, isTagClose = false) => {
    if (type === MODES.PLANT) {
      setSelectedPlants(event.map((e) => e.key));
    }
    if (type === MODES.CC) {
      if (isTagClose) {
        setSelectedCompanyCodes((prev) => prev?.filter((cc) => cc !== event));
      } else setSelectedCompanyCodes(event.map((e) => e.key));
    }
  };

  const updateInput = (e) => {
    const str = e.target?.value || '';
    setInput(str.substring(0, 8));
  };

  // Table of current outliers
  useEffect(() => {
    if (outliers?.length > 0) {
      if (showPlant && selectedPlants?.length > 0) {
        setFilteredTable(outliers.filter((o) => selectedPlants.includes(o.PLANT_FACILITY_SAP_ID)));
      } else if (!showPlant && selectedCompanyCodes?.length > 0) {
        setFilteredTable(outliers.filter((o) => selectedCompanyCodes.includes(o.COMPANY_CODE)));
      } else {
        setFilteredTable(outliers);
      }
    }
  }, [selectedPlants, selectedCompanyCodes, outliers]);

  useEffect(() => {
    setFilteredTable(outliers);
    setSelectedPlants([]);
    setSelectedCompanyCodes([]);
  }, [showPlant]);

  useEffect(() => {
    const isInvalid = !input?.length > 0 || !operator
      || (!selectedPlants?.length > 0 && !selectedCompanyCodes?.length > 0);
    setInvalid(isInvalid);
  }, [input, operator, selectedPlants, selectedCompanyCodes]);

  return (
    <>
      <Row gutter={4}>
        <Col className="plantCCSelector" span={12}>
          <ConfigSelector
            showPlant={showPlant}
            setShowPlant={setShowPlant}
            allPlants={allPlants}
            allCompanyCodes={allCompanyCodes}
            selectedPlants={selectedPlants}
            setSelectedPlants={setSelectedPlants}
            selectedCompanyCodes={selectedCompanyCodes}
            setSelectedCompanyCodes={setSelectedCompanyCodes}
            updatePlantCC={updatePlantCC}
            disableCC
          />
        </Col>
        <Col span={4}>
          <Select
            size="large"
            placeholder="Select condition"
            onChange={setOperator}
            value={operator}
            style={{ width: '100%' }}
          >
            {stringOptions.map((o) => (
              <Option value={o.value} key={o.value}>
                {o.display}
              </Option>
            ))}
          </Select>
        </Col>
        <Col span={5}>
          <Input
            style={{ width: '100%' }}
            size="large"
            value={input}
            placeholder="Input (maximum of 8 characters)"
            onChange={(e) => { updateInput(e); }}
          />
        </Col>
        <Col span={3}>
          <Tooltip title={invalid ? 'Please fill out plant/cc, condition and input' : ''}>
            <Button
              block
              size="large"
              type="primary"
              style={{ width: '100%' }}
              onClick={() => submitChanges()}
              disabled={invalid}
            >
              Add outlier
            </Button>
          </Tooltip>
        </Col>
      </Row>
      <br />
      <Table
        dataSource={filteredTable}
        rowClassName={(record, index) => (index % 2 === 0 ? 'table-row-light' : 'table-row-dark')}
        columns={outlierColumns(deleteOutlier)}
        size="small"
        pagination={{ pageSize: 20 }}
      />
    </>
  );
};

export default OutlierConfig;
