/* eslint-disable camelcase */
import * as actions from './MaterialMasterActionTypes';
import apiAction from '../utilities/CommonMethods';
import {
  Base_URL,
  Get_MaterialMaster_URL,
  Get_MaterialMaster_SearchCount_URL,
  Get_MaterialMaster_Columns_URL,
} from '../APIUrls';

const clearMMStoreState = () => ({
  type: actions.clearMMStoreState,
  payload: null,
});

const setCheckMatMasterCount = (data) => ({
  type: actions.checkMMListCount,
  payload: data,
});

const clearMMData = () => ({
  type: actions.clearMMData,
  payload: null,
});

const fetchMMList = (materialMasterViewState, searchID) => {
  const url = Base_URL + Get_MaterialMaster_URL;

  function setMMData(data, _searchID) {
    return {
      type: actions.receiveMM,
      payload: { data, searchID: _searchID },
    };
  }
  function onFailure(error) {
    console.log('Error occured fetching more data to material master view');
    console.log(error);
    return setCheckMatMasterCount(true);
  }
  return apiAction({
    url,
    method: 'POST',
    data: {
      SortColumn: materialMasterViewState.sortColumn?.length > 0
        ? materialMasterViewState.sortColumn : undefined,
      SortDirection: materialMasterViewState.sortDirection?.length > 0
        ? materialMasterViewState.sortDirection : undefined,
      SearchFilters: materialMasterViewState.searchFilters,
      NoOfRecords: materialMasterViewState.scrollPage.currentNoOfRecords
        + materialMasterViewState.scrollPage.noOfRecordsToFetch,
    },
    onSuccess: (data) => setMMData(data, searchID),
    onFailure,
    onFailureNotification: (error) => ({
      Type: 'error',
      Title: 'Material Master',
      Page: '/material-master',
      searchID,
      Message: error?.code === 'ETIMEOUT'
        ? 'Request timed out, please try again'
        : 'Failed to load material list data',
    }),
    label: actions.receiveMM,
  });
};
const setMMCountNull = () => ({
  type: actions.clearMMCount,
  payload: null,
});

const fetchMMCount = (materialViewState, searchID) => {
  const url = Base_URL + Get_MaterialMaster_SearchCount_URL;
  function setMMCount(data, _searchID) {
    return {
      type: actions.setMMCount,
      payload: { data, searchID: _searchID },
    };
  }
  return apiAction({
    url,
    method: 'POST',
    data: {
      SearchFilters: materialViewState.searchFilters,
    },
    onSuccess: (data) => setMMCount(data, searchID),
    onFailure: () => console.log('Error occured fetching MM count'),
    label: actions.fetchMMCount,
  });
};

const fetchMMDynamicWLCount = (searchFilters, wlID, wlName) => {
  const url = Base_URL + Get_MaterialMaster_SearchCount_URL;
  function setDynamicWLCount(data) {
    return {
      type: actions.setDynamicWLCount,
      payload: { data, wlID },
    };
  }
  return apiAction({
    url,
    method: 'POST',
    data: {
      SearchFilters: JSON.parse(searchFilters),
    },
    onSuccess: (data) => setDynamicWLCount(data),
    onFailure: () => console.log('Error occured fetching worklist count'),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Worklist record count',
      Message: `Error occured while fetching record count of worklist ${wlName}`,
    }),
    label: actions.setDynamicWLCount,
  });
};

const fetchMaterialColumns = (
) => {
  const url = Base_URL + Get_MaterialMaster_Columns_URL;
  function setMMColumns(data) {
    return {
      type: actions.receiveMMColumns,
      payload: data,
    };
  } return apiAction({
    url,
    method: 'GET',
    onSuccess: setMMColumns,
    onFailure: () => console.log('Error occured fetching material list columns'),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Could not retrieve columns',
      Message: 'An error occured while fetching column name mappings',
    }),
    label: actions.receiveMMColumns,
  });
};

const setSelectedRowKeys = (keys) => ({
  type: actions.setSelectedRowKeys,
  payload: keys,
});

const setLocalMaterialView = (data) => ({
  type: actions.setLocalMMView,
  payload: data,
});

const selectView = (data) => ({
  type: actions.selectView,
  payload: data,
});

const setDisplayHeaderCells = (data) => ({
  type: actions.setMMDisplayHeaderCells,
  payload: data,
});

const setLoading = () => ({
  type: actions.setLoading,
});

const resetComplete = () => ({
  type: actions.resetMMComplete,
});

const setSearchID = (searchID) => ({
  type: actions.setSearchID,
  payload: searchID,
});

export default {
  fetchMMList,
  fetchMMDynamicWLCount,
  setSelectedRowKeys,
  setLocalMaterialView,
  selectView,
  setDisplayHeaderCells,
  setLoading,
  setCheckMatMasterCount,
  fetchMMCount,
  fetchMaterialColumns,
  setMMCountNull,
  clearMMData,
  clearMMStoreState,
  resetComplete,
  setSearchID,
};
