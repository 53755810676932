import {
  Button, Card, Divider, Row, Select, Space, Tag, Tooltip,
} from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../actions';
import { MODES } from '../../Common/GlobalConstants';
import MultiItemSelector from '../../Common/MultiItemSelector';
import DashboardFiltersSettings from '../Settings/DashboardFiltersSettings';
import WidgetContainer from './WidgetContainer';
import { SettingCodes } from '../Settings/SettingTypes';

const { Option } = Select;

const Dashboard = () => {
  const numberOfItems = 6;
  const dispatch = useDispatch();

  const userState = useSelector((state) => state.authState.user);
  const userSettings = useSelector((state) => state.commonState.userSettings);
  const plantGroups = useSelector((state) => state.commonState.plantGroups);
  const plants = useSelector((state) => state.commonState.plants);
  const companyCodes = useSelector((state) => state.commonState.companyCodes);
  const useDefaultSettings = useSelector((state) => state.commonState.useDefaultSettings);
  const settingsLoaded = useSelector((state) => state.commonState?.loaded?.userSettings);
  const defaultFilters = useSelector(
    (state) => state.commonState?.userSettings?.DASHBOARD_FILTERS,
  );

  const storedGrpsNdPlants = useSelector(
    (state) => state.commonState.userSettings?.DEFAULT_GROUPS_AND_PLANTS,
  );

  const [formattedGrps, formatPlantGrps] = useState([]);

  const [mode, setMode] = useState(MODES.PLANT);
  const [plantList, sortPlantList] = useState([]);
  const [selectedPlants, setSelectedPlants] = useState([]);
  const [selectedPlantValues, setSelectedPlantValues] = useState([]);
  const [selectedCCs, setSelectedCCs] = useState([]);
  const [plantNames, setPlantNames] = useState({});
  const [ccNames, setCCNames] = useState({});
  const [ccList, sortCCList] = useState([]);
  const [maxTagCount, setTagCount] = useState(0);
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [localFilters, setLocalFilters] = useState({});
  const [grpsNdPlants, setGrpsNdPlants] = useState({
    groups: [],
    plants: [],
  });
  const [cards, editCards] = useState([]);

  // load list of plants
  useEffect(() => {
    if (plants?.length > 0) {
      const tempPlantNames = {};
      plants.forEach((p) => {
        tempPlantNames[p.PLANT_FACILITY_SAP_ID] = p.PLANT_FACILITY_SAP_NM;
      });
      setPlantNames(tempPlantNames);
      const pList = plants.sort((p1, p2) => p1.PLANT_FACILITY_SAP_ID - p2.PLANT_FACILITY_SAP_ID);
      sortPlantList(pList);
    }
  }, [plants]);

  // load list of company codes
  useEffect(() => {
    if (companyCodes?.length > 0) {
      const tempCCs = {};
      companyCodes.forEach((cc) => {
        tempCCs[cc.Company_Code] = cc.Company_Name;
      });
      setCCNames(tempCCs);
      const tempccList = companyCodes?.sort((cc1, cc2) => cc1.Company_Code - cc2.Company_Code);
      sortCCList(tempccList);
    }
  }, [companyCodes]);

  // load user defaults

  useEffect(() => {
    if (plantGroups?.length) {
      const formatting = plantGroups?.map((group) => ({
        name: group?.Name,
        value: JSON.stringify(group?.PlantList),
      }));
      const sorted = formatting.sort((a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      });
      formatPlantGrps(sorted);
    }
  }, [plantGroups]);

  const updatePlants = (e) => {
    const tempGroups = [];
    const grpsObj = [];
    let grpCount = 0;
    let newSelectedPlants = [];
    let unSelectedPlants = [];
    const event = e?.filter((x) => Object.keys(x)?.length > 0);
    selectedPlants.forEach((val) => {
      if (unSelectedPlants.length === 0
        && typeof JSON.parse(val) !== 'object'
        && !event.find((p) => p.value === val)) {
        unSelectedPlants.push(val);
      }
    });
    event?.forEach((group) => {
      if (typeof JSON.parse(group?.value) === 'object') {
        JSON.parse(group?.value)?.forEach(
          (plantID) => (unSelectedPlants?.[0] !== plantID
            ? newSelectedPlants.push(plantID) : null),
        );
        tempGroups.push(group.value);
        grpCount += 1;
        if (!JSON.parse(group?.value)?.includes(unSelectedPlants[0])) {
          grpsObj.push({
            grpName: group.key,
            value: group.value,
          });
        }
      } else {
        newSelectedPlants.push(group.value);
      }
    });
    let groups = [...tempGroups];
    selectedPlants.forEach((val) => {
      // If group is un-selected, all corresponding plants are un-selected.
      if (typeof JSON.parse(val) === 'object' && !(tempGroups.find((x) => x === val))) {
        let tempUnSelectedPlants = JSON.parse(val); // Get all plants of unselected grp
        JSON.parse(val)?.forEach((p) => {
          const plantExistInOtherGrp = tempGroups?.find(
            // Check if plant exist in other SELECTED grps
            (grp) => JSON.parse(grp)?.find((x) => x === p),
          );
          if (plantExistInOtherGrp) {
            // Since, plant exist in other grp, It is not unSelected
            tempUnSelectedPlants = tempUnSelectedPlants.filter((x) => x !== p);
          }
        });
        unSelectedPlants = [...unSelectedPlants, ...tempUnSelectedPlants];
        // if plant is removed, Corresponding grps are un-selected
      } else if (!newSelectedPlants.find((p) => p === val)) {
        groups = groups.filter(
          (grp) => JSON.parse(grp).length && !(JSON.parse(grp).find((p) => p === val)),
        );
      }
    });
    newSelectedPlants = newSelectedPlants.filter(
      (x) => !unSelectedPlants.some((p) => x === p),
    );
    newSelectedPlants = newSelectedPlants?.filter(
      (id, index) => newSelectedPlants?.indexOf(id) === index,
    );
    setTagCount(grpCount);
    setSelectedPlantValues(newSelectedPlants);
    setSelectedPlants([...groups, ...newSelectedPlants]);
    setGrpsNdPlants({
      groups: grpsObj,
      plants: newSelectedPlants.filter(
        (x) => !grpsObj.length || !grpsObj.find((g) => g?.value?.includes(x)),
      ),
    });
  };

  const changeSelectedPlants = (event) => {
    if (event?.length > 0) {
      updatePlants(event);
    } else {
      setSelectedPlants([]);
      setSelectedPlantValues([]);
    }
  };

  const changeSelectedCCs = (event) => {
    if (event?.length > 0) {
      setSelectedCCs(event.map((e) => e.key));
    } else {
      setSelectedCCs([]);
    }
  };

  const unSelectOption = (e, value, field) => {
    if (field === MODES.CC) {
      const cList = selectedCCs.filter((x) => !(x === value));
      setSelectedCCs([...cList]);
    } else {
      let pList = selectedPlants.filter((x) => x !== value);
      pList = pList.filter((val) => {
        // if plant is removed, Corresponding grps are un-selected
        if (typeof JSON.parse(val) === 'object' && JSON.parse(val)?.find((p) => p === value)) {
          return false;
        }
        return true;
      });
      setSelectedPlants(pList);
      setSelectedPlantValues(pList.filter((x) => typeof JSON.parse(x) !== 'object'));
      setGrpsNdPlants((curState) => {
        const newGrpsState = {};
        newGrpsState.groups = curState.groups?.filter((x) => {
          if (pList.includes(x.value)) return true;
          return false;
        });
        newGrpsState.plants = pList.filter((x) => {
          const val = JSON.parse(x);
          if (typeof val === 'object') return false;
          if (newGrpsState.groups?.find((g) => g.value?.includes(x))) return false;
          return true;
        });
        return newGrpsState;
      });
    }
  };

  const pasteValue = (key, event) => {
    event.preventDefault();
    const pastedText = event.clipboardData.getData('text');
    const splitText = pastedText && pastedText.split('\r\n').filter((s) => s.length > 0);
    if (!splitText || splitText.length < 1) return;
    if (key === MODES.PLANT) {
      const newPlants = [...new Set([...selectedPlants, ...splitText])];
      const filteredPlants = newPlants.filter(
        (val) => plants.some((plant) => plant.PLANT_FACILITY_SAP_ID === val),
      );
      setSelectedPlants(filteredPlants);
      setSelectedPlantValues(filteredPlants.filter((x) => typeof JSON.parse(x) !== 'object'));
    }
    if (key === MODES.CC) {
      const newCCs = [...new Set([...selectedCCs, ...splitText])];
      const filteredCCs = newCCs.filter(
        (val) => companyCodes.some((cc) => cc.Company_Code === val),
      );
      setSelectedCCs(filteredCCs);
    }
  };

  const sortCCs = () => {
    if (!ccList) {
      return;
    }
    const sortedList = ccList?.sort((a, b) => {
      const aSearch = selectedCCs?.find((x) => a.Company_Code === x);
      const bSearch = selectedCCs?.find((x) => b.Company_Code === x);
      if ((aSearch && bSearch) || (!aSearch && !bSearch)) {
        return a.Company_Code - b.Company_Code;
      }
      if (aSearch) {
        return -1;
      }
      return 1;
    });
    sortCCList(sortedList.length ? [...sortedList] : ccList);
  };

  const sortPlants = () => {
    if (!selectedPlants || selectedPlants?.length === 0) {
      const pList = plantList?.sort(
        (p1, p2) => p1.PLANT_FACILITY_SAP_ID - p2.PLANT_FACILITY_SAP_ID,
      );
      sortPlantList(pList);
      return;
    }
    const filteredPlants = plantList?.filter((p) => {
      const x = selectedPlants?.find((plant) => plant === p.PLANT_FACILITY_SAP_ID);
      if (x) {
        return true;
      }
      return false;
    });
    const unselectedPlants = plantList?.filter((p) => {
      const x = selectedPlants?.find((plant) => plant === p.PLANT_FACILITY_SAP_ID);
      if (x) {
        return false;
      }
      return true;
    });
    sortPlantList(filteredPlants.concat(unselectedPlants));
  };

  const changeMode = (val) => {
    setMode(val);
    setSelectedCCs([]);
    setSelectedPlants([]);
    setSelectedPlantValues([]);
  };

  const displaySelectedIDs = ({ value }, field) => {
    try {
      if (typeof JSON.parse(value) === 'object') {
        return null;
      }
    } catch (exception) {
      console.log(value);
      console.log(exception);
      return null;
    }
    let title;
    if (field === MODES.PLANT) {
      title = plants?.find(
        (p) => value && p?.PLANT_FACILITY_SAP_ID === value,
      )?.PLANT_FACILITY_SAP_NM;
    } else {
      title = companyCodes?.find((cc) => value && cc?.Company_Code === value)?.Company_Name;
    }
    if (!title) title = value;
    else title = `${title}(${value})`;
    return (
      <Tooltip title={title}>
        <Tag
          key={value}
          closable="true"
          onClose={(e) => unSelectOption(e, value, field)}
        >
          <span style={{ fontSize: '16px' }}>{value}</span>
        </Tag>
      </Tooltip>
    );
  };

  const updateDashboardValues = (plantVals, ccVals, modeVal) => {
    setMode(modeVal);
    setSelectedPlantValues(plantVals);
    // setSelectedPlants(plantVals);
    setSelectedCCs(ccVals);
    if (_.isEmpty(localFilters)) {
      setLocalFilters(JSON.parse(defaultFilters ?? null));
      dispatch(allActions.CommonActions.setLocalDashboardFilters(
        JSON.parse(defaultFilters ?? null),
      ));
    }
    dispatch(allActions.CommonActions.selectPlant(plantVals));
    dispatch(allActions.CommonActions.selectCompanyCode(ccVals));
    dispatch(allActions.CommonActions.setDashboardMode(modeVal));
    dispatch(allActions.CommonActions.setSelectedGrpsNdPlants(grpsNdPlants));
    // dispatch(allActions.CommonActions.setLocalDashboardFilters(JSON.parse(defaultFilters ?? null)));
  };

  useEffect(() => {
    if (settingsLoaded) {
      if (userSettings?.DEFAULT_PLANTS?.length && useDefaultSettings) {
        updateDashboardValues(userSettings.DEFAULT_PLANTS, [], MODES.PLANT);
        if (storedGrpsNdPlants) {
          const tempObj = JSON.parse(storedGrpsNdPlants ?? '');
          if (tempObj?.selectedPlants) {
            setTagCount(tempObj.selectedPlants?.filter((x) => {
              try {
                if (typeof JSON.parse(x) === 'object') return true;
                return false;
              } catch (exception) {
                console.log(exception, x);
                return false;
              }
            })?.length || 0);
            setSelectedPlants(tempObj.selectedPlants);
          }
          if (tempObj?.grpsNdPlants) {
            const temp = _.cloneDeep(tempObj?.grpsNdPlants);
            setGrpsNdPlants(temp);
            dispatch(allActions.CommonActions.setSelectedGrpsNdPlants(temp));
          }
        }
      } else if (userSettings?.DEFAULT_COMPANY_CODES?.length && useDefaultSettings) {
        updateDashboardValues([], userSettings?.DEFAULT_COMPANY_CODES, MODES.CC);
      } else {
        updateDashboardValues([], [], MODES.PLANT);
      }
    }
  }, [settingsLoaded, userSettings, useDefaultSettings, formattedGrps]);

  const update = () => {
    dispatch(allActions.CommonActions.selectPlant(selectedPlantValues));
    dispatch(allActions.CommonActions.selectCompanyCode(selectedCCs));
    dispatch(allActions.CommonActions.setDashboardMode(mode));
    dispatch(allActions.CommonActions.setSelectedGrpsNdPlants(grpsNdPlants));
  };

  const updateFilters = (key, filterSettings) => {
    const tempFilters = JSON.parse(filterSettings);
    setLocalFilters(tempFilters);
    dispatch(allActions.CommonActions.setLocalDashboardFilters(tempFilters));
  };

  const saveLayout = (reset = false) => {
    const currentLayout = cards.map((c) => ({ key: c.type }));
    try {
      const userID = userState.uniqueId;
      dispatch(allActions.CommonActions.setUserSetting(
        userID, SettingCodes.dashboardLayout, reset ? '' : currentLayout,
      ));
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Card style={{ backgroundColor: '#FAFAFA', padding: '2px 0px' }}>
        <Row gutter={8}>
          <Space>
            {mode === MODES.PLANT ? (
              <MultiItemSelector
                placeHolder="Select plants"
                items={plantList
                  && plantList.map((x) => (
                    { id: x.PLANT_FACILITY_SAP_ID, name: x.PLANT_FACILITY_SAP_NM }
                  ))}
                groupedItems={formattedGrps}
                multiSelect
                selectedValues={selectedPlants}
                handleChange={(event) => changeSelectedPlants(event)}
                clear={() => changeSelectedPlants()}
                tagRender={(props) => displaySelectedIDs(props, MODES.PLANT)}
                maxTagCount={maxTagCount + numberOfItems}
                maxTagPlaceholder={(
                  <Tooltip
                    title={selectedPlantValues?.length > 0 && selectedPlantValues.map((p) => `${plantNames[p]} (${p})`).join('\r\n')}
                    overlayClassName="selectorTooltip"
                  >
                    {`+ ${(selectedPlantValues?.length ?? 0) - numberOfItems}`}
                  </Tooltip>
                )}
                maxTagTextLength={30}
                onPasteCapture={(e) => pasteValue(MODES.PLANT, e)}
                onDropdownVisibleChange={(open) => sortPlants(open)}
                style={{ width: '580px' }}
              />
            ) : (
              <MultiItemSelector
                placeHolder="Select company code"
                items={ccList
                  && ccList.map((x) => (
                    { id: x.Company_Code, name: x.Company_Name }
                  ))}
                multiSelect
                tagRender={(props) => displaySelectedIDs(props, MODES.CC)}
                selectedValues={selectedCCs}
                handleChange={(event) => changeSelectedCCs(event)}
                clear={() => setSelectedCCs()}
                maxTagCount={numberOfItems}
                maxTagPlaceholder={(
                  <Tooltip
                    title={selectedCCs?.length > 0 && selectedCCs.map((cc) => `${ccNames[cc]} (${cc})`).join('\r\n')}
                    overlayClassName="selectorTooltip"
                  >
                    {`+ ${(selectedCCs?.length ?? 0) - numberOfItems}`}
                  </Tooltip>
                )}
                maxTagTextLength={20}
                onPasteCapture={(e) => pasteValue(MODES.CC, e)}
                onDropdownVisibleChange={(e) => sortCCs(e)}
                style={{ width: '580px' }}
              />
            )}
            <Select
              style={{ width: '290px' }}
              value={mode}
              onChange={(val) => changeMode(val)}
              size="large"
            >
              <Option value="plant">Plant</Option>
              <Option value="companyCode">Company code</Option>
            </Select>
            <Button style={{ width: '80px' }} type="primary" size="large" onClick={update}>Go</Button>
            <DashboardFiltersSettings
              visible={filtersVisible}
              setVisible={setFiltersVisible}
              updateSettings={updateFilters}
              localFilters={localFilters}
            />
            <Button
              style={{ width: '200px' }}
              type={!_.isEmpty(localFilters?.filterVals) ? 'primary' : 'default'}
              size="large"
              onClick={() => setFiltersVisible(true)}
            >
              Additional Filters
            </Button>
            <Divider type="vertical" />
            <Button
              type="primary"
              size="large"
              onClick={() => saveLayout(false)}
            >
              Save layout
            </Button>
            <Button
              size="large"
              onClick={() => saveLayout(true)}
            >
              Reset to default layout
            </Button>
          </Space>
        </Row>
      </Card>
      <WidgetContainer cards={cards} editCards={editCards} />
    </>
  );
};
export default Dashboard;
