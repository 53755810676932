export const getMaterial = 'GET_MATERIAL';
export const getMaterialDescriptions = 'GET_MATERIAL_DESCRIPTIONS';
export const functionalLocation = 'FUNCTIONAL_LOCATION';
export const bomExplosion = 'BOM_EXPLOSION';
export const consumptionHistory = 'CONSUMPTION_HISTORY';
export const reservations = 'RESERVATIONS';
export const changelogs = 'CHANGELOGS';
export const purchasing = 'PURCHASING';
export const materialChangelogs = 'MATERIAL_CHANGELOGS';
export const materialLeadTime = 'MATERIAL_LEADTIME';
export const materialMovement = 'MATERIAL_MOVEMENT';
export const relatedPlants = 'RELATED_PLANTS';
export const warehouse = 'WAREHOUSE';
export const mrp = 'MRP';
export const getLongText = 'GET_LONG_TEXT';
export const getClassification = 'GET_CLASSIFICATION';
export const getIOTAComments = 'GET_IOTA_COMMENTS';
export const getWarehouseComments = 'GET_WAREHOUSE_COMMENTS';
export const getXPlant = 'X_PLANT';
export const returnDocument = 'RETURN_DOCUMENT';
export const fixedBin = 'FIXED_BIN';
