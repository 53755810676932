import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Table, Card, Col, Row,
  Button,
  Input,
} from 'antd';
import allActions from '../../../../../../actions';
import styles from './styles.module.scss';
import { getCorrectTokenFromUser } from '../../../../../../utils/token';
import { showNotification } from '../../../../../../actions/utilities/Notifications/NotificationActions';
import '../TableTab.css';

const FixedBin = ({ materialNumber, plant }) => {
  const [data, setData] = useState([]);
  const userState = useSelector((state) => state.authState.user);
  const [editMode, setEditMode] = useState(false);
  const [changes, setChanges] = useState({});
  const [loading, setLoading] = useState(false);
  const [warehouseNumber, setWarehouseNumber] = useState();
  const dispatch = useDispatch();

  const validStorageTypes = ['001', '002', '003', '020'];

  const getRowClassName = (record, index) => (index % 2 === 0 ? 'table-row-light' : 'table-row-dark');

  const compareOnStorType = (a, b) => {
    if (a.StorageType < b.StorageType) {
      return -1;
    }
    if (a.StorageType > b.StorageType) {
      return 1;
    }
    return 0;
  };

  const fetchFixedBin = async () => {
    const fetchWarehouseData = await allActions.MaterialDetailsActions.fetchWarehouseByPlant(plant, getCorrectTokenFromUser(userState), dispatch);
    if (fetchWarehouseData) {
      setWarehouseNumber(fetchWarehouseData.WAREHOUSE_NUMBER);
      const fixedBinData = await allActions.SapAPIActions.getFixedBin(materialNumber, fetchWarehouseData.WAREHOUSE_NUMBER, getCorrectTokenFromUser(userState), dispatch);
      if (fixedBinData?.length > 0) {
        const validData = fixedBinData.filter((v) => validStorageTypes.includes(v.StorageType));
        const sortOnStorType = validData.sort(compareOnStorType);
        setData(sortOnStorType);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    setWarehouseNumber();
    setData([]);
    fetchFixedBin();
  }, [plant]);

  const updateChanges = (value, storageData) => {
    setChanges((prev) => ({
      ...prev,
      [storageData.StorageType]: {
        fixedBin: value,
      },
    }));
  };

  const onlyUpperCase = (value) => (value === '' ? '' : value.toUpperCase());

  const submitChanges = async () => {
    setLoading(true);
    setEditMode(false);
    let hasErr = false;
    const asList = Object.entries(changes);
    await Promise.all(asList.map(async (v) => {
      hasErr = await allActions.SapAPIActions.setFixedBin(materialNumber, warehouseNumber, v[0], onlyUpperCase(v[1].fixedBin), getCorrectTokenFromUser(userState), dispatch);
    }));
    if (hasErr) {
      setEditMode(true);
      setLoading(false);
    } else {
      fetchFixedBin(warehouseNumber);
      dispatch(showNotification({
        Type: 'success',
        Title: 'SAP update successful',
        Message: `Fixed Bin for warehouse ${warehouseNumber} updated`,
      }));
    }
  };

  const columns = [
    {
      children: [{
        title: 'Plant',
        dataIndex: 'plant',
        render: () => plant,
        width: '8%',
      }],
    },
    {
      children: [{
        title: 'Whs',
        render: (v) => v.WarehouseNumber,
        width: '16%',
      }],
    },
    {
      children: [{
        title: 'Stor Type',
        render: (v) => v.StorageType,
        width: '17%',
      }],
    },
    {
      children: [{
        title: 'Fixed bin',
        render: (v) => (editMode ? (
          <div>
            <Input
              defaultValue={v.StorageBin}
              onChange={(e) => updateChanges(e.target?.value, v)}
              maxLength={10}
            />
          </div>
        ) : v.StorageBin),
        width: '35%',
      }],
    },
  ];

  return (
    <div style={{ height: '100%', overflowX: 'hidden' }}>
      <h3>Fixed bin</h3>
      <Row gutter={48}>
        <Col span={24}>
          <Card
            className="tabCard"
            style={{
              textAlign: 'center', paddingLeft: '0', paddingRight: '0',
            }}
          >
            <Table
              className={`tabTable ${styles.fixedBinTable}`}
              rowClassName={getRowClassName}
              columns={columns}
              dataSource={data}
              pagination={false}
              size="small"
              loading={loading}
            />
          </Card>
        </Col>
      </Row>
      <div className={styles.buttons}>
        {!editMode && <Button onClick={() => { setEditMode(!editMode); }}>Edit</Button>}
        {editMode && (
        <>
          <Button onClick={() => { setEditMode(!editMode); }}>Reset</Button>
          <Button type="primary" onClick={() => { submitChanges(); }}>Submit</Button>
        </>
        )}
      </div>
    </div>
  );
};

export default FixedBin;
