import * as NotificationActionTypes from './NotificationActionTypes';

export const showNotification = (notificationData) => (dispatch) => {
  dispatch({ type: NotificationActionTypes.showNotificationType, notificationData });
};

export const clearNotification = () => (dispatch) => {
  dispatch({ type: NotificationActionTypes.clearNotificationType });
};

export const storeNotifKey = (notificationKey) => ({
  type: NotificationActionTypes.storeNotifKey,
  notificationKey,
});
