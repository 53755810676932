import React from 'react';
import Icon from '@ant-design/icons/lib/components/Icon';
import { ReactComponent as IndicatorIcon } from '../../../images/icons/radio_button_checked_black_24dp.svg';

const columns = [
  { title: 'Status', dataIndex: 'success', width: '3%' },
  { title: 'Button', dataIndex: 'button', width: '5%' },
  { title: 'Endpoint name', dataIndex: 'endpointName', width: '10%' },
  { title: 'Args', dataIndex: 'args', width: '40%' },
  { title: 'Error msg', dataIndex: 'errorMsg' },
];

const getIndicatorColor = (statusIndicator) => {
  switch (statusIndicator) {
    case true:
      return '#30E317'; // green
    case false:
      return '#F13919'; // red
    default:
      return '#EDEDED'; // gray
  }
};

const successIcon = (test, testState, multiple = false) => {
  let status = null;
  if (multiple) {
    if (test.some((x) => testState[x.action]?.error
      || testState[x.action]?.success === false)) {
      status = false;
    } else if (!test.some((x) => testState[x.action]?.success === undefined)) {
      status = true;
    }
  } else {
    status = testState[test.action]?.success;
  }
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Icon
        component={IndicatorIcon}
        style={{ color: getIndicatorColor(status), fontSize: 16 }}
      />
    </div>
  );
};

export {
  columns,
  successIcon,
};
