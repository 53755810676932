/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import Loading from '../../Common/Loading';
import allActions from '../../../actions';
import { USE_WORKLIST_VIEW } from '../../Common/GlobalConstants';
import MaterialMasterContainer from './MaterialMasterContainer';
import { modes } from '../MaterialListView/MaterialListFunctions';

function MMWorklistContainer() {
  const { worklistID } = useParams();
  const dispatch = useDispatch();
  const worklistDetails = useSelector((state) => state.worklistState.worklistDetails?.[0]);
  const [detailsResolved, setDetailsResolved] = useState(false);

  useEffect(() => {
    if (worklistID) {
      dispatch(allActions.WorkListActions.fetchWorklistDetails(worklistID));
    }
  }, [worklistID]);

  useEffect(() => {
    if (worklistDetails?.SelectedColumns?.length > 0 && worklistDetails?.WorklistID === worklistID) {
      dispatch(allActions.MaterialListActions.selectView(USE_WORKLIST_VIEW));
      setDetailsResolved(true);
    }
  }, [worklistDetails]);

  return (
    detailsResolved
      ? (
        <MaterialMasterContainer
          worklistID={worklistID}
          worklistDetails={{
            WorklistID: worklistDetails?.WorklistID,
            WorklistName: worklistDetails?.WorklistName,
            WorklistDescription: worklistDetails?.WorklistDescription,
            CreatedDate: worklistDetails?.CreatedDate,
            ModifiedDate: worklistDetails?.ModifiedDate,
            CreatedBy: worklistDetails?.CreatedBy,
            ModifiedBy: worklistDetails?.ModifiedBy,
            IsWorklistShared: worklistDetails?.IsWorklistShared,
            CreatedUserEmail: worklistDetails?.CreatedUserEmail,
            ModifiedByUserEmail: worklistDetails?.ModifiedByUserEmail,
            ListType: worklistDetails?.ListType,
          }}
          headerCells={worklistDetails?.SelectedColumns ? JSON.parse(worklistDetails.SelectedColumns) : []}
          searchFilters={worklistDetails?.SearchFilters ? JSON.parse(worklistDetails.SearchFilters) : {}}
          sortColumn={worklistDetails?.SortColumn}
          sortDirection={worklistDetails?.sortDirection}
          mode={modes.DYNAMIC}
        />
      )
      : <Loading text="Loading Worklist" />
  );
}

MMWorklistContainer.propTypes = {

};

export default MMWorklistContainer;
