import {
  timeString,
  isoDateToFirstOfYear,
  isoDateToLastOfYear,
} from '../../../../Common/DateAndNumberFunctions';

const GRAPH_TYPES = {
  consumption: 'CONSUMPTION',
  purchasing: 'PURCHASING',
};

const generateAllMonths = (data) => {
  const first = isoDateToFirstOfYear(data[0].EDAM_POSTING_DT);
  const last = isoDateToLastOfYear(data[data.length - 1].EDAM_POSTING_DT);
  let dateCounter = first;
  const dateArray = [{ x: timeString(dateCounter), y: 0 }];
  while (timeString(dateCounter) !== timeString(last)) {
    dateCounter = new Date(dateCounter.getFullYear(), dateCounter.getMonth() + 1, 1);
    dateArray.push({ x: timeString(dateCounter), y: 0 });
  }
  return dateArray;
};

const getStrings = (type) => {
  const strings = {
    CONSUMPTION: {
      quantity: 'Consumed qty',
      events: 'Consumptions',
      showQuantity: 'Show consumed',
      showEvents: 'Show consumptions',
    },
    PURCHASING: {
      quantity: 'Purchased qty',
      events: 'Purchases',
      showQuantity: 'Show purchased',
      showEvents: 'Show purchases',
    },
  };
  return strings[type];
};

const formatLargeNumber = (val, currency = false, toFixed = 1) => {
  let num = val;
  let x = false;
  if (num < 0) {
    num = 0 - num;
    x = true;
  }
  if (num >= 1000000000 && !currency) {
    return `${((x ? 0 - num : num) / 1000000000).toFixed(toFixed).replace(/\.0$/, '')}G`;
  }
  if (num >= 1000000) {
    return `${((x ? 0 - num : num) / 1000000).toFixed(toFixed).replace(/\.0$/, '')}${
      currency ? ' M' : 'M'}`;
  }
  if (num >= 1000) {
    return `${((x ? 0 - num : num) / 1000).toFixed(toFixed).replace(/\.0$/, '')}${
      currency ? ' K' : 'K'}`;
  }
  return Number.parseFloat(x ? (0 - num) : num).toFixed(toFixed);
};

export {
  GRAPH_TYPES, generateAllMonths, getStrings, formatLargeNumber,
};
