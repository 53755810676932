import { CloseOutlined, InfoCircleTwoTone } from '@ant-design/icons';
import {
  Card, Button, Select, Space, Tooltip,
} from 'antd';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DraggableModal from '../../Common/DraggableModal';
import {
  widgetTypes, limitedWidgetTypes, superUserWidgets, widgetTypeKeys,
} from './Widgets/widgetTypes';
import Loading from '../../Common/Loading';
import MonetaryImpact from './Widgets/MonetaryImpact';
import HorizontalGraphsContainer from './Widgets/HorizontalGraphsContainer';
import './widget.css';
import GraphWidgetsContainer from './Widgets/GraphWidgetsContainer';
import HistoricalDataWidgetsContainer from './Widgets/HistoricalDataWidgetsContainer';
import consumptionGroupID from '../../../images/consumptionGroupID.png';

const { Option } = Select;

const Widget = ({
  settings, index, setType, removeWidget, canViewValues, isSuperUser,
}) => {
  const [widgets, setWidgets] = useState([]);
  const getAvailableWidgets = () => {
    if (isSuperUser || canViewValues) {
      setWidgets(superUserWidgets);
    } else {
      setWidgets(limitedWidgetTypes);
    }
  };

  useEffect(() => {
    getAvailableWidgets();
  }, [canViewValues, isSuperUser]);

  const [infoTitle, setInfoTitle] = useState('');
  const [infoContent, setInfoContent] = useState(<></>);
  const [infoModalVisible, setInfoModalVisible] = useState(false);

  const showInfoStorageCheckID = () => {
    setInfoTitle('Consumption Group');
    setInfoContent(
      <div
        onKeyPress={null}
        onClick={() => window.open(consumptionGroupID)}
        role="button"
        tabIndex={0}
      >
        <img style={{ cursor: 'pointer' }} title="Click to open image in new window" src={consumptionGroupID} alt="Status Tables" />
      </div>,
    );
    setInfoModalVisible(true);
  };

  const closeInfoModal = () => setInfoModalVisible(false);
  const modalTitle = <div className="handle">{infoTitle}</div>;

  const content = () => {
    switch (settings.type) {
      case widgetTypeKeys.optimalInventoryByStrategy:
        return (
          <GraphWidgetsContainer
            canViewValues={canViewValues}
            widgetID={widgetTypeKeys.optimalInventoryByStrategy}
            indexBy="id"
            label={superUserWidgets.optimalInventoryByStrategy}
            keysVisible={false}
            keys={['value']}
          />
        );
      case widgetTypeKeys.optimalInventoryByGroup:
        return (
          <GraphWidgetsContainer
            canViewValues={canViewValues}
            widgetID={widgetTypeKeys.optimalInventoryByGroup}
            indexBy="GROUP"
            label={superUserWidgets.optimalInventoryByGroup}
          />
        );
      case widgetTypeKeys.optimalInventoryBySP:
        return (
          <GraphWidgetsContainer
            canViewValues={canViewValues}
            widgetID={widgetTypeKeys.optimalInventoryBySP}
            indexBy="SUPPLY_PROCESS"
            label={superUserWidgets.optimalInventoryBySP}
          />
        );
      case widgetTypeKeys.optimalInventoryByCrit:
        return (
          <GraphWidgetsContainer
            canViewValues={canViewValues}
            widgetID={widgetTypeKeys.optimalInventoryByCrit}
            indexBy="CRITICALITY"
            label={superUserWidgets.optimalInventoryByCrit}
          />
        );
      case widgetTypeKeys.optimalInventoryByROP:
        return (
          <GraphWidgetsContainer
            canViewValues={canViewValues}
            widgetID={widgetTypeKeys.optimalInventoryByROP}
            indexBy="ROP_STATUS"
            label={superUserWidgets.optimalInventoryByROP}
          />
        );
      case widgetTypeKeys.plannedOrders:
        return (
          <HorizontalGraphsContainer
            widgetID={widgetTypeKeys.plannedOrders}
          />
        );
      case widgetTypeKeys.monetaryImpact:
        return <MonetaryImpact />;
      case widgetTypeKeys.excessStockByStatus:
        return (
          <GraphWidgetsContainer
            canViewValues={canViewValues}
            widgetID={widgetTypeKeys.excessStockByStatus}
            indexBy="GROUP"
            label={superUserWidgets.excessStockByStatus}
            keysVisible={false}
            textWidgetID={widgetTypeKeys.totalExcessStock}
          />
        );
      case widgetTypeKeys.cleanUpWidget:
        return (
          <GraphWidgetsContainer
            canViewValues={canViewValues}
            widgetID={widgetTypeKeys.cleanUpWidget}
            indexBy="GROUP"
            label={superUserWidgets.cleanUpWidget}
          />
        );
      case widgetTypeKeys.optimalInvByTime:
        return (
          <HistoricalDataWidgetsContainer
            canViewValues={canViewValues}
            widgetID={widgetTypeKeys.optimalInvByTime}
            label={superUserWidgets.optimalInvByTime}
            textWidgetID={widgetTypeKeys.optInvTot}
          />
        );
      case widgetTypeKeys.inventoryValByTime:
        return (
          <HistoricalDataWidgetsContainer
            canViewValues={canViewValues}
            widgetID={widgetTypeKeys.inventoryValByTime}
            label={superUserWidgets.inventoryValByTime}
            textWidgetID={widgetTypeKeys.inventoryValue}
          />
        );
      default:
        return (
          <Space style={{ width: '100%', justifyContent: 'center' }}>
            <Loading text="Select widget type" spinning={false} />
          </Space>
        );
    }
  };

  return (
    <>
      <DraggableModal
        content={infoContent}
        handle=".handle"
        title={modalTitle}
        centered
        visible={infoModalVisible}
        style={{ maxWidth: '80vw' }}
        onCancel={closeInfoModal}
        footer={[
          <Button key="close" type="primary" onClick={closeInfoModal}>
            Close
          </Button>,
        ]}
      >
        { }
      </DraggableModal>
      <Card
        className="dashboard-card"
        index={index}
        size="small"
        type="inner"
        style={{ height: 'auto' }}
        title={
          (
            <div>
              {settings.type === widgetTypeKeys.optimalInventoryByGroup && (
                <Tooltip title="Consumption Group Info">
                  <InfoCircleTwoTone onClick={showInfoStorageCheckID} />
                </Tooltip>
              )}
            </div>
          )
        }
        extra={(
          <>
            <Select
              bordered={false}
              style={{ width: 270, textAlign: 'right' }}
              placeholder="Widget type"
              value={superUserWidgets[settings.type]}
              onChange={(e) => setType(e, index)}
            >
              {Object.keys(widgets).map((type) => (
                <Option value={type} key={type}>{superUserWidgets[type]}</Option>))}
            </Select>
            <Button size="small" onClick={() => removeWidget(index)}><CloseOutlined /></Button>
          </>
        )}
      >
        <div
          draggable
          style={{ cursor: 'default' }}
          onDragStart={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          {content()}
        </div>
      </Card>

    </>
  );
};

export default Widget;

Widget.propTypes = {
  settings: PropTypes.shape({
    type: PropTypes.string,
    width: PropTypes.number,
  }).isRequired,
  index: PropTypes.number.isRequired,
  setType: PropTypes.func.isRequired,
  removeWidget: PropTypes.func.isRequired,
  canViewValues: PropTypes.bool.isRequired,
  isSuperUser: PropTypes.bool.isRequired,
};
