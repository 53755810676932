import { Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const TableCell = ({
  initValue, colName, onDataChange, record, disabled, addOn,
}) => {
  const [curValue, setCurValue] = useState(initValue);
  const { Option } = Select;
  const isNumber = (val) => /^-?[0-9]+(e[0-9]+)?(\.[0-9]+)?$/.test(val);
  // Cannot import from summaryColumns as it forms 'import cycle'(loop).
  // Or Can only use props to pass
  const impactType = {
    savings: 'Savings',
    noImpact: 'No impact',
    investment: 'Investment',
  };

  useEffect(() => {
    setCurValue(initValue);
  }, [initValue]);

  const getClassName = () => {
    if (colName !== 'Impact') return '';
    return isNumber(curValue) ? '' : 'invalidImpactInput';
  };

  const getCellRender = () => {
    switch (colName) {
      case 'Impact':
      case 'Notes':
        return (
          <Input
            className={getClassName()}
            value={curValue}
            onChange={(e) => setCurValue(e.target.value)}
            onBlur={(e) => onDataChange(e.target?.value, record, colName)}
            addonAfter={addOn}
          />
        );
      case 'ImpactType':
        return (
          <Select
            placeholder="Select Impact Type"
            value={curValue}
            style={{ width: '100%' }}
            size="middle"
            onChange={(e) => onDataChange(e, record, 'EditableType')}
            disabled={disabled}
          >
            <Option value={impactType.savings}>{impactType.savings}</Option>
            <Option value={impactType.investment}>{impactType.investment}</Option>
            <Option value={impactType.noImpact}>{impactType.noImpact}</Option>
          </Select>
        );
      default:
        return curValue;
    }
  };

  return getCellRender(curValue);
};

TableCell.defaultProps = {
  curValue: '',
  disabled: false,
  addOn: false,
};

TableCell.propTypes = {
  curValue: PropTypes.string,
  disabled: PropTypes.bool,
  colName: PropTypes.string.isRequired,
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  onDataChange: PropTypes.func.isRequired,
  addOn: PropTypes.bool,
};

export default TableCell;
