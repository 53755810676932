import { impactType } from './summaryColumns';

const match = (a, b) => String(a).toUpperCase() === String(b).toUpperCase();

const getCategoryArrays = (data) => {
  const savings = data.filter((x) => match(x.ImpactType, impactType.savings));
  const investment = data.filter((x) => match(x.ImpactType, impactType.investment));
  const noImpact = data.filter((x) => match(x.ImpactType, impactType.noImpact));
  return [savings, investment, noImpact];
};

const getSummary = (data = []) => {
  const [savings, investment, noImpact] = getCategoryArrays(data);
  const savingsObject = {
    impactType: impactType.savings,
    noOfRegistrations: savings.length,
    totalImpact: savings.reduce((acc, curr) => acc + Number.parseFloat(curr.Impact || 0), 0),
    lastRegDate: [...savings.sort(
      (a, b) => new Date(a.FormTimeStamp) - new Date(b.FormTimeStamp),
    )]?.pop()?.FormTimeStamp,
    key: impactType.savings,
  };
  const investmentObject = {
    impactType: impactType.investment,
    noOfRegistrations: investment.length,
    totalImpact: investment.reduce((acc, curr) => acc + Number.parseFloat(curr.Impact || 0), 0),
    lastRegDate: [...investment.sort(
      (a, b) => new Date(a.FormTimeStamp) - new Date(b.FormTimeStamp),
    )]?.pop()?.FormTimeStamp,
    key: impactType.investment,
  };
  const noImpactObject = {
    impactType: impactType.noImpact,
    noOfRegistrations: noImpact.length,
    totalImpact: noImpact.reduce((acc, curr) => acc + Number.parseFloat(curr.Impact || 0), 0),
    lastRegDate: [...noImpact.sort(
      (a, b) => new Date(a.FormTimeStamp) - new Date(b.FormTimeStamp),
    )]?.pop()?.FormTimeStamp,
    key: impactType.noImpact,
  };
  return [savingsObject, investmentObject, noImpactObject];
};

export { getCategoryArrays, getSummary };
