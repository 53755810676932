import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import { LoadingOutlined, SettingOutlined } from '@ant-design/icons';

export default function Loading({ text, spinning }) {
  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    }}
    >
      <Spin
        tip={text}
        indicator={spinning
          ? <LoadingOutlined style={{ fontSize: 28 }} spin />
          : <SettingOutlined style={{ fontSize: 24 }} />}
      />
    </div>
  );
}

Loading.defaultProps = {
  text: 'Loading...',
  spinning: true,
};

Loading.propTypes = {
  text: PropTypes.string,
  spinning: PropTypes.bool,
};
