import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { confirmColumns } from './massUpdateGlobals';

const RevertConfirm = ({ revertObject, textType }) => {
  const getDataSource = () => {
    if (!revertObject) return [];
    const source = [];
    revertObject.to_LongText?.forEach((m, i) => {
      const previousText = revertObject?.previousTexts?.find(
        (t) => t.Plant === m.Plant && t.MaterialNumber === m.MaterialNumber,
      )?.LongText;
      source.push({
        key: i,
        plant: m.Plant,
        material: m.MaterialNumber,
        currentText: previousText ?? '?',
        newText: m.LongText,
      });
    });
    return source;
  };

  return (
    <Table
      dataSource={getDataSource()}
      columns={confirmColumns(textType)}
      size="small"
      pagination={false}
      style={{ whiteSpace: 'pre' }}
    />
  );
};

export default RevertConfirm;

RevertConfirm.defaultProps = {
  revertObject: null,
};

RevertConfirm.propTypes = {
  revertObject: PropTypes.objectOf(PropTypes.any),
  textType: PropTypes.string.isRequired,
};
