import * as actionTypes from '../actions/auth/ActionTypes';

export default function authReducer(state = {}, action) {
  switch (action.type) {
    case actionTypes.loginSuccess:
      return {
        ...state,
        login: action.payload,
      };
    case actionTypes.storeToken:
      return {
        ...state,
        user: action.payload,
      };
    case actionTypes.updateAccountData:
      return {
        ...state,
        user: {
          ...state.user,
          account: action.payload,
        },
      };
    case actionTypes.setToLimitedViewer: {
      return {
        ...state,
        user: {
          ...state.user,
          idTokenClaims: {
            ...state.user.idTokenClaims,
            roles: ['LimitedViewer'],
          },
        },
      };
    }
    default:
      return state;
  }
}
