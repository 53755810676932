const limitedWidgetTypes = {
  optimalInventoryByStrategy: 'Optimal Inventory By Strategy',
  optimalInventoryByGroup: 'Optimal Inventory By Group',
  excessStockByStatus: 'Excess Stock by Status',
  optimalInventoryByCrit: 'Optimal Inventory By Criticality',
  optimalInventoryBySP: 'Optimal Inventory By Supply Process',
  plannedOrders: 'Planned Orders',
};

const widgetTypes = {
  ...limitedWidgetTypes,
  monetaryImpact: 'Monetary Impact Potential',
};

const superUserWidgets = {
  ...widgetTypes,
  cleanUpWidget: 'Clean Up',
  optimalInvByTime: 'Optimal Inventory by Time',
  inventoryValByTime: 'Inventory Value by Time',
  optimalInventoryByROP: 'Optimal Inventory By Reorder Point',
};

const widgetTypeKeys = {
  optimalInventoryByStrategy: 'optimalInventoryByStrategy',
  optimalInventoryByGroup: 'optimalInventoryByGroup',
  optimalInventoryBySP: 'optimalInventoryBySP',
  optimalInventoryByCrit: 'optimalInventoryByCrit',
  optimalInventoryByROP: 'optimalInventoryByROP',
  plannedOrders: 'plannedOrders',
  inventoryValue: 'inventoryValue',
  monetaryImpact: 'monetaryImpact',
  totalExcessStock: 'totalExcessStock',
  excessStockByStatus: 'excessStockByStatus',
  cleanUpWidget: 'cleanUpWidget',
  optInvTot: 'optInvTot',
  optimalInvByTime: 'optimalInvByTime',
  inventoryValByTime: 'inventoryValByTime',
};

export {
  limitedWidgetTypes, widgetTypes, superUserWidgets, widgetTypeKeys,
};
