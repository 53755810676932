import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Descriptions,
  Empty, Row, Tabs, Tooltip, Typography,
} from 'antd';
import PropTypes from 'prop-types';
import { InfoOutlined, PlusCircleTwoTone } from '@ant-design/icons';
import Item from 'antd/lib/list/Item';
import { useParams } from 'react-router';
import './SummaryStyle.css';
import Loading from '../../../../Common/Loading';
import { getLongText } from '../../../../../actions/MaterialDetailsDomain/LoadingKeys';
import CommentsContainer from './CommentsContainer';
import { COMMENT_TYPE } from '../../../../Common/GlobalConstants';
import allActions from '../../../../../actions';

const { TabPane } = Tabs;
const { Paragraph } = Typography;

function CommentSection({
  data, expandLongText, loading, classification, tableStyle, SAPwritebackAuthorized,
}) {
  const [activeKey, setActiveKey] = useState('material_memo');
  const defaultTab = useSelector(
    (state) => state.commonState?.userSettings?.COMMENT_SECTION_DEFAULT_TAB,
  );
  const { plant, material } = useParams();
  const dispatch = useDispatch();

  const labelStyle = {
    fontSize: '12px',
    height: '5px',
    padding: '0px 4px',
    width: '40%',
  };

  useEffect(() => {
    setActiveKey(defaultTab ?? 'material_memo');
  }, [defaultTab]);

  const updateLastChecked = () => {
    dispatch(allActions.SapAPIActions.activateLastChecked(plant, material));
  };

  const content = (key) => {
    if ((key === 'material_memo' && loading?.MRP) || loading[getLongText]) {
      return (
        <div style={{ width: '100%' }}>
          <Loading />
        </div>
      );
    }
    if (key === 'Pl_Ord_Txt') {
      return (
        <div>
          <Descriptions
            column={1}
            bordered
            labelStyle={labelStyle}
            contentStyle={{
              fontSize: '12px',
              height: '5px',
              padding: '0px 4px',
              width: '50ch',
            }}
          >
            <Item label="Last Checked">
              <div style={{ textAlign: 'center' }}>
                {data?.LastCheckedDate}
                <span style={{
                  border: '0.5px solid hsl(49.41deg 87.18% 84.71%)',
                  borderRadius: '25% 25%',
                  backgroundColor: 'hsl(49.41deg 87.18% 84.71%)',
                  marginLeft: '10px',
                }}
                >
                  <Tooltip title="Update Last Checked Date">
                    <InfoOutlined
                      style={{
                        fontSize: '18px',
                        color: 'hsl(201.04deg 83.75% 31.37%)',
                        fontWeight: 'bold',
                        rotate: '40deg',
                      }}
                      onClick={() => updateLastChecked()}
                    />
                  </Tooltip>
                </span>
              </div>
            </Item>
            <Item label="Planned Order Qty">
              <div style={{ textAlign: 'center' }}>{data?.CC_PLANNED_ORDER_QTY}</div>
            </Item>
          </Descriptions>
          <Paragraph className="commentParagaph" style={{ paddingTop: '10px' }}>
            {data?.[key] ?? ''}
          </Paragraph>
        </div>
      );
    }
    if (data?.[key]) {
      return (
        <Paragraph className="commentParagaph">
          {data[key]}
        </Paragraph>
      );
    }
    return (
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ fontSize: '12px' }} description="No comments found" />
    );
  };

  const longTextTab = (key, title, titleText) => (
    <TabPane tab={<div className="tabTitle" style={{ color: data[key] ? 'inherit' : '#999' }}>{title}</div>} key={key}>
      <>
        <Row className={data?.[key] ? 'commentContainer' : 'commentContainerEmpty'}>
          {content(key)}
        </Row>
        <Row style={{ float: 'right' }}>
          {(data?.[key] || ((key === 'material_memo' || key === 'Pl_Ord_Txt') && SAPwritebackAuthorized)) && (
            <Tooltip title={key === 'material_memo' && SAPwritebackAuthorized ? 'View full text / edit material memo' : 'View full text'}>
              <PlusCircleTwoTone style={{ height: '2vh' }} onClick={() => expandLongText(key, titleText)} />
            </Tooltip>
          )}
        </Row>
      </>
    </TabPane>
  );

  const classificationTab = () => {
    const classificationKeys = Object.keys(classification).filter((x) => x !== 'OLD_STATOIL_ARTICLECODE' && x !== 'MATERIAL_TYPE_SAP_ID');
    const items = classificationKeys.map((x) => <Item key={x} label={x}>{classification[x]}</Item>);
    return (
      <TabPane
        tab={
          (
            <div className="tabTitle">
              Class
              <br />
              data
            </div>
          )
        }
        key="classification"
      >
        <div style={{ height: '16vh', overflowY: 'auto' }}>
          <Descriptions
            column={1}
            bordered
            labelStyle={tableStyle}
            contentStyle={tableStyle}
          >
            {items}
          </Descriptions>
        </div>
      </TabPane>
    );
  };

  const iotaCommentTab = () => (
    <TabPane
      tab={(
        <div className="tabTitle" style={{ color: data.iotaComment ? 'inherit' : '#999' }}>
          {'IOTA '}
          <br />
          {' memo'}
        </div>
      )}
      key="IOTAComments"
    >
      <CommentsContainer
        type={COMMENT_TYPE.IOTA}
        data={data}
        expandLongText={expandLongText}
      />
    </TabPane>
  );

  const warehouseCommentTab = () => (
    <TabPane
      tab={(
        <div className="tabTitle" style={{ color: data.warehouseComment ? 'inherit' : '#999' }}>
          {'Whs '}
          <br />
          {' memo'}
        </div>
      )}
      key="WarehouseComments"
    >
      <CommentsContainer
        type={COMMENT_TYPE.WAREHOUSE}
        data={data}
        expandLongText={expandLongText}
      />
    </TabPane>
  );

  const changeActiveTab = (key) => {
    setActiveKey(key);
  };

  return (
    !loading
      ? <Loading text="Loading long text..." spinning />
      : (
        <Tabs
          size="small"
          type="card"
          style={{ position: 'relative' }}
          onChange={changeActiveTab}
          activeKey={activeKey}
        >
          {longTextTab('material_memo', (
            <div>
              {'Material '}
              <br />
              {' memo'}
            </div>),
          'Material memo')}
          {longTextTab('Pl_Ord_Txt', (
            <div>
              Pl Ord
              <br />
              {' Text'}
            </div>),
          'Planned Order Text')}
          {longTextTab('POtext', (
            <div>
              {'PO '}
              <br />
              {' text'}
            </div>),
          'PO Text')}
          {longTextTab('internal_comment', (
            <div>
              {'Internal '}
              <br />
              {' comment'}
            </div>),
          'Internal comment')}
          {classificationTab()}
          {iotaCommentTab()}
          {warehouseCommentTab()}
        </Tabs>
      )
  );
}

export default CommentSection;
CommentSection.defaultProps = {
  data: {},
  loading: {},
  classification: {},
};
CommentSection.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  expandLongText: PropTypes.func.isRequired,
  loading: PropTypes.objectOf(PropTypes.bool),
  classification: PropTypes.objectOf(PropTypes.any),
  tableStyle: PropTypes.objectOf(PropTypes.any).isRequired,
  SAPwritebackAuthorized: PropTypes.bool.isRequired,
};
