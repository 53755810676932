import React from 'react';
import { MODES } from '../../../Common/GlobalConstants';

const parameters = {
  manual: 'MANUAL',
  auto: 'AUTO',
  holding: 'HOLDING',
};

/**
 * @param {*} value input value from user
 * @returns false if invalid format, returns value converted to float if valid.
 */
const validatePOCost = (value) => {
  if (Number.isNaN(Number(value))) return false;
  const parsedVal = Number.parseFloat(value);
  if (parsedVal < 0 || parsedVal > 100000) return false;
  return parsedVal;
};

/**
 * @param {*} value input value from user
 * @returns false if invalid format, returns value converted to float if valid.
 */
const validateHoldingCost = (value) => {
  if (Number.isNaN(Number(value))) return false;
  const parsedVal = Number.parseFloat(value);
  if (parsedVal < 0.0 || parsedVal > 1) return false;
  return Number(parsedVal.toFixed(4));
};

/**
 * @param {*} value input value from user
 * @returns false if invalid format, returns value converted to float if valid.
 */
const validateInput = (value, parameter) => {
  if (parameter === parameters.manual || parameter === parameters.auto) {
    return validatePOCost(value);
  }
  if (parameter === parameters.holding) {
    return validateHoldingCost(value);
  }
  return false;
};

const addonAfter = (col, record) => {
  if (col === 'HOLDING_COST_PRCT') return '%';
  return <div style={{ width: '2vw' }}>{record?.COMPANY_CURRENCY_SAP_CD}</div>;
};

const formatPercent = (value) => +parseFloat(value * 100).toFixed(2);

const pasteValue = (key, event, selectedPlants, selectedCompanyCodes, allPlants,
  allCompanyCodes, setSelectedPlants, setSelectedCompanyCodes) => {
  event.preventDefault();
  const pastedText = event.clipboardData.getData('text');
  const splitText = pastedText && pastedText.split('\r\n').filter((s) => s.length > 0);
  if (!splitText || splitText.length < 1) return;
  if (key === MODES.PLANT) {
    const newPlants = [...new Set([...selectedPlants, ...splitText])];
    const filteredPlants = newPlants.filter(
      (val) => allPlants.some((plant) => plant.PLANT_FACILITY_SAP_ID === val),
    );
    setSelectedPlants(filteredPlants);
  }
  if (key === MODES.CC) {
    const newCCs = [...new Set([...selectedCompanyCodes, ...splitText])];
    const filteredCCs = newCCs.filter(
      (val) => allCompanyCodes.some((cc) => cc.Company_Code === val),
    );
    setSelectedCompanyCodes(filteredCCs);
  }
};

export {
  parameters, validatePOCost, validateHoldingCost, validateInput,
  addonAfter, formatPercent, pasteValue,
};
