import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router';
import Loading from '../components/Common/Loading';

const ProtectedRoute = ({ children, requiredRoles, redirectPath }) => {
  const user = useSelector((state) => state.authState.user);
  const checkRequiredRoles = (roles) => roles.some((value) => requiredRoles?.includes(value));

  return (
    user?.idTokenClaims?.roles ? (
      <Route
        render={() => (checkRequiredRoles(user?.idTokenClaims?.roles) ? (
          children
        ) : (
          <Redirect to={redirectPath} />
        ))}
      />
    ) : <Loading />
  );
};
export default ProtectedRoute;

ProtectedRoute.defaultProps = {
  redirectPath: '/dashboard',
};

ProtectedRoute.propTypes = {
  requiredRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  redirectPath: PropTypes.string,
  children: PropTypes.objectOf(PropTypes.any).isRequired,
};
