/* eslint-disable max-len */
import {
  Tooltip, Button, Checkbox, Menu, Dropdown,
} from 'antd'; import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { DownOutlined, LoadingOutlined } from '@ant-design/icons';
import { listModes, STATIC_WL_MAX_ITEMS } from '../../Common/GlobalConstants';
import allActions from '../../../actions';
import AddToStaticWL from '../MaterialListView/HeaderComponents/AddToStaticWL';
import DelegateMenu from './DelegateMenu';

export default function HeaderOptions({ data, canEdit }) {
  const [worklistMenuVisible, setWorklistMenuVisible] = useState(false);
  const [incRelatedPlants, setIncRelatedPlants] = useState(false);
  const [noOfItemsToAdd, setNoOfItemsToAdd] = useState(1);
  const [staticWLModal, setStaticWLModal] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authState.user);
  const relatedPlants = useSelector((state) => state.materialDetailsState.plantRelationships);
  const { plant, material } = useParams();

  const staticWorklists = useSelector((state) => state.worklistState?.staticWorklists);
  const relevantWorklists = useSelector((state) => state.worklistState?.relevantStaticWorklists);
  const materialCount = useSelector(
    (state) => state.materialState?.materiallistSearchCount?.[0]?.NoofRecords,
  );

  useEffect(() => {
    dispatch(allActions.WorkListActions.getRelevantStaticWLs(user.uniqueId, plant, material));
  }, [staticWorklists, plant, material]);

  const checkIncRelatedPlants = (checked) => {
    setIncRelatedPlants(checked);
    setNoOfItemsToAdd(checked ? relatedPlants[plant].length + 1 : 1);
  };

  const createMetadata = (worklist) => ({
    userID: user?.uniqueId,
    worklistID: worklist?.WORKLIST_ID,
    worklistName: worklist?.WorklistName,
  });

  const createItems = () => {
    const items = [{ plantID: plant, materialID: material }];
    if (incRelatedPlants) {
      relatedPlants[plant].forEach((e) => {
        items.push({ plantID: e.Plant_ID, materialID: material });
      });
    }
    return items;
  };

  const addToWorklist = (worklist) => {
    dispatch(allActions.WorkListActions.addToStaticWL(createMetadata(worklist), createItems(), listModes.matList));
  };

  const removeFromWorklist = (worklist) => {
    dispatch(allActions.WorkListActions.removeFromStaticWL(createMetadata(worklist), createItems(), listModes.matList));
  };

  const createSWL = () => {
    setWorklistMenuVisible(false);
    setSelectedItems(createItems());
    setStaticWLModal(true);
  };

  const existsInWorklistFilter = (worklist) => {
    if (relevantWorklists?.worklists?.length > 0) {
      return !relevantWorklists.worklists.some((w) => w.WORKLIST_ID === worklist.WORKLIST_ID);
    }
    return true;
  };

  const worklistMenu = () => (
    <Menu>
      <Menu.ItemGroup title={<span style={{ fontWeight: 'bold' }}>Add to Static Worklist</span>} style={{ overflowY: 'auto' }}>
        {staticWorklists
          ?.filter(existsInWorklistFilter)
          ?.sort((a, b) => (a.WorklistName > b.WorklistName ? 1 : -1))
          .map((worklist) => (
            <Menu.Item
              key={worklist.WORKLIST_ID}
              disabled={worklist.NoOfRecords + noOfItemsToAdd > STATIC_WL_MAX_ITEMS}
              onClick={() => addToWorklist(worklist)}
            >
              <Tooltip title={worklist.NoOfRecords + noOfItemsToAdd > STATIC_WL_MAX_ITEMS
                // eslint-disable-next-line prefer-template
                ? 'Worklist would exceed max. allowed number of items. Max allowed: ' + STATIC_WL_MAX_ITEMS
                : null}
              >
                {worklist.WorklistName}
              </Tooltip>
            </Menu.Item>
          ))}
      </Menu.ItemGroup>
      {
        relevantWorklists?.worklists?.length > 0
        && (
          <Menu.ItemGroup title={<span style={{ fontWeight: 'bold' }}>Remove from Static Worklist</span>} style={{ overflowY: 'auto' }}>
            {relevantWorklists?.worklists
              ?.sort((a, b) => (a.WorklistName > b.WorklistName ? 1 : -1))
              .map((worklist) => (
                <Menu.Item
                  key={worklist?.WORKLIST_ID + worklist?.WORKLIST_ID}
                  onClick={() => removeFromWorklist(worklist)}
                >
                  <Tooltip title="Click to remove from worklist">
                    {worklist.WorklistName}
                  </Tooltip>
                </Menu.Item>
              ))}
          </Menu.ItemGroup>
        )
      }
      <Menu.ItemGroup title="Options">
        <Menu.Item key="incRelatedPlantsCheck">
          <Checkbox
            checked={incRelatedPlants}
            onChange={(e) => checkIncRelatedPlants(e.target.checked)}
          >
            Include related plants
          </Checkbox>
        </Menu.Item>
        <Menu.Item key="createNewSWL">
          {staticWLModal
            && (
              <AddToStaticWL
                selectedItems={selectedItems}
                create
                closeModal={() => setStaticWLModal(false)}
                materialCount={materialCount}
              />
            )}
          <Button style={{ width: '100%' }} onClick={() => createSWL()}>
            Create New Worklist
          </Button>
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  );

  const getButtonText = () => {
    if (relevantWorklists?.plant !== plant
      || relevantWorklists?.material !== material) {
      return (
        <span>
          Worklist options
          {' '}
          <LoadingOutlined spin />
        </span>
      );
    }
    if (relevantWorklists?.worklists?.length > 0) {
      return 'Worklist options ';
    }
    return 'Add to Worklist';
  };

  return (
    <>
      {canEdit && <DelegateMenu data={data} />}
      <Dropdown overlay={worklistMenu} placement="bottomLeft" visible={worklistMenuVisible}>
        <Button
          className="headerButton"
          type={relevantWorklists?.plant === plant
            && relevantWorklists?.material === material
            && relevantWorklists?.worklists?.length > 0
            ? 'primary'
            : 'default'}
          size="small"
          style={{ minWidth: '145px' }}
          onClick={() => setWorklistMenuVisible(!worklistMenuVisible)}
        >
          {getButtonText()}
          <DownOutlined />
        </Button>
      </Dropdown>
    </>
  );
}
HeaderOptions.defaultProps = {
  data: [],
  canEdit: false,
};

HeaderOptions.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  canEdit: PropTypes.bool,
};
