import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { CopyOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button, Form, Input, InputNumber, message, Pagination, Select, Space,
} from 'antd';
import _ from 'lodash';
import { v4 as uuid } from 'uuid';
import NewStockToolTips from './NewStockToolTips';
import {
  supplierLocations, criticality, CUOptions,
} from './FormData';
import classMatrixImage from '../../../../images/classMatrix.png';
import InfoToolTip from '../../../Common/InfoToolTip';
import allActions from '../../../../actions';
import './NewStockForm.css';
import { NS_PAGE_SIZE } from '../../../Common/GlobalConstants';
import ConfirmModal from '../../../Common/ConfirmModal';

export default function NewStockForm({
  rows, setRows, currentKeyNo, setKeyNo, setDataSource, resultRowsCount,
  setChangedRows, currPage, goToPage, setRowRemoved,
  isSimRunning, setCheckResWithStore, startNewSimulation,
}) {
  const [requiredFields, setRequiredFieldsError] = useState({});
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [modalOptions, setModalOptions] = useState({});
  const [modalVisible, setModalVisible] = useState(false);

  const { Option } = Select;

  useEffect(() => {
    if (rows.length === 0) {
      const first = { key: 'newStockFormRow00' };
      setRows([first]);
      setKeyNo(rows.length);
    }
  }, [rows]);

  const add = () => {
    const n = _.clone(rows);
    n.push({ key: 'newStockFormRow'.concat(currentKeyNo * 10) });
    setRows(n);
    setKeyNo((count) => count + 1);
    const extra = (rows.length + 1) % NS_PAGE_SIZE;
    goToPage(Math.floor((rows.length + 1) / NS_PAGE_SIZE) + (extra ? 1 : 0));
  };

  const copy = (item) => {
    const newItem = { ...item };
    const newRows = _.clone(rows);
    newItem.key = 'newStockFormRow'.concat(currentKeyNo * 10);
    newRows.push(newItem);
    setRows(newRows);
    setKeyNo((count) => count + 1);
    const extra = (rows.length + 1) % NS_PAGE_SIZE;
    goToPage(Math.floor((rows.length + 1) / NS_PAGE_SIZE) + (extra ? 1 : 0));
  };

  const remove = (item) => {
    if (!rows?.length || rows?.length <= 1) return;
    const i = rows.findIndex((x) => x.key === item.key);
    setRows(rows.filter((x) => x.key !== item.key));
    const extra = rows.length % NS_PAGE_SIZE;
    if (extra === 1 && (currPage === Math.ceil(rows.length / NS_PAGE_SIZE))) goToPage(currPage - 1);
    if (i === 0 || i) setRowRemoved(i);
  };

  // const fillReferences = (materialNumber, referenceMaterial) => {
  //   materialNumber.forEach((number) => {
  //     const tempObject = {};
  //     tempObject[number] = { reference: referenceMaterial };
  //     form.setFieldsValue(tempObject);
  //   });
  // };

  const displayClassMatrixSettings = {
    modalTitle: 'Definition of consequence classification',
    modalContent: <img src={classMatrixImage} alt="Class matrix diagram" />,
    modalOkText: '',
    formName: '',
    formDesc: '',
    modalStyle: {
      minWidth: '350px',
    },
    hideFooter: true,
  };

  const displayClassMatrix = () => {
    setModalOptions(displayClassMatrixSettings);
    setModalVisible(true);
  };

  const label = (key, name, onClick) => {
    if (key === (currPage - 1) * NS_PAGE_SIZE) {
      return (
        <div>
          {name}
          {NewStockToolTips[name]
            ? <InfoToolTip onClick={onClick} tooltip={NewStockToolTips[name]} />
            : <></>}
        </div>
      );
    }
    return '';
  };

  const getChangedRow = (prev, item, count) => {
    const rowNo = rows.findIndex((row) => row.key === item.key);
    if (Number.isNaN(rowNo)) return prev;
    if (prev?.indexOf(rowNo) !== -1) {
      setCheckResWithStore(true);
      return prev;
    }
    if (rowNo >= count) {
      return prev;
    }
    return [...prev, rowNo];
  };

  const handleSelect = (value, options, item, rowNo, field) => {
    const tempRows = rows.map((x) => {
      if (x.key === item.key) {
        const tempItem = { ...x };
        tempItem[options.name] = value;
        if (value && (rowNo || rowNo === 0) && field) {
          setRequiredFieldsError((prev) => {
            const temp = prev?.[rowNo] ? { ...prev[rowNo] } : {};
            return {
              ...prev,
              [rowNo]: {
                ...temp,
                [field]: false,
              },
            };
          });
        }
        setChangedRows((prev) => getChangedRow(prev, item, resultRowsCount));
        return tempItem;
      }
      return x;
    });
    setRows(tempRows);
  };

  const handleChange = (e, item, name, isNum = false) => {
    const tempRows = rows.map((x) => {
      if (x.key === item.key) {
        const tempItem = { ...x };
        let val;
        if (e === null || !e?.target) val = e;
        else if (typeof e === 'object') val = e.target?.value;
        if (name !== 'itemText' && val && val < 0) {
          val = 0;
        }
        tempItem[name] = val;
        if (!val && val !== 0) tempItem[name] = null;
        if (isNum && Number.isNaN(Number.parseFloat(val))) tempItem[name] = null;
        if (name !== 'itemText') {
          setChangedRows((prev) => getChangedRow(prev, item));
        }
        return tempItem;
      }
      return x;
    });
    setRows(tempRows);
  };

  const newSimModalContent = (
    <div style={{ whiteSpace: 'pre-line' }}>
      {'Simulation is still running. Results will be lost upon starting \n'}
      a new simulation. Are you sure you want to start a new simulation?
    </div>
  );

  const newSimModalSettings = {
    modalTitle: 'Start New Simulation',
    modalContent: newSimModalContent,
    modalOkText: 'Confirm',
    formName: '',
    formDesc: '',
    modalStyle: {
      minWidth: '350px',
    },
    modalOnOk: () => {
      setModalVisible(false);
      startNewSimulation();
    },
  };

  const onFinish = (allRows) => {
    let issue = false;
    const reqVals = {};
    allRows.forEach((row, i) => {
      if (row
        && (!row.supplierLocation
          || !row.consumptionUnit
          || !row.maxConsumed
          || !row.price
          || !row.deliveryTime
          || !row.consumptionValue
          || !row.criticality)) {
        issue = true;
        reqVals[i] = {
          consumptionUnit: !row.consumptionUnit,
          supplierLocation: !row.supplierLocation,
          consumptionValue: !row.consumptionValue,
          price: !row.price,
          deliveryTime: !row.deliveryTime,
          maxConsumed: !row.maxConsumed,
          criticality: !row.criticality,
        };
      }
    });
    const updatedRows = allRows.map((row) => {
      if (!row.itemText) {
        return { ...row, itemText: '' };
      }
      return row;
    });
    if (issue) {
      setRequiredFieldsError({ ...reqVals });
      message.error('Please provide values for the required fields', 5);
      return;
    }
    setRequiredFieldsError({});
    if (isSimRunning) {
      setModalOptions(newSimModalSettings);
      setModalVisible(true);
    } else {
      setChangedRows([]);
      setRowRemoved(null);
      setDataSource([]);
      dispatch(allActions.SimulationActions.clearNewStockResults());
      dispatch(allActions.SimulationActions.storeNewStockSessionForm(updatedRows, uuid()));
    }
  };

  const formRow = (item, rowNo) => {
    if ((rowNo >= (currPage - 1) * NS_PAGE_SIZE && rowNo < currPage * NS_PAGE_SIZE)) {
      return (
        <Space key={item.key} className="newStockForm" style={{ display: 'flex', marginBottom: 4, marginTop: 4 }} align="end">
          <Form.Item hidden label={label(rowNo, 'Item ID')}>
            <Input style={{ width: '50px', textAlign: 'center' }} disabled value={item.key} />
          </Form.Item>
          <Form.Item style={{ width: '110px' }} label={label(rowNo, 'Item text')}>
            <Input placeholder="Optional" value={item.itemText} onChange={(e) => handleChange(e, item, 'itemText')} name="itemText" autoComplete="off" />
          </Form.Item>
          <Form.Item label={label(rowNo, 'Delivery time')}>
            <InputNumber
              onStep={(e) => handleChange(e, item, 'deliveryTime', true)}
              placeholder="#"
              style={{
                width: '120px',
                border: requiredFields?.[rowNo]?.deliveryTime ? '1px solid red' : '',
              }}
              addonAfter="days"
              value={item.deliveryTime}
              onBlur={(e) => handleChange(e, item, 'deliveryTime', true)}
            />
          </Form.Item>
          <Form.Item label={label(rowNo, 'Supplier location')}>
            <Select
              showSearch
              filterOption={(input, o) => o.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                || o.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              style={{
                width: '130px',
                border: requiredFields?.[rowNo]?.supplierLocation ? '1px solid red' : '',
              }}
              onSelect={(value, options) => handleSelect(value, options, item, rowNo, 'supplierLocation')}
              value={item.supplierLocation}
            >
              {supplierLocations.map((c) => (
                <Option label={c.display} value={c.code} key={c.code} name="supplierLocation" item={item.key}>
                  {c.display}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={label(rowNo, 'Material criticality', displayClassMatrix)}>
            <Select
              showSearch
              style={{ width: '128px', border: requiredFields?.[rowNo]?.criticality ? '1px solid red' : '' }}
              onSelect={(value, options) => handleSelect(value, options, item)}
              value={item.criticality}
            >
              {criticality.map((c) => (
                <Option label={c.display} value={c.code} key={c.code} name="criticality" item={item.key}>
                  {c.display}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={label(rowNo, 'Max consumed')}>
            <InputNumber
              style={{
                width: '100px',
                border: requiredFields?.[rowNo]?.maxConsumed ? '1px solid red' : '',
              }}
              placeholder="Quantity"
              onStep={(e) => handleChange(e, item, 'maxConsumed', true)}
              onBlur={(e) => handleChange(e, item, 'maxConsumed', true)}
              name="maxConsumed"
              value={item.maxConsumed}
            />
          </Form.Item>
          <Form.Item style={{ width: '170px' }} label={label(rowNo, 'Consumption frequency')}>
            <Input.Group>
              <Form.Item
                noStyle
              >
                <InputNumber
                  style={{
                    width: '50%',
                    border: requiredFields?.[rowNo]?.consumptionValue ? '1px solid red' : '',
                  }}
                  placeholder="#"
                  onBlur={(e) => handleChange(e, item, 'consumptionValue', true)}
                  value={item.consumptionValue}
                  onStep={(e) => handleChange(e, item, 'consumptionValue', true)}
                />
              </Form.Item>
              <Form.Item
                noStyle
              >
                <Select
                  style={{ width: '50%', border: requiredFields?.[rowNo]?.consumptionUnit ? '1px solid red' : '' }}
                  placeholder="time unit"
                  onSelect={(value, options) => handleSelect(value, options, item, rowNo, 'consumptionUnit')}
                  value={item.consumptionUnit}
                >
                  {CUOptions.map((c) => (
                    <Option label={c.display} value={c.code} key={c.code} name="consumptionUnit" item={item.key}>
                      {c.display}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <Form.Item label={label(rowNo, 'Price')}>
            <InputNumber
              addonAfter=" NOK"
              style={{
                width: '120px',
                border: requiredFields?.[rowNo]?.price ? '1px solid red' : '',
              }}
              onBlur={(e) => handleChange(e, item, 'price', true)}
              value={item.price}
              onStep={(e) => handleChange(e, item, 'price', true)}
            />
          </Form.Item>
          <CopyOutlined onClick={() => copy(item)} style={{ paddingBottom: '35px' }} />
          <MinusCircleOutlined onClick={() => remove(item)} style={{ paddingBottom: '35px' }} />
        </Space>
      );
    }
    return null;
  };

  return (
    <>
      <ConfirmModal
        message={modalOptions?.modalContent}
        title={modalOptions?.modalTitle}
        okText={modalOptions?.modalOkText}
        onOK={modalOptions?.modalOnOk}
        style={modalOptions?.modalStyle}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        hideFooter={modalOptions?.hideFooter}
      />
      <Form
        form={form}
        onFinish={() => onFinish(rows)}
        layout="vertical"
      >
        {
          rows.map((item, rowNo) => formRow(item, rowNo))
        }
        <Form.Item>
          <Button type="dashed" block onClick={add} icon={<PlusOutlined />}>
            Add new material
          </Button>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
          <Pagination
            style={{ display: 'inline', float: 'right' }}
            current={currPage}
            defaultCurrent={1}
            total={rows?.length ?? 1}
            pageSize={NS_PAGE_SIZE}
            pageSizeOptions={[]}
            hideOnSinglePage
            onChange={(val) => goToPage(val)}
            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
          />
        </Form.Item>
      </Form>
    </>
  );
}
NewStockForm.defaultProps = {
  isSimRunning: false,
};

NewStockForm.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.any).isRequired,
  setRows: PropTypes.func.isRequired,
  currentKeyNo: PropTypes.number.isRequired,
  setKeyNo: PropTypes.func.isRequired,
  setDataSource: PropTypes.func.isRequired,
  resultRowsCount: PropTypes.number.isRequired,
  setChangedRows: PropTypes.func.isRequired,
  currPage: PropTypes.number.isRequired,
  goToPage: PropTypes.func.isRequired,
  setRowRemoved: PropTypes.func.isRequired,
  isSimRunning: PropTypes.bool,
  setCheckResWithStore: PropTypes.func.isRequired,
  startNewSimulation: PropTypes.func.isRequired,
};
