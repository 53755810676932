import * as actionTypes from '../actions/TestDomain/ActionTypes';

const testReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.logTestResult:
      return {
        ...state,
        [action.payload.label]: {
          success: action.payload.success,
          error: action.payload.error,
        },
      };
    case actionTypes.resetTestResults:
      return {};
    default:
      return state;
  }
};

export default testReducer;
