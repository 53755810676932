import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'antd';

const ControllerCheckbox = ({ excludeList, setExcludeList, materials }) => {
  const indeterminate = excludeList.length > 0 && excludeList.length < materials.length;
  const checked = excludeList.length === 0;

  const handleClick = () => {
    if (indeterminate || !checked) {
      setExcludeList([]);
    } else if (checked) {
      setExcludeList(materials);
    }
  };

  return (
    <div>
      <Checkbox
        indeterminate={indeterminate && !checked}
        checked={checked}
        onClick={handleClick}
      />
    </div>
  );
};

export default ControllerCheckbox;

ControllerCheckbox.propTypes = {
  excludeList: PropTypes.arrayOf(PropTypes.any).isRequired,
  setExcludeList: PropTypes.func.isRequired,
  materials: PropTypes.arrayOf(PropTypes.any).isRequired,
};
