import React, {
  useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { Checkbox, InputNumber } from 'antd';
import { validatePOCost, validateHoldingCost, addonAfter } from './ConfigFunctions';
import './ConfigTable.css';

const RenderCell = ({
  changeTable, dispatch, text, record, col, isBit,
}) => {
  const [value, setValue] = useState(text);
  const [input, setInput] = useState(false);
  const [inputClass, setInputClass] = useState(null);

  const handleChange = (val) => {
    let checkedValue;
    if (col === 'EXCLUDE_PM10') {
      checkedValue = val;
    } else {
      checkedValue = (col === 'HOLDING_COST_PRCT')
        ? validateHoldingCost(val)
        : validatePOCost(val);
      // limit to 4 decimals to avoid float imprecision creating trailing ...9999
      setValue((col === 'HOLDING_COST_PRCT') ? val.toFixed(4) : val);
      if (!checkedValue && checkedValue !== 0) {
        setInputClass('invalid');
        return;
      }
    }
    setInputClass(checkedValue === text ? null : 'changed');
    setInput(true);
    dispatch({ type: 'addChange', payload: { originalRecord: record, col, value: checkedValue } });
  };

  useEffect(() => {
    // this condition is used to avoid loop of updates when user is typing input
    // if this condition is false it means the change comes from multi update
    if (input) {
      setInput(false);
      return;
    }
    const relevantRec = changeTable.find(
      (rec) => rec.key === record.key,
    );
    if (relevantRec) {
      setValue(relevantRec?.[col]);
      setInputClass(relevantRec?.[col] === text ? null : 'changed');
    } else {
      setValue(text);
      setInputClass(null);
    }
  }, [changeTable]);

  useEffect(() => {
    // after changes are submitted, update input field to show new actual value
    setValue(text);
    setInputClass(null);
  }, [text]);

  return (
    isBit
      ? (
        <Checkbox
          defaultChecked={value}
          onChange={(val) => handleChange(val.target?.checked)}
        />
      )
      : (
        <InputNumber
          className={inputClass}
          value={value}
          min={0}
          max={col === 'HOLDING_COST_PRCT' ? 1 : 100000}
          step={col === 'HOLDING_COST_PRCT' ? 0.001 : 10}
          formatter={(val) => (col === 'HOLDING_COST_PRCT' ? +parseFloat(val * 100).toFixed(2) : val)}
          parser={(val) => (col === 'HOLDING_COST_PRCT' ? (val / 100) : val)}
          onChange={(val) => handleChange(val)}
          addonAfter={addonAfter(col, record)}
        />
      )
  );
};

RenderCell.defaultProps = {
  text: null,
  isBit: false,
};

RenderCell.propTypes = {
  changeTable: PropTypes.arrayOf(PropTypes.any).isRequired,
  dispatch: PropTypes.func.isRequired,
  text: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  col: PropTypes.string.isRequired,
  isBit: PropTypes.bool,
};

export default RenderCell;
