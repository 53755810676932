/**
 * @param {*} userRoles the user's current roles
 * @param {*} neededRole the role that is required
 * @returns true if neededRole is among user's current roles, else false
 */
const hasAuthenticatedRole = (userRoles, neededRole) => {
  const result = neededRole?.split(',').filter(
    (val) => userRoles?.includes(val),
  ).length > 0;
  return result;
};

export default hasAuthenticatedRole;
