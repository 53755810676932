import * as actionTypes from '../actions/common/ActionTypes';
import * as loadingKeys from '../actions/common/LoadingKeys';

export default function commonReducer(
  state = { notifications: [], hideDoneNotifications: false }, action,
) {
  const loadingArray = state.loading ? { ...state.loading } : {};
  const arrayToMap = (array) => {
    const obj = {};
    array.forEach((setting) => {
      obj[setting.SETTINGS_KEY] = JSON.parse(setting.SETTINGS_VALUE);
    });
    return obj;
  };

  const tempSelectedFuncLocations = state?.selectedFuncLocations?.length > 0
    ? state.selectedFuncLocations : [];

  const updatePlantGroupName = (id, name) => {
    const plantGroups = [...state?.plantGroups];
    if (plantGroups?.length && id && plantGroups?.find((x) => x.key === id)) {
      return plantGroups?.map((x) => {
        if (x.key === id) {
          return {
            ...x,
            Name: name,
          };
        }
        return { ...x };
      });
    }
    return plantGroups;
  };

  switch (action.type) {
    case actionTypes.getPlants:
      return {
        ...state,
        plants: action.payload,
      };
    // DO NOT REMOVE COMMENT. Adding comment to let users search the location.
    // Value SQLOnly is set here.
    case actionTypes.getESStatus:
      return {
        ...state,
        SQLOnly: action.payload,
      };
    case actionTypes.getCompanyCodes:
      return {
        ...state,
        companyCodes: action.payload,
      };
    case actionTypes.setFuncLocationList:
      return {
        ...state,
        funcLocations: [...tempSelectedFuncLocations, ...action.payload],
      };
    case actionTypes.setQuickSearchObject:
      return {
        ...state,
        quickSearchObject: action.payload,
      };
    case actionTypes.setPlantLvlMatView:
      return {
        ...state,
        mmSearchObject: action.payload,
      };
    case actionTypes.selectPlant:
      return {
        ...state,
        selectedPlants: action.payload,
      };
    case actionTypes.selectCompanyCode:
      return {
        ...state,
        selectedCompanyCodes: action.payload,
      };
    case actionTypes.setLocalDashboardFilters:
      return {
        ...state,
        localDashboardFilters: action.payload,
      };
    case actionTypes.selectMaterials:
      return {
        ...state,
        selectedMaterials: action.payload,
      };
    case actionTypes.setDashboardMode:
      return {
        ...state,
        dashboardMode: action.payload,
      };
    case actionTypes.setSelectedGrpsNdPlants:
      return {
        ...state,
        grpsNdPlants: action.payload,
      };
    case actionTypes.storeUserSettings: {
      loadingArray[loadingKeys.userSettings] = false;
      return {
        ...state,
        userSettings: arrayToMap(action.payload),
        loaded: { ...state.loaded, userSettings: true },
        loading: loadingArray,
      };
    }
    case actionTypes.storeWidgetViews:
      return {
        ...state,
        widgetViews: action.payload,
      };
    case actionTypes.selectWidgetView:
      return {
        ...state,
        selectedWidgetView: action.payload,
      };
    case actionTypes.storeCurrencyList: {
      return {
        ...state,
        currencyList: [
          {
            TO_CURRENCY: 'NOK',
            TO_CUR_NAME: 'Norwegian Krone',
            FROM_CURRENCY: 'NOK',
            FROM_CUR_NAME: 'Norwegian Krone',
            FROM_RATIO: 1,
            TO_RATIO: 1,
            EXCHANGE_RATE: 1,
          },
          ...action.payload,
        ],
      };
    }
    case actionTypes.getColumnMapper: {
      return {
        ...state,
        columnMapper: action.payload,
      };
    }
    case actionTypes.setNotificationCount: {
      return {
        ...state,
        notificationCount: action.payload?.[0],
      };
    }
    case actionTypes.storeNotifications: {
      const notifications = action.payload;
      // sort, most recent first
      notifications.sort((a, b) => new Date(b.generatedTime) - new Date(a.generatedTime));
      return {
        ...state,
        notifications,
      };
    }
    case actionTypes.storeAdditionalNotifications: {
      const notifications = [
        // remove the old versions of notifications if they exist.
        ...state.notifications.filter(
          (e) => !action.payload.some(
            (item) => e.key === item.key,
          ),
        ),
        ...action.payload,
      ];
      // sort, most recent first
      notifications.sort((a, b) => new Date(b.generatedTime) - new Date(a.generatedTime));
      return {
        ...state,
        notifications,
      };
    }
    case actionTypes.changeHideDone: {
      return {
        ...state,
        hideDoneNotifications: action.payload,
        notifications: [],
      };
    }
    case actionTypes.setLoading: {
      loadingArray[action.payload] = true;
      return {
        ...state,
        loading: loadingArray,
      };
    }
    case actionTypes.clearNotifications: {
      return {
        ...state,
        notifications: [],
      };
    }
    case actionTypes.setUseDefaultSettings: {
      return {
        ...state,
        useDefaultSettings: action.payload,
      };
    }
    case actionTypes.storePlantGroups: {
      return {
        ...state,
        plantGroups: action.payload?.map((group) => ({
          ...group,
          key: group?.GroupID,
          PlantList: JSON.parse(group?.PlantList),
        })),
      };
    }

    case actionTypes.changePlantGroupName:
      return {
        ...state,
        plantGroups: updatePlantGroupName(action.payload.groupID, action.payload.name),
      };

    case actionTypes.storeConfigTable: {
      loadingArray[loadingKeys.configTable] = false;
      return {
        ...state,
        configTable: action.payload,
        loading: loadingArray,
      };
    }
    case actionTypes.clearConfigTable:
      return {
        ...state,
        configTable: null,
      };
    case actionTypes.getOutliers:
      loadingArray[loadingKeys.outliers] = false;
      return {
        ...state,
        outliers: action.payload,
        loading: loadingArray,
        loaded: { ...state.loaded, outliers: true },
      };
    case actionTypes.getWarnings:
      return {
        ...state,
        warningText: action.payload.displayWarning === 'true' ? action.payload.warningText : null,
      };
    case actionTypes.getOutdated:
      return {
        ...state,
        outdated: action.payload,
      };
    case actionTypes.getCSV:
      return {
        ...state,
        CSV: action.payload,
      };

    case actionTypes.setCSVID:
      return {
        ...state,
        CSVID: action.payload,
      };

    case actionTypes.setCSVError:
      return {
        ...state,
        CSV: {
          ...state.CSV,
          error: action.payload,
        },
      };

    case actionTypes.clearCSV:
      return {
        ...state,
        CSV: undefined,
        CSVID: undefined,
      };

    case actionTypes.getMonetaryImpactSummary:
      return {
        ...state,
        monetaryImpactSummary: action.payload.data,
      };

    case actionTypes.clearMonetaryImpactSummary:
      return {
        ...state,
        monetaryImpactSummary: [],
      };

    case actionTypes.setExportListType:
      return {
        ...state,
        CSV: {
          ...state.CSV,
          listType: action.payload,
        },
      };

    default:
      return state;
  }
}
