import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

const getOption = (value, display, symbol = '', disabled = false) => (
  <Option disabled={disabled} value={value} key={value}>
    <>
      <span className={symbol ? 'operatorSymbol' : ''}>
        {symbol}
      </span>
      {display}
    </>
  </Option>
);

const getBoolOptions = () => [getOption('True', 'True'), getOption('False', 'False')];
const getBitOptions = () => [getOption('1', 'True'), getOption('0', 'False')];

const numberOptions = [{
  value: 'EqualTo',
  display: 'Exactly',
  multiple: true,
},
{
  value: 'LessThan',
  display: 'Less than',
},
{
  value: 'GreaterThan',
  display: 'Greater Than',
},
{
  value: 'LessThanOrEqual',
  display: 'Less Than or Equal',
},
{
  value: 'GreaterThanOrEqual',
  display: 'Greater Than or Equal',
},
{
  value: 'Contains',
  display: 'Contains',
},
{
  value: 'Between',
  display: 'Between',
  disabled: true,
},
{
  value: 'Not',
  display: 'Not',
  multiple: true,
}];

const dateOptions = [{
  value: 'EqualTo',
  display: 'Exactly',
},
{
  value: 'Before',
  display: 'Before',
},
{
  value: 'After',
  display: 'After',
},
{
  value: 'Between',
  display: 'Between',
  disabled: true,
}];

const stringOptions = [{
  value: 'EqualTo',
  display: 'Exactly',
  multiple: true,
},
{
  value: 'StartsWith',
  display: 'Starts With',
  multiple: true,
},
{
  value: 'EndsWith',
  display: 'Ends With',
  multiple: true,
},
{
  value: 'Contains',
  display: 'Contains',
  multiple: true,
}];

const dropdownOptions = [{
  value: 'EqualTo',
  display: 'Exactly',
  multiple: true,
},
{
  value: 'Not',
  display: 'Not',
  multiple: true,
}];

const boolOptions = [{
  value: 'EqualTo',
  display: 'Exactly',
}];

const getTypeOptionArray = (type) => {
  switch (type) {
    case 'bit':
      return boolOptions;
    case 'date':
    case 'datetime':
    case 'datetime2':
      return dateOptions;
    case 'bigint':
    case 'int':
    case 'decimal':
    case 'float':
      return numberOptions;
    case 'nvarchar':
    case 'varchar':
      return stringOptions;
    default:
      return [];
  }
};

export {
  getOption,
  getBoolOptions,
  getBitOptions,
  numberOptions,
  dateOptions,
  stringOptions,
  dropdownOptions,
  boolOptions,
  getTypeOptionArray,
};
