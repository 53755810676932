/* eslint-disable import/prefer-default-export */
export const simulate = 'SIMULATE';
export const createNewStock = 'CREATE_NEWSTOCK';
export const getNewStockForms = 'GET_NEWSTOCK_FORMS';
export const deleteNewStock = 'DELETE_NEWSTOCK';
export const saveNewStock = 'SAVE_NEWSTOCK';
export const storeNewStock = 'STORE_NEWSTOCK';
export const getNewStockResults = 'GET_NEWSTOCK_RESULTS';
export const getAllNewStockForms = 'GET_ALL_NEWSTOCK_FORMS';
export const retrieveSimulation = 'RETRIEVE_SIMULATION';
export const saveSimulation = 'SAVE_SIMULATION';
export const deleteSimulation = 'DELETE_SIMULATION';
export const simulationStatus = 'SIMULATION_STATUS';
export const simulateMultiple = 'SIMULATE_MULTIPLE';
export const clearSimulationResults = 'CLEAR_SIMULATION_RESULTS';
export const getAllSimJobs = 'GET_ALL_SIM_JOBS';
export const setSimLoading = 'SET_SIM_LOADING';
export const saveNewStockResult = 'SAVE_NEW_STOCK_RESULTS_DATA';
export const setNewStockError = 'SET_NEW_STOCK_ADDITION_ERROR';
export const saveNewStockJobData = 'SAVE_NEW_STOCK_JOB_DATA';
export const saveAllNewStockForms = 'STORE_ALL_NEW_STOCK_FORMS';
export const clearNewStockResults = 'CLEAR_NEW_STOCK_RESULTS_FROM_STORE';
export const storeFormRowsTemp = 'STORE_FORM_ROWS_TEMPORARILY';
export const unsetNewStockError = 'REMOVE_NEW_STOCK_ADDITION_ERROR_FLAG';
