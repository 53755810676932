import React from 'react';
import { Select } from 'antd';
import PropTypes from 'prop-types';
import { widgetViewVals } from './WidgetViewConstants';

const { Option } = Select;

const ConditionSelect = ({
  widgetID, setExpandDisabled, value, setSelectedCondition,
}) => {
  const onChange = (val) => {
    if (val === 'All') {
      setExpandDisabled((prev) => [...prev, widgetID]);
    } else {
      setExpandDisabled((prev) => prev.filter((x) => x !== widgetID));
    }
    setSelectedCondition((prev) => ({ ...prev, [widgetID]: val }));
  };

  return (
    <Select style={{ width: '250px' }} width={110} onChange={onChange} value={value}>
      <Option key="All" value="All">For All groups</Option>
      <Option key="Multiple" value="Multiple">For Individual groups</Option>
    </Select>
  );
};

ConditionSelect.defaultProps = {
  value: null,
};

ConditionSelect.propTypes = {
  setExpandDisabled: PropTypes.func.isRequired,
  setSelectedCondition: PropTypes.func.isRequired,
  value: PropTypes.string,
  widgetID: PropTypes.string.isRequired,
};

const ViewSelect = ({
  widgetID, groupID, bindViewToWidget, views, value,
}) => (
  <Select
    style={{ width: '250px' }}
    width={200}
    value={value}
    onChange={(val) => bindViewToWidget(val, widgetID, groupID)}
  >
    {views?.map((view) => (
      <Option key={view.VIEW_ID} value={view.VIEW_ID}>
        {view.VIEW_NAME}
      </Option>
    ))}
    <Option key="_BLANK" value={widgetViewVals.delete} style={{ backgroundColor: 'rgb(242 236 236)' }}>
      {' '}
    </Option>
  </Select>
);

ViewSelect.defaultProps = {
  groupID: null,
  views: {},
  value: null,
};

ViewSelect.propTypes = {
  groupID: PropTypes.string,
  views: PropTypes.objectOf(PropTypes.any),
  value: PropTypes.string,
  widgetID: PropTypes.string.isRequired,
  bindViewToWidget: PropTypes.func.isRequired,
};

const getWidgetViewCols = (
  views, bindViewToWidget, expandDisabled, setExpandDisabled, setSelectedCondition,
) => [
  {
    dataIndex: 'title',
    title: 'Widget/Group Name',
    key: 'title',
  },
  {
    dataIndex: 'condition',
    title: 'Single/Multiple Views',
    key: 'condition',
    render: (value, record) => (record.type === 'widget' ? (
      <ConditionSelect
        widgetID={record.key}
        setExpandDisabled={setExpandDisabled}
        setSelectedCondition={setSelectedCondition}
        value={value}
      />
    ) : null),
  },
  {
    dataIndex: 'views',
    title: 'Select View',
    key: 'views',
    render: (value, record) => (expandDisabled?.includes(record.key) || record.type !== 'widget'
      ? (
        <ViewSelect
          widgetID={record.widgetID}
          views={views}
          groupID={record.groupID}
          bindViewToWidget={bindViewToWidget}
          value={value}
        />
      ) : null),
  },
];

export { ConditionSelect, getWidgetViewCols };
