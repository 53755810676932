import * as LoadingActionTypes from '../actions/utilities/Spinner/LoadingActionTypes';

export default function loadingReducer(state = {}, action) {
  switch (action.type) {
    case LoadingActionTypes.showLoadingType:
      return {
        ...state,
        isLoading: true,
      };
    case LoadingActionTypes.clearLoadingType:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
