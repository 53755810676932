/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import {
  Button, Form, Select, Input, Col, Row, Tooltip, Checkbox,
} from 'antd';
import {
  FormOutlined, InfoCircleOutlined,
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { retrieveSimulations } from '../../../actions/SimulationDomain/LoadingKeys';

const { Option } = Select;
const { TextArea } = Input;

const SimulationForm = ({
  onFinish, labelCol, wrapperCol, preElements, twoColumns, initialValues,
}) => {
  const loading = useSelector((state) => state.simState.loading);
  const [form] = Form.useForm();
  const span = twoColumns ? 12 : 19;

  const [hasChanged, setHasChanged] = useState({ mrp_type: 'V1' });

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  const handleChange = (change) => {
    const type = Object.keys(change)[0];
    setHasChanged({ ...hasChanged, [type]: initialValues[type] !== `${change[type]}` });
  };

  const showChangeIcon = (type) => hasChanged[type] && (
    <Tooltip title="Value has been changed">
      <FormOutlined style={{ color: 'rgb(24, 144, 255)' }} />
    </Tooltip>
  );

  const itemStyle = {
    marginBottom: '5px',
  };

  return (
    <Form
      form={form}
      name="simulation"
      onFinish={(values) => onFinish(values, hasChanged)}
      size="small"
      wrapperCol={wrapperCol}
      labelCol={labelCol}
      onValuesChange={handleChange}
      initialValues={twoColumns ? {} : initialValues}
      labelWrap
    >
      {preElements}
      <Row gutter={24}>
        <Col span={span}>
          <Form.Item
            name="mrp_type"
            label="MRP Type"
            style={itemStyle}
          >
            <Select placeholder="MRP Type" defaultValue="V1" value="V1" disabled>
              <Option value="V1">V1</Option>
            </Select>
          </Form.Item>
        </Col>
        {/* {
          !twoColumns && (
            <Col span={1}>
              {showChangeIcon('mrp_type')}
            </Col>
          )
        } */}
        <Col span={span}>
          <Form.Item
            name="lead_time"
            label={(
              <label>
                Leadtime
                {' '}
                <Tooltip title="Total lead time, including transport days">
                  <InfoCircleOutlined style={{ textAlign: 'center', fontSize: '14px' }} />
                </Tooltip>
              </label>
            )}
            style={itemStyle}
          >
            <Input placeholder="Total lead time (including transport days)" />
          </Form.Item>
        </Col>
        {
          !twoColumns && (
            <Col span={1}>
              {showChangeIcon('lead_time')}
            </Col>
          )
        }
        <Col span={span}>
          <Form.Item
            name="max_criticality"
            label="Max Criticality"
            style={itemStyle}
          >
            <Select placeholder="Maximum criticality">
              <Option value="1">1</Option>
              <Option value="2">2</Option>
              <Option value="3">3</Option>
              <Option value="4">4</Option>
              <Option value="5">5</Option>
              <Option value="6">6</Option>
              <Option value="7">7</Option>
              <Option value="8">8</Option>
              <Option value="9">9</Option>
              <Option value="A">A</Option>
              <Option value="N">N</Option>
              <Option value="Y">Y</Option>
              <Option value="Z">Z</Option>
              <Option value="None">None</Option>
            </Select>
          </Form.Item>
        </Col>
        {
          !twoColumns && (
            <Col span={1}>
              {showChangeIcon('max_criticality')}
            </Col>
          )
        }
        <Col span={span}>
          <Form.Item
            name="approved_deviation"
            label="Approved deviation"
            valuePropName="checked"
            style={itemStyle}
          >
            <Checkbox />
          </Form.Item>
        </Col>
        {
          !twoColumns && (
            <Col span={1}>
              {showChangeIcon('approved_deviation')}
            </Col>
          )
        }
        <Col span={span}>
          <Form.Item
            name="description"
            label="Description"
            style={itemStyle}
          >
            <TextArea
              rows={3}
              maxLength={400}
              placeholder="Enter optional description of simulation"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row justify={twoColumns ? 'end' : 'start'}>
        <Col span={span} style={{ marginBottom: '-20px' }}>
          <Form.Item>
            <Button
              loading={loading?.[retrieveSimulations]}
              size="default"
              type="primary"
              htmlType="submit"
              style={{ float: 'right' }}
            >
              Submit
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default SimulationForm;

SimulationForm.defaultProps = {
  labelCol: {},
  wrapperCol: {},
  preElements: [],
  twoColumns: false,
  initialValues: {},
};

SimulationForm.propTypes = {
  onFinish: PropTypes.func.isRequired,
  labelCol: PropTypes.objectOf(PropTypes.any),
  wrapperCol: PropTypes.objectOf(PropTypes.any),
  preElements: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  twoColumns: PropTypes.bool,
  initialValues: PropTypes.shape({
    mrp_type: PropTypes.string,
    lead_time: PropTypes.string,
    max_criticality: PropTypes.string,
    display_rec_ind: PropTypes.string,
    unit_price: PropTypes.string,
    consumption_group: PropTypes.string,
    holding_cost: PropTypes.string,
    transaction_cost: PropTypes.string,
  }),
};
