/* eslint-disable prefer-template */
import React, { useState, useEffect } from 'react';
import {
  Typography, Row, Col, Switch, Tooltip, Button,
} from 'antd';
import { CheckOutlined, CloseOutlined, FormOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import allActions from '../../actions';
import './AppBar.css';
import UserMenuContainer from './UserMenuContainer';
import ImpactForm from './ImpactForm';
import styles from './styles.module.scss';

const { Title } = Typography;

export default function AppBar({ isProd }) {
  const dispatch = useDispatch();

  const userSettings = useSelector((state) => state.commonState.userSettings);
  const useDefaultSettings = useSelector((state) => state.commonState.useDefaultSettings);
  const user = useSelector((state) => state.authState.user);
  const plants = useSelector((state) => state.commonState.plants);
  const companyCodes = useSelector((state) => state.commonState.companyCodes);
  const [defaultsDisabled, setDefaultsDisabled] = useState(true);

  const [impactFormVisible, setImpactFormVisible] = useState(false);

  useEffect(() => {
    if (userSettings?.DEFAULT_PLANTS?.length > 0
      || userSettings?.DEFAULT_COMPANY_CODES?.length > 0) {
      setDefaultsDisabled(false);
    } else {
      setDefaultsDisabled(true);
    }
  }, [userSettings]);

  const onUseDefaultToggle = (checked) => {
    dispatch(allActions.CommonActions.setUseDefaultSettings(checked));
    dispatch(allActions.WorkListActions.nullRowCountAll());
  };

  const submitImpactForm = ({
    plant, material, impact, impactType, notes, overstock = 0,
  }) => {
    setImpactFormVisible(false);
    dispatch(allActions.CommonActions.submitImpactForm(
      plant,
      material,
      impact,
      impactType,
      notes,
      user?.account?.username,
      overstock,
    ));
  };

  const getTitle = () => {
    let title;
    if (userSettings?.DEFAULT_PLANTS?.length > 0 && plants) {
      const plantNames = {};
      plants.forEach((p) => {
        plantNames[p.PLANT_FACILITY_SAP_ID] = p.PLANT_FACILITY_SAP_NM;
      });
      title = userSettings?.DEFAULT_PLANTS.map((p) => p + ' (' + plantNames[p] + ')').join('\r\n');
    } else if (userSettings?.DEFAULT_COMPANY_CODES?.length > 0 && companyCodes.length) {
      const ccNames = {};
      companyCodes.forEach((cc) => {
        ccNames[cc.Company_Code] = cc.Company_Name;
      });
      title = userSettings?.DEFAULT_COMPANY_CODES.map((cc) => cc + ' (' + ccNames[cc] + ')').join('\r\n');
    } else {
      title = 'No plants or company codes are set as default.';
    }
    return <span style={{ whiteSpace: 'pre-line' }}>{title}</span>;
  };

  const onClick = () => {
    dispatch(allActions.AuthActions.setToLimitedViewer());
  };

  return (
    <Row justify="space-around" gutter={24}>
      {!isProd && <div className={styles.limitedViewer}><Button type="primary" onClick={onClick}>limited viewer</Button></div>}
      <Col span={9} />
      <Col span={6}>
        <Title strong style={{ color: isProd ? 'var(--prod-title)' : 'var(--non-prod-title)' }} className="mainHeader">
          IOTA
        </Title>
      </Col>
      <Col offset={3} span={3}>
        <span className="toggle">
          <Tooltip
            title={getTitle}
            placement="bottom"
          >
            <Switch
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              checked={defaultsDisabled ? false : useDefaultSettings}
              onChange={onUseDefaultToggle}
              disabled={defaultsDisabled}
            />
            <span> Default plants/CCs</span>
          </Tooltip>
        </span>
      </Col>
      <Col span={1}>
        <Tooltip title="Register impact">
          <FormOutlined onClick={() => setImpactFormVisible(true)} className="formIcon" />
        </Tooltip>
      </Col>
      <Col span={1}>
        <UserMenuContainer />
      </Col>
      {
        impactFormVisible
        && (
          <ImpactForm
            visible={impactFormVisible}
            onOk={submitImpactForm}
            onCancel={() => setImpactFormVisible(false)}
          />
        )
      }
    </Row>
  );
}
