import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { superUserWidgets } from '../Dashboard/Widgets/widgetTypes';
import { getWidgetViewCols } from './WidgetViewHelpers';
import { widgetGroups, widgetViewVals } from './WidgetViewConstants';
import allActions from '../../../actions/index';

const WidgetViewSettings = () => {
  const [treeData, setTreeData] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [expandDisabled, setExpandDisabled] = useState([]);
  const [columns, setColumns] = useState([]);
  const [selectedViews, setSelectedViews] = useState({});
  const [selectedCondition, setSelectedCondition] = useState({});
  const user = useSelector((state) => state.authState.user);
  const views = useSelector((state) => state.materialState.userViews);
  const viewsLoaded = useSelector((state) => state.materialState.loaded?.userViews);
  const widgetViews = useSelector((state) => state.commonState.widgetViews);

  const dispatch = useDispatch();

  const updateExpandedRowList = (expanded, record) => {
    if (expanded) {
      const tempKeys = [...expandedRowKeys];
      if (!expandDisabled.includes(record.key)) tempKeys.push(record.key);
      setExpandedRowKeys(tempKeys);
      return;
    }
    setExpandedRowKeys(expandedRowKeys.filter((x) => x !== record.key));
  };

  const bindViewToWidget = (value, widgetID, groupID) => {
    const widgetName = superUserWidgets[widgetID];
    if (value === widgetViewVals.delete) {
      dispatch(allActions.CommonActions.unBindViewToWidget(
        widgetID, groupID || widgetViewVals.all, widgetName,
      ));
      return;
    }
    dispatch(allActions.CommonActions.bindViewToWidget(
      value, widgetID, groupID || widgetViewVals.all, user?.uniqueId, widgetName,
    ));
  };

  useEffect(() => {
    if (user?.uniqueId && !viewsLoaded) {
      dispatch(allActions.MaterialListActions.getUserViews(
        user.uniqueId, user.account?.username,
      ));
      dispatch(allActions.CommonActions.getWidgetViews());
    }
  }, [user?.uniqueId]);

  useEffect(() => {
    let data = [];
    const widgetList = Object.keys(widgetGroups);
    data = widgetList.map((widgetID) => ({
      key: widgetID,
      widgetID,
      type: 'widget',
      title: superUserWidgets[widgetID],
      condition: selectedCondition?.[widgetID],
      views: expandDisabled.includes(widgetID) ? selectedViews?.[widgetID] : null,
      children: widgetGroups[widgetID].map((x) => ({
        key: widgetID.concat('|', x.key),
        widgetID,
        title: x[x.key],
        groupID: x.key,
        views: selectedViews?.[widgetID.concat('|', x.key)],
      })),
    }));
    setTreeData(data);
  }, [selectedCondition, selectedViews]);

  useEffect(() => {
    setExpandedRowKeys(expandedRowKeys.filter((x) => !expandDisabled.includes(x)));
  }, [expandDisabled]);

  useEffect(() => {
    if (views?.length) {
      const publicViews = views?.filter((x) => x.PUBLIC_VIEW);
      const widgetCols = getWidgetViewCols(
        publicViews, bindViewToWidget, expandDisabled, setExpandDisabled, setSelectedCondition,
      );
      setColumns(widgetCols);
    }
  }, [views, widgetViews, expandDisabled]);

  useEffect(() => {
    if (widgetViews) {
      const tempSelectedViews = {};
      const tempSelectedCondition = {};
      const tempExpandDisabled = [];
      widgetViews?.forEach((x) => {
        if (x?.GroupID === widgetViewVals.all) {
          tempSelectedCondition[x.WidgetID] = 'All';
          tempExpandDisabled.push(x.WidgetID);
        } else {
          tempSelectedCondition[x.WidgetID] = 'Multiple';
        }
        tempSelectedViews[x.key] = x.ViewID;
      });
      setExpandDisabled((prev) => Array.from(new Set([...prev, ...tempExpandDisabled])));
      setSelectedCondition({ ...tempSelectedCondition });
      setSelectedViews({ ...tempSelectedViews });
    }
  }, [widgetViews]);

  return (
    <Table
      style={{ margin: '30px', fontSize: '25px', textAlign: 'center' }}
      loading={!views?.length}
      columns={columns}
      dataSource={treeData}
      size="large"
      expandable={{
        expandedRowKeys,
        onExpand: updateExpandedRowList,
        indentSize: 25,
      }}
    />
  );
};

export default WidgetViewSettings;
