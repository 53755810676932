/* eslint-disable camelcase */
import * as actionTypes from './ActionTypes';
import apiAction from '../utilities/CommonMethods';
import {
  Base_URL,
  Get_Plants_URL,
  SetUserSetting,
  GetAllUserSettings,
  GetColumnMapper,
  Get_Company_Codes_URL,
  Get_Functional_Locations_List_URL,
  Notifications,
  Config,
  GetPlantGroups,
  CreatePlantGroup,
  DeletePlantGroup,
  DeleteMultiplePlantGroups,
  UpdatePlantGroupNameURL,
  GetCurrencyList,
  GetWarnings,
  GetOutdated,
  Outliers,
  GenerateCSV,
  Track,
  GetMonetaryImpactSummary,
  UpdateMonetaryImpactSummary,
  ESStatus,
  GetWidgetViews,
  BindViewToWidget,
  UnBindViewToWidget,
} from '../APIUrls';
import { SettingStrings } from '../../components/Views/Settings/SettingTypes';

const getPlants = () => {
  const url = Base_URL + Get_Plants_URL;
  function setPlants(data) {
    return {
      type: actionTypes.getPlants,
      payload: data,
    };
  }

  return apiAction({
    url,
    method: 'GET',
    onSuccess: setPlants,
    onFailure: () => console.log('Error occured fetching plants'),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Plant data',
      Message: 'Failed to load plant data',
    }),
    label: actionTypes.getPlants,
  });
};

const getCompanyCodes = () => {
  const url = Base_URL + Get_Company_Codes_URL;
  function setCompanyCodes(data) {
    return {
      type: actionTypes.getCompanyCodes,
      payload: data,
    };
  }

  return apiAction({
    url,
    method: 'GET',
    onSuccess: setCompanyCodes,
    onFailure: () => console.log('Error occured fetching company codes'),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Company code data',
      Message: 'Failed to load company codes',
    }),
    label: actionTypes.getCompanyCodes,
  });
};

const getFuncLocationDropDown = (value) => {
  const url = Base_URL + Get_Functional_Locations_List_URL.concat('?Location_ID=', value);
  function setFuncLocationList(data) {
    return {
      type: actionTypes.setFuncLocationList,
      payload: data,
    };
  }

  return apiAction({
    url,
    method: 'GET',
    onSuccess: setFuncLocationList,
    onFailure: () => {
      console.log('Error occured while fetching Functional locations');
      setFuncLocationList([]);
    },
    label: actionTypes.setFuncLocationList,
  });
};

const setLocalDashboardFilters = (data) => ({
  type: actionTypes.setLocalDashboardFilters,
  payload: data,
});

const selectPlant = (data) => ({
  type: actionTypes.selectPlant,
  payload: data,
});

const selectCompanyCode = (data) => ({
  type: actionTypes.selectCompanyCode,
  payload: data,
});

const selectMaterials = (data) => ({
  type: actionTypes.selectMaterials,
  payload: data,
});

const setDashboardMode = (mode) => ({
  type: actionTypes.setDashboardMode,
  payload: mode,
});

const setSelectedGrpsNdPlants = (data) => ({
  type: actionTypes.setSelectedGrpsNdPlants,
  payload: data,
});

const setLoading = (key) => ({
  type: actionTypes.setLoading,
  payload: key,
});

const setPlantLvlMatView = (payload) => ({
  type: actionTypes.setPlantLvlMatView,
  payload,
});

const clearPlantLvlMatView = () => ({
  type: actionTypes.setPlantLvlMatView,
  payload: null,
});

const getAllUserSettings = (userID) => {
  const url = `${Base_URL + GetAllUserSettings}?user=${userID}`;

  function storeUserSettings(data) {
    return {
      type: actionTypes.storeUserSettings,
      payload: data,
    };
  }

  return apiAction({
    url,
    method: 'GET',
    onSuccess: storeUserSettings,
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'User settings',
      Message: 'Failed to load user settings',
    }),
    label: actionTypes.storeUserSettings,
  });
};

const getCurrencyList = () => {
  const url = `${Base_URL + GetCurrencyList}`;

  function storeCurrencyList(data) {
    return {
      type: actionTypes.storeCurrencyList,
      payload: data,
    };
  }

  return apiAction({
    url,
    method: 'GET',
    onSuccess: storeCurrencyList,
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'User settings',
      Message: 'Failed to load currency data list',
    }),
    label: actionTypes.storeCurrencyList,
  });
};

const setUserSetting = (userID, setting_type, setting_value) => {
  const url = Base_URL + SetUserSetting;
  return apiAction({
    url,
    method: 'POST',
    data: {
      User_ID: userID,
      Setting_Type: setting_type,
      Setting_Value: JSON.stringify(setting_value),
    },
    onSuccess: () => getAllUserSettings(userID),
    onSuccessNotification: {
      Type: 'success',
      Title: 'Updated user setting',
      Message: `Successfully updated user setting: ${SettingStrings[setting_type]}.`,
    },
    onFailure: (msg) => console.log(`Error occured updating a user setting ${msg}`),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Failed to update user settings',
      Message: 'The system was unable to submit the selected user settings',
    }),
    label: actionTypes.setUserSetting,
  });
};
const setQuickSearchObject = (quickSearchObject) => ({
  type: actionTypes.setQuickSearchObject,
  payload: quickSearchObject,
});

const setQuickSearchNull = () => ({
  type: actionTypes.setQuickSearchObject,
  payload: null,
});

const getColumnMapper = () => {
  const url = Base_URL + GetColumnMapper;
  function storeColumnMapper(data) {
    return {
      type: actionTypes.getColumnMapper,
      payload: data,
    };
  }

  return apiAction({
    url,
    method: 'GET',
    onSuccess: storeColumnMapper,
    onFailure: () => console.log('Error occured fetching column name mapper.'),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Column name data',
      Message: 'Failed to load column name data table',
    }),
    label: actionTypes.getColumnMapper,
  });
};

const checkNotifications = (UserID, Types) => {
  const url = `${Base_URL + Notifications}/check`;
  function setNotificationCount(data) {
    return {
      type: actionTypes.setNotificationCount,
      payload: data,
    };
  }

  return apiAction({
    url,
    method: 'POST',
    data: { UserID, Types },
    onSuccess: setNotificationCount,
    onFailure: () => console.log('Error occured checking number of unread notifications.'),
    label: actionTypes.setNotificationCount,
  });
};

const getNotifications = (
  UserID, Types, NumberOfRows = null, Offset = null, UnacceptedOnly = null,
) => {
  const url = Base_URL + Notifications;
  function storeNotifications(data) {
    return {
      type: actionTypes.storeNotifications,
      payload: data,
    };
  }

  return apiAction({
    url,
    method: 'POST',
    data: {
      UserID, Types, NumberOfRows, Offset, UnacceptedOnly: UnacceptedOnly || null,
    },
    onSuccess: storeNotifications,
    onFailure: () => console.log('Error occured fetching notifications.'),
    label: actionTypes.storeNotifications,

  });
};

const getAdditionalNotifications = (
  UserID, Types, NumberOfRows = null, Offset = null, UnacceptedOnly = null,
) => {
  const url = Base_URL + Notifications;
  function storeAdditionalNotifications(data) {
    return {
      type: actionTypes.storeAdditionalNotifications,
      payload: data,
    };
  }

  return apiAction({
    url,
    method: 'POST',
    data: {
      UserID, Types, NumberOfRows, Offset, UnacceptedOnly: UnacceptedOnly || null,
    },
    onSuccess: storeAdditionalNotifications,
    onFailure: () => console.log('Error occured fetching more notifications.'),
    label: actionTypes.storeAdditionalNotifications,
  });
};

const acceptNotification = (ID, UserID, showTypes) => {
  const url = `${Base_URL + Notifications}/accept`;

  return apiAction({
    url,
    method: 'POST',
    data: { ID },
    onSuccess: () => checkNotifications(UserID, showTypes),
    onFailure: () => console.log('Error occured updating notification as accepted.'),
    label: actionTypes.acceptNotification,
  });
};

const markNotifAsViewed = (ID, UserID, showTypes) => {
  const url = `${Base_URL + Notifications}/markAsViewed`;

  return apiAction({
    url,
    method: 'POST',
    data: { ID },
    onSuccess: () => checkNotifications(UserID, showTypes),
    onFailure: () => console.log('Error occured updating notification as viewed.'),
    label: actionTypes.markNotifAsViewed,
  });
};

const acceptAllNotifications = (UserID, showTypes) => {
  const url = `${Base_URL + Notifications}/accept-all`;

  return apiAction({
    url,
    method: 'POST',
    data: { UserID },
    onSuccess: () => checkNotifications(UserID, showTypes),
    onFailure: () => console.log('Error occured setting all notifications as accepted.'),
    label: actionTypes.acceptAllNotifications,
  });
};

const setUseDefaultSettings = (checked) => ({
  type: actionTypes.setUseDefaultSettings,
  payload: checked,
});

const changeHideDone = (hideDone) => ({
  type: actionTypes.changeHideDone,
  payload: hideDone,
});

const clearNotifications = () => ({
  type: actionTypes.clearNotifications,
  payload: null,
});

const getConfigTable = () => {
  const url = Base_URL + Config;

  function storeConfigTable(data) {
    return {
      type: actionTypes.storeConfigTable,
      payload: data,
    };
  }

  return apiAction({
    url,
    method: 'GET',
    onSuccess: storeConfigTable,
    onFailure: () => console.log('Error occured retrieving config table.'),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Config Table',
      Message: 'Failed to load config table',
    }),
    label: actionTypes.storeConfigTable,
  });
};

const clearConfigTable = () => ({
  type: actionTypes.clearConfigTable,
  payload: null,
});

const updateConfigTable = (changeTable) => apiAction({
  url: (Base_URL + Config),
  method: 'POST',
  data: { changes: changeTable },
  onSuccess: getConfigTable,
  onSuccessNotification: {
    Type: 'success',
    Title: 'Updated config table',
    Message: 'Successfully updated configuration table.',
  },
  onFailure: () => console.log('Error occured updating config table.'),
  onFailureNotification: () => ({
    Type: 'error',
    Title: 'Config Table',
    Message: 'Failed to update config table',
  }),
  label: actionTypes.updateConfigTable,
});

const getPlantGroups = () => {
  const url = Base_URL + GetPlantGroups;

  function storePlantGroups(data) {
    return {
      type: actionTypes.storePlantGroups,
      payload: data,
    };
  }

  return apiAction({
    url,
    method: 'GET',
    onSuccess: storePlantGroups,
    onFailure: () => console.log('Error occured retrieving plant groups.'),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Plant groups',
      Message: 'Failed to load plant groups.',
    }),
    label: actionTypes.storePlantGroups,
  });
};

const deletePlantGroup = (groupID, name) => {
  const url = Base_URL + DeletePlantGroup;

  function storePlantGroups(data) {
    return {
      type: actionTypes.storePlantGroups,
      payload: data,
    };
  }

  return apiAction({
    url,
    method: 'DELETE',
    data: {
      GroupID: groupID,
    },
    onSuccess: storePlantGroups,
    onSuccessNotification: {
      Type: 'success',
      Title: 'Plant groups',
      Message: `Successfully deleted plant group ${name ?? ''}`,
    },
    onFailure: () => console.log(`Error occured deleting "${name ?? ''}" plant group.`),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Plant groups',
      Message: `Failed to delete "${name ?? ''}" plant group`,
    }),
    label: actionTypes.deletePlantGroup,
  });
};

const deleteMultiplePlantGroups = (groupIDs) => {
  const url = Base_URL + DeleteMultiplePlantGroups;

  return apiAction({
    url,
    method: 'DELETE',
    data: {
      GroupIDs: groupIDs,
    },
    onSuccess: getPlantGroups,
    onSuccessNotification: {
      Type: 'success',
      Title: 'Plant groups',
      Message: 'Successfully deleted selected Plant groups',
    },
    onFailure: () => console.log('Error occured deleting Plant Group(s).'),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Plant groups',
      Message: 'Failed to delete selected plant groups',
    }),
    label: actionTypes.deleteMultiplePlantGroups,
  });
};

const createPlantGroup = (data) => {
  const url = Base_URL + CreatePlantGroup;

  return apiAction({
    url,
    method: 'POST',
    data,
    onSuccess: getPlantGroups,
    onSuccessNotification: {
      Type: 'success',
      Title: 'Plant groups',
      Message: `Successfully created/updated Plant group ${data?.Name}`,
    },
    onFailure: () => console.log(`Error occured while creating plant group ${data?.Name}`),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Plant groups',
      Message: `Failed to create/update plant group ${data?.Name}`,
    }),
    label: actionTypes.createPlantGroup,
  });
};

const updatePlantGroupName = (name, groupID) => {
  const url = Base_URL + UpdatePlantGroupNameURL;

  const updateGroupName = () => ({
    type: actionTypes.changePlantGroupName,
    payload: {
      groupID,
      name,
    },
  });

  return apiAction({
    url,
    method: 'POST',
    data: {
      Name: name,
      GroupID: groupID,
    },
    onSuccess: updateGroupName,
    onSuccessNotification: {
      Type: 'success',
      Title: 'Plant groups',
      Message: 'Successfully updated selected plant group\'s name',
    },
    onFailure: () => console.log('Error occured while updating plant group\'s name'),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Plant groups',
      Message: 'Failed to update plant group\'s name',
    }),
    label: actionTypes.updatePlantGroupName,
  });
};

const updateOutliers = (data) => ({
  type: actionTypes.getOutliers,
  payload: data,
});

const getOutliers = () => {
  const url = Base_URL + Outliers;

  return apiAction({
    url,
    method: 'GET',
    onSuccess: updateOutliers,
    onFailure: () => console.log('Error occured while retrieving outliers'),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Outliers',
      Message: 'Failed to retrieve outliers',
    }),
    label: actionTypes.getOutliers,
  });
};

const addOutliers = (changeTable, isPlant, createdBy) => {
  const url = Base_URL + Outliers;

  return apiAction({
    url,
    method: 'POST',
    data: {
      changeTable,
      isPlant,
      createdBy,
    },
    onSuccess: updateOutliers,
    onSuccessNotification: {
      Type: 'success',
      Title: 'Outliers',
      Message: 'Successfully updated outliers',
    },
    onFailure: () => console.log('Error occured while updating outliers'),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Outliers',
      Message: 'Failed to update outliers',
    }),
    label: actionTypes.addOutliers,
  });
};

const deleteOutlier = (outlierID) => {
  const url = Base_URL + Outliers;

  return apiAction({
    url,
    method: 'DELETE',
    data: {
      outlierID,
    },
    onSuccess: updateOutliers,
    onSuccessNotification: {
      Type: 'success',
      Title: 'Outliers',
      Message: 'Successfully deleted outlier',
    },
    onFailure: () => console.log('Failed to delete outlier'),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Outliers',
      Message: 'Failed to delete outlier',
    }),
    label: actionTypes.deleteOutlier,
  });
};

const getWarnings = () => {
  const url = Base_URL + GetWarnings;

  const updateWarnings = (data) => ({
    type: actionTypes.getWarnings,
    payload: data,
  });
  return apiAction({
    url,
    method: 'GET',
    onSuccess: updateWarnings,
    onFailure: () => console.log('Could not retrieve warnings'),
    label: actionTypes.getWarnings,
  });
};

const getOutdated = () => {
  const url = Base_URL + GetOutdated;

  const updateOutdated = (data) => ({
    type: actionTypes.getOutdated,
    payload: data,
  });
  return apiAction({
    url,
    method: 'GET',
    onSuccess: updateOutdated,
    label: actionTypes.getOutdated,
  });
};

const generateCSV = (
  filters, selectedCols, exportType, typeOfWorklist, worklistID, mode, currency,
) => {
  const url = `${Base_URL}${GenerateCSV}`;
  function onSuccess(data) {
    return {
      type: actionTypes.setCSVID,
      payload: data.run_id,
    };
  }
  return apiAction({
    url,
    method: 'POST',
    data: {
      filters,
      selectedCols: JSON.stringify(selectedCols),
      exportType,
      typeOfWorklist,
      worklistID,
      Mode: mode,
      currency,
    },
    onSuccess,
    onFailure: () => ({
      type: actionTypes.setCSVError,
      payload: true,
    }),
    onFailureNotification: () => ({
      Type: 'error',
      Title: `${exportType ?? ''} file Generation Failure`,
      Message: `Failed to generate ${exportType ?? ''} file from filters.`,
    }),
    label: actionTypes.setCSVID,
  });
};

const getCSV = (id) => {
  const url = `${Base_URL}${GenerateCSV}/${id || 0}`;

  function onResponse(data) {
    if (data?.error
      && data?.error?.includes('No output is available until the task begins') !== true) {
      return {
        type: actionTypes.setCSVError,
        payload: true,
      };
    }
    const obj = {
      done: !!data?.notebook_output?.result,
      url: data?.notebook_output?.result,
      exportStatus: data?.metadata?.state?.life_cycle_state,
    };
    return {
      type: actionTypes.getCSV,
      payload: obj,
    };
  }

  return apiAction({
    url,
    method: 'GET',
    onSuccess: onResponse,
    onFailure: () => ({
      type: actionTypes.setCSVError,
      payload: true,
    }),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'File Retrieval Failure',
      Message: 'Failed to retrieve data for current file generation job',
    }),
    label: actionTypes.getCSV,
  });
};

const getMonetaryImpactSummary = (userEmail) => {
  const url = Base_URL + GetMonetaryImpactSummary;
  function setMonetaryImpactSummary(data) {
    return {
      type: actionTypes.getMonetaryImpactSummary,
      payload: {
        data,
      },
    };
  }
  return apiAction({
    url,
    method: 'POST',
    data: {
      userEmail,
    },
    onSuccess: setMonetaryImpactSummary,
    onFailure: (e) => console.log(e),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Monetary Impact',
      Message: 'Failed to load monetary impact summary',
    }),
    label: actionTypes.getMonetaryImpactSummary,
  });
};

const updateMonetaryImpactSummary = (userEmail, userID, monetarySummary) => {
  const url = Base_URL + UpdateMonetaryImpactSummary;

  return apiAction({
    url,
    method: 'POST',
    data: {
      userEmail,
      userID,
      monetarySummary,
    },
    onSuccess: () => getMonetaryImpactSummary(userEmail),
    onFailure: (e) => console.log(e),
    onSuccessNotification: {
      Type: 'success',
      Title: 'Monetary Impact Update',
      Message: 'Successfully updated monetary impact summary',
    },
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Monetary Impact Update',
      Message: 'Failed to update monetary impact summary',
    }),
    label: actionTypes.updateMonetaryImpactSummary,
  });
};

const clearMonetaryImpactSummary = () => ({
  type: actionTypes.clearMonetaryImpactSummary,
});

const submitImpactForm = (plant, material, impact, impactType, notes, userEmail, overstock = 0) => {
  const url = `${Base_URL}impact`;
  return apiAction({
    url,
    method: 'POST',
    data: {
      plant,
      material,
      impact,
      impactType,
      notes,
      userEmail,
      overstock,
    },
    onSuccess: () => getMonetaryImpactSummary(userEmail),
    onSuccessNotification: {
      Type: 'success',
      Title: 'Impact form submitted',
      Message: 'Successfully submitted impact form',
    },
    onFailure: () => console.log('Error occured submitting impact form'),
    label: actionTypes.submitImpactForm,
  });
};

const trackUniqueIds = (uniqueId) => {
  const url = Base_URL + Track;
  return apiAction({
    url,
    method: 'POST',
    data: { uniqueId },
    onSuccess: () => {},
    onFailure: () => {},
    label: actionTypes.Track,
  });
};

const clearCSV = () => ({
  type: actionTypes.clearCSV,
});

const setExportListType = (mode) => ({
  type: actionTypes.setExportListType,
  payload: mode,
});

const getESStatus = () => {
  const url = Base_URL + ESStatus;
  function setESStatus(data) {
    return {
      type: actionTypes.getESStatus,
      payload: data,
    };
  }

  return apiAction({
    url,
    method: 'GET',
    onSuccess: setESStatus,
    onFailure: () => console.log('Error occured fetching Elastic search status'),
    label: actionTypes.getESStatus,
  });
};

const selectWidgetView = (viewID) => ({
  type: actionTypes.selectWidgetView,
  payload: viewID,
});

const getWidgetViews = () => {
  const url = Base_URL + GetWidgetViews;
  const storeWidgetViewSettings = (data) => ({
    type: actionTypes.storeWidgetViews,
    payload: data,
  });
  return apiAction({
    url,
    method: 'GET',
    onSuccess: storeWidgetViewSettings,
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Widget Views',
      Message: 'Failed to Widget views settings',
    }),
    label: actionTypes.storeWidgetViews,
  });
};

const bindViewToWidget = (viewID, widgetID, groupID, userID, widgetName) => {
  const url = Base_URL + BindViewToWidget;

  return apiAction({
    url,
    method: 'POST',
    data: {
      UserID: userID,
      ViewID: viewID,
      WidgetID: widgetID,
      GroupID: groupID,
    },
    onSuccess: () => getWidgetViews(),
    onSuccessNotification: {
      Type: 'success',
      Title: 'Save Widget View',
      Message: `Successfully set/updated view to ${widgetName}`,
    },
    onFailure: () => console.log('Error occured while updating outliers'),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Save Widget View',
      Message: `Failed to save/update view for ${widgetName}`,
    }),
    label: actionTypes.saveWidgetViews,
  });
};

const unBindViewToWidget = (widgetID, groupID, widgetName) => {
  const url = Base_URL + UnBindViewToWidget;

  return apiAction({
    url,
    method: 'DELETE',
    data: {
      WidgetID: widgetID,
      GroupID: groupID,
    },
    onSuccess: () => getWidgetViews(),
    onSuccessNotification: {
      Type: 'success',
      Title: 'Delete Widget View',
      Message: `Successfully un-set view to ${widgetName}`,
    },
    onFailure: () => console.log('Error occured while updating outliers'),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Delete Widget View',
      Message: `Failed to un-set view to ${widgetName}`,
    }),
    label: actionTypes.deleteWidgetView,
  });
};

export default {
  getPlants,
  getCompanyCodes,
  getFuncLocationDropDown,
  selectPlant,
  selectCompanyCode,
  selectMaterials,
  setDashboardMode,
  setUserSetting,
  getAllUserSettings,
  setQuickSearchObject,
  setQuickSearchNull,
  setPlantLvlMatView,
  clearPlantLvlMatView,
  getColumnMapper,
  checkNotifications,
  getNotifications,
  getAdditionalNotifications,
  acceptNotification,
  markNotifAsViewed,
  acceptAllNotifications,
  changeHideDone,
  setLoading,
  clearNotifications,
  setUseDefaultSettings,
  getConfigTable,
  clearConfigTable,
  updateConfigTable,
  getPlantGroups,
  createPlantGroup,
  deletePlantGroup,
  updatePlantGroupName,
  deleteMultiplePlantGroups,
  getCurrencyList,
  getOutliers,
  addOutliers,
  deleteOutlier,
  getWarnings,
  getOutdated,
  setLocalDashboardFilters,
  generateCSV,
  getCSV,
  getMonetaryImpactSummary,
  clearMonetaryImpactSummary,
  updateMonetaryImpactSummary,
  submitImpactForm,
  trackUniqueIds,
  clearCSV,
  setExportListType,
  setSelectedGrpsNdPlants,
  getESStatus,
  bindViewToWidget,
  getWidgetViews,
  unBindViewToWidget,
  selectWidgetView,
};
