/* eslint-disable no-underscore-dangle */

import {
  applyMiddleware, combineReducers, compose, createStore,
} from 'redux';
import thunk from 'redux-thunk';
import { connectRouter } from 'connected-react-router';
import { routerMiddleware } from 'react-router-redux';
import TokenMiddleware from '../api/TokenMiddleware';
import apiMiddleware from '../api/apiMiddleware';
import { reducers } from '../reducers';

export default function configureStore(history, initialState, msalInstance) {
  const loggerMiddleware = () => (next) => (action) => {
    next(action);
  };
  const middleware = [
    TokenMiddleware(msalInstance),
    thunk,
    loggerMiddleware,
    routerMiddleware(history),
    apiMiddleware,
  ];

  const rootReducer = combineReducers({
    ...reducers,
    router: connectRouter(history),
  });

  const enhancers = [];
  const windowIfDefined = typeof window === 'undefined' ? null : window;
  if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
  }

  const store = createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(...middleware), ...enhancers),
  );

  return store;
}
