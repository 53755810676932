/* eslint-disable react/no-unused-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import allActions from '../../../../actions/index';
import { listModes } from '../../../Common/GlobalConstants';

const RemoveFromStaticWL = ({
  selectedItems, setSelectedItems, closeModal, worklistID,
  worklistName, materialMaster, staticWorklists,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authState.user);
  const [visible, setVisible] = useState(true);

  const handleClose = () => {
    setVisible(false);
    closeModal();
  };

  const getWlItems = (wlID) => {
    const wlItems = staticWorklists?.find((wl) => wl?.WORKLIST_ID === wlID)?.MaterialList;
    if (!selectedItems) return wlItems || null;
    if (!wlItems) return null;
    let mmWlItems = wlItems.split('&');
    mmWlItems = mmWlItems?.filter((x) => x && !selectedItems.includes(x));
    if (!mmWlItems?.length) return null;
    return mmWlItems.join('&');
  };

  const handleRemove = () => {
    const metadata = {
      userID: user.uniqueId,
      worklistID,
      worklistName,
    };
    if (materialMaster) {
      const tempList = getWlItems(worklistID);
      dispatch(allActions.WorkListActions.updateMMStaticWLContent(metadata, tempList, false));
    } else {
      dispatch(
        allActions.WorkListActions.removeFromStaticWL(
          metadata, selectedItems, listModes.matList,
        ),
      );
    }
    setSelectedItems([]);
    handleClose();
  };

  return (
    <Modal
      title="Remove from worklist"
      visible={visible}
      onOk={handleRemove}
      onCancel={handleClose}
      style={{ minWidth: '450px' }}
    >
      {`Remove ${selectedItems?.length} selected item(s) from worklist named ${worklistName}?`}
    </Modal>
  );
};

export default RemoveFromStaticWL;

RemoveFromStaticWL.defaultProps = {
  materialMaster: false,
};

RemoveFromStaticWL.propTypes = {
  selectedItems: PropTypes.arrayOf(PropTypes.any).isRequired,
  setSelectedItems: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  worklistID: PropTypes.string.isRequired,
  worklistName: PropTypes.string.isRequired,
  materialMaster: PropTypes.bool,
  staticWorklists: PropTypes.arrayOf(PropTypes.any).isRequired,
};
