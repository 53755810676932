import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Modal, Input, Card, PageHeader, Tabs, Button, Divider,
} from 'antd';
import { DeleteOutlined, FileAddOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import StaticWorklists from './StaticWorklistTable';
import allActions from '../../../../actions';
import { listModes } from '../../../Common/GlobalConstants';

const { TextArea } = Input;

const StaticWorklistsContainer = () => {
  const materialWorklists = useSelector((state) => state.worklistState.staticWorklists);
  const mmWorklists = useSelector((state) => state.worklistState.mmStaticWorklists);
  const userState = useSelector((state) => state.authState.user);
  const defaultWLTab = useSelector(
    (state) => state.commonState.userSettings?.STATIC_WORKLIST_DEFAULT_TAB,
  );
  const dispatch = useDispatch();
  const [selectedMatItems, setSelectedMatItems] = useState([]);
  const [selectedMMItems, setSelectedMMItems] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [worklistName, setWorklistName] = useState('');
  const [worklistDescription, setWorklistDescription] = useState();
  const [nameInUse, setNameInUse] = useState(false);
  const { matList, mmList } = listModes;
  const [currSelectedItems, setCurrSelectedItems] = useState([]);
  const [mmRowKeys, setMMRowKeys] = useState([]);
  const [matRowKeys, setMatRowKeys] = useState([]);
  const [mode, setMode] = useState(matList);
  const [wlTab, setWlTab] = useState(listModes.matList);
  const { TabPane } = Tabs;

  useEffect(() => {
    dispatch(allActions.WorkListActions.clearStaticWorklist(listModes.matList));
    dispatch(allActions.WorkListActions.clearStaticWorklist(listModes.mmList));
  }, []);

  useEffect(() => {
    if (userState?.uniqueId) {
      dispatch(allActions.WorkListActions.getStaticWLs(userState.uniqueId, matList));
      dispatch(allActions.WorkListActions.getStaticWLs(userState.uniqueId, mmList));
    }
  }, [userState?.uniqueId]);

  useEffect(() => {
    if (materialWorklists?.some((e) => e.WorklistName === worklistName)
      || mmWorklists?.some((e) => e.WorklistName === worklistName)) setNameInUse(true);
    else setNameInUse(false);
  }, [worklistName]);

  const setDelete = () => {
    setDeleteModal(true);
  };

  const setCreate = () => {
    setCreateModal(true);
  };

  const deleteWorklists = () => {
    // change list of worklist objects to array of array of worklistIDs
    const worklistIDList = currSelectedItems.map((e) => [e.WORKLIST_ID]);
    dispatch(allActions.WorkListActions.deleteStaticWLs(worklistIDList, userState.uniqueId, mode));
    setDeleteModal(false);
  };

  const createWorklist = () => {
    const regexStr = /^\s+|\s+$/g; // Remove line breaks and spaces at both ends of the string.
    const tempWLName = worklistName?.replace(regexStr, '');
    const tempWLDescr = worklistDescription?.replace(regexStr, '');
    if (!userState?.uniqueId) return;
    const metadata = {
      userID: userState.uniqueId,
      worklistID: uuidv4(),
      noOfRecords: 0,
      worklistName: tempWLName,
      userEmail: userState?.account?.username || null,
      worklistDescription: tempWLDescr,
    };
    dispatch(allActions.WorkListActions.createStaticWL(metadata, {}, [], mode));
    setCreateModal(false);
  };

  const onTabChange = (key) => {
    setSelectedMatItems([]);
    setMMRowKeys([]);
    setMatRowKeys([]);
    setMode(key);
    setWlTab(key);
  };

  useEffect(() => {
    setCurrSelectedItems(_.cloneDeep(selectedMMItems));
  }, [selectedMMItems]);

  useEffect(() => {
    setCurrSelectedItems(_.cloneDeep(selectedMatItems));
  }, [selectedMatItems]);

  useEffect(() => {
    if (mmRowKeys?.length) {
      const tempRows = [];
      selectedMMItems.forEach((x) => {
        if (mmWorklists?.find(
          (wl) => x?.WORKLIST_ID && wl?.WORKLIST_ID === x.WORKLIST_ID,
        )) tempRows.push(x);
      });
      setSelectedMMItems(tempRows);
    }
    if (matRowKeys?.length) {
      const tempRows = [];
      selectedMatItems.forEach((x) => {
        if (materialWorklists?.find(
          (wl) => x?.WORKLIST_ID && wl?.WORKLIST_ID === x.WORKLIST_ID,
        )) tempRows.push(x);
      });
      setSelectedMatItems(tempRows);
    }
  }, [mmWorklists, materialWorklists]);

  useEffect(() => {
    if (defaultWLTab) onTabChange(defaultWLTab);
  }, [defaultWLTab]);

  const tableOperations = (
    (
      <div>
        <div className="divFloatRight">
          <Button type="primary" onClick={() => setCreate()}>
            <FileAddOutlined />
            Create new Static Worklist
          </Button>
          <Divider type="vertical" />
          <Button type="primary" disabled={!currSelectedItems.length} onClick={() => setDelete()}>
            <DeleteOutlined />
            Delete selected items
          </Button>
        </div>
      </div>
    )
  );

  return (
    <>
      <PageHeader
        title="Static Worklists"
        ghost={false}
        style={{ minHeight: '50vh' }}
        extra={tableOperations}
      >
        <Tabs activeKey={wlTab} onChange={(key) => onTabChange(key)}>
          <TabPane tab="Material List" key={matList}>
            <StaticWorklists
              worklists={materialWorklists}
              worklistCount={materialWorklists?.length ?? 0}
              updateSelectedItems={setSelectedMatItems}
              wlRoute="static-work-list"
              selectedRowKeys={matRowKeys}
              setSelectedRowKeys={setMatRowKeys}
            />
          </TabPane>
          <TabPane tab="Material Master List" key={mmList}>
            <StaticWorklists
              worklists={mmWorklists}
              worklistCount={mmWorklists?.length ?? 0}
              updateSelectedItems={setSelectedMMItems}
              wlRoute="material-master/static-work-list"
              selectedRowKeys={mmRowKeys}
              setSelectedRowKeys={setMMRowKeys}
            />
          </TabPane>
        </Tabs>
      </PageHeader>

      <Modal
        title="Delete"
        visible={deleteModal}
        onOk={() => deleteWorklists()}
        onCancel={() => setDeleteModal(false)}
        okText="Confirm"
      >
        Are you sure you want to delete the following worklists?
        <Card>
          {currSelectedItems.map((worklist) => (
            <div key={worklist.WORKLIST_ID}>
              {worklist.WorklistName}
              <br />
            </div>
          ))}
        </Card>
      </Modal>

      <Modal
        title="Create new Static Worklist"
        visible={createModal}
        onOk={() => createWorklist()}
        okButtonProps={{ disabled: !worklistName || worklistName.length < 1 || nameInUse }}
        onCancel={() => setCreateModal(false)}
        style={{ minWidth: '450px' }}
      >
        <TextArea
          autoSize
          placeholder="Worklist Name"
          value={worklistName}
          onChange={(e) => setWorklistName(e.target.value)}
        />
        <br />
        {nameInUse && <span style={{ color: '#cf1322' }}>Name is already in use.</span>}
        <br />
        <TextArea
          autoSize
          placeholder="Worklist Description"
          value={worklistDescription}
          onChange={(e) => setWorklistDescription(e.target.value)}
        />
      </Modal>
    </>
  );
};

export default StaticWorklistsContainer;
