import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import 'antd/dist/antd.css';
import PropTypes from 'prop-types';
import allActions from '../../../actions';
import '../MaterialListView/MaterialListView.css';
import MaterialTableHeader from '../MaterialListView/MaterialTableHeader';
import MaterialTableFooter from '../MaterialListView/MaterialTableFooter';
import MaterialList from '../MaterialListView/MaterialList';
import Loading from '../../Common/Loading';
import {
  generateHeaders, createSearchFilterItem, modes,
} from '../MaterialListView/MaterialListFunctions';
import { USE_WORKLIST_VIEW, listModes, NUMBER_OF_RECORDS_TO_FETCH } from '../../Common/GlobalConstants';

import hasAuthenticatedRole from '../../Common/AuthFunctions';

const MaterialMasterContainer = ({
  worklistID,
  worklistDetails,
  headerCells,
  searchFilters,
  sortColumn,
  sortDirection,
  mode,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const materialListColumns = useSelector((state) => state.materialMasterState.materialListColumns);
  const displayFetchedMaterials = useSelector(
    (state) => state.materialMasterState.materialMasterData,
  );
  const staticWLData = useSelector((state) => state.worklistState.mmStaticWorklist);
  const headerCellsStore = useSelector((state) => state.materialMasterState.displayHeaderCells);
  const selectedRowKeys = useSelector((state) => state.materialMasterState.selectedRowKeys);
  const [displayHeaderCells, setDisplayHeaderCells] = useState([]);
  const [allHeaderCells, setAllHeaderCells] = useState([]);
  const commonStateStore = useSelector((state) => state.commonState);
  const userState = useSelector((state) => state.authState.user);
  const materialViewStore = useSelector(
    (state) => state.materialMasterState.localMaterialViewStore,
  );
  const dropdownValues = useSelector((state) => state.materialState.dropdownValues);
  const checkMatListCount = useSelector((state) => state.materialMasterState.checkMatListCount);
  const [materialViewState, setMaterialViewState] = useState({
    headerCells,
    sortColumn: sortColumn || materialViewStore?.sortColumn,
    sortDirection: sortDirection || materialViewStore?.sortDirection,
    searchFilters: searchFilters || materialViewStore?.searchFilters || {},
    scrollPage: {
      currentNoOfRecords: 0,
      noOfRecordsToFetch: NUMBER_OF_RECORDS_TO_FETCH,
    },
  });
  const [atEndOfList, setAtEndOfList] = useState(false);
  const endOfResults = useSelector(
    (state) => state.materialMasterState.localMaterialViewStore?.atEndOfResults,
  );
  const materialLoading = useSelector((state) => state.materialMasterState.isLoadingData);
  const [readyToFetch, setReadyToFetch] = useState(false);
  const [requireFetch, setRequireFetch] = useState(false);
  const dropdownLoaded = useSelector((state) => state.materialState.loaded?.dropdownValues);
  const matListColumnsLoaded = useSelector(
    (state) => state.materialMasterState.loaded?.materialListColumns,
  );
  const mmlistCount = useSelector(
    (state) => state.materialMasterState.mmSearchCount,
  );
  const reset = useSelector((state) => state.materialMasterState.reset);
  const selectedView = useSelector((state) => state.materialMasterState.selectedView);
  const userViews = useSelector((state) => state.materialState.userViews);
  const userViewsLoaded = useSelector((state) => state.materialState.loaded?.userViews);
  const userSettings = useSelector((state) => state.commonState.userSettings);
  const currencyList = useSelector((state) => state.commonState.currencyList);
  const [headersResolved, setHeadersResolved] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [canViewPrices, setCanViewPrices] = useState(false);
  const [allowRowClick, setAllowRowClick] = useState(true);
  const [hasSelectedAll, setHasSelectedAll] = useState(false);
  const [hasSorted, setHasSorted] = useState(false);
  const [isCopying, setCopying] = useState(false);
  const displayHeaderRef = useRef(displayHeaderCells);
  const defaultCurrencyRef = useRef(null);

  useEffect(() => {
    if (userState?.uniqueId && !userViewsLoaded) {
      dispatch(allActions.MaterialListActions.getUserViews(
        userState.uniqueId,
        userState.account?.username,
      ));
    }
  }, [userState?.uniqueId]);

  const appendDefaultFilters = (currentFilters) => {
    const filters = currentFilters ?? {};
    if (!filters.MATERIAL_TYPE_DELETION_IND) {
      filters.MATERIAL_TYPE_DELETION_IND = [
        {
          ColumnName: 'MATERIAL_TYPE_DELETION_IND',
          FilterOperator: 'EqualTo',
          FilterValue: ['0'],
        },
      ];
    }
    return { ...filters };
  };

  useEffect(() => {
    if (userState?.idTokenClaims?.roles) {
      const userCanViewPrices = hasAuthenticatedRole(
        userState?.idTokenClaims?.roles, process.env.REACT_APP_VIEW_PRICES,
      );
      setCanViewPrices(userCanViewPrices);
    }
  }, [userState?.idTokenClaims]);

  useEffect(() => {
    if (checkMatListCount) {
      if (mmlistCount?.[0]?.NoofRecords
        && mmlistCount?.[0]?.NoofRecords < displayFetchedMaterials?.length) {
        dispatch(allActions.MMActions.clearMMData());
      }
      dispatch(allActions.MMActions.setCheckMatMasterCount(false));
    }
  }, [checkMatListCount]);

  const changeSearchFilters = (filterValue) => {
    setMaterialViewState({
      ...materialViewState,
      searchFilters: appendDefaultFilters(filterValue),
      scrollPage: {
        currentNoOfRecords: 0,
        noOfRecordsToFetch: NUMBER_OF_RECORDS_TO_FETCH,
      },
    });
  };

  const fetchMaterials = () => {
    if (mode === modes.STATIC) {
      const offset = staticWLData?.length ?? 0;
      dispatch(allActions.WorkListActions.getStaticWL(
        worklistID,
        materialViewState.sortColumn,
        materialViewState.sortDirection,
        materialViewState.scrollPage.noOfRecords,
        hasSorted ? 0 : offset,
        listModes.mmList,
      ));
    } else {
      const newSearchID = uuidv4();
      dispatch(allActions.MMActions.setLoading());
      dispatch(allActions.MMActions.setSearchID(newSearchID));
      dispatch(
        allActions.MMActions.fetchMMList(materialViewState, newSearchID),
      );
      if (materialViewState.scrollPage.currentNoOfRecords === 0) {
        dispatch(allActions.MMActions.setMMCountNull());
        dispatch(allActions.MMActions.fetchMMCount(
          materialViewState, newSearchID,
        ));
      }
    }
    if (selectedItems?.length > 0) {
      setSelectedItems([]);
      dispatch(allActions.MMActions.setSelectedRowKeys([]));
    }
    setHasSorted(false);
  };

  const updateDisplayHeaderCells = (newDisplayHeaderCells) => {
    displayHeaderRef.current = newDisplayHeaderCells;
    setDisplayHeaderCells(newDisplayHeaderCells);
  };

  useEffect(() => {
    if (matListColumnsLoaded
      && materialViewState
      && !readyToFetch) {
      if (userSettings?.DEFAULT_MM_USER_VIEW && !selectedView) {
        dispatch(allActions.MMActions.selectView(userSettings?.DEFAULT_MM_USER_VIEW));
      }
      setHeadersResolved(true);
    }
  }, [matListColumnsLoaded, materialViewState]);

  useEffect(() => {
    if (headersResolved) {
      if (headerCellsStore?.length > 0 && !worklistID) {
        updateDisplayHeaderCells(headerCellsStore);
      }
      setReadyToFetch(true);
    }
  }, [headersResolved]);

  useEffect(() => {
    setRequireFetch(true);
    if (materialViewState) {
      dispatch(allActions.MMActions.setLocalMaterialView(materialViewState));
    }
  }, [materialViewState]);

  const getColumnData = (changedView) => {
    // avoid applying stored column setup if in worklist mode
    if (headerCellsStore && !changedView && !worklistID) return headerCellsStore;
    if (selectedView) return userViews?.find((v) => v.VIEW_ID === selectedView)?.columns;
    if (selectedView === USE_WORKLIST_VIEW && headerCells.length > 0) return headerCells;
    return null;
  };

  const updateWidth = (columnName, newWidth) => {
    const tempDisplayHeaderCells = [...displayHeaderRef.current];
    const i = tempDisplayHeaderCells.findIndex((x) => x.dataIndex === columnName);
    if (i !== -1) {
      tempDisplayHeaderCells[i] = {
        ...tempDisplayHeaderCells[i],
        width: newWidth,
      };
      updateDisplayHeaderCells(tempDisplayHeaderCells);
    }
  };

  const updateFilters = (filters, usingAdvanced) => {
    const updatedFilters = _.cloneDeep(materialViewState.searchFilters);
    const displayFilters = _.cloneDeep(displayHeaderCells);
    if (usingAdvanced) {
      for (let i = 0; i < displayFilters.length; i++) {
        displayFilters[i].filteredValue = null;
        displayFilters[i].filterDropdown = null;
      }
      setMaterialViewState({
        ...materialViewState,
        searchFilters: appendDefaultFilters(filters),
        scrollPage: {
          currentNoOfRecords: 0,
          noOfRecordsToFetch: NUMBER_OF_RECORDS_TO_FETCH,
        },
      });
      updateDisplayHeaderCells(displayFilters);
    } else {
      Object.keys(filters).forEach((filter) => {
        for (let i = 0; i < displayHeaderCells.length; i++) {
          if (filter === displayHeaderCells[i].dataIndex) {
            if (filters[filter]?.length) {
              displayFilters[i].filteredValue = filters[filter];
              updatedFilters[filter] = [{
                ColumnName: filter, FilterValue: filters[filter], FilterOperator: 'Contains', fromTable: true,
              }];
            } else {
              delete updatedFilters[filter];
              delete displayFilters[i].filteredValue;
            }
          }
        }
      });
      changeSearchFilters(updatedFilters);
    }
  };

  const onChangeFilterSort = (pagination, filters, sorter, extra) => {
    if (extra.action === 'sort') {
      if (sorter.order !== undefined) {
        if (mode === modes.STATIC) setHasSorted(true);
        setMaterialViewState({
          ...materialViewState,
          sortColumn: sorter.field,
          sortDirection: sorter.order === 'ascend' ? 'ASC' : 'DESC',
          scrollPage: {
            currentNoOfRecords: 0,
            noOfRecordsToFetch: NUMBER_OF_RECORDS_TO_FETCH,
          },
        });
      } else {
        setMaterialViewState({
          ...materialViewState,
          sortColumn: '',
          sortDirection: '',
          scrollPage: {
            currentNoOfRecords: 0,
            noOfRecordsToFetch: NUMBER_OF_RECORDS_TO_FETCH,
          },
        });
      }
    }
    if (extra.action === 'search') {
      changeSearchFilters(filters);
    }
  };

  const updateHeaders = (changedView, currentCurrency) => {
    let currency = {};
    if (currentCurrency) {
      const temp = currencyList?.find((x) => currentCurrency === x?.FROM_CURRENCY);
      currency = { ...temp, defaultCurrency: currentCurrency };
    }
    if (readyToFetch) {
      const generatedHeaders = generateHeaders(
        materialListColumns,
        dropdownValues,
        getColumnData(changedView),
        materialViewState.searchFilters,
        mode,
        updateWidth,
        onChangeFilterSort,
        false,
        canViewPrices,
        currency,
        true,
      );
      updateDisplayHeaderCells(generatedHeaders.tempHeaderCells);
      setAllHeaderCells(generatedHeaders.tempAllCells);
    }
  };

  useEffect(() => {
    if (dropdownLoaded && matListColumnsLoaded && readyToFetch) {
      updateHeaders(null, userSettings?.DEFAULT_CURRENCY);
    }
  }, [materialViewState?.searchFilters,
    materialViewState?.sortColumn,
    materialViewState?.sortDirection,
    readyToFetch,
    dropdownValues,
    materialListColumns,
  ]);

  const updateLocally = () => {
    updateHeaders(true, userSettings?.DEFAULT_CURRENCY);
  };

  useEffect(() => {
    updateLocally();
  }, [selectedView, userSettings?.DEFAULT_CURRENCY]);

  useEffect(() => {
    defaultCurrencyRef.current = userSettings?.DEFAULT_CURRENCY;
  }, [userSettings?.DEFAULT_CURRENCY]);

  useEffect(() => {
    if (reset) {
      setMaterialViewState({
        ...materialViewState,
        searchFilters: appendDefaultFilters({}),
        sortColumn: undefined,
        sortDirection: undefined,
        scrollPage: {
          currentNoOfRecords: 0,
          noOfRecordsToFetch: NUMBER_OF_RECORDS_TO_FETCH,
        },
      });
      if (userSettings?.DEFAULT_MM_USER_VIEW) {
        dispatch(allActions.MMActions.selectView(userSettings.DEFAULT_MM_USER_VIEW));
      } else {
        dispatch(allActions.MMActions.selectView(null));
      }
      dispatch(allActions.MMActions.setSelectedRowKeys([]));
      dispatch(allActions.MMActions.resetComplete());
    }
  }, [reset]);

  useEffect(() => {
    if (requireFetch && readyToFetch) {
      fetchMaterials();
      setRequireFetch(false);
    }
  }, [requireFetch, readyToFetch]);

  useEffect(() => {
    if (commonStateStore.quickSearchObject) {
      const {
        quickSearchText,
        searchMMR,
        selectedPlants,
        selectedCCs,
        funcLocations,
        storageBins,
        contractNos,
        searchOperators,
      } = commonStateStore.quickSearchObject;
      const filters = {};
      let quicksearchFilter;
      if (quickSearchText.length > 0) {
        const operator = quickSearchText.some((text) => text.includes('*')) ? 'WildCard' : searchOperators?.keywords;
        const searchText = quickSearchText.map((text) => text.replaceAll('*', '%'));
        quicksearchFilter = createSearchFilterItem('QUICK_SEARCH', operator, searchText, true);
      }
      const searchMMRfilter = searchMMR.length > 0 ? createSearchFilterItem('MATERIAL_TYPE_SAP_ID', searchOperators?.material, searchMMR) : null;
      const plantFilter = selectedPlants.length > 0 ? createSearchFilterItem('PLANT_FACILITY_SAP_ID', searchOperators?.plant, selectedPlants) : null;
      const ccFilter = selectedCCs.length > 0 ? createSearchFilterItem('COMPANY_CODE', searchOperators?.companyCode, selectedCCs) : null;
      const funcLocationFilter = funcLocations.length > 0 ? createSearchFilterItem('FACILITY_SAP_ID', searchOperators?.funcLoc, funcLocations) : null;
      const storageBinFilter = storageBins.length > 0 ? createSearchFilterItem('STORAGE_BIN', searchOperators?.storageBin, storageBins) : null;
      const contractNosFilter = contractNos.length > 0 ? createSearchFilterItem('CONTRACT_NUMBER', searchOperators?.contractNos, contractNos) : null;

      Object.assign(filters, quicksearchFilter);
      Object.assign(filters, searchMMRfilter);
      Object.assign(filters, plantFilter);
      Object.assign(filters, ccFilter);
      Object.assign(filters, funcLocationFilter);
      Object.assign(filters, storageBinFilter);
      Object.assign(filters, contractNosFilter);
      changeSearchFilters(filters);
      dispatch(allActions.CommonActions.setQuickSearchNull());
    }
  }, [commonStateStore.quickSearchObject]);

  useEffect(() => {
    if (userState?.uniqueId) {
      setAtEndOfList(false);
      dispatch(allActions.WorkListActions.getStaticWLs(
        userState?.uniqueId, listModes.mmList,
      ));
    }
  }, [displayFetchedMaterials, staticWLData, userState]);

  useEffect(() => {
    if (displayHeaderCells?.length > 0) {
      dispatch(allActions.MMActions.setDisplayHeaderCells(displayHeaderCells));
    }
  }, [displayHeaderCells]);

  const endOfList = () => {
    if (!endOfResults) {
      // logic for end of list
      const currentNoOfRecords = (materialViewState.scrollPage.currentNoOfRecords
        + NUMBER_OF_RECORDS_TO_FETCH);
      const noRowsToFetch = materialViewState.scrollPage.noOfRecordsToFetch;
      setMaterialViewState({
        ...materialViewState,
        scrollPage: {
          currentNoOfRecords,
          noOfRecordsToFetch: noRowsToFetch,
        },
      });
    }
  };

  useEffect(() => {
    if (atEndOfList) {
      if (mode === modes.STATIC) {
        if (worklistDetails.NoOfRecords <= staticWLData.length) return;
      } else if (mmlistCount?.[0]?.NoofRecords
        && displayFetchedMaterials?.length
        && displayFetchedMaterials.length === mmlistCount[0].NoofRecords) return;
      endOfList();
    }
  }, [atEndOfList]);

  useEffect(() => {
    if (materialViewState?.scrollPage) {
      const { noOfRecordsToFetch: fetch, currentNoOfRecords: curr } = materialViewState?.scrollPage;
      setHasSelectedAll(fetch + curr === selectedItems?.length);
    }
  }, [materialViewState, selectedItems]);

  const rowSelection = {
    onChange: (_selectedRowKeys, userSelectedRows) => {
      const materials = userSelectedRows.map((x) => x.MATERIAL_TYPE_SAP_ID);
      setSelectedItems(materials);
      const sortedRowKeys = _selectedRowKeys.sort((a, b) => {
        const numA = Number.parseInt(a, 10);
        const numB = Number.parseInt(b, 10);
        if (Number.isNaN(numA) || Number.isNaN(numB)) return 0;
        return numA - numB;
      });
      dispatch(allActions.MMActions.setSelectedRowKeys(sortedRowKeys));
    },
    selectedRowKeys,
    fixed: true,
    columnWidth: '50px',
  };

  const handleRowClick = (plant, material, e, record) => {
    if (allowRowClick && !isCopying) {
      if (e?.target?.cellIndex === 0) return;
      const tempFilters = {
        MATERIAL_TYPE_SAP_ID: [{
          ColumnName: 'MATERIAL_TYPE_SAP_ID',
          FilterOperator: 'EqualTo',
          FilterValue: [record?.MATERIAL_TYPE_SAP_ID],
        }],
      };
      dispatch(allActions.CommonActions.setPlantLvlMatView(tempFilters));
      history.push('/material-list');
    }
  };

  const tableMatProps = {
    title: () => (
      <MaterialTableHeader
        setDisplayHeaderCells={updateDisplayHeaderCells}
        worklistID={worklistID}
        worklistDetails={worklistDetails}
        userName={userState?.account?.name}
        updateFilters={updateFilters}
        displayHeaderCells={displayHeaderRef.current}
        allHeaderCells={allHeaderCells}
        useGlobalDefault={false}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        mode={mode}
        worklistHasView={headerCells?.length > 0}
        displayData={displayFetchedMaterials}
        materialViewState={materialViewState}
        setAllowRowClick={setAllowRowClick}
        setMaterialViewState={setMaterialViewState}
        canViewPrices={canViewPrices}
        searchFilters={materialViewStore?.searchFilters}
        materiallistCount={mmlistCount}
        materialMaster
        updateLocally={updateLocally}
        hasSelectedAll={hasSelectedAll}
      />
    ),
    footer: () => (
      <MaterialTableFooter
        materialMaster
        mode={mode}
        noOfRecords={worklistDetails?.NoOfRecords}
        data={displayFetchedMaterials}
        listCount={mmlistCount}
        staticWLCount={staticWLData?.length}
      />
    ),
    scroll: { y: window.innerHeight - 280 },
    pagination: false,
  };

  return (
    displayHeaderCells.length > 0
      ? (
        <MaterialList
          additionalTableProps={tableMatProps}
          displayHeaderCells={displayHeaderRef.current}
          setDisplayHeaderCells={updateDisplayHeaderCells}
          handleRowClick={handleRowClick}
          data={mode === modes.STATIC ? staticWLData : displayFetchedMaterials}
          loading={mode === modes.STATIC ? false : materialLoading}
          onChange={onChangeFilterSort}
          setAtEndOfList={setAtEndOfList}
          rowSelection={rowSelection}
          setCopying={setCopying}
          materialMaster
        />
      )
      : <Loading text="Loading Material Master List" />);
};

MaterialMasterContainer.defaultProps = {
  worklistID: '',
  worklistDetails: {},
  headerCells: [],
  searchFilters: null,
  sortColumn: null,
  sortDirection: null,
  mode: '',
};

MaterialMasterContainer.propTypes = {
  worklistID: PropTypes.string,
  worklistDetails: PropTypes.shape({
    WorklistID: PropTypes.string,
    WorklistName: PropTypes.string,
    WorklistDescription: PropTypes.string,
    CreatedDate: PropTypes.string,
    ModifiedDate: PropTypes.string,
    CreatedBy: PropTypes.string,
    ModifiedBy: PropTypes.string,
    IsWorklistShared: PropTypes.bool,
    CreatedUserEmail: PropTypes.string,
    ModifiedByUserEmail: PropTypes.string,
    NoOfRecords: PropTypes.number,
  }),
  headerCells: PropTypes.arrayOf(PropTypes.any),
  searchFilters: PropTypes.objectOf(PropTypes.any),
  sortColumn: PropTypes.string,
  sortDirection: PropTypes.string,
  mode: PropTypes.oneOf(Object.values(modes)),
};

export default MaterialMasterContainer;
