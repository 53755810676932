import React, { useState, useEffect } from 'react';
import {
  Button, Card, Empty, Row, Select, Spin,
} from 'antd';
import PropTypes from 'prop-types';
import { ExportOutlined, InfoCircleTwoTone } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { localDateTimeFormat, localTimeFormat } from '../../../../../Common/DateAndNumberFunctions';
import { retrieveSimulationResult, retrieveSimulations } from '../../../../../../actions/SimulationDomain/LoadingKeys';
import Loading from '../../../../../Common/Loading';
import SimInputDisplay from '../../../../SimulationViews/SimInputDisplay';
import MaterialSimResult from '../../../../../Common/Simulation/MaterialSimResult';
import allActions from '../../../../../../actions';
import { transformInputObject } from './SimulationFunctions';

const SimulationResults = ({ onClose, returnSimulatedValues }) => {
  const { Option } = Select;
  const { plant, material } = useParams();
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.simState.loading);
  const allSimJobs = useSelector((state) => state.simState.allSimJobs);
  const simulationID = useSelector((state) => state.simState.simulationID);
  const simulationResults = useSelector((state) => state.simState.simResults);
  const userEmail = useSelector((state) => state.authState.user?.account?.username);

  const [relevantSimJobs, setRelevantSimJobs] = useState([]);
  const [selectedSim, setSelectedSim] = useState(null);

  const getAllSimulations = () => {
    dispatch(allActions.SimulationActions.setLoading(retrieveSimulations));
    dispatch(allActions.SimulationActions.getAllSimJobs(userEmail));
  };

  useEffect(() => {
    if (userEmail) {
      getAllSimulations();
    }
  }, [userEmail]);

  useEffect(() => {
    if (simulationID) {
      getAllSimulations();
    }
  }, [simulationID]);

  const retrieveResults = () => {
    dispatch(allActions.SimulationActions.setLoading(retrieveSimulationResult));
    dispatch(allActions.SimulationActions.retrieveSimulation(selectedSim));
  };

  useEffect(() => {
    if (selectedSim) {
      if (!simulationResults?.[selectedSim]?.results) {
        retrieveResults();
      }
    }
  }, [selectedSim]);

  useEffect(() => {
    const simStatus = simulationResults?.[selectedSim]?.status;
    if (selectedSim && simStatus && (simStatus === 'RUNNING' || simStatus === 'PENDING')) {
      retrieveResults();
    }
  }, [selectedSim, simulationResults]);

  const exportSimulation = () => {
    const formattedData = transformInputObject(simulationResults?.[selectedSim]?.results);
    const focusedSimData = formattedData?.length && formattedData?.find(
      (x) => x.PLANT_FACILITY_SAP_ID.toString() === plant
        && x.MATERIAL_TYPE_SAP_ID.toString() === material,
    );
    returnSimulatedValues(focusedSimData);
    onClose();
  };

  const getResults = () => {
    if (loading?.[retrieveSimulationResult]) {
      return <Loading text="Awaiting simulation result..." />;
    }
    if (loading?.[retrieveSimulations]) {
      return <Loading text="Loading simulations..." />;
    }
    if (selectedSim && simulationResults?.[selectedSim]?.results) {
      return (
        <>
          <SimInputDisplay simID={selectedSim} />
          <MaterialSimResult
            simData={simulationResults?.[selectedSim]?.results}
            focusedPlant={plant}
          />
        </>
      );
    }
    if (selectedSim && simulationResults?.[selectedSim]?.status === 'FAILED') {
      return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Spin
            tip={<p style={{ color: '#ff4d4f' }}>Simulation failed</p>}
            indicator={<InfoCircleTwoTone twoToneColor="#ff4d4f" style={{ fontSize: 28 }} />}
          />
        </div>
      );
    }
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No results found" />;
  };

  useEffect(() => {
    if (allSimJobs && Object.keys(allSimJobs)?.length > 0) {
      const relSimJobs = Object.values(allSimJobs)
        .filter((job) => job.PlantID === plant && job.MaterialID === material)
        .filter((job) => job.Type === 'Single Material')
        .sort((a, b) => new Date(b.RunTimeStamp) - new Date(a.RunTimeStamp));
      if (relSimJobs?.length > 0) {
        const mostRecent = relSimJobs[0];
        setSelectedSim(mostRecent.RunID);
      } else {
        setSelectedSim(null);
      }
      setRelevantSimJobs(relSimJobs);
    } else {
      setRelevantSimJobs([]);
    }
  }, [allSimJobs]);

  return (
    <Row>
      <Card
        title={
          simulationResults?.[selectedSim]?.metadata?.start_time
            ? `Results from simulation job submitted at ${localTimeFormat(new Date(simulationResults[selectedSim].metadata.start_time))}`
            : 'Results'
        }
        style={{ width: '100%' }}
      >
        {getResults()}
        <>
          <Button
            type="primary"
            className="bottomRightButton"
            onClick={exportSimulation}
            disabled={!simulationResults?.[selectedSim]?.results
              || simulationResults?.[selectedSim]?.results?.length === 0}
          >
            <ExportOutlined />
            Export to recommendations
          </Button>
          <Select
            className="bottomRightButton"
            disabled={!relevantSimJobs.length}
            style={{ width: 200 }}
            value={selectedSim}
            onChange={(value) => setSelectedSim(value)}
          >
            {relevantSimJobs.map((job) => (
              <Option key={job.RunID} value={job.RunID}>
                {localDateTimeFormat(new Date(job.RunTimeStamp.replace('Z', '')))}
              </Option>
            ))}
          </Select>
        </>
      </Card>
    </Row>
  );
};

export default SimulationResults;
SimulationResults.propTypes = {
  onClose: PropTypes.func.isRequired,
  returnSimulatedValues: PropTypes.func.isRequired,
};
