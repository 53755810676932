import React from 'react';
import PropTypes from 'prop-types';
import { Select, Empty } from 'antd';
import LoadingIndicator from './LoadingIndicator';

const { Option, OptGroup } = Select;

const MultiItemSelector = ({
  items,
  placeHolder,
  multiSelect,
  selectedValues,
  defaultValue,
  handleChange,
  clear,
  size,
  disabled,
  maxTagCount,
  maxTagPlaceholder,
  maxTagTextLength,
  autoClearSearchValue,
  onPasteCapture,
  onDropdownVisibleChange,
  onFocusLeave,
  onInputKeyDown,
  tagRender,
  style,
  onlyID,
  onSearch,
  groupedItems,
}) => (
  <Select
    showSearch
    allowClear
    mode={multiSelect ? 'multiple' : null}
    size={size}
    style={style}
    placeholder={placeHolder}
    loading={!items?.length}
    optionFilterProp="children"
    notFoundContent={items?.length ? <Empty /> : <LoadingIndicator />}
    defaultValue={defaultValue || undefined}
    value={selectedValues}
    onChange={(value, event) => handleChange(event)}
    onClear={clear}
    disabled={disabled}
    tagRender={tagRender}
    maxTagCount={maxTagCount}
    maxTagPlaceholder={maxTagPlaceholder}
    maxTagTextLength={maxTagTextLength}
    autoClearSearchValue={autoClearSearchValue}
    onPasteCapture={onPasteCapture}
    onBlur={onFocusLeave}
    onInputKeyDown={onInputKeyDown}
    onDropdownVisibleChange={onDropdownVisibleChange}
    onSearch={onSearch}
  >
    {
    groupedItems?.length
      ? (
        <>
          <OptGroup label="Plant Groups">
            {
              groupedItems.map(
                (groupedItem) => (
                  <Option
                    key={groupedItem.name || null}
                    value={groupedItem.value || null}
                    label={groupedItem.name || null}
                  >
                    {`${groupedItem.name}`}
                  </Option>
                ),
              )
            }
          </OptGroup>
          <OptGroup label="Plants">
            {
              items.map(
                (item) => (
                  <Option
                    key={item.id || null}
                    value={item.id || null}
                    label={item.id || null}
                  >
                    { onlyID ? item.id : `${item.name} (${item.id})` || 'Name missing'}
                  </Option>
                ),
              )
            }
          </OptGroup>
        </>
      ) : null
    }
    {items && !groupedItems?.length ? (
      items.map(
        (item) => (
          <Option
            key={item.id || null}
            value={item.id || null}
            label={item.id || null}
          >
            { onlyID ? item.id : `${item.name} (${item.id})` || 'Name missing'}
          </Option>
        ),
      )
    ) : null}
  </Select>
);

export default MultiItemSelector;

MultiItemSelector.defaultProps = {
  items: [],
  multiSelect: false,
  selectedValues: undefined,
  defaultValue: undefined,
  size: 'large',
  disabled: false,
  maxTagCount: null,
  maxTagPlaceholder: null,
  maxTagTextLength: null,
  autoClearSearchValue: true,
  onPasteCapture: null,
  onDropdownVisibleChange: null,
  onFocusLeave: null,
  onInputKeyDown: null,
  onSearch: null,
  tagRender: null,
  style: { width: '100%' },
  onlyID: false,
  groupedItems: [],
};

MultiItemSelector.propTypes = {
  items: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  placeHolder: PropTypes.string.isRequired,
  multiSelect: PropTypes.bool,
  selectedValues: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  handleChange: PropTypes.func.isRequired,
  clear: PropTypes.func.isRequired,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  maxTagCount: PropTypes.number,
  maxTagPlaceholder: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  maxTagTextLength: PropTypes.number,
  autoClearSearchValue: PropTypes.bool,
  onPasteCapture: PropTypes.func,
  onDropdownVisibleChange: PropTypes.func,
  onFocusLeave: PropTypes.func,
  onInputKeyDown: PropTypes.func,
  onSearch: PropTypes.func,
  tagRender: PropTypes.func,
  style: PropTypes.PropTypes.objectOf(PropTypes.any),
  onlyID: PropTypes.bool,
  groupedItems: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
};
