import React from 'react';
import PropTypes from 'prop-types';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

const InfoToolTip = ({ tooltip, fontSize, onClick }) => (
  <Tooltip overlayStyle={{ whiteSpace: 'pre-line' }} placement="rightTop" title={tooltip}>
    <InfoCircleOutlined onClick={onClick} placement="rightBottom" style={{ paddingLeft: '5px', fontSize: `${fontSize}px` }} />
  </Tooltip>
);
export default InfoToolTip;

InfoToolTip.defaultProps = {
  fontSize: 14,
  onClick: null,
};

InfoToolTip.propTypes = {
  tooltip: PropTypes.string.isRequired,
  fontSize: PropTypes.number,
  onClick: PropTypes.func,
};
