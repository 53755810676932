import React, {
  useEffect, useLayoutEffect, useRef, useState,
} from 'react';
import { Line } from '@nivo/line';
import { Table, Button } from 'antd';
import PropTypes from 'prop-types';
import {
  localDateFormat,
  isoDateToFirstOfMonth,
  timeString,
} from '../../../../Common/DateAndNumberFunctions';
import '../../MaterialDetails.css';
import {
  GRAPH_TYPES, generateAllMonths, getStrings, formatLargeNumber,
} from './TabGraphHelpers';

export default function TabGraph({
  filteredData, type, containerID, activeTabKey,
}) {
  const [graphData, setGraphData] = useState([]);
  const [eventsByMonth, setEventsByMonth] = useState({});
  const [maxTick, setMaxTick] = useState(0);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [showQuantity, setShowQuantity] = useState(true);
  const [tooltipColumns, setTooltipColumns] = useState([]);
  const elementRef = useRef(null);
  const displayStrings = getStrings(type);

  useEffect(() => {
    if (filteredData && filteredData.length > 0) {
      // sort the data received
      const sortedData = filteredData
        .sort((a, b) => new Date(a.EDAM_POSTING_DT) - new Date(b.EDAM_POSTING_DT));
      // create array of all months
      const allMonths = generateAllMonths(sortedData);
      // create an object that stores the month and each event in that month
      const newEventsByMonth = {};
      // for all the real data, tally up the monthly events
      sortedData.forEach((value) => {
        const date = timeString(isoDateToFirstOfMonth(value.EDAM_POSTING_DT));
        const monthObject = allMonths.find((item) => item.x === date);
        if (showQuantity) {
          monthObject.y += value.MATERIAL_BASE_UOM_QTY;
        } else {
          monthObject.y += 1;
        }
        // for all real data, store for a given monthly event each individual line
        if (newEventsByMonth[date]) {
          newEventsByMonth[date] = [...newEventsByMonth[date], value];
        } else {
          newEventsByMonth[date] = [value];
        }
      });
      // set y-axis ticks by counting the highest number of monthly events
      const highestQty = allMonths.reduce((prev, curr) => (curr.y > prev ? curr.y : prev), 0);
      setMaxTick(Math.min(highestQty, 15));

      const newGraphData = [{ id: 'amount', data: allMonths }];
      setGraphData(newGraphData);
      setEventsByMonth(newEventsByMonth);
      const columns = [
        {
          title: 'Date',
          dataIndex: 'date',
        },
        {
          title: 'Qty',
          dataIndex: 'qty',
        },
        {
          title: type === GRAPH_TYPES.consumption ? 'Source plant' : 'Dest. plant',
          dataIndex: 'plant',
        },
      ];
      if (type === GRAPH_TYPES.consumption) {
        columns.push({
          title: type === GRAPH_TYPES.consumption ? 'Planning plant' : null,
          dataIndex: type === GRAPH_TYPES.consumption ? 'planningPlant' : null,
        });
      }
      setTooltipColumns(columns);
    } else {
      setGraphData();
    }
  }, [filteredData, showQuantity]);

  const lookupEvents = (datestring) => {
    const key = new Date(datestring).toISOString();
    const events = eventsByMonth[key] && eventsByMonth[key].map((x, i) => ({
      key: i,
      date: localDateFormat(x.EDAM_POSTING_DT),
      qty: x.MATERIAL_BASE_UOM_QTY,
      planningPlant: x.PLANNING_PLANT_ID,
      plant: type === GRAPH_TYPES.consumption
        ? x.SOURCE_CONS_PLANT_ID
        : (x.OFFSHORE_PLANT ?? x.PLANT_FACILITY_SAP_ID),
    }));
    return events;
  };

  useLayoutEffect(() => {
    setWidth(elementRef?.current?.clientWidth ?? 1);
    setHeight(document.getElementById(containerID)?.clientHeight);
    const resizeListener = () => {
      const newHeight = document.getElementById(containerID)?.clientHeight;
      const newWidth = elementRef?.current?.clientWidth ?? 1;
      if (newWidth > 0) setWidth(newWidth);
      if (newHeight > 0) setHeight(newHeight);
    };
    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  useEffect(() => {
    const newHeight = document.getElementById(containerID)?.clientHeight;
    const newWidth = elementRef?.current?.clientWidth ?? 1;
    if (newWidth > 0) setWidth(newWidth);
    if (newHeight > 0) setHeight(newHeight);
  }, [activeTabKey]);

  return (
    <div style={{ height: '100%' }}>
      <div className="topRightGraphButton" style={{ display: graphData?.length ? 'block' : 'none' }}>
        <Button type={showQuantity ? 'primary' : 'default'} onClick={() => setShowQuantity(true)}>{displayStrings.showQuantity}</Button>
        <Button type={!showQuantity ? 'primary' : 'default'} onClick={() => setShowQuantity(false)}>{displayStrings.showEvents}</Button>
      </div>
      <div ref={elementRef} style={{ height: '100%' }}>
        {
          graphData?.length > 0 && height > 0
            ? (
              <Line
                curve="monotoneX"
                data={graphData}
                yFormat={() => null}
                axisLeft={{
                  legend: showQuantity ? displayStrings.quantity : displayStrings.events, legendPosition: 'middle', legendOffset: '-50', format: (value) => formatLargeNumber(value), tickValues: maxTick,
                }}
                xScale={{ format: '%Y-%m-%dT%H:%M:%S.%LZ', type: 'time' }}
                xFormat="time:%Y-%m-%dT%H:%M:%S.%LZ"
                axisBottom={{
                  legend: 'Month/Year',
                  legendPosition: 'middle',
                  legendOffset: '40',
                  tickRotation: 22,
                  format: (value) => `${(value?.getMonth() + 1).toString().padStart(2, '0')}/${value?.getFullYear()}`,
                }}
                tooltip={(x) => {
                  const maxItemsInTable = 10;
                  const getDate = (xValue) => xValue.split('T')[0];
                  const dataSource = lookupEvents(x.point.data.x)?.sort((a, b) => b.qty - a.qty);
                  const tooltips = dataSource?.slice(0, maxItemsInTable);
                  const hiddenItems = dataSource?.length - maxItemsInTable;
                  const currentDate = getDate(x.point.data.xFormatted);
                  const index = graphData[0].data.findIndex((v) => (getDate(v.x) === currentDate));
                  const isFirstHalf = (index < (graphData[0]?.data?.length / 2));
                  return dataSource ? (
                    <div style={{
                      position: 'absolute',
                      right: isFirstHalf ? null : 0,
                      backgroundColor: '#ffffff',
                      border: '1px solid #a1a1a1',
                      boxShadow: '3px 3px 3px #a1a1a1',
                    }}
                    >
                      <Table
                        bordered
                        style={{ width: '100%' }}
                        size="small"
                        pagination={false}
                        columns={tooltipColumns}
                        dataSource={tooltips}
                        footer={hiddenItems > 0 ? () => (<strong style={{ width: '100%' }}>{`Showing ${maxItemsInTable} of ${hiddenItems + maxItemsInTable} items, sorted by qty`}</strong>) : null}
                      />
                    </div>
                  ) : null;
                }}
                width={width}
                height={height}
                isInteractive
                useMesh
                enablePointLabel
                margin={{
                  top: 40,
                  right: 10,
                  bottom: 70,
                  left: 60,
                }}
              />
            )
            : <></>
        }
      </div>
    </div>
  );
}

TabGraph.defaultProps = {
  filteredData: [],
  containerID: '',
  activeTabKey: '0',
};

TabGraph.propTypes = {
  filteredData: PropTypes.arrayOf(PropTypes.any),
  type: PropTypes.string.isRequired,
  containerID: PropTypes.string,
  activeTabKey: PropTypes.string,
};
