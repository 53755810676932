import React from 'react';
import PropTypes from 'prop-types';
import AdvancedSearch from './AdvancedSearch';

const AdvancedSearchContainer = ({
  onClose, updateFilters, canViewPrices, materialListFilters, materialMaster,
}) => {
  const submit = (searchFilters) => {
    updateFilters(searchFilters, true);
  };

  return (
    <AdvancedSearch
      materialMaster={materialMaster}
      materialListFilters={materialListFilters}
      submit={submit}
      onClose={onClose}
      canViewPrices={canViewPrices}
    />
  );
};

export default AdvancedSearchContainer;

AdvancedSearchContainer.propTypes = {
  canViewPrices: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  updateFilters: PropTypes.func.isRequired,
  materialMaster: PropTypes.bool,
  materialListFilters: PropTypes.objectOf(PropTypes.any).isRequired,
};

AdvancedSearchContainer.defaultProps = {
  canViewPrices: true,
  materialMaster: false,
};
