import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Menu, Checkbox, Dropdown, Tooltip, Col, Row, Input,
} from 'antd';
import {
  FilterOutlined, SortAscendingOutlined, SortDescendingOutlined, UpOutlined, DownOutlined,
} from '@ant-design/icons';
import _ from 'lodash';
import './FilterMenuView.css';
import { v4 as uuidv4 } from 'uuid';
import { sensitiveValues, unSelectableCols } from '../../../Common/SpecialValues';
import MMColumnSelector from './MMColumnSelector';

const ColumnSelector = ({
  setDisplayHeaderCells,
  displayHeaderCells,
  allHeaderCells,
  colSelVisible,
  setColSelVisible,
  canViewPrices,
  materialMaster,
}) => {
  const sortOrders = {
    AZ: 'az',
    ZA: 'za',
  };

  const [hasChanged, setHasChanged] = useState(false);
  const [sortOrder, setSortOrder] = useState(sortOrders.AZ);
  const [searchValue, setSearchValue] = useState('');
  const [localDHC, setLocalDHC] = useState(displayHeaderCells);
  const [filteredDisplayCells, setFilteredDisplayCells] = useState(displayHeaderCells);
  const [filteredAllCells, setFilteredAllCells] = useState(allHeaderCells);
  const [id, setId] = useState();
  const [hiddenCols, setHiddenCols] = useState(unSelectableCols);

  useEffect(() => {
    if (!canViewPrices) {
      setHiddenCols([...hiddenCols, ...sensitiveValues]);
    }
  }, [canViewPrices]);

  useEffect(() => {
    setId(uuidv4());
  }, []);

  useEffect(() => {
    setFilteredAllCells(allHeaderCells);
  }, [allHeaderCells]);

  useEffect(() => {
    setLocalDHC(displayHeaderCells);
  }, [displayHeaderCells]);

  const setVisibleChange = (visible) => {
    setColSelVisible(visible);
  };

  const reset = () => {
    setLocalDHC(displayHeaderCells);
    setFilteredDisplayCells(displayHeaderCells);
    setFilteredAllCells(allHeaderCells);
    setSearchValue('');
    setHasChanged(false);
    setId(uuidv4());
  };

  const handleVisibilityChange = (visible) => {
    setVisibleChange(visible);
  };

  const apply = () => {
    setSearchValue('');
    setHasChanged(false);
    setDisplayHeaderCells(localDHC);
    setFilteredDisplayCells(localDHC);
    setFilteredAllCells(allHeaderCells);
    setVisibleChange(false);
  };

  const onFilterCheckChange = (e) => {
    const itemIsChecked = e.target.checked;
    const itemId = e.target.id;
    let newDHC = _.clone(localDHC);
    if (itemIsChecked) {
      const newItem = allHeaderCells.find((item) => item.dataIndex === itemId);
      newDHC.push(newItem);
    } else {
      newDHC = localDHC.filter((item) => item.dataIndex !== itemId);
    }
    setLocalDHC(newDHC);
    setHasChanged(true);
  };

  const title = (
    <Row justify="space-between">
      <Col>
        <div className="titleHeader">Columns</div>
      </Col>
      <Col>
        <Tooltip title="Click to sort alphabetically">
          <SortAscendingOutlined onClick={() => setSortOrder(sortOrders.AZ)} className="sortButton" />
        </Tooltip>
        <Tooltip title="Click to sort reverse alphabetically">
          <SortDescendingOutlined onClick={() => setSortOrder(sortOrders.ZA)} className="sortButton" />
        </Tooltip>
      </Col>
    </Row>
  );

  const onSearch = (event) => {
    const val = event.target.value;
    setSearchValue(val);
    if (val?.length > 0) {
      setFilteredDisplayCells(localDHC
        .filter((obj) => obj.name_long.toLocaleUpperCase().indexOf(val.toLocaleUpperCase()) > -1
          || obj.name.toLocaleUpperCase().indexOf(val.toLocaleUpperCase()) > -1));
      setFilteredAllCells(allHeaderCells
        .filter((obj) => obj.name_long.toLocaleUpperCase().indexOf(val.toLocaleUpperCase()) > -1
          || obj.name.toLocaleUpperCase().indexOf(val.toLocaleUpperCase()) > -1));
    } else {
      setFilteredDisplayCells(localDHC);
      setFilteredAllCells(allHeaderCells);
    }
  };

  const overlay = (
    <Menu className="colFilterMenu">
      <Menu.ItemGroup title={title}>
        <Menu.Item key="columnSearchInput">
          <Input
            allowClear
            placeholder="Search for column"
            key="columnSearch"
            value={searchValue}
            onChange={(v) => onSearch(v)}
          />
        </Menu.Item>
        {filteredDisplayCells
          .filter((cell) => !hiddenCols.some(
            (dataIndex) => dataIndex === cell.dataIndex,
          )).map((cell) => (
            <Menu.Item key={id + cell.dataIndex}>
              <Checkbox
                id={cell.dataIndex}
                onChange={onFilterCheckChange}
                defaultChecked
              >
                {cell.name === cell.name_long ? cell.name : `${cell.name} (${cell.name_long})`}
              </Checkbox>
            </Menu.Item>
          ))}
        {filteredAllCells
          .filter((ahc) => !filteredDisplayCells.some((dhc) => ahc.dataIndex === dhc.dataIndex)
            && !hiddenCols.some(
              (dataIndex) => dataIndex === ahc.dataIndex,
            ))
          .sort((a, b) => {
            const aName = a.name_long.toLocaleUpperCase();
            const bName = b.name_long.toLocaleUpperCase();
            if (sortOrder === sortOrders.AZ) {
              return aName < bName ? -1 : 1;
            }
            return aName < bName ? 1 : -1;
          }).map((cell) => (
            <Menu.Item key={cell.dataIndex}>
              <Checkbox
                id={cell.dataIndex}
                onChange={onFilterCheckChange}
                defaultChecked={false}
              >
                {cell.name === cell.name_long ? cell.name : `${cell.name} (${cell.name_long})`}
              </Checkbox>
            </Menu.Item>
          ))}
        {
          hasChanged
            ? (
              <Menu.Item key="applyBtn" className="applyContainer">
                <Button onClick={apply} className="applyButton" type="primary">
                  Apply
                </Button>
              </Menu.Item>
            )
            : <></>
        }
      </Menu.ItemGroup>
    </Menu>
  );

  return (
    materialMaster
      ? (
        <MMColumnSelector
          displayHeaderCells={displayHeaderCells}
          setDisplayHeaderCells={setDisplayHeaderCells}
          allHeaderCells={allHeaderCells}
        />
      )
      : (
        <Dropdown
          overlay={overlay}
          trigger={['click']}
          onVisibleChange={reset} // open/close only by clicking button
          visible={colSelVisible}
        >
          <Button type="primary" className="btnWhiteBG matlist-button" style={{ width: '200px' }} onClick={() => handleVisibilityChange(!colSelVisible)}>
            {colSelVisible
              ? (
                <Row justify="space-between">
                  <Col>
                    <FilterOutlined style={{ paddingRight: '2px' }} />
                    Click here to close
                  </Col>
                  <Col><UpOutlined style={{ fontSize: 12 }} /></Col>
                </Row>
              )
              : (
                <Row justify="space-between">
                  <Col>
                    <FilterOutlined style={{ paddingRight: '2px' }} />
                    Add/remove columns
                  </Col>
                  <Col>
                    <DownOutlined style={{ fontSize: 12 }} />
                  </Col>
                </Row>
              )}
          </Button>
        </Dropdown>
      )
  );
};
export default ColumnSelector;

ColumnSelector.defaultProps = {
  colSelVisible: false,
  canViewPrices: true,
  materialMaster: false,
};

ColumnSelector.propTypes = {
  setDisplayHeaderCells: PropTypes.func.isRequired,
  displayHeaderCells: PropTypes.arrayOf(PropTypes.any).isRequired,
  allHeaderCells: PropTypes.arrayOf(PropTypes.any).isRequired,
  colSelVisible: PropTypes.bool,
  setColSelVisible: PropTypes.func.isRequired,
  canViewPrices: PropTypes.bool,
  materialMaster: PropTypes.bool,
};
