/* eslint-disable quote-props */
export default
{
  'Recommended settings': 'Recommended MRP settings based on your forecasted/expected material data',
  'Material': '',
  'Reference': 'Only applicable if "Catch reference material" is used and displays the reference material number',
  'Delivery time': 'Enter expected delivery days',
  'Supplier location': 'Enter suppliers location from which the material is shipped',
  'Material criticality': 'Enter the highest criticality expected for the material at the plant.\nClick icon to display consequence classification matrix',
  'Max consumed qty': 'Enter the highest quantity expected to be needed for a single work order',
  'Consumption frequency': 'Enter the expected frequency between work orders where the material is required',
  'Price': 'Enter estimated price in NOK (Norwegian kroner)',
};
