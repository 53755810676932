import { Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import allActions from '../../../../actions';
import getColumns from '../CommonResources';
import hasAuthenticatedRole from '../../../Common/AuthFunctions';
import { WORKLIST_VIEW } from '../../../Common/GlobalConstants';

const WorklistTable = ({
  listType, setSelectedItems, displayPersonal, wlRoute, canDeleteWL,
}) => {
  const intialState = {
    searchText: '',
    searchedColumn: '',
  };
  const history = useHistory();
  const dispatch = useDispatch();
  const useDefaultSettings = useSelector((state) => state.commonState?.useDefaultSettings);
  const userSettings = useSelector((state) => state.commonState.userSettings);
  const dataSource = useSelector((state) => state.worklistState.allWorklistItems);
  const user = useSelector((state) => state.authState.user);
  const [accessToPublic, setAccessToPublic] = useState(false);
  const [worklistViewState, setWorklistViewState] = useState(intialState);
  const userState = useSelector((state) => state.authState.user);
  const [retrievedWorklists, setRetrievedWorklists] = useState(false);
  const [data, setData] = useState([]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setWorklistViewState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setWorklistViewState({ searchText: '' });
  };

  const columns = getColumns(worklistViewState, handleSearch, handleReset, 'dynamic', useDefaultSettings, userSettings, listType, displayPersonal);

  useEffect(() => {
    if (user?.idTokenClaims?.roles) {
      const hasAccessToPublicWorklists = hasAuthenticatedRole(
        user.idTokenClaims.roles,
        process.env.REACT_APP_VIEW_PRICES,
      );
      setAccessToPublic(hasAccessToPublicWorklists);
    }
  }, [user?.idTokenClaims]);

  useEffect(() => {
    if (userState?.uniqueId && userState?.account?.username && !retrievedWorklists) {
      dispatch(allActions.WorkListActions.getWorklists(userState.account.username));
      setRetrievedWorklists(true);
    }
  }, [userState]);

  useEffect(() => {
    if (accessToPublic) setData(dataSource?.filter((x) => x.ListType === listType) ?? []);
    else {
      setData(dataSource?.filter(
        (x) => x.ListType === listType && !x.IsWorklistShared
          && x.CreatedUserEmail === userState.account.username,
      ));
    }
  }, [dataSource, accessToPublic]);

  const handleClick = (path, worklistID, e) => {
    if (e.target?.cellIndex !== 0 && e.target && !e.target.cellIndex) return;
    history.push(`/${path}/${worklistID}`);
  };
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedItems(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.CreatedUserEmail !== userState.account?.username
        && record.CreatedBy !== userState.account?.name && !canDeleteWL,
    }),
  };

  const filterData = (wlData, visibility) => {
    if (visibility === WORKLIST_VIEW.PERSONAL) {
      return wlData?.filter((x) => x.CreatedUserEmail === userState.account.username);
    } if (visibility === WORKLIST_VIEW.PUBLIC) {
      return wlData?.filter((x) => x.IsWorklistShared);
    }
    return wlData;
  };

  const tableWorklistProps = {
    footer: () => {
      const relevantData = filterData(data, displayPersonal);
      return (
        <div className="tableRecordCount">
          <span>
            Showing
            {` ${relevantData?.length || 0} `}
            of
            {` ${relevantData?.length || 0} `}
            items
          </span>
        </div>
      );
    },
    scroll: { y: window.innerHeight - 350 },
    pagination: false,
  };

  return (
    <div>
      {dataSource && (
        <Table
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...tableWorklistProps}
          rowClassName={(record, index) => (index % 2 === 0 ? 'table-row-light' : 'table-row-dark')}
          columns={columns}
          dataSource={filterData(data, displayPersonal)}
          loading={!dataSource}
          size="middle"
          rowSelection={displayPersonal === WORKLIST_VIEW.PERSONAL || canDeleteWL ? {
            type: 'checkbox',
            ...rowSelection,
          } : null}
          onRow={(record) => ({
            onClick: (e) => {
              handleClick(wlRoute, record.WorklistID, e);
            },
          })}
        />
      )}
    </div>
  );
};

export default WorklistTable;

WorklistTable.defaultProps = {
  canDeleteWL: false,
};

WorklistTable.propTypes = {
  listType: PropTypes.string.isRequired,
  setSelectedItems: PropTypes.func.isRequired,
  displayPersonal: PropTypes.oneOf([WORKLIST_VIEW.PERSONAL, WORKLIST_VIEW.PUBLIC]).isRequired,
  wlRoute: PropTypes.string.isRequired,
  canDeleteWL: PropTypes.bool,
};
