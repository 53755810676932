import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import { Modal, Input, Checkbox } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import allActions from '../../../../actions';
import hasAuthenticatedRole from '../../../Common/AuthFunctions';

const initialState = {
  worklistId: '',
  worklistName: '',
  worklistDescription: '',
  isWorklistShared: false,
};

const CreateWorklist = ({
  isNewWorklist, closeCreateWorklist, listType, displayHeaderCells, materialViewStore,
}) => {
  const [worklistState, setWorklistState] = useState(initialState);
  const [visible, setVisible] = useState(true);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isSharedWorklistCreator, setIsSharedWorklistCreator] = useState(false);
  const worklistDetails = useSelector((state) => state.worklistState.worklistDetails?.[0]);
  const userState = useSelector((state) => state.authState.user);
  const { TextArea } = Input;
  const dispatch = useDispatch();

  useEffect(() => {
    if (userState?.idTokenClaims?.roles) {
      const userHasAuthenticatedRole = hasAuthenticatedRole(
        userState.idTokenClaims.roles,
        process.env.REACT_APP_SHAREDWORKLISTCREATORROLES,
      );
      setIsSharedWorklistCreator(userHasAuthenticatedRole);
    }
  }, [userState?.idTokenClaims]);

  useEffect(() => {
    if (!isNewWorklist) {
      setWorklistState({
        ...worklistState,
        worklistId: worklistDetails.WorklistID,
        worklistName: worklistDetails.WorklistName,
        worklistDescription: worklistDetails.WorklistDescription,
        isWorklistShared: worklistDetails.IsWorklistShared,

      });
    }
  }, [isNewWorklist]);

  const handleOk = () => {
    setConfirmLoading(true);
    const tempWL = { ...worklistState };
    const regexStr = /^\s+|\s+$/g; // Remove line breaks and spaces at both ends of the string.
    tempWL.worklistName = tempWL.worklistName?.replace(regexStr, '');
    tempWL.worklistDescription = tempWL.worklistDescription?.replace(regexStr, '');
    if (isNewWorklist) {
      dispatch(
        allActions.WorkListActions.createWorklistAction(
          materialViewStore,
          displayHeaderCells,
          tempWL,
          userState,
          listType,
        ),
      );
    } else {
      dispatch(
        allActions.WorkListActions.updateWorklistAction(
          materialViewStore,
          tempWL,
          userState,
          displayHeaderCells,
          listType,
        ),
      );
      dispatch(allActions.WorkListActions.getWorklists(
        materialViewStore,
        tempWL,
        userState,
        displayHeaderCells,
        listType,
      ));
      dispatch(allActions.WorkListActions.nullRowCount(
        worklistState.worklistId, materialViewStore.searchFilters,
      ));
    }
    closeCreateWorklist();
  };
  const handleCancel = () => {
    setVisible(false);
    closeCreateWorklist();
  };
  const onChangeDesc = (e) => {
    setWorklistState({
      ...worklistState,
      worklistDescription: e.target.value,
    });
  };
  const onChangeName = (e) => {
    setWorklistState({
      ...worklistState,
      worklistName: e.target.value,
    });
  };
  const onShareChecked = (e) => {
    setWorklistState({
      ...worklistState,
      isWorklistShared: e.target.checked,
    });
  };
  return (
    <>
      <Modal
        title={isNewWorklist ? 'Create Worklist' : 'Update Worklist'}
        visible={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        style={{ minWidth: '450px' }}
        okButtonProps={{ disabled: worklistState?.worklistName?.length === 0 }}
      >
        <TextArea autoSize placeholder="Worklist Name" value={worklistState.worklistName} onChange={onChangeName} />
        <br />
        <br />
        <TextArea autoSize placeholder="Worklist Description" value={worklistState.worklistDescription} onChange={onChangeDesc} />
        <br />
        <br />
        {isSharedWorklistCreator
        && (
          <Checkbox checked={worklistState.isWorklistShared} onChange={onShareChecked}>
            Shared
          </Checkbox>
        )}
      </Modal>
    </>
  );
};
export default CreateWorklist;

CreateWorklist.defaultProps = {
  displayHeaderCells: [],
};

CreateWorklist.propTypes = {
  isNewWorklist: PropTypes.bool.isRequired,
  closeCreateWorklist: PropTypes.func.isRequired,
  listType: PropTypes.string.isRequired,
  displayHeaderCells: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  materialViewStore: PropTypes.objectOf(PropTypes.any).isRequired,
};
