import { Table } from 'antd';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { generateScenarioReferenceArray, getScenarioColumns } from '../../Common/Simulation/SimulationGenerators';
import allActions from '../../../actions';
import * as loadingKeys from '../../../actions/MaterialDetailsDomain/LoadingKeys';
import { formatMaterialNumber } from '../../Common/DateAndNumberFunctions';

export default function ScenarioResult({ metaData, simulatedData }) {
  const dispatch = useDispatch();
  const currentValues = useSelector((state) => state.materialDetailsState.mrpSettings);
  const recVals = useSelector((state) => state.materialDetailsState.material);
  const [dataSource, setDataSource] = useState();
  const [plant, setPlant] = useState(
    (simulatedData?.[0]?.PLANT_FACILITY_SAP_ID
      ? simulatedData?.[0]?.PLANT_FACILITY_SAP_ID.toString()
      : metaData?.PlantID),
  );
  const columns = getScenarioColumns(plant);

  // retrieve the recommended values by looking up
  useEffect(() => {
    const materialObject = simulatedData.map((item) => ({
      plantID: item.PLANT_FACILITY_SAP_ID.toString(),
      materialID: formatMaterialNumber(item.MATERIAL_TYPE_SAP_ID),
    }));
    const fetchMaterials = materialObject.filter((material) => !recVals?.some(
      (m) => m.PLANT_FACILITY_SAP_ID === material.plantID
        && m.MATERIAL_TYPE_SAP_ID === material.materialID,
    ));
    if (fetchMaterials?.length > 0) {
      dispatch(allActions.MaterialDetailsActions.fetchMaterials(fetchMaterials));
    }
    const plantID = materialObject[0]?.plantID ?? metaData?.PlantID;
    setPlant(plantID);

    // check if current mrp settings from SAP for necessary materials exist in redux
    const fetchSAPCurrent = materialObject.filter((material) => !currentValues?.some(
      (m) => m.Plant === material.plantID
        && formatMaterialNumber(m.MaterialNumber) === material.materialID,
    ));
    const SAPQueryMaterials = [...new Set(fetchSAPCurrent.map(
      (item) => item.materialID,
    ))];
    if (SAPQueryMaterials?.length > 0) {
      dispatch(allActions.MaterialDetailsActions.setLoading(loadingKeys.mrp));
      dispatch(allActions.SapAPIActions.getMRPs([plantID], SAPQueryMaterials));
    }
  }, [simulatedData]);

  useEffect(() => {
    if (recVals?.length > 0 && simulatedData?.length > 0) {
      setDataSource(generateScenarioReferenceArray(
        currentValues,
        recVals,
        simulatedData,
        plant,
      ));
    }
  }, [currentValues, recVals, simulatedData, plant]);

  return (
    <Table dataSource={dataSource} columns={columns} pagination={false} bordered size="small" />
  );
}

ScenarioResult.defaultProps = {
  metaData: {},
};

ScenarioResult.propTypes = {
  metaData: PropTypes.objectOf(PropTypes.any),
  simulatedData: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
};
