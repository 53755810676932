import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Empty } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import _ from 'lodash';
import {
  generateReferenceArray, generateColumns, generateDataSource,
} from './SimulationGenerators';
import allActions from '../../../actions';
import * as loadingKeys from '../../../actions/MaterialDetailsDomain/LoadingKeys';
import { formatMaterialNumber } from '../DateAndNumberFunctions';
import { transformInputObject } from '../../Views/MaterialDetails/components/SummaryComponents/Simulation/SimulationFunctions';

const MaterialSimResult = ({ simData, focusedPlant }) => {
  const mrpData = useSelector((state) => state.materialDetailsState.mrpSettings);
  const materials = useSelector((state) => state.materialDetailsState.material);
  const [columns, setColumns] = useState();
  const [dataSource, setDataSource] = useState();
  const [plants, setPlants] = useState();
  const dispatch = useDispatch();
  const { material } = useParams();

  useEffect(() => {
    if (!_.isEmpty(simData) && plants?.length > 0) {
      const formattedData = transformInputObject(simData);
      setColumns(generateColumns(formattedData));
      const refArray = generateReferenceArray(
        mrpData, materials, formattedData, focusedPlant,
      );
      const focusedSimData = formattedData.find(
        (x) => x.PLANT_FACILITY_SAP_ID === focusedPlant
          && x.MATERIAL_TYPE_SAP_ID.toString() === material,
      );
      const plantToHide = focusedSimData?.DISPLAY_REC_IND === 0 ? focusedPlant : null;
      const generatedDataSource = generateDataSource(refArray, plantToHide);
      setDataSource(generatedDataSource);
    }
  }, [materials, simData, mrpData, plants]);

  useEffect(() => {
    if (!_.isEmpty(simData)) {
      const formattedData = transformInputObject(simData);
      if (formattedData?.length > 0) {
        setPlants(
          formattedData.map((res) => res.PLANT_FACILITY_SAP_ID.toString()),
        );
      }
      const requiredMaterials = formattedData?.map((item) => ({
        plantID: item.PLANT_FACILITY_SAP_ID.toString(),
        materialID: item.MATERIAL_TYPE_SAP_ID.toString(),
      }));
      const fetchMaterials = requiredMaterials.filter((mat) => !materials?.some(
        (m) => m.PLANT_FACILITY_SAP_ID === mat.plantID
          && m.MATERIAL_TYPE_SAP_ID === mat.materialID,
      ));
      if (fetchMaterials?.length > 0) {
        dispatch(allActions.MaterialDetailsActions.fetchMaterials(fetchMaterials));
      }

      // check if current mrp settings from SAP for necessary materials exist in redux
      const fetchSAPCurrent = requiredMaterials.filter((mat) => !mrpData?.some(
        (m) => m.Plant === mat.plantID
          && formatMaterialNumber(m.MaterialNumber) === mat.materialID,
      ));
      const SAPQueryPlants = [...new Set(fetchSAPCurrent.map(
        (item) => item.plantID,
      ))];
      if (SAPQueryPlants?.length > 0) {
        dispatch(allActions.MaterialDetailsActions.setLoading(loadingKeys.mrp));
        dispatch(
          allActions.SapAPIActions.getMRPs(SAPQueryPlants, [fetchSAPCurrent[0].materialID]),
        );
      }
    }
  }, [simData]);

  return dataSource
    ? (
      <Table
        size="small"
        bordered
        pagination={false}
        dataSource={dataSource}
        columns={columns}
      />
    )
    : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
};

export default MaterialSimResult;

MaterialSimResult.defaultProps = {
  simData: {},
  focusedPlant: null,
};

MaterialSimResult.propTypes = {
  simData: PropTypes.objectOf(PropTypes.any),
  focusedPlant: PropTypes.string,
};
