import { formatMaterialNumber } from '../../../../../Common/DateAndNumberFunctions';

// eslint-disable-next-line import/prefer-default-export
export const getDeliveryTimePayloadForSingleMaterial = (plant, relatedPlants, mrpsForRelatedPlants, materialData, material, newData) => {
  const toNdChange = newData.some((object) => object.key === 'MRPType' && object.userDefined === 'ND' && object.current !== 'ND');
  const fromNdChange = newData.some((object) => object.key === 'MRPType' && (object.userDefined === 'V1' || object.userDefined === 'PD') && object.current === 'ND');
  const { supplier } = materialData;
  const deliveryTimePayload = { MrpBatch: '123', to_Mrp: [] };
  if (toNdChange) {
    if (plant === supplier) {
      const onshoreDeliveryTime = mrpsForRelatedPlants.find(
        (mrpSetting) => mrpSetting.Plant === plant
              && formatMaterialNumber(mrpSetting.MaterialNumber) === material,
      )?.PlannedDeliveryTimeInDays;
      if (onshoreDeliveryTime) {
        relatedPlants[plant]?.forEach((relatedPlant) => {
          const materialNumber = formatMaterialNumber(material);
          if (mrpsForRelatedPlants?.some((mrp) => mrp.Plant === relatedPlant.Plant_ID && formatMaterialNumber(mrp.MaterialNumber) === materialNumber)) {
            const changeObject = {
              MaterialNumber: materialNumber,
              Plant: relatedPlant.Plant_ID,
              PlannedDeliveryTimeInDays: onshoreDeliveryTime,
              PlannedDeliveryTimeInDays_X: true,
            };
            deliveryTimePayload.to_Mrp.push(changeObject);
          }
        });
      }
    }
  }

  const allPlants = { [plant]: [...relatedPlants[plant], { Plant_ID: plant }] };

  if (fromNdChange) {
    mrpsForRelatedPlants?.forEach((mrpPlant) => {
      if (mrpPlant.Plant !== supplier) {
        const materialNumber = formatMaterialNumber(material);
        if (allPlants[plant]?.some((relPlant) => relPlant.Plant_ID === mrpPlant.Plant && formatMaterialNumber(mrpPlant.MaterialNumber) === materialNumber)) {
          if (!mrpPlant.SpecialProcurementType || mrpPlant.SpecialProcurementType === '') {
            const changeObject = {
              MaterialNumber: materialNumber,
              Plant: mrpPlant.Plant,
              SpecialProcurementType_X: true,
              SpecialProcurementType: '40',
            };
            deliveryTimePayload.to_Mrp.push(changeObject);
          }
        }
      }
    });
  }
  return deliveryTimePayload;
};
