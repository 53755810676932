/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import allActions from '../../../actions';
import * as MaterialListActions from '../../../actions/MaterialListDomain/MaterialListActionTypes';
import * as MaterialDetailsAction from '../../../actions/MaterialDetailsDomain/ActionTypes';
import * as CommonActions from '../../../actions/common/ActionTypes';
import * as DashboardActionTypes from '../../../actions/DashboardDomain/ActionTypes';
import * as SAPAPIActionTypes from '../../../actions/SapAPIDomain/ActionTypes';
import * as SimulationActionTypes from '../../../actions/SimulationDomain/ActionTypes';
import * as WorklistActionTypes from '../../../actions/WorkListDomain/WorkListActionTypes';
import * as args from './endpointArgs';
import { listModes, SIM_TYPES } from '../../Common/GlobalConstants';
import { widgetTypeKeys } from '../Dashboard/Widgets/widgetTypes';

const materialListObjects = [
  {
    label: 'fetchMaterialList',
    endpoint: allActions.MaterialListActions.fetchMaterialList(args.materialViewState, args.newSearchID),
    action: MaterialListActions.receiveMateriallist,
  },
  {
    label: 'fetchMaterialListCount',
    endpoint: allActions.MaterialListActions.fetchMaterialListCount(args.materialViewState, args.newSearchID),
    action: MaterialListActions.fetchMaterialListCount,
  },
  {
    label: 'fetchMaterialColumns',
    endpoint: allActions.MaterialListActions.fetchMaterialColumns(),
    action: MaterialListActions.receiveMaterialListColumns,
  },
  {
    label: 'setUserView',
    endpoint: allActions.MaterialListActions.setUserView(args.userID, args.viewName, args.viewSetup, args.userEmail),
    action: MaterialListActions.storeUserViews,
  },
  {
    label: 'getUserViews',
    endpoint: allActions.MaterialListActions.getUserViews(args.userID),
    action: MaterialListActions.storeUserViews,
  },
  {
    label: 'deleteUserView',
    endpoint: allActions.MaterialListActions.deleteUserView(args.userID, args.viewName),
    action: MaterialListActions.deleteUserView,
  },
  {
    label: 'getDropdownValues',
    endpoint: allActions.MaterialListActions.getDropdownValues(),
    action: MaterialListActions.getDropdownValues,
  },
  // {
  //   label: 'generateCSV',
  //   endpoint: allActions.MaterialListActions.generateCSV(args.materialViewState.searchFilters, args.selectedCols, args.exportType, args.typeOfWorklist, null),
  //   action: MaterialListActions.setCSVID,
  // },
  // {
  //   label: 'getCSV',
  //   endpoint: allActions.MaterialListActions.getCSV(args.jobID),
  //   action: MaterialListActions.getCSV,
  // },
];

const materialDetailsObjects = [
  {
    label: 'fetchMaterial',
    endpoint: allActions.MaterialDetailsActions.fetchMaterial(args.plant, args.material),
    action: MaterialDetailsAction.getMaterial,

  }, {
    label: 'getMaterials',
    endpoint: allActions.MaterialDetailsActions.fetchMaterials(args.plantMaterials),
    action: MaterialDetailsAction.getMaterials,
  }, {
    label: 'fetchAdditionalMaterial',
    endpoint: allActions.MaterialDetailsActions.fetchAdditionalMaterial(args.plant, args.material),
    action: MaterialDetailsAction.getAdditionalMaterial,
  }, {
    label: 'fetchConsumptionHistory',
    endpoint: allActions.MaterialDetailsActions.fetchConsumptionHistory(args.plant, args.material),
    action: MaterialDetailsAction.getConsumptionHistory,
  }, {
    label: 'fetchReservations',
    endpoint: allActions.MaterialDetailsActions.fetchReservations(args.plant, args.material),
    action: MaterialDetailsAction.getReservations,
  }, {
    label: 'fetchMaterialMovement',
    endpoint: allActions.MaterialDetailsActions.fetchMaterialMovement(args.plant, args.material),
    action: MaterialDetailsAction.getMaterialMovement,
  }, {
    label: 'fetchMaterialLeadTime',
    endpoint: allActions.MaterialDetailsActions.fetchMaterialLeadTime(args.plant, args.material),
    action: MaterialDetailsAction.getMaterialLeadTime,
  }, {
    label: 'fetchXPlant',
    endpoint: allActions.MaterialDetailsActions.fetchXPlant(args.plant, args.material),
    action: MaterialDetailsAction.getXPlant,
  }, {
    label: 'fetchReturnDocument',
    endpoint: allActions.MaterialDetailsActions.fetchReturnDocument(args.plant, args.material),
    action: MaterialDetailsAction.returnDocument,
  }, {
    label: 'fetchChangelogs',
    endpoint: allActions.MaterialDetailsActions.fetchChangelogs(args.plant, args.material),
    action: MaterialDetailsAction.getChangelogs,
  }, {
    label: 'createChangelog',
    endpoint: allActions.MaterialDetailsActions.createChangelog(args.plant, args.material, args.payload),
    action: MaterialDetailsAction.createChangelog,
  }, {
    label: 'getMaterialChangelogs',
    endpoint: allActions.MaterialDetailsActions.fetchMaterialChangelogs(args.plant, args.material),
    action: MaterialDetailsAction.getMaterialChangelogs,
  }, {
    label: 'getFunctionalLocation',
    endpoint: allActions.MaterialDetailsActions.fetchFunctionalLocation(args.plant, args.material),
    action: MaterialDetailsAction.getFunctionalLocation,
  }, {
    label: 'getWarehouse',
    endpoint: allActions.MaterialDetailsActions.fetchWarehouse(args.plant, args.material),
    action: MaterialDetailsAction.getWarehouse,
  }, {
    label: 'getPurchasing',
    endpoint: allActions.MaterialDetailsActions.fetchPurchasing(args.plant, args.material),
    action: MaterialDetailsAction.getPurchasing,
  }, {
    label: 'getPlantRelationships',
    endpoint: allActions.MaterialDetailsActions.fetchPlantRelationships(args.plant),
    action: MaterialDetailsAction.getPlantRelationships,
  }, {
    label: 'storeLongText',
    endpoint: allActions.MaterialDetailsActions.getLongText(args.plant, args.material),
    action: MaterialDetailsAction.storeLongText,
  }, {
    label: 'getClassification',
    endpoint: allActions.MaterialDetailsActions.getClassification(args.material),
    action: MaterialDetailsAction.getClassification,
  }, {
    label: 'postpone',
    endpoint: allActions.MaterialDetailsActions.postpone(args.plant, args.material, args.date, args.userEmail, args.userID),
    action: MaterialDetailsAction.postpone,
  }, {
    label: 'inspect',
    endpoint: allActions.MaterialDetailsActions.inspect(args.plant, args.material, args.date, args.userEmail, args.userID),
    action: MaterialDetailsAction.inspect,
  }, {
    label: 'clearPostponement',
    endpoint: allActions.MaterialDetailsActions.clearPostponement(args.plant, args.material),
    action: MaterialDetailsAction.clearPostponement,
  }, {
    label: 'clearInspection',
    endpoint: allActions.MaterialDetailsActions.clearInspection(args.plant, args.material),
    action: MaterialDetailsAction.clearInspection,
  }, {
    label: 'review',
    endpoint: allActions.MaterialDetailsActions.review(args.plant, args.material, args.userEmail, args.date),
    action: MaterialDetailsAction.review,
  }, {
    label: 'clearReview',
    endpoint: allActions.MaterialDetailsActions.clearReview(args.plant, args.material),
    action: MaterialDetailsAction.clearReview,
  }, {
    label: 'storeIOTAComments',
    endpoint: allActions.MaterialDetailsActions.getComments(args.plant, args.material, args.commentType),
    action: MaterialDetailsAction.storeIOTAComments,
  }, {
    label: 'addComment',
    endpoint: allActions.MaterialDetailsActions.addComment(args.userID, args.userEmail, args.userName, args.plant, args.material, args.content, args.commentType),
    action: MaterialDetailsAction.addComment,
  }, {
    label: 'deleteComment',
    endpoint: allActions.MaterialDetailsActions.deleteComment(args.commentId, args.plant, args.material, args.commentType),
    action: MaterialDetailsAction.deleteComment,
  }, {
    label: 'adminDeleteComment',
    endpoint: allActions.MaterialDetailsActions.adminDeleteComment(args.commentId, args.userID, args.plant, args.material, args.commentType),
    action: MaterialDetailsAction.adminDeleteComment,
  }, {
    label: 'delegate',
    endpoint: allActions.MaterialDetailsActions.delegate(args.plant, args.material, args.userEmail, ''),
    action: MaterialDetailsAction.delegate,
  },
];

const commonObjects = [{
  label: 'getPlants',
  endpoint: allActions.CommonActions.getPlants(),
  action: CommonActions.getPlants,

}, {
  label: 'companyCodes',
  endpoint: allActions.CommonActions.getCompanyCodes(),
  action: CommonActions.getCompanyCodes,

}, {
  label: 'getAllUserSettings',
  endpoint: allActions.CommonActions.getAllUserSettings(args.userID),
  action: CommonActions.storeUserSettings,

}, {
  label: 'getCurrencyList',
  endpoint: allActions.CommonActions.getCurrencyList(),
  action: CommonActions.storeCurrencyList,

}, {
  label: 'setUserSetting',
  endpoint: allActions.CommonActions.setUserSetting(args.userID, args.settingType, args.settingValue),
  action: CommonActions.setUserSetting,

}, {
  label: 'getColumnMapper',
  endpoint: allActions.CommonActions.getColumnMapper(),
  action: CommonActions.getColumnMapper,
}, {
  label: 'checkNotifications',
  endpoint: allActions.CommonActions.checkNotifications(args.userID, args.notificationTypes),
  action: CommonActions.setNotificationCount,
}, {
  label: 'getNotifications',
  endpoint: allActions.CommonActions.getNotifications(args.userID, args.notificationTypes, args.numberOfRowsnull, args.offsetnull, args.unacceptedOnlynull),
  action: CommonActions.storeNotifications,
}, {
  label: 'getAdditionalNotifications',
  endpoint: allActions.CommonActions.getAdditionalNotifications(args.userID, args.notificationTypes, args.numberOfRowsnull, args.offsetnull, args.unacceptedOnlynull),
  action: CommonActions.storeAdditionalNotifications,
}, {
  label: 'acceptNotification',
  endpoint: allActions.CommonActions.acceptNotification(args.notificationID, args.userID, args.notificationTypes),
  action: CommonActions.acceptNotification,
}, {
  label: 'markNotifAsViewed',
  endpoint: allActions.CommonActions.markNotifAsViewed(args.notificationID, args.userID, args.notificationTypes),
  action: CommonActions.markNotifAsViewed,
}, {
  label: 'acceptAllNotifications',
  endpoint: allActions.CommonActions.acceptAllNotifications(args.userID, args.notificationTypes),
  action: CommonActions.acceptAllNotifications,
}, {
  label: 'getConfigTable',
  endpoint: allActions.CommonActions.getConfigTable(),
  action: CommonActions.storeConfigTable,
}, {
  label: 'getPlantGroups',
  endpoint: allActions.CommonActions.getPlantGroups(),
  action: CommonActions.storePlantGroups,
}, {
  label: 'createPlantGroup',
  endpoint: allActions.CommonActions.createPlantGroup(args.plantGroupData),
  action: CommonActions.createPlantGroup,
}, {
  label: 'updatePlantGroupName',
  endpoint: allActions.CommonActions.updatePlantGroupName('Delete after testing 2', args.groupID),
  action: CommonActions.updatePlantGroupName,
}, {
  label: 'deletePlantGroup',
  endpoint: allActions.CommonActions.deletePlantGroup(args.groupID),
  action: CommonActions.deletePlantGroup,
},
{
  label: 'submitImpactForm',
  endpoint: allActions.CommonActions.submitImpactForm(args.plant, args.material, args.impact, args.impactType, args.impactNotes, args.userEmail),
  action: CommonActions.submitImpactForm,
}];

const dashboardObjects = [
  {
    label: widgetTypeKeys.optimalInventoryByStrategy,
    endpoint: allActions.DashboardActions.optimalInventoryByStrategy(args.plants, args.companyCodes, args.mode),
    action: DashboardActionTypes.optimalInventoryByStrategy,
  },
  {
    label: widgetTypeKeys.optimalInventoryByGroup,
    endpoint: allActions.DashboardActions.optimalInventoryByGroup(args.plants, args.companyCodes, args.mode),
    action: DashboardActionTypes.optimalInventoryByGroup,
  },
  {
    label: widgetTypeKeys.getExcessStockByStatus,
    endpoint: allActions.DashboardActions.getExcessStockByStatus(args.plants, args.companyCodes, args.mode),
    action: DashboardActionTypes.excessStockByStatus,
  },
  {
    label: widgetTypeKeys.inventoryValue,
    endpoint: allActions.DashboardActions.inventoryValue(args.plants, args.companyCodes, args.mode, []),
    action: DashboardActionTypes.inventoryValue,
  },
  {
    label: widgetTypeKeys.getTotalExcessStock,
    endpoint: allActions.DashboardActions.getTotalExcessStock(args.plants, args.companyCodes, args.mode),
    action: DashboardActionTypes.totalExcessStock,
  },
  {
    label: widgetTypeKeys.getMonetaryImpactVals,
    endpoint: allActions.DashboardActions.getMonetaryImpactVals(args.plants, args.companyCodes, args.mode, []),
    action: DashboardActionTypes.monetaryImpact,
  },
  {
    label: widgetTypeKeys.getOptInvTot,
    endpoint: allActions.DashboardActions.getOptInvTot(args.plants, args.companyCodes, args.mode),
    action: DashboardActionTypes.storeOptInvTot,
  },
  {
    label: widgetTypeKeys.optimalInventoryBySP,
    endpoint: allActions.DashboardActions.optimalInventoryBySP(args.plants, args.companyCodes, args.mode),
    action: DashboardActionTypes.optimalInventoryBySP,
  },
  {
    label: widgetTypeKeys.optimalInventoryByCrit,
    endpoint: allActions.DashboardActions.optimalInventoryByCrit(args.plants, args.companyCodes, args.mode),
    action: DashboardActionTypes.optimalInventoryByCrit,
  },
  {
    label: widgetTypeKeys.plannedOrders,
    endpoint: allActions.DashboardActions.plannedOrders(args.plants, args.companyCodes, args.mode),
    action: DashboardActionTypes.plannedOrders,
  }];

const sapObjects = [
  {
    label: 'getMRP',
    endpoint: allActions.SapAPIActions.getMRP(args.plant, args.material),
    action: SAPAPIActionTypes.getMRP,
  },

  {
    label: 'getMRPs',
    endpoint: allActions.SapAPIActions.getMRPs(args.plants, args.materials),
    action: SAPAPIActionTypes.getMRPs,
  },
];

const simulationObjects = [
  {
    label: 'retrieveSimulation',
    endpoint: allActions.SimulationActions.retrieveSimulation(args.simulationID),
    action: SimulationActionTypes.retrieveSimulation,
  },
  {
    label: 'simulate',
    endpoint: allActions.SimulationActions.simulate(args.plant, [args.material], SIM_TYPES.SINGLE_MATERIAL, args.userEmail, 'args.type', args.userID, {}),
    action: SimulationActionTypes.simulate,
  },
  {
    label: 'simulateMultiple',
    endpoint: allActions.SimulationActions.simulate(args.plant, args.materials, {}, args.userEmail, SIM_TYPES.SCENARIO, args.userID, {}),
    action: SimulationActionTypes.simulateMultiple,
  },
  {
    label: 'getAllNewStockForms',
    endpoint: allActions.SimulationActions.getAllNewStockForms(args.userID),
    action: SimulationActionTypes.getAllNewStockForms,
  },
  {
    label: 'saveNewStockForm',
    endpoint: allActions.SimulationActions.saveNewStockForm(args.formObj, args.successMsg, args.errMsg),
    action: SimulationActionTypes.saveNewStock,
  },
  {
    label: 'deleteNewStockForm',
    endpoint: allActions.SimulationActions.deleteNewStockForm(args.userID, args.formId, args.formName),
    action: SimulationActionTypes.deleteNewStock,
  },
  {
    label: 'storeNewStockSessionForm',
    endpoint: allActions.SimulationActions.storeNewStockSessionForm(args.formdata, args.newStockSessionId),
    action: SimulationActionTypes.storeNewStock,
  },
  {
    label: 'getNewStockResults',
    endpoint: allActions.SimulationActions.getNewStockResults(args.runID),
    action: SimulationActionTypes.getNewStockResults,
  },
  {
    label: 'getAllSimJobs',
    endpoint: allActions.SimulationActions.getAllSimJobs(args.userEmail),
    action: SimulationActionTypes.getAllSimJobs,
  },
  {
    label: 'deleteSim',
    endpoint: allActions.SimulationActions.deleteSim([[12]], args.userEmail),
    action: SimulationActionTypes.deleteSimulation,
  },
];

const worklistObjects = [
  {
    label: 'createWorklistAction',
    endpoint: allActions.WorkListActions.createWorklistAction(args.materialViewState, args.displayHeaderCells, args.worklistState, args.userState),
    action: WorklistActionTypes.createWorklistStore,
  },
  {
    label: 'fetchWorklistDetails',
    endpoint: allActions.WorkListActions.fetchWorklistDetails(args.worklistID),
    action: WorklistActionTypes.receiveWorklistDetails,
  },
  {
    label: 'updateWorklistAction',
    endpoint: allActions.WorkListActions.updateWorklistAction(args.materialViewState, args.worklistState, args.userState, args.displayHeaderCells),
    action: WorklistActionTypes.createWorklistStore,
  },
  {
    label: 'getWorklists',
    endpoint: allActions.WorkListActions.getWorklists(args.userEmail),
    action: WorklistActionTypes.receiveWorklists,
  },
  {
    label: 'fetchDynamicWLCount',
    endpoint: allActions.WorkListActions.fetchDynamicWLCount(JSON.stringify(args.materialViewState.searchFilters), args.selectedWorklistItems[0].WorklistID, args.selectedWorklistItems[0].WorklistName),
    action: WorklistActionTypes.setDynamicWLCount,
  },
  {
    label: 'deleteWorklists',
    endpoint: allActions.WorkListActions.deleteWorklists(args.selectedWorklistItems, args.userEmail),
    action: WorklistActionTypes.deleteWorklistStore,
  },
  {
    label: 'getStaticWLs',
    endpoint: allActions.WorkListActions.getStaticWLs(args.userID),
    action: WorklistActionTypes.storeStaticWLs,
  },
  {
    label: 'getRelevantStaticWLs',
    endpoint: allActions.WorkListActions.getRelevantStaticWLs(args.userID, args.plant, args.material),
    action: WorklistActionTypes.storeRelevantStaticWLs,
  },
  {
    label: 'createStaticWL',
    endpoint: allActions.WorkListActions.createStaticWL(args.staticWLmetadata, [], args.worklistItems, listModes.matList),
    action: WorklistActionTypes.createStaticWL,
  },
  {
    label: 'addToStaticWL',
    endpoint: allActions.WorkListActions.addToStaticWL(args.metadata, args.worklistItems, listModes.matList),
    action: WorklistActionTypes.addToStaticWL,
  },
  {
    label: 'allMaterialStaticWL',
    endpoint: allActions.WorkListActions.allMaterialStaticWL(args.metadataAll, {}, args.materialViewState),
    action: WorklistActionTypes.allMaterialStaticWL,
  },
  {
    label: 'removeFromStaticWL',
    endpoint: allActions.WorkListActions.removeFromStaticWL(args.metadata, args.worklistItems),
    action: WorklistActionTypes.removeFromStaticWL,
  },
  {
    label: 'getStaticWL',
    endpoint: allActions.WorkListActions.getStaticWL(args.worklistID, args.selectedWorklistItems[0].SortColumn, args.selectedWorklistItems[0].SortDirection, args.noOfRows, args.offset),
    action: WorklistActionTypes.storeStaticWLs,
  },
  {
    label: 'deleteStaticWLs',
    endpoint: allActions.WorkListActions.deleteStaticWLs(args.worklists, args.userID),
    action: WorklistActionTypes.deleteStaticWLs,
  },
  {
    label: 'setStaticWLView',
    endpoint: allActions.WorkListActions.setStaticWLView(args.worklistID, args.userID, args.selectedCols),
    action: WorklistActionTypes.setStaticWLView,
  },
];

export {
  materialListObjects,
  materialDetailsObjects,
  commonObjects,
  dashboardObjects,
  sapObjects,
  simulationObjects,
  worklistObjects,
};
