import React from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox, Form, Input, InputNumber, Select,
} from 'antd';
import './ImpactForm.css';
import DraggableModal from '../Common/DraggableModal';

const { TextArea } = Input;
const { Option } = Select;

function ImpactForm({
  visible, onOk, onCancel,
}) {
  const [form] = Form.useForm();
  const modalTitle = <div className="handle">Register Form</div>;

  const validateInput = async (_, value) => {
    const pattern = /^(?!\d*[.,]{2})\d*([.,])?\d*$/;
    if (!pattern.test(value)) {
      return Promise.reject(new Error('Invalid number'));
    }
    return Promise.resolve();
  };

  return (
    <DraggableModal
      handle=".handle"
      title={modalTitle}
      visible={visible}
      onOk={() => onOk(form.getFieldsValue())}
      onCancel={onCancel}
      okText="Submit"
      cancelText="Cancel"
      className="impactForm"
      content={(
        <Form
          form={form}
          layout="vertical"
        >
          <Form.Item label="Plant" name="plant">
            <InputNumber min={0} style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item label="Material" name="material">
            <InputNumber min={0} style={{ width: '100%' }} />
          </Form.Item>

          {/*  I don't trust this implementation, will return
          <Form.Item rules={[{ validator: validateInput }]}
          label="Impact (NOK)" name="impact"> */}
          <Form.Item label="Impact (NOK)" name="impact">
            <Input style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item label="Impact type" name="impactType">
            <Select style={{ width: '100%' }}>
              <Option value="Savings">Savings</Option>
              <Option value="Investment">Investment</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Notes" name="notes">
            <TextArea style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            name="overstock"
            label="Overstock"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
        </Form>
      )}
    />
  );
}

export default ImpactForm;

ImpactForm.propTypes = {
  visible: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
