import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Empty, Table, Button, Card, Checkbox, Col, Row, Select, Space, Spin, Tooltip, Input, Tag,
} from 'antd';
import { CloseOutlined, ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import _ from 'lodash';
import allActions from '../../../actions';
import * as loadingKeys from '../../../actions/MaterialDetailsDomain/LoadingKeys';
import MaterialSelector from '../../Common/MaterialSelector';
import {
  getCurTabText, isCommentType, massUpdate, requestBuilder,
} from './massUpdateGlobals';
import ConfirmModal from '../../Common/ConfirmModal';
import MemoConfirm from './MemoConfirm';
import RevertConfirm from './RevertConfirm';
import './massUpdate.css';
import { isInteger } from '../../Common/DateAndNumberFunctions';
import { updateSelectedMaterials } from './utils';

const MassUpdate = ({ currentTab, matIds }) => {
  if (currentTab === massUpdate.MRP) return null;
  const { Option } = Select;
  const dispatch = useDispatch();
  const { TextArea } = Input;

  const user = useSelector((state) => state.authState.user);
  const mrpData = useSelector((state) => state.materialDetailsState.mrpSettings);
  const loading = useSelector((state) => state.materialDetailsState.loading);
  const allPlants = useSelector((state) => state.commonState.plants);
  const materialDescriptions = useSelector(
    (state) => state.materialDetailsState.materialDescriptions,
  );

  const [plantList, setPlantList] = useState([]);
  const [plant, setPlant] = useState();
  const [materials, setMaterials] = useState([]);
  const [excludeList, setExcludeList] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [showTable, setShowTable] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showRevertModal, setShowRevertModal] = useState(false);
  const [validExists, setValidExists] = useState(true);

  const [currentTextList, setCurrentTextList] = useState({});
  const [newTextUpdates, setNewTextUpdates] = useState({});
  const [changesMade, setChangesMade] = useState(false);
  const [error, setError] = useState(false);

  const [revert, setRevert] = useState();
  const delimiter = '-';

  const writeTypes = {
    BEGINNING: 'Beginning',
    END: 'End',
    REPLACE: 'Replace',
  };

  const getDataSource = (items) => items.map((x) => ({ key: x, material: x }));

  const getPlantName = (plantObj) => `${plantObj.PLANT_FACILITY_SAP_ID} - ${plantObj.PLANT_FACILITY_SAP_NM}`;

  const setTextObjects = () => {
    const textObj = {};
    materialDescriptions?.forEach((m) => {
      // eslint-disable-next-line prefer-template
      const key = plant + '-' + m.MATERIAL_TYPE_SAP_ID; // Disabled ESlint as `${}` method giving runtime eslint error
      if (currentTab === massUpdate.WAREHOUSE_MEMO) {
        textObj[key] = m.warehouseComment;
      } else if (currentTab === massUpdate.IOTA_MEMO) {
        textObj[key] = m.iotaComment;
      } else {
        textObj[key] = '';
      }
    });
    setCurrentTextList(textObj);
    setNewTextUpdates(textObj);
  };

  useEffect(() => {
    if (matIds?.ids?.length) {
      if (matIds.plant) {
        setPlant(matIds.plant.toString());
      } else {
        setPlant(null);
      }
      updateSelectedMaterials(matIds.ids, setMaterials, dispatch);
    } else {
      setMaterials([]);
    }
    setInputValue('');
  }, [currentTab]);

  useEffect(() => {
    setPlantList(allPlants?.sort((a, b) => a.PLANT_FACILITY_SAP_ID - b.PLANT_FACILITY_SAP_ID));
  }, [allPlants]);

  useEffect(() => {
    if (mrpData) {
      const textObj = {};
      let textID;
      if (isCommentType(currentTab)) return;
      if (massUpdate.MEMO === currentTab) textID = 'LTXT';
      else if (massUpdate.PLANNED_ORDER) textID = 'Z901';
      mrpData.forEach((d) => {
        const key = `${Number.parseInt(d.Plant, 10).toString()}-${Number.parseInt(d.MaterialNumber, 10).toString()}`;
        textObj[key] = d.to_MrpLongText?.results?.find((t) => t.TextId === textID)?.LongText;
      });
      setCurrentTextList(textObj);
      setNewTextUpdates(textObj);
    }
  }, [mrpData, currentTab]);

  const getExcludeList = (exclude = []) => {
    const matList = {};
    materialDescriptions?.forEach((x) => {
      if (x) {
        const val = x.PLANT_FACILITY_SAP_ID + delimiter + x.MATERIAL_TYPE_SAP_ID;
        matList[val] = true;
      }
    });
    const tempExclude = [...exclude];
    materials?.forEach((x) => {
      if (!matList[plant + delimiter + x] && !tempExclude.includes(x)) {
        tempExclude.push(plant + delimiter + x);
      }
    });
    return tempExclude;
  };

  useEffect(() => {
    if (materialDescriptions) {
      setExcludeList([...getExcludeList(excludeList)]);
      setValidExists(materialDescriptions.length !== 0);
      if (isCommentType(currentTab)) {
        setTextObjects();
      }
    } else {
      setValidExists(false);
    }
  }, [materialDescriptions]);

  useEffect(() => {
    setChangesMade(!_.isEqual(newTextUpdates, currentTextList));
  }, [newTextUpdates, currentTextList]);

  const updateMaterialText = (e, key) => {
    if (plant) {
      const newKey = `${plant}-${key}`;
      const tempObject = { ...newTextUpdates };
      tempObject[newKey] = e;
      setNewTextUpdates(tempObject);
    }
  };

  const onInputValueChange = (e) => {
    setInputValue(e.currentTarget.value);
  };

  const handleExclude = (isChecked, key) => {
    if (isChecked) {
      const filteredList = excludeList.filter((i) => i !== key);
      setExcludeList(filteredList);
    } else {
      setExcludeList([...excludeList, key]);
    }
  };

  const configColumns = (exclude) => [
    {
      title: 'Plant',
      dataIndex: 'plant',
      render: () => plant,
      width: '7%',
    },
    {
      title: 'Material',
      dataIndex: 'material',
      width: '8%',
    },
    {
      title: 'Material Description',
      dataIndex: 'materialDescription',
      width: '35%',
      render: (_v, r) => {
        const material = materialDescriptions?.find((m) => m.PLANT_FACILITY_SAP_ID === plant
          && m.MATERIAL_TYPE_SAP_ID === r.material);
        if (excludeList?.includes(plant + delimiter + r.material) && !material) {
          return (
            <Tooltip title="Material does not exist">
              <CloseOutlined style={{ color: 'red' }} />
            </Tooltip>
          );
        }
        if (!material) return <LoadingOutlined spin />;
        const description = material?.EDAM_MATERIAL_TYPE_NM;
        return <Tooltip title={description}>{description}</Tooltip>;
      },
    },
    {
      title: getCurTabText(currentTab),
      dataIndex: 'materialText',
      render: (_v, r) => {
        if (!plant || !(materials?.length > 0)) return '';
        const lookup = `${plant}-${r?.material}`;
        return loading?.[loadingKeys?.mrp] && !isCommentType(currentTab) ? (
          <Spin
            indicator={<LoadingOutlined spin />}
            style={{ textAlign: 'center' }}
          />
        ) : (
          <TextArea
            autoSize
            value={newTextUpdates?.[lookup]}
            onChange={(e) => updateMaterialText(e.currentTarget.value, r.key)}
          />
        );
      },
    },
    {
      title: 'Include',
      dataIndex: 'include',
      width: '5%',
      render: (_v, r) => {
        const material = materialDescriptions?.find((m) => m.PLANT_FACILITY_SAP_ID === plant
          && m.MATERIAL_TYPE_SAP_ID === r.material);
        return (
          <Tooltip title={material ? null : 'Material does not exist'}>
            <Checkbox
              defaultChecked={!!material}
              checked={!exclude?.includes(plant + delimiter + r.material)}
              disabled={!material}
              onChange={(e) => {
                handleExclude(e.target.checked, `${plant}-${r.material}`);
              }}
            />
          </Tooltip>
        );
      },
    },
  ];

  const onOK = () => {
    setRevert(null);
    if (!plant || !(materials?.length > 0)) return;
    setShowTable(true);
    const plantMaterials = materials.map((m) => ({
      plantID: plant,
      materialID: m,
    }));
    dispatch(allActions.MaterialDetailsActions.fetchMaterialDescriptions(plantMaterials));
    if (!isCommentType(currentTab)) {
      dispatch(allActions.MaterialDetailsActions.setLoading(loadingKeys.mrp));
      dispatch(allActions.SapAPIActions.getMRPs([plant], materials));
    }
  };

  const reset = () => {
    setChangesMade(false);
    setNewTextUpdates(currentTextList);
    setExcludeList([...getExcludeList()]);
  };

  const massInput = (writeType) => {
    if (plant && materials.length > 0) {
      const tempNewTextUpdates = { ...newTextUpdates };
      const newTextKeys = Object.keys(tempNewTextUpdates).filter(
        (item) => !excludeList.includes(item),
      );
      const space = inputValue?.substring(inputValue?.length - 1) === '\n' ? '' : ' ';
      const removeTrailingNewLines = (t) => t.replace(/\n+$/, '');
      const removeLeadingNewLines = (t) => t.replace(/^\n+/, '');
      if (writeType === writeTypes.BEGINNING) {
        newTextKeys?.forEach((textKey) => {
          if (tempNewTextUpdates?.[textKey]?.length === 0) {
            tempNewTextUpdates[textKey] = removeTrailingNewLines(inputValue);
          } else {
            tempNewTextUpdates[textKey] = inputValue + space + (tempNewTextUpdates[textKey] || '');
          }
        });
      } else if (writeType === writeTypes.END) {
        newTextKeys.forEach((textKey) => {
          if (tempNewTextUpdates?.[textKey]?.length === 0) {
            tempNewTextUpdates[textKey] = removeLeadingNewLines(inputValue);
          } else {
            tempNewTextUpdates[textKey] = (tempNewTextUpdates[textKey] || '') + space + inputValue;
          }
        });
      } else if (writeType === writeTypes.REPLACE) {
        newTextKeys.forEach((textKey) => {
          tempNewTextUpdates[textKey] = inputValue;
        });
      }
      setNewTextUpdates(tempNewTextUpdates);
    }
  };

  const resetTextList = () => {
    setShowTable(false);
    setCurrentTextList({});
    setNewTextUpdates({});
  };

  const setSearchMMR = (values) => {
    const removeLeadingZeroes = (input) => {
      let inputStr = input.toString();
      inputStr = inputStr.replace(/^0+/, '');
      return inputStr;
    };
    const cleanValues = values
      .map((v) => removeLeadingZeroes(v))
      .map((v) => v.replaceAll('\t', '').replaceAll('\n', ''))
      .map((v) => v.trim());
    const hasInvalidInput = cleanValues.some((e) => !(isInteger(e)));
    setError(hasInvalidInput);
    const newMMR = hasInvalidInput
      ? cleanValues.sort((a) => (!isInteger(a) ? -1 : 0))
      : cleanValues;
    setMaterials(newMMR);
    resetTextList();
  };

  const tagRender = ({
    label, value, closable,
  }) => {
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    const onClose = (e) => {
      e.preventDefault();
      setSearchMMR(materials.filter((x) => x !== value));
    };
    const valid = isInteger(String(value).trim());
    return (
      <Tooltip title={valid ? '' : 'Only numbers are valid inputs'}>
        <Tag
          key={value}
          color={valid ? '' : 'red'}
          icon={valid ? '' : <ExclamationCircleOutlined />}
          onMouseDown={onPreventMouseDown}
          closable={closable}
          onClose={onClose}
          style={{ fontSize: '16px' }}
        >
          {label}
        </Tag>
      </Tooltip>
    );
  };

  const updatePlant = (p) => {
    setPlant(p);
    resetTextList();
  };

  const previewChanges = () => {
    setShowModal(true);
  };

  const previewRevertChanges = () => {
    setShowRevertModal(true);
  };

  const submit = (request, reverting = false) => {
    if (request?.to_LongText.length === 0) return;
    dispatch(allActions.MaterialDetailsActions.setLoading(loadingKeys.mrp));
    const requestJSON = JSON.stringify(request);
    let textID = '';
    setCurrentTextList({});
    setNewTextUpdates({});
    if (isCommentType(currentTab)) setTextObjects();
    if (reverting) setRevert(null);
    if (massUpdate.MEMO === currentTab) {
      textID = 'LTXT';
    } else if (massUpdate.PLANNED_ORDER === currentTab) textID = 'Z901';
    delete request.previousTexts;
    switch (currentTab) {
      case massUpdate.MEMO:
        dispatch(allActions.SapAPIActions.addMaterialMemos(
          request, requestJSON, user?.uniqueId, user?.account?.username, plant, materials, textID,
        ));
        break;
      case massUpdate.PLANNED_ORDER:
        dispatch(allActions.SapAPIActions.addPlannedOrderTexts(
          request, requestJSON, user?.uniqueId, user?.account?.username, plant, materials, textID,
        ));
        break;
      case massUpdate.IOTA_MEMO:
        dispatch(allActions.MaterialDetailsActions.addIotaMemoComments(
          request?.to_LongText, user?.uniqueId, user?.account?.name,
          user?.account?.username, massUpdate.IOTA_MEMO, plant, materials,
        ));
        break;
      case massUpdate.WAREHOUSE_MEMO:
        dispatch(allActions.MaterialDetailsActions.addWareHouseComments(
          request?.to_LongText, user?.uniqueId, user?.account?.name,
          user?.account?.username, massUpdate.WAREHOUSE_MEMO, plant, materials,
        ));
        break;
      default:
        break;
    }
  };

  const submitTextUpdates = () => {
    setShowModal(false);
    const [newRequest, revertRequest] = requestBuilder(
      plant, materials, currentTextList, newTextUpdates, excludeList, currentTab,
    );
    setRevert(revertRequest);
    submit(newRequest);
  };

  const submitRevert = () => {
    setShowRevertModal(false);
    submit(revert, true);
  };

  const onPasteCapture = (event) => {
    event.preventDefault();
    const pastedText = event.clipboardData.getData('text');
    const splitText = pastedText && pastedText.split('\r\n').filter((s) => s.length > 0);
    if (!splitText || splitText.length < 1) return;
    setSearchMMR([...materials, ...splitText]);
  };

  return (
    <>
      <ConfirmModal
        style={{
          minWidth: '80vw', minHeight: '80vh', maxWidth: '80vw', maxHeight: '80vh',
        }}
        title="Confirm changes"
        visible={showModal}
        onOK={submitTextUpdates}
        onCancel={() => setShowModal(false)}
        message={(
          <MemoConfirm
            selectedPlant={plant}
            materials={materials}
            currentTextList={currentTextList}
            newTextUpdates={newTextUpdates}
            excludeList={excludeList}
            textType={currentTab}
          />
        )}
      />
      <ConfirmModal
        style={{
          minWidth: '80vw', minHeight: '80vh', maxWidth: '80vw', maxHeight: '80vh',
        }}
        title="Undo submitted changes?"
        visible={showRevertModal}
        onOK={submitRevert}
        onCancel={() => setShowRevertModal(false)}
        message={(
          <RevertConfirm
            revertObject={revert}
            currentTextList={currentTextList}
            textType={currentTab}
          />
        )}
      />
      <Card>
        <Row gutter={[12, 12]}>
          <Col span={12}>
            <Space style={{ width: '100%' }} direction="vertical">
              <Select
                showSearch
                placeholder="Select a single plant"
                size="large"
                style={{ width: '100%' }}
                onChange={updatePlant}
                value={plant}
              >
                {plantList?.map((x) => (
                  <Option
                    key={x.PLANT_FACILITY_SAP_ID}
                  >
                    {getPlantName(x)}
                  </Option>
                ))}
              </Select>
              <MaterialSelector
                searchMMR={materials}
                setSearchMMR={setSearchMMR}
                style={{ width: '100%' }}
                placeholder="Enter one or more materials"
                handleMMRSearch={(e) => {
                  e?.target?.blur();
                  e?.target?.focus();
                }}
                tagRender={tagRender}
                onPasteCapture={onPasteCapture}
              />
              <Space>
                <Button type="primary" onClick={onOK} disabled={!plant || materials?.length === 0 || error}>
                  Select materials
                </Button>
              </Space>
            </Space>
          </Col>
          <Col span={12}>
            <Space style={{ width: '100%' }} direction="vertical">
              <Row>
                <TextArea
                  value={inputValue}
                  onChange={(e) => onInputValueChange(e)}
                  placeholder={`${getCurTabText(currentTab)} to add to all materials`}
                  style={{ width: '100%', minHeight: '88px' }}
                  size="large"
                  autoSize
                />
              </Row>
              <Row>
                <Space style={{ width: '100%', justifyContent: 'right' }}>
                  <Button
                    disabled={inputValue?.length === 0}
                    type="primary"
                    onClick={() => massInput(writeTypes.BEGINNING)}
                  >
                    Add to beginning
                  </Button>
                  <Button
                    disabled={inputValue?.length === 0}
                    type="primary"
                    onClick={() => massInput(writeTypes.END)}
                  >
                    Add to end
                  </Button>
                  <Button
                    disabled={inputValue?.length === 0}
                    onClick={() => massInput(writeTypes.REPLACE)}
                  >
                    Overwrite all
                  </Button>
                </Space>
              </Row>
            </Space>
          </Col>
        </Row>

        <br />
        {
          plant && materials?.length > 0 && showTable
            ? (
              <Table
                dataSource={getDataSource(materials)}
                rowClassName={(_record, index) => (index % 2 === 0 ? 'table-row-light' : 'table-row-dark')}
                columns={configColumns(excludeList)}
                size="small"
                pagination={false}
                scroll={{
                  y: 465,
                }}
                footer={() => (
                  <Row style={{ justifyContent: 'right' }}>
                    <Col offset={8} span={8} style={{ display: 'flex', justifyContent: 'center' }}>
                      <Button
                        style={{ display: revert ? 'inherit' : 'none' }}
                        className="revertButton"
                        onClick={previewRevertChanges}
                      >
                        Undo submitted change
                      </Button>
                    </Col>
                    <Col span={8}>
                      <Space style={{ position: 'relative', float: 'right' }}>
                        <Button type="primary" onClick={reset} disabled={!changesMade}>
                          Reset all changes
                        </Button>
                        <Button
                          disabled={!plant || materials?.length === 0 || !validExists}
                          type="primary"
                          onClick={previewChanges}
                        >
                          Submit
                        </Button>
                      </Space>
                    </Col>
                  </Row>
                )}
              />
            )
            : <Empty description="No materials selected" image={Empty.PRESENTED_IMAGE_SIMPLE} />
        }
      </Card>
    </>
  );
};

export default MassUpdate;

MassUpdate.propTypes = {
  currentTab: PropTypes.string.isRequired,
};
