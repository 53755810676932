import { SearchOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';

export default function MaterialSelector({
  searchMMR, setSearchMMR, handleMMRSearch, size, placeholder, tagRender, onPasteCapture,
}) {
  const count = searchMMR?.length - 1;
  return (
    <Select
      placeholder={placeholder || (
        <div>
          <SearchOutlined style={{ color: 'black', marginRight: '4px' }} />
          Material Search
        </div>
      )}
      maxTagCount={5}
      maxTagPlaceholder={`+ ${count - 4} values`}
      allowClear
      mode="tags"
      size={size}
      notFoundContent=""
      style={{ width: '100%' }}
      onChange={(event) => {
        setSearchMMR(event);
      }}
      value={searchMMR}
      tokenSeparators={[' ']}
      onInputKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.stopPropagation();
          if (handleMMRSearch) handleMMRSearch(e);
        }
      }}
      tagRender={tagRender}
      onPasteCapture={onPasteCapture}
    />

  );
}

MaterialSelector.defaultProps = {
  handleMMRSearch: null,
  size: 'large',
  placeholder: null,
  tagRender: null,
  onPasteCapture: null,
};

MaterialSelector.propTypes = {
  searchMMR: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
    .isRequired,
  setSearchMMR: PropTypes.func.isRequired,
  handleMMRSearch: PropTypes.func,
  size: PropTypes.string,
  placeholder: PropTypes.node,
  tagRender: PropTypes.func,
  onPasteCapture: PropTypes.func,
};
