const getPlantCluster = (plantRelationships, focusedPlant) => {
  const plantCluster = [];
  const relatedPlants = plantRelationships?.[focusedPlant];
  plantCluster.push(focusedPlant);
  if (relatedPlants?.length > 0) {
    relatedPlants.forEach((rel) => {
      plantCluster.push(rel.Plant_ID);
      const relatedPlant = plantRelationships?.[rel.Plant_ID];
      if (relatedPlant) relatedPlant.forEach((r) => plantCluster.push(r.Plant_ID));
    });
  }
  return [...new Set(plantCluster)];
};

const convertToNumber = (value) => {
  const n = Number(value);
  if (Number.isNaN(n)) return 0;
  return n;
};

const transformInputObject = (inputObject) => {
  const keys = Object.keys(inputObject);
  const { length } = Object.keys(inputObject[keys[0]]);
  return Array.from({ length }, (_v, i) => {
    const item = keys.reduce((acc, key) => {
      acc[key] = inputObject[key][i];
      return acc;
    }, {});
    return item;
  });
};

export { getPlantCluster, convertToNumber, transformInputObject };
