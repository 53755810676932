const NUMBER_OF_RECORDS_TO_FETCH = 50;
const NUMBER_OF_NOTIFICATIONS_TO_FETCH = 10;
const USE_WORKLIST_VIEW = 'USE_WORKLIST_VIEW';
const STATIC_WL_MAX_ITEMS = 2000; // temporary value for testing
const SIM_RETENTION_PERIOD = 5184000; // 60 days in seconds
const SIM_TYPES = {
  SINGLE_MATERIAL: 'Single Material',
  SCENARIO: 'Scenario',
  NEW_STOCK: 'New Stock',
};
const NOTIFICATION_TYPES = {
  PAST_DUE: 'PastDue',
  SIM_COMPLETE: 'SimComplete',
  SIM_FAILED: 'SimFailed',
};
const NOTIFICATION_TYPE_NAMES = {
  PAST_DUE: 'Decision past due',
  SIM_COMPLETE: 'Simulation run complete',
  SIM_FAILED: 'Simulation run failed',
};

const COMMENT_TYPE = {
  IOTA: 'iota',
  WAREHOUSE: 'warehouse',
};

const MODES = {
  PLANT: 'plant',
  CC: 'companyCode',
};

const listModes = {
  matList: 'MATERIAL_LIST',
  mmList: 'MATERIAL_MASTER',
};

const IMPACT = {
  savings: 'Savings',
  invest: 'Investment',
  noImp: 'No impact',
};

const ACTIONTYPES = {
  Postpone: 'Postpone',
  Review: 'Review',
  Inspect: 'Inspect',
  LastChecked: 'Last-Checked-Date',
};

const WORKLIST_VIEW = {
  PERSONAL: 'personal',
  PUBLIC: 'public',
};

const dateCols = ['EDAM_POSTING_DT', 'NEW_VALUE', 'OLD_VALUE', 'ITEM_REQUIRED_SAP_DT', 'CC_LAST_REAL_CONS_DT'];
const dateTimeCols = ['UDATETIME'];

// New Stock
const NS_PAGE_SIZE = 10;

const emptyValue = '§';

export {
  NUMBER_OF_RECORDS_TO_FETCH,
  NUMBER_OF_NOTIFICATIONS_TO_FETCH,
  USE_WORKLIST_VIEW,
  STATIC_WL_MAX_ITEMS,
  SIM_RETENTION_PERIOD,
  SIM_TYPES,
  NOTIFICATION_TYPES,
  NOTIFICATION_TYPE_NAMES,
  COMMENT_TYPE,
  MODES,
  IMPACT,
  dateCols,
  dateTimeCols,
  NS_PAGE_SIZE,
  listModes,
  emptyValue,
  WORKLIST_VIEW,
  ACTIONTYPES,
};
