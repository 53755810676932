import * as actionTypes from './ActionTypes';

const logTestResult = (label, success, error) => ({
  type: actionTypes.logTestResult,
  payload: {
    label,
    success,
    error,
  },
});

const resetTestResults = () => ({
  type: actionTypes.resetTestResults,
});

export default { logTestResult, resetTestResults };
