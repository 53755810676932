import * as actionTypes from '../actions/MaterialDetailsDomain/ActionTypes';
import * as SapAPIActions from '../actions/SapAPIDomain/ActionTypes';
import * as loadingKeys from '../actions/MaterialDetailsDomain/LoadingKeys';

export default function materialDetailsReducer(state = { material: [], mrpSettings: [], fixedBin: null }, action) {
  const loadingArray = state.loading ? { ...state.loading } : {};
  switch (action.type) {
    case actionTypes.setLoading: {
      loadingArray[action.payload] = true;
      return {
        ...state,
        loading: loadingArray,
      };
    }
    case actionTypes.failedLoading: {
      loadingArray[action.payload] = false;
      return {
        ...state,
        loading: loadingArray,
      };
    }
    case actionTypes.getMaterial:
    case actionTypes.getMaterials:
    case actionTypes.getAdditionalMaterial: {
      loadingArray[loadingKeys.getMaterial] = false;
      return {
        ...state,
        material: [
          ...state.material.filter(
            (e) => !action.payload.some(
              (item) => e.PLANT_FACILITY_SAP_ID === item.PLANT_FACILITY_SAP_ID
                && e.MATERIAL_TYPE_SAP_ID === item.MATERIAL_TYPE_SAP_ID,
            ),
          ),
          ...action.payload,
        ],
        loading: loadingArray,
      };
    }
    case actionTypes.getMaterialDescriptions:
      loadingArray[loadingKeys.getMaterialDescriptions] = false;
      return {
        ...state,
        materialDescriptions: action.payload,
        loading: loadingArray,
      };
    case actionTypes.getBomExplosion: {
      loadingArray[loadingKeys.bomExplosion] = false;
      return {
        ...state,
        bomExplosion: action.payload,
        loading: loadingArray,
      };
    }
    case actionTypes.getConsumptionHistory: {
      loadingArray[loadingKeys.consumptionHistory] = false;
      return {
        ...state,
        consumptionHistory: action.payload,
        loading: loadingArray,
      };
    }
    case actionTypes.getReservations: {
      loadingArray[loadingKeys.reservations] = false;
      return {
        ...state,
        reservations: action.payload,
        loading: loadingArray,
      };
    }
    case actionTypes.createChangelog:
      return {
        ...state,
        isLoadingData: false,
      };
    case actionTypes.getChangelogs: {
      loadingArray[loadingKeys.changelogs] = false;
      return {
        ...state,
        changelogs: action.payload,
        loading: loadingArray,
      };
    }
    case actionTypes.getMaterialLeadTime: {
      loadingArray[loadingKeys.materialLeadTime] = false;
      return {
        ...state,
        materialLeadTime: action.payload,
        loading: loadingArray,
      };
    }
    case actionTypes.getXPlant: {
      loadingArray[loadingKeys.getXPlant] = false;
      return {
        ...state,
        xPlant: action.payload,
        loading: loadingArray,
      };
    }
    case actionTypes.returnDocument: {
      loadingArray[loadingKeys.returnDocument] = false;
      return {
        ...state,
        returnDocument: action.payload,
        loading: loadingArray,
      };
    }
    case actionTypes.getMaterialChangelogs: {
      loadingArray[loadingKeys.materialChangelogs] = false;
      return {
        ...state,
        materialChangelogs: action.payload,
        loading: loadingArray,
      };
    }
    case actionTypes.getMaterialMovement: {
      loadingArray[loadingKeys.materialMovement] = false;
      return {
        ...state,
        materialMovement: action.payload,
        loading: loadingArray,
      };
    }
    case actionTypes.getFunctionalLocation: {
      loadingArray[loadingKeys.functionalLocation] = false;
      return {
        ...state,
        functionalLocation: action.payload,
        loading: loadingArray,
      };
    }
    case actionTypes.getWarehouse: {
      loadingArray[loadingKeys.warehouse] = false;
      return {
        ...state,
        warehouse: action.payload,
        loading: loadingArray,
      };
    }
    case actionTypes.getPurchasing: {
      loadingArray[loadingKeys.purchasing] = false;
      return {
        ...state,
        purchasing: action.payload,
        loading: loadingArray,
      };
    }
    case actionTypes.getPlantRelationships:
      loadingArray[loadingKeys.relatedPlants] = false;
      return {
        ...state,
        plantRelationships: {
          ...state.plantRelationships,
          [action.payload.plant]: action.payload.data,
        },
        loading: loadingArray,
      };
    case SapAPIActions.getMRP:
    case SapAPIActions.getMRPs:
      loadingArray[loadingKeys.mrp] = false;
      return {
        ...state,
        mrpSettings: [
          // remove the old versions of incoming MRP data if they exist.
          ...state.mrpSettings.filter(
            (e) => !action.payload.some(
              (item) => e.Plant === item.Plant && e.MaterialNumber === item.MaterialNumber,
            ),
          ),
          ...action.payload,
        ],
        loading: loadingArray,
      };
    case SapAPIActions.setMRP:
      return {
        ...state,
        mrpUpdated: true,
      };
    case SapAPIActions.clearMRP:
    case SapAPIActions.clearMRPs:
      return {
        ...state,
        mrpSettings: [],
      };
    case SapAPIActions.getFixedBin:
      loadingArray[loadingKeys.fixedBin] = false;
      return {
        ...state,
        fixedBin: action.payload,
        loading: loadingArray,
      };
    case SapAPIActions.setFixedBin:
      return {
        ...state,
        fixedBinUpdate: true,
      };
    case SapAPIActions.clearFixedBin:
      return {
        ...state,
        fixedBinUpdate: [],
      };
    case SapAPIActions.clearFixedBinupdate:
      return {
        ...state,
        fixedBinUpdate: false,
      };
    case SapAPIActions.mrpUpdateError:
      return {
        ...state,
        mrpUpdateError: true,
      };
    case SapAPIActions.clearMrpUpdateError:
      return {
        ...state,
        mrpUpdateError: false,
      };
    case SapAPIActions.clearMRPupdate:
      return {
        ...state,
        mrpUpdated: false,
      };
    case actionTypes.changeGroupIdFromMassUpdateMRP:
      return {
        ...state,
        changeGroupIdFromMassUpdateMRP: action.payload,
      };
    case actionTypes.storeLongText:
      loadingArray[loadingKeys.getLongText] = false;
      if (state.longText && state.longText.material === action.payload.material) {
        return {
          ...state,
          longText: { ...state.longText, [action.payload.plant]: action.payload?.data[0] },
          loading: loadingArray,
        };
      }
      return {
        ...state,
        longText: {
          material: action.payload.material,
          [action.payload.plant]: action.payload?.data[0],
        },
        loading: loadingArray,
      };
    case actionTypes.getClassification:
      loadingArray[loadingKeys.getClassification] = false;
      return {
        ...state,
        classification: action.payload,
      };

    case actionTypes.setActionComplete:
      return {
        ...state,
        actionComplete: {
          ...state?.actionComplete,
          [action.payload]: true,
        },
      };

    case actionTypes.clearActionComplete:
      return {
        ...state,
        actionComplete: {
          ...state?.actionComplete,
          [action.payload]: false,
        },
      };

    case actionTypes.updateReviewData: {
      const material = state.material.find(
        (e) => e.PLANT_FACILITY_SAP_ID === action?.payload?.[0].PLANT_FACILITY_SAP_ID
          && e.MATERIAL_TYPE_SAP_ID === action?.payload?.[0].MATERIAL_TYPE_SAP_ID,
      );
      if (material) {
        material.IsReviewed = action?.payload?.[0]?.IsReviewed;
        material.ReviewDate = action?.payload?.[0]?.ReviewDate;
        material.ReviewedBy = action?.payload?.[0]?.ReviewedBy;
        const newState = {
          ...state,
          material: [
            ...state.material.filter(
              (e) => !action.payload.some(
                (item) => e.PLANT_FACILITY_SAP_ID === item.PLANT_FACILITY_SAP_ID
                  && e.MATERIAL_TYPE_SAP_ID === item.MATERIAL_TYPE_SAP_ID,
              ),
            ),
            material,
          ],
        };
        return newState;
      }
      return state;
    }

    case actionTypes.clearData:
      return {
        ...state,
        warehouse: null,
        functionalLocation: null,
        materialMovement: null,
        materialChangelogs: null,
        consumptionHistory: null,
        reservations: null,
        purchasing: null,
        leadtime: null,
        IOTAComments: null,
        warehouseComments: null,
      };

    case actionTypes.storeIOTAComments:
      loadingArray[loadingKeys.getIOTAComments] = false;
      if (state.IOTAComments && state.IOTAComments.material === action.payload.material) {
        return {
          ...state,
          IOTAComments: { ...state.IOTAComments, [action.payload.plant]: action.payload?.data },
          loading: loadingArray,
        };
      }
      return {
        ...state,
        IOTAComments: {
          material: action.payload.material,
          [action.payload.plant]: action.payload?.data,
        },
        loading: loadingArray,
      };

    case actionTypes.storeWarehouseComments:
      loadingArray[loadingKeys.getWarehouseComments] = false;
      if (state.warehouseComments && state.warehouseComments.material === action.payload.material) {
        return {
          ...state,
          warehouseComments: {
            ...state.warehouseComments, [action.payload.plant]: action.payload?.data,
          },
          loading: loadingArray,
        };
      }
      return {
        ...state,
        warehouseComments: {
          material: action.payload.material,
          [action.payload.plant]: action.payload?.data,
        },
        loading: loadingArray,
      };

    default:
      return state;
  }
}
