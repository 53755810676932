/* eslint-disable no-else-return */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Skeleton } from 'antd';
import _ from 'lodash';
import allActions from '../../../../actions';
import * as loadingKeys from '../../../../actions/DashboardDomain/LoadingKeys';
import { MODES } from '../../../Common/GlobalConstants';
import {
  defaultColorMapping, defaultTypes, excessStockColorMapping, excessStockTypes,
} from './WidgetConstants';
import VerticalGraphWidget from './VerticalGraphWidget';
import {
  getCleanUpWidgetData, getExcessStockData, getOptInvCritOrSPData, getOptInvStrategyData,
} from './WidgetFunctions';
import { widgetTypeKeys } from './widgetTypes';
import styles from './styles.module.scss';

const GraphWidgetsContainer = ({
  canViewValues, indexBy, widgetID, label, keysVisible, keys, textWidgetID,
}) => {
  const dispatch = useDispatch();
  const selectedPlants = useSelector((state) => state.commonState.selectedPlants);
  const selectedCCs = useSelector((state) => state.commonState.selectedCompanyCodes);
  const dashboardMode = useSelector((state) => state.commonState.dashboardMode);
  const optimalInventory = useSelector((state) => state.dashboardState?.[widgetID]);
  const settingsLoaded = useSelector((state) => state.commonState?.loaded?.userSettings);
  const loading = useSelector((state) => state.dashboardState.loading);
  const userSettings = useSelector((state) => state.commonState.userSettings);
  const SQLOnly = useSelector((state) => state.commonState?.SQLOnly?.dashboard);
  const [inventoryStatus, setInventoryStatus] = useState({});

  const selectedExists = () => {
    if (selectedCCs?.length === 0 && selectedPlants?.length === 0 && optimalInventory?.A) {
      return true;
    }
    if (dashboardMode === MODES.PLANT) {
      const key = `P${selectedPlants?.sort().join('')}`;
      return !!optimalInventory?.[key];
    }
    const key = `CC${selectedCCs?.sort().join('')}`;
    return !!optimalInventory?.[key];
  };

  const updateInventoryStatus = () => {
    if (selectedCCs?.length === 0 && selectedPlants?.length === 0) {
      setInventoryStatus(optimalInventory.A);
    } else if (dashboardMode === MODES.PLANT) {
      setInventoryStatus(optimalInventory[`P${selectedPlants?.sort().join('')}`]);
    } else { setInventoryStatus(optimalInventory[`CC${selectedCCs?.sort().join('')}`]); }
  };

  const getFormattedData = (inv, widgetIDVal, indexByVal, filters) => {
    if (widgetIDVal === widgetTypeKeys.optimalInventoryByStrategy) {
      return getOptInvStrategyData(inv, filters);
    }
    if (widgetIDVal === widgetTypeKeys.optimalInventoryBySP
      || widgetIDVal === widgetTypeKeys.optimalInventoryByCrit
      || widgetIDVal === widgetTypeKeys.optimalInventoryByGroup
      || widgetIDVal === widgetTypeKeys.optimalInventoryByROP) {
      return getOptInvCritOrSPData(inv, indexByVal, filters);
    }
    if (widgetIDVal === widgetTypeKeys.excessStockByStatus) return getExcessStockData(inv, filters);
    if (widgetID === widgetTypeKeys.cleanUpWidget) return getCleanUpWidgetData(inv, filters);
    return [];
  };

  const getTypes = (widgetIDVal) => {
    if (widgetIDVal === widgetTypeKeys.excessStockByStatus) {
      return excessStockTypes;
    }
    return defaultTypes;
  };

  const getColorMapping = (widgetIDVal) => {
    if (widgetIDVal === widgetTypeKeys.excessStockByStatus) {
      return excessStockColorMapping;
    }
    return defaultColorMapping;
  };

  const loadingKeyMapper = {
    optimalInventoryBySP: loadingKeys.optInvSP,
    optimalInventoryByCrit: loadingKeys.optInvCrit,
    optimalInventoryByStrategy: loadingKeys.optInvStrategy,
    optimalInventoryByGroup: loadingKeys.optInvGroup,
    excessStockByStatus: loadingKeys.excessStockStatus,
    optimalInventoryByROP: loadingKeys.optInvROP,
    cleanUpWidget: loadingKeys.cleanUpWidget,
  };

  useEffect(() => {
    if (selectedPlants && selectedCCs && settingsLoaded && dashboardMode
      && (SQLOnly === true || SQLOnly === false)) {
      if (selectedExists()) {
        updateInventoryStatus();
      } else {
        if (!widgetID) return;
        if (loading?.[loadingKeyMapper[widgetID]] === true) return;
        dispatch(allActions.DashboardActions.setLoading(loadingKeyMapper[widgetID]));
        if (widgetID === widgetTypeKeys.excessStockByStatus) {
          dispatch(
            allActions.DashboardActions.getExcessStockByStatus(
              dashboardMode === MODES.PLANT ? selectedPlants : null,
              dashboardMode === MODES.CC ? selectedCCs : null,
              dashboardMode,
              SQLOnly,
            ),
          );
        } else {
          dispatch(
            allActions.DashboardActions[widgetID](
              dashboardMode === MODES.PLANT ? selectedPlants : null,
              dashboardMode === MODES.CC ? selectedCCs : null,
              dashboardMode,
              SQLOnly,
            ),
          );
        }
      }
    }
  }, [selectedPlants, selectedCCs, settingsLoaded,
    dashboardMode, optimalInventory, widgetID, SQLOnly]);

  return (
    <div className={styles.widgetHeight}>
      {inventoryStatus?.data && !loading?.[loadingKeyMapper[widgetID]]
        && ((dashboardMode === MODES.PLANT && _.isEqual(inventoryStatus?.plants, selectedPlants))
          || (dashboardMode === MODES.CC && _.isEqual(inventoryStatus?.companyCodes, selectedCCs)))
        ? (
          <VerticalGraphWidget
            inventoryStatusData={inventoryStatus.data}
            showLegendSetting={userSettings?.SHOW_WIDGET_LEGENDS}
            canViewValues={canViewValues}
            types={getTypes(widgetID)}
            colorMapping={getColorMapping(widgetID)}
            label={label}
            keysVisible={keysVisible ?? true}
            widgetID={widgetID}
            keys={keys}
            getFormattedData={(inv, filters) => getFormattedData(inv, widgetID, indexBy, filters)}
            indexBy={indexBy}
            textWidgetID={textWidgetID}
          />
        )
        : (
          <Skeleton
            active
            paragraph={{ rows: 5 }}
          />
        )}
    </div>
  );
};
export default GraphWidgetsContainer;

GraphWidgetsContainer.defaultProps = {
  label: '',
  keysVisible: true,
  keys: [],
  textWidgetID: null,
};

GraphWidgetsContainer.propTypes = {
  canViewValues: PropTypes.bool.isRequired,
  indexBy: PropTypes.string.isRequired,
  widgetID: PropTypes.string.isRequired,
  label: PropTypes.string,
  keysVisible: PropTypes.bool,
  keys: PropTypes.arrayOf(PropTypes.string),
  textWidgetID: PropTypes.string,
};
