const supplierLocations = [
  { code: 'Domestic', display: 'Domestic (+3)' },
  { code: 'Continental', display: 'Continental (+9)' },
  { code: 'Global', display: 'Global (+12)' },
];

const criticality = [

  { code: '3', display: '3 (H)' },
  { code: '2/6', display: '2/6 (M)' },
  { code: '9', display: '9 (M)' },
  { code: '5', display: '5 (M)' },
  { code: '1/4/7/8', display: '1/4/7/8 (L)' },
  { code: 'A/Y/N/Z', display: 'A/Y/N/Z (Other)' },
  { code: 'Blank (None)', display: 'Blank (None)' },
];

const CUOptions = [
  { code: 'days', display: 'days' },
  { code: 'weeks', display: 'weeks' },
  { code: 'months', display: 'months' },
  { code: 'years', display: 'years' },
];

const nullResults = {
  StockLocation: null,
  MRPType: null,
  LotSize: null,
  ROP: null,
  MAX: null,
  MLS: null,
  consumptionSegment: null,
};

const columns = [
  {
    title: 'MRP type', dataIndex: 'MRP_TYPE', align: 'center', width: 70,
  },
  {
    title: 'Lot Size', dataIndex: 'Lot_Size', align: 'center', width: 70,
  },
  {
    title: 'ROP', dataIndex: 'ROP', align: 'center', width: 80,
  },
  {
    title: 'Max', dataIndex: 'Max', align: 'center', width: 80,
  },
  {
    title: 'MLS', dataIndex: 'MLS', align: 'center', width: 90,
  },
  {
    title: 'Consumption segment', dataIndex: 'consumption_segment', align: 'center', width: 80,
  },
  {
    title: 'Stock Location', dataIndex: 'Stock_loc', align: 'center', width: 100,
  },
];

const referenceColumns = [
  {
    title: 'Delivery time',
    dataIndex: 'delivery',
  },
  {
    title: 'Supplier location',
    dataIndex: 'location',
  },
  {
    title: 'Material criticality',
    dataIndex: 'criticality',
  },
  {
    title: 'Max consumed',
    dataIndex: 'consumed',
  },
  {
    title: 'Consumption frequency',
    dataIndex: 'consumption',
  },
  {
    title: 'Price (NOK)',
    dataIndex: 'price',
  },
];

export {
  supplierLocations, criticality, nullResults, columns, CUOptions, referenceColumns,
};
