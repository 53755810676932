import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveBar } from '@nivo/bar';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Empty, Space } from 'antd';
import { scales } from './WidgetConstants';
import allActions from '../../../../actions';
import { NUMBER_OF_RECORDS_TO_FETCH } from '../../../Common/GlobalConstants';
import { filterWidgetData, getDashboardFilters, hasDataAfterFilter } from './WidgetFunctions';
import { widgetTypeKeys } from './widgetTypes';
import { widgetViewVals } from '../../Config/WidgetViewConstants';

function HorizontalGraphWidget({ inventoryData, widgetID }) {
  const selectedPlants = useSelector((state) => state.commonState.selectedPlants);
  const selectedCCs = useSelector((state) => state.commonState.selectedCompanyCodes);
  const userSettings = useSelector((state) => state.commonState.userSettings);
  const dashboardFilter = useSelector((state) => state.commonState.localDashboardFilters);
  const dragStatus = useSelector((state) => state.dashboardState.dragStatus);
  const widgetViews = useSelector((state) => state.commonState.widgetViews);
  const [data, setData] = useState([]);
  const [axisLeft, setAxisLeft] = useState({});

  const statusTypes = {
    openWithInsufficient: 'Open reservations with insufficient coverage',
    openWithSufficient: 'Open reservations with sufficient coverage',
    openWithIncoming: 'Open reservations with incoming coverage',
    replenishment: 'Replenishment only',
  };

  const tickFormat = (v) => {
    const labels = {
      [statusTypes.openWithIncoming]: ['Open reservations with', 'incoming coverage'],
      [statusTypes.openWithInsufficient]: ['Open reservations with', 'insufficient coverage'],
      [statusTypes.openWithSufficient]: ['Open reservations with', 'sufficient coverage'],
      [statusTypes.replenishment]: ['Replenishment', 'only'],
    };
    const valueArray = labels[v];
    if (valueArray?.length === 2) {
      return (
        <tspan x="0" y="0">
          {' '}
          <tspan x="0" dy="0">{valueArray[0]}</tspan>
          {' '}
          <tspan x="0" dy="10">{valueArray[1]}</tspan>
          {' '}
        </tspan>
      );
    }
    return labels?.[v]?.join() || v;
  };

  useEffect(() => {
    if (_.isEmpty(inventoryData)) {
      setData([]);
      return;
    }
    if (inventoryData) {
      const filteredData = filterWidgetData(inventoryData, dashboardFilter);
      const formattedData = {};
      Object.values(statusTypes).forEach((status) => {
        const count = filteredData?.filter((x) => x?.PLANNED_ORDER_STATUS === status)
          ?.reduce(((prev, curr) => prev + (curr?.COUNT ?? 0)), 0);
        formattedData[status] = count ?? 0;
      });
      const result = Object.keys(formattedData).map((x) => ({
        status: x,
        count: formattedData[x],
      }))?.sort((a, b) => (a?.count ?? 0) - (b?.count ?? 0));
      setData(result);
    }
  }, [inventoryData, dashboardFilter]);

  useEffect(() => {
    if (!widgetID || widgetID === widgetTypeKeys.plannedOrders) {
      setAxisLeft({
        tickSize: 8,
        tickPadding: 4,
        tickRotation: 12,
        format: tickFormat,
      });
    } else {
      setAxisLeft({
        tickSize: 8,
        tickPadding: 4,
        tickRotation: 12,
      });
    }
  }, [widgetID]);

  const dispatch = useDispatch();
  const history = useHistory();

  const getColor = (x) => {
    switch (x.indexValue) {
      case (statusTypes.openWithInsufficient):
        return '#FF7D23';
      case (statusTypes.openWithIncoming):
        return '#FFA061';
      case (statusTypes.openWithSufficient):
        return '#FFC59F';
      case (statusTypes.replenishment):
        return '#FFE7D6';
      default: return '#888888';
    }
  };

  const handleClick = (e) => {
    if (dragStatus) return;
    const viewID = widgetViews?.find(
      (x) => x.WidgetID === 'plannedOrders' && (x.GroupID === e.indexValue || x.GroupID === widgetViewVals.all),
    )?.ViewID;
    if (viewID) {
      dispatch(allActions.CommonActions.selectWidgetView(viewID));
    } else {
      dispatch(allActions.CommonActions.selectWidgetView(userSettings?.DEFAULT_USER_VIEW));
    }
    let searchObject = {
      PLANNED_ORDER_STATUS: [
        {
          ColumnName: 'PLANNED_ORDER_STATUS',
          FilterOperator: 'EqualTo',
          FilterValue: [
            e.indexValue,
          ],
        },
      ],
    };
    if (selectedPlants?.length > 0) {
      searchObject = {
        ...searchObject,
        PLANT_FACILITY_SAP_ID: [
          {
            ColumnName: 'PLANT_FACILITY_SAP_ID',
            FilterOperator: 'EqualTo',
            FilterValue:
              selectedPlants,
          },
        ],
      };
    } else if (selectedCCs?.length > 0) {
      searchObject = {
        ...searchObject,
        COMPANY_CODE: [
          {
            ColumnName: 'COMPANY_CODE',
            FilterOperator: 'EqualTo',
            FilterValue:
              selectedCCs,
          },
        ],
      };
    }
    searchObject = {
      ...searchObject,
      ...getDashboardFilters(dashboardFilter),
    };
    const materialViewState = {
      headerCells: [],
      searchFilters: searchObject,
      scrollPage: {
        currentNoOfRecords: 0,
        noOfRecordsToFetch: NUMBER_OF_RECORDS_TO_FETCH,
      },
    };
    if ((selectedPlants?.length > 0
      && !_.isEqual(selectedPlants?.sort(), userSettings?.DEFAULT_PLANTS?.sort()))
      || (selectedCCs?.length > 0
        && !_.isEqual(selectedCCs?.sort(), userSettings?.DEFAULT_COMPANY_CODES?.sort()))) {
      dispatch(allActions.CommonActions.setUseDefaultSettings(false));
    }
    dispatch(allActions.MaterialListActions.setLocalMaterialView(materialViewState));
    dispatch(allActions.MaterialListActions.setSelectedRowKeys([]));
    history.push('/material-list');
  };

  return data.length > 0 && hasDataAfterFilter(data) ? (
    <ResponsiveBar
      data={data}
      indexBy="status"
      keys={['count']}
      layout="horizontal"
      label={(d) => `${d.value === 0 ? '' : d.value}`}
      valueScale={scales.symloghor}
      colors={getColor}
      axisLeft={axisLeft}
      margin={{
        top: 10, right: 20, bottom: 25, left: 130,
      }}
      padding={0.3}
      enableGridX
      enableGridY={false}
      tooltip={() => (<></>)}
      ariaLabel="Planned orders"
      onClick={handleClick}
    />
  ) : (
    <Space
      direction="vertical"
      style={{
        width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center',
      }}
    >
      <Empty description={_.isEmpty(dashboardFilter?.filterVals) ? 'No data' : 'No data after filtering'} />
    </Space>
  );
}

export default HorizontalGraphWidget;
HorizontalGraphWidget.defaultProps = {};

HorizontalGraphWidget.propTypes = {
  inventoryData: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    PropTypes.objectOf(PropTypes.any)]).isRequired,
  widgetID: PropTypes.string.isRequired,
};
