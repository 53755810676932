export const latest = {
  date: '11-10-2022',
  features: [
    '- Warehouse comment seciton',
    '- Inspection functionality',
    '- Quick Search - Functional Location and Storage Bin',
  ],
};
export const pages = [
  {
    label: 'Menu',
    features: [
      '- Quick search',
      '- Material search',
      '- Plant/CC selector',
      '- Plant/CC Mode selector',
      '- Default settings switch',
      '- Impact registration',
    ],
  },
  {
    label: 'Notifications',
    features: [
      '- Notification UI',
      '- Mark specific / all notifications as done',
      '- User can show/hide completed notifications',
      '- Notification for postponed decision past due',
      '- Notification for completed simulation job',
    ],
  },
  {
    label: 'Material List',
    features: [
      '- Sort, filter, search',
      '- Navigate to Material Details',
      '- Save as Worklist',
      '- Advanced search',
      '- Multiple values search',
      '- Paste value list from excel',
      '- Save current View with a name',
      '- Show number of results',
      '- Apply default plants as filter',
      '- Save selection as static worklist or add to existing static worklist',
      '- Adjustable columns',
      '- Download CSV file with results of search, with all columns or current view',
      '- Can click through materials in a worklist / material list',
    ],
  },
  {
    label: 'Material Details',
    features: [
      '- Summary of plant / material',
      '- Implementing recommendations (demo)',
      '- Related Plants',
      '- Tabs (Material Movement, Functional Location, Reservations)',
      '- MRP settings from SAP',
      '- Directly update MRP settings or Material memo in SAP',
      '- Simulation (single material)',
      '- Multiplant simulation',
      '- Long text (reformatted)',
      '- Add material to static worklist (or create new worklist with the current plant/material)',
      '- Set material reviewed',
      '- Postpone decision, clear postponed to date',
      '- Delegate',
      '- Can click through materials in a worklist / material list',
      '- Warehouse comment seciton',
      '- Inspection functionality',
    ],
  },
  {
    label: 'Worklist',
    features: [
      '- Sort, search, delete worklists',
      '- View and edit worklists',
      '- Shared / private worklists',
      '- Save as new worklist',
      '- Show number of worklists found',
      '- Static worklist page',
      '- Create or delete static worklists',
      '- View static worklist contents',
      '- Change worklist view (column setup) when viewing it in material list',
      '- Remove items from static worklist by selection when viewing contents',
      '- Can add all materials in a search to static worklist',
      '- When adding to a static worklist, it selects the first one with available space',
      '- Download CSV file with static or dynamic worklist results, with all columns or current view',
    ],
  },
  {
    label: 'Simulation',
    features: [
      '- Scenario (multiple material) simulation',
      '- Central view of all simulations',
    ],
  },
  {
    label: 'Settings',
    features: [
      '- Set default plant(s) or company code',
      '- Choose whether plant/CC filter should be applied by default',
      '- Manage your material list Views and set the default',
      '- Choose which notification types to see (Only Past Due notifications implemented so far)',
      '- Default tabs for Material Details',
    ],
  },
  {
    label: 'Dashboard',
    features: [
      '- A standard set of 7 widgets, based on your default plants, or all plants if none selected.',
      '- Optimal Inventory',
      '- Value of Inventory',
      '- Optimal Inventory by Strategy',
      '- Optimal Inventory by Group',
      '- Monetary Impact',
      '- Total Excess Stock',
      '- Excess Stock By Status',
    ],
  },
];
