import React, {
  useState, useReducer, useEffect,
} from 'react';
import {
  Table, Row, Col, Button, InputNumber,
} from 'antd';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  parameters, validatePOCost, validateHoldingCost, validateInput, addonAfter,
} from './helpers/ConfigFunctions';
import allActions from '../../../actions/index';
import * as loadingKeys from '../../../actions/common/LoadingKeys';
import './helpers/ConfigTable.css';
import ConfirmModal from '../../Common/ConfirmModal';
import ConfigConfirm from './helpers/ConfigConfirm';
import { MODES } from '../../Common/GlobalConstants';
import { configColumns } from './helpers/columns';
import ConfigSelector from './helpers/ConfigSelector';

const reducer = (state, action) => {
  switch (action.type) {
    case 'addChange': {
      // Copy the change table without the record to be edited, as it will be replaced
      const newChangeTable = state.changeTable.filter(
        (rec) => rec.key !== action.payload.originalRecord.key,
      );
      // If the record was already in changetable, base edit on that
      let changeRec = state.changeTable.find(
        (rec) => rec.key === action.payload.originalRecord.key,
      ) ?? action.payload.originalRecord;
      changeRec = { ...changeRec, [action.payload.col]: action.payload.value };
      // if the change makes it equal to original, leave it out of changetable
      if (!_.isEqual(changeRec, action.payload.originalRecord)) newChangeTable.push(changeRec);
      return { ...state, changeTable: newChangeTable };
    }

    case 'resetRow': {
      const exists = state.changeTable.some((rec) => rec.key === action.payload.record.key);
      if (!exists) return { ...state };
      const newChangeTable = state.changeTable.filter(
        (rec) => rec.key !== action.payload.record.key,
      );
      return { ...state, changeTable: newChangeTable };
    }

    case 'resetAll':
      return { ...state, changeTable: [] };

    case 'changeAll': {
      // populate a new changeTable with all records not in current configTable selection,
      // these will not be changed.
      // (user might have changed some records, then changed selection)
      const newChangeTable = state.changeTable.filter(
        (rec) => !action.payload.configTable.some((r) => r.key === rec.key),
      );
      // for each record in the current selection, add the change
      // (or don't add if change returns it to original values)
      action.payload.configTable.forEach((record) => {
        let changeRec = state.changeTable.find(
          (rec) => rec.key === record.key,
        ) ?? record;
        changeRec = { ...changeRec, [action.payload.col]: action.payload.value };
        if (!_.isEqual(changeRec, record)) newChangeTable.push(changeRec);
      });
      return { ...state, changeTable: newChangeTable };
    }

    default:
      throw new Error();
  }
};

/**
 * This component displays the records given by prop with editable fields.
 * It keeps track of changes made to the current records.
 * It validates input and adds valid inputs to the change table.
 * It allows the user to click submit and review the change table.
 * It allows the user to set a value for all the selected records
 * (the entire config table given by parent component)
 */
const CostConfig = () => {
  const configTable = useSelector((state) => state.commonState.configTable);
  const dispatch = useDispatch();
  const allPlants = useSelector((state) => state.commonState.plants);
  const allCompanyCodes = useSelector((state) => state.commonState.companyCodes);
  const [selectedPlants, setSelectedPlants] = useState([]);
  const [selectedCompanyCodes, setSelectedCompanyCodes] = useState([]);
  // MPOC=Manual PO Cost, APOC=Auto PO Cost, HC=Holding Cost
  const [multiMPOC, setMultiMPOC] = useState();
  const [multiAPOC, setMultiAPOC] = useState();
  const [multiHC, setMultiHC] = useState();
  const [paramIsValid, setParamIsValid] = useState({});
  const [confirmModal, setConfirmModal] = useState(false);
  const [showPlant, setShowPlant] = useState(true);
  const [state, localDispatch] = useReducer(reducer, { changeTable: [] });
  const [displayConfig, setDisplayConfig] = useState([]);

  useEffect(() => { setDisplayConfig(configTable); }, [configTable]);

  useEffect(() => {
    dispatch(allActions.CommonActions.setLoading(loadingKeys.configTable));
    if (!(configTable?.length > 0)) {
      dispatch(allActions.CommonActions.getConfigTable());
    }
  }, []);

  const resetAll = () => {
    localDispatch({ type: 'resetAll' });
  };

  const resetRow = (record) => {
    localDispatch({ type: 'resetRow', payload: { record } });
  };

  const updateMulti = (value, parameter) => {
    const valid = validateInput(value, parameter);
    setParamIsValid({ ...paramIsValid, [parameter]: valid });
    if (parameter === parameters.auto) setMultiAPOC(value);
    if (parameter === parameters.manual) setMultiMPOC(value);
    if (parameter === parameters.holding) setMultiHC(value);
  };

  const applyMulti = () => {
    const checkedMPOC = validatePOCost(multiMPOC);
    if (checkedMPOC || checkedMPOC === 0) {
      localDispatch({
        type: 'changeAll',
        payload: { configTable: displayConfig, col: 'MANUAL_PO_COST', value: checkedMPOC },
      });
    }

    const checkedAPOC = validatePOCost(multiAPOC);
    if (checkedAPOC || checkedAPOC === 0) {
      localDispatch({
        type: 'changeAll',
        payload: { configTable: displayConfig, col: 'AUTO_PO_COST', value: checkedAPOC },
      });
    }

    const checkedHC = validateHoldingCost(multiHC);
    if (checkedHC || checkedHC === 0) {
      localDispatch({
        type: 'changeAll',
        payload: { configTable: displayConfig, col: 'HOLDING_COST_PRCT', value: checkedHC },
      });
    }
  };

  const submitChanges = () => {
    if (state.changeTable?.length > 0) {
      dispatch(allActions.CommonActions.updateConfigTable(state.changeTable));
    }
    setConfirmModal(false);
    localDispatch({ type: 'resetAll' });
  };

  const handleChange = (event, type) => {
    if (type === MODES.PLANT) {
      setSelectedPlants(event.map((e) => e.key));
    }
    if (type === MODES.CC) {
      setSelectedCompanyCodes(event.map((e) => e.key));
    }
  };

  useEffect(() => {
    const newConfigSelection = [];
    if (showPlant && selectedPlants?.length > 0) {
      selectedPlants.forEach((plant) => newConfigSelection.push(
        configTable.find((rec) => plant === rec.PLANT_FACILITY_SAP_ID),
      ));
    }
    if (!showPlant && selectedCompanyCodes?.length > 0) {
      configTable.forEach((rec) => {
        if (selectedCompanyCodes.includes(rec.COMPANY_CODE)) {
          newConfigSelection.push(rec);
        }
      });
    }
    setDisplayConfig(newConfigSelection.length > 0 ? newConfigSelection : configTable);
  }, [selectedPlants, selectedCompanyCodes]);

  useEffect(() => {
    setDisplayConfig(configTable);
    setSelectedPlants([]);
    setSelectedCompanyCodes([]);
  }, [showPlant]);

  return (
    <>
      <ConfirmModal
        title="Confirm changes"
        visible={confirmModal}
        onOK={() => submitChanges()}
        onCancel={() => setConfirmModal(false)}
        message={<ConfigConfirm configTable={configTable} changeTable={state.changeTable} />}
      />
      <Row gutter={4}>
        <Col className="plantCCSelector" span={12}>
          <ConfigSelector
            showPlant={showPlant}
            setShowPlant={setShowPlant}
            allPlants={allPlants}
            allCompanyCodes={allCompanyCodes}
            selectedPlants={selectedPlants}
            setSelectedPlants={setSelectedPlants}
            selectedCompanyCodes={selectedCompanyCodes}
            setSelectedCompanyCodes={setSelectedCompanyCodes}
            updatePlantCC={handleChange}
          />
        </Col>
        <Col span={3}>
          <InputNumber
            size="large"
            className={paramIsValid[parameters.manual] === false ? 'invalid' : ''}
            style={{ width: '100%' }}
            value={multiMPOC}
            placeholder="Manual PO Cost"
            min={0}
            max={100000}
            step={10}
            onChange={(val) => updateMulti(val, parameters.manual)}
          />
        </Col>
        <Col span={3}>
          <InputNumber
            size="large"
            className={paramIsValid[parameters.auto] === false ? 'invalid' : ''}
            style={{ width: '100%' }}
            value={multiAPOC}
            placeholder="Auto PO Cost"
            min={0}
            max={100000}
            step={10}
            onChange={(val) => updateMulti(val, parameters.auto)}
          />
        </Col>
        <Col span={3}>
          <InputNumber
            size="large"
            style={{ width: '100%' }}
            className={paramIsValid[parameters.holding] === false ? 'invalid' : ''}
            value={multiHC}
            placeholder="Holding Cost (%)"
            min={0}
            max={1}
            step={0.001}
            formatter={(val) => (val ? +parseFloat(val * 100).toFixed(2) : '')}
            parser={(val) => (val ? (val / 100) : '')}
            onChange={(val) => updateMulti(val, parameters.holding)}
            addonAfter={addonAfter('HOLDING_COST_PRCT')}
          />
        </Col>
        <Col span={3}>
          <Button size="large" type="primary" style={{ width: '100%' }} onClick={() => applyMulti()}>
            Set
          </Button>
        </Col>
      </Row>
      <br />
      <Table
        dataSource={displayConfig}
        rowClassName={(record, index) => (index % 2 === 0 ? 'table-row-light' : 'table-row-dark')}
        columns={configColumns(state, localDispatch, resetAll, resetRow)}
        size="small"
        pagination={{ pageSize: 10 }}
      />
      <Row justify="end">
        <Button type="primary" onClick={() => setConfirmModal(true)} disabled={state.changeTable?.length < 1}>
          Submit changes
        </Button>
      </Row>
    </>
  );
};

export default CostConfig;
