import { Col, Empty, Row } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import PlantCard from './components/PlantComponents/PlantCard';
import PlantColumnsCard from './components/PlantComponents/PlantColumnsCard';
import allActions from '../../../actions';
import * as loadingKeys from '../../../actions/MaterialDetailsDomain/LoadingKeys';
import './MaterialDetails.css';

function PlantElements({ hasViewerAccess }) {
  const { plant } = useParams();
  const dispatch = useDispatch();
  const plantRelationships = useSelector((state) => state.materialDetailsState.plantRelationships);
  const loading = useSelector((state) => state.materialDetailsState.loading);

  useEffect(() => {
    dispatch(allActions.MaterialDetailsActions.setLoading(loadingKeys.relatedPlants));
    dispatch(allActions.MaterialDetailsActions.fetchPlantRelationships(plant));
  }, [plant]);

  const width = plantRelationships?.[plant]?.length
    ? Math.min(Math.floor((24 - 4) / plantRelationships?.[plant]?.length), 6)
    : 0;
  const plants = plantRelationships?.[plant]?.map((relatedPlant) => (
    <Col key={relatedPlant.Plant_ID} span={width}>
      <PlantCard
        plantID={relatedPlant.Plant_ID}
        loading={loading?.[loadingKeys.relatedPlants] ?? false}
        hasViewerAccess={hasViewerAccess}
      />
    </Col>
  ));

  return (
    plants?.length > 0
      ? (
        <Row className="full-tab">
          <Col span={4}>
            <PlantColumnsCard />
          </Col>
          {plants}
        </Row>
      )
      : <Empty />
  );
}

export default PlantElements;
PlantElements.defaultProps = {
  hasViewerAccess: false,
};
PlantElements.propTypes = {
  hasViewerAccess: PropTypes.bool,
};
