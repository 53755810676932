import React, { useEffect, useState } from 'react';
import {
  Button, Card, Col, Descriptions, Empty, Row, Tooltip,
} from 'antd';
import PropTypes from 'prop-types';
import './SummaryStyle.css';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import _ from 'lodash';
import {
  localDateFormat, getCurrency, convertCost,
} from '../../../../Common/DateAndNumberFunctions';
import { getSAPLink, statusIndicator } from '../helpers';
import CommentSection from './CommentSection';
import ExpandedLongTextModal from './ExpandedLongTextModal';
import allActions from '../../../../../actions';
import { createIOTAPayload } from './Recommendations/Generators';

const { Item } = Descriptions;

function MaterialDetailsCard({
  data, classification, loading, canViewPrices, canWriteback,
}) {
  const defaultCurrency = useSelector((state) => state.commonState?.userSettings?.DEFAULT_CURRENCY);
  const currencyList = useSelector((state) => state.commonState?.currencyList);
  const configTable = useSelector((state) => state.commonState.configTable);
  const user = useSelector((state) => state.authState.user);
  const [expandedLongText, setExpandedLongText] = useState(false);
  const [longTextType, setLongTextType] = useState('');
  const display = !!data.DISPLAY_REC_IND;
  const dispatch = useDispatch();
  const { plant, material } = useParams();
  const [lastPO, setLastPO] = useState('');
  const [lastPOCurrency, setLastPOCurrency] = useState(null);

  useEffect(() => {
    if (plant
      && !_.isEmpty(data)
      && configTable?.length > 0
      && configTable.some((x) => x.PLANT_FACILITY_SAP_ID === plant)) {
      const { AUTO_PO_COST, MANUAL_PO_COST, COMPANY_CURRENCY_SAP_CD } = configTable
        ?.find((x) => x.PLANT_FACILITY_SAP_ID === plant);
      if (AUTO_PO_COST && MANUAL_PO_COST && data?.CC_TRANS_COST_SOURCE) {
        setLastPO(data?.CC_TRANS_COST_SOURCE === 'Manual PO' ? MANUAL_PO_COST : AUTO_PO_COST);
        setLastPOCurrency(COMPANY_CURRENCY_SAP_CD);
      }
    }
  }, [plant, data, configTable]);

  const tableStyle = {
    fontSize: '12px',
    height: '5px',
    padding: '0px 4px',
    width: '20ch',
  };

  const labelStyle = {
    fontSize: '12px',
    height: '5px',
    padding: '0px 4px',
    width: '40%',
  };

  const dateIndicator = (propName) => {
    if (data?.[propName]) {
      const currentDate = new Date();
      const compareDate = new Date(data?.[propName]);
      if (currentDate > compareDate) {
        return statusIndicator('Red');
      }
      return statusIndicator('Green');
    }
    return null;
  };

  const expandLongText = (type) => {
    setExpandedLongText(true);
    setLongTextType(type);
  };

  const closeLongText = () => {
    setExpandedLongText(false);
  };

  const dateItem = (label, value, clearFunction = null, dateArg = null) => (
    <Item label={label}>
      <Row align="space-around">
        <Col style={{ width: '15%' }} />
        <Col style={{ width: '20%' }}>{dateArg && dateIndicator(dateArg)}</Col>
        <Col style={{ width: '30%' }}>{value ? localDateFormat(value) : '-'}</Col>
        <Col style={{ width: '35%' }}>
          {value && clearFunction && <Button className="clearButton" onClick={clearFunction} size="small">Clear</Button>}
        </Col>
      </Row>
    </Item>
  );

  const changelogPayload = (key, oldVal, userObj) => {
    const payload = createIOTAPayload(plant, material, [{
      current: oldVal,
      userDefined: '-',
      key,
    }], userObj);
    return payload;
  };

  const clearPostpone = () => {
    const payload = changelogPayload('Postpone', data?.PostponeToDate, user);
    dispatch(allActions.MaterialDetailsActions.createChangelog(plant, material, payload));
    dispatch(allActions.MaterialDetailsActions.clearPostponement(plant, material));
  };

  const clearReview = () => {
    const payload = changelogPayload('Review', data?.ReviewDate, user);
    dispatch(allActions.MaterialDetailsActions.createChangelog(plant, material, payload));
    dispatch(allActions.MaterialDetailsActions.clearReview(plant, material));
  };

  const clearInspection = () => {
    const payload = changelogPayload('Inspect', data?.InspectToDate, user);
    dispatch(allActions.MaterialDetailsActions.createChangelog(plant, material, payload));
    dispatch(allActions.MaterialDetailsActions.clearInspection(plant, material));
  };

  return (
    data ? (
      <>
        <ExpandedLongTextModal
          expanded={expandedLongText}
          type={longTextType}
          longText={data?.[longTextType]}
          close={closeLongText}
          authorizedToEdit={canWriteback}
          data={data}
        />
        <Card
          className="detailCard"
          style={{
            height: 'calc(51vh - 120px)',
            padding: '0px',
          }}
        >
          <div className="headline">Basic Material Data</div>
          <Descriptions
            className="infoTable"
            column={1}
            bordered
            labelStyle={labelStyle}
            contentStyle={tableStyle}
          >
            <Item label="Material">
              {
                getSAPLink('Click to navigate to MM03 in SAP', 'MM03', [data?.MATERIAL_TYPE_SAP_ID], data?.MATERIAL_TYPE_SAP_ID)
              }
            </Item>
            <Item label="Description">
              <Tooltip title={data?.EDAM_MATERIAL_TYPE_NM || ''}>
                {data?.EDAM_MATERIAL_TYPE_NM ? `${data?.EDAM_MATERIAL_TYPE_NM?.substring(0, 28)}...` : '-'}
              </Tooltip>
            </Item>
            <Item label="Base unit">{data?.BASE_UNIT_OF_MEASURE_SAP_ID}</Item>
            <Item label="Segment">{data?.SEGMENT}</Item>
            <Item label="Material group">
              {`${data?.PRODUCT_SERVICE_GROUP_SAP_CD} - ${data?.PRODUCT_SERVICE_GROUP_SAP_NM}`}
            </Item>
            <Item label="In agreement">{data?.MATERIAL_TYPE_IN_CONTRACT_IND}</Item>
            <Item label="Leadtime">{data?.CC_LEAD_TIME}</Item>
            <Item label="Leadtime source">{data?.CC_LT_SOURCE}</Item>
            <Item label="Holding cost">{display && canViewPrices && (data?.HOLDING_COST_PRCT ? `${(data?.HOLDING_COST_PRCT * 100).toFixed(0)} %` : '-')}</Item>
            <Item label="Transaction cost">{display && canViewPrices && (lastPO ? `${convertCost(lastPO, defaultCurrency, currencyList, lastPOCurrency)} ${getCurrency(lastPO, defaultCurrency)}` : '-')}</Item>
            <Item label="Trans. cost source">{display && canViewPrices && data?.CC_TRANS_COST_SOURCE}</Item>
            <Item label="Moving avg. price">
              {canViewPrices && `${convertCost(data?.CC_UNIT_COST_AMT, defaultCurrency, currencyList) ?? '-'} ${getCurrency(data?.CC_UNIT_COST_AMT, defaultCurrency)}`}
            </Item>
            <Item label="Market price">
              {canViewPrices && `${convertCost(data?.MARKET_PRICE, defaultCurrency, currencyList) ?? '-'} ${getCurrency(data?.MARKET_PRICE, defaultCurrency)}`}
            </Item>
            <Item label="Fair price">
              {canViewPrices && `${convertCost(data?.FAIR_PRICE, defaultCurrency, currencyList) ?? '-'} ${getCurrency(data?.FAIR_PRICE, defaultCurrency)}`}
            </Item>
            <Item label="QM proc">{data?.QM_PROCUREMENT_ACTIVE}</Item>
            <Item label="x-plant matl status">{data?.CROSS_PLANT_MATERIAL_STATUS}</Item>
          </Descriptions>
        </Card>
        <Card
          style={{
            height: 'calc(25vh - 5px)',
          }}
        >
          <CommentSection
            data={data}
            expandLongText={expandLongText}
            loading={loading}
            classification={classification}
            tableStyle={tableStyle}
            SAPwritebackAuthorized={canWriteback}
          />
        </Card>
        <Card
          className="detailCard"
          style={{
            height: '26vh',
            padding: '0px',
          }}
        >
          <div className="headline">Recency</div>
          <Descriptions
            column={1}
            bordered
            labelStyle={labelStyle}
            contentStyle={tableStyle}
          >
            {dateItem('Last consumption', data?.CC_LAST_REAL_CONS_DT)}
            {dateItem('Last movement', data?.CC_LAST_MOVEMENT_DT)}
            {dateItem('Last received', data?.CC_LAST_RECEIVED_DT)}
            {dateItem('Last reviewed', data?.ReviewDate, clearReview)}
            {dateItem('Last optimized date', data?.LastOptimizedDate)}
            {dateItem('Day 1', data?.CC_DAY_1)}
            {dateItem('Created date', data?.EDAM_MATERIAL_TYPE_CREATED_DT)}
            {dateItem('Postponed date', data?.PostponeToDate, clearPostpone, 'PostponeToDate')}
            {dateItem('Inspection date', data?.InspectToDate, clearInspection, 'InspectToDate')}
            {dateItem('Rec. source date', data?.CC_ROP_LT_END_DT)}
          </Descriptions>
        </Card>
      </>
    ) : <Empty />
  );
}

MaterialDetailsCard.defaultProps = {
  data: {},
  classification: {},
  loading: {},
};

MaterialDetailsCard.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  classification: PropTypes.objectOf(PropTypes.any),
  loading: PropTypes.objectOf(PropTypes.bool),
  canViewPrices: PropTypes.bool.isRequired,
  canWriteback: PropTypes.bool.isRequired,
};

export default MaterialDetailsCard;
