import { emptyValue } from '../../../../../Common/GlobalConstants';

const originalColumns = [
  {
    title: '',
    dataIndex: 'name',
    width: '40%',
  },
  {
    title: 'Current',
    dataIndex: 'current',
    width: '15%',
    align: 'center',
    render: (text) => (text === emptyValue ? ' ' : text),
  },
  {
    title: 'Recomm.',
    dataIndex: 'recommended',
    width: '15%',
    align: 'center',
    render: (text) => (text === emptyValue ? ' ' : text),
  },
];

const tableColumns = () => [
  {
    key: 'MRPType',
    name: 'MRP Type',
  },
  {
    key: 'LotSize',
    name: 'Lot Size',
  },
  {
    key: 'ReorderPoint',
    name: 'Reorder Point',
  },
  {
    key: 'MinimumLotSize',
    name: 'Min Lot Size',
  },
  {
    key: 'MaximumStockLevel',
    name: 'Max Stock Level',
  },
  {
    key: 'MaximumLotSize',
    name: 'Max Lot Size',
  },
  {
    key: 'RoundingValue',
    name: 'Rounding Value',
  },
  {
    key: 'FixedLotSize',
    name: 'Fixed Lot Size',
  },
  {
    key: 'MRPGroup',
    name: 'MRP Group',
  },
  {
    key: 'MRPController',
    name: 'MRP Controller',
  },
  {
    key: 'PlantMaterialStatus',
    name: 'Plant Mat. Status',
  },
  {
    key: 'ABCIndicator',
    name: 'ABC Indicator',
  },
];

const dropdownColumns = {
  MRPType: 'MRP_TYPE_SAP_CD',
  LotSize: 'LOT_SIZE_SAP_CD',
  MRPGroup: 'CC_MRP_GROUP',
  MRPController: 'MRP_CONTROLLER_SAP_ID',
  PlantMaterialStatus: 'CROSS_PLANT_MATERIAL_STATUS',
  ABCIndicator: 'CONSUMPTION_VALUE_TYPE_CD',
};

export {
  originalColumns,
  tableColumns,
  dropdownColumns,
};
