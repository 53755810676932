import * as actions from '../actions/SimulationDomain/ActionTypes';
import * as loadingKeys from '../actions/SimulationDomain/LoadingKeys';

export default function SimulationReducer(state = {}, action) {
  const simResultsArrToMap = (array) => {
    const obj = {};
    array.forEach((result) => {
      obj[result.RunID] = result;
    });
    return obj;
  };
  const simLoadingArray = state.loading ? { ...state.loading } : {};
  // let status;
  switch (action.type) {
    case actions.setSimLoading:
      simLoadingArray[action.payload] = true;
      return {
        ...state,
        loading: simLoadingArray,
      };
    case actions.getAllSimJobs:
      simLoadingArray[loadingKeys.retrieveSimulations] = false;
      return {
        ...state,
        allSimJobs: simResultsArrToMap(action.payload),
        loading: simLoadingArray,
      };
    case actions.simulate:
      return {
        ...state,
        simulationID: action.payload,
      };
    case actions.simulateMultiple:
      return {
        ...state,
        simulationMultipleID: action.payload,
      };
    case actions.retrieveSimulation:
      simLoadingArray[loadingKeys.retrieveSimulationResult] = false;
      return {
        ...state,
        simResults: {
          ...state.simResults,
          [action.payload.id]: {
            metadata: action.payload.metadata, results: action.payload.results,
          },
        },
        loading: simLoadingArray,
      };
    case actions.clearSimulationResults:
      return {
        ...state,
        simResults: { ...state.simResults, [action.payload]: null },
      };

    case actions.simulationStatus:
      if (action.payload.status !== 'RUNNING' && action.payload.status !== 'PENDING') {
        simLoadingArray[loadingKeys.retrieveSimulationResult] = false;
      }
      return {
        ...state,
        simResults: {
          ...state.simResults,
          [action.payload.id]: {
            status: action.payload.status, start_time: action.payload.start_time,
          },
        },
        loading: simLoadingArray,
      };

    case actions.saveNewStockJobData:
      return {
        ...state,
        newStockData: {
          ...state.newStockData,
          jobData: action.payload,
        },
      };

    case actions.setNewStockError:
      return {
        ...state,
        newStockData: {
          ...state.newStockData,
          jobData: {
            ...state.newStockData.jobData,
            error: true,
            jobId: null,
          },
        },
      };

    case actions.unsetNewStockError:
      return {
        ...state,
        newStockData: {
          ...state.newStockData,
          jobData: {
            ...state.newStockData.jobData,
            error: false,
          },
        },
      };

    case actions.storeFormRowsTemp:
      return {
        ...state,
        newStockData: {
          ...state.newStockData,
          formRows: action.payload,
        },
      };

    case actions.saveNewStockResult:
      // status = 'done';
      // if (action.payload === null) status = 'running';
      return {
        ...state,
        newStockData: {
          ...state.newStockData,
          simResults: action.payload,
          loading: false,
        },
      };

    case actions.clearNewStockResults:
      return {
        newStockData: {
          ...state.newStockData,
          simResults: {},
          jobData: {},
          loading: false,
        },
      };

    case actions.saveAllNewStockForms:
      return {
        ...state,
        newStockData: {
          ...state.newStockData,
          allNewStockForms: action.payload,
        },
      };

    default:
      return state;
  }
}
