import { Button } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import React from 'react';
import { stringOptions } from '../../MaterialListView/HeaderComponents/AdvancedSearch/AdvancedSearchOptions';
import RenderCell from './RenderCell';
import { localDateFormat } from '../../../Common/DateAndNumberFunctions';
import { formatPercent } from './ConfigFunctions';

const getCondition = (value) => stringOptions.find((x) => x.value === value)?.display || value;

const configViewColumns = (uniqueItems, initialDefaults = []) => [
  {
    title: 'Plant',
    dataIndex: 'PLANT_FACILITY_SAP_ID',
    sorter: (a, b) => a.PLANT_FACILITY_SAP_ID - b.PLANT_FACILITY_SAP_ID,
    filters: uniqueItems,
    onFilter: (value, record) => record.PLANT_FACILITY_SAP_ID.indexOf(value) === 0,
    defaultFilteredValue: initialDefaults,
    width: '5%',

  },
  {
    title: 'Company Code',
    dataIndex: 'COMPANY_CODE',
    sorter: (a, b) => a.COMPANY_CODE - b.COMPANY_CODE,
    width: '5%',
  },
  {
    title: 'Manual PO Cost',
    dataIndex: 'MANUAL_PO_COST',
    render: (text, record) => `${text} ${record.COMPANY_CURRENCY_SAP_CD || ''}`,
    width: '5%',
  },
  {
    title: 'Auto PO Cost',
    dataIndex: 'AUTO_PO_COST',
    render: (text, record) => `${text} ${record.COMPANY_CURRENCY_SAP_CD || ''}`,
    width: '5%',
  },
  {
    title: 'Holding Cost',
    dataIndex: 'HOLDING_COST_PRCT',
    render: (text) => `${formatPercent(text)}%`,
    width: '5%',
  },
  {
    title: 'Exclude PM10',
    dataIndex: 'EXCLUDE_PM10',
    render: (text) => (text ? <CheckOutlined /> : <CloseOutlined />),
    width: '5%',
  },
];

const configColumns = (state, localDispatch, resetAll, resetRow) => [
  {
    title: 'Plant',
    dataIndex: 'PLANT_FACILITY_SAP_ID',
    sorter: (a, b) => a.PLANT_FACILITY_SAP_ID - b.PLANT_FACILITY_SAP_ID,
  },
  {
    title: 'Company Code',
    dataIndex: 'COMPANY_CODE',
    sorter: (a, b) => a.COMPANY_CODE - b.COMPANY_CODE,
  },
  {
    title: 'Currency',
    dataIndex: 'COMPANY_CURRENCY_SAP_CD',
    sorter: (a, b) => a.COMPANY_CURRENCY_SAP_CD?.localeCompare(b.COMPANY_CURRENCY_SAP_CD),
  },
  {
    title: 'Manual PO Cost',
    dataIndex: 'MANUAL_PO_COST',
    width: '13%',
    render: (text, record, index) => (
      <RenderCell
        changeTable={state.changeTable}
        dispatch={localDispatch}
        text={text}
        record={record}
        index={index}
        col="MANUAL_PO_COST"
      />
    ),
  },
  {
    title: 'Auto PO Cost',
    dataIndex: 'AUTO_PO_COST',
    width: '12%',
    render: (text, record, index) => (
      <RenderCell
        changeTable={state.changeTable}
        dispatch={localDispatch}
        text={text}
        record={record}
        index={index}
        col="AUTO_PO_COST"
      />
    ),
  },
  {
    title: 'Holding Cost',
    dataIndex: 'HOLDING_COST_PRCT',
    width: '14%',
    render: (text, record, index) => (
      <RenderCell
        changeTable={state.changeTable}
        dispatch={localDispatch}
        text={text}
        record={record}
        index={index}
        col="HOLDING_COST_PRCT"
      />
    ),
  },
  {
    title: 'Exclude PM10',
    dataIndex: 'EXCLUDE_PM10',
    render: (text, record, index) => (
      <RenderCell
        changeTable={state.changeTable}
        dispatch={localDispatch}
        text={text}
        record={record}
        index={index}
        col="EXCLUDE_PM10"
        isBit
      />
    ),
  },
  {
    key: 'delete',
    dataIndex: 'delete',
    title: (
      <Button onClick={() => resetAll()}>
        Reset all changes
      </Button>
    ),
    width: '12%',
    render: (_text, record) => (
      <Button size="small" onClick={() => resetRow(record)}>
        Reset
      </Button>
    ),
  },
];

const outlierViewColumns = (uniqueItems, initialDefaults = []) => ([
  {
    title: 'Plant',
    dataIndex: 'PLANT_FACILITY_SAP_ID',
    sorter: (a, b) => a.PLANT_FACILITY_SAP_ID - b.PLANT_FACILITY_SAP_ID,
    filters: uniqueItems,
    onFilter: (value, record) => record.PLANT_FACILITY_SAP_ID.indexOf(value) === 0,
    defaultFilteredValue: initialDefaults,
  },
  {
    title: 'Company Code',
    dataIndex: 'COMPANY_CODE',
    sorter: (a, b) => a.COMPANY_CODE - b.COMPANY_CODE,
  },
  {
    title: 'Condition',
    dataIndex: 'QUERY_TYPE',
    render: (text) => getCondition(text),
  },
  {
    title: 'Input',
    dataIndex: 'REVISION_CODE',
  },
  {
    title: 'Created by',
    dataIndex: 'CREATED_BY',
  },
  {
    title: 'Created on',
    dataIndex: 'CREATED_ON',
    render: (createdDate) => localDateFormat(createdDate),
    sorter: (a, b) => new Date(a?.CREATED_ON) - new Date(b?.CREATED_ON),
  },
]);

const outlierColumns = (deleteOutlier) => ([
  ...outlierViewColumns(),
  {
    key: 'delete',
    dataIndex: 'delete',
    title: '',
    render: (_text, record) => (
      <Button size="small" onClick={() => deleteOutlier(record)}>
        Remove
      </Button>
    ),
  },
]);

export {
  configViewColumns, configColumns, outlierColumns, outlierViewColumns,
};
