const classCharacteristics = [
  {
    value: 'CREATED_BY',
    key: 'CREATED_BY',
    title: 'Created by',
  },
  {
    value: 'EDAM_MATERIAL_TYPE_CREATED_DT',
    key: 'EDAM_MATERIAL_TYPE_CREATED_DT',
    title: 'Created Date',
  },
  {
    value: 'MATERIAL_TYPE_DELETION_IND',
    key: 'MATERIAL_TYPE_DELETION_IND',
    title: 'Del Ind',
  },
  {
    value: 'QUICK_SEARCH_EN',
    key: 'QUICK_SEARCH_EN',
    title: 'EN: Descriptions',
  },
  {
    value: 'INDUSTRY_SECTOR',
    key: 'INDUSTRY_SECTOR',
    title: 'Industry Sector',
  },
  {
    value: 'LAST_CHANGED_DATE',
    key: 'LAST_CHANGED_DATE',
    title: 'Last changed date',
  },
  {
    value: 'LAST_CHANGED_BY',
    key: 'LAST_CHANGED_BY',
    title: 'Last changed by',
  },
  {
    value: 'MANUFACTURER_COMPANY_SAP_ID',
    key: 'MANUFACTURER_COMPANY_SAP_ID',
    title: 'Manuf',
  },
  {
    value: 'MANUFACTURER_MATERIAL_TYPE_SAP_CD',
    key: 'MANUFACTURER_MATERIAL_TYPE_SAP_CD',
    title: 'Manuf Partno',
  },
  {
    value: 'PRODUCT_SERVICE_GROUP_SAP_CD',
    key: 'PRODUCT_SERVICE_GROUP_SAP_CD',
    title: 'Mat Grp',
  },
  {
    value: 'PRODUCT_SERVICE_GROUP_SAP_NM',
    key: 'PRODUCT_SERVICE_GROUP_SAP_NM',
    title: 'Mat Grp Name',
  },
  {
    value: 'SEGMENT',
    key: 'SEGMENT',
    title: 'Mat Segm',
  },
  {
    value: 'MATERIAL_CLASS_SAP_CD',
    key: 'MATERIAL_CLASS_SAP_CD',
    title: 'Mat Typ',
  },
  {
    value: 'MATERIAL_CLASS_SAP_NM',
    key: 'MATERIAL_CLASS_SAP_NM',
    title: 'Mat Typ Descr',
  },
  {
    value: 'MATERIAL_TYPE_SAP_ID',
    key: 'MATERIAL_TYPE_SAP_ID',
    title: 'Material',
  },
  {
    value: 'NET_WEIGHT',
    key: 'NET_WEIGHT',
    title: 'Net Weight',
  },
  {
    value: 'OLD_MATERIAL_NUMBER',
    key: 'OLD_MATERIAL_NUMBER',
    title: 'Old Material Number',
  },
  {
    value: 'QM_PROCUREMENT_ACTIVE',
    key: 'QM_PROCUREMENT_ACTIVE',
    title: 'QM Proc Act',
  },
  {
    value: 'BASE_UNIT_OF_MEASURE_SAP_ID',
    key: 'BASE_UNIT_OF_MEASURE_SAP_ID',
    title: 'UOM',
  },
  {
    value: 'CROSS_PLANT_MATERIAL_STATUS',
    key: 'CROSS_PLANT_MATERIAL_STATUS',
    title: 'X-Pl Matl Stat',
  },
  {
    value: 'chemical',
    key: 'chemical',
    title: 'Chemical class',
    children: [
      {
        value: 'CONDITIONCODE',
        key: 'CONDITIONCODE',
        title: 'Condition Code',
      },
      {
        value: 'CROSS_REF_EHS',
        key: 'CROSS_REF_EHS',
        title: 'Cross Ref EHS',
      },
      {
        value: 'LOCALIMPORTVENDOR',
        key: 'LOCALIMPORTVENDOR',
        title: 'Local Import Vendor',
      },
      {
        value: 'NATIONALPROD-IMP',
        key: 'NATIONALPROD-IMP',
        title: 'National Prod IMP',
      },
      {
        value: 'PROD_GROUP',
        key: 'PROD_GROUP',
        title: 'Prod Group',
      },
      {
        value: 'SYNONYME',
        key: 'SYNONYME',
        title: 'Synonyme',
      },
      {
        value: 'TEXT_SEARCH_1',
        key: 'TEXT_SEARCH_1',
        title: 'Text Search',
      },
      {
        value: 'TRADE_NAME',
        key: 'TRADE_NAME',
        title: 'Trade Name',
      },
    ],
  },
  {
    value: 'general',
    key: 'general',
    title: 'General class',
    children: [
      {
        value: 'CLASS_QUALITY_CHECKED',
        key: 'CLASS_QUALITY_CHECKED',
        title: 'Class Quality Checked',
      },
      {
        value: 'COMMENT_MATERIAL_',
        key: 'COMMENT_MATERIAL_',
        title: 'Comment Material',
      },
      {
        value: 'COUNTRY_OF_ORIGIN',
        key: 'COUNTRY_OF_ORIGIN',
        title: 'Country of Origin',
      },
      {
        value: 'CREATE_POTEXT',
        key: 'CREATE_POTEXT',
        title: 'Create PO Text',
      },
      {
        value: 'DATE_OF_LAST_CHANGE',
        key: 'DATE_OF_LAST_CHANGE',
        title: 'Date of Last Change',
      },
      {
        value: 'EXTENDED_PARTNO',
        key: 'EXTENDED_PARTNO',
        title: 'Ext partno',
      },
      {
        value: 'IDENTIFIER_IN_SOURCE_',
        key: 'IDENTIFIER_IN_SOURCE_',
        title: 'Identifier in Source',
      },
      {
        value: 'MANUFACTURERS_PARTNUMBER_',
        key: 'MANUFACTURERS_PARTNUMBER_',
        title: 'Manufacturer\'s Part Number',
      },
      {
        value: 'MANUFACTURER_',
        key: 'MANUFACTURER_',
        title: 'Manufacturer',
      },
      {
        value: 'MATERIAL_CATEGORY',
        key: 'MATERIAL_CATEGORY',
        title: 'Material Category',
      },
      {
        value: 'MFGR_PARTNO_STRIPPED_',
        key: 'MFGR_PARTNO_STRIPPED_',
        title: 'Manuf. Partnum. Stripped',
      },
      {
        value: 'NORSOK_NAME_',
        key: 'NORSOK_NAME_',
        title: 'Norsok Name',
      },
      {
        value: 'PURCHASINGQUALITY',
        key: 'PURCHASINGQUALITY',
        title: 'Purchasing Quality',
      },
      {
        value: 'SOURCE_OF_INFORMATION_',
        key: 'SOURCE_OF_INFORMATION_',
        title: 'Source of Information',
      },
      {
        value: 'TEXT_SEARCH_',
        key: 'TEXT_SEARCH_',
        title: 'Text Search',
      },
      {
        value: 'TYPE_',
        key: 'TYPE_',
        title: 'Model/Type',
      },
      {
        value: 'WEIGHT_UNIT',
        key: 'WEIGHT_UNIT',
        title: 'Weight Unit',
      },
    ],
  },
  {
    value: 'internalComment',
    key: 'internalComment',
    title: 'Internal Comment',
    children: [
      {
        value: 'internalCommentDA',
        key: 'internalCommentDA',
        title: 'DA Internal Comment',
      },
      {
        value: 'internalCommentEN',
        key: 'internalCommentEN',
        title: 'EN Internal Comment',
      },
      {
        value: 'internalCommentNO',
        key: 'internalCommentNO',
        title: 'NO Internal Comment',
      },
      {
        value: 'internalCommentPT',
        key: 'internalCommentPT',
        title: 'PT Internal Comment',
      },
    ],
  },
  {
    value: 'materialDescription',
    key: 'materialDescription',
    title: 'Material description',
    children: [
      {
        value: 'DA_MATERIAL_TYPE_NM',
        key: 'DA_MATERIAL_TYPE_NM',
        title: 'DA:Material Description',
      },
      {
        value: 'EN_MATERIAL_TYPE_NM',
        key: 'EN_MATERIAL_TYPE_NM',
        title: 'EN:Material Description',
      },
      {
        value: 'NO_MATERIAL_TYPE_NM',
        key: 'NO_MATERIAL_TYPE_NM',
        title: 'NO:Material Description',
      },
      {
        value: 'PT_MATERIAL_TYPE_NM',
        key: 'PT_MATERIAL_TYPE_NM',
        title: 'PT:Material Description',
      },
    ],
  },
  {
    value: 'poText',
    key: 'poText',
    title: 'PO Text',
    children: [
      {
        value: 'POtextDA',
        key: 'POtextDA',
        title: 'DA: PO txt.',
      },
      {
        value: 'POtextEN',
        key: 'POtextEN',
        title: 'EN: PO txt.',
      },
      {
        value: 'POtextNO',
        key: 'POtextNO',
        title: 'NO: PO txt.',
      },
      {
        value: 'POtextPT',
        key: 'POtextPT',
        title: 'PT: PO txt.',
      },
    ],
  },
];

export default classCharacteristics;
