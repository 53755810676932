import {
  Table, Card, PageHeader, DatePicker, Button, message,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { MinusCircleTwoTone, PlusCircleTwoTone } from '@ant-design/icons';
import _ from 'lodash';
import {
  impactType, reviewTableCols, subLevelColumns, topLevelColumns,
} from './summaryColumns';
import allActions from '../../../actions';
import { getSummary, getCategoryArrays } from './generators';
import { convertCost, isoDateToFirstOfMonth, isoDateToFirstOfNextMonth } from '../../Common/DateAndNumberFunctions';
import './monetaryImpactSummary.css';
import ConfirmModal from '../../Common/ConfirmModal';

const { RangePicker } = DatePicker;

const MonetaryImpactSummary = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authState.user);
  const defaultCurrency = useSelector((state) => state.commonState?.userSettings?.DEFAULT_CURRENCY);
  const currencyList = useSelector((state) => state.commonState?.currencyList);
  const plants = useSelector((state) => state.commonState?.plants);

  const monetaryImpactSummary = useSelector((state) => state.commonState.monetaryImpactSummary);
  const [initialData, setInitialData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filteredSummaryData, setFilteredSummaryData] = useState([]);

  const today = moment();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(today);
  const [loaded, setLoaded] = useState(false);
  const [editable, setEditable] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [updatedCurrencyVals, setUpdatedCurrency] = useState({});

  useEffect(() => {
    if (plants && user?.account?.username) {
      dispatch(allActions.CommonActions.getMonetaryImpactSummary(user?.account?.username));
    }
  }, [user?.account?.username, plants]);

  const getInitialData = () => {
    let tempSummary;
    if (defaultCurrency && defaultCurrency !== 'NOK') {
      tempSummary = monetaryImpactSummary?.map((x) => ({
        ...x,
        Impact: convertCost(x?.Impact, defaultCurrency, currencyList, 'NOK', false),
        EditableType: x?.ImpactType,
      }));
    } else {
      tempSummary = monetaryImpactSummary.map((x) => ({
        ...x,
        EditableType: x?.ImpactType,
      }));
    }
    return tempSummary;
  };

  useEffect(() => {
    if (monetaryImpactSummary?.length > 0) {
      const tempSummary = getInitialData();
      setInitialData(tempSummary);
      setFilteredData(tempSummary);
      setFilteredSummaryData(getSummary());
      setLoaded(true);
      setEditable(false);
      setModalData([]);
      setShowConfirmModal(false);
    } else if (monetaryImpactSummary?.length === 0) {
      setLoaded(true);
    }
  }, [monetaryImpactSummary]);

  useEffect(() => {
    if (!loaded) return;
    if (startDate && endDate) {
      const start = isoDateToFirstOfMonth(new Date(startDate));
      const end = isoDateToFirstOfNextMonth(new Date(endDate));
      const filtered = initialData.filter(
        (x) => new Date(x.FormTimeStamp) >= start && new Date(x.FormTimeStamp) < end,
      );
      setFilteredData(filtered);
      setFilteredSummaryData(getSummary(filtered));
    } else {
      setFilteredData(initialData);
      setFilteredSummaryData(getSummary(initialData));
    }
  }, [startDate, endDate, loaded, initialData]);

  const dateControl = (values) => {
    const [start, end] = values;
    setStartDate(start);
    setEndDate(end);
  };

  const onDataChange = (e, record, col) => {
    const index = initialData.findIndex((x) => x.key === record.key);
    const newData = _.cloneDeep(initialData);
    newData[index][col] = e;
    setInitialData(newData);
  };

  const expandedRowRender = (value) => {
    let dataSource = [];
    const [savings, investment, noImpact] = getCategoryArrays(filteredData);
    if (value.impactType === impactType.investment) {
      dataSource = investment;
    }
    if (value.impactType === impactType.savings) {
      dataSource = savings;
    }
    if (value.impactType === impactType.noImpact) {
      dataSource = noImpact;
    }

    return (
      <Table
        columns={subLevelColumns(
          dataSource, defaultCurrency, editable, onDataChange,
        )}
        dataSource={dataSource}
        pagination={false}
      />
    );
  };

  const expandIcon = ({ expanded, onExpand, record }) => {
    if (record.noOfRegistrations === 0) {
      return null;
    } if (expanded) {
      return <MinusCircleTwoTone onClick={(e) => onExpand(record, e)} />;
    }
    return <PlusCircleTwoTone onClick={(e) => onExpand(record, e)} />;
  };

  const monetaryUpdate = (edit, sDate, eDate) => {
    if (edit) {
      const updatedSummary = [];
      const start = isoDateToFirstOfMonth(new Date(sDate));
      const end = isoDateToFirstOfNextMonth(new Date(eDate));
      initialData?.forEach?.((x, i) => {
        const updatedCols = [];
        const { Notes, Impact, ImpactType } = monetaryImpactSummary[i];
        let convertedImpact = Impact;
        if (defaultCurrency && defaultCurrency !== 'NOK') {
          convertedImpact = convertCost(convertedImpact, defaultCurrency, currencyList, 'NOK', false);
        }
        if (!x) return;
        if (Notes !== x.Notes) updatedCols.push('Notes');
        if (convertedImpact !== x.Impact) {
          updatedCols.push('Impact');
          setUpdatedCurrency({ ...updatedCurrencyVals, [x.key]: x.Impact });
        } else {
          const tempVals = { ...updatedCurrencyVals };
          if (tempVals[x.key] || tempVals[x.key] === 0) delete tempVals[x.key];
        }
        if (ImpactType !== x.EditableType) {
          updatedCols.push('ImpactType');
        }
        if (updatedCols.length > 0
          && ((!sDate && !eDate)
            || (new Date(x.FormTimeStamp) >= start && new Date(x.FormTimeStamp) < end))) {
          updatedSummary.push({
            ...x,
            updatedCols: JSON.stringify(updatedCols),
          });
        }
      });
      if (updatedSummary.length > 0) {
        setShowConfirmModal(true);
        setModalData(updatedSummary);
      } else {
        message.warning('No changes were found', 3);
      }
    } else {
      setEditable(true);
      setModalData([]);
    }
  };

  const saveSummaryChanges = () => {
    const updatedData = modalData?.map((x) => {
      const rowData = { ...x };
      const tempObj = monetaryImpactSummary.find((obj) => obj.key === x.key);
      let obj = {};
      if (x?.updatedCols && JSON.parse(x?.updatedCols ?? '')?.includes('Impact')) {
        if (defaultCurrency && defaultCurrency !== 'NOK') {
          rowData.Impact = convertCost(x?.Impact, 'NOK', currencyList, defaultCurrency, false);
        }
      } else {
        rowData.Impact = tempObj?.Impact;
      }
      obj = {
        Impact: tempObj?.Impact || '',
        ImpactType: tempObj?.ImpactType || '',
        Notes: tempObj?.Notes || '',
      };
      rowData.oldValue = JSON.stringify(obj);
      obj = {
        Impact: rowData?.Impact || '',
        ImpactType: rowData?.EditableType || '',
        Notes: rowData?.Notes || '',
      };
      rowData.newValue = JSON.stringify(obj);
      rowData.ImpactType = rowData?.EditableType;
      delete rowData.EditableType;
      return rowData;
    });
    dispatch(
      allActions.CommonActions.updateMonetaryImpactSummary(
        user?.account?.username, user?.uniqueId, updatedData,
      ),
    );
  };

  const getReviewTable = (data) => (
    <Table
      dataSource={data}
      columns={reviewTableCols}
      pagination={data?.length > 15}
    />
  );

  const cancelEdit = () => {
    setEditable(false);
    setInitialData(getInitialData());
  };

  return (
    <Card style={{ minHeight: '50vh' }}>
      <PageHeader
        title="Personal Monetary Impacts"
        ghost={false}
      />
      <RangePicker
        picker="month"
        defaultPickerValue={[moment().subtract(1, 'years'), moment().subtract(1, 'years')]}
        allowClear={false}
        allowEmpty={[false, true]}
        onChange={(values) => dateControl(values)}
        onCalendarChange={(values) => dateControl(values)}
        value={[startDate, endDate]}
      />
      <Button
        style={{ marginLeft: '5px', marginBottom: '5px' }}
        type="primary"
        onClick={() => dateControl([null, today])}
      >
        Reset date filter
      </Button>
      <Button
        style={{ marginLeft: '10px', marginRight: '10px' }}
        onClick={() => monetaryUpdate(editable, startDate, endDate)}
      >
        {editable ? 'Save' : 'Edit'}
      </Button>
      {
        editable ? <Button onClick={() => cancelEdit()}>Cancel Edit</Button> : null
      }
      <Table
        columns={topLevelColumns(defaultCurrency, currencyList)}
        dataSource={filteredSummaryData}
        expandable={{ expandedRowRender, expandIcon }}
        pagination={false}
        loading={!loaded}
      />
      <ConfirmModal
        title="Review Monetary Impact summary changes"
        visible={showConfirmModal}
        onOK={saveSummaryChanges}
        onCancel={() => setShowConfirmModal(false)}
        message={getReviewTable(modalData)}
      />
    </Card>
  );
};

export default MonetaryImpactSummary;
