import React from 'react';
import {
  Col, Radio, Row, Tag, Tooltip,
} from 'antd';
import PropTypes from 'prop-types';
import MultiItemSelector from '../../../Common/MultiItemSelector';
import { MODES } from '../../../Common/GlobalConstants';
import { pasteValue } from './ConfigFunctions';

const ConfigSelector = ({
  showPlant, setShowPlant, allPlants, allCompanyCodes,
  selectedPlants, setSelectedPlants, selectedCompanyCodes,
  setSelectedCompanyCodes, updatePlantCC, disableCC,
}) => (
  <Col className="plantCCSelector">
    <Row>
      <Col>
        <Radio.Group
          defaultValue={showPlant}
          value={showPlant}
          onChange={(e) => setShowPlant(e.target.value)}
          size="large"
        >
          <Radio.Button value>Plant</Radio.Button>
          <Radio.Button disabled={disableCC} value={false}>Company code</Radio.Button>
        </Radio.Group>
      </Col>
      <Col flex="auto">
        {
            showPlant
              ? (
                <MultiItemSelector
                  placeHolder="Select Plants"
                  items={allPlants
                    && allPlants.map((x) => (
                      { id: x.PLANT_FACILITY_SAP_ID, name: x.PLANT_FACILITY_SAP_NM }
                    ))}
                  multiSelect
                  selectedValues={selectedPlants}
                  handleChange={(e) => updatePlantCC(e, MODES.PLANT)}
                  clear={() => setSelectedPlants([])}
                  autoClearSearchValue={false}
                  onPasteCapture={(e) => pasteValue(
                    MODES.PLANT, e, selectedPlants, selectedCompanyCodes, allPlants,
                    allCompanyCodes, setSelectedPlants, setSelectedCompanyCodes,
                  )}
                  maxTagCount={8}
                  maxTagPlaceholder={(
                    <Tooltip
                      title={selectedPlants?.length > 0 && selectedPlants.map((p) => p).join('\r\n')}
                      overlayClassName="selectorTooltip"
                    >
                      {`+ ${(selectedPlants?.length ?? 0) - 8}`}
                    </Tooltip>
                  )}
                  tagRender={(x) => (
                    <Tag
                      key={x.value}
                      closable={false}
                    >
                      <span style={{ fontSize: '16px' }}>{x.value}</span>
                    </Tag>
                  )}
                />
              )
              : (
                <MultiItemSelector
                  placeHolder="Select Company Codes"
                  items={allCompanyCodes
                    && allCompanyCodes.map((x) => (
                      { id: x.Company_Code, name: x.Company_Name }
                    ))}
                  multiSelect
                  selectedValues={selectedCompanyCodes}
                  handleChange={(e) => updatePlantCC(e, MODES.CC)}
                  clear={() => setSelectedCompanyCodes([])}
                  autoClearSearchValue={false}
                  onPasteCapture={(e) => pasteValue(MODES.CC, e)}
                  tagRender={(x) => (
                    <Tag
                      key={x.value}
                      closable="true"
                      onClose={() => updatePlantCC(x.value, MODES.CC, true)}
                    >
                      <span style={{ fontSize: '16px' }}>{x.value}</span>
                    </Tag>
                  )}
                />
              )
          }
      </Col>
    </Row>
  </Col>
);

ConfigSelector.defaultProps = {
  allPlants: [],
  allCompanyCodes: [],
  disableCC: false,
};

ConfigSelector.propTypes = {
  showPlant: PropTypes.bool.isRequired,
  setShowPlant: PropTypes.func.isRequired,
  allPlants: PropTypes.arrayOf(PropTypes.any),
  allCompanyCodes: PropTypes.arrayOf(PropTypes.any),
  selectedPlants: PropTypes.arrayOf(PropTypes.any).isRequired,
  setSelectedPlants: PropTypes.func.isRequired,
  selectedCompanyCodes: PropTypes.arrayOf(PropTypes.any).isRequired,
  setSelectedCompanyCodes: PropTypes.func.isRequired,
  updatePlantCC: PropTypes.func.isRequired,
  disableCC: PropTypes.bool,
};

export default ConfigSelector;
