import _ from 'lodash';
import { MODES } from '../../../Common/GlobalConstants';
import {
  colors, defaultColorMapping, defaultTypes as types, labelMapping,
} from './WidgetConstants';
import { widgetTypeKeys } from './widgetTypes';

const filterWidgetData = (data, filters, statusKey = null) => {
  if (!filters?.filterVals || !data || _.isEmpty(data)) return data;
  let tempData = _.cloneDeep(data);
  Object.keys(filters?.filterVals).forEach((key) => {
    const condition = filters.searchOperators[key];
    tempData = tempData.filter((record) => {
      let objKey = key;
      if (statusKey && key === 'STATUS_LIGHT') objKey = statusKey;
      if (condition === 'EqualTo') {
        return filters.filterVals[key].includes(record[objKey]);
      }
      return !(filters.filterVals[key].includes(record[objKey]));
    });
  });
  return tempData;
};

const widgetDataGrouping = (data, groups, keysToMerge) => {
  const groupVals = [];
  const mergedVals = {};
  groups?.forEach(() => groupVals.push([]));

  data?.forEach((rec) => {
    const groupedKey = groups?.reduce(((prev, curr) => {
      const notVals = [false, null];
      const hasCurr = !!(rec?.[curr] || notVals.includes(rec?.[curr]));
      const hasPrev = !!(prev || notVals.includes(prev));
      if (!hasPrev && hasCurr) return rec[curr];
      if (hasPrev && !hasCurr) return prev;
      if (!hasPrev && !hasCurr) return '';
      const delimiter = '$$';
      return prev + delimiter + rec?.[curr];
    }), '');
    if (!mergedVals[groupedKey]) mergedVals[groupedKey] = {};
    keysToMerge?.forEach((key) => {
      if (!mergedVals[groupedKey]?.[key]) mergedVals[groupedKey][key] = 0;
      mergedVals[groupedKey][key] += rec?.[key] ?? 0;
    });
  });
  const formattedData = [];
  Object.keys(mergedVals)?.forEach((values) => {
    const vals = values?.split('$$');
    const boolVals = ['true', 'false'];
    if (vals?.length) {
      let tempObj = {};
      vals.forEach((val, i) => {
        let tempVal = val;
        if (boolVals.includes(val)) {
          tempVal = (val === 'true');
        }
        tempObj[groups[i]] = tempVal;
      });
      if (Object.keys(mergedVals[values])?.length) tempObj = { ...tempObj, ...mergedVals[values] };
      formattedData.push(tempObj);
    }
  });
  return formattedData;
};

const getFormattedData = (formattedData) => formattedData.map((obj) => {
  if (!!obj?.GREEN_COLOR && !!obj?.RED_COLOR && !!obj?.YELLOW_COLOR) return obj;
  const tempObj = _.clone(obj);
  if (!obj?.GREEN_COLOR) {
    tempObj.GREEN_COLOR = 'Green';
    tempObj.GREEN_RECORDS = 0;
    tempObj.GREEN_INVENTORY = 0;
  }
  if (!obj?.RED_COLOR) {
    tempObj.RED_COLOR = 'Red';
    tempObj.RED_RECORDS = 0;
    tempObj.RED_INVENTORY = 0;
  }
  if (!obj?.YELLOW_COLOR) {
    tempObj.YELLOW_COLOR = 'Yellow';
    tempObj.YELLOW_RECORDS = 0;
    tempObj.YELLOW_INVENTORY = 0;
  }
  return tempObj;
});

const getCleanUpWidgetData = (inv, filters) => {
  const mapping = {
    CU_MRP_TYPE: 'MRP Type',
    CU_ND_STOCK: 'ND Stock',
    CU_PD_EMPTY: 'PD Empty',
    CU_OUT_OF_STOCK: 'Out of Use',
    CU_DELETED: 'Deleted',
    CU_LOT_SIZE: 'Lot Size',
  };
  let formattedData = [];
  let invData = filterWidgetData(inv, filters);
  const groups = ['CU_MRP_TYPE', 'CU_ND_STOCK', 'CU_PD_EMPTY',
    'CU_OUT_OF_STOCK', 'CU_DELETED', 'CU_LOT_SIZE'];
  invData = invData?.map((x) => {
    let obj = { ...x };
    if (!obj) obj = {};
    groups.forEach((col) => {
      if (x?.[col] !== false && x?.[col] !== 'false' && !x?.[col]) {
        obj[col] = false;
      }
    });
    return obj;
  });
  invData = widgetDataGrouping(invData, [...groups, 'STATUS_LIGHT'], ['NUMBER_OF_RECORDS']);
  groups.forEach((grp) => {
    const recs = invData?.filter((rec) => rec?.[grp]);
    recs.forEach((x) => {
      const percentage = labelMapping[x.STATUS_LIGHT];
      const records = `${x.STATUS_LIGHT}_records`.toUpperCase();
      const color = `${x.STATUS_LIGHT}_color`.toUpperCase();
      // const inventory = `${x.STATUS_LIGHT}_inventory`.toUpperCase();
      const grpName = mapping[grp] || grp;
      const xAxis = formattedData.find((item) => item.GROUP === grpName);
      if (xAxis) {
        xAxis[records] = (xAxis[records] ?? 0) + x.NUMBER_OF_RECORDS;
        xAxis[percentage] = xAxis[records];
        xAxis[color] = x.STATUS_LIGHT;
      } else {
        const newIndex = { GROUP: grpName };
        newIndex[records] = (newIndex[records] ?? 0) + x.NUMBER_OF_RECORDS;
        newIndex[percentage] = newIndex[records];
        newIndex[color] = x.STATUS_LIGHT;
        formattedData.push(newIndex);
      }
    });
  });
  formattedData = getFormattedData(formattedData);
  formattedData = formattedData.sort((a, b) => {
    const totalA = (a?.[types.APPROVED] ?? 0) + (a?.[types.NOT_APPROVED] ?? 0)
      + (a?.[types.UNKNOWN] ?? 0);
    const totalB = (b?.[types.APPROVED] ?? 0) + (b?.[types.NOT_APPROVED] ?? 0)
      + (b?.[types.UNKNOWN] ?? 0);
    return totalB - totalA;
  });
  return formattedData;
};

const getOptInvCritOrSPData = (inv, index, filters) => {
  let formattedData = [];
  let invData = filterWidgetData(inv, filters);
  let indexName = index;
  const groups = ['STATUS_LIGHT'];
  const keysToMerge = ['NUMBER_OF_RECORDS'];
  if (index === 'CRITICALITY') {
    indexName = 'CC_MAX_CRITICALITY';
  }
  if (index === 'SUPPLY_PROCESS') {
    indexName = index;
  } else if (index === 'GROUP') {
    indexName = 'CC_CONSUMPTION_SEGMENT_GROUP';
  } else if (index === 'ROP_STATUS') {
    indexName = 'ROP_STATUS';
    keysToMerge.push('ROP_DEVIATION');
  }
  if (index !== 'ROP_STATUS') {
    keysToMerge.push('PERCENTAGE');
  }
  keysToMerge.push('INVENTORY');
  groups.push(indexName);
  invData = widgetDataGrouping(invData, groups, keysToMerge);
  const totNoOfRecs = invData?.reduce((prev, cur) => prev + cur?.NUMBER_OF_RECORDS ?? 0, 0);
  invData.forEach((x) => {
    const percentage = labelMapping[x.STATUS_LIGHT];
    const records = `${x.STATUS_LIGHT}_records`.toUpperCase();
    const color = `${x.STATUS_LIGHT}_color`.toUpperCase();
    const inventory = `${x.STATUS_LIGHT}_inventory`.toUpperCase();
    const xAxis = formattedData.find((item) => item[index] === x[indexName]);
    let tempPercent = 0;
    if (xAxis) {
      tempPercent = (((x.NUMBER_OF_RECORDS ?? 0) / (totNoOfRecs || 1)) * 100)?.toFixed(1);
      xAxis[percentage] = Number.parseFloat(tempPercent);
      if (index === 'ROP_STATUS') {
        xAxis[`ROP_DEVIATION_${x.STATUS_LIGHT}`] = x.ROP_DEVIATION;
      }
      xAxis[inventory] = x.INVENTORY;
      xAxis[records] = x.NUMBER_OF_RECORDS;
      xAxis[color] = x.STATUS_LIGHT;
    } else {
      const newIndex = { [index]: x[indexName] };
      tempPercent = (((x.NUMBER_OF_RECORDS ?? 0) / (totNoOfRecs || 1)) * 100)?.toFixed(1);
      newIndex[percentage] = Number.parseFloat(tempPercent);
      if (index === 'ROP_STATUS') {
        newIndex[`ROP_DEVIATION_${x.STATUS_LIGHT}`] = x.ROP_DEVIATION;
      }
      newIndex[inventory] = x.INVENTORY;
      newIndex[records] = x.NUMBER_OF_RECORDS;
      newIndex[color] = x.STATUS_LIGHT;
      formattedData.push(newIndex);
    }
  });
  formattedData = getFormattedData(formattedData);
  formattedData = formattedData.sort((a, b) => {
    const totalA = (a?.[types.APPROVED] ?? 0) + (a?.[types.NOT_APPROVED] ?? 0)
      + (a?.[types.UNKNOWN] ?? 0);
    const totalB = (b?.[types.APPROVED] ?? 0) + (b?.[types.NOT_APPROVED] ?? 0)
      + (b?.[types.UNKNOWN] ?? 0);
    return totalB - totalA;
  });
  return formattedData;
};

const lookup = (key) => {
  switch (key) {
    case 'According to strategy':
    case 'Approved deviation':
    case 'Fast moving':
    case 'None':
      return colors.GREEN;
    case 'ABC other':
      return colors.YELLOW;
    case 'Not tied to tag':
    case 'Low criticality':
    case 'Medium criticality':
      return colors.RED;
    default:
      return 'gray';
  }
};

const getOptInvStrategyData = (inv, filters) => {
  let invData = filterWidgetData(inv, filters);
  invData = widgetDataGrouping(invData, ['status'], ['count', 'inventory']);
  const totalCount = invData.reduce((prev, curr) => prev + curr.count, 0);
  let formattedData = invData.map((x) => {
    const percentage = (((x.count * 100) / totalCount).toFixed(2));
    return {
      id: x.status,
      label: x.status,
      count: x.count,
      value: percentage,
      inventory: x.inventory,
      color: lookup(x.status),
    };
  });
  formattedData = formattedData?.sort((a, b) => (b?.value ?? 0) - (a?.value ?? 0));
  return formattedData;
};

const getSampleExcessStock = () => {
  const keys = ['Excess V1 EX', 'Excess V1 HB/ZB', 'Excess PD'];
  return keys.map((key) => ({
    RED: 0,
    GREEN: 0,
    GREEN_COLOR: 'Green',
    GREEN_INVENTORY: 0,
    GREEN_RECORDS: 0,
    GROUP: key,
    YELLOW: 0,
    RED_COLOR: 'Red',
    RED_INVENTORY: 0,
    RED_RECORDS: 0,
    YELLOW_COLOR: 'Yellow',
    YELLOW_INVENTORY: 0,
    YELLOW_RECORDS: 0,
  }));
};

const getSampleGroupOrSPOrCrit = (id, index) => {
  let keys = [];
  if (id === widgetTypeKeys.cleanUpWidget) {
    keys = ['MRP Type', 'ND Stock', 'PD Empty', 'Out of Use', 'Deleted', 'Lot Size'];
  }
  if (id === widgetTypeKeys.optimalInventoryByCrit) {
    keys = ['3', '2', '6', 'NULL', 'A', '1', '5', '4', '9', 'N', '8', '7', 'Z', 'BLANK'];
  }
  if (id === widgetTypeKeys.optimalInventoryByGroup) {
    keys = ['NM-D', 'MM', 'SM', 'NM-O', 'NM-Y', 'NU', 'NEW', 'NAP', 'FM'];
  }
  if (id === widgetTypeKeys.optimalInventoryBySP) {
    keys = ['SP-Stock', 'SP2', 'SP3', 'SP1', 'SP4', 'SP-NonStock'];
  }
  if (id === widgetTypeKeys.optimalInventoryByROP) {
    keys = ['Optimal ROP', 'Remove ROP', 'Reduce ROP', 'Increase ROP', 'Add ROP'];
  }

  return keys.map((key) => ({
    'Approved stock': 0,
    'Criticality unknown': 0,
    GREEN_COLOR: 'Green',
    GREEN_INVENTORY: 0,
    GREEN_RECORDS: 0,
    [index]: key,
    'Not approved stock': 0,
    RED_COLOR: 'Red',
    RED_INVENTORY: 0,
    RED_RECORDS: 0,
    YELLOW_COLOR: 'Yellow',
    YELLOW_INVENTORY: 0,
    YELLOW_RECORDS: 0,
  }));
};

const getSampleOptInvStrategyData = () => {
  const keys = ['According to strategy', 'Low criticality', 'Not tied to tag',
    'Approved deviation', 'Medium criticality', 'ABC other', 'None'];
  return keys.map((key) => ({
    color: '#00977b',
    count: 0,
    id: key,
    inventory: '',
    label: key,
    value: 0,
  }));
};

const getExcessStockData = (inventory, filters) => {
  let formattedData = [{
    GROUP: 'Excess PD',
  }, {
    GROUP: 'Excess V1 EX',
  }, {
    GROUP: 'Excess V1 HB/ZB',
  }];
  let invData = filterWidgetData(inventory, filters);
  invData = widgetDataGrouping(invData, ['STATUS_LIGHT'],
    ['NumberOfRecords', 'Excess PD', 'Excess V1 EX', 'Excess V1 HB/ZB', 'PercentageOfTotal', 'INVENTORY_VALUE']);
  if (!invData?.length) return invData;
  const totNoOfRecs = invData?.reduce((prev, cur) => prev + cur?.NumberOfRecords ?? 0, 0);
  formattedData.forEach((x, i) => {
    invData.forEach((inv) => {
      let tempPercent = 0;
      tempPercent = (((inv?.[x.GROUP] ?? 0) / (totNoOfRecs || 1)) * 100)?.toFixed(1);
      formattedData[i][inv?.STATUS_LIGHT?.toUpperCase()] = Number.parseFloat(tempPercent);
      formattedData[i][inv?.STATUS_LIGHT?.toUpperCase().concat('_RECORDS')] = inv[x.GROUP];
      let invVal = (inv[formattedData[i]?.GROUP] / inv?.NumberOfRecords) * inv?.INVENTORY_VALUE;
      invVal = Math.round((invVal + Number.EPSILON) * 10) / 10;
      formattedData[i][inv?.STATUS_LIGHT?.toUpperCase().concat('_INVENTORY')] = invVal;
      formattedData[i][inv?.STATUS_LIGHT?.toUpperCase().concat('_COLOR')] = inv?.STATUS_LIGHT;
    });
  });
  formattedData = formattedData.sort((a, b) => {
    const totalA = (a.GREEN ?? 0) + (a.YELLOW ?? 0) + (a.RED ?? 0);
    const totalB = (b.GREEN ?? 0) + (b.YELLOW ?? 0) + (b.RED ?? 0);
    return totalB - totalA;
  });
  return formattedData;
};

const getOptimalPercent = (data) => {
  const tot = data?.reduce(((prev, curr) => prev + (curr.count ?? 0)), 0);
  const green = data?.filter((e) => e.status === 'Green').reduce(((prev, curr) => prev + (curr.count ?? 0)), 0);
  const yellow = data?.filter((e) => e.status === 'Yellow').reduce(((prev, curr) => prev + (curr.count ?? 0)), 0);
  const red = data?.filter((e) => e.status === 'Red').reduce(((prev, curr) => prev + (curr.count ?? 0)), 0);
  const greenPercent = ((green ?? 0) / (tot || 1)) * 100;

  const percent = {
    green: greenPercent,
    [green ?? 0]: greenPercent,
    [yellow ?? 0]: ((yellow ?? 0) / tot) * 100,
    [red ?? 0]: ((red ?? 0) / tot) * 100,
  };
  return percent;
};

const getOptInvTot = (inventoryStatus, filters) => {
  const data = filterWidgetData(inventoryStatus?.data, filters, 'status');
  return getOptimalPercent(data);
};

const getRandomColor = () => {
  const getRandomVal = (max) => Number.parseInt(Math.random() * max, 10);
  return `rgb(${getRandomVal(255)},${getRandomVal(255)},${getRandomVal(255)})`;
};

const secodTransformation = (groups) => {
  const tempFormatted = [];
  groups.forEach((line) => {
    let index = -1;
    line.data?.forEach((x) => {
      if (index === -1) {
        if (x.INVENTORY_DATE) {
          tempFormatted.push({
            ...line,
            data: [{
              date: x.INVENTORY_DATE,
              nodeData: [
                { ...x },
              ],
            }],
          });
          index = tempFormatted.length - 1;
        }
      } else if (x.INVENTORY_DATE) {
        const dataObj = tempFormatted[index].data.find(
          (o) => o.date && o.date === x.INVENTORY_DATE,
        );
        if (dataObj) {
          dataObj.nodeData.push({ ...x });
        } else {
          tempFormatted[index].data.push({
            date: x.INVENTORY_DATE,
            nodeData: [
              { ...x },
            ],
          });
        }
      }
    });
  });
  return tempFormatted;
};

const thirdTranformation = (formatted, widgetId) => {
  const finalData = [];
  let count;
  formatted.forEach((line) => {
    let index = -1;
    line.data.forEach((node) => {
      let yVal = 0;
      const dataObj = {};
      if (widgetId === widgetTypeKeys.inventoryValByTime) {
        yVal = node?.nodeData?.reduce((prev, curr) => prev + curr?.total, 0);
        count = node?.nodeData
          ?.reduce((prev, curr) => (prev + Number.parseInt(curr?.NO_OF_RECORDS, 10)), 0);
        dataObj.count = count;
      } else {
        const temp = widgetDataGrouping(node?.nodeData, ['status'], ['total']);
        const findRow = (color) => temp?.find((x) => x?.status === color);
        const total = (findRow('Green')?.total ?? 0)
          + (findRow('Yellow')?.total ?? 0)
          + (findRow('Red')?.total ?? 0);

        yVal = ((findRow('Green')?.total ?? 0) / total) * 100;
      }
      if (index === -1) {
        finalData.push({
          ...line,
          data: [{
            ...dataObj,
            x: node.date,
            y: yVal,
          }],
        });
        index = finalData.length - 1;
      } else {
        finalData[index].data.push({
          ...dataObj,
          x: node.date,
          y: yVal,
        });
      }
    });
  });
  return finalData;
};

const getOptInvByTimeData = (inventoryStatus, filters, widgetId, dashboardMode) => {
  let finalGrps = [];
  if (inventoryStatus.length) {
    inventoryStatus.forEach((grp) => {
      const grpName = Object.keys(grp)?.[0];
      if (!grpName) return;
      let plantData = grp?.[grpName]?.data;
      if (grpName === 'singles') {
        plantData = filterWidgetData(plantData, filters, 'status');
        let plantOrCC = 'CC';
        if (dashboardMode === MODES.PLANT || !grp?.[grpName]?.companyCodes?.length) plantOrCC = 'PLANT';
        plantData = widgetDataGrouping(plantData,
          [plantOrCC, 'status', 'INVENTORY_DATE'],
          ['total', 'NO_OF_RECORDS']);
        let list = [];
        const timePeriods = ['WEEK', 'MONTH', 'YEAR'];
        if (dashboardMode === MODES.PLANT) {
          list = grp?.[grpName]?.plants?.filter(
            (x) => !timePeriods.includes(x),
          );
        } else {
          list = grp?.[grpName]?.companyCodes?.filter(
            (x) => !timePeriods.includes(x),
          );
        }
        let tempFormatted = [];
        // 1st transformation. Creating lines for Chart where each line corresponds to a plant
        list.forEach((p) => {
          const tempData = plantData.filter((x) => {
            if (dashboardMode === MODES.PLANT) {
              return p && x?.PLANT?.toString() === p;
            }
            return p && x?.CC?.toString() === p;
          });
          tempFormatted.push({
            id: p,
            data: [...tempData],
            color: getRandomColor(),
          });
        });
        // 2nd Transformation. Segregate data of each line of chart based on DATE.
        tempFormatted = secodTransformation(tempFormatted);
        // 3rd Transformation. Calculate Y axis points and create nodes for each line/Plant.
        tempFormatted = thirdTranformation(tempFormatted, widgetId);
        finalGrps = [...finalGrps, ...tempFormatted];
      } else {
        plantData = filterWidgetData(plantData, filters, 'status');
        plantData = widgetDataGrouping(plantData, ['status', 'INVENTORY_DATE'], ['total', 'NO_OF_RECORDS']);
        let tempFormatted = {
          id: grpName,
          data: [...plantData],
          color: getRandomColor(),
        };
        tempFormatted = secodTransformation([tempFormatted]);
        tempFormatted = thirdTranformation(tempFormatted, widgetId);
        finalGrps = [...finalGrps, ...tempFormatted];
      }
    });
  } else return [];
  finalGrps = finalGrps?.map((grp) => {
    const grpData = grp?.data?.sort((a, b) => (new Date(a.x) > new Date(b.x) ? 1 : -1))
      ?.map((data) => ({ ...data, x: data.x?.replaceAll('-', '.') }));
    const temp = {
      ...grp,
      data: grpData,
    };
    return temp;
  });
  finalGrps = finalGrps?.sort(
    (a, b) => (new Date(a?.data?.[0]?.x) > new Date(b?.data?.[0]?.x) ? 1 : -1),
  );
  return finalGrps;
};

const getMonetaryImpactTot = (inventoryStatus, filters) => {
  const data = filterWidgetData(inventoryStatus?.data, filters);
  const newData = [];
  if (!data?.length) return [];
  newData.push({
    Impact: data?.filter((x) => x?.MONETARY_IMPACT_TYPE === 'Investment')?.reduce(
      ((prev, curr) => prev + curr?.Impact), 0,
    ) ?? 0,
    MONETARY_IMPACT_TYPE: 'Investment',
  });
  newData.push({
    Impact: data?.filter((x) => x?.MONETARY_IMPACT_TYPE === 'Savings')?.reduce(
      ((prev, curr) => prev + curr?.Impact), 0,
    ) ?? 0,
    MONETARY_IMPACT_TYPE: 'Savings',
  });
  return newData;
};

const getMax = (data) => (
  (data?.[types.APPROVED] ?? 0) + (data?.[types.NOT_APPROVED] ?? 0) + (data?.[types.UNKNOWN] ?? 0)
  * 1.2)?.toFixed(0);

const getExcessStockMax = (data) => (
  (data?.GREEN ?? 0) + (data?.RED ?? 0) + (data?.YELLOW ?? 0)
  * 1.2)?.toFixed(0);

const indexName = {
  optimalInventoryByCrit: 'CC_MAX_CRITICALITY',
  optimalInventoryBySP: 'SUPPLY_PROCESS',
  optimalInventoryByGroup: 'CC_CONSUMPTION_SEGMENT_GROUP',
  optimalInventoryByStrategy: 'INVENTORY_STRATEGY_STATUS',
};

const cleanUpFilters = (e, obj) => {
  let filterObject = { ...obj };
  const mapping = {
    'MRP Type': 'CU_MRP_TYPE',
    'ND Stock': 'CU_ND_STOCK',
    'PD Empty': 'CU_PD_EMPTY',
    'Out of Use': 'CU_OUT_OF_STOCK',
    Deleted: 'CU_DELETED',
    'Lot Size': 'CU_LOT_SIZE',
  };
  const indexValue = mapping[e.indexValue];
  filterObject.STATUS_LIGHT[0].FilterValue[0] = defaultColorMapping[e.id];
  if (e.indexValue === 'MRP Type') {
    filterObject = {
      ...filterObject,
      MRP_TYPE_SAP_CD: [
        {
          ColumnName: 'MRP_TYPE_SAP_CD',
          FilterOperator: 'Not',
          FilterValue: [
            'ND',
            'PD',
            'V1',
            'ZP',
          ],
        },
      ],
    };
    return filterObject;
  }
  if (e.indexValue === 'Lot Size') {
    filterObject = {
      ...filterObject,
      LOT_SIZE_SAP_CD: [
        {
          ColumnName: 'LOT_SIZE_SAP_CD',
          FilterOperator: 'Not',
          FilterValue: [
            'EX',
            'HB',
            'ZB',
            'FX',
            'None',
          ],
        },
      ],
      MRP_TYPE_SAP_CD: [
        {
          ColumnName: 'MRP_TYPE_SAP_CD',
          FilterOperator: 'EqualTo',
          FilterValue: [
            'V1',
            'PD',
            'ZP',
          ],
        },
      ],
    };
    return filterObject;
  }
  if (e.indexValue === 'ND Stock') {
    filterObject = {
      ...filterObject,
      CC_ND_STOCK: [
        {
          ColumnName: 'CC_ND_STOCK',
          FilterOperator: 'GreaterThan',
          FilterValue: ['0'],
        },
      ],
    };
    return filterObject;
  }

  filterObject = {
    ...filterObject,
    [indexValue]: [
      {
        ColumnName: indexValue,
        FilterOperator: 'EqualTo',
        FilterValue: [
          'True',
        ],
      },
    ],
  };
  return filterObject;
};

const getFiltersForMatList = (e, widgetID, colorMapping) => {
  let obj = {};
  if (widgetID !== widgetTypeKeys.optimalInventoryByStrategy) {
    obj = {
      STATUS_LIGHT: [
        {
          ColumnName: 'STATUS_LIGHT',
          FilterOperator: 'EqualTo',
          FilterValue: [
            colorMapping[e.id],
          ],
        },
      ],
    };
  }
  if (widgetID === widgetTypeKeys.cleanUpWidget) {
    const filters = cleanUpFilters(e, obj);
    obj = { ...obj, ...filters };
  } else if (widgetID === widgetTypeKeys.excessStockByStatus) {
    const mapping = {
      'Excess V1 EX': 'EXCESS_V1_EX',
      'Excess V1 HB/ZB': 'EXCESS_V1_HBZB',
      'Excess PD': 'EXCESS_PD',
    };
    const indexValue = mapping[e.indexValue];
    obj.STATUS_LIGHT[0].FilterValue[0] = e.id;
    obj = {
      ...obj,
      TOTAL_EXCESS_STOCK: [
        {
          ColumnName: 'TOTAL_EXCESS_STOCK',
          FilterOperator: 'GreaterThan',
          FilterValue: [
            '0',
          ],
        },
      ],
      [indexValue]: [
        {
          ColumnName: indexValue,
          FilterOperator: 'Not',
          FilterValue: [
            0,
          ],
        },
      ],
    };
  } else if (widgetID === widgetTypeKeys.optimalInventoryByROP) {
    obj.STATUS_LIGHT[0].FilterValue[0] = defaultColorMapping[e.id];
    obj = {
      ...obj,
      ROP_STATUS: [
        {
          ColumnName: 'ROP_STATUS',
          FilterOperator: 'EqualTo',
          FilterValue: [
            e.indexValue,
          ],
        },
      ],
      DISPLAY_REC_IND: [
        {
          ColumnName: 'DISPLAY_REC_IND',
          FilterOperator: 'EqualTo',
          FilterValue: [
            'True',
          ],
        },
      ],
    };
  } else {
    obj = {
      ...obj,
      TOT_STOCK: [
        {
          ColumnName: 'TOT_STOCK',
          FilterOperator: 'GreaterThan',
          FilterValue: [
            '0',
          ],
        },
      ],
      [indexName[widgetID]]: [
        {
          ColumnName: indexName[widgetID],
          FilterOperator: 'EqualTo',
          FilterValue: [
            e.indexValue,
          ],
        },
      ],
    };
  }
  return obj;
};

const getDashboardFilters = (dashboardFilter) => {
  if (!dashboardFilter) return {};
  const tempFilters = dashboardFilter;
  const filterKeys = Object.keys(tempFilters?.filterVals || {});
  if (!filterKeys?.length) return {};
  const filterObj = {};
  filterKeys.forEach((key) => {
    const vals = tempFilters.filterVals[key];
    if (key && vals?.length) {
      filterObj[key] = [{
        ColumnName: key,
        FilterOperator: tempFilters.searchOperators?.[key],
        FilterValue: [...vals],
      }];
    }
  });
  return filterObj;
};

const hasDataAfterFilter = (filteredData) => {
  /* If sum of records is 0, we have filtered out all data */
  let sumOfRecords = 0;
  filteredData.forEach((f) => {
    sumOfRecords += ((f.count || 0)
      + (f.GREEN_INVENTORY || 0)
      + (f.GREEN_RECORDS || 0)
      + (f.RED_RECORDS || 0)
      + (f.YELLOW_RECORDS || 0));
  });
  return !!sumOfRecords;
};

export {
  getOptInvCritOrSPData,
  getCleanUpWidgetData,
  getOptInvStrategyData,
  getExcessStockData,
  getOptInvTot,
  getMax,
  getExcessStockMax,
  getFiltersForMatList,
  filterWidgetData,
  getSampleOptInvStrategyData,
  getSampleGroupOrSPOrCrit,
  getSampleExcessStock,
  getMonetaryImpactTot,
  getDashboardFilters,
  getOptInvByTimeData,
  hasDataAfterFilter,
};
