import React, { useEffect, useState } from 'react';
import {
  PageHeader, Card, Row, Tooltip, Empty, Button, Select,
} from 'antd';
import {
  InfoCircleOutlined,
} from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import ScenarioResult from './ScenarioResult';
import allActions from '../../../actions';
import { retrieveSimulations as loadingKey } from '../../../actions/SimulationDomain/LoadingKeys';
import getStatusDisplay from '../../Common/Simulation/SimulationStatus';
import ScenarioForm from './ScenarioForm';
import { SIM_TYPES } from '../../Common/GlobalConstants';
import { localDateTimeFormat } from '../../Common/DateAndNumberFunctions';
import SimInputDisplay from './SimInputDisplay';

const { Option } = Select;

const Scenario = () => {
  const [plant, setPlant] = useState();
  const storedPlantIDs = useSelector((state) => state.commonState.userSettings?.DEFAULT_PLANTS);
  const [searchMMR, setSearchMMR] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [simulatedValues, setSimulatedValues] = useState([]);
  const loading = useSelector((state) => state.simState.loading);
  const uniqueId = useSelector((state) => state.authState?.user?.uniqueId);
  const userEmail = useSelector((state) => state.authState.user?.account?.username);
  const allSimJobs = useSelector((state) => state.simState.allSimJobs);
  const [selectedSim, setSelectedSim] = useState();
  const [relevantSimJobs, setRelevantSimJobs] = useState([]);
  const dispatch = useDispatch();

  const simulationResults = useSelector((state) => state.simState.simResults);

  useEffect(() => {
    if (allSimJobs && Object.keys(allSimJobs)?.length > 0) {
      const relSimJobs = Object.values(allSimJobs).filter(
        (job) => job.Type === SIM_TYPES.SCENARIO,
      );
      relSimJobs.sort((a, b) => new Date(b.RunTimeStamp) - new Date(a.RunTimeStamp));
      if (relSimJobs?.length > 0) {
        const mostRecent = relSimJobs[0];
        setSelectedSim(mostRecent.RunID);
      } else {
        setSelectedSim(null);
      }
      setRelevantSimJobs(relSimJobs);
    } else {
      setRelevantSimJobs([]);
    }
  }, [allSimJobs]);

  useEffect(() => {
    if (selectedSim) {
      setShowResults(false);
      dispatch(allActions.SimulationActions.retrieveSimulation(selectedSim));
    }
  }, [selectedSim]);

  useEffect(() => {
    if (!storedPlantIDs || storedPlantIDs[0] === undefined) return;
    setPlant(storedPlantIDs[0]);
  }, [storedPlantIDs]);

  const handleMMRSearch = () => {
    // console.log(searchMMR);
  };

  function handleChange(event) {
    if (event) setPlant(event.key);
  }

  function clearPlant() {
    setPlant(null);
  }

  const checkForResults = () => {
    dispatch(allActions.SimulationActions.setLoading(loadingKey));
    setShowResults(false);
    dispatch(allActions.SimulationActions.getAllSimJobs(userEmail));
    if (selectedSim) dispatch(allActions.SimulationActions.retrieveSimulation(selectedSim));
  };

  const onFinish = (values) => {
    if (searchMMR?.length > 0) {
      dispatch(allActions.SimulationActions.simulate(
        plant, searchMMR, { ...values, mrp_type: 'V1' }, userEmail, SIM_TYPES.SCENARIO, uniqueId,
      ));
      setShowResults(false);
    }
  };

  useEffect(() => {
    if (simulationResults?.[selectedSim]?.results) {
      setShowResults(true);
      setSimulatedValues(JSON.parse(JSON.stringify(simulationResults[selectedSim].results).replace(/Null/g, 'null')));
    }
  }, [simulationResults, selectedSim]);

  const title = (
    <div>
      {'Scenario '}
      <Tooltip placement="rightTop" title="Run a scenario for a single plant, using a list of materials with new values">
        <InfoCircleOutlined style={{ fontSize: '14px' }} />
      </Tooltip>
    </div>
  );

  return (
    <PageHeader
      title={title}
      ghost={false}
      style={{ width: '100%' }}
    >
      <Row>
        <Card
          title="Enter values"
          bodyStyle={{ paddingTop: '16px' }}
        >
          <ScenarioForm
            plant={plant}
            handleChange={handleChange}
            clearPlant={clearPlant}
            searchMMR={searchMMR}
            setSearchMMR={setSearchMMR}
            handleMMRSearch={handleMMRSearch}
            onFinish={onFinish}
          />
        </Card>
      </Row>
      <Row>
        <Card
          title="Results"
          style={{ width: '100%' }}
        >
          {
            showResults
              ? (
                <>
                  <SimInputDisplay simID={selectedSim} />
                  <ScenarioResult simulatedData={simulatedValues} />
                </>
              )
              : (
                <>
                  {simulationResults?.[selectedSim]?.status
                    ? getStatusDisplay(simulationResults[selectedSim])
                    : <Empty description="No simulation data found" />}
                </>
              )
          }
          <Select
            disabled={!relevantSimJobs.length}
            style={{ margin: '8px 8px 0 0', width: 250 }}
            value={selectedSim}
            onChange={(value) => setSelectedSim(value)}
          >
            {relevantSimJobs.map((job) => (
              <Option key={job.RunID} value={job.RunID}>
                {localDateTimeFormat(new Date(job.RunTimeStamp))}
                {` - (${job.RunID})`}
              </Option>
            ))}
          </Select>
          <Button
            style={{ margin: '8px 0px' }}
            type="primary"
            onClick={checkForResults}
            loading={loading?.[loadingKey] ?? false}
          >
            Check for latest simulation results
          </Button>
        </Card>
      </Row>
    </PageHeader>
  );
};

export default Scenario;
