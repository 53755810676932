// eslint-disable-next-line import/prefer-default-export
export const optInvTot = 'OPT_INV_TOT_DATA';
export const inventoryValue = 'INVENTORY_VALUE';
export const optInvGroup = 'OPT_INV_GROUP';
export const optInvStrategy = 'OPT_INV_STRATEGY';
export const monetaryImpact = 'MONETARY_IMPACT';
export const excessStockStatus = 'EXCESS_STOCK_BY_STATUS';
export const totalExcessStock = 'TOTAL_EXCESS_STOCK_VALUE';
export const optInvSP = 'OPT_INV_SP';
export const optInvCrit = 'OPT_INV_CRIT';
export const optInvROP = 'OPT_INV_ROP';
export const optimalInvTime = 'OPT_INV_TIME';
export const inventoryValTime = 'INV_VAL_TIME';
export const plannedOrders = 'PLANNED_ORDERS';
export const cleanUpWidget = 'CLEAN_UP_WIDGET';
