const widgetGroups = {
  cleanUpWidget: [
    { key: 'CU_MRP_TYPE', CU_MRP_TYPE: 'MRP Type' },
    { key: 'CU_ND_STOCK', CU_ND_STOCK: 'ND Stock' },
    { key: 'CU_PD_EMPTY', CU_PD_EMPTY: 'PD Empty' },
    { key: 'CU_OUT_OF_STOCK', CU_OUT_OF_STOCK: 'Out of Use' },
    { key: 'CU_DELETED', CU_DELETED: 'Deleted' },
    { key: 'CU_LOT_SIZE', CU_LOT_SIZE: 'Lot Size' },
  ],
  optimalInventoryByStrategy: [
    { key: 'ABC_other', ABC_other: 'ABC other' },
    { key: 'According_to_strategy', According_to_strategy: 'According to strategy' },
    { key: 'Approved_deviation', Approved_deviation: 'Approved deviation' },
    { key: 'Low_criticality', Low_criticality: 'Low criticality' },
    { key: 'Medium_criticality', Medium_criticality: 'Medium criticality' },
    { key: 'Missing_Status', Missing_Status: 'Missing Status' },
    { key: 'None', None: 'None' },
    { key: 'Not_tied_to_tag', Not_tied_to_tag: 'Not tied to tag' },
  ],
  optimalInventoryByGroup: [
    { key: 'FM', FM: 'FM' }, { key: 'MM', MM: 'MM' },
    { key: 'NAP', NAP: 'NAP' }, { key: 'NEW', NEW: 'NEW' },
    { key: 'NM-D', 'NM-D': 'NM-D' }, { key: 'NM-O', 'NM-O': 'NM-O' },
    { key: 'NM-Y', 'NM-Y': 'NM-Y' }, { key: 'NU', NU: 'NU' },
    { key: 'OTH', OTH: 'OTH' }, { key: 'SM', SM: 'SM' },
  ],
  excessStockByStatus: [
    { key: 'EXCESS_V1_EX', EXCESS_V1_EX: 'Excess V1 EX' },
    { key: 'EXCESS_V1_HBZB', EXCESS_V1_HBZB: 'Excess V1 HB/ZB' },
    { key: 'EXCESS_PD', EXCESS_PD: 'Excess PD' },
  ],
  optimalInventoryByCrit: [
    { key: '1', 1: '1' }, { key: '2', 2: '2' }, { key: '3', 3: '3' },
    { key: '4', 4: '4' }, { key: '5', 5: '5' }, { key: '6', 6: '6' },
    { key: '7', 7: '7' }, { key: '8', 8: '8' }, { key: '9', 9: '9' },
    { key: 'A', A: 'A' }, { key: 'N', N: 'N' }, { key: 'Y', Y: 'Y' },
    { key: 'Z', Z: 'Z' }, { key: 'BLANK', BLANK: 'BLANK' }, { key: 'None', None: 'None' },
  ],
  optimalInventoryBySP: [
    { key: 'SP1', SP1: 'SP1' }, { key: 'SP2', SP2: 'SP2' },
    { key: 'SP3', SP3: 'SP3' }, { key: 'SP4', SP4: 'SP4' },
    { key: 'SP5', SP5: 'SP5' }, { key: 'SP6', SP6: 'SP6' },
  ],
  plannedOrders: [
    {
      key: 'openWithInsufficient',
      openWithInsufficient: 'Open reservations with incoming coverage',
    },
    {
      key: 'openWithSufficient',
      openWithSufficient: 'Open reservations with insufficient coverage',
    },
    {
      key: 'openWithIncoming',
      openWithIncoming: 'Open reservations with sufficient coverage',
    },
    {
      key: 'replenishment',
      replenishment: 'Replenishment only',
    },
  ],
  monetaryImpact: [
    { key: 'Investment', Investment: 'Investment' }, { key: 'Savings', Savings: 'Savings' },
  ],
  optimalInventoryByROP: [
    { key: 'Add_ROP', Add_ROP: 'Add ROP' },
    { key: 'Increase_ROP', Increase_ROP: 'Increase ROP' },
    { key: 'No_ROP', No_ROP: 'No ROP' },
    { key: 'Optimal_ROP', Optimal_ROP: 'Optimal ROP' },
    { key: 'Reduce_ROP', Reduce_ROP: 'Reduce ROP' },
    { key: 'Remove_ROP', Remove_ROP: 'Remove ROP' },
  ],
};

const widgetViewVals = {
  all: '#All',
  delete: '#DELETE',
};

export {
  widgetGroups,
  widgetViewVals,
};
