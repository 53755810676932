import * as materialTypes from '../actions/MaterialMasterDomain/MaterialMasterActionTypes';
import { NUMBER_OF_RECORDS_TO_FETCH } from '../components/Common/GlobalConstants';

export default function materialMasterReducer(state = { searchID: '' }, action) {
  switch (action.type) {
    case materialTypes.receiveMM:
      if (action.payload.searchID !== state.searchID) return state;
      return {
        ...state,
        isLoadingData: false,
        materialMasterData: action.payload.data,
        atEndofResults:
          action.payload.data?.length
          < state.localMaterialViewStore?.scrollPage?.noOfRecordsToFetch,
      };

    case materialTypes.receiveMMDownload:
      return {
        ...state,
        isLoadingData: false,
        materialListDataDownload: action.payload,
      };

    case materialTypes.clearMMData:
      return {
        ...state,
        materialListData: [],
      };

    case materialTypes.clearMMStoreState:
      return {
        ...state,
        localMaterialViewStore: {
          ...state.localMaterialViewStore,
          headerCells: null,
          searchFilters: {},
          sortColumn: undefined,
          sortDirection: undefined,
          scrollPage: {
            currentNoOfRecords: 0,
            noOfRecordsToFetch: NUMBER_OF_RECORDS_TO_FETCH,
          },
        },
        displayHeaderCells: null,
        reset: true,
        scrollToRec: { ...state.scrollToRec, yAxis: 0 },
      };

    case materialTypes.resetMMComplete:
      return {
        ...state,
        reset: false,
      };

    case materialTypes.receiveMMColumns:
      return {
        ...state,
        materialListColumns: action.payload,
        loaded: { ...state.loaded, materialListColumns: true },
      };

    case materialTypes.setSelectedRowKeys:
      return {
        ...state,
        selectedRowKeys: action.payload,
      };

    case materialTypes.setMMCount:
      if (action.payload.searchID !== state.searchID) return state;
      return {
        ...state,
        mmSearchCount: action.payload.data,
      };

    case materialTypes.checkMMListCount:
      return {
        ...state,
        isLoadingData: false,
        checkMMListCount: !!action.payload,
      };

    case materialTypes.clearMMCount:
      return {
        ...state,
        mmSearchCount: null,
      };

    case materialTypes.storeMMStaticWLs:
      return {
        ...state,
        staticWorklists: action.payload,
      };

    case materialTypes.selectView:
      return {
        ...state,
        selectedView: action.payload,
      };

    case materialTypes.setLocalMMView:
      if (state.reset) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        localMaterialViewStore: action.payload,
      };

    case materialTypes.setMMDisplayHeaderCells:
      return {
        ...state,
        displayHeaderCells: action.payload,
      };

    case materialTypes.setLoading:
      return {
        ...state,
        isLoadingData: true,
      };

    case materialTypes.endLoading:
      return {
        ...state,
        isLoadingData: false,
      };

    case materialTypes.setSearchID:
      return {
        ...state,
        searchID: action.payload,
      };

    default:
      return state;
  }
}
