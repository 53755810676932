import axios from 'axios';
import { API } from '../actions/api/typeApi';
import * as materialResultType from '../actions/MaterialListDomain/MaterialListActionTypes';
import {
  accessDenied, apiError, apiStart, apiEnd,
} from '../actions/api/api';
import {
  showNotification,
} from '../actions/utilities/Notifications/NotificationActions';
import {
  showLoading,
  clearLoading,
} from '../actions/utilities/Spinner/LoadingActions';
import { appInsights } from './appInsights';
import allActions from '../actions';

const apiMiddleware = ({ dispatch, getState }) => (next) => (action) => {
  next(action);

  const getCorrectToken = () => {
    const { origin } = window;
    const useIdToken = origin.includes('localhost') || origin.includes('dev') || origin.includes('acc');
    if (useIdToken) {
      return getState().authState?.user?.idToken || null;
    }
    return getState().authState?.user?.accessToken || null;
  };

  if (action.type !== API) return;
  const token = getCorrectToken();
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const {
    url,
    method,
    params,
    data,
    onSuccess,
    onSuccessNotification,
    onFailure,
    onFailureNotification,
    label,
  } = action.payload;

  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL || '';
  axios.defaults.headers.common['Content-Type'] = 'application/json';
  axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

  if (label !== materialResultType.receiveMaterialListColumns) {
    dispatch(showLoading());
    dispatch(apiStart(label));
  }
  axios
    .request({
      url,
      method,
      headers,
      params,
      data,
    })
    .then(({ data: reply }) => {
      if (reply.success) {
        if (!label) {
          console.log('Missing label: ');
          console.log(url);
        } else {
          dispatch(allActions.TestActions.logTestResult(
            label, reply.success, reply?.error?.message,
          ));
        }
        if (onSuccessNotification) {
          dispatch(showNotification(onSuccessNotification));
        }
        // get onSuccess action to dispatch if the current action has one
        const nextAction = onSuccess(reply?.responseData);
        if (nextAction) dispatch(onSuccess(reply?.responseData));
      } else {
        dispatch(allActions.TestActions.logTestResult(label, false, reply?.error?.message));
        appInsights.trackException({
          error: reply?.error,
          properties: {
            url,
            method,
            params,
            code: reply?.error?.code,
            message: reply?.error?.message,
            procName: reply?.error?.procName,
            serverName: reply?.error?.serverName,
            data: JSON.stringify(data || ''),
          },
        });
        const nextAction = onFailure(reply?.error, reply?.responseData);
        if (nextAction) dispatch(onFailure(reply?.error, reply?.responseData));
        if (onFailureNotification) dispatch(showNotification(onFailureNotification(reply?.error)));
      }
    })
    .catch((error) => {
      dispatch(allActions.TestActions.logTestResult(label, false, error?.message));
      dispatch(apiError(error));
      if (onFailureNotification) dispatch(showNotification(onFailureNotification(error)));
      if (error?.response && error?.response?.status === 403) {
        dispatch(accessDenied(window.location.pathname));
      }
      const nextAction = onFailure(error);
      if (nextAction) dispatch(onFailure(error));
    })
    .finally(() => {
      if (label !== materialResultType.receiveMaterialListColumns) {
        dispatch(clearLoading());
      }
      if (label) {
        dispatch(apiEnd(label));
      }
    });
};

export default apiMiddleware;
