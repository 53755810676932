export const receiveMM = 'RECEIVE_MATERIAL_MASTER_DATA_RESULT';
export const fetchMMCount = 'GET_MATERIAL_MASTER_COUNT';
export const checkMMListCount = 'CHECK_MATERIAL_MASTER_LIST_RECORDS_COUNT';
export const receiveMMColumns = 'RECEIVE_MATERIAL_MASTER_COLUMNS_RESULT';
export const receiveMMDownload = 'RECEIVE_MATERIAL_MASTER_DATA_DOWNLOAD';
export const setLocalMMView = 'SET_LOCAL_MATERIAL_MASTER_VIEW';
export const selectView = 'SELECT_MM_VIEW';
export const setMMDisplayHeaderCells = 'SET_MATERIAL_MASTER_DISPLAYHEADERCELLS';
export const setLoading = 'SET_LOADING';
export const endLoading = 'END_LOADING';
export const setMMCount = 'SET_MATERIAL_MASTER_COUNT';
export const clearMMCount = 'CLEAR_MATERIAL_MASTER_COUNT';
export const clearMMData = 'CLEAR_MATERIAL_MASTER';
export const clearMMStoreState = 'SET_MATERIAL_MASTER_STATE_NULL';
export const resetMMComplete = 'RESET_COMPLETE';
export const setSearchID = 'SET_SEARCH_ID';
export const setSelectedRowKeys = 'SET_SELECTED_ROW_KEYS';
export const storeMMStaticWLs = 'STORE_MATERIAL_MASTER_STATIC_WORKLISTS';
export const deleteMMStaticWLs = 'DELETE_MATERIAL_MASTER_STATIC_WORKLISTS';
export const setDynamicWLCount = 'SET_DYNAMIC_WORKLIST_COUNT';
