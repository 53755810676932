import PropTypes from 'prop-types';
import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import allActions from '../../../actions';

const DelegateMenu = ({ data }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authState.user);
  const { plant, material } = useParams();
  const [delegateMenuVisible, setDelegateMenuVisible] = useState(false);
  const [isDelegated, setIsDelegated] = useState();
  const [delegationType, setDelegationType] = useState();
  const delType = {
    Automatic: 'Automatic',
    CoreTeam: 'Core Team',
    ManualMRP: 'Manual MRP',
  };

  useEffect(() => {
    const plantData = data?.find(
      (entry) => entry?.PLANT_FACILITY_SAP_ID === plant && entry?.MATERIAL_TYPE_SAP_ID === material,
    );
    const delegated = plantData?.Automatic || plantData?.CoreTeam || plantData.ManualMRP;
    setIsDelegated(delegated);
    if (delegated) {
      let delegateType;
      if (plantData?.Automatic) delegateType = delType.Automatic;
      else if (plantData?.CoreTeam) delegateType = delType.CoreTeam;
      else delegateType = delType.ManualMRP;
      setDelegationType(delegateType);
    } else {
      setDelegationType();
    }
  }, [data]);

  const delegate = (arg) => {
    setDelegateMenuVisible(false);
    dispatch(allActions.MaterialDetailsActions.delegate(
      plant,
      material,
      user?.account?.username,
      arg === delegationType ? '' : arg,
    ));
  };

  const style = (arg) => {
    if (delegationType === arg) return { backgroundColor: '#bae7ff' };
    return undefined;
  };

  const delegateMenu = (
    <Menu>
      <Menu.Item key="1" onClick={() => delegate(delType.Automatic)} style={style(delType.Automatic)}>
        {delType.Automatic}
      </Menu.Item>
      <Menu.Item key="2" onClick={() => delegate(delType.CoreTeam)} style={style(delType.CoreTeam)}>
        {delType.CoreTeam}
      </Menu.Item>
      <Menu.Item key="3" onClick={() => delegate(delType.ManualMRP)} style={style(delType.ManualMRP)}>
        {delType.ManualMRP}
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown overlay={delegateMenu} placement="bottomRight" visible={delegateMenuVisible}>
      <Button
        className="headerButton"
        size="small"
        type={isDelegated ? 'primary' : 'default'}
        onClick={() => setDelegateMenuVisible(!delegateMenuVisible)}
      >
        {isDelegated ? delegationType : 'Delegate'}
        <DownOutlined />
      </Button>
    </Dropdown>
  );
};

export default DelegateMenu;
DelegateMenu.defaultProps = {
  data: {},
};

DelegateMenu.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.any),
    PropTypes.arrayOf(PropTypes.any),
  ]),
};
