import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Skeleton } from 'antd';
import allActions from '../../../../actions';
import * as loadingKeys from '../../../../actions/DashboardDomain/LoadingKeys';
import { MODES } from '../../../Common/GlobalConstants';
import LineChartWidget from './LineChartWidget';
import styles from './styles.module.scss';

const HistoricalDataWidgetsContainer = ({
  canViewValues, widgetID, label, textWidgetID,
}) => {
  const dispatch = useDispatch();
  const selectedPlants = useSelector((state) => state.commonState.selectedPlants);
  const selectedCCs = useSelector((state) => state.commonState.selectedCompanyCodes);
  const dashboardMode = useSelector((state) => state.commonState.dashboardMode);
  const optimalInventory = useSelector((state) => state.dashboardState?.[widgetID]);
  const settingsLoaded = useSelector((state) => state.commonState?.loaded?.userSettings);
  const loading = useSelector((state) => state.dashboardState.loading);
  const [timePeriod, setTimePeriod] = useState('MONTH');
  const userSettings = useSelector((state) => state.commonState.userSettings);
  const grpsNdPlants = useSelector((state) => state.commonState.grpsNdPlants);
  const [inventoryStatus, setInventoryStatus] = useState({
    mode: null,
    data: null,
  });
  const SQLOnly = useSelector((state) => state.commonState?.SQLOnly?.dashboard);

  const selectedExists = (plantGroup = null, time, grouped) => {
    const tempTime = time ?? 'MONTH';
    let key = null;
    if (selectedCCs?.length === 0 && selectedPlants?.length === 0
        && optimalInventory && optimalInventory[`P${tempTime}`]) {
      return true;
    }
    if (dashboardMode === MODES.PLANT) {
      if (plantGroup) {
        // key = `P${JSON.parse(plantGroup)?.sort().join('')}${tempTime}GROUPED`; //Uncomment this
        // line if every data point need to contribution from every plant in the plant group select
        key = `P${JSON.parse(plantGroup)?.sort().join('')}${tempTime}${grouped ? 'GROUPED' : ''}`;
        return !!optimalInventory?.[key];
      }
      key = `P${selectedPlants?.sort().join('')}${tempTime}`;
      return !!optimalInventory?.[key];
    }
    key = `CC${selectedCCs?.sort().join('')}${tempTime}`;
    return !!optimalInventory?.[key];
  };

  const updateInventoryStatus = (time) => {
    let key = null;
    const tempTime = time ?? 'MONTH';
    const data = [];
    if (selectedCCs?.length === 0 && selectedPlants?.length === 0) {
      setInventoryStatus({
        mode: MODES.PLANT,
        data: [{
          All: optimalInventory[`P${tempTime}`],
        }],
      });
    } else if (dashboardMode === MODES.PLANT) {
      if (grpsNdPlants.groups?.length) {
        grpsNdPlants.groups.forEach((grp) => {
          key = `P${JSON.parse(grp.value)?.sort().join('')}${tempTime}GROUPED`; // Comment this
          // line if every data point dont need contribution
          // from every plant in the plant group select
          // key = `P${JSON.parse(grp.value)?.sort().join('')}${tempTime}`;// And Uncomment this one
          data.push({
            [grp.grpName]: optimalInventory?.[key],
          });
        });
        if (grpsNdPlants.plants?.length) {
          key = `P${grpsNdPlants.plants?.sort().join('')}${tempTime}`;
          data.push({
            singles: optimalInventory?.[key],
          });
        }
      } else if (selectedPlants?.length) {
        key = `P${selectedPlants?.sort().join('')}${tempTime}`;
        data.push({
          singles: optimalInventory?.[key],
        });
      }
      setInventoryStatus({
        mode: dashboardMode,
        data,
      });
    } else {
      setInventoryStatus({
        mode: dashboardMode,
        data: [{
          singles: optimalInventory[`CC${selectedCCs?.sort().join('')}${tempTime}`],
        }],
      });
    }
  };

  const loadingKeyMapper = {
    optimalInvByTime: loadingKeys.optimalInvTime,
    inventoryValByTime: loadingKeys.inventoryValTime,
  };

  const dispatchAction = useCallback((plants, CCs, mode, time, isGrouped = null, sqlOnly) => {
    dispatch(allActions.DashboardActions.setLineChartLoading(loadingKeyMapper[widgetID]));
    dispatch(
      allActions.DashboardActions[widgetID](
        mode === MODES.PLANT ? plants : null,
        mode === MODES.CC ? CCs : null,
        mode,
        time ?? 'MONTH',
        isGrouped,
        sqlOnly,
      ),
    );
  }, [SQLOnly]);

  useEffect(() => {
    if (selectedPlants && selectedCCs && settingsLoaded
      && dashboardMode && grpsNdPlants && timePeriod && (SQLOnly === true || SQLOnly === false)) {
      let allDataExists = true;
      if (!selectedCCs?.length && !selectedPlants.length) {
        if (!selectedExists(null, timePeriod)) {
          dispatchAction([], [], MODES.PLANT, timePeriod, false, SQLOnly);
        } else {
          updateInventoryStatus(timePeriod);
        }
        return;
      }
      if (dashboardMode === MODES.PLANT) {
        if (grpsNdPlants.groups?.length) {
          grpsNdPlants.groups.forEach((grp) => {
            if (!selectedExists(grp?.value, timePeriod, true)) {
              allDataExists = false;
              dispatchAction(
                JSON.parse(grp?.value ?? [])?.sort(), [], dashboardMode, timePeriod,
                true, // set this to true if every data point need to
                // contribution from every plant in the plant group select
                SQLOnly,
              );
            }
          });
          if (grpsNdPlants.plants?.length) {
            if (!selectedExists(JSON.stringify(grpsNdPlants.plants ?? []), timePeriod)) {
              allDataExists = false;
              dispatchAction(grpsNdPlants.plants?.sort(), [],
                dashboardMode, timePeriod, false, SQLOnly);
            }
          }
        } else if (!selectedExists(null, timePeriod)) {
          if (!selectedPlants?.length) {
            dispatchAction([], [], dashboardMode, timePeriod, false, SQLOnly);
          } else dispatchAction([...selectedPlants], [], dashboardMode, timePeriod, false, SQLOnly);
          allDataExists = false;
        }
      } else if (!selectedExists(null, timePeriod)) {
        allDataExists = false;
        dispatchAction([], selectedCCs, dashboardMode, timePeriod, false, SQLOnly);
      }
      if (allDataExists) {
        updateInventoryStatus(timePeriod);
      }
    }
  }, [
    selectedPlants, selectedCCs,
    settingsLoaded, dashboardMode,
    optimalInventory, widgetID,
    grpsNdPlants, timePeriod, SQLOnly,
  ]);

  return (
    <div className={styles.widgetHeight}>
      {inventoryStatus?.data && !loading?.[loadingKeyMapper[widgetID]]
        ? (
          <LineChartWidget
            inventoryStatus={inventoryStatus}
            showLegendSetting={userSettings?.SHOW_WIDGET_LEGENDS}
            canViewValues={canViewValues}
            label={label}
            widgetID={widgetID}
            timePeriod={timePeriod}
            setTimePeriod={setTimePeriod}
            textWidgetID={textWidgetID}
          />
        )
        : (
          <Skeleton
            active
            paragraph={{ rows: 5 }}
          />
        )}
    </div>
  );
};
export default HistoricalDataWidgetsContainer;

HistoricalDataWidgetsContainer.defaultProps = {
  label: '',
  textWidgetID: null,
};

HistoricalDataWidgetsContainer.propTypes = {
  canViewValues: PropTypes.bool.isRequired,
  widgetID: PropTypes.string.isRequired,
  label: PropTypes.string,
  textWidgetID: PropTypes.string,
};
