import { FilterTwoTone, FilterFilled } from '@ant-design/icons';
import Icon from '@ant-design/icons/lib/components/Icon';
import { Tooltip, Typography } from 'antd';
import React from 'react';
import { ReactComponent as IndicatorIcon } from '../../../../images/icons/radio_button_checked_black_24dp.svg';
import { localDateFormat, localDateTimeFormat } from '../../../Common/DateAndNumberFunctions';
import widths from './tabColumnWidths';
import { dateCols, dateTimeCols } from '../../../Common/GlobalConstants';
import { SAP_URL } from '../../../../actions/APIUrls';

const { Text } = Typography;

const getDropdownOptionsText = (data = [], col = '') => Array
  .from(new Set(data.map((x) => x[col])))
  .sort()
  .map((x) => ({
    text: x ?? 'No value',
    value: x,
  }));

const getDropdownOptionsYear = (data = [], col = '') => Array
  .from(new Set(data.map((x) => new Date(x[col]).getFullYear())))
  .sort((a, b) => b - a)
  .map((year) => ({ text: year, value: year }));

const getDropdownOptions = (data = [], col = '', arg = null) => (arg === 'YEAR'
  ? getDropdownOptionsYear(data, col)
  : getDropdownOptionsText(data, col));

const generateTabHeaders = (
  originalColumns,
  columnData = [],
  tabOptions = {},
  data,
  allMovements,
) => {
  const headers = [];
  const isNumber = (val) => /^-?[0-9]+(e[0-9]+)?(\.[0-9]+)?$/.test(val);
  originalColumns.filter((x) => !tabOptions.hiddenColumns?.includes(x)).forEach((col) => {
    const cell = columnData.find((header) => header.ORIGINAL_NAME === col)
      ?? { ORIGINAL_NAME: col };
    const displayName = cell.DISPLAY_NAME ?? cell.ORIGINAL_NAME;
    const displayNameLong = cell.DISPLAY_NAME_LONG ?? displayName;
    const widthsAM = {
      EDAM_MATERIAL_DOCUMENT_HEADER_TXT: 65,
      ITEM_TEXT: 65,
      WBSE_DESC: 70,
      PLANT_FACILITY_SAP_ID: 51,
      MATERIAL_MEMO: 50,
    };
    let renderAM = null;
    if (allMovements && widthsAM[cell?.ORIGINAL_NAME]) {
      renderAM = (text) => (
        <Tooltip title={text}>
          <Text
            style={{ width: widthsAM[cell?.ORIGINAL_NAME] }}
            ellipsis
          >
            {text}
          </Text>
        </Tooltip>
      );
    }

    const useCorrectDateForCorrectValue = (text) => {
      if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(text)) return text;
      return localDateFormat(text);
    };

    const render = (text, record) => {
      if (tabOptions[cell?.ORIGINAL_NAME]?.render && text && text !== '') {
        return tabOptions[cell?.ORIGINAL_NAME].render(text, record);
      }
      if (dateCols.includes(col)) { return useCorrectDateForCorrectValue(text); }
      if (dateTimeCols.includes(col)) { return localDateTimeFormat(new Date(text)); }
      if (cell.ORIGINAL_NAME === 'MATERIAL_MEMO') {
        return (
          <Tooltip title={text}>
            <Text
              style={{ width: widthsAM[cell?.ORIGINAL_NAME] }}
              ellipsis
            >
              {text}
            </Text>
          </Tooltip>
        );
      }
      if (cell.ORIGINAL_NAME === 'PLANT_FACILITY_SAP_ID') {
        return (
          <Tooltip title={record.PLANT_FACILITY_SAP_NM}>
            <Text
              style={{ width: widthsAM[cell?.ORIGINAL_NAME] }}
            >
              {text}
            </Text>
          </Tooltip>
        );
      }
      return text;
    };
    headers.push({
      title: <Tooltip title={displayNameLong}>{displayName}</Tooltip>,
      showSorterTooltip: false,
      dataIndex: col,
      key: col,
      width: allMovements
        ? widthsAM[cell?.ORIGINAL_NAME] ?? widths[cell?.ORIGINAL_NAME] ?? undefined
        : widths[cell?.ORIGINAL_NAME] ?? undefined,
      defaultSortOrder: tabOptions[col]?.defaultSortOrder,
      sorter: {
        compare: (a, b) => {
          if (a[col] === b[col]) {
            return 0;
          }
          if (a[col] === null) {
            return 1;
          }
          if (b[col] === null) {
            return -1;
          }
          if ((isNumber(a[col]) && isNumber(b[col]))
            || typeof a[col] === 'boolean' || typeof b[col] === 'boolean') {
            return a[col] - b[col];
          }
          return a[col].localeCompare(b[col]);
        },
      },
      filters: tabOptions[col]?.filters
        ? getDropdownOptions(data, col, tabOptions[col]?.filterOn)
        : null,
      onFilter: tabOptions[col]?.filters
        ? (val, item) => item[col]?.includes(val) || (item[col] === null && val === null)
        : null,
      render: renderAM || render,
      filterIcon: (filtered) => (filtered ? <FilterFilled /> : <FilterTwoTone />),
    });
  });
  return headers;
};

const getIndicatorColor = (statusIndicator) => {
  switch (statusIndicator) {
    case 'Green':
      return '#30E317';
    case 'Yellow':
      return '#F9DD15';
    case 'Red':
      return '#F13919';
    default:
      return '#EDEDED';
  }
};

const statusIndicator = (statusLight) => (
  <Icon
    component={IndicatorIcon}
    style={{ color: getIndicatorColor(statusLight), fontSize: 16 }}
  />
);

const getDemandTime = (n) => {
  const parsedN = Number.parseInt(n, 10);
  let demandTime = '-';
  if ([3].includes(parsedN)) { demandTime = '0-2 days'; }
  if ([2, 6, 9].includes(parsedN)) { demandTime = '< 15 days'; }
  if ([1, 4, 5, 7, 8].includes(parsedN)) { demandTime = '< 60 days'; }
  return demandTime;
};

const SAPLinks = (args) => [{
  key: 'IW33',
  url: `${SAP_URL}sap/bc/gui/sap/its/webgui?sap-client=505&sap-language=EN&~transaction=*iw33%20CAUFVD-AUFNR=${args[0]}`,
},
{
  key: 'MB51',
  url: `${SAP_URL}sap/bc/gui/sap/its/webgui?sap-client=505&sap-language=EN&~transaction=MB51%20MBLNR-LOW=${args[0]};MATNR-LOW=${args[1]}`,
},
{
  key: 'ME2N',
  url: `${SAP_URL}sap/bc/gui/sap/its/webgui?sap-client=505&sap-language=EN&~transaction=*ME2N%20EN_EBELN-LOW=${args[0]}`,
},
{
  key: 'IL03',
  url: `${SAP_URL}sap/bc/gui/sap/its/webgui?sap-client=505&sap-language=EN&~transaction=*IL03%20IFLO-TPLNR=${args[0]}`,
},
{
  key: 'MD04',
  url: `${SAP_URL}sap/bc/gui/sap/its/webgui?sap-client=505&sap-language=EN&~transaction=*MD04%20RM61R-MATNR=${args[0]};RM61R-WERKS=${args[1]}`,
},
/* {
  key: 'ME33K',
  url: `${SAP_URL}irj/portal/TS/TransactionStarter?System=SAP_OIL&TCode=ME33K&&AutoStart=true&ApplicationParameter=RM06E-EVRTN=${args[0]}`,
}, */
{
  key: 'MM03',
  url: `${SAP_URL}sap/bc/gui/sap/its/webgui?sap-client=505&sap-language=EN&~transaction=MM03%20RMMG1-MATNR=${args?.[0]}`,
},
{
  key: 'O3O_RT03',
  url: `${SAP_URL}sap/bc/gui/sap/its/webgui?sap-client=505&sap-language=EN&~transaction=*O3O_RT03%20ROIO_RT_RTDOC_SCR-DOCNR%3D${args[0]}`,
}];

const getSAPurl = (key, args) => SAPLinks(args).find((x) => x.key === key)?.url;

const getSAPLink = (tooltip, SAPkey, args, text) => <Tooltip title={tooltip}><a target="_blank" href={getSAPurl(SAPkey, args)} rel="noreferrer">{text}</a></Tooltip>;

const getSAPLinkFunction = (tooltip, type, material = null, trimZero = false) => (text) => {
  const { trimEnd } = String.prototype;
  let value = text;
  value = trimZero ? value.replace(/^0+/, '') : value;
  value = trimEnd.call(value);
  if (text === '0' && type === 'IW33') {
    return '';
  }
  return (
    <Tooltip title={tooltip}>
      <a target="_blank" href={getSAPurl(type, [text, material])} rel="noreferrer">
        <div style={{ whiteSpace: 'pre' }}>{value}</div>
      </a>
    </Tooltip>
  );
};

const allMovementCellRender = (value, record) => {
  let bgColor;
  if (record?.EDAM_LOCAL_CURRENCY_AMT === 0) bgColor = 'transparent';
  else if (record?.MATERIAL_BASE_UOM_CALCULATED_QTY > 0) bgColor = 'lightgreen';
  else bgColor = 'lightred';
  return (
    <div className={bgColor}>
      <Text>{value}</Text>
    </div>
  );
};

export {
  generateTabHeaders,
  getIndicatorColor,
  statusIndicator,
  getDemandTime,
  getSAPLink,
  getSAPLinkFunction,
  allMovementCellRender,
};
