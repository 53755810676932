import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { formatPercent } from './ConfigFunctions';
import './ConfigTable.css';

const ConfigConfirm = ({ configTable, changeTable }) => {
  const columns = [
    {
      title: 'Plant',
      dataIndex: 'PLANT_FACILITY_SAP_ID',
      width: '15%',
      sorter: (a, b) => a.PLANT_FACILITY_SAP_ID - b.PLANT_FACILITY_SAP_ID,
    },
    {
      title: 'Company Code',
      dataIndex: 'COMPANY_CODE',
      width: '15%',
      sorter: (a, b) => a.COMPANY_CODE - b.COMPANY_CODE,
    },
    {
      title: 'Currency',
      dataIndex: 'COMPANY_CURRENCY_SAP_CD',
      width: '10%',
      sorter: (a, b) => a.COMPANY_CURRENCY_SAP_CD - b.COMPANY_CURRENCY_SAP_CD,
    },
    {
      title: 'Manual PO Cost',
      dataIndex: 'MANUAL_PO_COST',
      width: '15%',
      render: (text, record) => {
        const originalRec = configTable.find((rec) => rec.key === record.key);
        return originalRec?.MANUAL_PO_COST === record?.MANUAL_PO_COST
          ? <span>{text}</span>
          : (
            <span className="changed">
              {`${originalRec?.MANUAL_PO_COST} `}
              <CaretRightOutlined />
              {` ${text}`}
            </span>
          );
      },
    },
    {
      title: 'Auto PO Cost',
      dataIndex: 'AUTO_PO_COST',
      width: '15%',
      render: (text, record) => {
        const originalRec = configTable.find((rec) => rec.key === record.key);
        return originalRec?.AUTO_PO_COST === record?.AUTO_PO_COST
          ? <span>{text}</span>
          : (
            <span className="changed">
              {`${originalRec?.AUTO_PO_COST} `}
              <CaretRightOutlined />
              {` ${text}`}
            </span>
          );
      },
    },
    {
      title: 'Holding Cost',
      dataIndex: 'HOLDING_COST_PRCT',
      width: '15%',
      render: (text, record) => {
        const originalRec = configTable.find((rec) => rec.key === record.key);
        return originalRec?.HOLDING_COST_PRCT === record?.HOLDING_COST_PRCT
          ? <span>{`${formatPercent(text)}%`}</span>
          : (
            <span className="changed">
              {`${formatPercent(originalRec?.HOLDING_COST_PRCT)}%`}
              <CaretRightOutlined />
              {`${formatPercent(text)}%`}
            </span>
          );
      },
    },
    {
      title: 'Exclude PM10',
      dataIndex: 'EXCLUDE_PM10',
      render: (text, record) => {
        const originalRec = configTable.find((rec) => rec.key === record.key);
        return originalRec?.EXCLUDE_PM10 === record?.EXCLUDE_PM10
          ? text
          : (
            <span className="changed">
              {originalRec?.EXCLUDE_PM10 ? 'True' : 'False'}
              <CaretRightOutlined />
              {text ? 'True' : 'False'}
            </span>
          );
      },
    },
  ];

  return (
    <Table
      dataSource={changeTable}
      columns={columns}
      size="small"
      pagination={{ pageSize: 20 }}
    />
  );
};

export default ConfigConfirm;

ConfigConfirm.defaultProps = {
  configTable: [],
  changeTable: [],
};

ConfigConfirm.propTypes = {
  configTable: PropTypes.arrayOf(PropTypes.any),
  changeTable: PropTypes.arrayOf(PropTypes.any),
};
