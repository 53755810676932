import * as actionTypes from '../actions/DashboardDomain/ActionTypes';
import * as loadingKeys from '../actions/DashboardDomain/LoadingKeys';

export default function dashboardReducer(state = {}, action) {
  const loadingArray = state.loading ? { ...state.loading } : {};
  // TODO this needs to handle materials
  const getKey = (payload) => {
    if (payload?.plants?.length === payload?.companyCodes?.length) return 'A';
    return payload?.plants?.length > 0
      ? `P${payload?.plants.sort().join('')}`
      : `CC${payload?.companyCodes?.sort().join('')}`;
  };

  const getTimeWidgetKey = (payload) => {
    const timePeriods = ['WEEK', 'MONTH', 'YEAR'];
    if (!payload?.plants?.filter((x) => !timePeriods.includes(x))?.length
      && !payload?.companyCodes?.filter((x) => !timePeriods.includes(x))?.length) {
      return `P${payload?.plants?.join('')}`;
    }
    return payload?.plants?.length > 0
      ? `P${payload?.plants.sort().join('')}${payload?.isGrouped ?? ''}`
      : `CC${payload?.companyCodes?.sort().join('')}`;
  };

  switch (action.type) {
    case actionTypes.optimalInventoryByStrategy: {
      loadingArray[loadingKeys.optInvStrategy] = false;
      const key = getKey(action?.payload);
      return {
        ...state,
        optimalInventoryByStrategy: { ...state.optimalInventoryByStrategy, [key]: action.payload },
        loading: loadingArray,
      };
    }

    case actionTypes.optimalInventoryByGroup: {
      loadingArray[loadingKeys.optInvGroup] = false;
      const key = getKey(action?.payload);
      return {
        ...state,
        optimalInventoryByGroup: { ...state.optimalInventoryByGroup, [key]: action.payload },
        loading: loadingArray,
      };
    }

    case actionTypes.optimalInventoryBySP: {
      loadingArray[loadingKeys.optInvSP] = false;
      const key = getKey(action?.payload);
      return {
        ...state,
        optimalInventoryBySP: { ...state.optimalInventoryBySP, [key]: action.payload },
        loading: loadingArray,
      };
    }

    case actionTypes.optimalInventoryByCrit: {
      loadingArray[loadingKeys.optInvCrit] = false;
      const key = getKey(action?.payload);
      return {
        ...state,
        optimalInventoryByCrit: { ...state.optimalInventoryByCrit, [key]: action.payload },
        loading: loadingArray,
      };
    }

    case actionTypes.optimalInventoryByROP: {
      loadingArray[loadingKeys.optInvROP] = false;
      const key = getKey(action?.payload);
      return {
        ...state,
        optimalInventoryByROP: { ...state.optimalInventoryByROP, [key]: action.payload },
        loading: loadingArray,
      };
    }

    case actionTypes.optimalInvByTime: {
      loadingArray[loadingKeys.optimalInvTime] = false;
      const key = getTimeWidgetKey(action?.payload);
      return {
        ...state,
        optimalInvByTime: { ...state.optimalInvByTime, [key]: action.payload },
        loading: loadingArray,
      };
    }

    case actionTypes.inventoryValByTime: {
      loadingArray[loadingKeys.inventoryValTime] = false;
      const key = getTimeWidgetKey(action?.payload);
      return {
        ...state,
        inventoryValByTime: { ...state.inventoryValByTime, [key]: action.payload },
        loading: loadingArray,
      };
    }

    case actionTypes.plannedOrders: {
      loadingArray[loadingKeys.plannedOrders] = false;
      const key = getKey(action?.payload);
      return {
        ...state,
        plannedOrders: { ...state.plannedOrders, [key]: action.payload },
        loading: loadingArray,
      };
    }

    case actionTypes.cleanUpWidget: {
      loadingArray[loadingKeys.cleanUpWidget] = false;
      const key = getKey(action?.payload);
      return {
        ...state,
        cleanUpWidget: { ...state.cleanUpWidget, [key]: action.payload },
        loading: loadingArray,
      };
    }

    case actionTypes.excessStockByStatus: {
      loadingArray[loadingKeys.excessStockStatus] = false;
      const key = getKey(action?.payload);
      return {
        ...state,
        excessStockByStatus: { ...state.excessStockByStatus, [key]: action.payload },
        loading: loadingArray,
      };
    }

    case actionTypes.inventoryValue: {
      loadingArray[loadingKeys.inventoryValue] = false;
      const key = getKey(action?.payload);
      return {
        ...state,
        inventoryValue: { ...state.inventoryValue, [key]: action.payload },
        loading: loadingArray,
      };
    }

    case actionTypes.monetaryImpact: {
      loadingArray[loadingKeys.monetaryImpact] = false;
      const key = getKey(action?.payload);
      return {
        ...state,
        monetaryImpact: { ...state.monetaryImpact, [key]: action.payload },
        loading: loadingArray,
      };
    }

    case actionTypes.totalExcessStock: {
      loadingArray[loadingKeys.totalExcessStock] = false;
      const key = getKey(action?.payload);
      return {
        ...state,
        totalExcessStock: { ...state.totalExcessStock, [key]: action.payload },
        loading: loadingArray,
      };
    }

    case actionTypes.storeOptInvTot: {
      loadingArray[loadingKeys.optInvTot] = false;
      const key = getKey(action?.payload);
      return {
        ...state,
        optInvTot: { ...state.optInvTot, [key]: action.payload },
        loading: loadingArray,
      };
    }
    case actionTypes.setLoading: {
      loadingArray[action.payload] = true;
      return {
        ...state,
        loading: loadingArray,
      };
    }
    case actionTypes.setLineChartLoading: {
      loadingArray[action.payload] = true;
      return {
        ...state,
        loading: loadingArray,
      };
    }
    default:
      return state;
  }
}
