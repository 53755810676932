import PropTypes from 'prop-types';
import { DownOutlined, FilterOutlined } from '@ant-design/icons';
import {
  Button, Col, Row, TreeSelect,
} from 'antd';
import React, { useState, useEffect } from 'react';
import './FilterMenuView.css';

import classCharacteristics from '../ClassCharacteristics';

const MMColumnSelector = ({ displayHeaderCells, setDisplayHeaderCells, allHeaderCells }) => {
  const [mmCols, setMMCols] = useState([]);
  const [hasChanged, setHasChanged] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => { setMMCols(displayHeaderCells.map((x) => x.dataIndex)); }, [displayHeaderCells]);

  const getSubCharacteristics = (parent) => parent?.children?.map((x) => x.key);

  const mmApply = () => {
    setHasChanged(false);
    const headerCellKeys = [];
    mmCols.forEach((column) => {
      const topLevelItem = classCharacteristics.find((char) => char.key === column);
      if (topLevelItem && topLevelItem.children?.length > 0) {
        getSubCharacteristics(topLevelItem)?.forEach((subChar) => headerCellKeys.push(subChar));
      } else {
        headerCellKeys.push(column);
      }
    });
    const localHC = headerCellKeys.map(
      (key) => allHeaderCells.find((cell) => cell.dataIndex === key),
    ).filter((x) => x !== undefined);
    if (localHC.length > 0) setDisplayHeaderCells(localHC);
    setIsOpen(false);
  };

  const onChange = (cols) => {
    setHasChanged(true);
    setMMCols(cols);
  };

  return (
    <TreeSelect
      dropdownMatchSelectWidth={250}
      open={isOpen}
      className="mmTreeSelect"
      treeCheckable
      dropdownRender={(dropdown) => (!hasChanged ? dropdown : (
        <>
          {dropdown}
          <Button onClick={mmApply} className="applyButton" type="primary">
            Apply
          </Button>
        </>
      ))}
      onDropdownVisibleChange={setIsOpen}
      maxTagPlaceholder={(
        <Row justify="space-between">
          <Col style={isOpen && { fontWeight: 'normal' }}>
            <FilterOutlined style={{ paddingRight: '2px' }} />
            {!isOpen && 'Add/remove columns'}
          </Col>
          <Col>
            <DownOutlined style={{ paddingLeft: '2px', fontSize: 12 }} />
          </Col>
        </Row>
      )}
      maxTagCount={0}
      style={{ width: '200px', maxHeight: '1200px' }}
      treeData={classCharacteristics}
      value={mmCols}
      onChange={onChange}
      showSearch
      listHeight={800}
      placeholder={undefined}
      showCheckedStrategy="SHOW_PARENT"
    />
  );
};

export default MMColumnSelector;

MMColumnSelector.propTypes = {
  displayHeaderCells: PropTypes.arrayOf(PropTypes.any).isRequired,
  setDisplayHeaderCells: PropTypes.func.isRequired,
  allHeaderCells: PropTypes.arrayOf(PropTypes.any).isRequired,
};
