const scales = {
  linear: { type: 'linear' },
  symlog: { type: 'symlog', constant: 1e-2 },
  symloghor: { type: 'symlog', constant: 1e+2 },
};
const colors = {
  RED: '#e24973',
  GREEN: '#00977b',
  YELLOW: '#e8d166',
};

const defaultTypes = {
  UNKNOWN: 'Criticality unknown',
  NOT_APPROVED: 'Not approved stock',
  APPROVED: 'Approved stock',
};

const defaultColorMapping = {
  'Approved stock': 'Green',
  'Not approved stock': 'Red',
  'Criticality unknown': 'Yellow',
};

const defaultColorCodes = {
  Green: '#00977B',
  Yellow: '#E8D166',
  Red: ' #E24973',
};

const excessStockTypes = {
  YELLOW: 'YELLOW',
  RED: 'RED',
  GREEN: 'GREEN',
};

const labelMapping = {
  Green: defaultTypes.APPROVED,
  Red: defaultTypes.NOT_APPROVED,
  Yellow: defaultTypes.UNKNOWN,
};

const textWidgetsValueLabels = {
  optInvTot: 'Optimal Inventory',
  inventoryValue: 'Value of inventory',
  totalExcessStock: 'Total Value of Excess Stock',
};

export {
  scales,
  colors,
  defaultColorMapping,
  defaultColorCodes,
  defaultTypes,
  excessStockTypes,
  excessStockTypes as excessStockColorMapping,
  textWidgetsValueLabels,
  labelMapping,
};
